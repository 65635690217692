import React from 'react';
import {Col, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {FormikTextInputGroup} from '../common/formik/FormikTextInputGroup';
import MuiAutocomplete from '../common/MuiAutocomplete/MuiAutocomplete';
import FormikSelect from "../common/formik/FormikSelect";
import styles from "../form.module.scss";
import { COUNTRIES } from "../../utils/countries";
import { COUNTRY_CODE } from "../../utils/countryCode";
import { get } from 'lodash';

const SALUTATION_OPTIONS = [
    {id: 'm', name: 'Herr'},
    {id: 'f', name: 'Frau'},
    {id: 'd', name: 'Divers'},
]

function FormContact({ name, title , formik }) {
    const getValueCountryCode = ( fieldname ) => ({
        name: get(formik.values, `${name}.${fieldname}.countryCode`),
        id: get(formik.values, `${name}.${fieldname}.countryCode`)
    });

    return (
        <div className={styles.formBox}>
            <h2 className={styles.formTitle}>{title}</h2>
            <Row className="mt-3">
                <Col className="col-md-4">
                    <FormikSelect
                        label="Anrede*"
                        options={SALUTATION_OPTIONS}
                        name={`${name}.salutation`}
                        isShowEmptyValue={false}
                        emptyValueLabel="Bitte wählen..."
                    />
                </Col>
                <Col className="col-md-4">
                    <FormikTextInputGroup
                        label="Nachname*"
                        name={`${name}.lastName`}
                    />
                </Col>
                <Col className="col-md-4">
                    <FormikTextInputGroup
                        label="Vorname*"
                        name={`${name}.firstName`}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col className="col-md-4">
                    <FormikTextInputGroup
                        label="Straße & Hausnummer*"
                        name={`${name}.address1`}
                    />
                </Col>
                <Col className="col-md-4">
                    <FormikTextInputGroup
                        label="Adresszusatz"
                        name={`${name}.address2`}
                    />
                </Col>
                <Col className="col-md-4">
                    <FormikTextInputGroup
                        label="Firma"
                        name={`${name}.companyName`}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col className="col-md-4">
                    <FormikTextInputGroup
                        label="PLZ*"
                        name={`${name}.postalCode`}
                    />
                </Col>
                <Col className="col-md-4">
                    <FormikTextInputGroup
                        label="Stadt*"
                        name={`${name}.city`}
                    />
                </Col>
                <Col className="col-md-4">
                    <FormikSelect
                        label="Land*"
                        options={COUNTRIES}
                        name={`${name}.country`}
                        isShowEmptyValue={false}
                        emptyValueLabel="Bitte wählen..."
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col className="col-md-4">
                    <div className={styles.phoneBox}>
                        <MuiAutocomplete
                            label='Ländervorwahl'
                            prefix={'+'}
                            options={COUNTRY_CODE}
                            value={getValueCountryCode('phone')}
                            error={get(formik.errors[name], 'phone.countryCode')}
                            onChange={(_, { id }) => formik.setFieldValue(`${name}.phone.countryCode`, id)}
                        />
                        <FormikTextInputGroup
                            label="Telefon"
                            name={`${name}.phone.phoneNumber`}
                        />
                    </div>
                </Col>
                <Col className="col-md-4">
                    <div className={styles.phoneBox}>
                        <MuiAutocomplete
                            label='Ländervorwahl'
                            prefix={'+'}
                            options={COUNTRY_CODE}
                            value={getValueCountryCode('mobile')}
                            error={get(formik.errors[name], 'mobile.countryCode')}
                            onChange={(_, { id }) => formik.setFieldValue(`${name}.mobile.countryCode`, id)}
                        />

                        <FormikTextInputGroup
                            label="Mobil"
                            name={`${name}.mobile.phoneNumber`}
                        />
                    </div>
                </Col>
                <Col className="col-md-4">
                    <FormikTextInputGroup
                        label="E-Mail*"
                        name={`${name}.email`}
                    />
                </Col>
            </Row>
        </div>
    )
}

FormContact.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
};

export default FormContact;
