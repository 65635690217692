import {call, put} from "@redux-saga/core/effects";
import {normalize} from "normalizr";

/**
 * Saga für den Saalplanabruf
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export const byVenueSaga = ({actions, resource, schemaList}) => function* ({payload: {id}}) {
    try {
        const result = yield call(resource.byVenue, id);
        const response = normalize(result, schemaList);
        yield put(actions.byVenueSuccess({response}));
    } catch (error) {
        yield put(actions.byVenueError({id, error}));
    }
};
