import React, {Component} from 'react';
import Form from "./Form";
import {connect} from "react-redux";
import {LoadingIndicator} from "../common/LoadingIndicator";
import {Col, Row} from "react-bootstrap";
import * as backendUser from "../../state/entities/backendUser";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Edit extends Component {

    componentDidMount() {
        this.props.loadBackendUser(this.props.backendUserId);
    }

    onSubmit = (backendUser, {setErrors, setFieldValue }) => {
        this.props.saveBackendUser(backendUser, setErrors);
        // clear password field after updating
        setFieldValue('password', null);
    };

    render() {
        const { backendUser, loading, saving } = this.props;

        if (!backendUser || loading) {
            return <LoadingIndicator/>;
        }
        return <>
            <Row className="my-4">
                <Breadcrumbs
                    entity={["Benutzer"]}
                    endpoints = {['backend_user/breadcrumb']}
                    title="Benutzer"
                    links={['/base/backend-user/']}
                    active={[backendUser.id]}
                />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Bearbeiten des Benutzer <em>{backendUser.name}</em></h1>
                </Col>
            </Row>
            <Form backendUser={backendUser} onSubmit={this.onSubmit} submitPending={saving} />
        </>;
    }
}

const mapStateToProps = (state, props) => {
    const backendUserId = props.match.params.id;
    return {
        backendUserId,
        loading: state.entities.backendUser.loading[backendUserId],
        saving: state.entities.backendUser.saving[backendUserId],
        backendUser: state.entities.backendUser.byId[backendUserId]
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadBackendUser: id => dispatch(backendUser.actions.load({id})),
        saveBackendUser: entity => dispatch(backendUser.actions.save({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
