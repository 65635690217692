import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import * as PropTypes from 'prop-types';
import Table from "react-bootstrap/Table";
import {FormattedDate} from "react-intl";
import {Link} from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import styles from "../form.module.scss";
import Api from "../../api";

const api = new Api();

function Events({artistId}) {
    const [artistEvents, setArtistEvents] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        if (!artistId) {
            return;
        }
        setIsFetching(true);
        api.getArtistEvents(artistId)
            .then(result => {
                setArtistEvents(result);
                setIsFetching(false);
            })
            .catch(() => {
                setIsFetching(false);
            });
    }, [artistId]);

    return (
        <div className={styles.formBox}>
            <h2 className={styles.formTitle}>Events</h2>
            <Row className="mt-4">
                <Col className="col-md-4">
                    <Table striped bordered hover className="multiselectlist">
                        <thead>
                        <tr>
                            <th>Event Titel</th>
                            <th>Startzeitpunkt</th>
                            <th>&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!isFetching ? (
                            artistEvents.map((element, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{element.title.de}</td>
                                        <td><FormattedDate value={element.startDate} day="2-digit" month="2-digit"
                                                           year="numeric"/></td>
                                        <td>
                                            {element.type === 'venueEvent' &&
                                                <Link target="_blank" to={`/event-management/event/${element.id}`}>
                                                    zu diesem Event springen
                                                </Link>}
                                            {element.type === 'metaEvent' &&
                                                <Link target="_blank" to={`/event-management/meta-event/${element.id}`}>
                                                    zu diesem Event springen
                                                </Link>}
                                        </td>
                                    </tr>
                                )
                            })

                        ) : (<Spinner animation="border" role="status"/>)
                        }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
    )
}

Events.propTypes = {
    venuePlan: PropTypes.object,
};

export default Events;
