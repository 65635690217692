import React, {useEffect, useState} from 'react';
import {Alert, Card, Col, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Api from '../../api';
import FeedbackButton from "../common/FeedbackButton";
import {saveAs} from 'file-saver';
import moment from 'moment';
import StopJob from "../common/job/StopJob";
import {MenuItem, Select as MuiSelect} from '@mui/material';
import CheckIcon from "@mui/icons-material/Check";

const INTERVAL_TO_CHECK_IF_FILE_CREATED = 5000; // 5s
const api = new Api();

const EXPORT_TYPES = [
    {value: 'valid', name: 'Nur zur Zeit gültige Barcodes exportieren (Whitelist)', isDefault: true},
    {value: 'invalid', name: 'Nur geblockte Barcodes exportieren (Blacklist)'},
    {value: 'all', name: 'Alle Barcodes exportieren'}
];

const ExportTickets = ({eventId, eventShortName}) => {
    const [loading, setLoading] = useState(false);
    const [exportType, setExportType] = useState(EXPORT_TYPES.find(type => type.isDefault).value);
    const [intervalCheck, setIntervalCheck] = useState({jobId: null, endCheckingTime: null});
    const [error, setError] = useState(null);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!intervalCheck.jobId) {
                clearInterval(interval);
                setLoading(false);
                return;
            }

            api.getEventTicketsExportDownload(intervalCheck.jobId)
                .then((res) => {
                    if (res.status === 200) {
                        clearInterval(interval);
                        let fileName = 'tickets_' + eventShortName + '_' + moment().format('YYYY-MM-DD_HH:mm:ss') + '.csv';

                        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        let matches = filenameRegex.exec(res.headers['content-disposition']);
                        if (matches != null && matches[1]) {
                            fileName = matches[1].replace(/['"]/g, '');
                        }
                        setLoading(false);
                        saveAs(new Blob([res.data], {type: res.headers['content-type']}), fileName);
                    }
                })
                .catch((err) => {
                    setIntervalCheck({jobId: null, endCheckingTime: null});
                    clearInterval(interval);
                    setError(err);
                    setLoading(false);
                });

            if (moment(new Date()) >= intervalCheck.endCheckingTime) {
                setIntervalCheck({jobId: null, endCheckingTime: null});
                setLoading(false);
                clearInterval(interval);
            }
        }, INTERVAL_TO_CHECK_IF_FILE_CREATED);
        return () => clearInterval(interval);
    }, [eventId, intervalCheck.endCheckingTime, eventShortName, intervalCheck.jobId, error]);

    function downloadButtonHandle() {
        setLoading(true);

        api.getEventTicketsExportGenerate(eventId, exportType).then(result => {
            if (result) {
                setIntervalCheck({
                    endCheckingTime: moment(new Date()).add(3, 'hours'),
                    jobId: result.jobId
                });
            }

        }).catch(() => {
            setLoading(false);
        });
    }

    return (
        <Card className="mt-4 mb-4">
            <Card.Body>
                <Row className="mt-3 w-75 justify-content-between">
                    <Col className="pt-2 col-auto">
                        <h2 className="font-weight-bold pb-3 mb-3">Tickets exportieren</h2>
                        <p>Hier können Sie sich alle Ticketinformationen inklusive Barcodes für dieses Event als csv herunterladen.</p>
                    </Col>
                </Row>

                <Row>
                    <Col className="pt-2 col-md-3">
                        <MuiSelect
                            label="Exporttyp"
                            value={exportType}
                            onChange={e => setExportType(e.target.value)}
                            options={EXPORT_TYPES}
                        >
                            {EXPORT_TYPES.map((type) => (
                                <MenuItem key={type.value} value={type.value}>
                                    {type.name}
                                    {type.value === exportType && <CheckIcon/>}
                                </MenuItem>
                            ))}
                        </MuiSelect>
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col className="pt-2 col-auto">
                        <FeedbackButton busy={loading} onClick={downloadButtonHandle}>
                            Tickets exportieren
                        </FeedbackButton>

                        {intervalCheck.jobId && loading
                            && <StopJob
                                id={intervalCheck.jobId}
                                onStop={() => {
                                    setIntervalCheck({jobId: null, endCheckingTime: null})
                                }}
                                modalTitle={'Export abbrechen'}
                                buttonText={'Export abbrechen'}
                                bodyText={'Diese Aktion wird die Erstellung des Exports stoppen. Dies kann nicht rückgängig gemacht werden. Sind Sie sicher, dass Sie die Aktion beenden möchten?'}
                            />}
                    </Col>
                </Row>
                {error && <Row className="mt-3">
                    <Alert variant="danger">
                        {error.message}
                    </Alert>
                </Row>}

            </Card.Body>
        </Card>
    )
}

ExportTickets.propTypes = {
    eventId: PropTypes.string,
    eventTitle: PropTypes.string,
};

export default ExportTickets;