import React from "react";
import {connect} from 'react-redux';
import {useDrop} from 'react-dnd';
import DragAndDropItemTypes from "./DragAndDropItemTypes";
import classNames from 'classnames';
import {Button, Col, Row} from "react-bootstrap";
import {get} from "lodash";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ConfirmModal from "../../common/modal/ConfirmModal";
import MoneyInput from "../../common/form/MoneyInput";
import {dragOver} from "../../../state/entities/pricingMatrix/dragAndDrop/actions";
import {cellClear, cellDrop, changeCellGrossAmount, changeCellStrikeAmount} from "../../../state/entities/pricingMatrix/cellMap/actions";
import {getLocalItemTemplateById} from "./helper";
import PricingComponentDisplay from "./PricingComponentDisplay";
import MainComponentGrossAmountNegativeWarning from "./MainComponentGrossAmountNegativeWarning";
import BookingInformationIncompleteWarning from "./BookingInformationIncompleteWarning";
import {toggleEditLocalItemTemplate} from "../../../state/entities/pricingMatrix/actions";

export const MatrixCell = ({cellData, localItemTemplate, onDrop, clearCell, onGrossAmountChange,
                               onStrikeAmountChange, includeStrikeAmount,
                               outerDragOver, onDragOver, toggleEditLocalItemTemplate,
                               pricingComponentsVisible,
}) => {
    const itemTemplate = localItemTemplate ? localItemTemplate.purchasableItemTemplate : undefined;

    let [{dragOver, backgroundColor}, dropRef] = useDrop({
        accept: DragAndDropItemTypes.PURCHASABLE_ITEM_TEMPLATE,
        drop: dragItem => onDrop(dragItem.id),
        hover: onDragOver,
        collect: monitor => ({
            dragOver: !!monitor.isOver(),
            backgroundColor: monitor.getItem() && monitor.getItem().backgroundColor
        }),
    });

    dragOver = outerDragOver || dragOver;
    const styling = {
        backgroundColor: dragOver ? backgroundColor : (itemTemplate ? itemTemplate.color : 'white')
    };

    return (
        <td className={classNames('pricing-matrix-cell', {'drag-over': dragOver})} style={styling} ref={dropRef}>
            {cellData && localItemTemplate &&
            <>
                <Row>
                    <Col sm={12} className="mb-2">
                        {itemTemplate.shortName}
                    </Col>
                    <Col sm={12}>
                        <MoneyInput value={cellData.grossAmount} className="form-control form-control-sm"
                                    onChange={onGrossAmountChange}/>
                        {includeStrikeAmount &&
                            <div className="mt-2">
                                Streichpreis
                                <MoneyInput value={cellData.strikeAmount} className="form-control form-control-sm"
                                            onChange={onStrikeAmountChange}/>
                            </div>
                        }
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <MainComponentGrossAmountNegativeWarning grossAmount={cellData.grossAmount}
                                                                 itemTemplate={itemTemplate}/>
                        <BookingInformationIncompleteWarning localItemTemplate={localItemTemplate} toggleEditLocalItemTemplate={toggleEditLocalItemTemplate}/>

                    </Col>
                </Row>
                <Row>
                    <Col className="text-right">
                        <ConfirmModal title="Zuweisung löschen"
                                      body="Beim nächsten Speichern der Seite endgültig löschen?">
                            {confirm =>
                                <Button onClick={() => confirm(clearCell)} variant="link" size="lg" className="button-right">
                                    <DeleteOutlineOutlinedIcon className='table-icon' />
                                </Button>
                            }
                        </ConfirmModal>
                    </Col>
                </Row>
                {pricingComponentsVisible &&
                <Row>
                    <Col>
                        <PricingComponentDisplay grossAmount={cellData.grossAmount} itemTemplate={itemTemplate}/>
                    </Col>
                </Row>
                }

            </>
            }
        </td>
    );
};


const mapStateToProps = (state, props) => {

    const {pricingMatrix, pricingClass, pricingCategory} = props;

    const outerDragOver = state.entities.pricingMatrix.dragOverObjectId;
    const cellData = get(state.entities.pricingMatrix.cellMapById, [pricingMatrix.id, pricingClass.id, pricingCategory.id]);

    return {
        cellData: cellData,
        localItemTemplate: cellData ? getLocalItemTemplateById(pricingMatrix, cellData.localItemTemplateId) : undefined,
        outerDragOver: outerDragOver === props.pricingClass.id || outerDragOver === props.pricingCategory.id || outerDragOver === 'ALL',
        pricingComponentsVisible: state.entities.pricingMatrix.pricingComponentsVisible,
        includeStrikeAmount: pricingMatrix.includeStrikeAmount
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onDragOver: () => dispatch(dragOver()),
        onDrop: (itemTemplateId) => {
            const localItemTemplate = getLocalItemTemplateById(props.pricingMatrix, itemTemplateId);
            if (localItemTemplate) {
                dispatch(cellDrop({
                    matrixId: props.pricingMatrix.id,
                    classId: props.pricingClass.id,
                    categoryId: props.pricingCategory.id,
                    cellData: {localItemTemplateId: localItemTemplate.id}
                }));
            }
        },
        clearCell: () => {
            dispatch(cellClear({
                matrixId: props.pricingMatrix.id,
                classId: props.pricingClass.id,
                categoryId: props.pricingCategory.id
            }))
        },
        onGrossAmountChange: value => dispatch(changeCellGrossAmount({
            matrixId: props.pricingMatrix.id,
            classId: props.pricingClass.id,
            categoryId: props.pricingCategory.id,
            grossAmount: value
        })),
        onStrikeAmountChange: value => dispatch(changeCellStrikeAmount({
            matrixId: props.pricingMatrix.id,
            classId: props.pricingClass.id,
            categoryId: props.pricingCategory.id,
            strikeAmount: value
        })),
        toggleEditLocalItemTemplate: (localItemTemplateId) => dispatch(toggleEditLocalItemTemplate({
            matrixId: props.pricingMatrix.id,
            localItemTemplateId
        })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MatrixCell);
