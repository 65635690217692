import React from 'react';
import {Col} from 'react-bootstrap';
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import List from "./List";
import {BATCH_ACTION_MOVE_TO_ACTIVE} from "../common/listView/ListViewBatchActions";
import RefreshIcon from '@mui/icons-material/Refresh';
import FeedbackButton from '../common/FeedbackButton';

const LinkToActive = () => {
    return (
        <FeedbackButton
            to={`/event-management/sales-rule`}
            icon={<RefreshIcon />}
            variant='contained'
            className='listLinkCenter'
        >
            Archiv
        </FeedbackButton>
    );
}

const Breadcrumb = () => {
    return (
        <Breadcrumbs entity={["Verkaufsregeln archivieren"]} endpoints = {['archived_sales_rule/breadcrumb']} title = "Verkaufsregeln archivieren" links={['/event-management/sales-rule/archived/']} active={[0]} />
    )
}

const ArchivedSalesRuleList = () => {
    return (
        <List
            linkNearHeader={LinkToActive}
            breadcrumb={Breadcrumb}
            heading="Verkaufsregeln archivieren"
            listViewEndpoint="listingView/archivedSalesRule"
            allowedBatchActions={[BATCH_ACTION_MOVE_TO_ACTIVE]}
        />
    );
};

export default ArchivedSalesRuleList;
