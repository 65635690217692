import React, {useState} from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import PropTypes from "prop-types";
import {getIn} from "formik";
import FilteringMultiSelectList from "../common/form/FilteringMultiSelectList/FilteringMultiSelectList";
import {findIndex} from "lodash";
import ConfirmModal from "../common/modal/ConfirmModal";
import FormModal from "../common/modal/FormModal";
import {MaxTicketsSalesRulesGroup} from "./schema";
import ObjectId from "bson-objectid";
import MaxTicketsSalesRulesGroupForm from "./MaxTicketsSalesRulesGroupForm";
import FeedbackButton from '../common/FeedbackButton';
import AddIcon from '@mui/icons-material/Add';
import styles from "../form.module.scss";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

export function createSalesRulesGroupsListElements(formGroups, eventSalesRules) {
    return formGroups.map((group, index) => {
        let enabledStatus = !!group.enabled;
        let groupStatusText = group.enabled ? 'Enabled' : 'Disabled';

        if (typeof group.eventSalesRuleIds !== "undefined") {
            for (let i = 0; i < group.eventSalesRuleIds.length; i++) {
                // find current eventSalesRule from group in event's sales rules
                let eventSalesRule = eventSalesRules.find(salesRule => {
                    return salesRule.id === group.eventSalesRuleIds[i];
                });

                if (eventSalesRule) {
                    if (!eventSalesRule.enabled || Date.parse(eventSalesRule.validTo) <= Date.now()) {
                        groupStatusText = 'Disabled because ' + eventSalesRule.salesRule.name;
                        enabledStatus = false;
                        break;
                    }
                }
            }
        }

        return ({
            ...group,
            index: index,
            enabledText: groupStatusText,
            enabledStatus: enabledStatus,
        });
    });
}

function FormMaxTicketsSalesRulesGroups({formik, event}) {
    const [salesRulesMaxTicketsGroupToEdit, setSalesRulesMaxTicketsGroupToEdit] = useState(null);
    const [salesRulesMaxTicketsGroupToAdd, setSalesRulesMaxTicketsGroupToAdd] = useState(null);
    const [availableEventSalesRules, setAvailableEventSalesRules] = useState([]);

    const fieldCheckbox = {name: 'enabled', label: 'Regel freigeben', showStatusIcon: true, type: 'formBundles'};
    const groupFields = [
        {name: 'name', label: 'Name'},
        {name: 'maxTicketsPerUser', label: 'Max. Tickets'},
    ];

    const addSalesRulesGroup = (addedGroup) => {
        formik.setFieldValue('maxTicketsSalesRulesGroups', [...formik.values.maxTicketsSalesRulesGroups, {
            ...addedGroup,
            id: ObjectId.generate()
        }]);

        setSalesRulesMaxTicketsGroupToAdd(null);
    };

    const updateSalesRulesGroup = (updatedGroup) => {
        let maxTicketsSalesRulesGroups = getIn(formik.values, 'maxTicketsSalesRulesGroups', []);
        const index = findIndex(maxTicketsSalesRulesGroups, s => s.id === updatedGroup.id);

        if (index !== -1) {
            maxTicketsSalesRulesGroups = [...maxTicketsSalesRulesGroups];
            maxTicketsSalesRulesGroups.splice(index, 1, updatedGroup);
            formik.setFieldValue('maxTicketsSalesRulesGroups', maxTicketsSalesRulesGroups);
        }

        setSalesRulesMaxTicketsGroupToEdit(null);
    };

    const onChange = (selection) => {
        formik.setFieldValue('maxTicketsSalesRulesGroups', selection)
    };

    const RemoveSalesRulesGroupAction = ({id}) => {
        const group = formik.values.maxTicketsSalesRulesGroups.find(item => {
            return item.id === id;
        });

        const removeGroup = (id) => () => {
            const values = formik.values.maxTicketsSalesRulesGroups.filter(group => group.id !== id);
            formik.setFieldValue('maxTicketsSalesRulesGroups', values)
        };

        return (
            <ConfirmModal title="Verkaufsregelgruppierung löschen"
                          body={`Möchten sie die Verkaufsregelgruppierung "${group.name}" wirklich löschen?`}
                          cancelLabel="Abbrechen"
                          confirmLabel="Löschen"
            >
                {confirm => (
                    <FeedbackButton
                        title="Löschen"
                        className="list-link"
                        onClick={() => confirm(removeGroup(id))}
                    >
                        <DeleteOutlineOutlinedIcon className='table-icon' />
                    </FeedbackButton>
                )}
            </ConfirmModal>
        )
    }

    const EditSalesRulesGroupButton = ({group}) => (
        <FeedbackButton title="Bearbeiten" className="list-link" onClick={() => setSalesRulesMaxTicketsGroupToEdit(group)}>
            <EditNoteOutlinedIcon className='table-icon' />
        </FeedbackButton>
    )
    return (
        <>
            <FormModal show={!!salesRulesMaxTicketsGroupToAdd}
                       title="Add Verkaufsregelgruppierung hinzufügen"
                       initialValues={{...MaxTicketsSalesRulesGroup.default(), ...salesRulesMaxTicketsGroupToAdd}}
                       validationSchema={MaxTicketsSalesRulesGroup}
                       onConfirm={addSalesRulesGroup}
                       onCancel={() => setSalesRulesMaxTicketsGroupToAdd(null)}
            >
                {({associationFormik}) => {
                    return (
                        <MaxTicketsSalesRulesGroupForm associationFormik={associationFormik}
                                                       availableEventSalesRules={availableEventSalesRules}
                                                       element={{...MaxTicketsSalesRulesGroup.default(), ...salesRulesMaxTicketsGroupToAdd}}
                                                       setAvailableEventSalesRules={setAvailableEventSalesRules}
                                                       eventSalesRules={formik.values.maxTicketsSalesRulesGroups}
                                                       parentFormik={formik}
                        />
                    )
                }}
            </FormModal>
            <FormModal show={!!salesRulesMaxTicketsGroupToEdit}
                       title="Verkaufsregelgruppierung bearbeiten"
                       initialValues={{...MaxTicketsSalesRulesGroup.default(), ...salesRulesMaxTicketsGroupToEdit}}
                       validationSchema={MaxTicketsSalesRulesGroup}
                       onConfirm={updateSalesRulesGroup}
                       onCancel={() => setSalesRulesMaxTicketsGroupToEdit(null)}
            >
                {({associationFormik}) => {
                    return (
                        <MaxTicketsSalesRulesGroupForm associationFormik={associationFormik}
                                                       availableEventSalesRules={availableEventSalesRules}
                                                       element={{...MaxTicketsSalesRulesGroup.default(), ...salesRulesMaxTicketsGroupToEdit}}
                                                       setAvailableEventSalesRules={setAvailableEventSalesRules}
                                                       eventSalesRules={formik.values.maxTicketsSalesRulesGroups}
                                                       parentFormik={formik}
                        />
                    )
                }}
            </FormModal>
            <div className={styles.formBox}>
                <h2 className={styles.formTitle}>Maximale Tickets je User für Verkaufsregelgruppierung</h2>
                <Row>
                    <Col className='col-md-4'>
                        <FeedbackButton
                            className="mb-3"
                            icon={<AddIcon />}
                            onClick={() => setSalesRulesMaxTicketsGroupToAdd({})}
                        >
                            Gruppierung hinzufügen
                        </FeedbackButton>
                        <FilteringMultiSelectList
                            elements={createSalesRulesGroupsListElements(formik.values.maxTicketsSalesRulesGroups, formik.values.salesRules)}
                            fieldCheckbox={fieldCheckbox}
                            fields={groupFields}
                            formik={formik}
                            onChange={onChange}
                        >
                            {({element}) => {
                                return (
                                    <div key={`div${element.id}`}>
                                        <EditSalesRulesGroupButton group={element}/>
                                        <RemoveSalesRulesGroupAction id={element.id}/>
                                    </div>
                                )
                            }}
                        </FilteringMultiSelectList>
                    </Col>
                </Row>
            </div>
        </>
    )
}

FormMaxTicketsSalesRulesGroups.propTypes = {
    formik: PropTypes.object,
    event: PropTypes.object,
};

export default FormMaxTicketsSalesRulesGroups;
