import React, {useEffect} from 'react';
import Form from "./Form";
import {connect} from "react-redux";
import {LoadingIndicator} from "../common/LoadingIndicator";
import {Col, Row} from "react-bootstrap";
import * as placePool from "../../state/entities/placePool";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";


const Edit = ({loadPlacePool, savePlacePool, placePoolId, placePool, loading, saving}) => {

    useEffect(() => {
        if (!placePool) {
            loadPlacePool(placePoolId);
        }

        // Wir wollen diesen Effekt explizit nur einmal "on-mount" durchführen, daher geben wir [] als dependency an.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (placePool, {setErrors}) => {
        savePlacePool(placePool, setErrors);
    };

    if (!placePool || loading) {
        return <LoadingIndicator/>;
    }
    return <>
        <Row className="my-4">
            <Breadcrumbs entity={["Platzpool"]} endpoints = {['place_pool/breadcrumb']} title = "Platzpools" links={['/base/place-pool/']} active={[placePool.id]} />
        </Row>
        <Row className="mb-4 mt-4">
            <Col className="pt-3 col-sm-8">
                <h1>Bearbeiten des Platzpools <em>{placePool.name}</em></h1>
            </Col>
        </Row>
        <Form placePool={placePool} onSubmit={onSubmit} submitPending={saving} />

    </>;
};


const mapStateToProps = (state, props) => {
    const placePoolId = props.match.params.spid;

    const placePool =state.entities.placePool.byId[placePoolId];

    return {
        placePoolId,
        loading: state.entities.placePool.loading[placePoolId],
        saving: state.entities.placePool.saving[placePoolId],
        placePool,
    }
};

const mapDispatchToProps = dispatch => ({
    loadPlacePool: id => dispatch(placePool.actions.load({id})),
    savePlacePool: entity => dispatch(placePool.actions.save({entity})),

});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
