import React, {useEffect} from 'react';
import Form from "./Form";
import * as zukoZone from "../../../state/entities/zukoZone";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Breadcrumbs from "../../common/breadcrumbs/Breadcrumbs";
import {LoadingIndicator} from "../../common/LoadingIndicator";

const Edit = ({loadZukoZone, saveZukoZone, venueId, zukoZone, zukoZoneId, loading, saving}) => {
    useEffect(() => {
        if (!zukoZone) {
            loadZukoZone(zukoZoneId);
        }
    }, []);

    const onSubmit = (zukoZone, {setErrors}) => {
        saveZukoZone(zukoZone, setErrors);
    };

    if (!zukoZone || loading) {
        return <LoadingIndicator/>;
    }

    return <>
        <Row className="my-4">
            <Breadcrumbs entity={["Spielstätte", "Zuko-Zonen"]} endpoints={['venue/breadcrumb', `venue/${venueId}/zuko_zone/breadcrumb`]} title="Zuko-Zonen" links={['/base/venue/', `/base/venue/${venueId}/zuko-zone/${zukoZoneId}`]} active={[venueId, zukoZoneId]}/>
        </Row>
        <Row className="mb-4 mt-4">
            <Col className="pt-3 col-sm-8">
                <h1>Erstellen einer neuen Zuko-Zone</h1>
            </Col>
        </Row>
        <Form zukoZone={zukoZone} onSubmit={onSubmit} venueId={venueId} submitPending={saving}/>
    </>;

}

const mapStateToProps = (state, props) => {
    const zukoZoneId = props.match.params.zid;
    const venueId = props.match.params.vid;

    const zukoZone = state.entities.zukoZone.byId[zukoZoneId];

    return {
        venueId,
        zukoZoneId,
        loading: state.entities.zukoZone.loading[zukoZoneId],
        saving: state.entities.zukoZone.saving[zukoZoneId],
        zukoZone,
    }
};

const mapDispatchToProps = dispatch => ({
    loadZukoZone: id => dispatch(zukoZone.actions.load({id})),
    saveZukoZone: entity => dispatch(zukoZone.actions.save({entity})),
});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
