import React, {useState} from 'react';
import {isArray} from "lodash";
import styles from "./menu.module.scss";
import {Link, useHistory, useRouteMatch} from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import classnames from 'classnames';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

/**
 * Create a new menu item instance.
 *
 * @param     {Object}      props
 * @property  {String|null} className CSS Selector classes.
 * @property  {Boolean}     active    The current state of the menu item.
 * @property  {String}      icon        The icon name.
 * @property  {String}          label      The items label.
 * @property  {JSX}         children  The items child nodes.
 * @return    {JSX}
 */
export default ({
    route,
    icon,
    handleOnClickIcon,
    label,
    children,
    setActiveCategory,
    isCategory = false,
    showIcon = false,
    forceClosed = false,
    testid
}) => {

    const match = useRouteMatch(route);
    const history = useHistory();

    const active = !!match;

    const [isExpanded, setIsExpanded] = useState(active);
    const handleOnClick = () => {
        setActiveCategory && setActiveCategory(route, setIsExpanded);
        return children ? setIsExpanded(!isExpanded) : (!isCategory && history.push(route))
    };

    if (children) {
        if (isArray(children)) {
            children = children.map((child, index) => <span key={index} className={styles.SubItem}>{child}</span>);
        } else {
            children = <span className={styles.SubItem}>{children}</span>;
        }
    }

    const LinkClasses = classnames(
        styles.Item,
        {[styles.ItemCurrent]: active},
        {[styles[testid]]: active},
    );

    const ArrowClasses = classnames(
        styles.MuiSvgIcon,
        {[styles.MuiSvgIcon__up]: isExpanded && children},
        {[styles.MuiSvgIcon__down]: !children && !isExpanded && !active},
    );

    return <>
        <Link
            className={LinkClasses}
            to={isCategory ? '#' : route}
            data-testid={testid}
        >
            {icon && (
                <IconButton onClick={() => handleOnClickIcon(setIsExpanded, route)}>
                    <img src={icon} alt={label} />
                </IconButton>
            )}
            <span onClick={handleOnClick}>{label}</span>
            {showIcon &&
                <ChevronRightOutlinedIcon onClick={handleOnClick} className={ArrowClasses} />
            }
        </Link>
        {children && isExpanded && forceClosed && children}
    </>;

};
