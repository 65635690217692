export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if(serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);

        if (localStorage.getItem('clearState') === 'true') {
            localStorage.removeItem('clearState');
            localStorage.removeItem('state');
            return;
        }

        localStorage.setItem('state', serializedState);
    } catch (err) {
        // ignore errors.
    }
}
