import * as React from 'react';
import authService from './authService';

const AuthContext = React.createContext({
    logout: () => ({}),
    isAuthenticated: () => ({}),
    signinRedirect: () => ({}),
    hasRole: (role) => ({})
});

export const AuthConsumer = AuthContext.Consumer;

export class AuthProvider extends React.Component {

    state = {isAuthenticated: false};

    constructor(props) {
        super(props);

        authService.ensureAuthentication().then(isAuthenticated => {
            this.setState({isAuthenticated});
        });
    }

    render() {
        return <AuthContext.Provider value={authService}>{this.state.isAuthenticated ? this.props.children : null}</AuthContext.Provider>;
    }
}
