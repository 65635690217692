import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from './layoutEditor.module.scss';

import interact from "interactjs";
import LayoutElement from "./element/LayoutElement";
import Toolbar, {GRID_SIZES} from "./Toolbar";
import {cloneDeep} from "lodash";
import Prototype, {AbsolutePosition} from "./Prototype";
import {Col, Row} from "react-bootstrap";
import ObjectId from "bson-objectid";
import {saveAs} from 'file-saver';

export const adjustCoordinatesByZoomLevel = (val, zoomLevel) => {
    return val / (zoomLevel / 10);
}

const LayoutEditor = ({width, height, layoutElements, setLayoutElements}) => {
    const layoutContainerRef = useRef();
    const [editModeEnabled, setEditModeEnabled] = useState(false);
    const [showElementBorder, setShowElementBorder] = useState(true);
    const [showElementToolbar, setShowElementToolbar] = useState(true);
    const [showGrid, setShowGrid] = useState(true);
    const [snapToGrid, setSnapToGrid] = useState(true);
    const [gridSize, setGridSize] = useState(GRID_SIZES[1].value);
    const [zoomLevel, setZoomLevel] = useState(10);
    const [prototypes, setPrototypes] = useState({});
    const [elements, setElements] = useState(layoutElements);
    const [selectedElements, setSelectedElements] = useState([]);

    const updateElements = useCallback((...args) => {
        setElements(...args);
        setLayoutElements(...args);
    }, [setLayoutElements]);

    const importElements = (e) => {
        const file = e.currentTarget.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsText(file, "UTF-8");
            reader.onload = function (evt) {
                const importData = JSON.parse(evt.target.result);

                if (Array.isArray(importData)
                    && importData[0].id && importData[0].type && importData[0].width && importData[0].height) {
                    updateElements(importData);
                }
            }
            reader.onerror = function (evt) {
            }
        }
    };

    const exportElements = useCallback(() => {
        saveAs(new Blob([JSON.stringify(elements)], {type: 'application/json'}), "export.json");
    }, [elements]);

    const getContainerStyles = () => {
        let styles = {
            width: width + 'mm',
            height: height + 'mm'
        };

        if (showGrid) {
            styles['backgroundSize'] = gridSize + 'px ' + gridSize + 'px';
        }

        styles["transform"] = "scale(" + (zoomLevel / 10) + ")";
        styles["transformOrigin"] = "top left";

        return styles;
    };

    const updateElement = useCallback((element) => {
        const currentElementsIds = [];
        updateElements(oldElements => {
            return oldElements.map(obj => {
                currentElementsIds.push(obj.id);

                if (obj.id === element.id) {
                    return {...element};
                }
                return {...obj};
            })
        });
        setSelectedElements(oldSelectedElements => [...oldSelectedElements.filter(elem => currentElementsIds.includes(elem))]);
    }, [updateElements]);

    const removeElement = (id) => {
        updateElements(oldElements => {
            return oldElements.filter(obj => {
                return obj.id !== id;
            })
        });
    };

    const cloneElement = useCallback((id) => {
        const prototype = cloneDeep(elements.find(obj => {
            return obj.id === id;
        }));

        prototype.id = ObjectId.generate();
        prototype.x = prototype.x + 5;
        prototype.y = prototype.y + 5;
        prototype.selected = false;

        updateElements(preElements => [...preElements, prototype]);
    }, [updateElements, elements]);

    const toggleEditMode = () => setEditModeEnabled(prevEditMove => {
        return !prevEditMove
    });

    const addElement = useCallback((prototypes, x, y, type) => {
        const containerPosition = AbsolutePosition(layoutContainerRef.current);
        const prototype = cloneDeep(prototypes[type]);

        const maxX = containerPosition.width - prototype.width;
        const maxY = containerPosition.height - prototype.height;

        x -= prototype.width / 2;
        y -= prototype.height / 2;

        x = Math.max(0, Math.min(maxX, x));
        y = Math.max(0, Math.min(maxY, y));

        prototype.id = ObjectId.generate();
        prototype.x = x;
        prototype.y = y;
        prototype.zIndex = 100;
        prototype.rotationPercent = 0;
        prototype.locked = false;

        updateElements(preElements => [...preElements, prototype]);
    }, [updateElements]);

    const toggleShowElementBorder = () => setShowElementBorder(prevState => !prevState);
    const toggleShowElementToolbar = () => setShowElementToolbar(prevState => !prevState);
    const toggleShowGrid = () => setShowGrid(prevState => !prevState);
    const toggleSnapToGrid = () => setSnapToGrid(prevState => !prevState);

    const toggleSelectedElement = useCallback((id) => {
        setSelectedElements(oldSelectedElements => {
            if (oldSelectedElements.includes(id)) {
                const newSelectedElements = [];
                for (let i = 0; i < oldSelectedElements.length; i++) {
                    if (oldSelectedElements[i] !== id) {
                        newSelectedElements.push(oldSelectedElements[i]);
                    }
                }
                return newSelectedElements;
            } else {
                return [...oldSelectedElements, id];
            }
        });
    }, []);

    const dragSelectedElements = useCallback((dx, dy, ignoreElementId) => {
        let selectedElements = [];

        setSelectedElements(oldSelectedElements => {
            selectedElements = oldSelectedElements;
            return oldSelectedElements;
        });

        updateElements(oldElements => {
            return oldElements.map(obj => {
                if (selectedElements.includes(obj.id) && obj.id !== ignoreElementId) {
                    const objectId = ObjectId.generate();
                    setSelectedElements(oldSelectedElements => {
                        const newSelectedElements = [];
                        for (let i = 0; i < oldSelectedElements.length; i++) {
                            if (oldSelectedElements[i] !== obj.id) {
                                newSelectedElements.push(oldSelectedElements[i]);
                            }
                        }

                        newSelectedElements.push(objectId);
                        return newSelectedElements;
                    });

                    return {...obj, x: obj.x + dx, y: obj.y + dy, id: objectId};
                }
                return {...obj};
            })
        });
    }, [updateElements]);

    useEffect(() => {
        const keyDownHandler = (e) => {
            // Alt+Shift+1
            if (e.altKey && e.shiftKey && e.keyCode === 49) {
                toggleEditMode();
            }
        };

        window.addEventListener('keydown', keyDownHandler, false);
        return () => window.removeEventListener('keydown', keyDownHandler, false);
    }, []);

    useEffect(() => {
        const layoutContainerRefCopy = layoutContainerRef.current;

        const prototypes = require('./prototypes.json');
        //TODO ask if we will have whitelist
        setPrototypes(prototypes);

        interact(layoutContainerRefCopy).dropzone({
            accept: `.${styles.WidgetPrototype}`,
            ondrop: function (event) {
                const dropOffset = layoutContainerRefCopy.getBoundingClientRect();

                addElement(
                    prototypes,
                    adjustCoordinatesByZoomLevel(event.dragEvent.clientX - dropOffset.left, zoomLevel),
                    adjustCoordinatesByZoomLevel(event.dragEvent.clientY - dropOffset.top, zoomLevel),
                    event.relatedTarget.getAttribute('data-type')
                );
            }
        });

        return () => {
            interact(layoutContainerRefCopy).unset();
        };
    }, [addElement, zoomLevel]);

    return <>
        <Row>
            <Col className="col-3">
                <div className={`${styles.WidgetPrototypeList}`}>
                    {Object.keys(prototypes).map((k) => <Prototype key={k} prototype={prototypes[k]}/>)}
                </div>
                <Toolbar editModeEnabled={editModeEnabled} gridSize={gridSize} showGrid={showGrid}
                         showElementBorder={showElementBorder} showElementToolbar={showElementToolbar}
                         snapToGrid={snapToGrid} zoomLevel={zoomLevel}
                         toggleEditMode={toggleEditMode} toggleShowGrid={toggleShowGrid}
                         toggleShowElementBorder={toggleShowElementBorder}
                         toggleShowElementToolbar={toggleShowElementToolbar} toggleSnapToGrid={toggleSnapToGrid}
                         importElements={importElements} exportElements={exportElements}
                         setGridSize={setGridSize} setZoomLevel={setZoomLevel}/>
            </Col>
            <Col className={`col-9 ${styles.LayoutContainerZoomWrap}`}>
                <div ref={layoutContainerRef} className={styles.LayoutContainer} style={getContainerStyles()}>
                    {elements.map(element => {
                        return (
                            <div key={element.id} onClick={(e) => {
                                !editModeEnabled && e.ctrlKey && toggleSelectedElement(element.id);
                            }}>
                                <LayoutElement
                                    zoomLevel={zoomLevel}
                                    initialElement={element}
                                    snapToGrid={snapToGrid}
                                    gridSize={gridSize}
                                    showElementBorder={showElementBorder}
                                    showElementToolbar={showElementToolbar}
                                    editModeEnabled={editModeEnabled}
                                    isSelected={(!editModeEnabled && selectedElements && selectedElements.includes(element.id)) || false}
                                    dragSelectedElements={dragSelectedElements}
                                    updateElement={updateElement}
                                    removeElement={removeElement}
                                    cloneElement={cloneElement}
                                />
                            </div>
                        );
                    })}
                </div>
            </Col>
        </Row>

    </>
};

export default LayoutEditor;



