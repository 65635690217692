import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { Field, FieldProps } from 'formik';

interface FormikCheckboxProps {
    name: string;
    label: string;
    classNameCheckbox?: string;
    icon?: string;
    helpTextsVisible?: boolean;
    helpText?: string;
    checked?: boolean;
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

export const FormikCheckbox: React.FC<FormikCheckboxProps> = ({
    name,
    label,
    classNameCheckbox,
    icon,
    helpTextsVisible,
    helpText,
    checked,
    value,
    onChange,
    disabled = false,
}) => {
    return (
        <Field name={name}>
            {({ field }: FieldProps) => (
                <div>
                    {helpTextsVisible && <p className="text-muted">{helpText}</p>}
                    <FormControlLabel
                        control={
                            <Checkbox
                                id={field.name}
                                checked={typeof checked === 'undefined' ? field.value : checked}
                                value={typeof value === 'undefined' ? field.value : value}
                                onChange={typeof onChange === 'undefined' ? field.onChange : onChange}
                                disabled={disabled}
                            />
                        }
                        label={icon ? <><img src={icon} className="payment-logo" /> {label}</> : label}
                        className={classNameCheckbox}
                    />
                </div>
            )}
        </Field>
    );
};

export default FormikCheckbox;