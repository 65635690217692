import {get, groupBy, mapValues} from "lodash";
import {entityName} from "../index";
import {createReducer} from "redux-starter-kit";
import reduceReducers from "reduce-reducers";
import * as actions from "./actions";
import * as helpers from "./helpers";

function cellMapById(state, action) {
    // Es wir auf alle actions reagiert, die eine "normalizr" reponse als payload haben mit den entsprechenden entities.
    const entities = get(action, ['payload', 'response', 'entities', entityName]);

    if (entities) {
        const cellMaps = mapValues(entities, pricingMatrix => mapValues(
            groupBy(pricingMatrix.matrixCells, 'pricingClass.id'),
            cellList => mapValues(
                groupBy(cellList, 'pricingCategory.id'),
                cellList => cellList.length > 0
                    ? {localItemTemplateId: cellList[0].localItemTemplateId, grossAmount: cellList[0].grossAmount, strikeAmount: cellList[0].strikeAmount}
                    : []
            ),
        ));

        return {
            ...state,
            ...cellMaps
        };
    }

    return state;
}

export default reduceReducers({}, cellMapById, createReducer({}, {
    [actions.cellDrop]: (state, {payload: {matrixId, classId, categoryId, cellData}}) => {
        helpers.setCellMapCell(state[matrixId], classId, categoryId, cellData);
    },
    [actions.cellClear]: (state, {payload: {matrixId, classId, categoryId}}) => {
        helpers.clearCellMapCell(state[matrixId], classId, categoryId);
    },
    [actions.changeCellGrossAmount]: (state, {payload: {matrixId, classId, categoryId, grossAmount}}) => {
        helpers.changeCellGrossAmount(state[matrixId], classId, categoryId, grossAmount);
    },
    [actions.changeCellStrikeAmount]: (state, {payload: {matrixId, classId, categoryId, strikeAmount}}) => {
        helpers.changeCellStrikeAmount(state[matrixId], classId, categoryId, strikeAmount);
    },
    [actions.classDrop]: (state, {payload: {pricingMatrix, classId, cellData}}) => {
        helpers.setCellMapClass(pricingMatrix, state[pricingMatrix.id], classId, cellData);
    },
    [actions.categoryDrop]: (state, {payload: {pricingMatrix, categoryId, cellData}}) => {
        helpers.setCellMapCategory(pricingMatrix, state[pricingMatrix.id], categoryId, cellData);
    },
    [actions.matrixDrop]: (state, {payload: {pricingMatrix, cellData}}) => {
        helpers.setCellMapMatrix(pricingMatrix, state[pricingMatrix.id], cellData);
    },

}));

