import { put, select } from "@redux-saga/core/effects";
import { Seat, SeatRecord } from "../../../seating/types";
import { PayloadAction } from "@reduxjs/toolkit";
import { determineSeatColor } from "../../../seating/editor/SeatingEditor";
import { fatalAPIError } from "./slice";
import { AxiosError } from "axios";
import { ImageData } from "../../../seating/editor/display/images/ImageData";
import { VenuePlanSettings } from "../../../seating/types/VenuePlanInformation";
import { SEAT_DEFAULT_COLOR } from "../../../seating/editor/display/scene";
import { VenuePlan } from "../../../seating/types/VenuePlan";
import { AreaFormData } from "../../../seating/editor/display/areaForms/AreaFormData";
import { IPointData, Point } from "pixi.js";



export const loadVenuePlanSaga = ({ actions, api }) => function* ({ payload: venuePlanId}: PayloadAction<string>) {
    try {
        const response: VenuePlan = yield api.getVenuePlan(venuePlanId);
        // Als payload wird eine "normalizr" response erwartet
        yield put(actions.loadVenuePlanSuccess(response));
    } catch (error) {
        yield put(actions.loadVenuePlanError({ error }));
        yield put(fatalAPIError.actions.setError((error as AxiosError).message));
    }
}


/**
 * Saga für VenuePlan Einstellungen
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export const loadVenuePlanSettingsSaga = ({ actions, api }) => function* ({ payload: venuePlanId } : PayloadAction<string>) {
    try {
        const response: VenuePlanSettings = yield api.getVenuePlanSettings(venuePlanId);
        // Als payload wird eine "normalizr" response erwartet
        yield put(actions.loadVenuePlanSettingsSuccess(response));
    } catch (error) {
        yield put(actions.loadVenuePlanSettingsError({ error }));
        yield put(fatalAPIError.actions.setError((error as AxiosError).message));
    }
};


/**
 * Saga für Event Informationen
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export const updateVenuePlanSettingsSaga = ({ actions, api }) => function* (action: PayloadAction<{venuePlanId: string, venuePlanSettings: VenuePlanSettings}>) {
    yield put(actions.setIsApiRequestPending(true));
    const {venuePlanId, venuePlanSettings} = action.payload;
    try {
        const response  = yield api.updateVenuePlanSettings(venuePlanId, venuePlanSettings);
        // Als payload wird eine "normalizr" response erwartet
        yield put(actions.updateVenuePlanSettingsSuccess(response));
    } catch (error) {
        yield put(actions.updateVenuePlanSettingsError({ error }));
        yield put(fatalAPIError.actions.setError((error as AxiosError).message));
    }
    yield put(actions.setIsApiRequestPending(false));
};


type APISeatData = {
    id: string,
    seatLabel: string,
    placePoolIds: string[],
    posX: number,
    posY: number,
    rowLabel: string,
    seatingTypeId: string,
    pricingCategoryId: string,
    blockId: string
}


const convertSeatData2Seat = (apiSeatData: APISeatData): Seat => {
    return {
        id: apiSeatData.id,
        label: apiSeatData.seatLabel,
        tags: apiSeatData.placePoolIds,
        style: 'AVAILABLE',
        color: SEAT_DEFAULT_COLOR,
        x: apiSeatData.posX,
        y: apiSeatData.posY,
        row: apiSeatData.rowLabel,
        area: "Area51",
        seatingTypeId: apiSeatData.seatingTypeId,
        pricingCategoryId: apiSeatData.pricingCategoryId,
        blockId: apiSeatData.blockId,
        enabled: true,
        available: true
    };
}

const convertSeat2SeatData = (seat: Seat): APISeatData => {
    return {
        id: seat.id,
        seatLabel: seat.label,
        placePoolIds: seat.tags,
        posX: seat.x,
        posY: seat.y,
        rowLabel: seat.row,
        seatingTypeId: seat.seatingTypeId,
        pricingCategoryId: seat.pricingCategoryId,
        blockId: seat.blockId
    }
}



/**
 * Saga für den Saalplanabruf
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export const loadAllSeatsSaga = ({ actions, api }) => function* ({ payload: venuePlanId } : PayloadAction<string>) {
    try {
        const response: Array<any> = yield api.getSeatsByVenuePlan(venuePlanId);
        // Als payload wird eine "normalizr" response erwartet
        const placepoolDefinitions = yield select(state => state.entities.venueEditor.placepoolDefinitions);
        const seats: SeatRecord = response.reduce((collection: SeatRecord, seat: APISeatData): SeatRecord => {
            const newSeat = convertSeatData2Seat(seat);
            newSeat.color = determineSeatColor(seat.placePoolIds as string[], placepoolDefinitions);
            return {
                ...collection,
                [seat.id as string]: newSeat
            }
        }, {} as SeatRecord);
        yield put(actions.loadAllSeatsSuccess(seats));
    } catch (error) {
        yield put(actions.loadAllSeatsError({ error }));
        yield put(fatalAPIError.actions.setError((error as AxiosError).message));
    }
};

/**
 * Saga zum Hinzufügen von Sitzen
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export const addSeatsSaga = ({ actions, api }) => function* ({ payload: seats } : PayloadAction<Seat[]>) {
    yield put(actions.setIsApiRequestPending(true));
    const seatsToSend: APISeatData[] = seats.map((seat) => convertSeat2SeatData(seat));
    try {
       const response = yield api.addSeatsByVenuePlan(seatsToSend);
       const newSeats = response.map((seatData: APISeatData) => convertSeatData2Seat(seatData));
       yield put(actions.addSeatsSuccess(newSeats));
    } catch (error) {
       yield put(actions.addSeatsError({ seats, error }));
       yield put(fatalAPIError.actions.setError((error as AxiosError).message));
    }
    yield put(actions.setIsApiRequestPending(false));
};


/**
 * Saga to save seats to undo history before they are updated
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export const updateSomeSeatsSaga = ({ actions, api }) => function* ({ payload: seats } : PayloadAction<Array<Seat>>) {
    const seatsToSend: APISeatData[] = seats.map(seat => convertSeat2SeatData(seat));
    try {
        const response = yield api.updateSeatsByVenuePlan(seatsToSend);
        yield put(actions.updateSomeSeatsSuccess(response));
    } catch (error) {
       yield put(actions.updateSomeSeatsError({ seats, error }));
       yield put(fatalAPIError.actions.setError((error as AxiosError).message));
    }
    yield put(actions.setIsApiRequestPending(false));
};

/**
 * Saga zum Entfernen von mehreren Sitzen
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export const deleteSeatsSaga = ({ actions, api }) => function* ({ payload: seatsToDelete } : PayloadAction<Array<Seat>>) {
    yield put(actions.setIsApiRequestPending(true));
    let seatIDsToDelete = seatsToDelete.map(seat => seat.id);
    try {
       const response = yield api.deleteSeats(seatIDsToDelete);
       yield put(actions.deleteSeatsSuccess(response));
    } catch (error) {
       yield put(actions.deleteSeatsError({ seatIDsToDelete, error }));
       yield put(fatalAPIError.actions.setError((error as AxiosError).message));
    }
    yield put(actions.setIsApiRequestPending(false));
};


/**
 * Saga für den Abruf der Placepool-Definitionen
 * 
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export const loadPlacepoolDefinitionsSaga = ({ actions, api }) => function* ({ payload: venuePlanId } : PayloadAction<string>) {
    try {
        const response = yield api.getPlacePoolsByQuery();
        yield put(actions.loadPlacepoolDefinitionsSuccess(response));
    } catch (error) {
        yield put(actions.loadPlacepoolDefinitionsError({ venuePlanId, error }));
        yield put(fatalAPIError.actions.setError((error as AxiosError).message));
    }
};

/**
 * Saga für den Abruf der PlaceCategories
 * 
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export const loadPlaceCategoriesSaga = ({ actions, api }) => function* () {
    try {
        const response = yield api.getPricingCategoriesByQuery();
        yield put(actions.loadPlaceCategoriesSuccess(response));
    } catch (error) {
        yield put(actions.loadPlaceCategoriesError({ error }));
        yield put(fatalAPIError.actions.setError((error as AxiosError).message));
    }
};

/**
 * Saga für den Abruf der SeatingTypes
 * 
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export const loadSeatingTypesSaga = ({ actions, api }) => function* () {
    try {
        const response = yield api.getSeatingTypes();
        yield put(actions.loadSeatingTypesSuccess(response));
    } catch (error) {
        yield put(actions.loadSeatingTypesError({ error }));
        yield put(fatalAPIError.actions.setError((error as AxiosError).message));
    }
};

/**
 * Saga für den Abruf der Blocks
 * 
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export const loadBlocksSaga = ({ actions, api }) => function* ({ payload: venuePlanId } : PayloadAction<string>) {
    try {
        const response = yield api.byVenuePlan(venuePlanId);
        yield put(actions.loadBlocksSuccess(response));
    } catch (error) {
        yield put(actions.loadBlocksError({ error }));
        yield put(fatalAPIError.actions.setError((error as AxiosError).message));
    }
};



type APIImageData = {
    venuePlanId: string,
    id: string,
    media: {
        id: string,
        title: string,
        url: string
    }
    width: number,
    height: number,
    rotationDegree: number,
    posX: number,
    posY: number,
    zIndex: number
}




export const convertImageData2Image = (apiImageData: APIImageData): ImageData => {
    return {
        id: apiImageData.id,
        media: apiImageData.media,
        width: apiImageData.width,
        height: apiImageData.height,
        rotation: apiImageData.rotationDegree,
        x: apiImageData.posX,
        y: apiImageData.posY,
        zIndex: apiImageData.zIndex
    };
}

export const convertImage2ImageData = (image: ImageData, venuePlanId: string = ''): APIImageData => {
    return {
        venuePlanId: venuePlanId,
        id: image.id,
        media: image.media,
        width: image.width,
        height: image.height,
        rotationDegree: (image.rotation + 360) % 360,
        posX: image.x,
        posY: image.y,
        zIndex: image.zIndex
    }
}


/**
 * Saga für den Abruf der Images
 * 
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export const loadImagesSaga = ({ actions, api }) => function* ({ payload: venuePlanId } : PayloadAction<string>) {
    yield put(actions.setIsApiRequestPending(true));
    try {
        const response = yield api.getImagesByVenuePlan(venuePlanId);
        const images: ImageData[] = response.map(image => convertImageData2Image(image));
        yield put(actions.loadImagesSuccess(images));
    } catch (error) {
        yield put(actions.loadImagesError({ error }));
        yield put(fatalAPIError.actions.setError((error as AxiosError).message));
    }
    yield put(actions.setIsApiRequestPending(false));
};


/**
 * Saga zum Hinzufügen eines Image
 * 
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export const addImageSaga = ({ actions, api }) => function* (action: PayloadAction<{venuePlanId: string, newImage: ImageData}>) {
    yield put(actions.setIsApiRequestPending(true));
    try {
        const {venuePlanId, newImage} = action.payload;
        const apiImData = convertImage2ImageData(newImage, venuePlanId);
        const response = yield api.addImageToVenuePlan(apiImData);
        const responseIm = convertImageData2Image(response);
        yield put(actions.addImagesSuccess([responseIm]));
    } catch (error) {
        yield put(actions.addImagesError({ error }));
        yield put(fatalAPIError.actions.setError((error as AxiosError).message));
    }
    yield put(actions.setIsApiRequestPending(false));
};



/**
 * Saga für den Abruf der Images
 * 
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export const updateImagesSaga = ({ actions, api }) => function* ({ payload: images } : PayloadAction<ImageData[]>) {
    yield put(actions.setIsApiRequestPending(true));
    try {
        const imagesData: Array<Record<string, any>> = images.map(image => convertImage2ImageData(image));
        const response = yield api.updateImagesOfVenuePlan(imagesData);
        yield put(actions.updateImagesSuccess(response));
    } catch (error) {
        yield put(actions.updateImagesError({ error }));
        yield put(fatalAPIError.actions.setError((error as AxiosError).message));
    }
    yield put(actions.setIsApiRequestPending(false));
};

/**
 * Saga zum Löschen von Images
 * 
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export const deleteImagesSaga = ({ actions, api }) => function* ({ payload: imagesToDelete } : PayloadAction<ImageData[]>) {
    yield put(actions.setIsApiRequestPending(true));
    try {
        const idToDelete = imagesToDelete[0].id;
         const response = yield api.deleteImageOfVenuePlan(idToDelete);
        yield put(actions.deleteImagesSuccess(response));
    } catch (error) {
        yield put(actions.deleteImagesError({ error }));
        yield put(fatalAPIError.actions.setError((error as AxiosError).message));
    }
    yield put(actions.setIsApiRequestPending(false));
};



export type APIAreaFormData = {
    id: string,
    venuePlan: string,
    blockGroups: string[],
    pricingCategory: string,
    backendNameShort: string,
    label: {
        de: string,
        en?: string
    },
    entrance: {
        de: string,
        en?: string
    },
    blockType: 'standing',
    position: IPointData,
    capacity: number,
    points: IPointData[]
    placePools: {placePool: string, capacity: number}[],
    zukoZones: string[]
}


const convertAfData2ApiAfData = (areaFormData: AreaFormData, venuePlanId: string): APIAreaFormData => {
    return {
        id: areaFormData.id,
        backendNameShort: areaFormData.shortTitle,
        blockGroups: [],
        venuePlan: venuePlanId,
        pricingCategory: areaFormData.priceCategoryId,
        label: {
            de: areaFormData.title.de,
            en: areaFormData.title?.en
        },
        entrance: {
            de: areaFormData.entranceHint.de,
            en: areaFormData.entranceHint?.en
        },
        blockType: 'standing',
        position: {x: areaFormData.x, y: areaFormData.y},
        capacity: areaFormData.capacity,
        points: areaFormData.points.map(p => ({x: p.x, y: p.y})),
        placePools: areaFormData.placePools.map(pp => ({placePool: pp.id, capacity: pp.capacity})),
        zukoZones: []
    }
}


export const convertApiAfData2AfData = (apiAreaFormData: APIAreaFormData): AreaFormData => {
    return {
        id: apiAreaFormData.id,
        title: {
            de: apiAreaFormData.label.de,
            en: apiAreaFormData.label.en
        },
        entranceHint: {
            de: apiAreaFormData.entrance.de,
            en: apiAreaFormData.entrance.en,
        },
        shortTitle: apiAreaFormData.backendNameShort,
        priceCategoryId: apiAreaFormData.pricingCategory,
        x: apiAreaFormData.position.x,
        y: apiAreaFormData.position.y,
        points: apiAreaFormData.points.map(p => new Point().copyFrom(p)),
        capacity: apiAreaFormData.capacity,
        placePools: apiAreaFormData.placePools?.map(pp => ({id: pp.placePool, capacity: pp.capacity})) ?? []
    };
}



/**
 * Saga zum Hinzufügen einer AreaForm
 * 
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export const addAreaFormSaga = ({ actions, api }) => function* (action: PayloadAction<{venuePlanId: string, newAreaForm: AreaFormData}>) {
    yield put(actions.setIsApiRequestPending(true));
    try {
        const {venuePlanId, newAreaForm} = action.payload;
        const apiAFData = convertAfData2ApiAfData(newAreaForm, venuePlanId);
        const response = yield api.createBlock(apiAFData);
        const responseAF = convertApiAfData2AfData(response);
        yield put(actions.addAreaFormSuccess(responseAF));
    } catch (error) {
        yield put(actions.addAreaFormError({ error }));
        yield put(fatalAPIError.actions.setError((error as AxiosError).message));
    }
    yield put(actions.setIsApiRequestPending(false));
};



/**
 * Saga für das Update einer AreaForm
 * 
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export const updateAreaFormsSaga = ({ actions, api }) => function* (action: PayloadAction<{venuePlanId: string, updatedAreaForms: AreaFormData[]}>) {
    yield put(actions.setIsApiRequestPending(true));
    try {
        const {venuePlanId, updatedAreaForms} = action.payload;
        const areaFormData = convertAfData2ApiAfData(updatedAreaForms[0], venuePlanId);
        const response = yield api.updateBlock(areaFormData.id, areaFormData);
        yield put(actions.updateImagesSuccess(response));
    } catch (error) {
        yield put(actions.updateImagesError({ error }));
        yield put(fatalAPIError.actions.setError((error as AxiosError).message));
    }
    yield put(actions.setIsApiRequestPending(false));
};

/**
* Saga zum Entfernen einer AreaForm
* 
*
* @param actions Aktionen für die Entität
* @param resource API Resource für die Entität
* @param schema normalizr Schema der Entitität
*/
export const deleteAreaFormSaga = ({ actions, api }) => function* ({ payload: areaFormToDelete } : PayloadAction<AreaFormData[]>) {
    yield put(actions.setIsApiRequestPending(true));
    try {
        const areaFormId = areaFormToDelete[0].id;  //only delete first one
        const response = yield api.deleteBlock(areaFormId);
        yield put(actions.deleteAreaFormsSuccess(response));
    } catch (error) {
        yield put(actions.addAreaFormError({ error }));
        yield put(fatalAPIError.actions.setError((error as AxiosError).message));
    }
    yield put(actions.setIsApiRequestPending(false));
};
