import {combineReducers} from 'redux';
import reduceReducers from "reduce-reducers";
import {all, takeEvery} from 'redux-saga/effects';

import loadingSlice from '../entity/slice/load';
import byId from '../entity/slice/byId';
import cellMapById from './cellMap/reducers';
import {dragOver} from './dragAndDrop/reducers';
import {pricingMatrix as schema} from "../../schema";
import {pricingList as schemaPricingList} from "../../schema";
import loadSaga from "../entity/saga/load";
import saveWithPricingList from "./saga/saveWithPricingList";
import Api from "../../../api";

import reducers from "./reducers";
import {createSlice} from "redux-starter-kit";

export const entityName = 'pricingMatrix';

const loadSlice = loadingSlice(entityName);

const pricingComponentsVisible = createSlice({
    slice: 'pricingMatrix',
    initialState: false,
    reducers: {
        togglePricingComponentsVisible: state => !state
    }
});

const saveWithPricingListSlice = createSlice({
    slice: 'pricingMatrix',
    initialState: {},
    reducers: {
        saveWithPricingList: (state, {payload}) =>  {
            state[payload.pricingMatrix?.id] = true;
            return state;
        },
        saveWithPricingListError: (state, {payload}) =>  {
            state[payload.pricingMatrix?.id] = false;
            return state;
        },
        saveWithPricingListSuccess: (state, {payload}) =>  {
            state[payload.response.result] = false;
            return state;
        }
    }
});
export const actions = {
    ...loadSlice.actions,
    ...pricingComponentsVisible.actions,
    ...saveWithPricingListSlice.actions
};

export const reducer = reduceReducers({},
    combineReducers({
        byId: byId(entityName),
        dragOverObjectId: dragOver,
        cellMapById: cellMapById,
        loading: loadSlice.reducer,
        savingWithPricingList: saveWithPricingListSlice.reducer,
        pricingComponentsVisible: pricingComponentsVisible.reducer
    }),
    reducers);

const api = new Api();
const resource = api.createResource(entityName);

export const saga = function* saga() {
    yield all([
        takeEvery(actions.load, loadSaga({actions, resource, schema})),
        takeEvery(actions.saveWithPricingList, saveWithPricingList({actions, api, schema, schemaPricingList})),
    ])
};
