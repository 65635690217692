import React, {useState} from 'react';
import PropTypes from "prop-types";
import styles from "../layoutEditor.module.scss";
import {Editor} from '@tinymce/tinymce-react';

export const tinyMceConfig = {
    inline: true,
    content_css: false,
    forced_root_block: 'div',
    menubar: false,
    plugins: "link lists paste table",
    paste_as_text: true,
    contextmenu: false,
    font_size_formats: "5pt 6pt 7pt 8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
    toolbar: 'table | undo redo | bold italic underline strikethrough | \
                    alignleft aligncenter alignright alignjustify | fontsize styles forecolor | bullist numlist outdent indent | link unlink | removeformat',
    style_formats: [
        {title: 'Zeilenabstand: 4pt', styles: {'line-height': '4pt'}, block: 'div'},
        {title: 'Zeilenabstand: 5pt', styles: {'line-height': '5pt'}, block: 'div'},
        {title: 'Zeilenabstand: 6pt', styles: {'line-height': '6pt'}, block: 'div'},
        {title: 'Zeilenabstand: 8pt', styles: {'line-height': '8pt'}, block: 'div'},
        {title: 'Zeilenabstand: 10pt', styles: {'line-height': '10pt'}, block: 'div'},
        {title: 'Zeilenabstand: 12pt', styles: {'line-height': '12pt'}, block: 'div'},
        {title: 'Zeilenabstand: 14pt', styles: {'line-height': '14pt'}, block: 'div'},
        {title: 'Zeilenabstand: 16pt', styles: {'line-height': '16pt'}, block: 'div'},
        {title: 'Zeilenabstand: 18pt', styles: {'line-height': '18pt'}, block: 'div'},
        {title: 'Zeilenabstand: 20pt', styles: {'line-height': '20pt'}, block: 'div'}
    ],
};

const Template = ({element, editModeEnabled, updateElement}) => {
    const [contentEditor, setContentEditor] = useState(element.template);

    if (!editModeEnabled) {
        return <div dangerouslySetInnerHTML={{__html: contentEditor}} className={`${styles.LayoutElementInner}`}></div>;
    }

    const handleEditorChange = (content, editor) => {
        setContentEditor(content);
    }

    const onBlur = () => {
        updateElement((prevElem) => ({...prevElem, template: contentEditor}));
    }

    return <div className={`${styles.LayoutElementInner}`}>
        <Editor
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/6.1.2/tinymce.min.js'}
            disabled={!editModeEnabled}
            init={tinyMceConfig}
            value={contentEditor}
            onEditorChange={handleEditorChange}
            onBlur={onBlur}
        />
    </div>;
};

Template.propTypes = {
    element: PropTypes.object.isRequired,
    editModeEnabled: PropTypes.bool,
    updateElement: PropTypes.func.isRequired,
};

export default Template;
