import * as types from './types';

export const queryListView = (endpoint, query) => {
    return {type: types.QUERY_LIST_VIEW, payload: {endpoint, query}};
};
export const queryListViewSuccess = (endpoint, query, result) => {
    return {type: types.QUERY_LIST_VIEW_SUCCESS, payload: {endpoint, query, result}};
};
export const queryListViewError = (endpoint, query) => {
    return {type: types.QUERY_LIST_VIEW_ERROR, payload: {endpoint, query}};
};
