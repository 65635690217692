import {createAction} from "redux-starter-kit";

export const addPricingClass = createAction('pricingMatrix/addPricingClass');
export const removePricingClass = createAction('pricingMatrix/removePricingClass');
export const addPricingCategory = createAction('pricingMatrix/addPricingCategory');
export const removePricingCategory = createAction('pricingMatrix/removePricingCategory');


export const addPurchasableItemTemplate = createAction('pricingMatrix/addPurchasableItemTemplate');
export const clearUnusedPurchasableItemTemplates = createAction('pricingMatrix/clearUnusedPurchasableItemTemplates');
export const clearAllPrices = createAction('pricingMatrix/clearAllPrices');

export const changeLocalItemTemplate = createAction('pricingMatrix/changeLocalItemTemplate');
export const toggleEditLocalItemTemplate = createAction('pricingMatrix/toggleEditLocalItemTemplate');

export const toggleIncludeStrikeAmount = createAction('pricingMatrix/toggleIncludeStrikeAmount');
