import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {FormikTextInputGroup} from '../common/formik/FormikTextInputGroup';
import FormikCheckbox from "../common/formik/FormikCheckbox";
import BundleFormSalesRule from "./BundleFormSalesRule";
import ObjectId from "bson-objectid";
import {getIn} from "formik";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import AddIcon from '@mui/icons-material/Add';
import FeedbackButton from "../common/FeedbackButton";

const BundleForm = ({element, associationFormik, parentFormik}) => {
    const nameOfValueSelectedSalesRule = 'bundle_selected_sales_rule';
    const [isLoading, setLoading] = useState(false);
    const [bundleSalesRules, setBundleSalesRules] = useState([]);
    const [availableSalesRules, setAvailableSalesRules] = useState([]);

    useEffect(() => {
        onSearchAvailableEventSalesRules();
    }, []);

    useEffect(() => {
        setBundleSalesRules(getIn(associationFormik.values, 'salesRules', []));
        onSearchAvailableEventSalesRules();
    }, [element, associationFormik.values, element.salesRules]);

    function onSearchAvailableEventSalesRules() {
        setLoading(true);
        let usedSalesRulesInOneArray = [];

        parentFormik.values.bundles.forEach((bundle) => {
            if (bundle.id !== element.id) {
                bundle.salesRules.forEach(salesRule => {
                    usedSalesRulesInOneArray.push(salesRule.eventSalesRuleId);
                })
            }
        });

        if (typeof associationFormik.values.salesRules !== "undefined") {
            associationFormik.values.salesRules.forEach(salesRule => {
                usedSalesRulesInOneArray.push(salesRule.eventSalesRuleId);
            })
        }

        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }

        usedSalesRulesInOneArray = usedSalesRulesInOneArray.filter(onlyUnique);

        // get sales rules from event that are not used on bundles
        setAvailableSalesRules(parentFormik.values.salesRules.filter(item => !usedSalesRulesInOneArray.includes(item.id)));
        setLoading(false);
    }

    const addSalesRule = () => {
        let selectedSalesRule = getIn(associationFormik.values, nameOfValueSelectedSalesRule, null);
        if (selectedSalesRule) {
            associationFormik.setFieldValue('salesRules',
                [...bundleSalesRules,
                    {
                        id: ObjectId.generate(),
                        eventSalesRuleId: selectedSalesRule.id,
                        salesRuleName: selectedSalesRule.salesRuleName,
                        minTickets: 1,
                        maxTickets: 1,
                    }
                ]
            );

            associationFormik.setFieldValue(nameOfValueSelectedSalesRule, null);
        }
    }

    const removeSalesRuleHandle = (salesRuleToRemove) => {
        let oldSalesRules = getIn(associationFormik.values, 'salesRules', []);
        let salesRules = oldSalesRules.filter(s => s.id !== salesRuleToRemove.id);
        salesRules = [...salesRules];

        associationFormik.setFieldValue('salesRules', salesRules);
    }

    const getSalesRuleNameByEventSalesRuleId = (eventSalesRuleId) => {
        if (typeof parentFormik.values.salesRules !== "undefined") {
            for (let salesRule of parentFormik.values.salesRules) {
                if (salesRule.id === eventSalesRuleId) {
                    return salesRule.salesRuleName;
                }
            }
        }
        return 'Sales Rule';
    }

    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        <FormikTextInputGroup label="Bundlename" name="name" type="text" testid="name"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row className="mb-3">
                            <Col className="salesRules">
                                {bundleSalesRules.map((item, index) => {
                                    return <BundleFormSalesRule
                                        key={item.id}
                                        index={index}
                                        associationFormik={associationFormik}
                                        element={item}
                                        removeSalesRuleHandle={() => removeSalesRuleHandle(item)}
                                        salesRuleName={getSalesRuleNameByEventSalesRuleId(item.eventSalesRuleId)}
                                    />
                                })}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col className="col-md-12">
                                <Form.Group controlId={nameOfValueSelectedSalesRule} data-testid={nameOfValueSelectedSalesRule} className='MuiFormControl-root-custom'>
                                    <Form.Label>Verkaufsregel</Form.Label>
                                    <AsyncTypeahead
                                        id={nameOfValueSelectedSalesRule}
                                        inputProps={{id: nameOfValueSelectedSalesRule, name: nameOfValueSelectedSalesRule, autoComplete: "on"}}
                                        placeholder="Select sales rule"
                                        options={availableSalesRules}
                                        onChange={(el) =>{associationFormik.setFieldValue(nameOfValueSelectedSalesRule, el[0] || null)}}
                                        onSearch={onSearchAvailableEventSalesRules}
                                        isLoading={isLoading}
                                        labelKey={o => o.salesRule.name}
                                        selected={getIn(associationFormik.values, nameOfValueSelectedSalesRule) ? [getIn(associationFormik.values, nameOfValueSelectedSalesRule)] : []}
                                        minLength={0}
                                    />
                                </Form.Group>
                                <FeedbackButton
                                    className="pull-right mb-3"
                                    onClick={() => addSalesRule()}
                                    icon={<AddIcon />}
                                >
                                    Verkaufsregel hinzufügen
                                </FeedbackButton>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col-md-12">
                        <p className="text-danger">Tickets können nur gekauft werden, wenn alle dieser Regeln erfüllt werden.</p>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-md-12">
                        <h3>Öffentliche Beschreibung</h3>
                        <p>Dieser Text wird bei der Auswahl von Plätzen, die mit dieser Bedingung verkauft werden, in der Kaufstrecke angezeigt.</p>
                    </Col>
                    <Col className="col-md-6">
                        <FormikTextInputGroup type="textarea" label="DE" name="description.de" testid="descriptionDe"/>
                    </Col>
                    <Col className="col-md-6">
                        <FormikTextInputGroup type="textarea" label="EN" name="description.en" testid="descriptionEn"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormikCheckbox label="Checkbox aktivieren" name="enabled"/>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
};

BundleForm.propTypes = {
    associationFormik: PropTypes.object,
    element: PropTypes.object,
    salesRulesFromEvent: PropTypes.array
};

export default BundleForm;
