import React, { useCallback, useEffect, useState } from 'react';
import classnames from "classnames";
import * as yup from 'yup';
import { Formik } from 'formik';
import { Col, Form as BootstrapForm, Row } from 'react-bootstrap';
import { FormikTextInputGroup } from '../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../common/FeedbackButton';
import { connect } from "react-redux";
import Footer from "../layout/Footer";
import LayoutEditor from "../layoutEditor/LayoutEditor";
import Api from "../../api";
import FormikSelect from "../common/formik/FormikSelect";
import { forOwn } from "lodash";
import ConfirmModal from "../common/modal/ConfirmModal";
import styles from "../form.module.scss";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const api = new Api();

const getInitialValues = () => (
    {
        name: '',
        width: 210,
        height: 297,
        type: '',
        elements: [],
        archived: false
    }
);

const FORM_SCHEMA = yup.object().shape({
    name: yup.string().required(),
});

const Form = ({ ticketLayout, onSubmit, submitPending, helpTextsVisible }) => {
    const [layoutElements, setLayoutElements] = useState(ticketLayout.elements ?? []);
    const [previewBusy, setPreviewBusy] = useState(false);
    const [config, setConfig] = useState({types: []});
    const [ticketLayoutTypes, setTicketLayoutTypes] = useState([]);
    const [ticketLayoutType, setTicketLayoutType] = useState(ticketLayout.type);
    const [isArchived, setIsArchived] = useState(ticketLayout.archived);
    const [eventsOptions, setEventsOptions] = useState(null);
    const [previewError, setPreviewError] = useState(null);
    const [currentEventId, setCurrentEventId] = useState(null);

    useEffect(() => {
        api.ticketLayoutConfig().then(result => {
            setConfig({...result, pending: false});
        }).catch();

        api.getEventsAutocomplete().then(res => setEventsOptions(res));

    }, []);

    useEffect(() => {
        const types = [];
        if (config.types) {
            forOwn(config.types, function (value, key) {
                types.push({
                    id: key,
                    name: value.label,
                });
            });
        }
        setTicketLayoutTypes(types);
    }, [config]);

    const handleSubmit = (ticketLayout, options) => {
        onSubmit({...ticketLayout, elements: layoutElements}, options);
    };

    const handlePreview = useCallback(() => {
        setPreviewError(null);
        if (!currentEventId) {
            setPreviewError('Wählen Sie ein Ereignis für die Vorschau');
            return;
        }

        setPreviewBusy(true);
        api.previewTicketLayout({...ticketLayout, type: ticketLayoutType, elements: layoutElements}, currentEventId)
            .then(result => {
                setPreviewBusy(false);

                const file = new Blob([result.data], {type: "application/pdf"});
                const fileURL = URL.createObjectURL(file);
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
            }).catch(() => {
            setPreviewBusy(false);
        });
    }, [ticketLayout, layoutElements, ticketLayoutType, currentEventId]);

    const ArchivedStatus = ({setFieldValue}) => {
        const confirmChangeStatus = () => () => {
            let newStatus = !isArchived
            setIsArchived(newStatus);
            setFieldValue('archived', newStatus);
        };
        return (
            <ConfirmModal title="Status ändern"
                          body={(isArchived) ? `Ticketlayout wieder reaktivieren?`: `Ticketlayout ins Archiv verschieben?`}
                          cancelLabel="Abbrechen"
                          confirmLabel="Bestätigen"
            >
                {confirm => (
                    <BootstrapForm.Check type="checkbox"
                                         id="archived"
                                         label="Ticketlayout archivieren"
                                         onChange={(e) => confirm(confirmChangeStatus(e))}
                                         name="archived"
                                         checked={isArchived}
                    />
                )}
            </ConfirmModal>
        )
    }

    return <Formik
        initialValues={{...getInitialValues(), ...ticketLayout}}
        validationSchema={FORM_SCHEMA}
        onSubmit={handleSubmit}
    >
        {formik => {
            if (!formik.values.type) {
                return <>
                    <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                        <div className={styles.formBox}>
                            <h2 className={styles.formTitle}>Allgemein</h2>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikSelect name="type" label="Papierformat" options={ticketLayoutTypes} />
                                </Col>
                            </Row>
                        </div>
                        <Footer>
                            <FeedbackButton to={`/base/ticket-layout`}>
                                Abbrechen
                            </FeedbackButton>
                        </Footer>
                    </form>
                </>;
            }

            if (formik.values.type && ticketLayoutType !== formik.values.type) {
                setTicketLayoutType(formik.values.type);
            }

            if (formik.values.type) {
                const typeConfig = config.types[formik.values.type];

                if (typeConfig && formik.values.width !== typeConfig.width) {
                    formik.setFieldValue('width', typeConfig.width);
                }

                if (typeConfig && formik.values.height !== typeConfig.height) {
                    formik.setFieldValue('height', typeConfig.height);
                }
            }

            return (
                <form onSubmit={formik.handleSubmit} className={classnames(styles.formCotainer, styles.ticketLayoutForm)}>
                    <div className={styles.formBox}>
                        <Row>
                            <Col className="col-md-4">
                                <h2 className={styles.formTitle}>Allgemein</h2>
                            </Col>
                            <Col className="col-md-4">
                                <h2 className={styles.formTitle}>Vorschau mit Beispieldaten</h2>
                            </Col>
                        </Row>
                        
                        <Row className="mt-3">
                            <Col className="col-md-4">
                                <FormikSelect
                                    name="type"
                                    label="Papierformat"
                                    options={ticketLayoutTypes}
                                    disabled={formik.values.id}
                                />
                            </Col>
                            <Col className='col-md-4'>
                                <FormikSelect
                                    label="Event zur Darstellung von Beispieldaten wählen"
                                    name="currentEventId"
                                    options={eventsOptions}
                                    emptyValueLabel="Bitte wählen ..."
                                    onChange={(value) => setCurrentEventId(value)}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col className="col-md-4">
                                <FormikTextInputGroup
                                    label="Titel"
                                    name="name"
                                    helpTextsVisible={helpTextsVisible}
                                    helpText="Titel des Ticketlayouts"
                                />
                            </Col>
                            <Col className='col-md-4 align-self-center'>
                                <FeedbackButton
                                    className="preview-button"
                                    busy={previewBusy}
                                    variant="outlined"
                                    icon={<PictureAsPdfIcon />}
                                    onClick={() => handlePreview()}
                                >
                                    Vorschau
                                </FeedbackButton>
                                {!!previewError && <div className="text-danger">{previewError}</div>}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-md-4">
                                <ArchivedStatus setFieldValue={formik.setFieldValue}/>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col className="col-md-10">
                                <LayoutEditor width={formik.values.width}
                                                height={formik.values.height}
                                                layoutElements={layoutElements}
                                                setLayoutElements={setLayoutElements}/>
                            </Col>
                            <Col className="col-md-2">
                                Hier können Sie die Vorlage für alle Dokumente dieses Typs anpassen.
                                In Texten stehen Ihnen folgende Platzhalter zur Verfügung:
                                {config.types[formik.values.type] &&
                                    <div className="mt-2">
                                        {config.types[formik.values.type].placeholders.map(value =>
                                            <span> {'{{'}{value}{'}}'} </span>
                                        )}
                                    </div>}
                            </Col>
                        </Row>
                    </div>
                    <Footer>
                        <FeedbackButton to={`/base/ticket-layout`}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton
                            type="submit"
                            busy={submitPending}
                        >
                            Speichern
                        </FeedbackButton>
                    </Footer>
                </form>
            )
        }}
    </Formik>
};


Form.propTypes = {
    onSubmit: PropTypes.any,
    submitPending: PropTypes.any,
    blockGroup: PropTypes.any,
    requestErrors: PropTypes.any
};

const mapStateToProps = (state, props) => {

    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps, null)(Form);



