import React from 'react';
import Zoom from 'react-medium-image-zoom'
import ImagePreview from '../ImagePreview/ImagePreview';
import MuiBoxLikeFormField from '../MuiBoxLikeFormField/MuiBoxLikeFormField';
import HelpContent from '../help/HelpContent';
import styles from './FieldImage.module.scss'


export interface FieldValuesProps {
    url: string;
    title: string;
    id: string;
}
interface FieldImageProps {
    label: string;
    placeholder?: string;
    fieldName: string;
    helpText?: string | null;
    values: FieldValuesProps;
    onHandleShowModal: (e: React.MouseEvent<HTMLButtonElement>, fieldName: string) => void;
    onHanldleRemove: (e: React.MouseEvent<HTMLButtonElement>, fieldName: string) => void;
}

export const FieldImage: React.FC<FieldImageProps> = ({
    values,
    label,
    fieldName = '',
    placeholder = 'Bitte wählen...',
    helpText = null,
    onHandleShowModal,
    onHanldleRemove,
}) => {
    return (
        <>
            <span className={styles.label}>{label}</span>
            {helpText && <HelpContent>{helpText.split('\n').map((item, i) => <p className="text-muted" key={i}>{item}</p>)}</HelpContent>}  
            <div className={styles.wrap}>
                {values && (
                    <Zoom>
                        <ImagePreview src={values?.url} width='56px' height='56px' fullSize />
                    </Zoom>
                )}
                <MuiBoxLikeFormField
                    value={values?.title}
                    mediaId={values?.id}
                    placeholder={placeholder}
                    fieldClassName={styles.fieldValue}
                    onHandleShowModal={(e) => onHandleShowModal(e, fieldName)}
                    onHanldleRemove={(e) => onHanldleRemove(e, fieldName)}
                    showCloseIcon
                />
            </div>
        </>
    )
};

export default FieldImage
