import React from 'react';
import styles from "../layoutEditor.module.scss";
import barcode from './img/barcode.svg';
import PropTypes from "prop-types";

const TicketBarcode = ({ element, editModeEnabled }) => {
    return <div className={`${styles.LayoutElementInner} ${!editModeEnabled ? "interactive" : ""}`}>
        <img src={barcode} alt={"Barcode"} style={{
            maxWidth: element.width + "px",
            maxHeight: element.height + "px",
        }} />
    </div>;
};

TicketBarcode.propTypes = {
    element: PropTypes.object.isRequired,
    editModeEnabled: PropTypes.bool,
};

export default TicketBarcode;
