import React, {Component} from 'react';
import * as yup from 'yup';
import {Formik} from 'formik';
import {Card, Col, Row} from 'react-bootstrap';
import {FormikTextInputGroup} from '../../../../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../../../../common/FeedbackButton';
import FormikObjectSelectMui from '../../../../common/formik/FormikObjectSelectMui';
import {connect} from "react-redux";
import {actions as SeatingTypeActions} from "../../../../../state/entities/seatingType";
import FormikAsyncTypeaheadInput from "../../../../common/formik/FormikAsyncTypeaheadInput";
import MultiSelectList from "../../../../common/form/MultiSelectList";
import Api from '../../../../../api';
import BackButton from "../../../../common/BackButton";
import Footer from "../../../../layout/Footer";
import styles from "../../../../form.module.scss";

const api = new Api();

const getInitialValues = (block) => ({
    pricingCategory: '',
    seatLabel: '',
    rowLabel: '',
    posX: '',
    posY: '',
    seatingType: '',
    block: block,
    placePools: []
});

const FORM_SCHEMA = yup.object().shape({
    seatingType: yup.object().required(),
});


class FormEdit extends Component {
    componentDidMount() {
        this.props.loadSeatingType();
    }

    render() {
        let {onSubmit, submitPending, seat, placePools, seatingTypes, block, helpTextsVisible} = this.props;
        const placePoolSelectFieldMap = {
            "name": 'Titel'
        };

        return <Formik initialValues={{...getInitialValues(block), ...seat}}
                       validationSchema={FORM_SCHEMA}
                       onSubmit={onSubmit}
        >
            {formik => {
                const handlePlacePoolSelectionChange = (placePoolSelection) => {
                    const selectedPlacePools = placePoolSelection.filter(selection => typeof (selection) === 'object');
                    formik.setFieldValue("placePools", selectedPlacePools);
                };

                const activePlacePools = placePools.filter(function (item)  {
                     return !item.archived || seat.placePools.find(el => el.id === item.id)
                    });

                return (
                    <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                        <div className={styles.formBox}>
                            <h2 className={styles.formTitle}>Allgemein</h2>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikAsyncTypeaheadInput
                                        id="pricingCategory"
                                        label="Preiskategorie"
                                        onSearch={query => api.getPricingCategoriesByQuery(query)}
                                        labelKey={o => o.name}
                                        minLength={0}
                                        testid="pricingCategory"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Reihenbezeichnung*" name="rowLabel" testid="fromRow"/>
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Sitznummerbezeichnung*" name="seatLabel" testid="fromSeat"/>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikObjectSelectMui
                                        name="seatingType"
                                        label="Bestuhlungstyp"
                                        options={seatingTypes}
                                        testid="seatingType"
                                        emptyValueLabel="Bitte wählen ..."
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="X-Platzkoordinate" name="posX" testid="posX"
                                                            helpTextsVisible={helpTextsVisible} helpText="Zur Zuordnung der Position des Platzes."/>
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Y-Platzkoordinate" name="posY" testid="posY"
                                                            helpTextsVisible={helpTextsVisible} helpText="Zur Zuordnung der Position des Platzes."/>
                                </Col>
                            </Row>
                        </div>
                        <div className={styles.formBox}>
                            <h2 className={styles.formTitle}>Platzpools</h2>
                            <Row className="mt-3">
                                <Col className="col-md-8">
                                    <MultiSelectList
                                        fieldMap={placePoolSelectFieldMap}
                                        elements={activePlacePools}
                                        selected={seat.placePools}
                                        onChange={handlePlacePoolSelectionChange}
                                    />

                                </Col>
                            </Row>
                        </div>
                        <Footer>
                            {/*
                            FIXME: auf Grund der aktuellen Routenstruktur ist es hier nicht möglich den Link Button
                            zu verwenden um in die Edit-Seite eines Saalplans/Listing der Blockgruppen zu gelangen,
                            da die Venue-ID hier nicht bekannt ist und sowohl die VenuePlan-ID als auch die
                            BlockGroup-ID aus der URL gepuhlt werden.
                            Der Browserback ist nicht in allen Fällen korrekt (bspw. nicht bei externen Einsprüngen)
                            und die Problematik sollte im Zuge der "Breadcrums" behoben werden.
                            */}
                            {/*<LinkButton variant="link" to={{pathname: `/base/venue/${venueId}/venue-plan/${venuePlanId}`}} >*/}
                            {/*    Abbrechen*/}
                            {/*</LinkButton>*/}
                            <BackButton>
                                Abbrechen
                            </BackButton>
                            <FeedbackButton
                                type="submit"
                                busy={submitPending}
                            >
                                Speichern
                            </FeedbackButton>
                        </Footer>
                    </form>
                )
            }}
        </Formik>
    }
}

FormEdit.propTypes = {
    onSubmit: PropTypes.any,
    submitPending: PropTypes.any,
    seat: PropTypes.any,
    requestErrors: PropTypes.any
};

export default connect((state, ownProps) => {
    let seatingTypes = Object.values(state.entities.seatingType.byId);
    const helpTextsVisible = state.helpTextsToggle;
    return {
        seatingTypes,
        helpTextsVisible
    }
}, {
    loadSeatingType: SeatingTypeActions.loadAll
})(FormEdit)



