import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import { findIndex, get, isEmpty, some } from 'lodash';
import ListViewPaginationControl from './ListViewPaginationControl';
import { ItemField, ListItem } from './ListViewPropTypes';
import ListViewRow from './ListViewRow';
import ListViewSearchBar from './ListViewSearchBar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ListViewColumnFilterConfig from './ListViewColumnFilterConfig';
import Popover from 'react-bootstrap/Popover';
import { Checkbox } from '@mui/material';
import classnames from 'classnames';
import { Card, Col, Form, Row } from "react-bootstrap";
import { LoadingIndicator } from "../LoadingIndicator";
import ListViewBatchActions from "./ListViewBatchActions";
import FeedbackButton from "../FeedbackButton";
import { IconButton } from '@mui/material';
import styles from './listView.module.scss';
import AddIcon from '@mui/icons-material/Add';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';

class ListView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allChecked: false,
            prevDeletingState: false,
            selectedElements: [],
            columnVisibility: {}
        }

        this.resetSelectedElements = this.resetSelectedElements.bind(this);
    }

    // Methods for selection elements
    isSelected = element => {
        return some(this.state.selectedElements, e => e.id === element.id)
    };

    selectElement = element => {
        const newSelection = [...this.state.selectedElements, element];
        this.setState({
            selectedElements: newSelection,
        })
    };
    deselectElement = element => {
        const i = findIndex(this.state.selectedElements, e => e.id === element.id);
        const newSelection = [...this.state.selectedElements.slice(0, i), ...this.state.selectedElements.slice(i + 1)];
        this.setState({
            selectedElements: newSelection,
            allChecked: false,
        })
    };

    toggleElement = element => this.isSelected(element)
        ? this.deselectElement(element)
        : this.selectElement(element);

    selectAll = () => {
        const items = this.props.onDataLoaded? this.props.onDataLoaded(this.props.items) : this.props.items;
        this.setState({
            selectedElements: items.filter(el => {return el.hardcoded === undefined || !el.hardcoded}),
        })
    };

    deselectAll = () => {
        this.setState({
            selectedElements: [],
        })
    };

    reloadAfterDelete = isDeleting => {
        if (isDeleting === true && this.state.prevDeletingState === false) {
            this.setState({
                prevDeletingState: true,
            })
        } else if (isDeleting === false && this.state.prevDeletingState === true) {
            this.setState({
                prevDeletingState: false,
            })
            this.props.reloadView();
        }
    };

    toggleAllVisible = () => {
        if (!this.state.allChecked) {
            this.selectAll();
        } else {
            this.deselectAll();
        }

        this.setState({
            allChecked: !this.state.allChecked
        })
    };

    toggleColumnVisibility = (e, fieldName) => {
        e.stopPropagation();
        this.setState((prevState) => ({
            columnVisibility: {
                ...prevState.columnVisibility,
                [fieldName]: !prevState.columnVisibility[fieldName],
            },
        }));
    };

    resetSelectedElements() {
        this.setState({selectedElements: [], allChecked: false});
    }

    toggleSort(e, fieldName) {
        e.stopPropagation();
        if (!this.props.onSortChange) {
            return;
        }

        let field = get(this.props.sort, 'field');
        let ascending = get(this.props.sort, 'ascending');

        // es soll nach einem anderen Feld sortiert werden
        if (field !== fieldName) {
            field = fieldName;
            ascending = true;
        }
        // aktuell wird absteigend sortiert -> Sortierung aufheben
        else if (!ascending) {
            field = undefined;
            ascending = undefined;
        }
        // Reihenfolge umkehren
        else {
            ascending = !ascending;
        }

        this.props.onSortChange({field, ascending});
    }

    getSortToggleClassnames(fieldName) {
        const field = get(this.props.sort, 'field');
        const ascending = get(this.props.sort, 'ascending');

        if (field === fieldName) {
            return ascending ? (
                <ArrowUpwardOutlinedIcon className={styles.arrowSortIconActive} onClick={(e) => this.toggleSort(e, fieldName)} />
            ) : (
                <ArrowDownwardOutlinedIcon className={styles.arrowSortIconActive} onClick={(e) => this.toggleSort(e, fieldName)} />
            );
        } else {
            return <ArrowUpwardOutlinedIcon className={styles.arrowSortIcon} onClick={(e) => this.toggleSort(e, fieldName)} />
        }
    };

    handleQueryChange = (query) => {
        if (!this.props.onFilterChange) {
            return;
        }

        if (isEmpty(query)) {
            this.props.onFilterChange({
                ...this.props.filter,
                $text: undefined,
            })
        } else {
            this.props.onFilterChange({
                ...this.props.filter,
                $text: query,
            });
        }
    };

    handleFilterChange(field, filter) {
        if (!this.props.onFilterChange) {
            return;
        }

        this.props.onFilterChange({
            ...this.props.filter,
            [field.name]: filter,
        });
    }

    isPaginationNecessary() {
        return this.props.pagination.totalPages > 1;
    }

    showAllHandle() {
        if (!this.props.showAllHandle) {
            return;
        }
        this.props.showAllHandle();
    }
    render() {
        this.reloadAfterDelete(this.props.isDeleting);
        const items = this.props.onDataLoaded? this.props.onDataLoaded(this.props.items) : this.props.items;
        if(!items) {
            return <LoadingIndicator/>;
        }
        const {heading, testid, card_testid, linkNearHeading} = this.props;

        return (
            <>
                <Card data-testid={card_testid} className={classnames(styles.ListViewLayout, this.props.className)}>
                    <div className={classnames({ [styles.formBox]: heading || linkNearHeading || this.props.buttonTitle } )}>
                        {heading && <Row><Col sm={linkNearHeading ? 4 : 8} className={styles.heading}><h1>{heading}</h1></Col></Row>}
                        {linkNearHeading && linkNearHeading()}
                        {this.props.buttonTitle && (
                            <Row>
                                <Col className={`${styles.addBtn} col-md-12`}>
                                    <FeedbackButton
                                        to={this.props.buttonLink}
                                        data-testid={testid}
                                        icon={<AddIcon />}
                                        variant='contained'
                                    >
                                        {this.props.buttonTitle}
                                    </FeedbackButton>
                                </Col>
                            </Row>
                        )}
                    </div>
                    <div className={classnames('data-table', styles.dataTable, {loading: this.props.loading})}>
                        <ListViewSearchBar onQueryChange={this.handleQueryChange} />
                        <div className='table-responsive'>
                            <Table className={styles.TableWrap} striped bordered hover>
                                <thead className={styles.TableHead}>
                                <tr>
                                    {!!this.props.isShowSelectionWithBatchActions && (
                                        <th>
                                            <Checkbox
                                                id='list-view-check-all'
                                                checked={this.state.allChecked}
                                                onChange={this.toggleAllVisible}
                                            />
                                        </th>
                                    )}
                                    {this.props.fields.map(field =>
                                        <th key={field.name} className={classnames({[styles.ListViewItemHead]: this.state.columnVisibility[field.name]})}>
                                            <OverlayTrigger
                                                trigger="click"
                                                placement="top"
                                                rootClose={true}
                                                overlay={<Popover>
                                                    <Popover.Body>
                                                        <ListViewColumnFilterConfig
                                                            field={field}
                                                            filter={this.props.filter[field.name]}
                                                            onFilterChange={filter => this.handleFilterChange(field, filter)}
                                                        />
                                                        <IconButton className={styles.MuiIconButtonClose} onClick={() => document.body.click()}>
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Popover.Body>
                                                </Popover>}
                                            >
                                                <span className="d-flex align-items-center">
                                                    {this.state.columnVisibility[field.name] ? (
                                                        <span title='Artikel anzeigen'>
                                                            <VisibilityOffOutlinedIcon
                                                                className={classnames(styles.eyeIcon, styles.eyeIconActive)}
                                                                onClick={(e) =>
                                                                    this.toggleColumnVisibility(e, field.name)
                                                                }
                                                            />
                                                        </span>
                                                    ) : (
                                                        <>                                       
                                                            <span className="text-truncate">{field.label || field.name}</span>
                                                            <span title='Elemente filtern'>
                                                                <FilterAltOutlinedIcon className={classnames(styles.filterIcon, {[styles.filterIconActive]: this.props.filter[field.name]})} />
                                                            </span>
                                                            <span title='Artikel sortieren'>
                                                                {this.getSortToggleClassnames(field.name)}
                                                            </span>
                                                            <span title='Elemente ausblenden'>
                                                                <RemoveRedEyeOutlinedIcon
                                                                    className={styles.eyeIcon}
                                                                    onClick={(e) =>
                                                                        this.toggleColumnVisibility(e, field.name)
                                                                    }
                                                                />
                                                            </span>
                                                        </>
                                                    )}
                                                </span>
                                            </OverlayTrigger>
                                        </th>
                                    )}
                                    {this.props.children && <th>Aktionen</th>}
                                </tr>
                                </thead>
                                <tbody className={styles.TableBody}>
                                    {items.map(item =>
                                        <ListViewRow
                                            key={item.id}
                                            fields={this.props.fields}
                                            item={item}
                                            children={this.props.children}
                                            isSelected={this.isSelected(item)}
                                            toggleElement={() => this.toggleElement(item)}
                                            columnVisibility={this.state.columnVisibility}
                                            isShowSelection = {!!this.props.isShowSelectionWithBatchActions}
                                        />
                                    )}
                                </tbody>
                            </Table>
                        </div>

                        {this.props.loading && (
                            <div className="busy-overlay">
                                <span className="busy-indicator">
                                    <i className="fa fa-fw fa-circle-o-notch fa-spin"></i>
                                    <span>Daten werden geladen &hellip;</span>
                                </span>
                            </div>
                        )}
                        {!(this.props.loading || items.length > 0) && (
                            <div className="no-results-overlay">
                                <span className="busy-indicator">
                                    <InfoIcon className='table-icon' />
                                    <span>Keine Ergebnisse</span>
                                </span>
                            </div>
                        )}
                    </div>
                </Card>
                {!!this.props.isShowSelectionWithBatchActions && this.props.allowedActions.length !== 0 && (
                    <div className={styles.batchActionsWrap}>
                        <Checkbox
                            id='list-view-check-all-bottom'
                            checked={this.state.allChecked}
                            onChange={this.toggleAllVisible}
                        />
                        <ListViewBatchActions
                            closeModal={this.closeModal}
                            selectedElements={this.state.selectedElements}
                            reloadView={this.props.reloadView}
                            resetSelectedElements={this.resetSelectedElements}
                            allowedActions={this.props.allowedActions}
                            entity={this.props.entity}
                        />
                    </div>
                )}
                {(items.length > 9 || this.props.pagination.totalPages > 1) && (
                    <div className={styles.ListViewPagination}>
                        <ListViewPaginationControl {...this.props.pagination} onPageChange={this.props.onPageChange}/>
                    </div>
                )}
            </>
        );
    }
}

ListView.propTypes = {
    heading: PropTypes.string,
    fields: PropTypes.arrayOf(ItemField).isRequired,
    items: PropTypes.arrayOf(ListItem).isRequired,

    filter: PropTypes.objectOf(PropTypes.node).isRequired,

    sort: PropTypes.shape({
        field: PropTypes.string,
        ascending: PropTypes.bool,
    }),

    pagination: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        totalPages: PropTypes.number.isRequired,
    }).isRequired,

    loading: PropTypes.bool,

    onSortChange: PropTypes.func,
    onPageChange: PropTypes.func,
    onFilterChange: PropTypes.func,

    showAllHandle: PropTypes.func,
    reloadView: PropTypes.func,
    isShowSelectionWithBatchActions: PropTypes.bool,
    isDeleting: PropTypes.bool,
    allowedActions: PropTypes.array,
    entity: PropTypes.string,
    linkNearHeading: PropTypes.func,
};

export default ListView;
