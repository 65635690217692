import {createSlice} from 'redux-starter-kit';

/**
 * Generischer Redux Slice für "copy" State/Actions einer Entität.
 *
 * @param entityName Der Name der Entität für die der Slice erzeugt wird.
 */
export default (entityName) => createSlice({
    slice: entityName,
    initialState: {},
    reducers: {
        copy: (state, {payload}) =>  {
            state[payload.entity.id] = true;
        },
        copyError: (state, {payload}) =>  {
            state[payload.entity.id] = false;
        },
        copySuccess: (state, {payload}) =>  {
            // Als payload wird eine "normalizr" response erwartet
            state[payload.response.result] = false;
        }
    }
})
