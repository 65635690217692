import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import {get, isFunction} from 'lodash';

/**
 * Auswahl für neue Achs-Werte der Preismatrix.
 */
class ObjectSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedValue: ''
        }
    }

    handleChange = (event) => {
        this.setState({selectedValue: event.target.value});
    };

    onSelect = (optionValue) => {
        if (this.props.onSelect) {
            const selectedOption = this.props.options.find(o => this.getOptionValue(o) === optionValue);
            this.props.onSelect(selectedOption);
        }

        this.setState({selectedValue: ''});
    };

    getOptionValue = option => {
        return get(option, this.props.optionValue);
    };
    getOptionLabel = option => {
        return isFunction(this.props.optionLabel) ? this.props.optionLabel(option) : get(option, this.props.optionLabel);
    };

    render() {
        const {options, classname, alignRight, drop, label, width} = this.props;

        return (
            <Form.Group>
                <InputGroup>
                    <Dropdown drop={drop} alignRight={alignRight} className={width}>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic" className={`dropdown-button dropdown-btn ${classname}`}>
                            <i className="fa fa-fw fa-plus"></i>{label}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-items">
                            {options.map((option) =>
                                <Dropdown.Item key={this.getOptionValue(option)} className="dropdown-list-item" onClick={() => this.onSelect(this.getOptionValue(option))}>{this.getOptionLabel(option)}</Dropdown.Item>)
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </InputGroup>
            </Form.Group>
        );
    }
}

ObjectSelect.propTypes = {
    label: PropTypes.string.isRequired,
    emptySelection: PropTypes.string.isRequired,
    buttonHint: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSelect: PropTypes.func,
    optionLabel: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    optionValue: PropTypes.string
};
ObjectSelect.defaultProps = {
    optionLabel: 'name',
    optionValue: 'id'
};

export default ObjectSelect;
