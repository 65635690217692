import React, {useEffect} from 'react';
import Form from "./Form";
import {connect} from "react-redux";
import {LoadingIndicator} from "../common/LoadingIndicator";
import {Col, Row} from "react-bootstrap";
import * as targetGroup from "../../state/entities/targetGroup";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";


const Edit = ({loadTargetGroup, saveTargetGroup, targetGroupId, targetGroup, loading, saving}) => {

    useEffect(() => {
        if (!targetGroup) {
            loadTargetGroup(targetGroupId);
        }

        // Wir wollen diesen Effekt explizit nur einmal "on-mount" durchführen, daher geben wir [] als dependency an.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (targetGroup, {setErrors}) => {
        saveTargetGroup(targetGroup, setErrors);
    };

    if (!targetGroup || loading) {
        return <LoadingIndicator/>;
    }
    return <>
        <Row className="my-4">
            <Breadcrumbs entity={["Personenpool"]} endpoints = {['target_group/breadcrumb']} title = "Personenpools" links={['/base/target-group/']} active={[targetGroup.id]} />
        </Row>
        <Row className="mb-4 mt-4">
            <Col className="pt-3 col-sm-8">
                <h1>Bearbeiten des Personenpools <em>{targetGroup.name}</em></h1>
            </Col>
        </Row>
        <Form targetGroup={targetGroup} onSubmit={onSubmit} submitPending={saving} />

    </>;
};


const mapStateToProps = (state, props) => {
    const targetGroupId = props.match.params.spid;

    const targetGroup =state.entities.targetGroup.byId[targetGroupId];

    return {
        targetGroupId,
        loading: state.entities.targetGroup.loading[targetGroupId],
        saving: state.entities.targetGroup.saving[targetGroupId],
        targetGroup,
    }
};

const mapDispatchToProps = dispatch => ({
    loadTargetGroup: id => dispatch(targetGroup.actions.load({id})),
    saveTargetGroup: entity => dispatch(targetGroup.actions.save({entity})),

});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
