import {createSlice} from 'redux-starter-kit';

/**
 * Generischer Redux Slice für "delete" State/Actions einer Entität.
 *
 * @param entityName Der Name der Entität für die der Slice erzeugt wird.
 */
export default (entityName) => createSlice({
    slice: entityName,
    initialState: {},
    reducers: {
        delete: (state, {payload}) =>  {
            state[payload.entity.id] = true;
        },
        deleteError: (state, {payload}) =>  {
            state[payload.entity.id] = false;
        },
        deleteSuccess: (state, {payload}) =>  {
            state[payload.entity.id] = false;
        }
    }
})
