import React, { useState, useRef } from 'react';
import {Container, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {actions} from '../../state/entities/eventSeries';
import ConnectedListView from '../common/listView/ConnectedListView';
import ConfirmModal from '../common/modal/ConfirmModal';
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import FeedbackButton from "../common/FeedbackButton";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

const List = (props) => {
    const deleteItem = (item) => () => {
        props.delete(item)
    };

    return (
        <Container>
            <Row className="my-4">
                <Breadcrumbs entity={["Eventserie"]} endpoints = {['event_series/breadcrumb']} title = "Eventserien" links={['/event-management/event-series/']} active={[0]} />
            </Row>
            <ConnectedListView
                heading="Eventserien"
                endpoint="listingView/eventSeries"
                buttonTitle="Neue Eventserie"
                buttonLink="/event-management/event-series/create"
                testid="link_new_eventSeries"
            >
                {({fields, item}) => (
                    <>
                        <FeedbackButton
                            className="list-link"
                            title="Eventserie bearbeiten"
                            to={`/event-management/event-series/${item.id}`}
                            icon={<EditNoteOutlinedIcon className='table-icon' />}
                        />

                        <ConfirmModal title="Eventserie löschen"
                                      body={`Möchten Sie die Eventserie "${item.name.de}" wirklich löschen?`}
                                      cancelLabel="Abbrechen"
                                      confirmLabel="Löschen"
                        >
                            {confirm =>
                                <FeedbackButton
                                    title="Eventserie löschen"
                                    className="list-link"
                                    onClick={() => confirm(deleteItem(item))}
                                >
                                    <DeleteOutlineOutlinedIcon className='table-icon' />
                                </FeedbackButton>
                            }
                        </ConfirmModal>
                    </>
                )}
            </ConnectedListView>

        </Container>
    );
}

export default connect(null, {
    delete: entity => actions.delete({entity})
})(List);
