import React, { useEffect, useRef, useState } from 'react';
import styles from '../layoutEditor.module.scss';
import { includes } from "lodash";
import interact from "interactjs";
import EventSeriesLogo from "./EventSeriesLogo";
import Image from "./Image";
import PropTypes from "prop-types";
import Template from "./Templete";
import TicketQRCode from "./TicketQRCode";
import TicketBarcode from "./TicketBarcode";
import Rectangle from "./Rectangle";
import Triangle from "./Triangle";
import Separator from "./Separator";
import { adjustCoordinatesByZoomLevel } from "../LayoutEditor";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import RmvLink from "./RmvLink";
import ConfirmModal from "../../common/modal/ConfirmModal";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const LayoutElement = ({
        showElementBorder,
        showElementToolbar,
        zoomLevel,
        initialElement,
        editModeEnabled,
        snapToGrid,
        gridSize,
        isSelected,
        dragSelectedElements,
        updateElement,
        removeElement,
        cloneElement,
    }) => {
    const [element, setElement] = useState({...initialElement});
    const elementRef = useRef();

    const updateLocalAndParameterElement = (newValue) => {
        setElement(newValue);

        if (typeof newValue === 'function') {
            const newValueResult = newValue(element);
            updateElement(newValueResult);
        } else {
            updateElement(newValue);
        }
    };

    const changeZIndex = (delta) => {
        updateLocalAndParameterElement({...element, zIndex: element.zIndex + delta});
    };

    const rotateRight = function () {
        updateLocalAndParameterElement({...element, rotationPercent: (element.rotationPercent + 25) % 100});
    };

    const rotateLeft = function () {
        let rotationPercent = (element.rotationPercent - 25) % 100;
        if (rotationPercent < 0) {
            rotationPercent += 100;
        }

        updateLocalAndParameterElement({...element, rotationPercent: rotationPercent});
    };

    const toggleLock = function () {
        updateLocalAndParameterElement({...element, locked: !element.locked});
    };

    // make stateRef always have the current element
    elementRef.current = element;

    const componentRef = useRef();
    const resizeRef = useRef();

    useEffect(() => {
        let componentRefCopy = componentRef.current;
        let resizeRefCopy = resizeRef.current;

        interact(componentRefCopy).unset();
        interact(resizeRefCopy).unset();

        if (!editModeEnabled && !element.locked) {
            const dragOptions = {
                origin: `.${styles.LayoutContainer}`, inertia: true, autoScroll: true, onmove: event => {
                    const dx = Math.round(adjustCoordinatesByZoomLevel(event.dx, zoomLevel));
                    const dy = Math.round(adjustCoordinatesByZoomLevel(event.dy, zoomLevel));
                    dragSelectedElements(dx, dy, element.id);

                    setElement(preValue => ({
                        ...preValue, x: preValue.x + dx, y: preValue.y + dy,
                    }));
                }, onstart: event => {
                }, onend: () => {
                    updateElement({...elementRef.current});
                }
            };

            const resizeAspectRatioLockedTypes = ['ticket_qr_code'];
            const resizeOptions = {
                origin: `.${styles.LayoutContainer}`,

                // resize from all edges and corners
                edges: {left: false, right: false, bottom: false, top: false},

                preserveAspectRatio: includes(resizeAspectRatioLockedTypes, element.type),

                // keep the edges inside the parent
                restrictEdges: {
                    outer: `.${styles.LayoutContainer}`, endOnly: true
                }, // minimum size
                restrictSize: {
                    min: {width: 10, height: 10}
                }, manualStart: true, inertia: true
            };

            if (snapToGrid) {
                const zoomLevelGridSize = Math.round(gridSize * (zoomLevel / 10));

                dragOptions.snap = {
                    targets: [interact.createSnapGrid({x: zoomLevelGridSize, y: zoomLevelGridSize})],
                    range: Infinity,
                    relativePoints: [{x: 0, y: 0}]
                };
                resizeOptions.snap = {
                    targets: [interact.createSnapGrid({
                        x: 10, y: 10, range: 10
                    })]
                };
            }

            interact(componentRefCopy)
                .draggable(dragOptions)
                .resizable(resizeOptions)
                .on('resizemove', event => {
                    setElement(prevElem => ({
                        ...prevElem,
                        width: adjustCoordinatesByZoomLevel(event.rect.width, zoomLevel),
                        height: adjustCoordinatesByZoomLevel(event.rect.height, zoomLevel),
                        x: prevElem.x + Math.round(adjustCoordinatesByZoomLevel(event.deltaRect.left, zoomLevel)),
                        y: prevElem.y + Math.round(adjustCoordinatesByZoomLevel(event.deltaRect.top, zoomLevel)),
                    }));
                }).on('resizeend', event => {
                updateElement({...elementRef.current});
            });

            interact(resizeRef.current)
                .on('down', event => {
                    const interaction = event.interaction;
                    const handle = event.currentTarget;

                    interaction.start({
                            name: 'resize', edges: {
                                top: handle.dataset.top,
                                left: handle.dataset.left,
                                bottom: handle.dataset.bottom,
                                right: handle.dataset.right,
                            }
                        }, interact(componentRefCopy),  // target Interactable
                        componentRefCopy);           // target Element
                });

            return () => {
                interact(componentRefCopy).unset();
                interact(resizeRefCopy).unset();
            };
        }
    }, [dragSelectedElements, editModeEnabled, element.id, element.locked, element.type, gridSize, snapToGrid, updateElement, zoomLevel]);

    const RenderElement = ({element}) => {
        switch (element.type) {
            case "event_series_logo":
                return <EventSeriesLogo element={element} editModeEnabled={editModeEnabled}
                                        updateElement={updateLocalAndParameterElement}/>;
            case "image":
                return <Image element={element} editModeEnabled={editModeEnabled}
                              updateElement={updateLocalAndParameterElement}/>;
            case "template":
                return <Template element={element} editModeEnabled={editModeEnabled}
                                 updateElement={updateLocalAndParameterElement}/>;
            case "ticket_qr_code":
                return <TicketQRCode element={element} editModeEnabled={editModeEnabled}/>;
            case "ticket_barcode":
                return <TicketBarcode element={element} editModeEnabled={editModeEnabled}/>;
            case "rectangle":
                return <Rectangle element={element} editModeEnabled={editModeEnabled}
                                  updateElement={updateLocalAndParameterElement}/>;
            case "triangle":
                return <Triangle element={element} editModeEnabled={editModeEnabled}
                                 updateElement={updateLocalAndParameterElement}/>;
            case "separator":
                return <Separator element={element} editModeEnabled={editModeEnabled}
                                  updateElement={updateLocalAndParameterElement}/>;
            case "rmv_link":
                return <RmvLink element={element} editModeEnabled={editModeEnabled}
                                updateElement={updateLocalAndParameterElement}/>;
            default:
                throw new Error("invalid element type: " + element.type);
        }
    };

    const getRotateClassname = () => {
        if (element.rotationPercent === 25) {
            return styles.Rotate25;
        } else if (element.rotationPercent === 50) {
            return styles.Rotate50;
        } else if (element.rotationPercent === 75) {
            return styles.Rotate75;
        }

        return "";
    }

    return <div ref={componentRef}
                className={`${styles.LayoutElement} ${!editModeEnabled && showElementBorder ? styles.Positioning : ""}
                ${isSelected ? styles.Selected : ""} ${getRotateClassname()}`}
                style={{
                    width: element.width + "px",
                    height: element.height + "px",
                    left: element.x + "px",
                    top: element.y + "px",
                    zIndex: element.zIndex
                }}>
        <RenderElement element={element}/>
        <div ref={resizeRef} className={`${styles.LayoutHandle} ${editModeEnabled ? styles.Hidden : ""}`}
             data-right="true" data-bottom="true">
            <i className="fa fa-expand fa-rotate-90"></i>
        </div>

        <div
            className={`${styles.EditButtons} ${editModeEnabled || (!showElementToolbar) ? styles.Hidden : ""}`}>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={"zIndexIcrese" + element.id}>Element entfernen</Tooltip>}
            >
                <ConfirmModal title="Element entfernen"
                              body={`Möchten sie das Element wirklich entfernen?`}
                              cancelLabel="Abbrechen"
                              confirmLabel="Löschen"
                >
                    {confirm => <button onClick={() => confirm(() => {
                        removeElement(element.id)
                    })} className="btn btn-sm" type="button">
                        <DeleteOutlineOutlinedIcon className='table-icon' />
                    </button>}
                </ConfirmModal>
            </OverlayTrigger>

            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={"toggleLock" + element.id}>
                    {element.locked && "Veränderungen des Elements freigeben"}
                    {!element.locked && "Veränderungen des Elements sperren"}
                </Tooltip>}
            >
                <button onClick={() => toggleLock()} className="btn btn-sm" type="button">
                    {element.locked && <i className="fa fa-lock fa-fw"></i>}
                    {!element.locked && <i className="fa fa-unlock fa-fw"></i>}
                </button>
            </OverlayTrigger>

            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={"duplicate" + element.id}>Duplikat</Tooltip>}
            >
                <button onClick={() => cloneElement(element.id)} className="btn btn-sm" type="button">
                    <i className="fa fa-clone"></i>
                </button>
            </OverlayTrigger>

            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={"zIndexIcrese" + element.id}>Nach rechts drehen</Tooltip>}
            >
                <button onClick={() => rotateLeft()} className="btn btn-sm" type="button">
                    <i className="fa fa-rotate-left"></i>
                </button>
            </OverlayTrigger>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={"zIndexIcrese" + element.id}>Nach links drehen</Tooltip>}
            >
                <button onClick={() => rotateRight()} className="btn btn-sm" type="button">
                    <i className="fa fa-rotate-right"></i>
                </button>
            </OverlayTrigger>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={"zIndexIcrese" + element.id}>Nach hinten bringen
                    (Position: {element.zIndex})</Tooltip>}
            >
                <button onClick={() => changeZIndex(-1)} className="btn btn-sm" type="button">
                    <i className="fa fa-arrow-down"></i>
                </button>
            </OverlayTrigger>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={"zIndexIcrese" + element.id}>Nach vorne bringen
                    (Position: {element.zIndex})</Tooltip>}
            >
                <button onClick={() => changeZIndex(1)}
                        className="btn btn-sm" type="button">
                    <i className="fa fa-arrow-up"></i>
                </button>
            </OverlayTrigger>
        </div>
    </div>;
};

LayoutElement.propTypes = {
    zoomLevel: PropTypes.number.isRequired,
    initialElement: PropTypes.object.isRequired,
    editModeEnabled: PropTypes.bool,
    showElementBorder: PropTypes.bool,
    snapToGrid: PropTypes.bool.isRequired,
    gridSize: PropTypes.number.isRequired,
    isSelected: PropTypes.bool.isRequired,
    dragSelectedElements: PropTypes.func.isRequired,
    updateElement: PropTypes.func.isRequired,
    removeElement: PropTypes.func.isRequired,
};

export default LayoutElement;



