import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import FormikCheckbox from "../common/formik/FormikCheckbox";
import FeedbackButton from '../common/FeedbackButton';
import { isEmpty } from 'lodash';
import {FormikTextInputGroup} from "../common/formik/FormikTextInputGroup";
import styles from '../common/listView/listView.module.scss';
import AddIcon from '@mui/icons-material/Add';
import classnames from 'classnames';

const FormPlacePools = ({formik, blockIndex, standingBlocks, placePools}) => {

    const findPlacePoolName = (id) => {
        if (!placePools || !Array.isArray(placePools)) {
            return '';
        }

        const placePool = placePools.find(item => {
            return item.id === id;
        });

        return placePool ? placePool.name : '';
    }

    const [checkboxStates, setCheckboxStates] = useState(Array.from({length: formik.values.standingBlocks[blockIndex].placePools.length}, () => false));

    useEffect(() => {
        const initialCheckboxStates = Array.from({ length: formik.values.standingBlocks[blockIndex].placePools.length }, (_, index) => {
            return formik.values.standingBlocks[blockIndex].placePools[index].overwriteCapacity || false;
        });
        setCheckboxStates(initialCheckboxStates);
    }, [formik.values.standingBlocks[blockIndex].placePools]);

    const getPlacePoolInfo = (formBlock, placePoolId, infoType) => {
        if (!standingBlocks || !Array.isArray(standingBlocks.venueEventStandingBlocks)) {
            return 0;
        }
    
        const block = standingBlocks.venueEventStandingBlocks.find(item => {
            return item.block === formBlock.block;
        });
    
        if (block && block.placePools) {
            const placePool = block.placePools.find(item => {
                return item.placePool === placePoolId;
            });
    
            if (placePool) {
                return placePool[infoType] || 0;
            }
            else {
                return 0;
            }
        }
        return 0;
    };

    const handleCheckboxChange = (index) => {
        setCheckboxStates(prevStates => {
            const newCheckboxStates = [...prevStates];
            newCheckboxStates[index] = !prevStates[index];
            return newCheckboxStates;
        });

        formik.setFieldValue(`standingBlocks[${blockIndex}].placePools[${index}].overwriteCapacity`, !checkboxStates[index]);
    };

    if (isEmpty(formik.values.standingBlocks[blockIndex].placePools)) {
        return (
            <div>
                <p>Kein Platzpool zugeordnet. Stehplätze noch nicht verkaufbar</p>
                <FeedbackButton
                    to={`/base/venue-plan/${formik.values.venuePlan}/block/standing/${formik.values.standingBlocks[blockIndex].block}`}
                    variant='contained'
                    icon={<AddIcon className='table-icon' />}
                >
                    Platzpools hinzufügen
                </FeedbackButton>
            </div>
        )
    }

    return (
        <>
            <table className={classnames('table table-striped', styles.TableWrap)}>
                <thead className={styles.TableHead}>
                <tr>
                    <th>Platzpool</th>
                    <th>Platzpool-Änderungen (entfernen, hinzufügen, Größe) immer übernehmen</th>
                    <th>Anzahl Plätze dieses Platzpools in diesem Block</th>
                </tr>
                </thead>
                <tbody className={styles.TableBody}>
                {
                    formik.values.standingBlocks[blockIndex].placePools.map((pool, index) => {
                        return (
                            <tr key={index}>
                                <td>{findPlacePoolName(formik.values.standingBlocks[blockIndex].placePools[index].placePool)}</td>
                                <td>
                                    <FormikCheckbox
                                        label="Blockgrößen(Kapazität)-Änderungen immer übernehmen"
                                        name={'standingBlocks[' + blockIndex + '].placePools[' + index + '].overwriteCapacity'}
                                        checked={checkboxStates[index]}
                                        onChange={() => handleCheckboxChange(index)}
                                    />
                                </td>
                                <td width={'250px'}>
                                    <span>Gebuchte Plätze: &nbsp;
                                        {getPlacePoolInfo(
                                            formik.values.standingBlocks[blockIndex],
                                            pool.placePool,
                                            'bookedPlaces'
                                        )}
                                    </span>
                                    <br />
                                    <span>Kapazität im Saalplan:&nbsp;
                                        {getPlacePoolInfo(
                                            formik.values.standingBlocks[blockIndex],
                                            pool.placePool,
                                            'venuePlanCapacity'
                                        )}
                                    </span>
                                    <FormikTextInputGroup
                                        type="number"
                                        className={styles.inputRemoveSpace}
                                        min="0"
                                        name={'standingBlocks[' + blockIndex + '].placePools[' + index + '].capacity'}
                                        disabled={!checkboxStates[index]}
                                        validate={(capacity) => capacity < 0 ? "Die Kapazität sollte größer oder gleich 0 sein" : undefined}
                                    />
                                </td>
                            </tr>

                        )
                    })
                }
                </tbody>
            </table>
        </>
    )

}

FormPlacePools.propTypes = {
    formik: PropTypes.any,
    blockIndex: PropTypes.any,
    standingBlocks: PropTypes.object,
    placePools: PropTypes.array,
};

export default FormPlacePools;
