import React, { useState, useRef } from 'react';
import {Container, Col, Row} from 'react-bootstrap';
import ConnectedListView from '../common/listView/ConnectedListView';
import FeedbackButton from '../common/FeedbackButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import {connect} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {get} from 'lodash';
import * as ticketLayout from "../../state/entities/ticketLayout";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import {BATCH_ACTION_MOVE_TO_ARCHIVE} from "../common/listView/ListViewBatchActions";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

const LinkToArchived = () => {
    return (
        <FeedbackButton
            to={`/base/ticket-layout/archived`}
            icon={<RefreshIcon />}
            variant='contained'
            className='listLinkCenter'
        >
            Archiv
        </FeedbackButton>
    );
}

const Breadcrumb = () => {
    return (
        <Breadcrumbs entity={["Ticketlayout"]} endpoints = {['ticket_layout/breadcrumb']} title = "Ticketlayouts" links={['/base/ticket-layout/']} active={[0]} />
    )
}

const List = ({
    deleteTicketLayout,
    copyTicketLayout,
    linkNearHeader = LinkToArchived,
    breadcrumb = Breadcrumb,
    heading = 'Ticketlayouts',
    listViewEndpoint = 'listingView/ticketLayout',
    allowedBatchActions = [BATCH_ACTION_MOVE_TO_ARCHIVE]
}) => {

    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [ticketLayoutToDelete, setTicketLayoutToDelete] = useState(undefined);
    const [showCopyConfirmDialog, setShowCopyConfirmDialog] = useState(false);
    const [ticketLayoutToCopy, setTicketLayoutToCopy] = useState(undefined);

    const showDeleteConfirmingDialog = (ticketLayout) => {
        if (ticketLayout.isHardcoded) {
            return;
        }

        setShowDeleteConfirmDialog(true);
        setTicketLayoutToDelete(ticketLayout);
    };

    const handleCancelDelete = () => {
        setShowDeleteConfirmDialog(false);
    };

    const handleConfirmDelete = () => {
        setShowDeleteConfirmDialog(false);
        deleteTicketLayout(ticketLayoutToDelete);
    };

    const showCopyConfirmingDialog = (ticketLayout) => {
        if (ticketLayout.isHardcoded) {
            return;
        }

        setShowCopyConfirmDialog(true);
        setTicketLayoutToCopy(ticketLayout);
    };

    const handleCancelCopy = () => {
        setShowCopyConfirmDialog(false);
    };

    const handleConfirmCopy = () => {
        setShowCopyConfirmDialog(false);
        copyTicketLayout(ticketLayoutToCopy);
    };

    return (
        <Container>
            <Row className="my-4">
                {breadcrumb()}
            </Row>
            <ConnectedListView
                endpoint={listViewEndpoint}
                linkNearHeading={linkNearHeader}
                heading={heading}
                buttonTitle="Neues TicketLayout"
                buttonLink="/base/ticket-layout/create"
                isShowSelectionWithBatchActions={true}
                allowedActions={allowedBatchActions}
            >
                {({fields, item}) => (
                    <>
                        {!item.hardcoded &&
                        <span>
                            <FeedbackButton
                                className="list-link"
                                title="TicketLayout bearbeiten"
                                to={`/base/ticket-layout/${item.id}`}
                                icon={<EditNoteOutlinedIcon className='table-icon' />}
                            />
                            <FeedbackButton
                                title="TicketLayout kopieren"
                                className="list-link"
                                onClick={() => showCopyConfirmingDialog(item)}
                            >
                                <ContentCopyOutlinedIcon className='table-icon' />
                            </FeedbackButton>
                            <FeedbackButton
                                title="TicketLayout löschen"
                                className="list-link"
                                onClick={() => showDeleteConfirmingDialog(item)}
                            >
                                <DeleteOutlineOutlinedIcon className='table-icon' />
                            </FeedbackButton>
                            </span>
                        }
                    </>
                )}
            </ConnectedListView>

            <Modal show={showCopyConfirmDialog} onHide={handleCancelCopy}>
                <Modal.Header closeButton>
                    <Modal.Title>TicketLayout kopieren</Modal.Title>
                </Modal.Header>
                <Modal.Body>Möchten Sie den TicketLayout "{get(ticketLayoutToCopy, 'name')}" wirklich
                    kopieren?</Modal.Body>
                <Modal.Footer>
                    <FeedbackButton variant="outlined" onClick={handleCancelCopy}>
                        Abbrechen
                    </FeedbackButton>
                    <FeedbackButton onClick={handleConfirmCopy}>
                        Kopieren
                    </FeedbackButton>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteConfirmDialog} onHide={handleCancelDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>TicketLayout löschen</Modal.Title>
                </Modal.Header>
                <Modal.Body>Möchten Sie den TicketLayout "{get(ticketLayoutToDelete, 'name')}" wirklich löschen?</Modal.Body>
                <Modal.Footer>
                    <FeedbackButton variant="outlined" onClick={handleCancelDelete}>
                        Abbrechen
                    </FeedbackButton>
                    <FeedbackButton onClick={handleConfirmDelete}>
                        Löschen
                    </FeedbackButton>
                </Modal.Footer>
            </Modal>

        </Container>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        deleteTicketLayout: entity => dispatch(ticketLayout.actions.delete({entity})),
        copyTicketLayout: entity => dispatch(ticketLayout.actions.copy({entity}))
    }
};

export default connect(null, mapDispatchToProps)(List);
