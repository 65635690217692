import React, {useState, useRef} from 'react';
import {Container, Col, Row} from 'react-bootstrap';
import ConnectedListView from '../common/listView/ConnectedListView';
import FeedbackButton from '../common/FeedbackButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import {connect} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {get} from 'lodash';
import * as salesRule from "../../state/entities/salesRule";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import {getSalesChannelsWithIcon} from "./Form";
import {BATCH_ACTION_MOVE_TO_ARCHIVE} from "../common/listView/ListViewBatchActions";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
const LinkToArchived = () => {
    return (
        <FeedbackButton
            to={`/event-management/sales-rule/archived`}
            icon={<RefreshIcon />}
            variant='contained'
            className='listLinkCenter'
        >
            Archiv
        </FeedbackButton>
    );
}

const Breadcrumb = () => {
    return (
        <Breadcrumbs entity={["Verkaufsregel"]} endpoints = {['sales_rule/breadcrumb']} title = "Verkaufsregeln" links={['/event-management/sales-rule/']} active={[0]} />
    )
}

const List = ({
    deleteSalesRule,
    copySalesRule,
    linkNearHeader = LinkToArchived,
    breadcrumb = Breadcrumb,
    heading = 'Verkaufsregel',
    listViewEndpoint = 'listingView/salesRule',
    allowedBatchActions = [BATCH_ACTION_MOVE_TO_ARCHIVE]
}) => {

    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [salesRuleToDelete, setSalesRuleToDelete] = useState(undefined);
    const [showCopyConfirmDialog, setShowCopyConfirmDialog] = useState(false);
    const [salesRuleToCopy, setSalesRuleToCopy] = useState(undefined);

    const showDeleteConfirmingDialog = (salesRule) => {
        setShowDeleteConfirmDialog(true);
        setSalesRuleToDelete(salesRule);
    };

    const handleCancelDelete = () => {
        setShowDeleteConfirmDialog(false);
    };

    const handleConfirmDelete = () => {
        setShowDeleteConfirmDialog(false);
        deleteSalesRule(salesRuleToDelete);
    };

    const showCopyConfirmingDialog = (salesRule) => {
        setShowCopyConfirmDialog(true);
        setSalesRuleToCopy(salesRule);
    };

    const handleCancelCopy = () => {
        setShowCopyConfirmDialog(false);
    };

    const handleConfirmCopy = () => {
        setShowCopyConfirmDialog(false);
        copySalesRule(salesRuleToCopy);
    };

    const onDataLoaded = (items) => {
        if(items.length > 0){
            if(items[0].salesChannels){
                return items.map((item) => {
                    const salesChannelsWithIcons = getSalesChannelsWithIcon(item.salesChannels);
                    const itemWithSalesChannelIcons = {
                        ...item,
                        salesChannels: salesChannelsWithIcons
                    }
                    return itemWithSalesChannelIcons;
                });
            } else {
                return this.props.items;
            }
        } else  {
            return []
        }
    }

    return (
        <Container>
            <Row className="my-4">
                {breadcrumb()}
            </Row>

            <ConnectedListView
                endpoint={listViewEndpoint}
                linkNearHeading={linkNearHeader}
                heading={heading}
                buttonTitle="Neue Verkaufsregel"
                buttonLink="/event-management/sales-rule/create"
                onDataLoaded={onDataLoaded}
                isShowSelectionWithBatchActions={true}
                allowedActions={allowedBatchActions}
            >
                {({fields, item}) => (
                    <>
                        <FeedbackButton
                            className="list-link"
                            title="Verkaufsregel bearbeiten"
                            to={`/event-management/sales-rule/${item.id}`}
                            icon={<EditNoteOutlinedIcon className='table-icon' />}
                        />
                        <FeedbackButton
                            className="list-link"
                            title="Preismatrix bearbeiten"
                            to={`/event-management/pricing-list/${item.pricingListId}`}
                            icon={<TableChartOutlinedIcon className='table-icon' />}
                        />
                        <FeedbackButton title="Verkaufsregel kopieren" className="list-link" onClick={() => showCopyConfirmingDialog(item)}>
                            <ContentCopyOutlinedIcon className='table-icon' />
                        </FeedbackButton>
                        <FeedbackButton title="Verkaufsregel löschen" className="list-link" onClick={() => showDeleteConfirmingDialog(item)}>
                            <DeleteOutlineOutlinedIcon className='table-icon' />
                        </FeedbackButton>
                    </>
                )}
            </ConnectedListView>


            <Modal show={showCopyConfirmDialog} onHide={handleCancelCopy}>
                <Modal.Header closeButton>
                    <Modal.Title>Verkaufsregel kopieren</Modal.Title>
                </Modal.Header>
                <Modal.Body>Möchten Sie die Verkaufsregel "{get(salesRuleToCopy, 'name')}" wirklich
                    kopieren?</Modal.Body>
                <Modal.Footer>
                    <FeedbackButton variant="outlined" onClick={handleCancelCopy}>
                        Abbrechen
                    </FeedbackButton>
                    <FeedbackButton onClick={handleConfirmCopy}>
                        Kopieren
                    </FeedbackButton>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteConfirmDialog} onHide={handleCancelDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>Verkaufsregel löschen</Modal.Title>
                </Modal.Header>
                <Modal.Body>Möchten Sie die Verkaufsregel "{get(salesRuleToDelete, 'name')}" wirklich löschen?</Modal.Body>
                <Modal.Footer>
                    <FeedbackButton variant="outlined" onClick={handleCancelDelete}>
                        Abbrechen
                    </FeedbackButton>
                    <FeedbackButton onClick={handleConfirmDelete}>
                        Löschen
                    </FeedbackButton>
                </Modal.Footer>
            </Modal>

        </Container>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        deleteSalesRule: entity => dispatch(salesRule.actions.delete({entity})),
        copySalesRule: entity => dispatch(salesRule.actions.copy({entity}))
    }
};

export default connect(null, mapDispatchToProps)(List);
