import React, {Component} from 'react';
import * as yup from 'yup';
import {Formik} from 'formik';
import {Card, Col, Row} from 'react-bootstrap';
import {FormikTextInputGroup} from '../../../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../../../common/FeedbackButton';
import Api from '../../../../api';
import FormPlacePools from './FormPlacePools';
import {memoize} from '../../../../form/fieldValidation';
import debounce from 'debounce-promise';
import BackButton from '../../../common/BackButton';
import Footer from '../../../layout/Footer';
import {connect} from 'react-redux';
import { get, isEmpty } from 'lodash';
import MultiSelectList from '../../../common/form/MultiSelectList';
import FormikSelect from "../../../common/formik/FormikSelect";
import HelpContent from "../../../common/help/HelpContent";
import ConfirmModal from "../../../common/modal/ConfirmModal";
import FormikAsyncTypeaheadInput from "../../../common/formik/FormikAsyncTypeaheadInput";
import styles from "../../../form.module.scss";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';

const api = new Api();

const getInitialValues = (type, venuePlanId, usedPlacePools, pricingCategory) => ({
    label: {
        de: '',
        en: ''
    },
    backendNameShort: '',
    pricingCategory: pricingCategory || '',
    entrance: {
        de: '',
        en: ''
    },
    blockType: type,
    venuePlan: venuePlanId,
    usedPlacePools: usedPlacePools,
    capacity: 500,
    position: {
        x: '0.00',
        y: '0.00',
    },
    points: '-10.00,-10.00\n10.00,-10.00\n10.00,10.00\n-10.00,10.00',
    placePools: [],
    zukoZones: []
});

const FORM_SCHEMA = yup.object().shape({
    label: yup.object().shape({
        de: yup.string().required(),
        en: yup.string(),
    }),
    backendNameShort: yup.string().required(),
    zukoZones: yup.array(),
    position: yup.object().shape({
        x: yup.string().required().test('is-number', 'X coordinate must be a number', value => !isNaN(value)),
        y: yup.string().required().test('is-number', 'Y coordinate must be a number', value => !isNaN(value)),
    }),
    points: yup.string().required().matches(/^ *-?\d+(\.\d+)? *, *-?\d+(\.\d+)?(?:\n *-?\d+(\.\d+)?, *-?\d+(\.\d+)?)* *$/, 'Invalid coordinates format'),
    pricingCategory: yup.string().when('blockType', {
        is: 'standing',
        then: () => yup.string().required(),
        otherwise: () => yup.string().nullable()
    }),
    capacity: yup.number().integer().required()
});

export const getBlockGroups = (block, blockGroups) => {
    if (block.blockGroups && block.blockGroups.length > 0) {
        return (<Row><Col><p>Gehört zu den Blockgruppen {blockGroups.join(', ')}</p></Col></Row>)
    }
};

class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pricingCategoryList: null,
            placePoolList: []
        }
    }

    componentDidMount = () => {
        api.getPricingCategoriesByQuery().then(options => {
            this.setState({ pricingCategoryList: options });
        });
        api.getPlacePoolsByQuery().then(options => {
            this.setState({ placePoolList: options });
        })
    }

    render() {
        let {onSubmit, submitPending, block, type, venuePlanId, helpTextsVisible, venue} = this.props;
        const blockGroups = block.blockGroups ? Object.values(block.blockGroups).map(blockGroup => blockGroup.backendNameShort) : [];
        const usedPlacePools = block.usedPlacePools ? block.usedPlacePools : [];
        const getBlockByBackendNameShortDebounced = debounce((backendNameShort, venuePlanId) => api.getBlockByBackendNameShort(backendNameShort, venuePlanId), 500);


        const validateBackendNameShort = memoize({
            initialValue: block.backendNameShort,
            async validate(backendNameShort) {
                // Wenn der backendNameShort leer ist brauchen wir nicht die API zu fragen.
                if (!backendNameShort) return;

                const responseBlock = await getBlockByBackendNameShortDebounced(backendNameShort, venuePlanId);
                if (responseBlock && responseBlock.id !== block.id) {
                    return 'Der Kurztitel des Blocks wird bereits verwendet'
                }
            }
        });

        const handleSubmit = (values, actions) => {

            const points = values.points.split('\n').map(pointStr => {
                const [x, y] = pointStr.split(',').map(coord => coord.trim());
                return { x, y };
            });

            const modifedValues = {
                ...values,
                points,
                pricingCategory: values.pricingCategory,
                venuePlan: values.venuePlan
            }

            onSubmit(modifedValues, actions);
        }

        const filterPlacePools = this.state.placePoolList.reduce((acc, placePool) => {
            const activePlacePools = !isEmpty(block.placePools) && block.placePools.find(active => active.placePool === placePool.id);
            if (activePlacePools) {
                acc.push({
                    placePool: {
                        id: placePool.id,
                        name: placePool.name
                    },
                    capacity: activePlacePools.capacity
                });
            }
            return acc;
        }, []);

        return (
            <Formik initialValues={{
                ...getInitialValues(type, venuePlanId, usedPlacePools, block?.pricingCategory),
                ...block,
                ...block.points && { points: block.points.map(point => `${point.x}, ${point.y}`).join('\n') },
                ...block.placePools && { placePools:  filterPlacePools },
                ...block.zukoZones && {zukoZones: block.zukoZones},
            }}
                validationSchema={FORM_SCHEMA}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {formik => {
                    const onSubSubmit = ( values, { resetForm }) => {
                        get(values, 'placePool.id') && formik.setFieldValue('placePools', [...formik.values.placePools, {
                            placePool: {
                                id: values.placePool.id,
                                name: values.placePool.name
                            },
                            capacity: values.capacityPlacePool
                        }]);
                        // Resetten der form, damit Werte auf Initial Values gesetzt werden
                        resetForm();
                    };

                    const onSubmitPlacePools = (values, { resetForm }) => {
                        formik.setFieldValue('placePools', [...formik.values.placePools, {
                            placePool: {
                                id: values.placePool.id,
                                name: values.placePool.name
                            },
                            capacity: 1
                        }]);

                        resetForm();
                    }

                    const deletePlacePoolFromAdd = (placePoolIndex) => {
                        formik.setFieldValue("placePools", formik.values.placePools.filter((_, index) => {
                            return (index !== placePoolIndex)
                        }));
                    };

                    const SUBFORM_SCHEMA = yup.object().shape({
                        placePool: yup.object().required()
                            .test('unique', 'Der Platzpool ist bereits zugeordnet', pool => !formik.values.placePools.find(p => p.placePool.id === pool.id))
                            .test('empty', 'Der Platzpool wurde nicht ausgewählt', pool => !!pool.name)
                    });

                    const zukoZoneSelectFieldMap = {
                        'entry': 'Zuko-Zone',
                        'label': 'Zutrittszone im Ticketing'
                    };

                    const handleZukoZoneSelectionChange = (zukoZoneSelection) => {
                        const selectedZukoZoneIds = zukoZoneSelection.map(selection => selection.id);
                        formik.setFieldValue('zukoZones', selectedZukoZoneIds);
                    };

                    const validatePoolCapacity = (capacity) => {
                        let minCapacity = 0;

                        if (minCapacity > capacity) {
                            return `Die Kapazität sollte größer oder gleich ${minCapacity} sein`
                        }
                    }

                    return (
                        <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                            <div className={styles.formBox}>
                                <h2 className={styles.formTitle}>Allgemein</h2>
                                <Row className="mt-3">
                                    <Col className="col-md-4">
                                        <FormikTextInputGroup
                                            label="Titel des Blocks (deutsch)*"
                                            name="label.de"
                                            testid="name_german"
                                            helpTextsVisible={helpTextsVisible}
                                            helpText="Dieser Name wird auf deutschsprachigen Tickets angezeigt."
                                        />
                                    </Col>
                                    <Col className="col-md-4">
                                        <FormikTextInputGroup
                                            label="Titel des Blocks (englisch)"
                                            name="label.en"
                                            testid="name_english"
                                            helpTextsVisible={helpTextsVisible}
                                            helpText="Dieser Name wird auf englischsprachigen Tickets angezeigt."
                                        />
                                    </Col>
                                    <Col className="col-md-4">
                                        <FormikTextInputGroup
                                            label="Kurztitel des Blocks*"
                                            name="backendNameShort"
                                            validate={validateBackendNameShort}
                                            testid="shortName"
                                            helpTextsVisible={helpTextsVisible}
                                            helpText="Dieser Name wird nur im Backend benutzt."
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col className="col-md-4">
                                        <FormikTextInputGroup
                                            label="Eingangshinweis (deutsch)"
                                            name="entrance.de"
                                            testid="entrance_german"
                                            helpTextsVisible={helpTextsVisible}
                                            helpText="Hinweis, welcher Eingang verwendet werden kann, wird auf dem Ticket angezeigt."
                                        />
                                    </Col>
                                    <Col className="col-md-4">
                                        <FormikTextInputGroup
                                            label="Eingangshinweis (englisch)"
                                            name="entrance.en"
                                            testid="entrance_english"
                                            helpTextsVisible={helpTextsVisible}
                                            helpText="Hinweis, welcher Eingang verwendet werden kann, wird auf dem Ticket angezeigt."
                                        />
                                    </Col>
                                    <Col className="col-md-4">
                                        <FormikSelect
                                            label={type === 'standing' ? "Preiskategorie der Stehplätze*" : "Preiskategorie für alle Plätze des Blocks ändern"}
                                            options={this.state.pricingCategoryList}
                                            name="pricingCategory"
                                            isShowEmptyValue={type === 'seating' ? true : false}
                                            emptyValueLabel="Bitte wählen..."
                                        />
                                    </Col>
                                </Row>
                            </div>
                            {type === 'seating' && (
                                <div className={styles.formBox}>
                                    <h2 className={styles.formTitle}>Platzpools</h2>
                                    <Row className="mt-3">
                                        <Col className="col-md-8">
                                            <FormPlacePools formik={formik} onSubSubmit={onSubSubmit}/>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                            {type === 'standing' && (
                                <>
                                    <div className={styles.formBox}>
                                        <h2 className={styles.formTitle}>Koordinaten des Stehplatz Blocks</h2>
                                        <p>Hauptkoordinaten des Stehplatz Blocks:</p>
                                        <Row className="mt-3">
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup
                                                    label="X Koordinate:"
                                                    name="position.x"
                                                    testid="positionX"
                                                    helpTextsVisible={helpTextsVisible}
                                                    helpText="Zur Zuordnung der Position des Platzes."
                                                />
                                            </Col>
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup
                                                    label="Y Koordinate:"
                                                    name="position.y"
                                                    testid="positionY"
                                                    helpTextsVisible={helpTextsVisible}
                                                    helpText="Zur Zuordnung der Position des Platzes."
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup
                                                    label="Eckpunkt-Koordinaten: (Je Zeile X und Y Koordinate, mit Komma separiert)"
                                                    name="points"
                                                    testid="points"
                                                    type="textarea"
                                                    minRows={6}
                                                    helpTextsVisible={helpTextsVisible}
                                                />
                                            </Col>
                                            <Col className="col-md-4 align-self-center">
                                                {helpTextsVisible && (
                                                    <div className={styles.textareaHelpBlock}>
                                                        <span>
                                                            XX.XX, YY.YY
                                                            <br/>
                                                            XX.XX, YY.YY
                                                        </span>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className={styles.formBox}>
                                        <h2 className={styles.formTitle}>Platzpools und Kapazität</h2>
                                        <Row className="mt-3">
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup
                                                    label="Anzahl der Stehplätze"
                                                    name="capacity"
                                                    testid="capacity"
                                                    type="number"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col className="col-md-4">
                                                <HelpContent as="p" className="text-muted">Diese Platzpools sind bei mindestens einem Platz in diesem Block hinterlegt.</HelpContent>

                                                <table className="table table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th>Platzpool</th>
                                                        <th className="text-center">Anzahl Plätze dieses Platzpools in diesem Block</th>
                                                        <th className="text-center">Zuordnung entfernen</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                        {!isEmpty(formik.values.placePools) && formik.values.placePools.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item.placePool.name}</td>
                                                                    <td>
                                                                        <FormikTextInputGroup
                                                                            type="number"
                                                                            min={0}
                                                                            name={`placePools[${index}].capacity`}
                                                                            validate={(capacity) => validatePoolCapacity(capacity)}
                                                                        />
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <ConfirmModal
                                                                            title="Platzpool löschen"
                                                                            body={`Möchten Sie die Zuordnung wirklich entfernen?`}
                                                                            cancelLabel="Abbrechen"
                                                                            confirmLabel="Entfernen"
                                                                        >
                                                                            {confirm =>
                                                                                <FeedbackButton
                                                                                    title="Zuordnung entfernen"
                                                                                    className="list-link link-btn"
                                                                                    onClick={() => confirm(() => {
                                                                                        deletePlacePoolFromAdd(index);
                                                                                    })}
                                                                                >
                                                                                    <DeleteOutlineOutlinedIcon className='table-icon' />
                                                                                </FeedbackButton>
                                                                            }
                                                                        </ConfirmModal>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        )}
                                                    </tbody>
                                                </table>

                                                <Formik
                                                    initialValues={{
                                                        placePool: {
                                                            id: '',
                                                            name: ''
                                                        },
                                                    }}
                                                    validationSchema={SUBFORM_SCHEMA}
                                                    onSubmit={onSubmitPlacePools}
                                                >
                                                    {( subformik ) => {
                                                        return (
                                                            <>
                                                                <FeedbackButton
                                                                    className='mt-3 mb-3'
                                                                    onClick={() => subformik.submitForm()}
                                                                    icon={<AddIcon />}
                                                                >
                                                                    hinzufügen
                                                                </FeedbackButton>
                                                                {!isEmpty(this.state.placePoolList) && (
                                                                    <div>
                                                                        <FormikAsyncTypeaheadInput
                                                                            id={'placePool'}
                                                                            label="Platzpools der Plätze"
                                                                            onSearch={() => Promise.resolve(this.state.placePoolList)}
                                                                            labelKey={o => o.name}
                                                                            minLength={0}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </>
                                                        )
                                                    }}
                                                </Formik>
                                            </Col>
                                        </Row>
                                    </div>
                                </>
                            )}
                            <div className={styles.formBox}>
                                <h2 className={styles.formTitle}>Zuko-Steuerung</h2>
                                <p>Nehmen Sie hier die notwendigen Einstellungen vor, um das Zutrittskontrollsystems für diesen Block zu steuern</p>
                                <Row className="mt-3">
                                    <Col className="col-md-8">
                                        <h2 className="font-weight-bold pb-3 mb-3">Automatisch gestattete Zutrittszonen für alle Tickets dieses Blocks</h2>
                                        <p className={!helpTextsVisible ? 'hidden' : 'text-muted'}>
                                            Absolut jedes Ticket für diesen Block erhält Zutritt zu den hier eingetragenen Zutrittszonen.
                                        </p>
                                        <MultiSelectList
                                            fieldMap={zukoZoneSelectFieldMap}
                                            elements={venue.zukoZones}
                                            selected={block.zukoZones ? venue.zukoZones.filter(item => block.zukoZones.includes(item.id)) : []}
                                            onChange={selection => handleZukoZoneSelectionChange(selection)}
                                        />
                                    </Col>
                                </Row>
                            </div>

                            {getBlockGroups(block, blockGroups)}

                            <Footer>
                                {/*
                            FIXME: auf Grund der aktuellen Routenstruktur ist es hier nicht möglich den Link Button
                            zu verwenden um in die Edit-Seite eines Saalplans/Listing der Blockgruppen zu gelangen,
                            da die Venue-ID hier nicht bekannt ist und sowohl die VenuePlan-ID als auch die
                            BlockGroup-ID aus der URL gepuhlt werden.
                            Der Browserback ist nicht in allen Fällen korrekt (bspw. nicht bei externen Einsprüngen)
                            und die Problematik sollte im Zuge der "Breadcrums" behoben werden.
                            */}
                                {/*<LinkButton variant="link" to={{pathname: `/base/venue/${venueId}/venue-plan/${venuePlanId}`}} >*/}
                                {/*    Abbrechen*/}
                                {/*</LinkButton>*/}
                                <BackButton>
                                    Abbrechen
                                </BackButton>
                                <FeedbackButton
                                    onClick={() => formik.submitForm()}
                                    busy={submitPending}
                                >
                                    Speichern
                                </FeedbackButton>
                            </Footer>
                        </form>
                    )
                }}
            </Formik>
        )
    }
}

Form.propTypes = {
    onSubmit: PropTypes.any,
    submitPending: PropTypes.any,
    block: PropTypes.any,
    requestErrors: PropTypes.any,
    type: PropTypes.any,
    venuePlanId: PropTypes.any,
};


const mapStateToProps = (state, props) => {

    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps)(Form);
