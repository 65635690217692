import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import ConnectedListView from '../../../common/listView/ConnectedListView';
import FeedbackButton from '../../../common/FeedbackButton';
import {connect} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {get} from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import * as blockGroup from "../../../../state/entities/blockGroup";


class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDeleteConfirmDialog: false,
            blockGroupToDelete: undefined,
        }
    }

    showDeleteConfirmDialog(blockGroup) {
        this.setState({
            showDeleteConfirmDialog: true,
            blockGroupToDelete: blockGroup,
        });
    }

    handleCancelDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
    };

    handleConfirmDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
        this.props.deleteBlockGroup(this.state.blockGroupToDelete);
    };


    render() {

        return (
            <>
                <Row className="mb-4 mt-4">
                    <Col className="pt-3 col-sm-8">
                        <h1>Blockgruppen für den Saalplan <em>{this.props.venuePlan.name}</em></h1>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="form-group">
                            <FeedbackButton
                                to={`/base/venue-plan/${this.props.venuePlanId}/block-group/create`}
                                icon={<AddIcon />}
                                variant='contained'
                            >
                                Neue Blockgruppe
                            </FeedbackButton>
                        </div>
                    </Col>
                </Row>
                <ConnectedListView endpoint={`listingView/blockGroup?venuePlanId=${this.props.venuePlanId}`}>
                    {({fields, item}) => (
                        <>
                            <FeedbackButton
                                className="list-link"
                                title="Blockgruppe bearbeiten"
                                to={`/base/venue-plan/${this.props.venuePlanId}/block-group/${item.id}`}
                                icon={<EditNoteOutlinedIcon className='table-icon' />}
                            />
                            <FeedbackButton title="Blockgruppe löschen" className="list-link" onClick={() => this.showDeleteConfirmDialog(item)}>
                                <DeleteOutlineOutlinedIcon className='table-icon' />
                            </FeedbackButton>
                        </>
                    )}
                </ConnectedListView>


                <Modal show={this.state.showDeleteConfirmDialog} onHide={this.handleCancelDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Blockgruppe löschen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Möchten Sie die Blockgruppe "{get(this.state, 'blockGroupToDelete.name')}" wirklich löschen?</Modal.Body>
                    <Modal.Footer>
                        <FeedbackButton variant="outlined" onClick={this.handleCancelDelete}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton onClick={this.handleConfirmDelete}>
                            Löschen
                        </FeedbackButton>
                    </Modal.Footer>
                </Modal>

            </>
        );
    }
}



export default connect(null, {
    deleteBlockGroup: entity => blockGroup.actions.delete({entity})
})(List);
