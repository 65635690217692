import React, { useState } from 'react';
import {
    AutocompleteRenderOptionState,
    Autocomplete,
    TextField,
    MenuItem,
    FormHelperText
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

interface Option {
    id: string;
    name: string;
}

interface IMuiAutocomplete {
    label: string;
    value: Option;
    options: Option[];
    emptyValueLabel?: string;
    prefix?: string;
    error?: string | null;
    onChange: () => void;
}

export const MuiAutocomplete: React.FC<IMuiAutocomplete> = ({
    label,
    options,
    value,
    prefix,
    onChange,
    error,
    emptyValueLabel = 'Bitte wählen...',
    ...rest
}) => {

    const displayValue = value.name ? value : { id: '', name: emptyValueLabel }
    return (
        <>        
            <Autocomplete
                {...rest}
                disableClearable
                className={error ? 'MuiAutocomplete-error' : ''}
                value={displayValue}
                defaultValue={displayValue}
                onChange={onChange}
                options={options}
                getOptionLabel={(option) => `${option.name}`}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                renderInput={(params) => <TextField {...params} label={label} />}
                renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: Option, state: AutocompleteRenderOptionState) => {
                    const isSelected = option.id === state.inputValue;
                    return (
                        <MenuItem {...props} value={option.id}>
                            {isSelected ? <CheckIcon /> : null}
                            <span>{prefix}&nbsp;{option.name}</span>
                        </MenuItem>
                    )
                }}
            />
            {error && <FormHelperText className='Mui-error' sx={{ bottom: -12 }}>{error}</FormHelperText>}
        </>
    )

};

export default MuiAutocomplete
