import { Field, getIn } from 'formik';
import Form from "react-bootstrap/Form";
import _ from "lodash";
import {
    FormControl,
    InputLabel,
    Select as MuiSelect,
    MenuItem,
    FormHelperText
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import React from "react";

// This was "forked" from the FormikSelectGroup as the way this is used with
// an explicit onChange actually makes it unfit for use with Formik.

export const SalesRuleAssociationFormSelect = ({
    name,
    label,
    options,
    emptyValueLabel,
    isShowEmptyValue = true,
    displayEmpty = true,
    disabled,
    onChange = () => { },
    testid = ''
}) => (
    <Field name={name}>
        {({ field, form }) => {
            const isInvalid = getIn(form.touched, field.name) && getIn(form.errors, field.name);
            return (
                <FormControl
                    fullWidth
                    error={isInvalid}
                    variant="outlined"
                >
                    {label && <InputLabel>{label}</InputLabel>}
                    <MuiSelect
                        {...field}
                        onChange={(event) => {
                            form.setFieldValue(name, event.target.value);
                            onChange(event);
                        }}
                        label={label}
                        disabled={disabled}
                        displayEmpty={displayEmpty}
                        renderValue={(selected) => {
                            if (!selected) {
                                return <em>{emptyValueLabel}</em>;
                            }
                            const selectedOption = options.find(option => option.id === selected);
                            return selectedOption ? selectedOption.name : selected;
                        }}
                        sx={{
                            marginTop: 'inherit'
                        }}
                    >
                        {isShowEmptyValue && (
                            <MenuItem value="">
                                <em>{emptyValueLabel}</em>
                            </MenuItem>
                        )}

                        {/* Es muss der index als key genommen werden, da nicht garantiert ist,
                            dass label oder value der choice eindeutig sind. */}
                        {options[0].salesChannelId
                            ? options.map(({ name, salesChannelId }, index) => (
                                <MenuItem key={index} value={salesChannelId}>
                                    {field.value === salesChannelId && <CheckIcon />}
                                    {name}
                                </MenuItem>
                            ))
                            : options.map(({ name, id }, index) => (
                                <MenuItem key={index} value={id}>
                                    {field.value === id && <CheckIcon />}
                                    {name}
                                </MenuItem>
                            ))
                        }

                    </MuiSelect>
                    {isInvalid && <FormHelperText>{getIn(form.errors, field.name)}</FormHelperText>}
                </FormControl>
            );
        }}
    </Field>
);

export default SalesRuleAssociationFormSelect;
