import React from 'react';
import {Switch} from 'react-router-dom';

import List from './List';
import Details from './Details';
import {Container} from 'react-bootstrap';
import SecureRoute from "../auth/SecureRoute";

const Routing = () => {
    return (
        <Container className={'mt-1'}>
            <Switch>
                <SecureRoute exact path="/sales/order" component={List}/>
                <SecureRoute exact path="/sales/order/:id" component={Details}/>
            </Switch>
        </Container>
    )
};

export default Routing;
