import Api from '../../api';
import React, { useState } from 'react';
import * as yup from 'yup';
import { Formik, getIn } from 'formik';
import {Card, Col, Row} from 'react-bootstrap';
import {FormikTextInputGroup} from '../common/formik/FormikTextInputGroup';
import FormikAsyncTypeaheadInput from "../common/formik/FormikAsyncTypeaheadInput";
import FeedbackButton from '../common/FeedbackButton';
import Footer from "../layout/Footer";
import {connect} from "react-redux";
import ListingModal from "../common/modal/ListingModal";
import FieldImage from '../common/FieldImage/FieldImage';
import styles from "../form.module.scss";

const api = new Api();

const INITIAL_VALUES = {
    name: {
        de: '',
        en: '',
    },
    description: {
        de: '',
        en: ''
    },
    tags: {
        de: [],
        en: []
    },
    image: null
};

const FORM_SCHEMA = yup.object().shape({
    name: yup.object().shape({
        de: yup.string().required(),
        en: yup.string().required(),
    }),
    description: yup.object().shape({
        de: yup.string(),
        en: yup.string()
    }).nullable(),
    tags: yup.object().shape({
        de: yup.array().of(yup.string()).nullable().default([]),
        en: yup.array().of(yup.string()).nullable().default([]),
    }).nullable(),
    image: yup.object().shape({
        id: yup.string(),
        url: yup.string().url(),
        title: yup.string(),
    }).nullable()
});


const Form = ({onSubmit, submitPending, eventSeries, helpTextsVisible}) => {
    const [activeFieldName, setActiveFieldName] = useState('');
    const [isShowBatchModal, setIsShowBatchModal] = useState(false);

    const closeModal = () => {
        setIsShowBatchModal(false);
    }

    const onHandleShowModal = (e, fieldName) => {
        e.stopPropagation();
        setIsShowBatchModal(true);
        setActiveFieldName(fieldName);
    }
    return <Formik initialValues={{...INITIAL_VALUES, ...eventSeries}}
                   validationSchema={FORM_SCHEMA}
                   onSubmit={onSubmit}
    >
        {formik => {
            const onHanldleRemove = (e, fieldName) => {
                e.stopPropagation();
                formik.setFieldValue(fieldName, null);
            }
            const onHandleChooseImage = (item) => {
                const { id, title, url } = item;
                formik.setFieldValue(activeFieldName, {
                    title,
                    url,
                    id,
                });
                setIsShowBatchModal(false);
            }
            return (
                <>
                    <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                        <div className={styles.formBox}>
                            <h2 className={styles.formTitle}>Allgemein</h2>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup
                                        label="Name (deutsch)"
                                        name="name.de"
                                        testid="name_german"
                                        helpTextsVisible={helpTextsVisible}
                                        helpText="Dieser Name wird auf dem Ticket angezeigt (deutsch)."
                                    />
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup
                                        label="Name (englisch)"
                                        name="name.en"
                                        testid="name_english"
                                        helpTextsVisible={helpTextsVisible} />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FieldImage
                                        values={getIn(formik.values, 'image')}
                                        fieldName='image'
                                        label="Logo/ Bild Eventserie"
                                        onHanldleRemove={onHanldleRemove}
                                        onHandleShowModal={onHandleShowModal}
                                        helpText="Wird im Ticketauswahlfenster angezeigt."
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup
                                        type="textarea"
                                        label="Zusatztext (deutsch)"
                                        name="description.de"
                                        helpTextsVisible={helpTextsVisible}
                                        helpText="Beschreibungstext zu einer Eventserie. Ist im Eventlisting enthalten."
                                    />
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup
                                        type="textarea"
                                        label="Zusatztext (englisch)"
                                        name="description.en"
                                        helpTextsVisible={helpTextsVisible}
                                        helpText="Beschreibungstext zu einer Eventserie. Ist im Eventlisting enthalten."
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikAsyncTypeaheadInput
                                        id="tags.de"
                                        label="Eventserietag (deutsch)"
                                        onSearch={() => api.getTagsForEventSeriesByLang('de')}
                                        minLength={0}
                                        testid="tags.de"
                                        multiple={true}
                                        arrayOfStrings={true}
                                        allowNew={true}
                                        helpText="Tags, nach denen gesucht werden kann, um diese Eventserie zu finden."
                                    />
                                </Col>
                                <Col className="col-md-4">
                                    <FormikAsyncTypeaheadInput
                                        id="tags.en"
                                        label="Eventserietag (englisch)"
                                        onSearch={() => api.getTagsForEventSeriesByLang('en')}
                                        minLength={0}
                                        testid="tags.en"
                                        multiple={true}
                                        arrayOfStrings={true}
                                        allowNew={true}
                                        helpText="Tags, nach denen gesucht werden kann, um diese Eventserie zu finden."
                                    />
                                </Col>
                            </Row>
                        </div>
                        <Footer>
                            <FeedbackButton to={`/event-management/event-series`}>
                                Abbrechen
                            </FeedbackButton>
                            <FeedbackButton
                                type="submit"
                                busy={submitPending}
                            >
                                Speichern
                            </FeedbackButton>
                        </Footer>
                    </form>
                    <ListingModal
                        show={isShowBatchModal}
                        title="Bild wählen"
                        initialValues={INITIAL_VALUES}
                        validationSchema={FORM_SCHEMA}
                        onCancel={closeModal}
                        onHandleChooseImage={onHandleChooseImage}
                    />
                </>
            )
        }}
    </Formik>
};


const mapStateToProps = (state, props) => {

    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps, null)(Form);

