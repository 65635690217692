import {createSlice} from "redux-starter-kit";

const entityName = 'block';

export const byVenuePlan = createSlice({
    slice: entityName,
    initialState: {},
    reducers: {
        byVenuePlan: (state, {payload}) => {
            state[payload.venuePlan] = true;
        },
        byVenuePlanError: (state, {payload}) => {
            state[payload.venuePlan] = false;
        },
        byVenuePlanSuccess: (state, {payload}) => {
            // Als payload wird eine "normalizr" response erwartet
            state[payload.response.result] = false;
        }
    }
});
