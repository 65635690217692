import React, {Component} from 'react';
import Form from "./Form";
import * as eventCategory from "../../state/entities/eventCategory";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Create extends Component {

    onSubmit = (eventCategory, {setErrors}) => {
        this.props.createEventCategory(eventCategory, setErrors);
    };

    render() {
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["EventCategory"]} endpoints = {['event_category/breadcrumb']} title = "Eventarten" links={['/base/event-category/']} active={[0]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Hier können Sie die Eventarten einstellen.</h1>
                </Col>
            </Row>
            <Form eventCategory={{}} onSubmit={this.onSubmit} submitPending={this.props.createPending} />
        </>;
    }
}

const mapStateToProps = (state) => {
    return {
        createPending: state.entities.eventCategory.createPending
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createEventCategory: entity => dispatch(eventCategory.actions.create({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
