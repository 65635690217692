import React from 'react';
import Form from 'react-bootstrap/Form';
import {ItemField} from '../ListViewPropTypes';
import PropTypes from 'prop-types';
import classnames from "classnames";
import {
    Checkbox,
    FormGroup,
    FormControlLabel,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Button
} from '@mui/material';
import {get, isEmpty} from 'lodash';
import CancelIcon from '@mui/icons-material/Cancel';

function PrefixFilterConfig({field, filter, onFilterChange}) {

    function toggleOnlyEmptyValue(event) {
        if (event.target.checked) {
            onFilterChange({empty: true});
        } else {
            onFilterChange(undefined);
        }
    }

    function handlePrefixChange(event) {
        if (isEmpty(event.target.value)) {
            onFilterChange(undefined);
        } else {
            onFilterChange({prefix: event.target.value});
        }
    }

    function clearFilter() {
        onFilterChange(undefined);
    }

    return (
        <Form>
            <FormControl
                fullWidth
                variant="outlined"
                sx={{
                    paddingBottom: 0
                }}
            >
                <InputLabel className={classnames('MuiLabel-noHover, MuiLabel-topSpace')}>Geben Sie einen Präfix für <strong>{field.label || field.name}</strong> ein nach dem gefiltert werden soll.</InputLabel>
                <OutlinedInput
                    value={get(filter, 'prefix', '')}
                    onChange={handlePrefixChange}
                    endAdornment={
                        <InputAdornment position="end">
                            {get(filter, 'prefix') && (
                                <IconButton onClick={clearFilter} edge="end" tabIndex={-1}>
                                    <CancelIcon sx={{ color: '#494949'}} />
                                </IconButton>
                            )}
                        </InputAdornment>
                    }
                />
            </FormControl>

            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox checked={!!get(filter, 'empty', '')} onChange={toggleOnlyEmptyValue} sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }} />
                    }
                    label="Nur Einträge ohne Wert anzeigen"
                    sx={{
                        marginBottom: 0
                    }}
                />
            </FormGroup>
            <Button
                variant="text"
                onClick={clearFilter}
                sx={{ display: 'flex', marginLeft: 'auto' }}
            >
                Filterung aufheben
            </Button>
        </Form>
    );
}

PrefixFilterConfig.propTypes = {
    field: ItemField.isRequired,
    filter: PropTypes.any,
    onFilterChange: PropTypes.func.isRequired
};

export default PrefixFilterConfig;
