import {Container, Polygon} from 'pixi.js';
import { AreaForm } from './areaFormItem';
import { AreaFormData } from './AreaFormData';
import { IVisualsManager, VisualsManager } from '../editableVisuals/visualsManager';
import { SEAT_SIZE } from '../scene';
import { calculateBoundingBox } from '../../geometry';
import { Vector } from '@flatten-js/core';


export const AREAFORMS_ENABLED = true;  //for intermediate releases


export const MIN_AREAFORM_WIDTH = SEAT_SIZE * 0.5;


export interface IAreaFormsManager extends IVisualsManager<AreaFormData> {
    onRequestAddAreaForm: (rect: Polygon) => void;
}



export class AreaFormsManager extends VisualsManager<AreaForm, AreaFormData> implements IAreaFormsManager {

    constructor(
        itemsContainer: Container,
        overlay: Container,
        private getZoomFactor: () => number, //callback to retrieve shown zoom factor
    ) {
        super(itemsContainer, overlay);        
        this.itemsContainer.visible = this.sensorsContainer.visible
            = this.handlesContainer.visible = AREAFORMS_ENABLED;
        this.setEditMode(false);
    }


    public requestAddAreaForm(rect: Polygon) {
        const points = [];
        for (let ci = 0; ci < rect.points.length; ci += 2) {
            points.push(new Vector(rect.points[ci], rect.points[ci + 1]));
        }
        const bb = calculateBoundingBox(points);
        if (bb.height < MIN_AREAFORM_WIDTH || bb.width < MIN_AREAFORM_WIDTH) rect = undefined;
        if (this.onRequestAddAreaForm) this.onRequestAddAreaForm(rect);
    }

    public onRequestAddAreaForm: (rect: Polygon) => void = undefined;


    protected visualItemFactory(
        dataItem: AreaFormData,
        index: number,
        itemsContainer: Container,
        sensorsContainer: Container,
        handlesContainer: Container,
        onSelectItem: (item: AreaForm) => void,
        onItemChanged: (itemData: AreaFormData) => void
    ): AreaForm {
        const newAreaFormDO = new AreaForm(
            dataItem,
            itemsContainer,
            sensorsContainer,
            handlesContainer,
            onSelectItem,
            onItemChanged,
            this.getZoomFactor
        );
        return newAreaFormDO;
    }
}
