import {
    AbstractIdleState,
    AbstractPointerDownState,
    DisplayContext,
    DRAGGING_START_THRESHOLD,
    DraggingState,
    DrawRectState,
    InteractionState
} from './common';
import {FederatedPointerEvent} from 'pixi.js';
import {difference, distance, Point, sum} from '../../geometry';
import type {Selection} from '../data';
import {Seat} from '../../../types';
import Flatten from '@flatten-js/core';
import { MARQUEE_CLICK_TARGET } from '../scene';


export class SelectionIdleState extends AbstractIdleState {

    onPointerDown(context: DisplayContext, origin: Point, event: FederatedPointerEvent): InteractionState {
        const selection = context.dataManager.getCurrentSelection();

        // user clicked on one of marquee's handle?
        if (selection?.getMarqueeClickTargetType(event.target) === MARQUEE_CLICK_TARGET.HANDLES) {
            return new SelectionRotateState(origin, selection);
        }

        // user clicked on marquee's area to move it? get the clicked seat.
        const pickedSeat = context.dataManager.pickSeat(context.scene.convertToViewportCoords(origin));

        return new SelectionPointerDownState(
            origin,
            selection,
            pickedSeat,
            selection?.getMarqueeClickTargetType(event.target) === MARQUEE_CLICK_TARGET.AREA
        );
    }

    onEviction(context: DisplayContext): void {
        context.dataManager.clearCurrentSelection();
    }

}

class SelectionPointerDownState extends AbstractPointerDownState {

    constructor(
        origin: Point,
        private selection: Selection | undefined,
        private pickedSeat: Readonly<Seat> | undefined,
        private dragSelection: boolean
    ) {
        super(origin);
    }

    onPointerUp(context: DisplayContext): InteractionState {
        // If clicked on empty space then, if not shift pressed, remove selection
        if (!this.shiftKeyPressed && !this.pickedSeat) {
            context.dataManager.clearCurrentSelection();
            return new SelectionIdleState();
        }

        // Select clicked seat, or, if shift pressed, add it to selection
        let newSelection = this.pickedSeat ? [this.pickedSeat] : [];
        if (this.shiftKeyPressed) {
            newSelection = newSelection.concat(context.dataManager.getSelectedSeats())
                .filter((seat, index, self) => index === self.findIndex((s) => s.id === seat.id)); //remove duplicates
        }
        context.dataManager.selectSeats(newSelection);
        return new SelectionIdleState();
    }

    onPointerMove(context: DisplayContext, current: Point): InteractionState {
        // Nur wenn eine bestimmte Auslenkung festgestellt wird eine Aktion ausführen,
        // um unbeabsichtigte Manipulation durch "Zucken" zu unterdrücken.
        if (distance(this.origin, current) < DRAGGING_START_THRESHOLD) {
            return this;
        }

        if (!this.dragSelection) {
            if (!this.pickedSeat) {  // if user clicked on empty space (not on a seat) we switch over to marquee dragging.
                return new SelectionMarqueeState(this.origin, this.selection);
            } else {  // since clicked seat is not inside the drag rect (no dragSelection) we make it the new selection
                this.selection = context.dataManager.selectSeats([this.pickedSeat]);
            }
        }

        // übergehen zum Verschieben der Auswahl
        return new SelectionMoveState(this.origin, this.selection);
    }
}

export class SelectionMarqueeState extends DrawRectState {

    constructor(origin: Point, private selection: Selection | undefined) {
        super(origin, 0xFF6666);
    }

    protected processRect(rect: Flatten.Box, context: DisplayContext): InteractionState {
        const selectedSeats = this.shiftKeyPressed ? context.dataManager.getSelectedSeats() : [];
        if (this.selection) context.dataManager.clearCurrentSelection(true);
        //on shift, merge new selection with previous selection
        context.dataManager.selectSeatsByMarquee(rect, selectedSeats);
        return new SelectionIdleState();
    }
}

class SelectionMoveState extends DraggingState {

    /**
     * @param origin Der Startpunkt der Verschiebung.
     * @param selection Die aktuelle Auswahl.
     */
    constructor(
        protected readonly origin: Point,
        private readonly selection: Selection
    ) {
        super(origin);
    }

    /**
     * Verschiebt die Auswahl entsprechen der aktuellen pointer Position.
     *
     * @param commitTransform Flag ob die Transformation der Auswahl auch angewendet werden soll,
     * i.d.R nur sinnvoll, wenn die Verschiebung "abgeschlossen" ist.
     */
    private moveSelection(context: DisplayContext, commitTransform = false): void {
        const localOrigin = context.scene.convertToViewportCoords(this.origin);
        const localCurrent = context.scene.convertToViewportCoords(this.current);

        // Da es unwahrscheinlich ist, dass der Benutzer beim Verschieben der Auswahl diese
        // genau auf den anchor geklickt hat, können wir nicht einfach die Auswahl auf die aktuelle
        // Positions des pointer verschieben, da dies den Effekt hätte, das die Auswahl
        // abrupt auf die Position des pointer springt. Stattdessen addieren wir einfach den
        // offset zwischen aktueller und anfänglicher Position zum anchor.
        const newPosition = sum(
            this.selection.anchor,
            difference(localCurrent, localOrigin)
        );

        // Evtl. ist snap-to-grid o.Ä. aktiv, sodass die neue Position ggf. eingeschränkt werden muss.
        this.selection.move(context.constrainPosition(newPosition));

        if (commitTransform) {
            this.selection.commitTransform();
        }
    }

    protected abort(context: DisplayContext): void {
        context.dataManager.clearCurrentSelection();
        context.scene.toggleSeatAndRowLabelDisplay(true);
    }

    protected finish(context: DisplayContext): InteractionState {
        this.moveSelection(context, true);
        context.scene.toggleSeatAndRowLabelDisplay(true);
        return new SelectionIdleState();
    }

    protected update(context: DisplayContext): InteractionState {
        context.scene.toggleSeatAndRowLabelDisplay(false);  //lieber bei onInstallation statt bei jedem update?
        this.moveSelection(context);
        return this;
    }

}

class SelectionRotateState extends DraggingState {

    /**
     * @param origin Der Startpunkt der Drehung.
     * @param selection Die aktuelle Auswahl.
     */
    constructor(
        protected readonly origin: Point,
        private readonly selection: Selection
    ) {
        super(origin);
    }

    /**
     * Dreht die Auswahl entsprechen der aktuellen pointer Position.
     *
     * @param commitTransform Flag ob die Transformation der Auswahl auch angewendet werden soll,
     * i.d.R nur sinnvoll, wenn die Drehung "abgeschlossen" ist.
     */
    private rotateSelection(context: DisplayContext, commitTransform = false): void {
        this.selection.rotate(this.origin, this.current);

        if (commitTransform) {
            this.selection.commitTransform();
        }
    }

    protected abort(context: DisplayContext): void {
        context.dataManager.clearCurrentSelection();
    }

    protected finish(context: DisplayContext): InteractionState {
        context.scene.toggleSeatAndRowLabelDisplay(true);
        this.rotateSelection(context, true);
        return new SelectionIdleState();
    }

    protected update(context: DisplayContext): InteractionState {
        context.scene.toggleSeatAndRowLabelDisplay(false);
        this.rotateSelection(context);
        return this;
    }
}
