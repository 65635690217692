import React from 'react';
import {Col, Row} from "react-bootstrap";
import {FormattedDate} from "react-intl";
import Euro from "../common/numberFormat/Euro";
import PaymentEvent from "./PaymentEvent";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefundDetails from "./RefundDetails";

const PaymentDetails = ({payment}) => {
    return (
        <>
            <Row>
                <Col className="col-sm-6 col-lg-2">
                    Payment Reference
                </Col>
                <Col className="col-sm-6">
                    {payment.paymentReference}
                </Col>
            </Row>
            <Row>
                <Col className="col-sm-6 col-lg-2">
                    Type
                </Col>
                <Col className="col-sm-6">
                    {payment.type}
                </Col>
            </Row>
            <Row>
                <Col className="col-sm-6 col-lg-2">
                    Zahlart
                </Col>
                <Col className="col-sm-6">
                    {payment.paymentProductIdLabel ?? payment.paymentMethodLabel ??  payment.paymentMethod ?? '-'}
                </Col>
            </Row>
            {payment.type === 'payone' && payment.payonePaymentId && <Row>
                <Col className="col-sm-6 col-lg-2">
                    Payone payment id
                </Col>
                <Col className="col-sm-6">
                    {payment.payonePaymentId}
                </Col>
            </Row>}
            {payment.type === 'mollie' && payment.molliePaymentId && <Row>
                <Col className="col-sm-6 col-lg-2">
                    Mollie payment id
                </Col>
                <Col className="col-sm-6">
                    {payment.molliePaymentId ? <a href={`https://my.mollie.com/dashboard/payments/${payment.molliePaymentId}`} target="_blank" rel="noreferrer">{payment.molliePaymentId}</a> : '-'}
                </Col>
            </Row>}
            {payment.type === 'mollieOrder' && payment.mollieOrderId && <Row>
                <Col className="col-sm-6 col-lg-2">
                    Mollie order id
                </Col>
                <Col className="col-sm-6">
                    {payment.mollieOrderId ? <a href={`https://my.mollie.com/dashboard/orders/${payment.mollieOrderId}`} target="_blank" rel="noreferrer">{payment.mollieOrderId}</a> : '-'}
                </Col>
            </Row>}
            <Row>
                <Col className="col-sm-6 col-lg-2">
                    Amount
                </Col>
                <Col className="col-sm-6">
                    <Euro centAmount={payment.amount}/>
                </Col>
            </Row>
            <Row>
                <Col className="col-sm-6 col-lg-2">
                    Erstatteter Betrag
                </Col>
                <Col className="col-sm-6">
                    <Euro centAmount={payment.refundedAmount}/>
                </Col>
            </Row>
            {payment.pendingRefundedAmount && <Row>
                <Col className="col-sm-6 col-lg-2">
                    Anhängig Erstatteter Betrag
                </Col>
                <Col className="col-sm-6">
                    <Euro centAmount={payment.pendingRefundedAmount}/>
                </Col>
            </Row>}
            <Row>
                <Col className="col-sm-6 col-lg-2">
                    Status
                </Col>
                <Col className="col-sm-6">
                    {payment.status}
                </Col>
            </Row>
            {payment.type === 'payone' && <Row>
                <Col className="col-sm-6 col-lg-2">
                    Payone status
                </Col>
                <Col className="col-sm-6">
                    {payment.payonePaymentStatus}
                </Col>
            </Row>}
            {payment.type === 'mollie' && <Row>
                <Col className="col-sm-6 col-lg-2">
                    Mollie status
                </Col>
                <Col className="col-sm-6">
                    {payment.molliePaymentStatus}
                </Col>
            </Row>}
            {payment.type === 'mollieOrder' && <Row>
                <Col className="col-sm-6 col-lg-2">
                    Mollie status
                </Col>
                <Col className="col-sm-6">
                    {payment.mollieOrderStatus}
                </Col>
            </Row>}
            <Row>
                <Col className="col-sm-6 col-lg-2">
                    Status changes
                </Col>
                <Col className="col-sm-6">
                    {payment.statusChanges.map((statusChange, index) => <Row key={index}>
                        <Col>
                            <FormattedDate value={statusChange.occurred} year="numeric" month="2-digit"
                                           day="2-digit" hour="2-digit" minute="2-digit" second="2-digit"/>
                        </Col>
                        <Col>
                            {statusChange.status}
                        </Col>
                    </Row>)}
                </Col>
            </Row>
            <Row className="mt-2">
                <Col className="col-sm-12 col-xl-8">
                    <Accordion className="paymentEvents">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <Typography>Zahlungsvorgänge</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {payment.paymentEvents.map((paymentEvent, index) => <PaymentEvent key={index}
                                                                                              paymentEvent={paymentEvent}/>)}
                        </AccordionDetails>
                    </Accordion>
                </Col>
            </Row>
            {payment.refunds && payment.refunds.length > 0 && <Row className="mt-2">
                {payment.refunds.map(refund => (
                    <Col className="col-sm-12 col-xl-8" key={refund.id}>
                        <Accordion className="paymentEvents">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                            >
                                <Typography>Stornoprozess von <FormattedDate
                                    value={refund.createdAt}
                                    year="numeric" month="2-digit"
                                    day="2-digit" hour="2-digit" minute="2-digit" second="2-digit"/> - {refund.status}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <RefundDetails
                                    refund={refund}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Col>
                ))}
            </Row>}
        </>
    )
}

export default PaymentDetails;
