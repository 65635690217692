import React from "react";
import {localItemTemplateIsComplete} from "./helper";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";

export default ({localItemTemplate, toggleEditLocalItemTemplate}) => {
    if (!localItemTemplateIsComplete(localItemTemplate)) {
        return <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id={`tooltip-mainpricingwarning-${localItemTemplate.id}`}>
                    ACHTUNG: Leistung unverkaufbar, Buchungsdaten fehlen.
                </Tooltip>
            }
        >
            <Button variant="link" className="p-0" onClick={() => toggleEditLocalItemTemplate(localItemTemplate.id)}>
                <span className="fa-stack incomplete-warning">
                  <i className="fa fa-circle-o fa-stack-2x text-danger"/>
                  <i className="fa fa-exclamation fa-stack-1x fa-inverse text-danger"/>
                </span>
            </Button>

        </OverlayTrigger>
    }

    return null;
};
