import React, {Component} from 'react';
import Form from './Form';
import {actions} from '../../state/entities/metaEvent';
import {connect} from 'react-redux';
import {LoadingIndicator} from '../common/LoadingIndicator';
import {Col, Row} from 'react-bootstrap';

import {denormalize} from 'normalizr';
import * as schema from '../../state/schema';
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Edit extends Component {
    componentDidMount() {
        this.props.load(this.props.metaEventId);
    }

    onSubmit = (metaEvent) => {
        let event = metaEvent;
        if (metaEvent.maxTicketsPerUser === "") {
            event = {...metaEvent, maxTicketsPerUser: null}
        }
        this.props.save(event);
    };

    getMetaEvent = () => {
        let event = {
            ...this.props.metaEvent,
            ticketSelectionExpiration: this.props.metaEvent.ticketSelectionExpiration / 60
        };
        if (this.props.metaEvent.maxTicketsPerUser === null) {
            event = {...event, maxTicketsPerUser: ""}
        }
        let rules = event.salesRules.map(rule => {
            if (rule.maxTicketsPerUser === null) {
                return {...rule, maxTicketsPerUser: ""};
            } else {
                return rule;
            }
        })
        return {...event, salesRules: rules}
    }

    render() {
        const {metaEvent, loading, saving} = this.props;
        if (!metaEvent || loading) {
            return <LoadingIndicator/>;
        }

        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Event"]} endpoints={['meta_event/breadcrumb']} title="Events"
                             links={['/event-management/meta-event/']} active={[metaEvent.id]}/>
            </Row>
            <Row className="">
                <Col className="mb-4 mt-4 pt-3 col-sm-8">
                    <h1>Bearbeiten des Meta-Events <em>{metaEvent.title.de}</em></h1>
                </Col>
            </Row>
            <Form metaEvent={this.getMetaEvent()} onSubmit={this.onSubmit} submitPending={saving}/>
        </>;
    }
}

const mapStateToProps = (state, props) => {
    const metaEventId = props.match.params.id;

    // Das Event liegt liegt in normalisierter Form vor, daher müssen wir es denormalisieren,
    // damit wir Informationen für referenzierte Daten wie die Preisliste anzeigen können.
    const metaEvent = denormalize(metaEventId, schema.metaEvent, {
        metaEvent: state.entities.metaEvent.byId,
        pricingList: state.entities.pricingList.byId,
    });

    return {
        metaEventId,
        loading: state.entities.metaEvent.loading[metaEventId],
        saving: state.entities.metaEvent.saving[metaEventId],
        metaEvent
    }
};

const mapDispatchToProps = dispatch => {
    return {
        load: id => dispatch(actions.load({id})),
        save: entity => dispatch(actions.save({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
