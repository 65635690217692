import React from 'react';
import PropTypes from 'prop-types';
import ObjectSelect from './ObjectSelect';
import MatrixCell from "./MatrixCell";
import MatrixRowHeader from "./MatrixRowHeader";
import MatrixColumnHeader from "./MatrixColumnHeader";
import ConfirmModal from "../../common/modal/ConfirmModal";
import {useDrop} from "react-dnd";
import DragAndDropItemTypes from "./DragAndDropItemTypes";
import {dragOver} from "../../../state/entities/pricingMatrix/dragAndDrop/actions";
import {connect} from "react-redux";
import {hasItemTemplates} from "../../../state/entities/pricingMatrix/cellMap/selectors";
import {getLocalItemTemplateById} from "./helper";
import {matrixDrop} from "../../../state/entities/pricingMatrix/cellMap/actions";
import {getSelectableForPricingMatrix as getSelectablePricingCategories} from "../../../state/entities/pricingCategory/selectors";
import {getSelectableForPricingMatrix as getSelectablePricingClasses} from "../../../state/entities/pricingClass/selectors";
import {addPricingCategory, addPricingClass} from "../../../state/entities/pricingMatrix/actions";

const Matrix = (props) => {
    const {
        pricingMatrix,
        onAddPricingCategory, onAddPricingClass,
        selectablePricingCategories, selectablePricingClasses,
        onDragOver, onDrop,
        hasItems,
    } = props;


    let confirm;
    const confirmModal = <ConfirmModal
        title="Leistungsvorlagen überschreiben?"
        body="Es sind bereits Leistungsvorlagen hinterlegt. Wollen Sie diese überschreiben?"
    >
        {c => {
            confirm = c
        }}
    </ConfirmModal>;

    const [{dragOver, backgroundColor}, dropRef] = useDrop({
        accept: DragAndDropItemTypes.PURCHASABLE_ITEM_TEMPLATE,
        drop: dragItem => hasItems ? confirm(() => onDrop(dragItem.id)) : onDrop(dragItem.id),
        hover: () => {
            onDragOver('ALL')
        },
        collect: monitor => ({
            dragOver: !!monitor.isOver(),
            backgroundColor: monitor.getItem() && monitor.getItem().backgroundColor
        }),
    });
    const styling = {
        backgroundColor: dragOver ? backgroundColor : 'white'
    };

    const shadow = {
        boxShadow: "0px 0px 5px 0px lightgray"
    };

    return (
        <>
            {confirmModal}
            <div className="pricing-matrix-table-pricing-category-header">
                Preiskategorien
            </div>
            <table className="table table-bordered pricing-matrix" style={shadow}>
                <thead>
                <tr>
                    <th className="legend" style={styling} ref={dropRef}>
                        <span className="pull-left">
                            Preisklassen
                        </span>
                    </th>
                    {pricingMatrix.pricingCategories.map(category =>
                        <MatrixColumnHeader key={category.id} pricingCategory={category} pricingMatrix={pricingMatrix}/>
                    )}
                    <td className="add-column">
                        <ObjectSelect
                            label=""
                            classname="dropdown-top"
                            alignRight={true}
                            drop="down"
                            buttonHint="Preiskategorie hinzufügen"
                            emptySelection="«Preiskategorie auswählen»"
                            options={selectablePricingCategories}
                            onSelect={onAddPricingCategory}
                        />
                    </td>
                </tr>
                </thead>
                <tbody>
                {pricingMatrix.pricingClasses.map(pricingClass =>
                    <tr key={pricingClass.id}>
                        <MatrixRowHeader pricingClass={pricingClass} pricingMatrix={pricingMatrix}/>
                        {pricingMatrix.pricingCategories.map(category =>
                            <MatrixCell key={category.id}
                                        pricingMatrix={pricingMatrix}
                                        pricingCategory={category} pricingClass={pricingClass}
                            />
                        )}
                        <td></td>
                    </tr>
                )}
                <tr>
                    <td className="bottom-row">
                        <ObjectSelect
                            label=""
                            classname="dropdown-bottom"
                            alignRight={false}
                            drop="up"
                            buttonHint="Preisklasse hinzufügen"
                            emptySelection="«Preisklasse auswählen»"
                            options={selectablePricingClasses}
                            onSelect={onAddPricingClass}
                        />
                    </td>
                    {pricingMatrix.pricingCategories.map(category => <td key={category.id}></td>)}
                    <td></td>
                </tr>
                </tbody>
            </table>
        </>
    );
};

const AxisValue = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
});

Matrix.propTypes = {
    pricingMatrix: PropTypes.shape({
        pricingCategories: PropTypes.arrayOf(AxisValue).isRequired,
        pricingClasses: PropTypes.arrayOf(AxisValue).isRequired,
    }).isRequired,
    selectablePricingCategories: PropTypes.arrayOf(AxisValue).isRequired,
    selectablePricingClasses: PropTypes.arrayOf(AxisValue).isRequired,
    onAddPricingCategory: PropTypes.func,
    onAddPricingClass: PropTypes.func
};

const mapStateToProps = (state, ownProps) => ({
    selectablePricingCategories: getSelectablePricingCategories(state, ownProps.pricingMatrix),
    selectablePricingClasses: getSelectablePricingClasses(state, ownProps.pricingMatrix),
    hasItems: hasItemTemplates(state, ownProps.pricingMatrix.id),
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onDragOver: id => dispatch(dragOver(id)),
        onDrop: (itemTemplateId) => {
            const localItemTemplate = getLocalItemTemplateById(ownProps.pricingMatrix, itemTemplateId);
            if (localItemTemplate) {
                dispatch(matrixDrop({
                    pricingMatrix: ownProps.pricingMatrix, cellData: {localItemTemplateId: localItemTemplate.id}
                }));
            }
        },
        onAddPricingClass: pricingClass => dispatch(addPricingClass({
            matrixId: ownProps.pricingMatrix.id,
            pricingClass
        })),
        onAddPricingCategory: pricingCategory => dispatch(addPricingCategory({
            matrixId: ownProps.pricingMatrix.id,
            pricingCategory
        }))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Matrix);
