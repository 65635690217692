import {all, takeEvery} from 'redux-saga/effects';
import { history } from '../../configureStore';
import entitySlice from '../entity/slice';
import Api from '../../../api';
import {zukoZone as schema, zukoZoneList as schemaList} from '../../schema';
import creatingSlice from "../entity/slice/create";
import {combineReducers} from "redux";
import byId from "../entity/slice/byId";
import createSaga from "../entity/saga/create";
import {byVenue} from './slice';
import {byVenueSaga} from './saga';
import ZukoZone from "./resource";
import savingSlice from "../entity/slice/save";
import loadingSlice from "../entity/slice/load";
import deletingSlice from "../entity/slice/delete";
import loadSaga from "../entity/saga/load";
import saveSaga from "../entity/saga/save";
import deleteSaga from "../entity/saga/delete";

const onCreateSuccess = entity => history.push(`/base/venue/${entity.venue.id}`);
const onDeleteSuccess = entity => history.push(`/base/venue/${entity.venue}`);
const entityName = 'zukoZone';

const slice = entitySlice(entityName);


const api = new Api();
const resource = new ZukoZone(api.axios);

const createSlice = creatingSlice(entityName);
const loadSlice = loadingSlice(entityName);
const saveSlice = savingSlice(entityName);
const deleteSlice = deletingSlice(entityName);

export const actions = {
    ...createSlice.actions,
    ...loadSlice.actions,
    ...saveSlice.actions,
    ...deleteSlice.actions,
    ...byVenue.actions
};

export const reducer = combineReducers({
    creating: slice.reducer,
    loading: loadSlice.reducer,
    saving: saveSlice.reducer,
    deleting: deleteSlice.reducer,
    byId: byId(entityName),
    byVenue: byVenue.reducer
});

export const saga = function* saga() {
    yield all([
        takeEvery(actions.load, loadSaga({actions, resource, schema})),
        takeEvery(actions.save, saveSaga({actions, resource, schema})),
        takeEvery(actions.delete, deleteSaga({actions, resource, schema, onSuccess: onDeleteSuccess})),
        takeEvery(actions.create, createSaga({actions, resource, schema, onSuccess: onCreateSuccess})),
        takeEvery(actions.byVenue, byVenueSaga({actions, resource, schemaList})),
    ])
};


