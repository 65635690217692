import React from 'react';
import {
    FormControl,
    InputLabel,
    Select as MuiSelect,
    MenuItem
} from '@mui/material';
import { isEmpty } from 'lodash';
import CheckIcon from '@mui/icons-material/Check';

interface Option {
    id: string;
    name: string
}

interface MuiSelectProps {
    label: string;
    setActiveKey?: string;
    options: Option[];
    emptyValueLabel?: string;
    isShowEmptyValue?: boolean;
    inputRef?: React.Ref<HTMLDivElement>;
    displayEmpty?: boolean;
    disabled?: boolean;
    value: string | null
    onChange?: (value: string | null) => void
}

export const MaterialSelect: React.FC<MuiSelectProps> = ({
    label,
    value,
    options,
    inputRef,
    emptyValueLabel = 'Bitte wählen...',
    isShowEmptyValue = true,
    displayEmpty = true,
    disabled,
    setActiveKey='id',
    onChange = () => {},
    ...rest
}) => {
    return (
        <FormControl fullWidth variant="outlined" ref={inputRef}>
            <InputLabel>{label}</InputLabel>
            <MuiSelect
                {...rest}
                label={label}
                value={value}
                displayEmpty={displayEmpty}
                renderValue={(selected) => {
                    if (isEmpty(selected)) {
                        return <em>{emptyValueLabel}</em>;
                    }
                    const selectedOption = !isEmpty(options) && options.find(option => option.id === selected);
                    
                    if (!selectedOption) return '';

                    return selectedOption ? selectedOption.name : selected;
                }}
                disabled={!!disabled}
                onChange={(event) => {
                    const selectedValue = event.target.value === '' ? null : event.target.value;
                    onChange(selectedValue);
                }}
            >
                {isShowEmptyValue && (
                    <MenuItem value="">
                        <em>{emptyValueLabel}</em>
                    </MenuItem>
                )}
                {!isEmpty(options) && options.map(({ name, id }) => {
                    return (
                        <MenuItem key={id} value={id}>
                            {value === id && <CheckIcon />}
                            {name}
                        </MenuItem>
                    )
                })}
            </MuiSelect>
        </FormControl>
    )

};

export default MaterialSelect;
