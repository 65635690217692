import React, {Component} from 'react';
import Form from "./Form";
import {connect} from "react-redux";
import {LoadingIndicator} from "../common/LoadingIndicator";
import {Col, Row} from "react-bootstrap";
import * as artist from "../../state/entities/artist";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Edit extends Component {

    componentDidMount() {
        this.props.loadartist(this.props.artistId);
    }

    onSubmit = (artist, {setErrors}) => {
        this.props.saveartist(artist, setErrors);
    };

    render() {
        const {artist, loading, saving} = this.props;

        if (!artist || loading) {
            return <LoadingIndicator/>;
        }
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Artist"]} endpoints = {['artist/breadcrumb']} title = "Künstler" links={['/base/artist/']} active={[artist.id]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Bearbeiten des Künstler <em>{artist.name}</em></h1>
                </Col>
            </Row>
            <Form artist={artist} onSubmit={this.onSubmit} submitPending={saving} />
        </>;
    }
}

const mapStateToProps = (state, props) => {
    const artistId = props.match.params.id;
    return {
        artistId,
        loading: state.entities.artist.loading[artistId],
        saving: state.entities.artist.saving[artistId],
        artist: state.entities.artist.byId[artistId]
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadartist: id => dispatch(artist.actions.load({id})),
        saveartist: entity => dispatch(artist.actions.save({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
