import {createSlice} from 'redux-starter-kit';

/**
 * Generischer Redux Slice für "loadAll" State/Actions einer Entität.
 *
 * @param entityName Der Name der Entität für die der Slice erzeugt wird.
 */
export default (entityName) => createSlice({
    slice: entityName,
    initialState: false,
    reducers: {
        loadAll: () => true,
        loadAllError: () => false,
        loadAllSuccess: () =>  false
    }
})
