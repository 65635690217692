import React, {Component} from 'react';
import Form from './Form';
import {connect} from 'react-redux';
import {Col, Row} from 'react-bootstrap';

import {hslTupleToHsl, toHex} from '@wessberg/color';
import * as purchasableItemTemplate from "../../state/entities/purchasableItemTemplate";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Create extends Component {

    constructor(props) {
        super(props);

        this.state = {
            suggestedColor: toHex(hslTupleToHsl([Math.random() * 360, 50, 50]))
        }
    }

    onSubmit = (purchasableItemTemplate, {setErrors}) => {
        this.props.createPurchasableItemTemplate(purchasableItemTemplate, setErrors);
    };

    render() {

        const newTemplate = {
            color: this.state.suggestedColor,
            pricingComponents: []
        };

        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Leistungsvorlage"]} endpoints = {['purchasable_item_template/breadcrumb']} title = "Leistungsvorlagen" links={['/event-management/purchasable-item-template/']} active={[0]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Erstellen einer neuen Leistungsvorlage</h1>
                </Col>
            </Row>
            <Form purchasableItemTemplate={newTemplate} onSubmit={this.onSubmit} submitPending={this.props.createPending}/>
        </>;
    }
}

const mapStateToProps = (state) => {
    return {
        createPending: state.entities.purchasableItemTemplate.createPending
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createPurchasableItemTemplate: entity => dispatch(purchasableItemTemplate.actions.create({entity}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
