import React from 'react';
import {Redirect, Switch} from 'react-router-dom';

import Edit from './Edit';
import {Container} from 'react-bootstrap';
import SecureRoute from '../auth/SecureRoute';
import PastMetaEventList from "./PastMetaEventList";

export default () => (
    <Container className={'mt-1'}>
        <Switch>
            <SecureRoute exact path="/event-management/past-meta-event" component={PastMetaEventList}/>
            <SecureRoute exact path="/event-management/past-meta-event/create">
                <Redirect to="/event-management/meta-event/create" />
            </SecureRoute>
            <SecureRoute exact path="/event-management/past-meta-event/:id" component={Edit}/>
        </Switch>
    </Container>
);
