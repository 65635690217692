import React, {Component} from 'react';
import Form from "./Form";
import {connect} from "react-redux";
import {LoadingIndicator} from "../common/LoadingIndicator";
import {Col, Row} from "react-bootstrap";
import * as seatingType from "../../state/entities/seatingType";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Edit extends Component {

    componentDidMount() {
        this.props.loadSeatingType(this.props.seatingTypeId);
    }

    onSubmit = (seatingType, {setErrors}) => {
        this.props.saveSeatingType(seatingType, setErrors);
    };

    render() {
        const {seatingType, loading, saving} = this.props;
        if (!seatingType || loading) {
            return <LoadingIndicator/>;
        }
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Bestuhlungstyp"]} endpoints = {['seating_type/breadcrumb']} title = "Bestuhlungstypen" links={['/base/seating-type/']} active={[seatingType.id]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Bearbeiten des Bestuhlungstyps <em>{seatingType.name}</em></h1>
                </Col>
            </Row>
            <Form seatingType={seatingType} onSubmit={this.onSubmit} submitPending={saving} />
        </>;
    }
}

const mapStateToProps = (state, props) => {
    const seatingTypeId = props.match.params.id;
    return {
        seatingTypeId,
        loading: state.entities.seatingType.loading[seatingTypeId],
        saving: state.entities.seatingType.saving[seatingTypeId],
        seatingType: state.entities.seatingType.byId[seatingTypeId]
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadSeatingType: id => dispatch(seatingType.actions.load({id})),
        saveSeatingType: entity => dispatch(seatingType.actions.save({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
