import React, { useEffect, useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormikSelect } from '../common/formik/FormikSelect';
import { FormikTextInputGroup } from '../common/formik/FormikTextInputGroup';
import FormikAsyncTypeaheadInput from "../common/formik/FormikAsyncTypeaheadInput";
import { isEmpty } from 'lodash';
import FeedbackButton from '../common/FeedbackButton';
import MuiSelect from '../common/MuiSelect/MuiSelect';
import Api from '../../api';
import styles from "../form.module.scss";

const api = new Api();

const FormBarcode = ({
    formik,
    previewBarcode,
    barcodeLabels,
    ticketLayouts,
    refs
}) => {
    const [activeTicketLayoutId, setActiveTicketLayoutId] = useState(null);
    const [previewBusy, setPreviewBusy] = useState(false);

    const handlePreview = () => {
        setPreviewBusy(true);
        let barcodeContext = JSON.parse(JSON.stringify(formik.values.barcodeContext));
        if (barcodeContext.barcodeType === 'Custom') {
            barcodeContext.barcodeConfig.barcodeParts = barcodeContext.barcodeConfig.barcodeParts.map(item => ({ type: item.id , name: item.name}));
        }
        api.previewTicketLayoutByEvent(formik.values.id, activeTicketLayoutId, barcodeContext)
            .then(result => {
                setPreviewBusy(false);
                const file = new Blob([result.data], {type: "application/pdf"});
                const fileURL = URL.createObjectURL(file);
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
            }).catch(err => {
            setPreviewBusy(false);
        });
    };

    const handleBarcodeTypeChange = (setFieldValue, barcodeType) => {
        if (barcodeType === 'Custom') {
            setFieldValue('barcodeContext.barcodeConfig', {
                encoding: formik.values.barcodeContext.barcodeConfig?.encoding || '',
                prefix: formik.values.barcodeContext.barcodeConfig?.prefix || '',
                hashType: formik.values.barcodeContext.barcodeConfig?.hashType || '',
                barcodeParts: formik.values.barcodeContext.barcodeConfig?.barcodeParts || [],
                barcodeImageType: formik.values.barcodeContext.barcodeConfig?.barcodeImageType || '',
                maxLength: formik.values.barcodeContext.barcodeConfig?.maxLength || '',
                delimiter: formik.values.barcodeContext.barcodeConfig?.delimiter || '',
            });
        }
    };

    useEffect(() => {
        handleBarcodeTypeChange(formik.setFieldValue, formik.values.barcodeContext.barcodeType);
    }, [formik.values.barcodeContext.barcodeType]);

    const renderEncodingText = useCallback(() => {
        switch (formik.values.barcodeContext.barcodeType) {
            case 'QR100Utf8':
                return 'UTF-8';
            case 'QR_100_ISO8859_13':
                return 'ISO-8859-13';
            case 'Barcode32':
                return 'digits';
            default:
                return 'ASCII';
        }
    }, [formik.values.barcodeContext.barcodeType]);

    return (
        <>
            <div className={styles.formBox} ref={refs.barcodeContext}>
                <h2 className={styles.formTitle}>Barcode Einstellungen</h2>
                <Row className="mt-3">
                    <Col className="pt-2 col-md-4">
                        <p className="d-inline">
                            Hier kann eingestellt werden, welche Art von Barcodes/QR-Codes für die zukünftig erstellten Tickets genutzt werden sollen.
                            Einige Arten von Barcodes bringen erweiterte Möglichkeiten wie eine einfache digitale Checksum-Berechnung mit,
                            sodass auch ohne Online-Anbindung einfach geprüft werden kann, ob das Ticket durch dieses System erstellt wurde.
                        </p>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-md-4">
                        <FormikSelect
                            name="barcodeContext.barcodeType"
                            displayEmpty={false}
                            isShowEmptyValue={false}
                            label="Vordefinierte Barcode-Typen:"
                            options={barcodeLabels.types}
                        />
                    </Col>
                </Row>
            </div>
            {formik.values.barcodeContext?.barcodeType !== null &&
            formik.values.barcodeContext?.barcodeType !== 'Custom' && (         
                <div className={styles.formBox}>
                    <Row className="mt-3 mb-3">
                        <Col className="pt-2 col-md-4">
                            <p className="d-inline">
                                Folgende Einstellungen gelten für den gewählten Barcode-Typen:
                            </p>
                        </Col>
                    </Row>
                    <div className={styles.barcodeUTF8Container}>
                        <span>Kodierung:</span>
                        <span>{renderEncodingText()}</span>

                        <span>Länge:</span>
                        <span>{formik.values.barcodeContext?.barcodeType === 'Barcode32' ? 32 : 100} Zeichen</span>

                        <span>Darstellung:</span>
                        <span>{formik.values.barcodeContext?.barcodeType === 'Barcode32' ? 'Barcode' : 'QR-Code'}</span>
                        
                        <span>Hash:</span>
                        <span>SHA1
                            {!isEmpty(formik.values.barcodeContext?.hashSecret) ? (
                                <span><i>, Secret: </i>{formik.values.barcodeContext?.hashSecret}</span>
                            ) : ''}
                        </span>
                        {!isEmpty(previewBarcode) && (
                            <>                            
                                <span>Beispiel (Daten):</span>
                                <span>{previewBarcode?.barcode}</span>
                            
                                <span>Beispiel (scanbar):</span>
                                <img src={previewBarcode?.barcodeImage} className={styles.barcodeImage} alt="Barcode|qrcode preview" />
                            </>
                        )}
                    </div>
                </div>
            )}
            {formik.values.barcodeContext?.barcodeType === 'Custom' && (         
                <div className={styles.formBox}>
                    <Row className="mt-3">
                        <Col className="col-md-4">
                            <FormikSelect
                                name="barcodeContext.barcodeConfig.encoding"
                                label="Kodierung & erlaubte Zeichen:"
                                options={barcodeLabels.encodings}
                                emptyValueLabel="Bitte wählen ..."
                            />
                        </Col>
                        <Col className="col-md-4">
                            <FormikTextInputGroup
                                name="barcodeContext.barcodeConfig.maxLength"
                                label="Max. Zeichenzahl:"
                                type="number"
                                helpText="Die Mindestzeichenzahl beträgt aus Sicherheitsgründen 8."
                            />
                        </Col>
                        <Col className="col-md-4">
                            <FormikSelect
                                name="barcodeContext.barcodeConfig.barcodeImageType"
                                label="Art:"
                                options={barcodeLabels.imageTypes}
                                emptyValueLabel="Bitte wählen ..."
                            />
                        </Col>
                        <Col className="col-md-4">
                            <FormikTextInputGroup
                                name="barcodeContext.hashSecret"
                                label="Secret für Hash:"
                                helpText="leer lassen für keine Hash-Berechnung. Die Mindestzeichenzahl beträgt aus Sicherheitsgründen 16"
                            />
                        </Col>
                        <Col className="col-md-4">
                            <FormikSelect
                                name="barcodeContext.barcodeConfig.hashType"
                                label="Hash:"
                                options={barcodeLabels.hashTypes}
                                emptyValueLabel="Bitte wählen ..."
                            />
                        </Col>
                        <Col className="col-md-4">
                            <FormikTextInputGroup
                                name="barcodeContext.barcodeConfig.prefix"
                                label="Prefix:"
                            />
                        </Col>
                        <Col className="col-md-4">
                            <FormikSelect
                                name="barcodeContext.barcodeConfig.delimiter"
                                label="Trennzeichen (Delimiter):"
                                options={formik.values.barcodeContext.barcodeConfig?.barcodeImageType === 'qr' ? barcodeLabels.allowedDelimiters.qr : barcodeLabels.allowedDelimiters.Code39}
                                isShowEmptyValue={false}
                                displayEmpty={false}
                            />
                        </Col>
                        <Col className="col-md-4">
                            {!isEmpty(barcodeLabels.partTypes) && (
                                <FormikAsyncTypeaheadInput
                                    formik={formik}
                                    id="barcodeContext.barcodeConfig.barcodeParts"
                                    label="Weitere fachliche Daten einbetten:"
                                    minLength={0}
                                    labelKey={o => o.name}
                                    onSearch={() => Promise.resolve(barcodeLabels.partTypes)}
                                    multiple={true}
                                />
                            )}
                        </Col>
                    </Row>
                    {!isEmpty(previewBarcode) && (
                        <div className={styles.barcodeUTF8Container}>
                            <span>Beispiel (Daten):</span>
                            <span>{previewBarcode?.barcode}</span>
                        
                            <span>Beispiel (scanbar):</span>
                            <img src={previewBarcode?.barcodeImage} className={styles.barcodeImage} alt="Barcode|qrcode preview" />
                        </div>
                    )}
                </div>
            )}
            <div className={styles.formBox}>
                <Row className="mt-3">
                    <Col className="col-md-4">
                        <MuiSelect
                            label="Ticketlayout wählen"
                            value={activeTicketLayoutId}
                            options={ticketLayouts}
                            emptyValueLabel="Bitte wählen ..."
                            onChange={id => setActiveTicketLayoutId(id)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-md-4">
                        <FeedbackButton
                            busy={previewBusy}
                            onClick={handlePreview}
                            disabled={isEmpty(activeTicketLayoutId)}
                            variant="contained"
                        >
                            Vorschau
                        </FeedbackButton>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default FormBarcode;