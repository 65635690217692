/**
 * Erzeugt eine memoisierte Variante der übergebenen Validierungsfunktion.
 *
 * Die übergebene Validierungsfunktion wird nur aufgerufen, wenn sich der
 * zu validierende Wert ggü. dem vorherigen Aufruf verändert hat.
 *
 * ACHTUNG: Diese Funktion erfüllt einen anderen Zweck als lodash/memoize,
 * da nicht jeder Wert memoisiert wird, sondern nur der letzte Aufruf.
 *
 * @param validate
 *
 * @param initialValue Der initiale Wert des Feld, sofern bekannt.
 * Dient dazu eine Validierung zu vermeiden wenn der Wert des Feldes
 * sich bisher nicht Verändert hat.
 *
 * @param initialResult Das Resultat der Validierung, wenn sich der Wert
 * bisher nicht ggü. dem initialValue verändert hat.
 */
export const memoize = ({validate, initialValue, initialResult}) => {
    let lastValue = initialValue;
    let lastResult = initialResult;

    return (value) => {

        if (value !== lastValue) {
            lastValue = value;
            lastResult = validate(value);
        }

        return lastResult;
    }
};
