import PropTypes from 'prop-types';

export const ItemField = PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    type: PropTypes.string
});

export const ListItem = PropTypes.shape({
    id: PropTypes.string.isRequired
});
