import React, {Component} from 'react';
import Form from "./Form";
import {connect} from "react-redux";
import {LoadingIndicator} from "../../../common/LoadingIndicator";
import {Col, Row} from "react-bootstrap";
import * as venuePlan from "../../../../state/entities/venuePlan";
import * as blockGroup from "../../../../state/entities/blockGroup";
import Breadcrumbs from "../../../common/breadcrumbs/Breadcrumbs";


class Edit extends Component {

    componentDidMount() {
        this.props.loadVenuePlan(this.props.venuePlanId);
        this.props.loadBlockGroup(this.props.blockGroupId);
    }

    onSubmit = (blockGroup, {setErrors}) => {
        this.props.saveBlockGroup(blockGroup, setErrors);
    };

    render() {
        const {blockGroup, loading, saving, venuePlan, venuePlanLoading} = this.props;
        if (!blockGroup || loading || venuePlanLoading) {
            return <LoadingIndicator/>;
        }
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Spielstätte", "Saalplan", "Blockgruppe", "Block", "Sitzplätze"]} endpoints = {['venue/breadcrumb', `venue/${venuePlan.venue.id}/venue_plan/breadcrumb`, `venue_plan/${venuePlan.id}/block_group/breadcrumb`, `venue_plan/${venuePlan.id}/block/breadcrumb`]} title = {["Blockgruppen", "Blöcke"]} links={['/base/venue/', `/base/venue/${venuePlan.venue.id}/venue-plan/`, `/base/venue-plan/${venuePlan.id}/block-group/`, `/base/venue-plan/${venuePlan.id}/block/`]} active={[venuePlan.venue.id, venuePlan.id, blockGroup.id]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Bearbeiten der Blockgruppe <em>{blockGroup.backendNameShort}</em> für den Saalplan <em>{venuePlan.name}</em></h1>
                </Col>
            </Row>
            <Form blockGroup={blockGroup} onSubmit={this.onSubmit} submitPending={saving}
                  venuePlanId={this.props.venuePlanId} venuePlan={venuePlan}/>

        </>;
    }
}

const mapStateToProps = (state, props) => {
    const venuePlanId = props.match.params.vpid;
    const blockGroupId = props.match.params.bgid;

    const blockGroup =state.entities.blockGroup.byId[blockGroupId];

    return {
        blockGroupId,
        venuePlanId,
        loading: state.entities.blockGroup.loading[blockGroupId],
        saving: state.entities.blockGroup.saving[blockGroupId],
        blockGroup,
        venuePlan: state.entities.venuePlan.byId[venuePlanId],
        venuePlanLoading: state.entities.venuePlan.loading[venuePlanId]
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadBlockGroup: id => dispatch(blockGroup.actions.load({id})),
        saveBlockGroup: entity => dispatch(blockGroup.actions.save({entity})),
        loadVenuePlan: id => dispatch(venuePlan.actions.load({id})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
