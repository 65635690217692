import {Resource} from "../../../api";

export default class VenuePlan extends Resource {

    constructor(axios) {
        super('venuePlan', axios);
    }

    /**
     * Ruft Saalpläne einer Spielstätte ab
     */
    byVenue = (id) => {
        return this.axios.get(`/byVenue/${id}`)
            .then((res) => res.data);
    };
}
