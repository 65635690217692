import React, {useState} from 'react';
import styles from "../layoutEditor.module.scss";
import PropTypes from "prop-types";
import EventSeriesLogo from "./EventSeriesLogo";

const Separator = ({element, editModeEnabled, updateElement}) => {
    const [color, setColor] = useState(element.color);

    const updateColor = (e) => {
        setColor(e.target.value);
    };

    const onBlur = () => {
        updateElement({...element, color: color});
    };

    return <div className={`${styles.LayoutElementInner} ${styles.Separator} ${!editModeEnabled ? "interactive" : ""}`}
                style={{
                    width: element.width + "px",
                    height: element.height + "px",
                }}
    >
        {editModeEnabled && <input type="color" onChange={updateColor} onBlur={onBlur}/>}

        <div style={{
            borderBottom: "2px solid " + color,
            height: "50%",
        }}></div>
    </div>;
};

EventSeriesLogo.propTypes = {
    element: PropTypes.object.isRequired,
    editModeEnabled: PropTypes.bool,
    updateElement: PropTypes.func.isRequired,
};

export default Separator;
