import React from 'react';
import {Switch} from 'react-router-dom';

import List from './List';
import Edit from './Edit';
import Create from './Create';
import {Container} from 'react-bootstrap';
import SecureRoute from "../auth/SecureRoute";

const Routing = () => {
    return (
        <Container className={'mt-1'}>
            <Switch>
                <SecureRoute exact path="/base/tenant"  requiredRoles={['admin_super_admin']} component={List}/>
                <SecureRoute exact path="/base/tenant/create" requiredRoles={['admin_super_admin']} component={Create}/>
                <SecureRoute exact path="/base/tenant/:id" requiredRoles={['admin_admin', 'admin_super_admin']} component={Edit}/>
            </Switch>
        </Container>
    )
};

export default Routing;
