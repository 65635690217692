import React, {Component} from 'react';
import Form from "./Form";
import {connect} from "react-redux";
import {LoadingIndicator} from "../common/LoadingIndicator";
import {Col, Row} from "react-bootstrap";
import * as purchasableItemTemplate from "../../state/entities/purchasableItemTemplate";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Edit extends Component {

    componentDidMount() {
        this.props.loadPurchasableItemTemplate(this.props.purchasableItemTemplateId);
    }

    onSubmit = (purchasableItemTemplate, {setErrors}) => {
        this.props.savePurchasableItemTemplate(purchasableItemTemplate, setErrors);
    };

    render() {
        const {purchasableItemTemplate, loading, saving} = this.props;
        if (!purchasableItemTemplate || loading) {
            return <LoadingIndicator/>;
        }
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Leistungsvorlage"]} endpoints = {['purchasable_item_template/breadcrumb']} title = "Leistungsvorlagen" links={['/event-management/purchasable-item-template/']} active={[purchasableItemTemplate.id]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Bearbeiten der Leistungsvorlage <em>{purchasableItemTemplate.name}</em></h1>
                </Col>
            </Row>
            <Form purchasableItemTemplate={purchasableItemTemplate} onSubmit={this.onSubmit} submitPending={saving} />
        </>;
    }
}

const mapStateToProps = (state, props) => {
    const purchasableItemTemplateId = props.match.params.id;
    return {
        purchasableItemTemplateId,
        loading: state.entities.purchasableItemTemplate.loading[purchasableItemTemplateId],
        saving: state.entities.purchasableItemTemplate.saving[purchasableItemTemplateId],
        purchasableItemTemplate: state.entities.purchasableItemTemplate.byId[purchasableItemTemplateId]
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadPurchasableItemTemplate: id => dispatch(purchasableItemTemplate.actions.load({id})),
        savePurchasableItemTemplate: entity => dispatch(purchasableItemTemplate.actions.save({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
