import {Point} from './types';
import {distance} from './index';

export type PointComparisonMode =
    | 'Y_ASC_X_ASC' // Vergleiche Y-Koordinate aufsteigend, wenn gleich dann X-Koordinate aufsteigend vergleichen.


/**
 * Prüft, ob zwei Punkte die gleiche Position darstellen.
 *
 * Der Vergleich erfolgt durch Prüfung, ob der Abstand der zwei Punkte geringer als der spezifizierte Epsilon Wert ist.
 */
export function isEqualPosition<P extends Point>(a: P, b: P, epsilon = Number.EPSILON): boolean {
    return distance(a, b) < epsilon;
}

/**
 * Erzeugt eine comparator function, um "Koordinaten-behaftete" Objekte
 * miteinander zu vergleichen für die Verwendung mit Array.sort().
 *
 * @param mode Modus der verwendet werden soll, um die Koordinaten zu vergleichen.
 */
export function comparePoints<P extends Point>(mode: PointComparisonMode): (a: P, b: P) => number {
    switch (mode) {
        case 'Y_ASC_X_ASC':
            return (a, b) => {
                if (a.y < b.y) return -1;
                if (a.y > b.y) return +1;
                if (a.x < b.x) return -1;
                if (a.x > b.x) return +1;
                return 0;
            };
    }

}

/**
 *  Findet aus einer Menge von Punkten den minimalen Punkt im Sinne des spezifizierten Vergleichsmodus.
 */
export function findMinimumPoint<P extends Point>(points: Iterable<P>, mode: PointComparisonMode): P | undefined {
    const compare = comparePoints(mode);
    let minimum: P | undefined = undefined;

    for (const p of points) {
        if (!minimum || compare(p, minimum) < 0) {
            minimum = p;
        }
    }

    return minimum;
}

/**
 * Bildet einen Punkt auf die Schnittpunkte eines (0, 0) zentrierten grids ab.
 *
 * @param point Der Punkt, der auf das grid abgebildet werden soll.
 * @param step Die Schrittweite des grid auf das der Punkt abgebildet werden soll.
 *
 * @return Der zum übergebenen Punkt nächstgelegene Schnittpunkt des grid.
 */
export function snapToGrid<P extends Point>(point: Readonly<P>, step: number): Point {
    return {
        x: Math.round(point.x / step) * step,
        y: Math.round(point.y / step) * step
    };
}


/**
 * Calculates luminance of given color
 * 
 * @param rgb numeric color value
 * @returns luminance in 0 - 255
 */
export const getLuminance = (rgb: number): number  => {
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

