import React from 'react';
import {Col} from 'react-bootstrap';
import {
    Button
} from '@mui/material';
import * as helpTextsToggle from "../../state/helpTextsToggle/helpTextsToggleSlice";
import {connect} from "react-redux";
import HelpIcon from '@mui/icons-material/Help';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';

/**
 * Togglebutton to show and hide helping texts on creating and editing pages
 */
const ToggleHelptexts = ({helpTextsVisible, toggle}) =>
    <Col className="text-right">
        <Button onClick={() => toggle()} sx={{ minWidth: 'auto', padding: 0, height: 36, width: 36 }}>
            {helpTextsVisible
                ? <HelpIcon sx={{ fontSize: 34 }} />
                : <HelpOutlineRoundedIcon sx={{ fontSize: 34 }} />
            }
        </Button>
    </Col>;


const mapStateToProps = (state, props) => {
    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible,
    }
};

const mapDispatchToProps = dispatch => ({
    toggle: id => dispatch(helpTextsToggle.actions.toggle()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ToggleHelptexts);

