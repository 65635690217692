import {createSlice} from "redux-starter-kit";

const entityName = 'venuePlan';

export const byVenue = createSlice({
    slice: entityName,
    initialState: {},
    reducers: {
        byVenue: (state, {payload}) => {
            state[payload.venue] = true;
        },
        byVenueError: (state, {payload}) => {
            state[payload.venue] = false;
        },
        byVenueSuccess: (state, {payload}) => {
            // Als payload wird eine "normalizr" response erwartet
            state[payload.response.result] = false;
        }
    }
});
