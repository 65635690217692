import React, {Component} from 'react';
import Form, {cleanupTenant} from "./Form";
import {connect} from "react-redux";
import {LoadingIndicator} from "../common/LoadingIndicator";
import {Col, Row} from "react-bootstrap";
import * as tenant from "../../state/entities/tenant";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Edit extends Component {

    componentDidMount() {
        this.props.loadTenant(this.props.tenantId);
    }

    onSubmit = (tenant, {setErrors}) => {
        this.props.saveTenant(cleanupTenant(tenant), setErrors);
    };

    render() {
        const {tenant, loading, saving} = this.props;
        if (!tenant || loading) {
            return <LoadingIndicator/>;
        }
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Tenant"]} endpoints = {['tenant/breadcrumb']} title = "Mandanten" links={['/base/tenant/']} active={[tenant.id]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Bearbeiten des Mandanten <em>{tenant.name}</em></h1>
                </Col>
                <Col className="pt-3 col-sm-8">
                <h2>Tenant ID - <em>{tenant.id}</em></h2>
                </Col>
            </Row>
            <Form tenant={tenant} onSubmit={this.onSubmit} submitPending={saving} />
        </>;
    }
}

const mapStateToProps = (state, props) => {
    const tenantId = props.match.params.id;
    return {
        tenantId,
        loading: state.entities.tenant.loading[tenantId],
        saving: state.entities.tenant.saving[tenantId],
        tenant: state.entities.tenant.byId[tenantId]
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadTenant: id => dispatch(tenant.actions.load({id})),
        saveTenant: entity => dispatch(tenant.actions.save({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
