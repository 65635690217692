import get from 'lodash/get';
import {dismiss} from './actions';

/**
 * Reducer for "globalMessagesList" state.
 */
export default (state = [], {type, payload}) => {
    const globalMessagesList = get(payload, 'globalMessagesList');

    if (globalMessagesList) {
        if (type === dismiss.toString()) {
            return state.filter(e => e.id !== globalMessagesList.id);
        } else {
            return [...state, globalMessagesList];
        }
    }

    return state;
};
