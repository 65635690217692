import {
    AbstractIdleState,
    DisplayContext,
    InteractionState,
} from './common';
import {Point} from '../../geometry';
import {FederatedPointerEvent} from 'pixi.js';



/**
 * Most interaction is done via PIXi inside images classes.
 */
export class ImagesIdleState extends AbstractIdleState {

    onInstallation(context: DisplayContext) {
        context.imagesManager.setEditMode(true);
    }

    onEviction(context: DisplayContext): void {
        context.imagesManager.setEditMode(false);
    };

    onPointerDown(context: DisplayContext, origin: Point, event: FederatedPointerEvent): InteractionState {
        return this;
    }

    protected override onKeyboard(context: DisplayContext, event: KeyboardEvent): InteractionState {
        if (event.key === 'Shift') {
            if (event.type === 'keydown') {
                context.imagesManager.setShiftKeyPressed(true);
            } else if (event.type === 'keyup') {
                context.imagesManager.setShiftKeyPressed(false);
            }
        }
        return this;
    }

}
