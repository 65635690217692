import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import { Seat, SeatRecord } from '../../../seating/types';
import { IPlacepool } from '../../../seating/types/Placepool';
import { ImageData } from '../../../seating/editor/display/images/ImageData';
import { InteractionMode } from '../../../seating/editor/display/interaction';
import { VenuePlanSettings } from '../../../seating/types/VenuePlanInformation';
import { AreaFormData } from '../../../seating/editor/display/areaForms/AreaFormData';
import { VenuePlan } from '../../../seating/types/VenuePlan';
import { IBlock } from '../../../seating/types/Block';
import { UndoAction } from '../../../seating/editor/UndoManager';


export enum UpdateSource {
    DISPLAY = 'display',
    STORE = 'store'
}


interface ISliceData {
    isLoaded: boolean;
}


export interface IVenuePlanSliceData extends ISliceData {
    venuePlan: VenuePlan
}

export const venuePlan = createSlice({
    name: 'venuePlan',
    initialState: {venuePlan: {}, isLoaded: false} as IVenuePlanSliceData,
    reducers: {
        loadVenuePlan: (state, {payload}: PayloadAction<string>) => {
            state.isLoaded = false;
            return state;
        },
        loadVenuePlanSuccess: (state, {payload}) => {
            return({
                venuePlan: {
                    id: payload.id,
                    name: payload.name,
                    backendName: payload.backendName
                },
                isLoaded: true
            });
        },
        loadVenuePlanError: (state, {payload}) => {
            state.isLoaded = false;
            return state;
        }
    }
});


export interface IVenuePlanSettingsSliceData extends ISliceData {
    venuePlanSettings: VenuePlanSettings
}

export const venuePlanSettings = createSlice({
    name: 'venuePlanSettings',
    initialState: {venuePlanSettings: {}, isLoaded: false} as IVenuePlanSettingsSliceData,
    reducers: {
        loadVenuePlanSettings: (state, { payload: venuePlanId } : PayloadAction<string>) => {
            state.isLoaded = false;
            return state;
        },
        loadVenuePlanSettingsError: () => {},
        loadVenuePlanSettingsSuccess: (state, { payload: venuePlanSettings } : PayloadAction<VenuePlanSettings>) => {
            return ({
                venuePlanSettings,
                isLoaded: true
            });
        },
        updateVenuePlanSettings: (
            state,
            {payload: {venuePlanId, venuePlanSettings}}:
                PayloadAction<{venuePlanId: string, venuePlanSettings: VenuePlanSettings}>
        ) => {
            state.venuePlanSettings = venuePlanSettings;
            return state;
        },
        updateVenuePlanSettingsSuccess: (state, { payload: venuePlanSettings } : PayloadAction<VenuePlanSettings>) => {
            return state;
        },
        updateVenuePlanSettingsError: (state, { payload: venuePlanSettings } : PayloadAction<VenuePlanSettings>) => {
            return state;
        }
    }
});


export const isApiRequestPending = createSlice({
    name: 'isApiRequestPending',
    initialState: false,
    reducers: {
        setIsApiRequestPending: (state, {payload: isPending}: PayloadAction<boolean>) => {
            return isPending;
        }
    }
});


export interface ISeatsSliceData extends ISliceData {
    allSeats: SeatRecord,
    newAddedSeats: Seat[],
    updatedSeatsBeforeUpdate: Seat[]
}


export const seats = createSlice({
    name: 'seats',
    initialState: {allSeats: {}, newAddedSeats: [], updatedSeatsBeforeUpdate: []} as ISeatsSliceData,
    reducers: {
        loadAllSeats: (state, {payload}: PayloadAction<string>) => {},
        loadAllSeatsError: () => {},
        loadAllSeatsSuccess: (state, { payload: seats } : PayloadAction<SeatRecord>) => {
            return {...state, allSeats: seats, isLoaded: true};
        },
        updateSomeSeats: (state, { payload: updatedSeats } : PayloadAction<Seat[]>) => {
            state.updatedSeatsBeforeUpdate = [];
            //here really do create a new seat object, because e.g. VenuePlanDisplay often returns
            //a RenderableSeat, where x,y are readonly.
            return updatedSeats.reduce((state: ISeatsSliceData, seat: Seat): ISeatsSliceData => {
                state.updatedSeatsBeforeUpdate.push(state.allSeats[seat.id]);
                let newSeat: Seat = {  //don't use spread op., it will stumble about readonlys x,y of position
                    id: seat.id,
                    tags: seat.tags,
                    x: seat.x,
                    y: seat.y,
                    color: seat.color,
                    style: seat.style,
                    area: seat.area,
                    label: seat.label,
                    row: seat.row,
                    enabled: seat.enabled,
                    available: seat.available,
                    seatingTypeId: seat.seatingTypeId,
                    pricingCategoryId: seat.pricingCategoryId,
                    blockId: seat.blockId,
                 };
                state.allSeats[seat.id] = newSeat;
                return state;
            }, state);
        },
        updateSomeSeatsError: (state, { payload: {seats, error} }) => {
            return state;
        },
        updateSomeSeatsSuccess: (state, { payload }) => {
            return state;
        },
        addSeats: (state, { payload} : PayloadAction<Seat[]>) => {
            return state;
        },
        addSeatsSuccess: (state, { payload: newSeats } : PayloadAction<Seat[]>) => {
            state = newSeats.reduce((allSeats: ISeatsSliceData, seat: Seat): ISeatsSliceData => {
                state.allSeats[seat.id] = seat;
                state.newAddedSeats.push(seat);
                return allSeats;
            }, state);
            return state;
        },
        addSeatsError: (state, { payload } : PayloadAction<Array<Seat>>) => {
            return state;
        },
        purgeNewAddedSeats: (state) => ({...state, newAddedSeats: []}),
        deleteSeats: (state, {payload: seatsToDelete} : PayloadAction<Seat[]>) => {
            seatsToDelete.forEach(seat => delete state.allSeats[seat.id]);
            return state;
        },
        deleteSeatsSuccess: (state, { payload } : PayloadAction<Seat[]>) => {
            return state;
        },
        deleteSeatsError: (state, { payload } : PayloadAction<Seat[]>) => {
            return state;
        }
    }
});


export interface ISelectedSeatIdsSliceData {
    selectedSeatIds: string[],
    selectedBy: UpdateSource,
    changedSelectionBeforeChange: string[]
}

export const selectedSeatIds = createSlice({
    name: 'selectedSeatIds',
    initialState: {
        selectedSeatIds: [],
        selectedBy: UpdateSource.DISPLAY,
        changedSelectionBeforeChange: []
    } as ISelectedSeatIdsSliceData,
    reducers: {
        setSelectedSeats: (
            state,
            {payload: {seats, selectedBy}}: PayloadAction<{seats: Seat[], selectedBy: UpdateSource}>
        ) => {
            return {
                selectedSeatIds: seats.length ? seats.map(seat => seat.id) : [],
                selectedBy: selectedBy,
                changedSelectionBeforeChange: state.selectedSeatIds.slice()
            };
        },
        setSelectedSeatIds: (
            state,
            {payload: {seatIds, selectedBy}}: PayloadAction<{seatIds: string[], selectedBy: UpdateSource}>
        ) => {
            return {
                changedSelectionBeforeChange: state.selectedSeatIds.slice(),
                selectedSeatIds: seatIds,
                selectedBy: selectedBy
            };
        },
        }
});


const MAX_UNDO_ENTRIES = 20;

export interface IUndoHistorySliceData {
    undoActions: UndoAction[],
    redoActions: UndoAction[]
}

export const undoHistory = createSlice({
    name: 'undoHistory',
    initialState: {undoActions: [], redoActions: []} as IUndoHistorySliceData,
    reducers: {
        addNewUndoAction: (state, { payload: undoAction } : PayloadAction<UndoAction>) => {
            let newHistory = state.undoActions.slice();
            newHistory.push(undoAction);
            newHistory = newHistory.slice(-MAX_UNDO_ENTRIES);
            return {undoActions: newHistory, redoActions: state.redoActions};
        },
        deleteLastUndoAction: (state, {payload}: PayloadAction) => {
            if (state.undoActions.length) state.undoActions.pop();
            return state;
        },
        purgeUndoHistory: (state) => ({undoActions: [], redoActions: state.redoActions}),
        addNewRedoAction: (state, { payload: undoAction } : PayloadAction<UndoAction>) => {
            let newHistory = state.redoActions.slice();
            newHistory.push(undoAction);
            newHistory = newHistory.slice(-MAX_UNDO_ENTRIES);
            return {undoActions: state.undoActions, redoActions: newHistory};
        },
        deleteLastRedoAction: (state, {payload}: PayloadAction) => {
            if (state.redoActions.length) state.redoActions.pop();
            return state;
        },
        purgeRedoHistory: (state) => ({undoActions: state.undoActions, redoActions: []})
    }
});


export const interactionMode = createSlice({
    name: 'interactionMode',
    initialState: InteractionMode.SELECT,
    reducers: {
        setInteractionMode: (state, { payload } : PayloadAction<InteractionMode>) => payload
    }
});


export const moveOnGrid = createSlice({
    name: 'moveOnGrid',
    initialState: false,
    reducers: {
        setMoveOnGrid: (state, { payload } : PayloadAction<boolean>) => payload
    }
});


export const placepoolDefinitions = createSlice({
    name: 'placepoolDefinitions',
    initialState: [],
    reducers: {
        loadPlacepoolDefinitions: (state, {payload: venuePlanId}: PayloadAction<string>) => [],
        loadPlacepoolDefinitionsError: () => [],
        loadPlacepoolDefinitionsSuccess: (state, { payload: placepools } : PayloadAction<IPlacepool[]>) => {
            // Als payload wird eine "normalizr" response erwartet
            const plplColors = [
                'FFD700', 'FF3333', '0044FF', 'A52A2A', '008000', '000080', 'FF00FF', '00FFFF',
                '6E0DD0', 'FFA07A', '008080', '800080', '7CFC00', '6A5ACD', '4682B4', '808000',
                'FFA500', '20B2AA', 'DB7093', 'B0C4DE', '32CD32', 'FA8072', 'ADFF2F', '9370DB',
                'FFFF00', '800000', '8A2BE2', 'FF4500', '4B0082', 'FF8C00', 'FF6347', '7B68EE',
                '808080'
            ];
            if (placepools) {
                for (let pi = 0; pi < placepools.length; pi++) {
                    placepools[pi].color = plplColors[pi];
                }
            }
            return placepools;
        }
    }
});


export const placeCategories = createSlice({
    name: 'placeCategories',
    initialState: [],
    reducers: {
        loadPlaceCategories: () => [],
        loadPlaceCategoriesError: (state, { payload }) => {
            console.log("error: " + payload);
            return [];
        },
        loadPlaceCategoriesSuccess: (state, { payload: placeCategories }) => {
            // Als payload wird eine "normalizr" response erwartet
            return placeCategories;
        }
    }
});


export const seatingTypes = createSlice({
    name: 'seatingTypes',
    initialState: [],
    reducers: {
        loadSeatingTypes: () => [],
        loadSeatingTypesError: () => [],
        loadSeatingTypesSuccess: (state, { payload: seatingTypes }) => {
            // Als payload wird eine "normalizr" response erwartet
            return seatingTypes;
        }
    }
});



export interface IBlocksData extends ISliceData {
    allBlocks: Array<IBlock>
}

export const blocks = createSlice({
    name: 'blocks',
    initialState: {allBlocks: [], isLoaded: false} as IBlocksData,
    reducers: {
        loadBlocks: (state, {payload}: PayloadAction<string>) => state,
        loadBlocksError: (state) => state,
        loadBlocksSuccess: (state, { payload: blocks }) => {
            // Als payload wird eine "normalizr" response erwartet
            return {...state, allBlocks: blocks, isLoaded: true};
        }
    }
});


export interface IImagesSliceData extends ISliceData {
    allImages: ImageData[],
    newAddedImages: ImageData[]
};


export const images = createSlice({
    name: 'images',
    initialState: {allImages: [], newAddedImages: []} as IImagesSliceData,
    reducers: {
        loadImages: (state, {payload}: PayloadAction<string>) => state,
        loadImagesError: (state) => state,
        loadImagesSuccess: (state, { payload: images } : PayloadAction<ImageData[]>) => {
            return {...state, allImages: images, isLoaded: true};
        },
        updateImages: (state, { payload: updatedImages } : PayloadAction<ImageData[]>) => {
            const newState = state.allImages.map(image => {
               const updatedImage = updatedImages.find(updated => updated.id === image.id);
               const newImage = updatedImage || image;
               return {...newImage};
            });
            return {...state, images: newState};
        },
        updateImagesError: (state, { payload } : PayloadAction<ImageData>) => {
            //TODO: show error and  reload editor
            return state;
        },
        updateImagesSuccess: (state, { payload } : PayloadAction<ImageData>) => {
            return state;
        },
        addImage: (state, action: PayloadAction<{venuePlanId: string, newImage: ImageData}>) => {
            return state; //.slice().concat(newImages);
        },
        addImagesError: (state, { payload } : PayloadAction<ImageData>) => {
            return state;
        },
        addImagesSuccess: (state, { payload: newImages } : PayloadAction<ImageData[]>) => {
            return {
                ...state,
                images: state.allImages.concat(newImages),
                newAddedImages: newImages
            };
        },
        purgeNewAddedImages: (state) => {
            return {...state, newAddedImages: []};
        },
        deleteImages: (state, { payload: imagesToDelete } : PayloadAction<ImageData[]>) => {
            const imageIdsToDelete: string[] = imagesToDelete.map(image => image.id);
            const remainImages = state.allImages.filter(image => !(imageIdsToDelete.includes(image.id)));
            return {...state, images: remainImages};
        },
        deleteImagesSuccess: (state, { payload } : PayloadAction<ImageData>) => {
            return state;
        },
        deleteImagesError: (state, { payload } : PayloadAction<ImageData>) => {
            return state;
        }
    }
});


export interface IAreaFormsSliceData extends ISliceData {
    areaForms: Record<string, AreaFormData>,
    newAddedAreaForms: Record<string, AreaFormData>,
    isAddAreaFormLoading: boolean,
    selectedAreaFormId: string,
};

export const areaForms = createSlice({
    name: 'areaForms',
    initialState: {areaForms: {}, newAddedAreaForms: {}, isLoaded: false, isAddAreaFormLoading: false} as IAreaFormsSliceData,
    reducers: {
        setLoadedAreaForms: (state, {payload: loadedAreaForms} : PayloadAction<Record<string, AreaFormData>>) => {
            return {
                ...state,
                areaForms: loadedAreaForms,
                isLoaded: true
            };
        },
        addAreaForm: (state, action: PayloadAction<{venuePlanId: string, newAreaForm: AreaFormData}>) => (
            {...state, isAddAreaFormLoading: true}
        ),
        addAreaFormError: state => ({...state, isAddAreaFormLoading: false}),
        addAreaFormSuccess: (state, {payload: newAreaForm}: PayloadAction<AreaFormData>) => (
            {
                ...state,
                areaForms: {...state.areaForms, [newAreaForm.id]: newAreaForm},
                newAddedAreaForms: {...state.newAddedAreaForms, [newAreaForm.id]: newAreaForm},
                isAddAreaFormLoading: false
            }
        ),
        removeFromNewAddedAreaForms: (state, {payload: areaForms}: PayloadAction<Record<string, AreaFormData>>) => {
            for (const ai in areaForms) delete state.newAddedAreaForms[ai];
            return state;
        },
        updateAreaForms: (state, action: PayloadAction<{venuePlanId: string, updatedAreaForms: AreaFormData[]}>) => {
            const updatedAreaForms = action.payload.updatedAreaForms;
            const newAreaForms = updatedAreaForms.reduce((areaForms: Record<string, AreaFormData>, updAF) => {
                return { ...areaForms, [updAF.id]: updAF};
            }, {...state.areaForms});
            return { ...state, areaForms: newAreaForms };
        },
        updateAreaFormsError: (state, { payload } : PayloadAction<AreaFormData>) => {
            return state;
        },
        updateAreaFormsSuccess: (state, { payload } : PayloadAction<AreaFormData>) => {
            return state;
        },
        deleteAreaForms: (state, { payload: areaFormsToDelete } : PayloadAction<AreaFormData[]>) => {
            state.areaForms = areaFormsToDelete.reduce((areaForms, delAF) => {
                delete areaForms[delAF.id];
                return areaForms;
            }, state.areaForms);
            return state;
        },
        deleteAreaFormsSuccess: (state, { payload } : PayloadAction<AreaFormData>) => {
            return state;
        },
        deleteAreaFormsError: (state, { payload } : PayloadAction<AreaFormData>) => {
            return state;
        },
        setSelectedAreaFormId: (state, {payload: selectedAreaFormId}: PayloadAction<string>) => {
            state.selectedAreaFormId = selectedAreaFormId;
            return state;
        }
    }
});


export const fatalAPIError = createSlice({
    name: 'fatalAPIError',
    initialState: '',
    reducers: {
        setError: (state, { payload: errorMessage } : PayloadAction<string>) =>  {
            return errorMessage;
        }
    }
});
