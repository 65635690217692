import React, { useState, useRef } from 'react';
import {Container, Row} from 'react-bootstrap';
import {connect} from 'react-redux';
import FeedbackButton from '../common/FeedbackButton';
import {actions} from '../../state/entities/venueEvent';
import ConnectedListView from '../common/listView/ConnectedListView';
import ConfirmModal from '../common/modal/ConfirmModal';
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

const List = (props) => {
    const deleteItem = (item) => () => {
        props.delete(item)
    };

    const copyItem = (item) => () => {
        props.copy(item)
    };

    return (
        <Container>
            <Row className="my-4">
                <Breadcrumbs entity={["Event"]} endpoints = {['venue_event/breadcrumb']} title = "Events" links={['/event-management/event/']} active={[0]} />
            </Row>
            <ConnectedListView
                heading="Events"
                endpoint="listingView/venueEvent"
                buttonTitle="Neues Event"
                buttonLink="/event-management/event/create"
            >
                {({fields, item: event}) => {
                    return(
                    <>
                        <FeedbackButton
                            className="list-link"
                            title="Event bearbeiten"
                            to={{
                                pathname: `/event-management/event/${event.id}`,
                                location: { tab: 'home' }
                            }}
                            icon={<EditNoteOutlinedIcon className='table-icon' />}
                        />
                        <ConfirmModal title="Event kopieren"
                                      body={`Möchten Sie das Event "${event.title}" wirklich kopieren?`}
                                      cancelLabel="Abbrechen"
                                      confirmLabel="Kopieren"
                        >
                            {confirm =>
                                <FeedbackButton
                                    title="Event kopieren"
                                    className="list-link"
                                    onClick={() => confirm(copyItem(event))}
                                >
                                    <ContentCopyOutlinedIcon className='table-icon' />
                                </FeedbackButton>
                            }
                        </ConfirmModal>

                        <FeedbackButton
                            className="list-link"
                            title="Verkaufsregeln des Events bearbeiten"
                            to={{
                                pathname: `/event-management/event/${event.id}`,
                                location: { tab: 'salesRules' }
                            }}
                            icon={<RuleOutlinedIcon className='table-icon' />}
                        />
                        <ConfirmModal title="Event löschen"
                                      body={`Möchten Sie das Event "${event.title}" wirklich löschen?`}
                                      cancelLabel="Abbrechen"
                                      confirmLabel="Löschen"
                        >
                            {confirm =>
                                <FeedbackButton
                                    title="Event löschen"
                                    className="list-link"
                                    onClick={() => confirm(deleteItem(event))}
                                >
                                    <DeleteOutlineOutlinedIcon className='table-icon' />
                                </FeedbackButton>
                            }
                        </ConfirmModal>
                    </>
                )}}
            </ConnectedListView>
        </Container>
    );
}

export default connect(null, {
    delete: entity => actions.delete({entity}),
    copy: entity => actions.copy({entity})
})(List);
