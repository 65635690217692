import { history } from '../configureStore';
import entitySlice from './entity/slice';
import entitySaga from './entity/saga';
import Api from '../../api';
import {pricingList as schema} from '../schema';

const onCreateSuccess = entity => history.push(`/event-management/pricing-list/${entity.id}`);
const onCopySuccess = onCreateSuccess;
const entityName = 'pricingList';

const slice = entitySlice(entityName);

export const actions = slice.actions;

export const reducer = slice.reducer;

const api = new Api();
const resource = api.createResource(entityName);

export const saga = entitySaga({
    actions: slice.actions,
    resource,
    schema,
    onCreateSuccess,
    onCopySuccess,
});


