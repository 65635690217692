import React, {Component} from 'react';
import Form from "./Form";
import {connect} from "react-redux";
import {LoadingIndicator} from "../common/LoadingIndicator";
import {Col, Row} from "react-bootstrap";
import * as eventCategory from "../../state/entities/eventCategory";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Edit extends Component {

    componentDidMount() {
        this.props.loadEventCategory(this.props.eventCategoryId);
    }

    onSubmit = (eventCategory, {setErrors}) => {
        this.props.saveEventCategory(eventCategory, setErrors);
    };

    render() {
        const {eventCategory, loading, saving} = this.props;
        if (!eventCategory || loading) {
            return <LoadingIndicator/>;
        }
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["EventCategory"]} endpoints = {['event_category/breadcrumb']} title = "Eventarten" links={['/base/event-category/']} active={[eventCategory.id]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Bearbeiten des Eventarten <em>{eventCategory.name.de}</em></h1>
                </Col>
            </Row>
            <Form eventCategory={eventCategory} onSubmit={this.onSubmit} submitPending={saving} />
        </>;
    }
}

const mapStateToProps = (state, props) => {
    const eventCategoryId = props.match.params.id;
    return {
        eventCategoryId,
        loading: state.entities.eventCategory.loading[eventCategoryId],
        saving: state.entities.eventCategory.saving[eventCategoryId],
        eventCategory: state.entities.eventCategory.byId[eventCategoryId]
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadEventCategory: id => dispatch(eventCategory.actions.load({id})),
        saveEventCategory: entity => dispatch(eventCategory.actions.save({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
