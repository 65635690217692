import React, {Component} from 'react';
import FormCreate from "./FormCreate";
import * as seat from "../../../../../state/entities/seat";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import * as block from "../../../../../state/entities/block";
import {LoadingIndicator} from "../../../../common/LoadingIndicator";
import Breadcrumbs from "../../../../common/breadcrumbs/Breadcrumbs";

class Create extends Component {

    componentDidMount() {
        this.props.loadBlock(this.props.blockId);
    }

    onSubmit = (seats, {setErrors}) => {
        this.props.createSeat(seats, setErrors);
    };

    render() {
        const {blockLoading, block} = this.props;

        if (blockLoading || !block) {
            return <LoadingIndicator/>;
        }

        return <>
            <Row className="my-4">
                <Breadcrumbs
                    entity={["Spielstätte", "Saalplan", "Blockgruppe", "Block", "Sitzplätze"]}
                    endpoints={['venue/breadcrumb', `venue/${block.venue}/venue_plan/breadcrumb`, `venue_plan/${block.venuePlan}/block_group/breadcrumb`, `venue_plan/${block.venuePlan}/block/breadcrumb`, `block/${block.id}/seat/breadcrumb`]}
                    title={["Blockgruppen", "Blöcke", "Sitzplätze"]}
                    links={['/base/venue/', `/base/venue/${block.venue}/venue-plan/`, `/base/venue-plan/${block.venuePlan}/block-group/`, `/base/venue-plan/${block.venuePlan}/block/`, `/base/block/${block.id}/seat/`]}
                    active={[block.venue, block.venuePlan, 0, block.id]}
                />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Neue Sitzplätze in diesem Block anlegen</h1>
                </Col>
            </Row>
            <FormCreate seat={{}} onSubmit={this.onSubmit} submitPending={this.props.createPending} block={block} />
        </>;
    }
}

const mapStateToProps = (state, props) => {
    const blockId = props.match.params.bid;
    return {
        blockId,
        block: state.entities.block.byId[blockId],
        blockLoading: state.entities.block.loading[blockId],
        createPending: state.entities.seat.createPending
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createSeat: seats => dispatch(seat.actions.batch({seats})),
        loadBlock: id => dispatch(block.actions.load({id})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
