import React, { useState } from 'react';
import {Form} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {debounce} from 'lodash';
import log from 'loglevel';
import pegjs from 'pegjs';
import styles from './listView.module.scss';
import MaterialInput from '../../layout/MaterialInput';

const CHANGE_DEBOUNCE_WAIT = 500;

const QUERY_PARSER = pegjs.generate(`
{
    function join(tokens) {
        return tokens.join('');
    }
}

// Suchbegriffe sind eine beliebige whitespace separierte Folge von einfachen Begriffen und Phrasen
Keywords = keywords:((Term / Phrase) _*)* {
    return keywords.map(function(v) { return v[0] });
}

Term "term" = chars:[^" \\t\\n\\r:]+ {
    return join(chars);
}

// Suchbegriffe die Leerzeichen enthalten, müssen durch Anführungszeichen begrenzt werden
Phrase "phrase" = '"' chars:[^"]+ '"' {
    return join(chars);
}

_ "whitespace"
  = [ \\t\\n\\r]    
`);

/**
 * Input for list view search queries.
 *
 * @param onQueryChange callback that gets passed the list of parses search terms on query change.
 */
const ListViewSearchBar = ({ onQueryChange, style }) => {
    const parseQueryAndPassToCallback = debounce(query => {
        onQueryChange(QUERY_PARSER.parse(query));
    }, CHANGE_DEBOUNCE_WAIT);

    const handleSearchChange = value => {
        try {
            parseQueryAndPassToCallback(value);
        } catch (err) {
            if (err.name !== 'SyntaxError') {
                log.debug('error parsing keywords:', err);
            }
        }
    };

    return (
        <Form onSubmit={event => event.preventDefault()} className={styles.searchBarWrap} style={style}>
            <MaterialInput
                className="MuiFormControl-searchBar"
                label={'Suche'}
                onChange={handleSearchChange}
            />
        </Form>
    );
};

ListViewSearchBar.propTypes = {
    onQueryChange: PropTypes.func.isRequired
};

export default ListViewSearchBar;
