import React from "react";
import CurrencyInput from "react-currency-input-field";

export const getCentValue = (value) => {
    if (typeof value === 'string' || value instanceof String) {

        if (value.indexOf(',') === value.length - 1) {
            return Math.round(value.substring(0, value.length - 1) * 100).toString() + ',';
        } else if (value.substring(value.length - 2) === ',0') {
            return Math.round(value.substring(0, value.length - 2) * 100).toString() + ',0';
        } else if (value.substring(value.length - 3) === ',00') {
            return Math.round(value.substring(0, value.length - 3) * 100).toString() + ',00';
        } else {
            value = value.replace(',', '.', value);
        }
    }

    return Math.round(value * 100)
};

const MoneyInput = ({value, onChange, onBlur, onFocus, className}) => {
    if (value === undefined || value === null) {
        value = '';
    }

    if (value && (typeof value === 'string' || value instanceof String)) {
        if (value.indexOf(',') === value.length - 1) {
            value = value.substring(0, value.length - 1) / 100;
            value += ',';
        } else if (value.substring(value.length - 2) === ',0') {
            value = value.substring(0, value.length - 2) / 100;
            value += ',0';
        } else if (value.substring(value.length - 3) === ',00') {
            value = value.substring(0, value.length - 3) / 100;
            value += ',00';
        } else {
            value += ',00';
        }
    } else if (value) {
        value = value / 100;
    }

    const handleChange = (value) => {
        if (typeof value === "undefined" || value === '' || value === null) {
            onChange(undefined);
        } else {
            onChange(getCentValue(value));
        }
    };

    return <CurrencyInput
        className={className}
        intlConfig={{locale: 'de-DE', currency: 'EUR'}}
        value={value}
        onValueChange={handleChange}
        onBlur={onBlur} onFocus={onFocus}
        disableAbbreviations={true}
        decimalScale="2"
    />;
};

export default MoneyInput;
