import { history } from '../../configureStore';
import entitySlice from '../entity/slice';
import entitySaga from '../entity/saga';
import Api from '../../../api';
import {backendUser as schema} from '../../schema';

const onCreateSuccess = entity => history.push(`/base/backend-user/${entity.id}`);
const entityName = 'backendUser';

const slice = entitySlice(entityName);

export const actions = slice.actions;
export const reducer = slice.reducer;

const api = new Api();
const resource = api.createResource(entityName);

export const saga = entitySaga({
    actions: slice.actions,
    resource,
    schema,
    onCreateSuccess,
});


