import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { current } from '@reduxjs/toolkit';

/**
 * Create a new material dropdown instance.
 *
 * @param  		{Object} 		props
 * @property 	{Function} 		onChange 	The onChange callback.
 * @property 	{String|null} 	className 	CSS Selector classes.
 * @property 	{String|null} 	placeholder The inputs placeholder.
 * @property 	{String|null} 	value 		The current value.
 * @return 		{JSX}
 */
export const MaterialDropdown = ({
  className,
  label,
  onChange,
  value,
  options,
  startAdornment = true,
  endAdornment = true
}) => {
  const [currentValue, setCurrentValue] = useState(value || '');

  const onHandleChange = (event) => {
    event.preventDefault();
    onChange(event.target.value);
    setCurrentValue(event.target.value);
  };

  const onHanldleRemove = () => {
    setCurrentValue('');
    onChange('');
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <FormControl fullWidth variant="outlined" className={className}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={currentValue}
        onChange={onHandleChange}
        label={label}
        startAdornment={
          startAdornment && (
            <IconButton edge="start">
              <ArrowBackIcon sx={{ color: '#494949 !important' }} />
            </IconButton>
          )
        }
        endAdornment={
          endAdornment && (
            <IconButton edge="end" onClick={onHanldleRemove}>
              {currentValue ? (
                <CloseIcon sx={{ color: '#494949 !important' }} />
              ) : (
                <SearchIcon sx={{ color: '#494949 !important' }} />
              )}
            </IconButton>
          )
        }
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MaterialDropdown;