import React from 'react';
import {Switch} from 'react-router-dom';

import List from './List';
import Edit from './Edit';
import Create from './Create';
import {Container} from 'react-bootstrap';
import SecureRoute from "../auth/SecureRoute";
import ArchivedPricingClassList from "./ArchivedPricingClassList";

const Routing = () => {
    return (
        <Container className={'mt-1'}>
            <Switch>
                <SecureRoute exact path="/base/pricing-class/archived" component={ArchivedPricingClassList}/>
                <SecureRoute exact path="/base/pricing-class" component={List}/>
                <SecureRoute exact path="/base/pricing-class/create" component={Create}/>
                <SecureRoute exact path="/base/pricing-class/:id" component={Edit}/>
            </Switch>
        </Container>
    )
};

export default Routing;
