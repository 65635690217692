import React, {Component} from 'react';
import Form from "./Form";
import * as backendUser from "../../state/entities/backendUser";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Create extends Component {

    onSubmit = (backendUser, {setErrors}) => {
        this.props.createBackendUser(backendUser, setErrors);
    };

    render() {
        return <>
            <Row className="my-4">
                <Breadcrumbs
                    entity={["Benutzer"]}
                    endpoints={['backend_user/breadcrumb']}
                    title="Benutzer"
                    links={['/base/backend-user/']}
                    active={[0]}
                />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Administrative User anlegen</h1>
                </Col>
            </Row>
            <Form backendUser={{}} onSubmit={this.onSubmit} submitPending={this.props.createPending} />
        </>;
    }
}

const mapStateToProps = (state) => {
    return {
        createPending: state.entities.backendUser.createPending
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createBackendUser: entity => dispatch(backendUser.actions.create({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
