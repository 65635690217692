import React, {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import styles from "../form.module.scss";
import FormikCheckbox from "../common/formik/FormikCheckbox";
import FeedbackButton from "../common/FeedbackButton";
import classnames from "classnames";
import {get} from "lodash";
import debounce from "debounce-promise";
import Api from "../../api";
import {
    BlockTypeSelect,
    BoldItalicUnderlineToggles, CreateLink, headingsPlugin, linkDialogPlugin, linkPlugin, listsPlugin,
    ListsToggle, markdownShortcutPlugin,
    MDXEditor, quotePlugin,
    Separator, thematicBreakPlugin,
    toolbarPlugin,
    UndoRedo
} from "@mdxeditor/editor";

function FormInvoiceSettings({ formik }) {
    const handleChangeDebounced = debounce(value => {
        formik.setFieldValue('invoiceSettings.footer', value);
    }, 300);
    const api = new Api();
    const [previewBusy, setPreviewBusy] = useState(false);

    const handlePreview = () => {
        setPreviewBusy(true);
        api.previewInvoiceByTenant(formik.values)
            .then(result => {
                setPreviewBusy(false);
                const file = new Blob([result.data], {type: "application/pdf"});
                const fileURL = URL.createObjectURL(file);
                const pdfWindow = window.open();
                pdfWindow.location.href = fileURL;
            }).catch(err => {
            setPreviewBusy(false);
        });
    };
    return (
        <div className={styles.formBox}>
            <Row>
                <Col className="col-md-4">
                    <h2 className={styles.formTitle}>Einstellungen zu Ihren Ausgangsrechnungen</h2>
                </Col>
            </Row>
            <Row>
                <Col className="col-md-4">
                    <FormikCheckbox
                        label="Rechnungserstellung aktivieren"
                        name={'invoiceSettings.enabled'}
                    />
                </Col>
            </Row>
            {formik.values.invoiceSettings.enabled && (
                <>
                    <Row className="mt-3">
                        <Col className="col-md-12 col-lg-6">
                            <span className={classnames(styles.MDXEditorLabel, {[styles.MDXEditorLabelError] : get(formik.touched, 'invoiceSettings.footer') || get(formik.errors, 'invoiceSettings.footer') })}>Fußzeilen der Rechnung:*</span>
                            <MDXEditor
                                value={get(formik.values, 'invoiceSettings.footer', '')}
                                onChange={handleChangeDebounced}
                                markdown={get(formik.values, 'invoiceSettings.footer', '')}
                                className={classnames(styles.MDXEditorWrapWrap, {[styles.MDXEditorWrapError] : get(formik.touched, 'invoiceSettings.footer') || get(formik.errors, 'invoiceSettings.footer') })}
                                suppressHtmlProcessing={false}
                                contentEditableClassName={styles.MDXEditorContent}
                                plugins={[
                                    toolbarPlugin({
                                        toolbarContents: () => (
                                            <>
                                                <UndoRedo />
                                                <Separator />
                                                <BoldItalicUnderlineToggles />
                                                <Separator />
                                                <ListsToggle />
                                                <Separator />
                                                <BlockTypeSelect />
                                                <Separator />
                                                <CreateLink />

                                            </>
                                        )
                                    }),
                                    listsPlugin(),
                                    headingsPlugin(),
                                    linkPlugin(),
                                    linkDialogPlugin(),
                                    quotePlugin(),
                                    thematicBreakPlugin(),
                                    markdownShortcutPlugin(),
                                ]}
                            />
                            {get(formik.touched, 'invoiceSettings.footer') && get(formik.errors, 'invoiceSettings.footer') ? (
                                <div className="text-danger">{get(formik.errors, 'invoiceSettings.footer', '')}</div>
                            ) : null}
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-md-4">
                            <FeedbackButton
                                variant="contained"
                                onClick={handlePreview}
                                busy={previewBusy}
                            >
                                Vorschau
                            </FeedbackButton>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    )
}

FormInvoiceSettings.propTypes = {
    formik: PropTypes.object,
    tenant: PropTypes.object,
};

export default FormInvoiceSettings;
