import {RenderableSeat, SeatSet} from './common';
import Flatten from '@flatten-js/core';
import {Seat} from '../../../types';
import {calculateBoundingBox, difference, Point, sum} from '../../geometry';
import {Marquee, MARQUEE_CLICK_TARGET, Scene, SEAT_RADIUS} from '../scene';
import {Container, FederatedEventTarget, Rectangle} from 'pixi.js';
import {DispatchInteractionEvent} from '../interaction/event';
import {findMinimumPoint} from '../../geometry/util';

const SELECTION_MARQUEE_COLOR = 0xFF0000;

export interface Selection extends SeatSet {

    readonly id: string;

    readonly boundingBox: Flatten.Box;

    /**
     * Referenzpunkt für Verschiebungen der Auswahl.
     */
    readonly anchor: Point;


    /**
     * Returns on which element of the marquee the user clicked
     */
    getMarqueeClickTargetType(clickTarget: FederatedEventTarget): MARQUEE_CLICK_TARGET;

    /**
     * Verschiebt die Auswahl an einen bestimmten Punkt.
     *
     * Der Referenzpunkt auf den sich die Verschiebung bezieht ist der Mittelpunkt der bounding box der Auswahl.
     *
     * @param to Der Punkt zu dem die Auswahl verschoben werden soll.
     */
    move(to: Point): void;

    /**
     * Rotates selection by angle given with the two points.
     *
     * Rotation pivot point is cneter of selection bounding box.
     *
     * @param originMousePos Start mouse position in global coordinates
     * @param currentMousePos Current mouse position in global coordinates
     */
    rotate(originMousePos: Point, currentMousePos: Point): void;

    /**
     * Wendet evtl. Änderungen am transform der Auswahl durch move() etc. an,
     * d.h. die Änderungen werden auch tatsächlich auf die in der Auswahl
     * enthaltenen Seats angewendet. Vorher sind alle Änderungen nur
     * transienter Natur rein zur Anzeige.
     */
    commitTransform(): void;

}

export class CurrentSelection implements Selection {

    private marquee: Marquee = null;

    private readonly container: Container = new Container();
    private readonly innerTransformContainer: Container = new Container();

    private unCommittedChangesStarted = false;

    /**
     * Der aktuelle Ankerpunkt der Auswahl.
     *
     * Wird bei Änderungen der Position der Auswahl, i.e. commitTransform() neu berechnet.
     */
    private _anchor: Point;

    constructor(
        public readonly id: string,
        public readonly seats: RenderableSeat[],
        private readonly scene: Scene,
        private readonly dispatch: DispatchInteractionEvent
    ) {
        this._anchor = this.calculateAnchor();

        this.container.addChild(this.innerTransformContainer);

        // Für die Dauer der Auswahl werden die Seats in einen eigenen Container verschoben,
        // damit man diese "am Stück" transformieren kann ohne die Position jedes Seat
        // einzeln zu modifizieren.
        this.scene.viewport.seats.addChild(this.container);
        this.seats.forEach(s => {
            this.container.addChild(s.sprite);
            s.style = 'SELECTED';
        })

        this.positionMarquee();
    }

    [Symbol.iterator](): Iterator<Seat> {
        return this.seats[Symbol.iterator]();
    };

    positionMarquee() {
        if (this.marquee) this.scene.removeMarquee(this.marquee);
        const isMultiSeatSelection = this.seats.length > 1;
        this.marquee = this.scene.addMarquee(this.id, this.getMarqueeRect(), SELECTION_MARQUEE_COLOR,
            isMultiSeatSelection);
    }

    private startUncommittedChanges() {  //dieser Umstand leider nötig, weil bei commitTransform, die Seats wieder ihre
        //richtigen Koordinaten haben müssen, um sie per Changed-Event rauszuschicken.
        if (this.unCommittedChangesStarted) return;
        this.unCommittedChangesStarted = true;
        const bb = this.boundingBox;
        this.innerTransformContainer.x = (bb.xmin + bb.xmax) / 2;
        this.innerTransformContainer.y = (bb.ymin + bb.ymax) / 2;
        this.innerTransformContainer.rotation = 0;
        this.seats.forEach(s => {
            this.innerTransformContainer.addChild(s.sprite);
            s.sprite.position = this.innerTransformContainer.toLocal(s.sprite.position, this.container);
        });
}

    get anchor(): Point {
        return this._anchor;
    }

    get boundingBox(): Flatten.Box {
        return calculateBoundingBox(this.seats)
    }

    private getMarqueeRect(): Rectangle {
        const bb = this.boundingBox;
        const offset = this.container.position;
        const rect = new Rectangle(bb.xmin + offset.x, bb.ymin + offset.y, bb.xmax - bb.xmin, bb.ymax - bb.ymin);
        return rect.pad(SEAT_RADIUS);
    }

    getMarqueeClickTargetType(clickTarget: FederatedEventTarget) {
        if (!this.marquee) return MARQUEE_CLICK_TARGET.NONE;
        return this.marquee.getClickTargetType(clickTarget);
    }

    move(to: Point): void {
        this.startUncommittedChanges();
        this.container.position.copyFrom(difference(to, this.anchor));
        this.marquee.box = this.getMarqueeRect();
    }

    rotate(originMousePos: Point, currentMousePos: Point): void {
        this.startUncommittedChanges();
        const orgMousePosLoc = this.innerTransformContainer.toLocal(originMousePos);
        const orgRotation = Math.atan2(orgMousePosLoc.x, orgMousePosLoc.y);
        const currMousePosLoc = this.innerTransformContainer.toLocal(currentMousePos);
        const currRotation = Math.atan2(currMousePosLoc.x, currMousePosLoc.y);
        this.innerTransformContainer.rotation = orgRotation - currRotation;
    }

    contains(seat: Seat): boolean {
        return Boolean(this.seats?.find(s => s.id === seat.id));
    }

    commitTransform(): void {
        this.seats.forEach(s => {
            s.position = sum(this.container.position, this.container.toLocal(s.sprite.position, this.innerTransformContainer));
            this.container.addChild(s.sprite);
        })
        this.innerTransformContainer.position = this.innerTransformContainer.position.add(this.container.position);
        this.container.position.set(0, 0);
        this.marquee.box = this.getMarqueeRect();
        this._anchor = this.calculateAnchor();
        this.unCommittedChangesStarted = false;
        this.dispatch({type: 'SEATS_UPDATED', seats: Array.from(this)});
    }

    /**
     * Die Position des Ankerpunktes berechnen.
     */
    private calculateAnchor(): Point {
        // Es wird die Position des "oberen-linken" Seat genommen
        // oder als default der Mittelpunkt der Auswahl.
        return findMinimumPoint(this.seats, 'Y_ASC_X_ASC') ?? this.boundingBox.center;
    }

    private _clear(reinsertSeatsIntoScene: boolean = true): void {
        this.scene.removeMarquee(this.marquee);
        this.scene.viewport.seats.removeChild(this.container);

        if (reinsertSeatsIntoScene) {
            // Seats wieder direkt in die Szene einfügen.
            this.seats.forEach(s => {
                this.scene.viewport.seats.addChild(s.sprite);
                s.style = 'AVAILABLE';
            })
        }
    }

    /**
     * Hebt diese Auswahl auf.
     */
    clear(): void {
        this._clear();
    }

    /**
     * Löscht alle Sitzplätze in dieser Auswahl aus dem Saalplan und hebt die Auswahl auf.
     */
    deleteSeats(): void {
        this.dispatch({type: 'SEATS_DELETED', seats: Array.from(this)});
        this._clear(false);
    }

}
