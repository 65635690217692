import * as types from './types';

import {put, takeEvery} from 'redux-saga/effects'
import {
    queryBreadcrumbsError,
    queryBreadcrumbsSuccess,
} from './actions';

import Api from '../../../api';

export function* queryBreadcrumbsSaga({type, payload: {endpoint, query}}) {
    const api = new Api();
    try {
        const result = yield api.queryBreadcrumbs(endpoint, query);
        yield put(queryBreadcrumbsSuccess(endpoint, query, result));
    } catch (error) {
        yield put(queryBreadcrumbsError(endpoint, query));
    }
}

export default function* componentSaga() {
    yield takeEvery(types.QUERY_BREADCRUMBS, queryBreadcrumbsSaga);
}
