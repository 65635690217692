import { useDispatch, useSelector } from 'react-redux';
import { actions as venueEditorActions } from '../../state/entities/venueEditor';
import { Seat } from "../types";
import { IState, selectChangedSelectionBeforeChange, selectRedoHistory, selectUndoHistory, selectUpdatedSeatsBeforeUpdate } from '../../state/entities/venueEditor/selectors';
import { useCallback, useEffect, useRef } from 'react';
import { UpdateSource } from '../../state/entities/venueEditor/slice';


enum UndoActionType {
    SEATS_UPDATE = 'seatsUpdate',
    SEATS_SELECT = 'seatsSelect'
}

enum UndoDirection {
    USER = 'user',
    UNDO = 'undo',
    REDO = 'redo'
}

interface BaseUndoAction {
    type: UndoActionType;
}

interface UndoActionSeatsUpdate extends BaseUndoAction {
    type: UndoActionType.SEATS_UPDATE;
    seats: Seat[];
}

interface UndoActionSeatsSelect extends BaseUndoAction {
    type: UndoActionType.SEATS_SELECT;
    seatIds: string[];
}

export type UndoAction = UndoActionSeatsUpdate | UndoActionSeatsSelect;


interface IUseUndoManagerHook {
    handleUndo: () => void;
    handleRedo: () => void;
}


const useUndoManager = (): IUseUndoManagerHook => {
    const dispatch = useDispatch();
    const undoHistory = useSelector((state: IState) => selectUndoHistory(state));
    const redoHistory = useSelector((state: IState) => selectRedoHistory(state));
    const updatedSeatsBeforeUpdate = useSelector((state: IState) => selectUpdatedSeatsBeforeUpdate(state));
    const changedSelectionBeforeChange = useSelector((state: IState) => selectChangedSelectionBeforeChange(state));
    const undoDirectionRef = useRef(UndoDirection.USER);

    
    const updateHistoriesOnChange = useCallback((undoAction: UndoAction) => {
        if (undoDirectionRef.current === UndoDirection.UNDO) dispatch(venueEditorActions.addNewRedoAction(undoAction));
        else {
            dispatch(venueEditorActions.addNewUndoAction(undoAction));
            if (undoDirectionRef.current !== UndoDirection.REDO) dispatch(venueEditorActions.purgeRedoHistory());
        }
        undoDirectionRef.current = UndoDirection.USER;
    }, [dispatch]);
    

    const executeUndoAction = (action: UndoAction) => {
        switch (action.type) {
            case UndoActionType.SEATS_UPDATE:
                dispatch(venueEditorActions.updateSomeSeats(action.seats));
                break;
            case UndoActionType.SEATS_SELECT:
                dispatch(venueEditorActions.setSelectedSeatIds({
                    seatIds: action.seatIds,
                    selectedBy: UpdateSource.STORE
                }));
                break;
        }
    }


    useEffect(() => {
        console.log('add undo seats change');
        updateHistoriesOnChange({type: UndoActionType.SEATS_UPDATE, seats: updatedSeatsBeforeUpdate});
    }, [updatedSeatsBeforeUpdate, updateHistoriesOnChange]);


    useEffect(() => {
        console.log('add undo select, selected seats: ' + changedSelectionBeforeChange.length);
        updateHistoriesOnChange({type: UndoActionType.SEATS_SELECT, seatIds: changedSelectionBeforeChange});
    }, [changedSelectionBeforeChange, updateHistoriesOnChange]);



    const handleUndo = () => {
        if (!undoHistory.length) return;
        const lastUndoAction = undoHistory[undoHistory.length - 1];
        dispatch(venueEditorActions.deleteLastUndoAction());
        undoDirectionRef.current = UndoDirection.UNDO;
        console.log('undo type ' + lastUndoAction.type);
        executeUndoAction(lastUndoAction);
    }


    const handleRedo = () => {
        if (!redoHistory.length) return;
        const lastRedoAction = redoHistory[redoHistory.length - 1];
        dispatch(venueEditorActions.deleteLastRedoAction());
        undoDirectionRef.current = UndoDirection.REDO;
        console.log('redo type ' + lastRedoAction.type);
        executeUndoAction(lastRedoAction);
    }


    return { handleUndo, handleRedo };
}


export default useUndoManager;
