import React from "react";
import {calculateMainPricingComponentAmount} from "./helper";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export default ({grossAmount, itemTemplate}) => {
    const parsedGrossAmount = parseInt(grossAmount)
    if (grossAmount !== undefined && calculateMainPricingComponentAmount(parsedGrossAmount, itemTemplate) < 0) {
        return <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id={`tooltip-mainpricingwarning-${itemTemplate.id}`}>
                    {(parsedGrossAmount === 0)
                        ? "Bruttopreis darf nicht 0 sein, da für diese Leistung Preiskomponenten mit einem Betrag eingerichtet sind."
                        : "Unterdeckung des Bruttopreises"}
                </Tooltip>
            }
        >
            <span className="fa-stack">
              <i className="fa fa-circle fa-stack-2x text-danger"/>
              <i className="fa fa-warning fa-stack-1x fa-inverse"/>
            </span>
        </OverlayTrigger>
    }
    return null;
};
