import React from 'react';
import {Col, Row} from 'react-bootstrap';
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import styles from "../form.module.scss";
import { Checkbox } from '@mui/material';
import stylesTable from '../common/listView/listView.module.scss';

// since in venueEvent we don't get sent all salesChannels but only the ones that are selected we need to get all salesChannels here (hardcoded)
export const allSalesChannels = [
    {
        salesChannelId: "app",
        name: "App",
        personalDataOnTicketShouldBeCollected: false,
        personalDataOnTicketIsRequired: false,
    },
    {
        salesChannelId: "web",
        name: "Web",
        personalDataOnTicketShouldBeCollected: false,
        personalDataOnTicketIsRequired: false,
    },
    {
        salesChannelId: "ticket_agency",
        name: "Vorverkaufsstelle",
        personalDataOnTicketShouldBeCollected: false,
        personalDataOnTicketIsRequired: false,
    },
    {
        salesChannelId: "pos",
        name: "POS",
        personalDataOnTicketShouldBeCollected: false,
        personalDataOnTicketIsRequired: false,
    },
    {
        salesChannelId: "stadium_box_office",
        name: "EF Kassenverkauf",
        personalDataOnTicketShouldBeCollected: false,
        personalDataOnTicketIsRequired: false,
    },
    {
        salesChannelId: "backend",
        name: "Backend",
        personalDataOnTicketShouldBeCollected: false,
        personalDataOnTicketIsRequired: false,
    }
];

// since the names of salesChannels is not sent via api we need to get it through hardcoded allSalesChannels
export const getSalesChannelsName = (id) => {
    return allSalesChannels.find(ch => ch.salesChannelId === id).name;
}

export const getValues = (salesChannels) => {
    return allSalesChannels.map(aCh => {
        return salesChannels.find(ch => ch.salesChannelId === aCh.salesChannelId) ||
            {
                salesChannelId: aCh.salesChannelId,
                personalDataOnTicketShouldBeCollected: aCh.personalDataOnTicketShouldBeCollected,
                personalDataOnTicketIsRequired: aCh.personalDataOnTicketIsRequired
            };
    })
}
// function for checking / unchecking checkboxes
// acceptance criteria demand that if second checkbox (personalDataOnTicketIsRequired) is being checked,
// the first checkbox (personalDataOnTicketShouldBeCollected) should also be checked
// and if first checkbox is being unchecked the second checkbox should also be unchecked
export const toggleValue = (event, element, key, salesChannels) => {
    const elementIndex = getValues(salesChannels).findIndex(ch => ch.salesChannelId === element.salesChannelId)
    const newSalesChannelPersonalization = getValues(salesChannels);
    // if first checkbox is being deselected the second checkbox should be deselected, too
    if(key === "personalDataOnTicketShouldBeCollected" && !event.target.checked){
        newSalesChannelPersonalization.splice(elementIndex, 1, {
            ...getValues(salesChannels)[elementIndex],
            [key]: event.target.checked,
            "personalDataOnTicketIsRequired": false
        })
        // if second checkbox is being selected the first checkbox should be selected, too
    } else if(key === "personalDataOnTicketIsRequired" && event.target.checked){
        newSalesChannelPersonalization.splice(elementIndex, 1, {
            ...getValues(salesChannels)[elementIndex],
            [key]: event.target.checked,
            "personalDataOnTicketShouldBeCollected": true
        })
    } else {
        // in all other cases only the selected checkbox should be changed
        newSalesChannelPersonalization.splice(elementIndex, 1, {
            ...getValues(salesChannels)[elementIndex],
            [key]: event.target.checked
        })
    }
    return newSalesChannelPersonalization;
};

function FormPersonalization({formik, salesChannels}) {

    const ListRow = ({element}) => {

        const setValue = (event, element, key) => {
            formik.setFieldValue("salesChannelPersonalizations", toggleValue(event, element, key, salesChannels));
        }

        return (
            <tr data-testid={element.salesChannelId}>
                <td className="personalization-table-first-column">
                    <p>{getSalesChannelsName(element.salesChannelId)}</p>
                </td>
                <td>
                    <Checkbox
                        id={`personalDataOnTicketShouldBeCollected${element.salesChannelId}`}
                        data-testid={`personalDataOnTicketShouldBeCollected_${element.salesChannelId}`}
                        checked={element.personalDataOnTicketShouldBeCollected}
                        onChange={(event) => setValue(event, element, "personalDataOnTicketShouldBeCollected")}
                    />
                </td>
                <td>
                    <Checkbox
                        id={`personalDataOnTicketIsRequired${element.salesChannelId}`}
                        data-testid={`personalDataOnTicketIsRequired_${element.salesChannelId}`}
                        checked={element.personalDataOnTicketIsRequired}
                        onChange={(event) => setValue(event, element, "personalDataOnTicketIsRequired")}
                    />
                </td>
            </tr>
        )}

    return (
        <div className={styles.formBox}>
            <h2 className={styles.formTitle}>Personalisierung</h2>
            <Row className="mt-3">
                <Col className="pt-2 col-md-4">
                    <p className="d-inline">Sollen Namen an jedem einzelnen Ticket abgefragt werden?</p>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col className="pt-2 col-md-6">
                    <Table striped bordered hover className={stylesTable.TableWrap}>
                        <thead className={stylesTable.TableHead}>
                        <tr>
                            <th>
                                <span>Verkaufskanal</span>
                            </th>
                            <th>
                                <span>Personalisierungsdaten abfragen</span>
                            </th>
                            <th>
                                <span>Personalisierungspflicht</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody className={stylesTable.TableBody}>
                        {getValues(salesChannels).map((element) =>
                            <ListRow key={element.salesChannelId} element={element}/>)}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
    )
}

FormPersonalization.propTypes = {
    formik: PropTypes.object,
    salesChannels: PropTypes.array,
};



export default FormPersonalization;
