import React, { useState, useRef } from 'react';
import FormModal from './FormModal';
import styles from './modal.module.scss';
import FeedbackButton from '../../common/FeedbackButton';
import ConnectedListView from '../../common/listView/ConnectedListView';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const ListingModal = ({
    onHandleChooseImage,
    title,
    initialValues = {},
    validationSchema = {},
    show,
    onCancel
}) => {
    const [refreshListView, setRefreshListView] = useState(false);

    const onHandleRefresh = () => {
        setRefreshListView(prevState => !prevState);
    }

    return (
        <FormModal
            show={show}
            title={title}
            initialValues={initialValues}
            className={styles.formModalWrap}
            showIconRefresh={true}
            onHandleRefresh={onHandleRefresh}
            validationSchema={validationSchema}
            onCancel={() => {onCancel()}}
            isCustomFooterElems={true}
            renderFooterElems={() => (
                <FeedbackButton
                    to={`/base/media/`}
                    icon={<OpenInNewIcon />}
                    variant='contained'
                    target='_blank'
                >
                    Zur Medienverwaltung
                </FeedbackButton>
            )}
        >
        {() => {
            return (
                <ConnectedListView
                    endpoint={'listingView/media'}
                    testid="link_new_media"
                    className={styles.ListViewLayoutModal}
                    isShowSelectionWithBatchActions={false}
                    refreshListView={refreshListView}
                >
                    {({ item }) => (
                        <FeedbackButton onClick={() => onHandleChooseImage(item)}>auswählen</FeedbackButton>
                    )}
                </ConnectedListView>
            )
        }}
        </FormModal>
    )
};

export default ListingModal;
