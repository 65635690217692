import {useDrop} from "react-dnd";
import DragAndDropItemTypes from "./DragAndDropItemTypes";
import PropTypes from "prop-types";
import React from "react";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ConfirmModal from "../../common/modal/ConfirmModal";
import {dragOver} from "../../../state/entities/pricingMatrix/dragAndDrop/actions";
import {connect} from "react-redux";
import {hasItemTemplatesForClassId} from "../../../state/entities/pricingMatrix/cellMap/selectors";
import {getLocalItemTemplateById} from "./helper";
import {classDrop} from "../../../state/entities/pricingMatrix/cellMap/actions";
import {Button, Col, Row} from "react-bootstrap";
import {removePricingClass} from "../../../state/entities/pricingMatrix/actions";

export const MatrixRowHeader = ({pricingClass, onDrop, hasItems, onDragOver, remove}) => {

    let dropConfirm;
    const dropConfirmModal = <ConfirmModal
        title="Leistungsvorlagen überschreiben?"
        body="Es sind bereits Leistungsvorlagen hinterlegt. Wollen Sie diese überschreiben?"
    >
        {c => {
            dropConfirm = c
        }}
    </ConfirmModal>;

    const [{dragOver, backgroundColor}, dropRef] = useDrop({
        accept: DragAndDropItemTypes.PURCHASABLE_ITEM_TEMPLATE,
        drop: dragItem => hasItems ? dropConfirm(() => onDrop(pricingClass.id, dragItem.id)) : onDrop(pricingClass.id, dragItem.id),
        hover: () => {
            onDragOver(pricingClass.id)
        },
        collect: monitor => ({
            dragOver: !!monitor.isOver(),
            backgroundColor: monitor.getItem() && monitor.getItem().backgroundColor
        }),
    });
    const styling = {
        backgroundColor: dragOver ? backgroundColor : 'white'
    };

    return <th className="pricing-matrix-header" style={styling} ref={dropRef}>
        <Row>
            <Col className="align-self-center text-truncate text-col" title={pricingClass.name}>
                {pricingClass.name}
                {dropConfirmModal}
            </Col>
            <Col className="align-self-center text-truncate button-col">
                <span className="pull-right">
                    <ConfirmModal title="Preisklasse entfernen?"
                                  body="Möchten Sie diese Preisklasse wirklich aus dieser Preismatrix entfernen?">
                        {confirm =>
                            <Button onClick={() => confirm(remove)} className="button-right" variant="link" size="lg">
                                <DeleteOutlineOutlinedIcon className='table-icon' />
                            </Button>
                        }
                    </ConfirmModal>
                </span>
            </Col>
        </Row>
    </th>;
};

MatrixRowHeader.propTypes = {pricingCategory: PropTypes.object};

const mapStateToProps = (state, ownProps) => {
    return {
        hasItems: hasItemTemplatesForClassId(state, ownProps.pricingMatrix.id, ownProps.pricingClass.id)
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onDragOver: id => dispatch(dragOver(id)),
        onDrop: (classId, itemTemplateId) => {
            const localItemTemplate = getLocalItemTemplateById(ownProps.pricingMatrix, itemTemplateId);
            if (localItemTemplate) {
                dispatch(classDrop({
                    pricingMatrix: ownProps.pricingMatrix, classId, cellData: {localItemTemplateId: localItemTemplate.id}
                }));
            }
        },
        remove: () => dispatch(removePricingClass({
            matrixId: ownProps.pricingMatrix.id,
            pricingClass: ownProps.pricingClass
        }))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MatrixRowHeader);
