import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import {connect, ErrorMessage, getIn} from 'formik';
import { isEmpty, isArray } from "lodash";
import HelpContent from "../help/HelpContent";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckIcon from '@mui/icons-material/Check';
import FeedbackButton from '../../common/FeedbackButton';
import classnames from 'classnames';
/**
 * Stellt ein per Formik verwaltetes Typeahead (async) dar.
 *
 * @param id ID/Name des Wertes, der abgefragt wird.
 * @param label Das Label des Eingabefelds.
 * @param placeholder Der Platzhalter des Eingabefelds.
 * @param formik Die durch <Formik/> bereitgestellten props.
 * @param onSearch query-Funktion zum Laden neuer Werte anhand der Eingabe
 * @param restProps
 */
export const FormikAsyncTypeaheadInput = ({
    id, label, onSearch, testid = '',
    multiple = false,
    arrayOfStrings = false,
    allowNew = false,
    placeholder = 'Bitte wählen...',
    promptText = 'Tippen, um zu suchen…',
    searchText = 'Suche…',
    emptyLabel = 'Keine Ergebnisse gefunden',
    defaultInputValue = '',
    helpText = '',
    formik,
    onChange = null,
    customValue = null,
    ...restProps
    }) => {

    const labelKeyFunc = (val) => {
        if (typeof val === 'string') {
            return val;
        } else {
            return restProps.labelKey(val);
        }
    }

    const [options, setOptions] = useState([]);
    const [optionsDef, setOptionsDef] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const getValue = () => {
        if (customValue) {
            return customValue;
        }

        let value = getIn(formik.values, id);
        if (multiple) {
            if (isArray(value)) {
                if (value[0] === undefined) {
                    value = []
                }
            }

            return value || [];
        } else {
            return value ? [value] : [];
        }
    };

    const prevQuery = useRef(getValue()[0]?.name);

    const handleSearch = async (query) => {
        setLoading(true);
        const results = await onSearch(query);

        if (multiple) {
            let res = [];
            const formikValues = getIn(formik.values, id) || [];

            if (arrayOfStrings) {
                
                for (let i = 0; i < results.length; i++) {
                    formikValues.find(el => el === results[i]) === undefined && res.push(results[i]);
                }
            } else {
                for (let i = 0; i < results.length; i++) {
                    formikValues.find(el => el.id === results[i].id) === undefined && res.push(results[i]);
                }
            }

            setOptions(res);
        } else {
            setOptions(results);
        }


        setLoading(false);
    };

    const handleChange = ([head, ...tail]) => {
        if (multiple) {
            if ([head, ...tail][0] === undefined) {
                formik.setFieldValue(id, []);
            } else {
                if (arrayOfStrings) {
                    let uniqueItems = [...new Set([head, ...tail].map((el) => {
                        return (typeof el === "string") ? el : el.label;
                    }))];

                    formik.setFieldValue(id, uniqueItems);
                } else {
                    formik.setFieldValue(id, [head, ...tail]);
                }
            }
        } else {
            formik.setFieldValue(id, head || null);
            prevQuery.current = labelKeyFunc(head);
        }

        if (onChange) {
            onChange([head, ...tail]);
        }
    };

    const getError = () =>
        getIn(formik.errors, id);

    const isInvalid = () => {
        // getIn liefert aus bisher unerfindlichen Gründen manchmal Objekte anstatt einen boolean Wert,
        // daher hier force-cast, damit Aufrufer, die explizit einen boolean Werte erwarten nicht explodieren.
        return !!getError() && !!getIn(formik.touched, id);
    };

    const onMenuToggle = (isOpen) => {
        setIsOpen(isOpen);
    }

    useEffect(() => {
        handleSearch(defaultInputValue);
        if (!multiple) {
            onSearch('').then(res => setOptionsDef(res));
        }

        // Wir wollen diesen Effekt explizit nur einmal "on-mount" durchführen, daher geben wir [] als dependency an.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInputChange = (query) => {
        if (!query && !multiple) {
            setOptions(optionsDef);
        }
    }
    const handleFocus = (event) => {
        if (event.target.value === prevQuery.current && !multiple) {
            setOptions(optionsDef);
        }
    }

    const renderMenuItemChildren = (option, props) => {
        if (multiple) {
            return (
                <div className={classnames('MuiList-asyncTypeahead-option')}>
                    <span>{labelKeyFunc(option)}</span>
                </div>
            );
        }

        const selectedOption = getIn(formik.values, id);
        const selected = !isEmpty(selectedOption) ? labelKeyFunc(selectedOption) === labelKeyFunc(option) : false;

        return (
          <div className={classnames('MuiList-asyncTypeahead-option', { 'MuiList-asyncTypeahead-option--selected': selected })}>
            {selected && <CheckIcon className='MuiList-asyncTypeahead-icon' />}
            <span>{labelKeyFunc(option)}</span>
          </div>
        );
    };

    return (
        <Form.Group controlId={id} data-testid={testid} className='MuiFormControl-root-custom'>
            {label && <label className='MuiFormLabel-root-custom'>{label}</label>}
            {helpText && <HelpContent><p className="text-muted">{helpText}</p></HelpContent>}
            <div className='MuiFormBox'>
                <AsyncTypeahead
                    {...restProps}
                    id={id}
                    inputProps={{id: id, name: id, autoComplete: "off"}}
                    placeholder={placeholder}
                    renderMenuItemChildren={renderMenuItemChildren}
                    options={options}
                    onSearch={handleSearch}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    onBlur={formik.handleBlur}
                    onFocus={handleFocus}
                    selected={getValue()}
                    isLoading={isLoading}
                    filterBy={!multiple ? () => true : undefined}
                    isInvalid={isInvalid()}
                    promptText={promptText}
                    searchText={searchText}
                    useCache={!multiple ? false : true}
                    emptyLabel={emptyLabel}
                    defaultInputValue={defaultInputValue}
                    multiple={multiple}
                    allowNew={allowNew}
                    onMenuToggle={onMenuToggle}
                />
                {isOpen ? (
                    <FeedbackButton icon={<ArrowDropUpIcon className='table-icon' />} className={classnames('list-link', 'list-link__select')} />
                ) : (
                    <FeedbackButton icon={<ArrowDropDownIcon className='table-icon' />} className={classnames('list-link', 'list-link__select', 'list-link__selectNo')} />
                )}
            </div>
            <small className="form-text text-danger">
                <ErrorMessage name={id}/>
            </small>
        </Form.Group>
    );
}

FormikAsyncTypeaheadInput.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    arrayOfStrings: PropTypes.bool,
    allowNew: PropTypes.bool,
    formik: PropTypes.shape({
        touched: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
        values: PropTypes.object.isRequired,
        setFieldValue: PropTypes.func.isRequired,
        handleBlur: PropTypes.func.isRequired
    }).isRequired
};

export default connect(FormikAsyncTypeaheadInput);
