import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import ConnectedListView from '../common/listView/ConnectedListView';
import FeedbackButton from '../common/FeedbackButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import {connect} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import {get} from 'lodash';
import * as pricingClass from "../../state/entities/pricingClass";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import {BATCH_ACTION_MOVE_TO_ARCHIVE} from "../common/listView/ListViewBatchActions";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

const LinkToArchived = () => {
    return (
        <FeedbackButton
            to={`/base/pricing-class/archived`}
            icon={<RefreshIcon />}
            className='listLinkCenter'
            variant='contained'
        >
            Archiv
        </FeedbackButton>
    );
}

const Breadcrumb = () => {
    return (
        <Breadcrumbs entity={["Preisklasse"]} endpoints = {['pricing_class/breadcrumb']} title = "Preisklassen" links={['/base/pricing-class/']} active={[0]} />
    )
}

class List extends Component {
    linkNearHeader = LinkToArchived;
    heading = 'Preisklassen';
    listViewEndpoint = "listingView/pricingClass";
    breadcrumb = Breadcrumb;
    allowedBatchActions = [BATCH_ACTION_MOVE_TO_ARCHIVE];

    constructor(props) {
        super(props);

        if (props.linkNearHeader !== undefined) {
            this.linkNearHeader = props.linkNearHeader;
        }
        if (props.heading !== undefined) {
            this.heading = props.heading;
        }
        if (props.listViewEndpoint !== undefined) {
            this.listViewEndpoint = props.listViewEndpoint;
        }
        if (props.breadcrumb !== undefined) {
            this.breadcrumb = props.breadcrumb;
        }
        if (props.allowedBatchActions !== undefined) {
            this.allowedBatchActions = props.allowedBatchActions;
        }

        this.state = {
            showCopyConfirmDialog: false,
            pricingClassToCopy: undefined,
            showDeleteConfirmDialog: false,
            pricingClassToDelete: undefined
        }
    }

    showCopyConfirmDialog(pricingClass) {
        this.setState({
            showCopyConfirmDialog: true,
            pricingClassToCopy: pricingClass,
        });
    }

    handleCancelCopy = () => {
        this.setState({showCopyConfirmDialog: false});
    };

    handleConfirmCopy = () => {
        this.setState({showCopyConfirmDialog: false});
        this.props.copyPricingClass(this.state.pricingClassToCopy);
    };

    showDeleteConfirmDialog(pricingClass) {
        this.setState({
            showDeleteConfirmDialog: true,
            pricingClassToDelete: pricingClass,
        });
    }

    handleCancelDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
    };

    handleConfirmDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
        this.props.deletePricingClass(this.state.pricingClassToDelete);
    };

    render() {
        return (
            <Container>
                <Row className="my-4">
                    { this.breadcrumb() }
                </Row>
                <ConnectedListView
                    endpoint={this.listViewEndpoint}
                    linkNearHeading={this.linkNearHeader}
                    heading={this.heading}
                    buttonTitle="Neue Preisklasse"
                    buttonLink="/base/pricing-class/create"
                    testid="link_new_pricingClass"
                    isShowSelectionWithBatchActions={true}
                    allowedActions={this.allowedBatchActions}
                >
                    {({fields, item}) => (
                        <>
                            <FeedbackButton
                                title="Preisklasse bearbeiten"
                                className="list-link"
                                to={`/base/pricing-class/${item.id}`}
                                icon={<EditNoteOutlinedIcon className='table-icon' />}
                            />
                            <FeedbackButton title="Preisklasse kopieren" className="list-link" onClick={() => this.showCopyConfirmDialog(item)} data-testid="link_copy_pricingClass">
                                <ContentCopyOutlinedIcon className='table-icon' />
                            </FeedbackButton>
                            <FeedbackButton title="Preisklasse löschen" className="list-link" onClick={() => this.showDeleteConfirmDialog(item)} data-testid="link_delete_pricingClass">
                                <DeleteOutlineOutlinedIcon className='table-icon' />
                            </FeedbackButton>
                        </>
                    )}
                </ConnectedListView>


                <Modal show={this.state.showCopyConfirmDialog} onHide={this.handleCancelCopy}>
                    <Modal.Header closeButton>
                        <Modal.Title>Preisklasse kopieren</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Möchten Sie die Preisklasse "{get(this.state, 'pricingClassToCopy.name')}" wirklich kopieren?</Modal.Body>
                    <Modal.Footer>
                        <FeedbackButton variant="outlined" onClick={this.handleCancelCopy}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton onClick={this.handleConfirmCopy}>
                            Kopieren
                        </FeedbackButton>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showDeleteConfirmDialog} onHide={this.handleCancelDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Preisklasse löschen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Möchten Sie die Preisklasse "{get(this.state, 'pricingClassToDelete.name')}" wirklich löschen?</Modal.Body>
                    <Modal.Footer>
                        <FeedbackButton variant="outlined" onClick={this.handleCancelDelete}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton onClick={this.handleConfirmDelete}>
                            Löschen
                        </FeedbackButton>
                    </Modal.Footer>
                </Modal>

            </Container>
        );
    }
}

export default connect(null, {
    copyPricingClass: entity => pricingClass.actions.copy({entity}),
    deletePricingClass: entity => pricingClass.actions.delete({entity}),
})(List);
