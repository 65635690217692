import React, {Component} from 'react';
import ConnectedListView from '../../common/listView/ConnectedListView';
import FeedbackButton from '../../common/FeedbackButton';
import {connect} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {get} from 'lodash';
import * as venuePlan from "../../../state/entities/venuePlan";
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import styles from "../../form.module.scss";
import AddIcon from '@mui/icons-material/Add';

class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDeleteConfirmDialog: false,
            venuePlanToDelete: undefined,
        }
    }

    showDeleteConfirmDialog(venuePlan) {
        this.setState({
            showDeleteConfirmDialog: true,
            venuePlanToDelete: venuePlan,
        });
    }

    handleCancelDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
    };

    handleConfirmDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
        this.props.deleteVenuePlan(this.state.venuePlanToDelete);
    };

    render() {
        return (
            <>
                <div className={styles.formBox}>
                    <h2 className={styles.formTitle}>Saalpläne</h2>
                    <FeedbackButton
                        to={`/base/venue/${this.props.venueId}/venue-plan/create`}
                        icon={<AddIcon />}
                        variant='contained'
                    >
                        Neuer Saalplan
                    </FeedbackButton>
                </div>
                <ConnectedListView endpoint={`listingView/venuePlan?venueId=${this.props.venueId}`}>
                    {({fields, item}) => (
                        <>
                            <FeedbackButton
                                title="im Saalplaneditor bearbeiten"
                                to={`/base/venue-plan/${item.id}/seating/editor/ui`}
                                target="_blank"
                                className='p-0'
                                style={{ textAlign: 'left'}}
                            >
                                im Saalplaneditor bearbeiten
                            </FeedbackButton>
                            <FeedbackButton
                                className="list-link"
                                title="Saalplan bearbeiten"
                                to={`/base/venue/${this.props.venueId}/venue-plan/${item.id}`}
                                icon={<EditNoteOutlinedIcon className='table-icon' />}
                            />
                            <FeedbackButton
                                icon={<DeleteOutlineOutlinedIcon className='table-icon' />}
                                className="list-link"
                                onClick={() => this.showDeleteConfirmDialog(item)}
                            />
                        </>
                    )}
                </ConnectedListView>

                <Modal show={this.state.showDeleteConfirmDialog} onHide={this.handleCancelDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Saalplan löschen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Möchten Sie den Saalplan "{get(this.state, 'venuePlanToDelete.name')}" wirklich löschen?</Modal.Body>
                    <Modal.Footer>
                        <FeedbackButton variant="outlined" onClick={this.handleCancelDelete}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton onClick={this.handleConfirmDelete}>
                            Löschen
                        </FeedbackButton>
                    </Modal.Footer>
                </Modal>

            </>
        );
    }
}


export default connect(null, {
    deleteVenuePlan: entity => venuePlan.actions.delete({entity})
})(List);
