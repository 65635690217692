import React, {Component} from 'react';
import Form from "./Form";
import {connect} from "react-redux";
import {LoadingIndicator} from "../common/LoadingIndicator";
import {Col, Row} from "react-bootstrap";
import * as media from "../../state/entities/media";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Edit extends Component {

    componentDidMount() {
        this.props.loadMedia(this.props.mediaId);
    }

    onSubmit = (media, {setErrors}) => {
        this.props.saveMedia(media, setErrors);
    };

    render() {
        const {media, loading, saving} = this.props;
        if (!media || loading) {
            return <LoadingIndicator/>;
        }
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["media"]} endpoints = {['media/breadcrumb']} title = "Medien" links={['/base/media/']} active={[media.id]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Bearbeiten des Medien <em>{media.title}</em></h1>
                </Col>
            </Row>
            <Form media={media} onSubmit={this.onSubmit} submitPending={saving} />
        </>;
    }
}

const mapStateToProps = (state, props) => {
    const mediaId = props.match.params.id;
    return {
        mediaId,
        loading: state.entities.media.loading[mediaId],
        saving: state.entities.media.saving[mediaId],
        media: state.entities.media.byId[mediaId]
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadMedia: id => dispatch(media.actions.load({id})),
        saveMedia: entity => dispatch(media.actions.save({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
