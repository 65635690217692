import React, { ReactNode } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';


interface MuiLoadingButtonProps {
    children: ReactNode;
    isLoading: boolean;
    handleSubmit: () => void;
    sx?: object;
}

export const MuiLoadingButton: React.FC<MuiLoadingButtonProps> = ({
    children,
    sx,
    isLoading,
    handleSubmit
}) => {
    return (
        <LoadingButton 
            loading={isLoading} 
            onClick={handleSubmit} 
            variant='contained'
            sx={ sx }
        >
        {children}
        </LoadingButton>
    )
};

export default MuiLoadingButton
