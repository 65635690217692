import React from 'react';
import Form from "./Form";
import * as targetGroup from "../../state/entities/targetGroup";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

const Create = ({createPending, createTargetGroup}) => {

    const onSubmit = (targetGroup, {setErrors}) => {
        createTargetGroup(targetGroup, setErrors);
    };


    return <>
        <Row className="my-4">
            <Breadcrumbs entity={["Warenkorbposition"]} endpoints = {['target_group/breadcrumb']} title = "Personenpools" links={['/base/target-group/']} active={[0]} />
        </Row>
        <Row className="mb-4 mt-4">
            <Col className="pt-3 col-sm-8">
                <h1>Erstellen eines neuen Personenpools</h1>
            </Col>
        </Row>
        <Form targetGroup={{}} onSubmit={onSubmit} submitPending={createPending} />
    </>;

}

const mapStateToProps = (state, props) => {
    return {
        createPending: state.entities.targetGroup.createPending
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createTargetGroup: entity => dispatch(targetGroup.actions.create({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
