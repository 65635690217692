import React from 'react';
import {Dropdown} from 'react-bootstrap';

/**
 * Dropdown Toggle
 */
const DropdownToggle = ({option, title, index, breadcrumbs}) => {

    const getActiveItem = () => {
        return option.items.find(op => op.active).name
    }

    const getHeading = () => {
        if (typeof title === 'string') {
            return title
        } else {
            // if title is an array of names, it means that we are on a page where two breadcrumbs should be shown, searchable and titled
            return title.find((t, i) => index === breadcrumbs.length - (title.length - i))
        }
    }

    return (
        <>
            <Dropdown.Toggle variant="outline-info" className="breadcrumb-item breadcrumbs-dropdown-btn"
                             id="dropdown-basic">
                <li className="breadcrumb-item" key={index}>
                    {/*if item is active, it should appear as heading of breadcrumb, otherwise the title of the breadcrumb should be displayed*/}
                    {option.items.find(op => op.active) ? getActiveItem() : getHeading()}
                </li>
            </Dropdown.Toggle>
        </>
    );
};


export default DropdownToggle;

