import React from 'react';
import { Field, FieldProps } from 'formik';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

export interface ItemsProps {
    value: string;
    name: string;
    component: JSX.Element | '';
    id?: string;
}

interface IFormikRadioProps {
    name: string;
    defaultValue?: string;
    className?: string;
    items: ItemsProps[]
}

export interface IFormValues {
    [field: string]: string;
}

export const FormikRadio: React.FC<IFormikRadioProps> = ({
    name,
    defaultValue,
    items,
    className
}) => (
    <Field name={name}>
        {({ field }: FieldProps<string>) => {
            return (
                <RadioGroup
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    defaultValue={defaultValue}
                    className={className}
                >
                    {items.map((item) => (
                        <div key={item.value}>
                            <FormControlLabel
                                id={`${field.name}/${item.id}`}
                                value={item.value}
                                control={<Radio />}
                                label={item.name}
                            />
                            {item.component}
                        </div>
                    ))}
                </RadioGroup>
            );
        }}
    </Field>
);

export default FormikRadio;
