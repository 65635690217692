import React, { useEffect, useRef } from "react";
import styles from "./baseLayout.module.scss";
import FeedbackButton from "../../components/common/FeedbackButton";
import { useDispatch, useSelector } from "react-redux";
import { IState, selectAllSeats, selectInteractionMode, selectPlacepoolDefinitions, selectSelectedAreaForm, selectSelectedSeats, selectVenuePlan } from "../../state/entities/venueEditor/selectors";
import { Seat, SeatInArrangement } from "../types";
import { cloneDeep, countBy, get } from "lodash";
import classnames from "classnames";
import { InteractionMode } from "../editor/display/interaction";
import { actions as venueEditorActions} from '../../state/entities/venueEditor';
import {  IAreaFormsManager } from "../editor/display/areaForms/areaFormsManager";
import { AreaFormPlacePool } from "../editor/display/areaForms/AreaFormData";
import { Formik, Form, Field } from "formik";
import { getLuminance } from "../editor/geometry/util";


enum PPSelectionState {
    ALL = "allSelected",
    PARTIALLY = "partiallySelected",
    NONE = "noneSelected"
}

export type TabPlacepoolChangeFunc = (seats:Seat[], tagsChanged: boolean) => void;

interface ITabPlacePoolsProps {
    areaFormsManager: IAreaFormsManager;
    onSelectedSeatsChange?: TabPlacepoolChangeFunc;
}



// Tip: Tag is the same as Placepool (should be unified once)
const TabPlacePools: React.FC<ITabPlacePoolsProps> = ({areaFormsManager, onSelectedSeatsChange}) => {
    const interactionMode = useSelector((state: IState) => selectInteractionMode(state));

    return (
        <div className={styles.tabsContent}>
            <h2 className={styles.tabsContent__title}>Platzpools</h2>
            {[InteractionMode.AREAFORMS, InteractionMode.ADD_AREAFORM].includes(interactionMode)
                ? <ListAreaFormsPlacePools
                    areaFormsManager={areaFormsManager}
                    onSelectedSeatsChange={onSelectedSeatsChange}
                />
                : <ListSeatPlacePools onSelectedSeatsChange={onSelectedSeatsChange} />
            }
        </div>
    );
};


interface IListSeatPlacePoolsProps {
    onSelectedSeatsChange?: TabPlacepoolChangeFunc;
}

const ListSeatPlacePools: React.FC<IListSeatPlacePoolsProps> = ({onSelectedSeatsChange}) => {
    const selectedSeats = useSelector((state: IState) => selectSelectedSeats(state));
    const allSeats = useSelector((state: IState) => selectAllSeats(state));
    const placepoolDefinitions = useSelector((state: IState) => selectPlacepoolDefinitions(state));

    // 2 seperate functions, so that complete counting isn't done for every tag (performance)
    const getFlatSelectedTagCounts = (selectedSeats) => {
        const seatsToCount = anySeatsSelected ? selectedSeats : Object.values(allSeats);
        // flatten tags from all selected seats
        const appliedTags: string[] = seatsToCount.map((selectedSeat: Readonly<SeatInArrangement>) => selectedSeat?.tags)
            .reduce((a, b) => a?.concat(b), []);
        return countBy(appliedTags);
    }
    const anySeatsSelected = selectedSeats && selectedSeats.length > 0;
    const flatTagCounts = getFlatSelectedTagCounts(selectedSeats);

    const getTagSeletionState = (tagId: string): PPSelectionState => {
        if (!flatTagCounts[tagId]) return PPSelectionState.NONE;
        const tagCount: number = get(flatTagCounts, tagId);
        return tagCount === selectedSeats.length ? PPSelectionState.ALL : PPSelectionState.PARTIALLY;
    };
    
    const handlePlacepoolSelect = (placepoolId: string) => {
        let changedSeats = selectedSeats.map((seat) => {
            let newSeat: Seat =  {...seat};
            if (newSeat.tags === undefined) newSeat.tags = [];
            if (getTagSeletionState(placepoolId) === PPSelectionState.ALL) { //deselect for all
                newSeat.tags = newSeat.tags.filter((pp) => pp !== placepoolId);
            } else {
                if (!newSeat.tags.includes(placepoolId)) newSeat.tags = [...newSeat.tags, placepoolId]; //select for all
            }
            return newSeat;
        });
        onSelectedSeatsChange(changedSeats, true);
    }

    const handleDeselectAllClick = () => {
        let changedSeats = selectedSeats.map((seat) => {
            return {...seat, tags: []};
        });
        onSelectedSeatsChange(changedSeats, true);
    }

    return (
        <>
            <h3>Platzpools werden genutzt, um den Verkauf und die Preise mittels Verkaufsregeln steuern zu können. Plätze können zu mehreren Platzpools gehören.</h3>
            <div className={styles.tabsContent__colorsContainer}>
                <div className={classnames(styles.tabsContent__colorBox, styles.tabsContent__colorBoxHead)}>
                    <p className={styles.tabsContent__colorBoxTitle} style={{ fontWeight: 'bold'}}>Platzpools</p>
                    <p className={styles.tabsContent__colorBoxNumber} style={{ fontWeight: 'bold'}}>Anzahl</p>
                </div>
                {placepoolDefinitions.map((pp, index) => (
                    <PlacePoolButton
                        key={pp.id}
                        backgroundColor={pp.color}
                        title={pp.name}
                        tagId={pp.id}
                        count={flatTagCounts[pp.id] || 0}
                        showCapacity={false}
                        selectionState={getTagSeletionState(pp.id)}
                        onSelect={handlePlacepoolSelect}
                    />
                ))}
            </div>
            <FeedbackButton className="mt-2" variant="outlined" disabled={selectedSeats.length === 0} onClick={handleDeselectAllClick}>Alle abwählen</FeedbackButton>
        </>
    );
}


interface IListAreaFormsPlacePoolsProps {
    areaFormsManager: IAreaFormsManager;
    onSelectedSeatsChange?: TabPlacepoolChangeFunc;
}

const ListAreaFormsPlacePools: React.FC<IListAreaFormsPlacePoolsProps> = ({areaFormsManager, onSelectedSeatsChange}) => {
    const dispatch = useDispatch();
    const placepoolDefinitions = useSelector((state: IState) => selectPlacepoolDefinitions(state));
    const selectedAreaForm = useSelector((state: IState) => selectSelectedAreaForm(state));
    const venuePlan = useSelector((state: IState) => selectVenuePlan(state));


    const initialValues = {
        placePools: placepoolDefinitions.reduce((acc, pp) => {
            acc[pp.id] = {
                capacity: selectedAreaForm?.placePools?.find(app => app.id === pp.id)?.capacity ??
                    selectedAreaForm?.capacity ?? 0
            };
            return acc;
        }, {})
    };


    const getTagSeletionState = (tagId: string): PPSelectionState => {
        if (!selectedAreaForm) return PPSelectionState.NONE;
        return selectedAreaForm.placePools.find(pp => pp.id === tagId)
            ? PPSelectionState.ALL
            : PPSelectionState.NONE
    };
    
    const handlePlacepoolSelect = (placepoolId: string) => {
        let newAreaFormData = cloneDeep(selectedAreaForm);
        let containedPP = false;
        if (!newAreaFormData.placePools) newAreaFormData.placePools = [];
        newAreaFormData.placePools = newAreaFormData.placePools.filter(pp => {
            if (pp.id === placepoolId) {
                containedPP = true;
                return false;
            } else return true;
        })
        if (!containedPP) newAreaFormData.placePools.push({
            id: placepoolId,
            capacity: newAreaFormData.capacity
        } as AreaFormPlacePool);
        dispatch(venueEditorActions.updateAreaForms({venuePlanId: venuePlan.id, updatedAreaForms: [newAreaFormData]}));
    }

    const handleSubmit = (values) => {
        const newAreaFormData = cloneDeep(selectedAreaForm);
        for (const ppi in values.placePools) {
            const afpp = newAreaFormData.placePools.find(app => app.id === ppi);
            if (!afpp) continue;
            afpp.capacity = values.placePools[ppi].capacity;
        }
        dispatch(venueEditorActions.updateAreaForms({venuePlanId: venuePlan.id, updatedAreaForms: [newAreaFormData]}));
    }


    return (
        <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
            {formik => (
                <Form className={styles.placePoolsFormLayout}>
                    <h3>Platzpools werden genutzt, um den Verkauf und die Preise mittels Verkaufsregeln steuern zu können. Blöcke können zu mehreren Platzpools gehören.</h3>
                    {!selectedAreaForm
                        ? <h3><br/><br/>Kein Stehplatzblock ausgewählt.</h3>
                        :
                        <div className={styles.tabsContent__colorsContainer}>
                            <div className={classnames(styles.tabsContent__colorBox, styles.tabsContent__colorBoxHead)}>
                                <p className={styles.tabsContent__colorBoxTitle} style={{ fontWeight: 'bold'}}>Platzpools</p>
                                <p className={styles.tabsContent__colorBoxCapacity} style={{ fontWeight: 'bold'}}>Kapazität</p>
                            </div>
                            <div className={styles.scrollableList}>
                                {placepoolDefinitions.map((pp, index) => (
                                    <PlacePoolButton
                                        key={pp.id}
                                        backgroundColor={pp.color}
                                        title={pp.name}
                                        tagId={pp.id}
                                        count={0}
                                        selectionState={getTagSeletionState(pp.id)}
                                        showCapacity
                                        fieldName={`placePools[${pp.id}].capacity`}
                                        onSelect={handlePlacepoolSelect}
                                    />
                                ))}
                            </div>
                            <div className={styles.bottomLineContainer}>
                                <FeedbackButton variant="outlined" onClick={() => formik.submitForm()}>
                                    Speichern
                                </FeedbackButton>
                            </div>
                        </div>
                    }
                </Form>
            )}
        </Formik>
    );
}




interface IPlacePoolButtonProps {
    backgroundColor: string,
    title: string,
    tagId: string,
    selectionState: PPSelectionState,
    count: number,
    showCapacity: boolean,
    fieldName?: string,
    onSelect: (placepoolId: string) => void;
}


const PlacePoolButton: React.FC<IPlacePoolButtonProps> = ({
    backgroundColor,
    title,
    tagId,
    selectionState,
    count,
    showCapacity,
    fieldName,
    onSelect
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current && selectionState === PPSelectionState.ALL) inputRef.current.focus();
    }, [selectionState]);

    const handleClick = () => {
        onSelect(tagId);
    }

    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    }

    // white text if dark background
    const textColor = selectionState === PPSelectionState.ALL &&
        getLuminance(parseInt(backgroundColor.slice(1), 16)) < 128
        ? '#FFF'
        : '#000';

    return (
        <div 
            className={styles.tabsContent__colorBox} 
            style={selectionState === PPSelectionState.NONE
                ? {}
                : (selectionState === PPSelectionState.ALL 
                    ? {backgroundColor: `#${backgroundColor}`, color: textColor}
                    : {backgroundColor: "#DDD"})
            }
            onClick={handleClick}
        >
            <div style={{ backgroundColor: "#" + backgroundColor }} className={styles.tabsContent__colorBoxColor}>
            </div>
            <p className={styles.tabsContent__colorBoxTitle} style={{color: textColor}}>{title}</p>
            {!showCapacity && (<p className={styles.tabsContent__colorBoxNumber} style={{color: textColor}}>{count}</p>)}
            {showCapacity && selectionState === PPSelectionState.ALL && fieldName && (
                <Field
                    name={fieldName}
                    innerRef={inputRef}
                    type="number"
                    onClick={stopPropagation}
                    className={`${styles.flexItem} ${styles.inputField}`}
                />
            )}
        </div>
    );
}



export default TabPlacePools;
