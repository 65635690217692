import {useEffect} from "react";
import {connect} from 'formik';
import {debounce} from 'lodash';

const debouncedFormikSubmit = debounce(submitForm => {submitForm()}, 250);

const AutoSubmit = ({formik}) => {
    useEffect(() => debouncedFormikSubmit(formik.submitForm), [formik.values, formik.submitForm]);
    return null;
};

export default connect(AutoSubmit);
