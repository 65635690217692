import * as yup from 'yup';
import {inRange, isInteger} from 'lodash';

export function validateEndDateNotWithoutStartDate(startDate, endDate) {
    return !endDate || !!startDate;
}

export const urlPattern = /^(?:([a-z0-9+.-]+):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/;
export const urlError = 'Muss eine gültige URL sein';

/**
 * Checks if a range of dates defined by a start and end is valid.
 *
 * @param start {Date?} The beginning of the range.
 * @param end  {Date?} The end of the range.
 * @returns {boolean} true if the range is open on either end (i.e. start or end is missing),
 * of if end is after start.
 */
export function validateDateRange(start, end) {
    return !start || !end || (start < end);
}

export function validateDays(from, until) {
    if (isInteger(parseInt(from)) && isInteger(parseInt(until))) {
        return parseInt(from) >= parseInt(until);
    } else {
        return true;
    }
}

export function validateNumberOrEmptyString(value) {
    if (isInteger(parseInt(value))) {
        if (inRange(parseInt(value), 1, 1000)) {
            return true;
        } else {
            return false;
        }
    }
    return value === '';
}

const I18nText = yup.object().shape({
    de: yup.string().nullable().default(''),
    en: yup.string().nullable().default(''),
})

const Tags = yup.object().shape({
    de: yup.array().of(yup.string()).nullable().default([]),
    en: yup.array().of(yup.string()).nullable().default([]),
})

export const SalesRuleAssociation = yup.object().shape({
        enabled: yup.bool().default(false),
        ticketLayout: yup.string().nullable().test(
            'ticket_layout_required',
            'Ticketlayout ist erforderlich',
            ticketLayout => !!ticketLayout
        ),
        validFrom: yup.date().nullable().test(
            'start_before_end',
            'Der Startzeitpunkt muss vor dem Endzeitpunkt liegen',
            function (validFrom) {
                return validateDateRange(validFrom, this.parent.validTo);
            }
        ),
        validTo: yup.date().nullable()
            .test(
                'end_after_start',
                'Der Endzeitpunkt muss nach dem Startzeitpunkt liegen',
                function (validTo) {
                    return validateDateRange(this.parent.validFrom, validTo);
                }
            ),
        maxTicketsPerUser: yup.mixed().test(
            'is_number_or_empty_string',
            'Bitte geben Sie eine passende Zahl ein',
            maxTicketsPerUser => validateNumberOrEmptyString(maxTicketsPerUser)
        ).default(''),
        deliveryGroups: yup.array().default([]).of(yup.object().shape({
            shippingCode: yup.string(),
            activeFromWorkingDaysBeforeEventStartDate: yup.string().nullable().test(
                'higher_than_until',
                'Dieser Wert muss höher liegen als bis Tage vorher, oder gleich',
                function (activeFromWorkingDaysBeforeEventStartDate) {
                    return validateDays(activeFromWorkingDaysBeforeEventStartDate, this.parent.activeUntilWorkingDaysBeforeEventStartDate);
                }
            ),
            activeUntilWorkingDaysBeforeEventStartDate: yup.string().nullable().test(
                'lower_than_from',
                'Dieser Wert muss niedriger sein als ab Tage vorher, oder gleich',
                function (activeUntilWorkingDaysBeforeEventStartDate) {
                    return validateDays(this.parent.activeFromWorkingDaysBeforeEventStartDate, activeUntilWorkingDaysBeforeEventStartDate);
                }
            ),
            lastApplicableDateTime: yup.string().nullable(),
            lastApplicableDayTime: yup.string().nullable(),
        })),
        printAtHomeTicketLayout: yup.string().nullable().test(
            'printAtHomeTicketLayout_required',
            'Ticketlayout ist erforderlich',
            printAtHomeTicketLayout => !!printAtHomeTicketLayout
        ),
        tags: yup.array().default([]),
        provideDownloadUrl: yup.bool().default(true),
        activeMembersOnly: yup.bool().default(false),
        activeMemberSinceAt: yup.date().nullable().test(
            'active_member_since_at_required',
            'Muss Mitglied sein seit ist erforderlich',
            function (activeMemberSinceAt) {
                if (this.parent.activeMembersOnly) {
                    return !!activeMemberSinceAt;
                }
                return true;
            }
        ).default(null),
        secondaryMarketFeeFactorCode: yup.string().test(
            'secondary_market_fee_factor_code_required',
            'Ticketbörsengebühr ist erforderlich',
            secondaryMarketFeeFactorCode => !!secondaryMarketFeeFactorCode
        ).default('FREE'),
        useFor: yup.string().nullable(),
    }
);

export const Quota = yup.object().shape({
        name: yup.string().required(),
        capacity: yup.number().required().integer(),
        eventSalesRuleIds: yup.array().of(yup.string()).default([])
    }
);

export const MaxTicketsSalesRulesGroup = yup.object().shape({
        name: yup.string().required().default(''),
        eventSalesRuleIds: yup.array().of(yup.string()).default([]),
        enabled: yup.bool().default(false),
        maxTicketsPerUser: yup.number().required().integer()
    }
);

export const BundleSalesRule = yup.object().shape({
        eventSalesRuleId: yup.string(),
        minTickets: yup.number(),
        maxTickets: yup.number(),
    }
);

export const Bundle = yup.object().shape({
        name: yup.string().required(),
        salesRules: yup.array().of(BundleSalesRule).default([]),
        description: yup.object().shape({
            de: yup.string().required(),
            en: yup.string().required(),
        }),
        enabled: yup.bool(),
    }
);

export const mediaSchema = yup.object().shape({
    id: yup.string(),
    url: yup.string().url(),
    title: yup.string(),
});

const allowedDelimitersQR = ['|', ';', ',', ':', '_', '-', '#', ''];
const allowedDelimitersCode39 = ['-', '$', '%', '/', '+', ''];

export const barcodeSettingsSchema = yup.object().shape({
    barcodeConfig: yup.mixed().when('barcodeType', {
        is: 'Custom',
        then: () => yup.object().shape({
            encoding: yup.string().oneOf(['digits', 'UTF-8', 'ISO-8859-1', 'ISO-8859-13', 'ASCII'])
                .required()
                .when('barcodeImageType', {
                    is: 'Code39',
                    then: () => yup.string().oneOf(['digits', 'ASCII'], 'Für Code39 ist nur die Kodierung "digits" erlaubt.'),
                }),
                prefix: yup.string().nullable().when('encoding', {
                    is: 'digits',
                    then: () => yup.string().nullable().oneOf([null], 'Dieser Prefix kann bei diesem Barcode-Typ nicht genutzt werden.'),
                    otherwise: () => yup.string().notRequired().test(
                        'not_start_with_zero',
                        'Das Präfix darf nicht mit der Ziffer 0 beginnen.',
                        function(value) {
                          return !value || value[0] !== '0';
                        }
                    )
                }),
            hashType: yup.string().oneOf(['adler32', 'xxh64', 'ripemd128', 'sha1', 'sha256', 'none']).required(),
            barcodeParts: yup.array().of(
                yup.string()
                    .transform((value) => (typeof value === 'object' && value !== null ? value.id : value))
                    .oneOf([
                        'block_label',
                        'row_label',
                        'seat_label',
                        'event_title',
                        'event_ident',
                        'legal_firstname',
                        'legal_lastname',
                        'event_start_date',
                        'event_start_time',
                        'ticket_id',
                        'order_id'
                    ])
            ).default([]),
            barcodeImageType: yup.string().oneOf(['qr', 'Code39']).required(),
            maxLength: yup.number().integer().positive()
                .min(8, 'Die Mindestzeichenzahl beträgt aus Sicherheitsgründen 8.')
                .max(999, 'Die Maximale Zeichenzahl ist 999.').required(),
            delimiter: yup.string().when('barcodeImageType', {
                is: 'qr',
                then: () => yup.string().oneOf(allowedDelimitersQR).nullable(),
                otherwise: () => yup.string().oneOf(allowedDelimitersCode39).nullable()
            }),
        }),
        otherwise: () => yup.mixed().nullable()
    }),
    barcodeType: yup.string().oneOf(['QR100Utf8', 'QR_100_ISO8859_13', 'Barcode32', 'Custom']),
    hashSecret: yup.string().notRequired().test(
        'empty_or_min_length',
        'Wenn angegeben, muss das Hash-Geheimnis mindestens 16 Zeichen lang sein.',
        function(value) {
          return !value || value.length >= 16;
        }
    ).nullable()
});

export const VenueEvent = yup.object().shape({
    eventSeries: yup.string().nullable(),
    eventCategory: yup.string().nullable(),
    title: yup.object().shape({
        de: yup.string().required(),
        en: yup.string().required(),
    }),
    subtitle: I18nText,
    disableSalesRulesAfterStartDate: yup.bool().default(false),
    disableSalesRulesAfterEndDate: yup.bool().default(true),
    ident: yup.string().required(),
    team1Image: mediaSchema.nullable(),
    team2Image: mediaSchema.nullable(),
    walletLogo: mediaSchema.nullable(),
    walletHeader: mediaSchema.nullable(),
    startDate: yup.date().nullable().required().test(
        'start_before_end',
        'Der Startzeitpunkt muss vor dem Endzeitpunkt liegen',
        function (startDate) {
            return validateDateRange(startDate, this.parent.endDate);
        }
    ),
    endDate: yup.date().nullable().required()
        .test(
            'end_after_start',
            'Der Endzeitpunkt muss nach dem Startzeitpunkt liegen',
            function (endDate) {
                return validateDateRange(this.parent.startDate, endDate);
            }
        ).test(
            'end_without_start',
            'Ein Endzeitpunkt kann nicht ohne Startzeitpunkt angegeben werden',
            function (endDate) {
                return validateEndDateNotWithoutStartDate(this.parent.startDate, endDate);
            }
        ),
    doorsOpenAt: yup.date().nullable().test(
        'doorsopenat_before_start',
        'Der Einlasszeitpunkt muss vor dem Startzeitpunkt liegen',
        function (doorsOpenAt) {
            return validateDateRange(doorsOpenAt, this.parent.startDate);
        }
    ),
    ticketSelectionExpiration: yup.number().integer(),
    isDatePreliminary: yup.bool(),
    disableVisualSelection: yup.bool(),
    preliminaryDateText: I18nText.nullable(),
    salesInformation: I18nText.nullable(),
    description: I18nText.nullable(),
    tags: Tags.nullable(),
    salesRules: yup.array().of(SalesRuleAssociation),
    quotas: yup.array().of(Quota).nullable(),
    maxTicketsSalesRulesGroups: yup.array().of(MaxTicketsSalesRulesGroup).nullable(),
    bundles: yup.array().of(Bundle).nullable(),
    maxTicketsPerUser: yup.number().nullable().min(1).max(999).integer(),
    zukoZones: yup.array(),
    barcodeContext: barcodeSettingsSchema,
    pricingClasses: yup.array()
});
