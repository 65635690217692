import React from 'react';
import PropTypes from 'prop-types';

import {FormattedDate, FormattedNumber, FormattedTime} from 'react-intl';
import {isNumber} from 'lodash';
import Euro from "../../numberFormat/Euro";

function ListViewItemCell({field, value}) {

    function renderValue(value) {
        switch (field.type) {
            case 'currency':
                return <Euro centAmount={value}/>;
            case 'datetime':
                return value && (
                    <>
                        <FormattedDate value={value} day="2-digit" month="2-digit" year="numeric"/>
                        &nbsp;
                        <FormattedTime value={value} hour="2-digit" minute="2-digit"/>
                    </>
                );

            default:
                return (isNumber(value) || !!value) ? value : null;
        }
    }

    return (
        <td className="narrowItemCells" data-testid={field.testid || ''}>{renderValue(value)}</td>
    );
}

ListViewItemCell.propTypes = {
    type: PropTypes.string,
    value: PropTypes.any
};

export default ListViewItemCell;
