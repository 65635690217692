import React, { useState, useRef } from 'react';
import {Container, Col, Row} from 'react-bootstrap';
import ConnectedListView from '../common/listView/ConnectedListView';
import FeedbackButton from '../common/FeedbackButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import {connect} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {get} from 'lodash';
import * as targetGroup from "../../state/entities/targetGroup";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import {BATCH_ACTION_MOVE_TO_ARCHIVE} from "../common/listView/ListViewBatchActions";
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

const LinkToArchived = () => {
    return (
        <FeedbackButton
            to={`/base/target-group/archived`}
            icon={<RefreshIcon />}
            variant='contained'
            className='listLinkCenter'
        >
            Archiv
        </FeedbackButton>
    );
}

const Breadcrumb = () => {
    return (
        <Breadcrumbs entity={["Personenpool"]} endpoints = {['target_group/breadcrumb']} title = "Personenpools" links={['/base/target-group/']} active={[0]} />
    )
}

const List = ({
    deleteTargetGroup,
    linkNearHeader = LinkToArchived,
    breadcrumb = Breadcrumb,
    heading = 'Personenpools',
    listViewEndpoint = 'listingView/targetGroup',
    allowedBatchActions = [BATCH_ACTION_MOVE_TO_ARCHIVE]
}) => {

    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [targetGroupToDelete, setTargetGroupToDelete] = useState(undefined);

    const showDeleteConfirmingDialog = (targetGroup) => {
        if (targetGroup.isHardcoded) {
            return;
        }

        setShowDeleteConfirmDialog(true);
        setTargetGroupToDelete(targetGroup);
    };

    const handleCancelDelete = () => {
        setShowDeleteConfirmDialog(false);
    };

    const handleConfirmDelete = () => {
        setShowDeleteConfirmDialog(false);
        deleteTargetGroup(targetGroupToDelete);
    };

    return (
        <Container>
            <Row className="my-4">
                {breadcrumb()}
            </Row>

            <ConnectedListView
                endpoint={listViewEndpoint}
                linkNearHeading={linkNearHeader}
                heading={heading}
                buttonTitle="Neuer Personenpool"
                buttonLink="/base/target-group/create"
                isShowSelectionWithBatchActions={true}
                allowedActions={allowedBatchActions}
            >
                {({fields, item}) => (
                    <>
                        {!item.hardcoded &&
                            <span>
                                <FeedbackButton
                                    className="list-link"
                                    title="Personenpool bearbeiten"
                                    to={`/base/target-group/${item.id}`}
                                    icon={<EditNoteOutlinedIcon className='table-icon' />}
                                />
                                <FeedbackButton
                                    title="Personenpool löschen"
                                    className="list-link"
                                    onClick={() => showDeleteConfirmingDialog(item)}
                                >
                                    <DeleteOutlineOutlinedIcon className='table-icon' />
                                </FeedbackButton>
                            </span>
                        }
                    </>
                )}
            </ConnectedListView>

            <Modal show={showDeleteConfirmDialog} onHide={handleCancelDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>Personenpool löschen</Modal.Title>
                </Modal.Header>
                <Modal.Body>Möchten Sie den Personenpool "{get(targetGroupToDelete, 'name')}" wirklich löschen?</Modal.Body>
                <Modal.Footer>
                    <FeedbackButton variant="outlined" onClick={handleCancelDelete}>
                        Abbrechen
                    </FeedbackButton>
                    <FeedbackButton onClick={handleConfirmDelete}>
                        Löschen
                    </FeedbackButton>
                </Modal.Footer>
            </Modal>

        </Container>
    );
};


const mapDispatchToProps = dispatch => {
    return {
        deleteTargetGroup: entity => dispatch(targetGroup.actions.delete({entity}))
    }
};

export default connect(null, mapDispatchToProps)(List);
