import {InteractionMode, InteractionState} from './common';
import {AddSeatsIdleState} from './add';
import {SelectionIdleState} from './selection';
import { ImagesIdleState } from './images';
import { AreaFormsCreateState, AreaFormsIdleState } from './areaforms';

export type {DisplayContext, InteractionState} from './common';

export {InteractionMode};

export function getIdleState(interactionMode: InteractionMode): InteractionState {
    switch (interactionMode) {
        case 'ADD':
            return new AddSeatsIdleState();
        case 'SELECT':
            return new SelectionIdleState();
        case 'IMAGES':
            return new ImagesIdleState();
        case 'AREAFORMS':
            return new AreaFormsIdleState();
        case 'ADD_AREAFORM':
            return new AreaFormsCreateState();
        }
}

export type {MouseWheelZoomEvent} from './common';