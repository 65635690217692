import React, {useEffect, useState} from 'react';
import styles from './sidebar.module.scss';
import {withRouter} from "react-router-dom";

/**
 * Create a new sidebar instance.
 *
 * @param     {Object}      props
 * @property  {String|null} className CSS Selector classes.
 * @property  {JSX}         children
 * @property  {Boolean}     isOpen 	  The current state of the sidebar.
 * @return    {JSX}
 */
const Sidebar = ({className, children, ...props}) => {

    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        const pattern = /^\/sales-control\/pricing-list\/(create\/?|archived\/?)$/;

        if (pattern.test(props.location.pathname)) {
            setIsOpen(false);
            return;
        }

        const patternEdit = new RegExp("pricing-list/\\w+");
        patternEdit.test(props.location.pathname) ? setIsOpen(true) : setIsOpen(false);
    }, [props.location.pathname]);


  if (! isOpen) {
  	return null;
  }

  return <div id="sidebar" className={[className, styles.Sidebar].join(' ')}>
  </div>
};

export default withRouter(Sidebar);
