import React, {Component} from 'react';
import {Container, Row} from 'react-bootstrap';
import ConnectedListView from '../common/listView/ConnectedListView';
import {connect} from 'react-redux';
import * as order from "../../state/entities/order";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import FeedbackButton from "../common/FeedbackButton";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import { getTicketingBaseUrl } from '../../utils/utils';
import AddIcon from '@mui/icons-material/Add';
import Api from '../../api';

const api = new Api();

class List extends Component {
    constructor(props) {
        super(props);

        this.state = {
            config: null
        }
    }
    componentDidMount() {
        api.getConfig().then(config => {
            this.setState({ config });
        })
    }

    newOrderUrl = () => {
        return (
            <a href={`${getTicketingBaseUrl()}/${this.state.config?.tenantSlug}/events/backend`}>
                <FeedbackButton
                    icon={<AddIcon />}
                    variant='contained'
                    className='listLinkCenter'
                >
                    Neue Bestellung
                </FeedbackButton>
            </a>
        );
    }

    render() {
        return (
            <Container>
                <Row className="my-4">
                    <Breadcrumbs entity={["Order"]} endpoints={['order/breadcrumb']} title="Bestellungen"
                                 links={['/sales/order/']} active={[0]}/>
                </Row>
                <ConnectedListView
                    heading="Bestellungen"
                    endpoint="listingView/order"
                    linkNearHeading={this.newOrderUrl}
                >
                    {({fields, item}) => (
                        <>
                            <FeedbackButton
                                className="list-link"
                                title="Order ansehen"
                                to={`/sales/order/${item.id}`}
                                icon={<EditNoteOutlinedIcon className='table-icon'/>}
                            />
                        </>
                    )}
                </ConnectedListView>
            </Container>
        );
    }
}


const mapDispatchToProps = () => {
    return {
        deleteOrder: entity => order.actions.delete({ entity }),
    }
};

export default connect(null, mapDispatchToProps)(List);
