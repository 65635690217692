import { combineReducers, Reducer} from 'redux';
import {all, takeEvery} from 'redux-saga/effects';
import loadingSlice from '../entity/slice/load';
import savingSlice from "../entity/slice/save";
import deletingSlice from "../entity/slice/delete";
import { loadAllSeatsSaga, loadPlaceCategoriesSaga, loadPlacepoolDefinitionsSaga, loadSeatingTypesSaga,
    updateSomeSeatsSaga, loadBlocksSaga, deleteSeatsSaga, loadImagesSaga, deleteImagesSaga, updateImagesSaga,
    addSeatsSaga, loadVenuePlanSettingsSaga, updateVenuePlanSettingsSaga, loadVenuePlanSaga, addAreaFormSaga,
    deleteAreaFormSaga, updateAreaFormsSaga, addImageSaga} from './saga';
import { venuePlan, isApiRequestPending, seats, placepoolDefinitions, selectedSeatIds, undoHistory, interactionMode,
    moveOnGrid, placeCategories, seatingTypes, blocks, images, areaForms, fatalAPIError,
    venuePlanSettings } from './slice';
import Api from "../../../api";
import { IVenueEditorRoot } from './selectors';

export const entityName = 'venueEditor';

const api = new Api();

export const switchToSerialClient = () => {
    api.switchToSerialClient();
}

const loadSlice = loadingSlice(entityName);
const saveSlice = savingSlice(entityName);
const deleteSlice = deletingSlice(entityName);

export const actions = {
    ...venuePlan.actions,
    ...venuePlanSettings.actions,
    ...isApiRequestPending.actions,
    ...loadSlice.actions,
    ...deleteSlice.actions,
    ...saveSlice.actions,
    ...seats.actions,
    ...undoHistory.actions,
    ...placepoolDefinitions.actions,
    ...placeCategories.actions,
    ...seatingTypes.actions,
    ...selectedSeatIds.actions,
    ...interactionMode.actions,
    ...moveOnGrid.actions,
    ...blocks.actions,
    ...images.actions,
    ...areaForms.actions,
    ...fatalAPIError.actions
};

export const reducer: Reducer<IVenueEditorRoot> = 
    combineReducers({
        venuePlan: venuePlan.reducer,
        venuePlanSettings: venuePlanSettings.reducer,
        isApiRequestPending: isApiRequestPending.reducer,
        seats: seats.reducer,
        undoHistory: undoHistory.reducer,
        placepoolDefinitions: placepoolDefinitions.reducer,
        placeCategories: placeCategories.reducer,
        seatingTypes: seatingTypes.reducer,
        selectedSeatIds: selectedSeatIds.reducer,
        interactionMode: interactionMode.reducer,
        moveOnGrid: moveOnGrid.reducer,
        blocks: blocks.reducer,
        images: images.reducer,
        areaForms: areaForms.reducer,
        fatalAPIError: fatalAPIError.reducer
    });

export const saga = function* saga() {
    yield all([
        takeEvery(actions.loadVenuePlan, loadVenuePlanSaga({ actions, api })),
        takeEvery(actions.loadVenuePlanSettings, loadVenuePlanSettingsSaga({ actions, api })),
        takeEvery(actions.updateVenuePlanSettings, updateVenuePlanSettingsSaga({ actions, api })),
        takeEvery(actions.loadAllSeats, loadAllSeatsSaga({ actions, api })),
        takeEvery(actions.updateSomeSeats, updateSomeSeatsSaga({ actions, api })),
        takeEvery(actions.addSeats, addSeatsSaga({ actions, api })),
        takeEvery(actions.deleteSeats, deleteSeatsSaga({ actions, api })),
        takeEvery(actions.loadPlacepoolDefinitions, loadPlacepoolDefinitionsSaga({ actions, api })),
        takeEvery(actions.loadPlaceCategories, loadPlaceCategoriesSaga({ actions, api })),
        takeEvery(actions.loadSeatingTypes, loadSeatingTypesSaga({ actions, api })),
        takeEvery(actions.loadBlocks, loadBlocksSaga({ actions, api })),
        takeEvery(actions.loadImages, loadImagesSaga({ actions, api })),
        takeEvery(actions.addImage, addImageSaga({ actions, api })),
        takeEvery(actions.updateImages, updateImagesSaga({ actions, api })),
        takeEvery(actions.deleteImages, deleteImagesSaga({ actions, api })),
        takeEvery(actions.addAreaForm, addAreaFormSaga({ actions, api })),
        takeEvery(actions.updateAreaForms, updateAreaFormsSaga({ actions, api })),
        takeEvery(actions.deleteAreaForms, deleteAreaFormSaga({ actions, api })),
    ])
};
