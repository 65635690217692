import * as PropTypes from "prop-types";
import React from "react";
import {Button, Col, Collapse, Row} from "react-bootstrap";
import { useDrag } from 'react-dnd';
import DragAndDropItemTypes from "./DragAndDropItemTypes";
import { lighten } from "@wessberg/color";
import { dragEnd } from "../../../state/entities/pricingMatrix/dragAndDrop/actions";
import { connect } from "react-redux";
import PricingComponentDisplay from "./PricingComponentDisplay";
import ItemTemplateOverrideForm from "./ItemTemplateOverrideForm";
import BookingInformationIncompleteWarning from "./BookingInformationIncompleteWarning";
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import FeedbackButton from '../../common/FeedbackButton';

export const PurchasableItemDragPrototype = ({
    localItemTemplate,
    onDragEnd,
    pricingComponentsVisible,
    onOverrideChange,
    toggleEditLocalItemTemplate
}) => {
    const purchasableItemTemplate = localItemTemplate.purchasableItemTemplate;
    const [, dragRef] = useDrag({
        type: DragAndDropItemTypes.PURCHASABLE_ITEM_TEMPLATE,
        item: {
            id: localItemTemplate.id,
            backgroundColor: lighten(purchasableItemTemplate.color, 20)
        },
        end: onDragEnd
    });

    return <div className="purchasable-item-drag-prototype" ref={dragRef}>
        <Row>
            <Col sm={10}>
                <Row>
                    <Col sm={1}><i className="fa fa-lg fa-arrows" aria-hidden="true"/></Col>
                    <Col sm={7} className="prototype-label">
                        {purchasableItemTemplate.name}
                        <FeedbackButton
                            className="list-link"
                            to={`/event-management/purchasable-item-template/${purchasableItemTemplate.id}`}
                            icon={<EditNoteOutlinedIcon className='table-icon' />}
                        />
                    </Col>
                    <Col sm={1}>
                        <Button variant="link" className="p-0" onClick={() => toggleEditLocalItemTemplate()}>
                            <BookingInformationIncompleteWarning localItemTemplate={localItemTemplate} />
                        </Button>
                    </Col>
                    <Col sm={2} className="text-right">
                        <Button variant="link" className="p-0" onClick={() => toggleEditLocalItemTemplate()}>
                            {localItemTemplate.$editExpanded
                                ? <><i className="fa fa-lg fa-angle-up"/></>
                                : <><i className="fa fa-lg fa-angle-down"/></>
                            }
                        </Button>
                    </Col>
                </Row>

                {pricingComponentsVisible &&
                <Col sm={11} className="mt-2">
                    <PricingComponentDisplay itemTemplate={purchasableItemTemplate}/>
                </Col>
                }

                <Collapse in={!!localItemTemplate.$editExpanded}>
                    <Col sm={12}>
                        <ItemTemplateOverrideForm localItemTemplate={localItemTemplate} onChange={onOverrideChange}/>
                    </Col>
                </Collapse>
            </Col>
            <Col sm={2} style={{backgroundColor: purchasableItemTemplate.color}}></Col>
        </Row>
    </div>;
};

PurchasableItemDragPrototype.propTypes = {itemTemplate: PropTypes.any};

const mapDispatchToProps = dispatch => {
    return {onDragEnd: () => dispatch(dragEnd())};
};

export default connect(null, mapDispatchToProps)(PurchasableItemDragPrototype);


