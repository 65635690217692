import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormikTextInputGroup } from "../common/formik/FormikTextInputGroup";
import FeedbackButton from "../common/FeedbackButton";
import FormikCheckbox from "../common/formik/FormikCheckbox";
import FormikSelect from '../common/formik/FormikSelect';
import { VoucherEvents } from "./schema";
import { getIn } from "formik";
import ConfirmModal from "../common/modal/ConfirmModal";
import FormModal from "../common/modal/FormModal";
import FilteringMultiSelectList from "../common/form/FilteringMultiSelectList/FilteringMultiSelectList";
import Api from "../../api";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import AddIcon from '@mui/icons-material/Add';
import styles from "../form.module.scss";

const api = new Api();

export const createEventListElements = (formEvents) => {
    return formEvents.map((event, index) => {
        return ({
            ...event,
            index: index
        });
    });
}


const FormVoucherSettings = ({ formik }) => {
    const [statusMessage, setStatusMessage] = useState([]);
    const [eventToEdit, setEventToEdit] = useState(null);
    const [eventToAdd, setEventToAdd] = useState(null);

    const makeSmtpStatusCheckRequest = () => {
        let data = {...formik.values.voucherSettings};

        let resp = api.checkTenantVoucherSettings(data);
        resp.then((result) => {
            if (result.errors) {
                setStatusMessage(result.errors);
            } else {
                setStatusMessage([]);
            }
        });
    };

    const eventFields = [
        {name: 'name', label: 'Name'},
        {name: 'id', label: 'Externe Event-IDs'},
    ];

    const getErrorMessages = () => {
        return (<span className="email-validation invalid">
            {statusMessage.join(", ")}
        </span>)
    };

    const addEvent = (addedEvent) => {
        const newEvents = [
            ...formik.values.voucherSettings.events,
            {
                ...addedEvent
            },
        ];
    
        formik.setFieldValue('voucherSettings.events', newEvents);
        setEventToAdd(null);
    };

    const updateEvent = (updatedEvent) => {
        let events = getIn(formik.values, 'voucherSettings.events', []);
        const index = updatedEvent?.index;

        if (events[index]) {
            events[index] = { id: updatedEvent.id, name: updatedEvent.name };
            formik.setFieldValue('voucherSettings.events', events);
        }

        setEventToEdit(null);
    };

    const EditEventButton = ({event}) => (
        <FeedbackButton title="Externe Event-ID bearbeiten" className="list-link" data-testid="edit_event" onClick={() => setEventToEdit(event)}>
            <EditNoteOutlinedIcon className='table-icon' />
        </FeedbackButton>
    )

    const onChange = (selection) => {
        formik.setFieldValue('voucherSettings.events', selection)
    };


    const RemoveEventAction = ({ id }) => {
        const event = formik.values.voucherSettings.events.find(item => {
            return item.id === id;
        });

        const removeEvent = (id) => () => {
            const values = formik.values.voucherSettings.events.filter(event => event.id !== id);
            formik.setFieldValue('voucherSettings.events', values)
        };

        return (
            <ConfirmModal title="Externe Event-ID entfernen"
                          body={`Möchten Sie die externe Event-ID "${event.name}" wirklich entfernen?`}
                          cancelLabel="Abbrechen"
                          confirmLabel="Löschen"
            >
                {confirm => (
                    <FeedbackButton title="Externe Event-ID löschen" className="list-link" onClick={() => confirm(removeEvent(id))}>
                        <DeleteOutlineOutlinedIcon className='table-icon' />
                    </FeedbackButton>
                )}
            </ConfirmModal>
        )
    }

    return (
        <>
            <div className={styles.formBox}>
                <Row className="mt-12">
                    <Col className="col-md-4">
                        <h2 className={styles.formTitle}>Externe Gutscheine</h2>
                        <h3>Hier können die Daten zur Anbindung externer Gutscheine hinterlegt werden</h3>
                    </Col>
                    <Col className="col-md-4">
                        <FeedbackButton
                            onClick={makeSmtpStatusCheckRequest}
                        >
                            Daten prüfen
                        </FeedbackButton>

                        {!statusMessage.length && <span className="email-validation valid">Valid</span>}
                        {!!statusMessage.length && getErrorMessages() }
                    </Col>
                </Row>
                <Row>
                    <Col className="col-md-4">
                        <FormikCheckbox label="Externe Gutschein-API aktiv" name="voucherSettings.enabled"/>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-md-4">
                        <FormikTextInputGroup label="Server der Gutschein-API" name="voucherSettings.apiBaseUri" />
                    </Col>
                    <Col className="col-md-4">
                        <FormikTextInputGroup label="API Public Key" name="voucherSettings.apiPublicKey" />
                    </Col>
                    <Col className="col-md-4">
                        <FormikTextInputGroup label="API Private Key" name="voucherSettings.apiPrivateKey" />
                    </Col>
                    <Col className="col-md-4">
                        <FormikTextInputGroup label="Externe Mandanten-ID" name="voucherSettings.externalTenantId" />
                    </Col>
                    <Col className="col-md-4">
                        <FormikSelect
                            label="Externe Event-IDs aktivieren"
                            options={formik.values.voucherSettings.events}
                            name="voucherSettings.externalEventId"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-4'>
                        <FeedbackButton
                            icon={<AddIcon />}
                            className="mb-3"
                            onClick={() => setEventToAdd({})}
                        >
                            Neue externe Event-ID
                        </FeedbackButton>
                        <FilteringMultiSelectList
                            elements={createEventListElements(formik.values.voucherSettings.events)}
                            fields={eventFields}
                            formik={formik}
                            onChange={onChange}
                        >
                            {({ element }) => {
                                return (
                                    <div key={element.id}>
                                        <EditEventButton event={element}/>
                                        <RemoveEventAction id={element.id}/>
                                    </div>
                                )
                            }}
                        </FilteringMultiSelectList>
                    </Col>
                </Row>
            </div>
            <FormModal
                show={!!eventToAdd}
                title="Externe Event-IDs hinzufügen"
                initialValues={{...VoucherEvents.default(), ...eventToAdd }}
                validationSchema={VoucherEvents}
                onConfirm={addEvent}
                onCancel={() => setEventToAdd(null)}
            >
                {() => {
                    return (
                        <>                      
                            <FormikTextInputGroup label="Externer Event-Name" name="name" />
                            <FormikTextInputGroup label="Externe Event-ID" name="id" />
                        </>
                    )
                }}
            </FormModal>
            <FormModal
                show={!!eventToEdit}
                title="Extendable list of IDs bearbeiten"
                initialValues={{...VoucherEvents.default(), ...eventToEdit }}
                validationSchema={VoucherEvents}
                onConfirm={updateEvent}
                onCancel={() => setEventToEdit(null)}
                submitTestId="submit_salesRule"
            >
                {() => {
                    return (
                        <>                      
                            <FormikTextInputGroup label="name" name="name" />
                            <FormikTextInputGroup label="externalEventId" name="id" />
                        </>
                    )
                }}
            </FormModal>
        </>
    )
}

export default FormVoucherSettings;
