import { UserManager } from 'oidc-client';
import { get, includes } from 'lodash';
import decode from 'jwt-decode';

import ObjectId from 'bson-objectid';

import log from 'loglevel';

/*
Step-by-step breakdown of what the code appears to do:
1. Setup Configuration: Configuration settings are initialized for Single Sign-On (SSO) using the oidc-client.
2. Events Handling: Events are set up to manage token expiration, renewal, user loading, and silent renewals.
3. User Authentication: Methods are provided to manage user authentication:
     - ensureAuthentication(): Checks for authentication. If not authenticated, it attempts to retrieve a user from storage or redirects to the login page.
     - redirectToLogin(): Redirects the user to the login page.
     - logout(): Logs the user out by initiating a signout redirect.
4. User Management: Methods to manage user details and tokens:
     - getUserFullname(): Retrieves the user's full name if authenticated.
     - getUser(): Retrieves the current user details.
     - setUser(): Sets the current user and decodes the access token.
     - setUserFromStorage(): Retrieves user details from storage.
     - getAccessToken(): Retrieves the access token for the user.

5. Authorization: Provides a method to check if the user has a specific role.
6. Silent Sign-In: Initiates silent sign-in attempts for users.

How-to guide for our SSO implementation
1. Initialization: Import the necessary modules and create an instance of the authService.
2. Authentication:
    - Use ensureAuthentication() to check if the user is authenticated.
    - If not authenticated, the user will be redirected to the login page.
3. Access User Information:
    - Access the user's full name using getUserFullname().
4. Authorization:
    - Verify user roles using hasRole().
5. Token Handling:
    - Retrieve the access token using getAccessToken().
6. Logging Out:
    - Call logout() to sign the user out..
*/


// number of seconds before the access token expires to try and renew it automatically
const TOKEN_EXPIRATION_TIME_SAFETY_MARGIN = 60;
const USER_MANAGER_CONFIG = {
    authority: window.config?.REACT_APP_IAM_AUTHORITY,
    client_id: window.config?.REACT_APP_IAM_CLIENT_ID,
    scope: window.config?.REACT_APP_IAM_SCOPE,
    response_type: window.config?.REACT_APP_IAM_RESPONSE_TYPE,

    post_logout_redirect_uri: window.location.href.replace(/\/+$/, ''), // (string): The OIDC post-logout redirect URI.
    redirect_uri: `${window.location.protocol}//${window.location.host}${process.env.PUBLIC_URL}`, //The URI of your client application to receive a response from the OIDC provider.
    silent_redirect_uri: `${window.location.protocol}//${window.location.host}${process.env.PUBLIC_URL}/silent-renew`, //(string): The URL for the page containing the code handling the silent renew.
    accessTokenExpiringNotificationTime: TOKEN_EXPIRATION_TIME_SAFETY_MARGIN,
    automaticSilentRenew: true
};

class authService {
    id;
    user;
    accessTokenData;

    constructor() {

        this.id = ObjectId.generate();

        this.userManager = new UserManager(USER_MANAGER_CONFIG);

        this.userManager.events.addAccessTokenExpiring(() => {
            log.info(`access token will expire in <= ${TOKEN_EXPIRATION_TIME_SAFETY_MARGIN}s`);
        });

        this.userManager.events.addAccessTokenExpired(() => {
            log.warn('access token has expired');
        });

        this.userManager.events.addUserLoaded(user => {
            log.info('user loaded');
            this.setUser(user);

            if (user.state && user.state.pathname) {
                const url = [user.state.pathname, user.state.search, user.state.hash].join('');

                console.log(url);
                window.location.href = url;
            }
        });

        this.userManager.events.addSilentRenewError(e => {
            log.error('access token could not be renewed', e.message);
        });
    }

    getUserFullname = () => {
        if (this.isAuthenticated() && this.user !== undefined) {
            return `${get(this.getUser(), 'profile.first_name')} ${get(this.getUser(), 'profile.last_name')}`;
        }

        return '';
    };

    /**
     * Check for authentication by sign-in callback or by user in storage, otherwise redirect to login.
     *
     * @returns {Promise<boolean>} resolves to true if user is authenticated, false otherwise.
     */
    async ensureAuthentication() {
        try {
            // Evtl. kommen wir durch einen Login-Redirect auf diese Seite, daher zunächst probieren einen
            // evtl. vorliegenden Authorization Code aus der URL zu fischen, um den Login abzuschließen.
            log.info('checking for sign-in callback state');
            await this.userManager.signinRedirectCallback();

            if (typeof URLSearchParams !== 'undefined') {
                let url = new URL(window.location.href);
                let params = new URLSearchParams(url.search.slice(1));

                params.delete('state')
                params.delete('session_state')
                params.delete('code')

                var newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + params.toString();
                window.history.pushState({ path: newUrl }, '', newUrl);
            }
        } catch (error) {
            // Ist kein Authorization Code in der URL enthalten, dann hat der Benutzer diese Seite ganz
            // normal aufgerufen. In diesem Fall einen evtl. bereits bestehenden Login abrufen.
            log.info('no sign-in callback state available; loading user from storage');

            const user = await this.userManager.getUser();

            if (user && !user.expired) {
                this.setUser(user);
            } else {
                log.info('no user available in storage; redirecting to login');
                this.redirectToLogin();
            }
        }

        return this.isAuthenticated();
    }

    redirectToLogin = () => {
        const state = {
            pathname: window.location.pathname,
            search: window.location.search,
            hash: window.location.hash,
        };

        // The EF platform SSO provider does not support wildcard redirect URIs
        // therefore we need to send the current path as contextual state information
        // and restore this after returning to the fixed redirect_uri.
        this.userManager.signinRedirect({ state });
    };

    isAuthenticated = () => {
        return !!this.getUser();
    };

    getUser = () => {
        return this.user;
    };

    setUser = user => {
        this.user = user;
        this.accessTokenData = decode(user.access_token);
    };

    async setUserFromStorage() {
        const user = await this.userManager.getUser();

        console.log('USER!', user);

        this.setUser();
    }

    /**
     * Retrieves the access token for the user.
     *
     * @return {string} The access token for the user.
     */
    getAccessToken() {
        return this.getUser().access_token;
    }

    logout = () => {
        this.userManager.signoutRedirect();
        this.userManager.clearStaleState();
    };

    hasRole = (role) => {
        role = role.toLocaleLowerCase();
        return includes(this.accessTokenData.roles, role);
    };

    signInSilent = () => {
        this.userManager.signinSilent().catch((err) => {
            console.log("signinSilent ", err);
        });
    }
}

export default new authService();
