import React, { useState } from 'react';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    OutlinedInput,
    TextareaAutosize,
    InputAdornment,
    IconButton
} from '@mui/material';
import { isEmpty } from 'lodash';
import HelpContent from '../help/HelpContent';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityIconOff from '@mui/icons-material/VisibilityOff';

interface InputTextFieldProps {
    label: string;
    value: string | number;
    testid?: string;
    className?: string;
    placeholder?: string;
    min?: number;
    type?: string;
    error?: undefined | string;
    inputRef?: React.Ref<HTMLTextAreaElement | HTMLInputElement>;
    onKeyDown?: (event: React.KeyboardEvent) => void;
    onChange: (e: React.ChangeEvent<any>) => void
    onBlur?: () => void;
    onHandleIconClick: () => void;
    helpText?: string;
    minRows?: number;
    maxRows?: number;
    disabled?: boolean;
    isInvalid?: boolean;
    readOnly?: boolean;
}

export const InputTextField: React.FC<InputTextFieldProps> = ({
    label,
    value,
    testid,
    placeholder = '',
    type = 'text',
    inputRef,
    onKeyDown,
    helpText = '',
    minRows = 1,
    maxRows = 6,
    className = '',
    disabled = false,
    readOnly = false,
    isInvalid = false,
    error = undefined,
    onHandleIconClick = () => {},
    onChange,
    onBlur
}) => {

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword((show) => !show)
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    let resolvedType = type;
    if (resolvedType === 'password' && showPassword) {
        resolvedType = 'text';
    }

    return (
        <FormControl
            fullWidth
            variant="outlined"
            error={isInvalid}
            disabled={disabled}
            className={className}
        >
            {label && <InputLabel>{label}</InputLabel>}
            {helpText && <HelpContent>{helpText.split('\n').map((item, i) => <p className="text-muted" key={i}>{item}</p>)}</HelpContent>}
            {type === 'textarea' ? (
                <div style={{ position: 'relative', minHeight: minRows === 1 && '56px' }}>
                    <TextareaAutosize
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholder={placeholder}
                        value={value}
                        onKeyDown={onKeyDown}
                        data-testid={testid}
                        minRows={minRows}
                        maxRows={maxRows}
                        className={isInvalid ? 'Mui-error' : ''}
                        style={{ minHeight: minRows === 1 && '56px', height: 'auto' }}
                    />
                    {value && (
                        <InputAdornment position="end">
                            <IconButton onClick={onHandleIconClick} edge="end" tabIndex={-1}>
                                <CancelIcon sx={{ color: '#494949' }} />
                            </IconButton>
                        </InputAdornment>
                    )}
                </div>
            ) : (
                <OutlinedInput
                    onChange={onChange}
                    onBlur={onBlur}
                    type={resolvedType}
                    placeholder={placeholder}
                    value={value}
                    onKeyDown={onKeyDown}
                    inputRef={inputRef}
                    data-testid={testid}
                    inputProps={{ readOnly: readOnly }}
                    endAdornment={
                        <InputAdornment position="end">
                            {(value && type !== 'password' && !readOnly) ? (
                                <IconButton onClick={onHandleIconClick} edge="end" tabIndex={-1}>
                                    <CancelIcon sx={{ color: '#494949' }} />
                                </IconButton>
                            ) : ''}
                            {!value && !!error && (
                                <IconButton edge="end" tabIndex={-1}>
                                    <ErrorIcon sx={{ color: '#B3261E' }} />
                                </IconButton>
                            )}
                            {(type === 'password' && value) && (
                                <IconButton
                                    edge="end"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? <VisibilityIconOff sx={{ color: '#494949' }} /> : <VisibilityIcon sx={{ color: '#494949' }} />}
                                </IconButton>
                            )}
                        </InputAdornment>
                    }
                />
            )}
            {isInvalid && <FormHelperText data-testid="error">{error}</FormHelperText>}
        </FormControl>
    );
};

export default InputTextField;