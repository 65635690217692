import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { get, sortBy, values, find, isEmpty, intersectionWith } from 'lodash';

import { actions as eventSeriesActions } from '../../state/entities/eventSeries';
import { actions as venueActions } from '../../state/entities/venue';
import { actions as eventCategoriesActions } from '../../state/entities/eventCategory';
import { actions as venuePlanActions } from '../../state/entities/venuePlan';
import { actions as ticketLayoutActions } from '../../state/entities/ticketLayout';
import { actions as loadSalesRuleActions } from '../../state/entities/salesRule';
import { actions as pricingClassActions } from '../../state/entities/pricingClass';
import { useLocation } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import TabsPanel from '../common/TabsPanel/TabsPanel';
import FormPricingClassesPriorities from '../venueEvent/FormPricingClassesPriorities';
import FormPersonalization from '../venueEvent/FormPersonalization';
import ErrorFocus from '../common/formik/ErrorFocus';
import FeedbackButton from '../common/FeedbackButton';
import Footer from '../layout/Footer';
import FormData from './FormData';
import * as PropTypes from 'prop-types';
import { MetaEvent } from './schema';
import { LoadingIndicator } from '../common/LoadingIndicator';
import Api from '../../api';
import FormDeliveryGroupsPriorities from '../venueEvent/FormDeliveryGroupsPriorities';
import FormSalesRules, { getDeliveryGroupsWithDescriptions } from "../venueEvent/FormSalesRules";
import FormQuotas from "../venueEvent/FormQuotas";
import FormBarcode from '../venueEvent/FormBarcode';
import styles from "../form.module.scss";

const api = new Api();

const INITIAL_VALUES = {
    id: '',
    ident: '',
    eventSeries: null,
    team1ImageUrl: '',
    team2ImageUrl: '',
    walletLogoUrl: '',
    walletHeaderUrl: '',
    title: {
        de: '',
        en: '',
    },
    subtitle: {
        de: '',
        en: '',
    },
    ticketSelectionExpiration: 15,
    salesInformation: {
        de: '',
        en: '',
    },
    description: {
        de: '',
        en: '',
    },
    tags: {
        de: [],
        en: [],
    },
    queueItEventId: '',
    coronaRulesUrl: '',
    salesRules: [],
    quotas: [],
    maxTicketsSalesRulesGroups: [],
    salesRule: null,
    maxTicketsPerUser: '',
    venuePlan: null,
    pricingClassesPriorities: [],
    disableSalesRulesAfterStartDate: false,
    disableSalesRulesAfterEndDate: true,
    shippingCodesPriorities: [],
    venueEvents: [],
    salesChannelPersonalizations: [],
    productId: '',
    artists: [],
    googleTrackingEnabled: false,
    eventCategory: null,
    barcodeContext: {
        barcodeConfig: null,
        barcodeType: 'QR100Utf8',
        hashSecret: null
    },
};

const Form = ({
    onSubmit,
    submitPending,
    metaEvent,
    eventSeries,
    loadEventSeries,
    pricingClasses,
    loadPricingClasses,
    loadVenues,
    loadEventCategories,
    salesRules,
    loadSalesRules,
    venue,
    eventCategory,
    ticketLayouts,
    loadVenuePlansByVenueId,
    loadTicketLayouts
}) => {
    const location = useLocation();
    const [currentTab, setCurrentTab] = useState(location?.location?.tab || 'home');
    const [allDeliveryGroups, setAllDeliveryGroups] = useState([]);
    const [databaseQuotas, setDatabaseQuotas] = useState({});
    const [newUpdateVersion, setNewUpdateVersion] = useState(0);
    const [metaEventStatus, setMetaEventStatus] = useState([]);
    const [updateBarcodeParts, setUpdateBarcodeParts] = useState([]);
    const [previewBarcode, setPreviewBarcode] = useState([]);

    const [barcodeLabels, setBarcodeLabels] = useState({
        encodings: [],
        types: [],
        imageTypes: [],
        hashTypes: [],
        partTypes: [],
        allowedDelimiters: {
            Code39: [],
            qr: []
        }
    });

    const loadQuotasByEvent = (eventId) => {
        api.getQuotasData(eventId).then(result => {
            setDatabaseQuotas(result);
        });
    };

    useEffect(() => {
        loadEventSeries();
        loadPricingClasses();
        loadVenues();
        loadSalesRules();
        loadEventCategories();
        if (metaEvent.venue) {
            loadVenuePlansByVenueId(metaEvent.venue)
        }
        api.getMetaEventStatus(metaEvent.id).then(result => {
            setMetaEventStatus(result.venueEvents);
        })
        loadTicketLayouts();
        api.getDeliveryGroupsForMetaEvent().then(result => {
            const deliveryGroupsWithDescription = getDeliveryGroupsWithDescriptions(result)
            setAllDeliveryGroups(deliveryGroupsWithDescription);
        });
        api.getBarcodeLabels().then(data => {
            setBarcodeLabels(data);

            if (!isEmpty(metaEvent)) {
                const updateBarcodeParts = !isEmpty(metaEvent.barcodeContext.barcodeConfig) &&
                intersectionWith(data.partTypes, metaEvent.barcodeContext.barcodeConfig.barcodeParts, (obj1, obj2) => obj1.id === obj2.type);
                setUpdateBarcodeParts(updateBarcodeParts);

                api.getBarcodePreview(metaEvent.id, metaEvent.barcodeContext).then(data => {
                    setPreviewBarcode(data);
                });
            }
        });

        // Wir wollen diesen Effekt explizit nur einmal "on-mount" durchführen, daher geben wir [] als dependency an.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setNewUpdateVersion(metaEvent.updateVersion);
    }, [metaEvent.updateVersion]);

    const ident = useRef(null);
    const title = useRef(null);
    const team1ImageUrl = useRef(null);
    const team2ImageUrl = useRef(null);
    const queueItEventId = useRef(null);
    const ticketLayout = useRef(null);
    const barcodeContext = useRef(null);

    const refsData = {
        ident,
        title,
        team1ImageUrl,
        team2ImageUrl,
        queueItEventId,
        barcodeContext
    }

    const refsSalesRules = {
        ticketLayout
    }

    const handleSubmit = (metaEvent, formik) => {
        let event = {
            ...metaEvent,
            // 15 minutes is default value
            ticketSelectionExpiration: metaEvent.ticketSelectionExpiration === '' ? 900 : metaEvent.ticketSelectionExpiration * 60,
            updateVersion: newUpdateVersion,
            artists: metaEvent.artists.map(artist => artist.id),
            barcodeContext: {
                ...metaEvent.barcodeContext.barcodeType !== 'Custom' ? {
                    barcodeConfig: null
                } : {
                    barcodeConfig: {
                        ...metaEvent.barcodeContext.barcodeConfig,
                        barcodeParts: metaEvent.barcodeContext.barcodeConfig.barcodeParts.map(item => ({ type: item.id })),
                    },
                },
                barcodeType: metaEvent.barcodeContext.barcodeType,
                hashSecret: metaEvent.barcodeContext.hashSecret === '' ? null : metaEvent.barcodeContext.hashSecret
            }
        };

        let rules = metaEvent.salesRules.map(rule => {
            const getSalesRuleId = rule.salesRule.id;

            // change this date when it sets in datepicker to +0 hours for send to api
            if (rule.activeMemberSinceAt && rule.activeMemberSinceAt instanceof Date) {
                const date = rule.activeMemberSinceAt;
                let newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
                rule = {...rule, salesRule: getSalesRuleId,  activeMemberSinceAt: newDate}
            }
            if (rule.useFor === '') {
                rule = {...rule, salesRule: getSalesRuleId, useFor: null};
            }
            if (rule.maxTicketsPerUser === '') {
                return {...rule, salesRule: getSalesRuleId, maxTicketsPerUser: null};
            } else {
                return {...rule, salesRule: getSalesRuleId };
            }
        });

        api.getBarcodeCheckForProblems(event.barcodeContext).then(() => {
            return;
        }).then(() => {
            if (!isEmpty(metaEvent.id)) {
                api.getBarcodePreview(metaEvent.id, event.barcodeContext).then(data => {
                    setPreviewBarcode(data);
                });
            }          
            
            onSubmit({...event, salesRules: rules});
        }).catch(err => {
            const getErrors = get(err, 'errors', []);
            [getErrors].map((error, i) => {
                const errorKey = Object.keys(error)?.[i];
                const errorValue = Object.values(error)?.[i][0].message;
                if (errorKey)
                    formik.setFieldError(`barcodeContext.${errorKey}`, errorValue);
            });
        });
    };

    ticketLayouts.sort((a, b) => (a.name > b.name) ? 1 : -1);
    if (pricingClasses.length === 0) {
        return(
            <>
                <p>Keine Preisklassen gefunden</p>
                <LoadingIndicator/>
            </>
        )
    }
    if (allDeliveryGroups.length === 0) {
        return(
            <>
                <p>Keine Versandarten Vorauswahl gefunden</p>
                <LoadingIndicator/>
            </>
        )
    }

    const updatedSalesRules = !isEmpty(metaEvent) && metaEvent.salesRules.map(item => {
        const matchingRule = find(salesRules, { id: item.salesRule });
        return matchingRule ? { ...item, salesRule: matchingRule } : item;
    });

    const modifiedMetaEvent = {
        ...metaEvent,
        artists: [],
        salesRules: updatedSalesRules || [],
        ...!isEmpty(metaEvent) && {
            barcodeContext: {
                ...metaEvent.barcodeContext,
                ...!isEmpty(metaEvent.barcodeContext.barcodeConfig) ? {       
                    barcodeConfig: {
                        ...metaEvent.barcodeContext.barcodeConfig,
                        barcodeParts: updateBarcodeParts || []
                    }
                } : {
                    barcodeConfig: null
                }
            }
        }
    }

    return <Formik initialValues={{ ...INITIAL_VALUES, ...modifiedMetaEvent }}
                   validationSchema={MetaEvent}
                   onSubmit={(event, formik) => handleSubmit(event, formik)}
    >
        {formik => {
            return (
                <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                    <Tabs
                        id="uncontrolled-tab-example"
                        value={currentTab}
                        onChange={(event, newTab) => setCurrentTab(newTab)}
                    >
                        <Tab value="home" label="Stammdaten" />
                        <Tab value="salesRules" label="Verkaufsregeln" />
                        <Tab value="quotas" label="Kontingente" />
                        <Tab value="pricingClassPriorities" label="Preisklassenvorauswahl" />
                        <Tab value="personalization" label="Personalisierung" />
                        <Tab value="deliveryGroupsPriorities" label="Versandarten Vorauswahl" />
                        {!!metaEvent.id && <Tab value="placeStatus" label="Tickets aktualisieren" />}
                        <Tab value="barcode" label="Barcode Einstellungen" />
                    </Tabs>
                    <div className={styles.tabsContentWrapper}>
                        <TabsPanel value={currentTab} index={'home'} data-event-key='home'>
                            <FormData
                                metaEvent={metaEvent}
                                formik={formik}
                                onSubmit={onSubmit}
                                formSchema={MetaEvent}
                                metaEventStatus={metaEventStatus}
                                venue={venue}
                                eventCategory={eventCategory}
                                initialValues={INITIAL_VALUES}
                                eventSeries={eventSeries}
                                submitPending={submitPending}
                                refs={refsData}
                            />
                        </TabsPanel>
                        <TabsPanel value={currentTab} index={'salesRules'} data-event-key='salesRules'>
                            <FormSalesRules
                                ticketLayouts={ticketLayouts}
                                event={metaEvent}
                                onSubmit={onSubmit}
                                submitPending={submitPending}
                                initialValues={INITIAL_VALUES}
                                formSchema={MetaEvent}
                                formik={formik}
                                refs={refsSalesRules}
                            />
                        </TabsPanel>
                        <TabsPanel value={currentTab} index={'quotas'} data-event-key='quotas'>
                            <FormQuotas
                                event={metaEvent}
                                databaseQuotas={databaseQuotas}
                                loadQuotasByEvent={loadQuotasByEvent}
                                formik={formik}
                                refs={refsData}
                            />
                        </TabsPanel>
                        <TabsPanel value={currentTab} index={'pricingClassPriorities'} data-event-key='pricingClassPriorities'>
                            <FormPricingClassesPriorities
                                pricingClasses={pricingClasses}
                                pricingClassesPriorities={metaEvent.pricingClassesPriorities || []}
                                formik={formik}
                            />
                        </TabsPanel>
                        <TabsPanel value={currentTab} index={'personalization'} data-event-key='personalization'>
                            <FormPersonalization
                                salesChannels={formik.values.salesChannelPersonalizations}
                                formik={formik}
                            />
                        </TabsPanel>
                        <TabsPanel value={currentTab} index={'deliveryGroupsPriorities'} data-event-key='deliveryGroupsPriorities'>
                            <FormDeliveryGroupsPriorities
                                deliveryGroups={allDeliveryGroups}
                                deliveryGroupsPriorities={metaEvent.shippingCodesPriorities || []}
                                onSubmit={onSubmit}
                                submitPending={submitPending} initialValues={INITIAL_VALUES}
                                formSchema={MetaEvent}
                                formik={formik}
                                refs={refsData}
                            />
                        </TabsPanel>

                        <TabsPanel value={currentTab} index={'barcode'} data-event-key='barcode'>
                            <FormBarcode
                                barcodeLabels={barcodeLabels}
                                previewBarcode={previewBarcode}
                                formik={formik}
                                ticketLayouts={ticketLayouts}
                                refs={refsData}
                            />
                        </TabsPanel>
                    </div>
                    <ErrorFocus setCurrentTab={setCurrentTab} refs={{...refsData, ...refsSalesRules}}/>
                    <Footer>
                        {metaEvent.id && metaEvent.deepLink && <div className="footer-center-button">
                            <a href={metaEvent.deepLink} target="_blank" rel="noreferrer">
                                <FeedbackButton variant="outlined">
                                    Tickets für diese Veranstaltung kaufen
                                </FeedbackButton>
                            </a></div>}
                        <FeedbackButton to={`/event-management/meta-event`}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton
                            type="submit"
                            busy={submitPending || (formik.isSubmitting && formik.isValidating)}
                        >
                            Speichern
                        </FeedbackButton>
                    </Footer>
                </form>
            )
        }}
    </Formik>
};

Form.propTypes = {
    onSubmit: PropTypes.func,
    submitPending: PropTypes.bool,
    metaEvent: PropTypes.object,
    loadVenues: PropTypes.func,
    loadEventCategories: PropTypes.func,
    eventSeries: PropTypes.array,
    loadTicketLayouts: PropTypes.func,
    loadVenuePlansByVenueId: PropTypes.func,
    loadPricingClasses: PropTypes.func,
    loadSalesRule: PropTypes.func,
    loadArtists: PropTypes.func,
    venue: PropTypes.array,
    eventCategory: PropTypes.object,
    ticketLayouts: PropTypes.array,
    pricingClasses: PropTypes.array,
    venuePlan: PropTypes.array,
    metaEventStatus: PropTypes.object,
};

export default connect((state, ownProps) => {
    let eventSeries = state.entities.eventSeries.byId;

    const assignedEventSeries = get(ownProps, 'venueEvent.eventSeries');

    // Da die auswählbaren Eventserien asynchron geladen werden, sind diese noch nicht verfügbar, wenn das
    // Formular bereits angezeigt wird. daher mergen wir die ggf. dem Event bereits zugewiesene Eventserie
    // in die Auswahlmöglichkeiten, denn dann kann immer min. die bereits zugewiesene Eventserie angezeigt
    // und ausgewählt werden.
    if (assignedEventSeries) {
        eventSeries = {
            ...eventSeries,
            [assignedEventSeries.id]: assignedEventSeries
        };
    }

    const venues = Object.values(state.entities.venue.byId);
    const eventCategories = Object.values(state.entities.eventCategory.byId);

    return {
        eventSeries: sortBy(values(eventSeries), 'name'),
        venue: venues,
        eventCategory: eventCategories,
        ticketLayouts: Object.values(state.entities.ticketLayout.byId),
        salesRules: Object.values(state.entities.salesRule.byId),
        pricingClasses: Object.values(state.entities.pricingClass.byId)
    }
}, {
    loadEventSeries: eventSeriesActions.loadAll,
    loadVenues: venueActions.loadAll,
    loadEventCategories: eventCategoriesActions.loadAll,
    loadVenuePlansByVenueId: id => venuePlanActions.byVenue({id}),
    loadTicketLayouts: ticketLayoutActions.loadAll,
    loadPricingClasses: pricingClassActions.loadAll,
    loadSalesRules: loadSalesRuleActions.loadAll,
})(Form)
