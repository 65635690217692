import React from "react";
import { CircularProgress } from "@mui/material/";

export const LoadingIndicator = ({disableShrink=false}) => (
    <CircularProgress
        disableShrink={disableShrink}
        size={34}
        sx={{ display: 'flex', margin: '0 auto' }}
    />
);
