import React from 'react';
import {Switch} from 'react-router-dom';

import List from './List';
import Edit from './Edit';
import Create from './Create';
import {Container} from 'react-bootstrap';
import SecureRoute from '../auth/SecureRoute';

export default () => (
    <Container className={'mt-1'}>
        <Switch>
            <SecureRoute exact path="/event-management/event-series" component={List}/>
            <SecureRoute exact path="/event-management/event-series/create" component={Create}/>
            <SecureRoute exact path="/event-management/event-series/:id" component={Edit}/>
        </Switch>
    </Container>
);
