import {Resource} from "../../../api";

export default class Seat extends Resource {

    constructor(axios) {
        super('seat', axios);
    }

    /**
     * Übergibt Sitzreihen und -plätze
     */
    batch = (entity) => {
        return this.axios.post(`/${this.entityName}/batch`, entity)
            .then((res) => res.data);
    };


}
