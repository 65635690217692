import React from 'react';
import { useSelector } from 'react-redux';

interface HelpContentProps {
    as?: React.ElementType;
    children: React.ReactNode
}

interface RootState {
    helpTextsToggle: boolean;
}

const HelpContent: React.FC<HelpContentProps> = ({ as: Component, children, ...otherProps }) => {
    const isHelpContentVisible = useSelector((state: RootState) => !!state.helpTextsToggle);

    if (!isHelpContentVisible) {
        return null;
    }

    if (Component) {
        return <Component {...otherProps}>{children}</Component>;
    }

    return <>{children}</>;
};

export default HelpContent;