import get from 'lodash/get';
import {dismiss} from './actions';

/**
 * Reducer für den "errors" state.
 */
export default (state = [], {type, payload}) => {
    const error = get(payload, 'error');

    // nur auf payloads reagiert die eine "error" property enthalten.
    if (error) {
        if (type === dismiss.toString()) {
            return state.filter(e => e !== error);
        } else {
            return [...state, error];
        }
    }

    return state;
};
