export interface ICOUNTRY_CODE {
    name: string;
    id: string;
}

export const COUNTRY_CODE: ICOUNTRY_CODE[] = [
    {
        'id': '49',
        'name': '49',
    },
    {
        'name': '43',
        'id': '43'
    },
    {
        'name': '41',
        'id': '41'
    },
    {
        'name': '376',
        'id': '376'
    },
    {
        'name': '971',
        'id': '971'
    },
    {
        'name': '93',
        'id': '93'
    },
    {
        'name': '1-268',
        'id': '1-268'
    },
    {
        'name': '1-264',
        'id': '1-264'
    },
    {
        'name': '355',
        'id': '355'
    },
    {
        'name': '374',
        'id': '374'
    },
    {
        'name': '244',
        'id': '244'
    },
    {
        'name': '672',
        'id': '672'
    },
    {
        'name': '54',
        'id': '54'
    },
    {
        'name': '1-684',
        'id': '1-684'
    },
    {
        'name': '297',
        'id': '297'
    },
    {
        'name': '358',
        'id': '358'
    },
    {
        'name': '994',
        'id': '994'
    },
    {
        'name': '387',
        'id': '387'
    },
    {
        'name': '1-246',
        'id': '1-246'
    },
    {
        'name': '880',
        'id': '880'
    },
    {
        'name': '32',
        'id': '32'
    },
    {
        'name': '226',
        'id': '226'
    },
    {
        'name': '359',
        'id': '359'
    },
    {
        'name': '973',
        'id': '973'
    },
    {
        'name': '257',
        'id': '257'
    },
    {
        'name': '229',
        'id': '229'
    },
    {
        'name': '590',
        'id': '590'
    },
    {
        'name': '1-441',
        'id': '1-441'
    },
    {
        'name': '673',
        'id': '673'
    },
    {
        'name': '591',
        'id': '591'
    },
    {
        'name': '599',
        'id': '599'
    },
    {
        'name': '55',
        'id': '55'
    },
    {
        'name': '1-242',
        'id': '1-242'
    },
    {
        'name': '975',
        'id': '975'
    },
    {
        'name': '47',
        'id': '47'
    },
    {
        'name': '267',
        'id': '267'
    },
    {
        'name': '375',
        'id': '375'
    },
    {
        'name': '501',
        'id': '501'
    },
    {
        'name': '1',
        'id': '1'
    },
    {
        'name': '61',
        'id': '61'
    },
    {
        'name': '243',
        'id': '243'
    },
    {
        'name': '236',
        'id': '236'
    },
    {
        'name': '242',
        'id': '242'
    },
    {
        'name': '225',
        'id': '225'
    },
    {
        'name': '682',
        'id': '682'
    },
    {
        'name': '56',
        'id': '56'
    },
    {
        'name': '237',
        'id': '237'
    },
    {
        'name': '86',
        'id': '86'
    },
    {
        'name': '57',
        'id': '57'
    },
    {
        'name': '506',
        'id': '506'
    },
    {
        'name': '53',
        'id': '53'
    },
    {
        'name': '238',
        'id': '238'
    },
    {
        'name': '357',
        'id': '357'
    },
    {
        'name': '420',
        'id': '420'
    },
    {
        'name': '253',
        'id': '253'
    },
    {
        'name': '45',
        'id': '45'
    },
    {
        'name': '1-767',
        'id': '1-767'
    },
    {
        'name': '1-809',
        'id': '1-809'
    },
    {
        'name': '213',
        'id': '213'
    },
    {
        'name': '593',
        'id': '593'
    },
    {
        'name': '372',
        'id': '372'
    },
    {
        'name': '20',
        'id': '20'
    },
    {
        'name': '212',
        'id': '212'
    },
    {
        'name': '291',
        'id': '291'
    },
    {
        'name': '34',
        'id': '34'
    },
    {
        'name': '251',
        'id': '251'
    },
    {
        'name': '679',
        'id': '679'
    },
    {
        'name': '500',
        'id': '500'
    },
    {
        'name': '691',
        'id': '691'
    },
    {
        'name': '298',
        'id': '298'
    },
    {
        'name': '33',
        'id': '33'
    },
    {
        'name': '241',
        'id': '241'
    },
    {
        'name': '44',
        'id': '44'
    },
    {
        'name': '1-473',
        'id': '1-473'
    },
    {
        'name': '995',
        'id': '995'
    },
    {
        'name': '594',
        'id': '594'
    },
    {
        'name': '233',
        'id': '233'
    },
    {
        'name': '350',
        'id': '350'
    },
    {
        'name': '299',
        'id': '299'
    },
    {
        'name': '220',
        'id': '220'
    },
    {
        'name': '224',
        'id': '224'
    },
    {
        'name': '240',
        'id': '240'
    },
    {
        'name': '30',
        'id': '30'
    },
    {
        'name': '502',
        'id': '502'
    },
    {
        'name': '1-671',
        'id': '1-671'
    },
    {
        'name': '245',
        'id': '245'
    },
    {
        'name': '592',
        'id': '592'
    },
    {
        'name': '852',
        'id': '852'
    },
    {
        'name': '504',
        'id': '504'
    },
    {
        'name': '385',
        'id': '385'
    },
    {
        'name': '509',
        'id': '509'
    },
    {
        'name': '36',
        'id': '36'
    },
    {
        'name': '62',
        'id': '62'
    },
    {
        'name': '353',
        'id': '353'
    },
    {
        'name': '972',
        'id': '972'
    },
    {
        'name': '91',
        'id': '91'
    },
    {
        'name': '246',
        'id': '246'
    },
    {
        'name': '964',
        'id': '964'
    },
    {
        'name': '98',
        'id': '98'
    },
    {
        'name': '354',
        'id': '354'
    },
    {
        'name': '39',
        'id': '39'
    },
    {
        'name': '1-876',
        'id': '1-876'
    },
    {
        'name': '962',
        'id': '962'
    },
    {
        'name': '81',
        'id': '81'
    },
    {
        'name': '254',
        'id': '254'
    },
    {
        'name': '996',
        'id': '996'
    },
    {
        'name': '855',
        'id': '855'
    },
    {
        'name': '686',
        'id': '686'
    },
    {
        'name': '269',
        'id': '269'
    },
    {
        'name': '1-869',
        'id': '1-869'
    },
    {
        'name': '850',
        'id': '850'
    },
    {
        'name': '82',
        'id': '82'
    },
    {
        'name': '965',
        'id': '965'
    },
    {
        'name': '1-345',
        'id': '1-345'
    },
    {
        'name': '7',
        'id': '7'
    },
    {
        'name': '856',
        'id': '856'
    },
    {
        'name': '961',
        'id': '961'
    },
    {
        'name': '1-758',
        'id': '1-758'
    },
    {
        'name': '423',
        'id': '423'
    },
    {
        'name': '94',
        'id': '94'
    },
    {
        'name': '231',
        'id': '231'
    },
    {
        'name': '266',
        'id': '266'
    },
    {
        'name': '370',
        'id': '370'
    },
    {
        'name': '352',
        'id': '352'
    },
    {
        'name': '371',
        'id': '371'
    },
    {
        'name': '218',
        'id': '218'
    },
    {
        'name': '377',
        'id': '377'
    },
    {
        'name': '373',
        'id': '373'
    },
    {
        'name': '382',
        'id': '382'
    },
    {
        'name': '261',
        'id': '261'
    },
    {
        'name': '692',
        'id': '692'
    },
    {
        'name': '389',
        'id': '389'
    },
    {
        'name': '223',
        'id': '223'
    },
    {
        'name': '95',
        'id': '95'
    },
    {
        'name': '976',
        'id': '976'
    },
    {
        'name': '853',
        'id': '853'
    },
    {
        'name': '1-670',
        'id': '1-670'
    },
    {
        'name': '596',
        'id': '596'
    },
    {
        'name': '222',
        'id': '222'
    },
    {
        'name': '1-664',
        'id': '1-664'
    },
    {
        'name': '356',
        'id': '356'
    },
    {
        'name': '230',
        'id': '230'
    },
    {
        'name': '960',
        'id': '960'
    },
    {
        'name': '265',
        'id': '265'
    },
    {
        'name': '52',
        'id': '52'
    },
    {
        'name': '60',
        'id': '60'
    },
    {
        'name': '258',
        'id': '258'
    },
    {
        'name': '264',
        'id': '264'
    },
    {
        'name': '687',
        'id': '687'
    },
    {
        'name': '227',
        'id': '227'
    },
    {
        'name': '234',
        'id': '234'
    },
    {
        'name': '505',
        'id': '505'
    },
    {
        'name': '31',
        'id': '31'
    },
    {
        'name': '977',
        'id': '977'
    },
    {
        'name': '674',
        'id': '674'
    },
    {
        'name': '683',
        'id': '683'
    },
    {
        'name': '64',
        'id': '64'
    },
    {
        'name': '968',
        'id': '968'
    },
    {
        'name': '507',
        'id': '507'
    },
    {
        'name': '51',
        'id': '51'
    },
    {
        'name': '689',
        'id': '689'
    },
    {
        'name': '675',
        'id': '675'
    },
    {
        'name': '63',
        'id': '63'
    },
    {
        'name': '92',
        'id': '92'
    },
    {
        'name': '48',
        'id': '48'
    },
    {
        'name': '508',
        'id': '508'
    },
    {
        'name': '870',
        'id': '870'
    },
    {
        'name': '970',
        'id': '970'
    },
    {
        'name': '351',
        'id': '351'
    },
    {
        'name': '680',
        'id': '680'
    },
    {
        'name': '595',
        'id': '595'
    },
    {
        'name': '974',
        'id': '974'
    },
    {
        'name': '262',
        'id': '262'
    },
    {
        'name': '40',
        'id': '40'
    },
    {
        'name': '381',
        'id': '381'
    },
    {
        'name': '250',
        'id': '250'
    },
    {
        'name': '966',
        'id': '966'
    },
    {
        'name': '677',
        'id': '677'
    },
    {
        'name': '248',
        'id': '248'
    },
    {
        'name': '249',
        'id': '249'
    },
    {
        'name': '46',
        'id': '46'
    },
    {
        'name': '65',
        'id': '65'
    },
    {
        'name': '290',
        'id': '290'
    },
    {
        'name': '386',
        'id': '386'
    },
    {
        'name': '421',
        'id': '421'
    },
    {
        'name': '232',
        'id': '232'
    },
    {
        'name': '378',
        'id': '378'
    },
    {
        'name': '221',
        'id': '221'
    },
    {
        'name': '252',
        'id': '252'
    },
    {
        'name': '597',
        'id': '597'
    },
    {
        'name': '211',
        'id': '211'
    },
    {
        'name': '239',
        'id': '239'
    },
    {
        'name': '503',
        'id': '503'
    },
    {
        'name': '1-721',
        'id': '1-721'
    },
    {
        'name': '963',
        'id': '963'
    },
    {
        'name': '268',
        'id': '268'
    },
    {
        'name': '1-649',
        'id': '1-649'
    },
    {
        'name': '235',
        'id': '235'
    },
    {
        'name': '228',
        'id': '228'
    },
    {
        'name': '66',
        'id': '66'
    },
    {
        'name': '992',
        'id': '992'
    },
    {
        'name': '690',
        'id': '690'
    },
    {
        'name': '670',
        'id': '670'
    },
    {
        'name': '993',
        'id': '993'
    },
    {
        'name': '216',
        'id': '216'
    },
    {
        'name': '676',
        'id': '676'
    },
    {
        'name': '90',
        'id': '90'
    },
    {
        'name': '1-868',
        'id': '1-868'
    },
    {
        'name': '688',
        'id': '688'
    },
    {
        'name': '886',
        'id': '886'
    },
    {
        'name': '255',
        'id': '255'
    },
    {
        'name': '380',
        'id': '380'
    },
    {
        'name': '256',
        'id': '256'
    },
    {
        'name': '598',
        'id': '598'
    },
    {
        'name': '998',
        'id': '998'
    },
    {
        'name': '39-06',
        'id': '39-06'
    },
    {
        'name': '1-784',
        'id': '1-784'
    },
    {
        'name': '58',
        'id': '58'
    },
    {
        'name': '1-284',
        'id': '1-284'
    },
    {
        'name': '1-340',
        'id': '1-340'
    },
    {
        'name': '84',
        'id': '84'
    },
    {
        'name': '678',
        'id': '678'
    },
    {
        'name': '681',
        'id': '681'
    },
    {
        'name': '685',
        'id': '685'
    },
    {
        'name': '967',
        'id': '967'
    },
    {
        'name': '27',
        'id': '27'
    },
    {
        'name': '260',
        'id': '260'
    },
    {
        'name': '263',
        'id': '263'
    }
];