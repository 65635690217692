import React from 'react';
import PropTypes from 'prop-types';
import {ItemField, ListItem} from './ListViewPropTypes';
import ListViewItemCell from './ListViewItemCell';
import { Checkbox } from '@mui/material';
import classnames from 'classnames';
import styles from './listView.module.scss';
function ListViewRow({
    item,
    fields,
    children,
    isSelected,
    toggleElement,
    columnVisibility,
    isShowSelection
}) {
    return (
        <tr>
            {isShowSelection && (
                <td>
                    {(item.hardcoded === undefined || !item.hardcoded) && (
                        <Checkbox
                            id={item.id}
                            checked={isSelected}
                            value={item.id}
                            onChange={(item) => {toggleElement(item)}}
                       />
                    )}
                </td>
            )}
            {fields.map((field, index) => {
                return (
                    !columnVisibility[field.name] ? (
                        <ListViewItemCell key={`${field.name}-${index}`} field={field} value={item[field.name]}/>
                    ) : <ListViewItemCell key={`${field.name}-${index}`} field={''} value={''} className={classnames({[styles.ListViewItemCell]: columnVisibility})}/>
                );
            })}
            {children && <td><span style={{ display: 'flex' }}>{children({fields, item})}</span></td>}
        </tr>
    );
}

ListViewRow.propTypes = {
    fields: PropTypes.arrayOf(ItemField).isRequired,
    item: ListItem.isRequired
};

export default ListViewRow;
