import React, { useState, useMemo, useEffect } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Card, Col, Row } from 'react-bootstrap';
import { FormikTextInputGroup } from '../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../common/FeedbackButton';
import Footer from "../layout/Footer";
import { connect } from "react-redux";
import Api from "../../api";
import { Tab, Tabs, FormHelperText } from "@mui/material";
import styles from "../form.module.scss";
import TabsPanel from "../common/TabsPanel/TabsPanel";
import UsedIn from "./UsedIn";
import UploadButton from "../common/UploadButton";
import ConfirmModal from "../common/modal/ConfirmModal";
import { FormikCheckbox } from "../common/formik/FormikCheckbox";
import FormikAsyncTypeaheadInput from "../common/formik/FormikAsyncTypeaheadInput";
import MuiBoxLikeFormField from '../common/MuiBoxLikeFormField/MuiBoxLikeFormField';
import ImagePreview from '../common/ImagePreview/ImagePreview';
import { isEmpty } from 'lodash';
import { formatBytes } from '../../utils/utils';

const INITIAL_VALUES = {
    title: '',
    tags: {
        de: [],
    },
    altText: '',
    description: '',
    copyrightText: '',
    mediaFile: null,
    archived: false
};

const FORM_SCHEMA = yup.object().shape({
    title: yup.string().required(),
    tags: yup.object().shape({
        de: yup.array().of(yup.string()).nullable().default([]),
    }).nullable(),
    altText: yup.string().nullable(),
    description: yup.string().nullable(),
    copyrightText: yup.string().nullable(),
    mediaFile: yup.object().shape({
        id: yup.string(),
        url: yup.string().url(),
    }).required(),
    archived: yup.boolean(),
});


const api = new Api();

const Form = ({ onSubmit, submitPending, media, helpTextsVisible }) => {
    const [isArchived, setIsArchived] = useState(media.archived);
    const [uploadProgress, setUploadProgress] = useState(false);
    const [currentTab, setCurrentTab] = useState('home');
    const [mediaUploadId, setMediaUloadId] = useState(undefined);
    const [mimeType, setMimeType] = useState('');
    const [size, setSize] = useState('');

    const ArchivedStatus = ({setFieldValue}) => {
        const confirmChangeStatus = () => () => {
            let newStatus = !isArchived
            setIsArchived(newStatus);
            setFieldValue('archived', newStatus);
        };
        return (
            <ConfirmModal title="Status ändern"
                          body={(isArchived) ? `Medien wieder reaktivieren?`: `Medien ins Archiv verschieben?`}
                          cancelLabel="Abbrechen"
                          confirmLabel="Bestätigen"
            >
                {confirm => (
                    <FormikCheckbox
                        type="checkbox"
                        id="archived"
                        label="Medien archivieren"
                        onChange={(e) => confirm(confirmChangeStatus(e))}
                        name="archived"
                        checked={isArchived}
                    />
                )}
            </ConfirmModal>
        )
    }

    const processUploadFile = (selectedFile, formik) => {
        if (selectedFile) {
            setUploadProgress(true);
            let formData = new FormData();
            formData.append('file', selectedFile);

            api.uploadMediaFile(formData).then((result) => {
                formik.setFieldValue('mediaFile', result);
                setMediaUloadId(result.id);
                setMimeType(result.mimeType);
                if (isEmpty(media)) {
                    setSize(formatBytes(result.size));
                }
                setUploadProgress(false);
            })
            .catch((error) => {
                setUploadProgress(false);
                if (error?.errorType === 'file.too_large_error') {
                    formik.setFieldError('uploadError', error?.message);
                } else {
                    formik.setFieldError('uploadError', 'Hochladen einer Mediendatei fehlgeschlagen');
                }
            });
        }
    }
    const deleteUploadFile = (formik) => {
        if (!isEmpty(media)) {
            setMimeType(media.mediaFile?.mimeType);
        }
        api.removeMediaFile(mediaUploadId).then(() => {
            if (isEmpty(media)) {
                formik.setFieldValue('mediaFile', null);
            } else {
                formik.setFieldValue('mediaFile', media.mediaFile);
            }
        })
        .catch((error) => {
            formik.setFieldError('uploadError', 'Löschen der Mediendatei fehlgeschlagen');
        });
    }

    const initialValues = useMemo(() => {
        if (media.mediaFile) {
            return {
                ...INITIAL_VALUES,
                ...media,
            };
        } else {
            return { ...INITIAL_VALUES, ...media };
        }
    }, [media?.mediaFile?.id]);

    useEffect(() => {
        if (!isEmpty(media)) {
            setSize(formatBytes(media.mediaFile?.size));
            setMimeType(media.mediaFile?.mimeType);
        }
    }, [media?.mediaFile?.size, media?.mediaFile?.mimeType]);


    return <Formik
        initialValues={initialValues}
        validationSchema={FORM_SCHEMA}
        onSubmit={(values, actions) => {
            onSubmit(values, actions);
            if (!isEmpty(media)) {
                actions.setFieldValue('mediaFile', media.mediaFile);
            }
        }}
    >
        {(formik) => {
            const { errors } = formik || {};
            return (
                <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                    <Tabs id="uncontrolled-tab-example" value={currentTab} onChange={(event, newTab) => setCurrentTab(newTab)}>
                        <Tab value="home" label="Mediainformationen" />
                        <Tab value="usedIn" label="Genutzt in" />
                    </Tabs>

                    <div className={styles.tabsContentWrapper}>
                        <TabsPanel value={currentTab} index={'home'}>
                            <Card className="mt-4 mb-4">
                                <Card.Body>
                                    <h2 className="font-weight-bold pb-3 mb-3">Mediadatei hinzufügen</h2>
                                    <Row className="mt-3">
                                        <Col className="col-md-4">
                                            <FormikTextInputGroup label="Titel*" name="title" testid="title"/>
                                        </Col>
                                        <Col className="col-md-4">
                                            <FormikAsyncTypeaheadInput
                                                id="tags.de"
                                                label="Tags"
                                                onSearch={() => api.getTagsForMediaByLang('de')}
                                                minLength={0}
                                                testid="tags.de"
                                                multiple={true}
                                                arrayOfStrings={true}
                                                allowNew={true}
                                                helpText="Tags, nach denen gesucht werden kann, um dieses Medien zu finden."
                                            />
                                        </Col>
                                        <Col className="col-md-4">
                                            <FormikTextInputGroup label="Alt-Text" name="altText" testid="altText"/>
                                        </Col>
                                        <Col className="col-md-4">
                                            <FormikTextInputGroup type="textarea" label="Beschreibung" name="description"/>
                                        </Col>
                                        <Col className="col-md-4">
                                            <FormikTextInputGroup type="textarea" label="Copyright-Text" name="copyrightText"/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-md-4">
                                            {media.mediaFile && (
                                                <ImagePreview src={media.mediaFile?.url} />
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col className="col-md-4">
                                            <UploadButton
                                                errors={errors}
                                                acceptedTypes={['.png, .jpg']}
                                                onChange={file => processUploadFile(file, formik)}
                                                label={media?.mediaFile?.id ? 'Ersetzen Sie die Datei' : 'Datei'}
                                                showPreview={true}
                                                uploadProgress={uploadProgress}
                                                deleteUploadFile={() => deleteUploadFile(formik)}
                                            />
                                            {errors.mediaFile && !uploadProgress && !errors?.uploadError && (
                                                <FormHelperText sx={{ bottom: '-8px' }} className={errors.mediaFile ? 'Mui-error' : ''} data-testid="error">
                                                    {errors.mediaFile}
                                                </FormHelperText>
                                            )}
                                            {media?.mediaFile && (media.mediaFile?.mimeType !== mimeType) && (
                                                <FormHelperText sx={{ bottom: '-18px' }} className='Mui-error' data-testid="error">
                                                    {`Die Ersetzung ist nur mit einer Datei desselben Typs möglich. Alter Typ ${media.mediaFile?.mimeType}, neuer Typ ${mimeType}`}
                                                </FormHelperText>
                                            )}

                                            {(errors?.uploadError) && (
                                                <FormHelperText sx={{ bottom: '-8px' }} className='Mui-error' data-testid="error">
                                                    {errors?.uploadError}
                                                </FormHelperText>
                                            )}

                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col className="col-md-4">
                                            <MuiBoxLikeFormField label='Dateityp' value={mimeType} disabled />
                                        </Col>
                                        <Col className="col-md-4">
                                            <MuiBoxLikeFormField label='Dateigröße' value={size} disabled />
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <ArchivedStatus setFieldValue={formik.setFieldValue}/>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </TabsPanel>
                        <TabsPanel value={currentTab} index={'usedIn'}>
                            {!!media.id && <UsedIn mediaId={media.id} />}
                        </TabsPanel>
                    </div>

                    <Footer>
                        <FeedbackButton to={`/base/media`}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton
                            type="submit"
                            busy={submitPending}
                            disabled={media?.mediaFile && (media.mediaFile?.mimeType !== mimeType)}
                        >
                            Speichern
                        </FeedbackButton>
                    </Footer>
                </form>
            )
        }}
    </Formik>
}

Form.propTypes = {
    onSubmit: PropTypes.any,
    submitPending: PropTypes.any,
    media: PropTypes.any,
    requestErrors: PropTypes.any
};


const mapStateToProps = (state, props) => {

    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps)(Form);
