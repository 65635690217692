import {forEach} from "lodash";

// Funktionen zum Verändern der Daten der cellMap (2D-Map von classId,categoryId auf cellData) durch reducer,
// ausgelagert da häufig benötigt

/**
 * Setzt den Inhalt einer einzelnen Zelle, identifiziert über classId und categoryId
 *
 * @param cellMap object
 * @param classId string
 * @param categoryId string
 * @param cellData object
 */
export const setCellMapCell = (cellMap, classId, categoryId, cellData) => {
    if (!(classId in cellMap)) {
        cellMap[classId] = {};
    }
    cellMap[classId][categoryId] = cellData;
};

/**
 * Entfernt den Inhalt einer einzelnen Zelle, identifiziert über classId und categoryId
 *
 * @param cellMap object
 * @param classId string
 * @param categoryId string
 */
export const clearCellMapCell = (cellMap, classId, categoryId) => {
    if (classId in cellMap) {
        if (categoryId in cellMap[classId]) {
            delete cellMap[classId][categoryId];
        }
    }
};

/**
 * @param cellMap
 * @param classId
 * @param categoryId
 * @param grossAmount
 */
export const changeCellGrossAmount = (cellMap, classId, categoryId, grossAmount) => {
    if (cellMap[classId][categoryId]) {
        cellMap[classId][categoryId].grossAmount = grossAmount;
    }
};

/**
 * @param cellMap
 * @param classId
 * @param categoryId
 * @param strikeAmount
 */
export const changeCellStrikeAmount = (cellMap, classId, categoryId, strikeAmount) => {
    if (cellMap[classId][categoryId]) {
        cellMap[classId][categoryId].strikeAmount = strikeAmount;
    }
};

/**
 * Setzt den Inhalt aller Zellen, die zur PricingClass mit der übergebenen classId gehören
 * -> Setzt für alle Zellen identifiziert über classId und IDs sömtlicher pricingCategories der Matrix
 *
 * @param pricingMatrix
 * @param cellMap
 * @param classId
 * @param cellData
 */
export const setCellMapClass = (pricingMatrix, cellMap, classId, cellData) => {
    forEach(pricingMatrix.pricingCategories, category =>
        setCellMapCell(cellMap, classId, category.id, cellData)
    );
};

/**
 * Entfernt den Inhalt aller Zellen, die zur PricingClass mit der übergebenen classId gehören
 *
 * @param cellMap
 * @param classId
 */
export const clearCellMapClass = (cellMap, classId) => {
    if(classId in cellMap) {
        delete cellMap[classId];
    }
};

/**
 * Setzt den Inhalt aller Zellen, die zur PricingCategory mit der übergebenen categoryId gehören
 * -> Setzt für alle Zellen identifiziert über IDs sömtlicher pricingClasses und categoryId der Matrix
 *
 * @param pricingMatrix
 * @param cellMap
 * @param categoryId
 * @param cellData
 */
export const setCellMapCategory = (pricingMatrix, cellMap, categoryId, cellData) => {
    forEach(pricingMatrix.pricingClasses, pricingClass =>
        setCellMapCell(cellMap, pricingClass.id, categoryId, cellData)
    );
};

/**
 * Entfernt den Inhalt aller Zellen, die zur PricingClass mit der übergebenen classId gehören
 *
 * @param cellMap
 * @param categoryId
 */
export const clearCellMapCategory = (cellMap, categoryId) => {
    forEach(cellMap, cellClassMap => {
        if(categoryId in cellClassMap) {
            delete cellClassMap[categoryId];
        }
    });
};

/**
 * Setzt den Inhalt aller Zellen der gesamten Matrix
 *
 * @param pricingMatrix
 * @param cellMap
 * @param cellData
 */
export const setCellMapMatrix = (pricingMatrix, cellMap, cellData) => {
    forEach(pricingMatrix.pricingClasses, pricingClass =>
        setCellMapClass(pricingMatrix, cellMap, pricingClass.id, cellData)
    );
};
