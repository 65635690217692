import React, {useState} from 'react';
import ConfirmModal from "../modal/ConfirmModal";
import Api from "../../../api";
import FeedbackButton from "../FeedbackButton";
import StopIcon from '@mui/icons-material/StopCircleOutlined';

const api = new Api();

export default ({
    id,
    onStop,
    modalAccept = 'Aktion beenden',
    modalCancel = 'Abbrechen',
    modalTitle = 'Aktion beenden',
    buttonText = 'Aktion beenden',
    bodyText = 'Dies kann nicht rückgängig gemacht werden. Sind Sie sicher, dass Sie die Aktion beenden möchten?'
}) => {
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const stopJob = () => {
        setButtonDisabled(true);

        api.stopJob(id)
            .then(() => {
                if (typeof onStop === 'function') {
                    onStop();
                }

                setButtonDisabled(false);
            })
            .catch(() => {
                setButtonDisabled(false);
            });
    };

    return <>
        <ConfirmModal title={modalTitle}
                      body={bodyText}
                      cancelLabel={modalCancel}
                      confirmLabel={modalAccept}
        >
            {confirm =>
                <FeedbackButton className="list-link" busy={buttonDisabled}
                                onClick={() => confirm(stopJob)}>
                    {buttonText} <StopIcon/>
                </FeedbackButton>
            }
        </ConfirmModal>
    </>;
};
