import React, {useCallback, useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormikCheckbox from "../common/formik/FormikCheckbox";
import {getIn} from "formik";
import {LoadingIndicator} from "../common/LoadingIndicator";
import Api from "../../api";
import CancelIcon from "@mui/icons-material/Cancel";

const api = new Api();

function FormMolliePaymentMethods({formik}) {
    const [loading, setLoading] = useState(false);
    const [mollieError, setMollieError] = useState('');
    const [paymentMethods, setPaymentMethods] = useState([]);

    const mollieSettingsInit = {
        apiKey: '',
        partnerId: '',
        profileId: '',
        paymentMethods: [],
    };

    const areMollieSettingsValid = (mollieSettings) => {
        return !(
            !mollieSettings
            || !mollieSettings.apiKey
            || !mollieSettings.partnerId
            || !mollieSettings.profileId
        );
    };

    const provider = formik.values.paymentSettings?.provider;
    const mollieSettings = formik.values.paymentSettings?.mollie ?? mollieSettingsInit;

    useEffect(() => {
        const settings = {
            apiKey: mollieSettings.apiKey,
            partnerId: mollieSettings.partnerId,
            profileId: mollieSettings.profileId,
        }

        if (provider !== 'mollie' || !areMollieSettingsValid(settings)) {
            return;
        }

        setMollieError('');

        const delayInputTimeoutId = setTimeout(() => {
            setLoading(true);
            api.getMolliePaymentMethods(settings).then(result => {
                setPaymentMethods(result);
                setLoading(false);
            }).catch(errResponse => {
                if (errResponse.hasOwnProperty('message')) {
                    setMollieError(errResponse.message);
                }
                setLoading(false);
                setPaymentMethods([]);
            });
        }, 500);

        return () => clearTimeout(delayInputTimeoutId);
    }, [provider, mollieSettings.apiKey, mollieSettings.partnerId, mollieSettings.profileId]);

    useEffect(() => {
        if (provider !== 'mollie') {
            return;
        }

        if (formik.values.paymentSettings?.mollie === null) {
            formik.setFieldValue('paymentSettings.mollie', {...mollieSettingsInit}
            );
        }
    }, [provider, formik]);

    useEffect(() => {
        if (formik.values.paymentSettings?.provider !== 'mollie') {
            return;
        }

        if (!loading && !formik.values.paymentSettings?.mollie) {
            formik.setFieldValue('paymentSettings.mollie', {...mollieSettingsInit}
            );
        }
    }, [loading, formik]);

    const paymentMethodsId = 'paymentSettings.mollie.paymentMethods';

    const isMethodChecked = useCallback((method) => {
        if (!formik.values.paymentSettings?.mollie?.paymentMethods) {
            return false;
        }
        return formik.values.paymentSettings.mollie.paymentMethods.includes(method);
    }, [formik]);

    const PaymentMethods = useCallback(() => {
        if (!(!!getIn(formik.touched, paymentMethodsId))) {
            return <></>;
        }

        const errorText = getIn(formik.errors, paymentMethodsId);

        if (errorText) {
            return <span className={'invalid-checkbox'}>
                {errorText}
            </span>;
        }
        return <></>;
    }, [formik]);

    const handleCheckboxChange = useCallback((event) => {
        const value = event.target.value;
        const paymentMethods = formik.values.paymentSettings?.mollie?.paymentMethods ?? [];
        const alreadySelected = paymentMethods.includes(value);

        formik.setFieldTouched(paymentMethodsId, true);

        if (alreadySelected) {
            formik.setFieldValue(
                paymentMethodsId,
                paymentMethods.filter(method => method !== value)
            );
        } else {
            formik.setFieldValue(
                paymentMethodsId,
                [...paymentMethods, value]
            );
        }
    }, [formik]);

    if (loading) {
        return <LoadingIndicator/>;
    }

    if (!areMollieSettingsValid(formik.values.paymentSettings?.mollie)) {
        return <span className={'invalid-checkbox'}>
                Bitte füllen Sie alle Mollie Felder aus, um die verfügbaren Zahlarten zu laden.
            </span>;
    }

    if (mollieError) {
        return <span className={'invalid-checkbox'}>{mollieError}</span>;
    }

    return (
        <>
            {paymentMethods.map(product => (
                <Row className="mt-1" key={product.id}>
                    <Col className="col-md-12 px-4">
                        <FormikCheckbox
                            label={product.active ? product.label : `${product.label} (Nicht aktiv bei Mollie)`}
                            icon={product.logo}
                            type="checkbox"
                            name={`mollie-${product.id}`}
                            value={product.id}
                            checked={isMethodChecked(product.id)}
                            onChange={handleCheckboxChange}
                        />
                    </Col>
                </Row>
            ))}
            <PaymentMethods/>
        </>
    )
}

FormMolliePaymentMethods.propTypes = {
    formik: PropTypes.object,
};

export default FormMolliePaymentMethods;
