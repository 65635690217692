import {createReducer} from "redux-starter-kit";
import {filter, forEach, includes, find, findIndex, slice, get} from 'lodash';
import * as cellMapHelpers from './cellMap/helpers';

import * as actions from './actions';
import ObjectId from "bson-objectid";

export default createReducer({}, {

    [actions.addPricingClass]: (state, {payload: {matrixId, pricingClass}}) => {
        state.byId[matrixId].pricingClasses.push(pricingClass);
    },
    [actions.removePricingClass]: (state, {payload: {matrixId, pricingClass}}) => {
        state.byId[matrixId].pricingClasses = filter(state.byId[matrixId].pricingClasses, c => c.id !== pricingClass.id);
        cellMapHelpers.clearCellMapClass(state.cellMapById[matrixId], pricingClass.id);
    },
    [actions.addPricingCategory]: (state, {payload: {matrixId, pricingCategory}}) => {
        state.byId[matrixId].pricingCategories.push(pricingCategory);
    },
    [actions.removePricingCategory]: (state, {payload: {matrixId, pricingCategory}}) => {
        state.byId[matrixId].pricingCategories = filter(state.byId[matrixId].pricingCategories, c => c.id !== pricingCategory.id);
        cellMapHelpers.clearCellMapCategory(state.cellMapById[matrixId], pricingCategory.id);
    },

    [actions.addPurchasableItemTemplate]: (state, {payload: {matrixId, purchasableItemTemplate}}) => {
        state.byId[matrixId].availableLocalItemTemplates.push({id: ObjectId.generate(), purchasableItemTemplate});
    },
    [actions.clearUnusedPurchasableItemTemplates]: (state, {payload: {matrixId}}) => {
        const usedIds = [];
        forEach(state.cellMapById[matrixId], categoryMap => forEach(categoryMap, cellData => {
            usedIds.push(cellData.localItemTemplateId);
        }));

        state.byId[matrixId].availableLocalItemTemplates = filter(
            state.byId[matrixId].availableLocalItemTemplates,
            itemTemplate => includes(usedIds, itemTemplate.id)
        );
    },
    [actions.clearAllPrices]: (state, {payload: {matrixId}}) => {
        state.byId[matrixId].pricingClasses.map((pricingClass) => {
            state.byId[matrixId].pricingCategories.map((pricingCategory) => {
                cellMapHelpers.changeCellGrossAmount(state.cellMapById[matrixId], pricingClass.id, pricingCategory.id, null);
                cellMapHelpers.changeCellStrikeAmount(state.cellMapById[matrixId], pricingClass.id, pricingCategory.id, null);
            });
        });
    },

    [actions.changeLocalItemTemplate]: (state, {payload: {matrixId, localItemTemplate}}) => {
        const index = findIndex(state.byId[matrixId].availableLocalItemTemplates, i => i.id === localItemTemplate.id);
        state.byId[matrixId].availableLocalItemTemplates = [
            ...slice(state.byId[matrixId].availableLocalItemTemplates, 0, index),
            localItemTemplate,
            ...slice(state.byId[matrixId].availableLocalItemTemplates, index + 1),
        ]
    },
    [actions.toggleEditLocalItemTemplate]: (state, {payload: {matrixId, localItemTemplateId}}) => {
        const itemTemplate = find(state.byId[matrixId].availableLocalItemTemplates, i => i.id === localItemTemplateId);
        itemTemplate.$editExpanded = !itemTemplate.$editExpanded;
    },
    [actions.toggleIncludeStrikeAmount]: (state, {payload: {matrixId, matrixVal}}) => {
        let matrix = state.byId[matrixId];
        matrix.includeStrikeAmount = !matrix.includeStrikeAmount;
    },

});

