import React, { useEffect, useState } from 'react';
import debounce from 'debounce-promise';
import { getIn } from 'formik';
import Api from '../../api';
import { FormikTextInputGroup } from '../common/formik/FormikTextInputGroup';
import { memoize } from '../../form/fieldValidation';
import { Col, Row } from 'react-bootstrap';
import { isEmpty, intersectionWith } from 'lodash';
import PropTypes from 'prop-types';
import HelpContent from '../common/help/HelpContent';
import FormVenueEvents from "./FormVenueEvents";
import { FormikSelect } from "../common/formik/FormikSelect";
import { FormikCheckbox } from "../common/formik/FormikCheckbox";
import FeedbackButton from '../common/FeedbackButton';
import FormikAsyncTypeaheadInput from "../common/formik/FormikAsyncTypeaheadInput";
import { connect, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import ListingModal from "../common/modal/ListingModal";
import FieldImage from '../common/FieldImage/FieldImage';
import styles from "../form.module.scss";

const api = new Api();

const FormData = ({
    metaEvent,
    eventSeries,
    venue,
    eventCategory,
    formik,
    initialValues,
    formSchema,
    metaEventStatus,
    refs,
    helpTextsVisible
}) => {
    const [refreshKey, setRefreshKey] = useState(0);
    const [isEventCategoryFilterApplied, setIsEventCategoryFilterApplied] = useState(true);

    const [activeFieldName, setActiveFieldName] = useState('');
    const [isShowBatchModal, setIsShowBatchModal] = useState(false);
    const [venuePlanOptions, setVenuePlanOptions] = useState([]);

    const currentTenant = useSelector(state => state.currentTenant);

    const [taxCategoryLabels, setTaxCategoryLabels] = useState([]);
    useEffect(() => {api.getTaxCategoryLabels().then(res => {setTaxCategoryLabels(res)})}, []);


    const closeModal = () => {
        setIsShowBatchModal(false);
    }

    const onHandleShowModal = (e, fieldName) => {
        e.stopPropagation();
        setIsShowBatchModal(true);
        setActiveFieldName(fieldName);
    }

    const onHandleChooseImage = (item) => {
        const { id, title, url } = item;
        formik.setFieldValue(activeFieldName, {
            title,
            url,
            id,
        });
        setIsShowBatchModal(false);
    }

    const onHanldleRemove = (e, fieldName) => {
        e.stopPropagation();
        formik.setFieldValue(fieldName, null);
    }

    // Die debounce Funktion aus lodash reicht hier nicht aus, denn wir brauchen ein debounctes Promise.
    // Bereits beim ersten Aufruf muss ein Promise geliefert werden, damit Formik weiß, dass die Validierung
    // anegstoßen wurde. Dieses Promise darf aber erst dann aufgelöst werden wenn die debouncte async Funktion
    // selbst abgeschlossen ist.
    const getMetaEventByIdentDebounced = debounce(ident => api.getMetaEventByIdent(ident), 500);

    // async field-level Validierung für ident, memoisiert um zu vermeiden,
    // dass die API bei jeder Änderung des Formulars abgefragt wird.
    const validateIdent = memoize({
        initialValue: metaEvent.ident,
        async validate(ident) {
            // Wenn der Ident leer ist brauchen wir nicht die API zu fragen.
            if (!ident) return;

            const event = await getMetaEventByIdentDebounced(ident);

            if (event && event.id !== metaEvent.id) {
                return 'Der Ident wird bereits verwendet'
            }
        }
    });

    let venuePlanId = '';
    if (formik.values.venuePlan && formik.values.venuePlan.id) {
        venuePlanId = formik.values.venuePlan.id;
    }

    const changeVenueSelection = (newId) => {
        formik.setFieldValue('venuePlan', null);
        api.byVenue(newId).then(res => setVenuePlanOptions(res))
    }

    let selectedEventCategory = formik.values.eventCategory?.id ?? null;
    const changeEventCategory = (newId) => {
        selectedEventCategory = newId;
    };

    const handleEventCategoryFilter = (filterChecked) => {
        setIsEventCategoryFilterApplied(filterChecked);
        setRefreshKey(prevKey => prevKey + 1);
    }

    useEffect(() => {
        !isEmpty(formik.values.venue) && api.byVenue(formik.values.venue).then(res => setVenuePlanOptions(res))

        if (!isEmpty(metaEvent.artists)) {
            api.getArtistsByQuery().then(res => formik.setFieldValue('artists', intersectionWith(res, metaEvent.artists, (a, b) => a.id === b)));
        }
    }, []);

    return (
        <>
            <div>
                <div className={styles.formBox}>
                    <h3>Um ein Meta-Event zu erstellen, müssen zuerst die einzelnen Events mit entsprechenden
                        VK-Regeln angelegt worden sein.</h3>
                    <h3>Bundles werden aus Kundensicht wie ein einzelnes Event gekauft.</h3>
                </div>
                <div className={styles.formBox}>
                    <h2 className={styles.formTitle}>Allgemein</h2>
                    <Row className="mt-3">
                        <Col className="col-md-4">
                            <FormikTextInputGroup label="Meta-Event Titel* (deutsch)" name="title.de"
                                                    inputRef={refs.title} testid="titleDe"
                                                    helpText="Dieser Name wird auf dem Ticket angezeigt."/>
                        </Col>
                        <Col className="col-md-4">
                            <FormikTextInputGroup label="Meta-Event Titel* (englisch)" name="title.en"
                                                    inputRef={refs.title} testid="titleEn"
                                                    helpText="Dieser Name wird auf dem Ticket angezeigt."/>
                        </Col>
                        <Col className="col-md-4">
                            <FormikTextInputGroup label="Untertitel (deutsch)" name="subtitle.de"
                                                    helpText="Dieser Name wird auf dem Ticket angezeigt."/>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-md-4">
                            <FormikTextInputGroup label="Untertitel (englisch)" name="subtitle.en"
                                                    helpText="Dieser Name wird auf dem Ticket angezeigt."/>
                        </Col>
                        <Col className="col-md-4">
                            <FormikTextInputGroup label="Ident*" name="ident" validate={validateIdent} testid="ident"
                                                    inputRef={refs.ident}
                                                    helpText="Kürzel des Events für Anzeige im Backend."/>
                        </Col>
                        <Col className="col-md-4">
                            <FormikTextInputGroup label="Product ID" name="productId" testid="productId" />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-md-4">
                            <FormikSelect
                                name="eventSeries"
                                label="Eventserie"
                                options={eventSeries}
                                emptyValueLabel="Bitte wählen ..."
                            />
                        </Col>
                        <Col className="col-md-4 align-self-end mb-4">
                            <FormikCheckbox label="Plätze ohne grafischen Saalplan verkaufen" name="disableVisualSelection"/>
                        </Col>
                    </Row>
                </div>
                <div className={styles.formBox}>
                    <h2 className={styles.formTitle}>Grafiken</h2>
                    <HelpContent as="p" className="text-muted">Hier werden die URLs der Grafiken
                        eingefügt.</HelpContent>
                    <Row className="mt-3">
                        <Col className="col-md-4">
                            <FieldImage
                                values={getIn(formik.values, 'team1Image')}
                                fieldName='team1Image'
                                label="Logo/Bild 1/Vereinslogo 1"
                                onHandleShowModal={onHandleShowModal}
                                onHanldleRemove={onHanldleRemove}
                                helpText='Vereinslogos werden im Ticketauswahlfenster angezeigt.'
                            />
                        </Col>
                        <Col className="col-md-4">
                            <FieldImage
                                values={getIn(formik.values, 'team2Image')}
                                fieldName='team2Image'
                                label="Logo/Bild 2/Vereinslogo 2"
                                onHandleShowModal={onHandleShowModal}
                                onHanldleRemove={onHanldleRemove}
                                helpText="Vereinslogos werden im Ticketauswahlfenster angezeigt."
                            />
                        </Col>
                        <Col className="col-md-4">
                            <FieldImage
                                values={getIn(formik.values, 'walletLogo')}
                                fieldName='walletLogo'
                                label="Wallet Logo"
                                onHandleShowModal={onHandleShowModal}
                                onHanldleRemove={onHanldleRemove}
                                helpText="Wird in der Wallet (Frontseite) oben links angezeigt"
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-md-4">
                            <FieldImage
                                values={getIn(formik.values, 'walletHeader')}
                                fieldName='walletHeader'
                                label="Wallet Headergrafik"
                                onHandleShowModal={onHandleShowModal}
                                onHanldleRemove={onHanldleRemove}
                                helpText="Wird in der Wallet (Frontseite) oben als Gestaltungselement angezeigt"
                            />
                        </Col>
                    </Row>
                </div>
                <div className={styles.formBox}>
                    <h2 className={styles.formTitle}>Kaufprozess</h2>
                    <Row className="mt-3">
                        <Col className="col-md-4">
                            <FormikTextInputGroup
                                label="Ablaufzeit bei Ticketauswahl in Minuten"
                                name="ticketSelectionExpiration"
                                helpText="Die Zeit, in der das Ticket reserviert bleibt, nachdem es in den Warenkorb gelegt wurde (in Minuten)."
                            />
                        </Col>
                        <Col className="col-md-4">
                            <FormikTextInputGroup
                                label="Vertriebsinformation (deutsch)"
                                name="salesInformation.de"
                                type="textarea"
                            />
                        </Col>
                        <Col className="col-md-4">
                            <FormikTextInputGroup
                                label="Vertriebsinformation (englisch)"
                                name="salesInformation.en"
                                type="textarea"
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-md-4">
                            <FormikAsyncTypeaheadInput
                                id="tags.de"
                                label="Eventtag (deutsch)"
                                onSearch={() => api.getTagsForMetaEventsByLang('de')}
                                minLength={0}
                                testid="tags.de"
                                multiple={true}
                                arrayOfStrings={true}
                                allowNew={true}
                                helpText="Tags, nach denen gesucht werden kann, um dieses Event zu finden."
                            />
                        </Col>
                        <Col className="col-md-4">
                            <FormikAsyncTypeaheadInput
                                id="tags.en"
                                label="Eventtag (englisch)"
                                onSearch={() => api.getTagsForMetaEventsByLang('en')}
                                minLength={0}
                                testid="tags.en"
                                multiple={true}
                                arrayOfStrings={true}
                                allowNew={true}
                                helpText="Tags, nach denen gesucht werden kann, um dieses Event zu finden."
                            />
                        </Col>
                        <Col className="col-md-4">
                            <FormikSelect
                                name="eventCategory"
                                label="Eventart"
                                options={eventCategory}
                                onChange={changeEventCategory}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-md-4">
                            <FormikAsyncTypeaheadInput
                                id="artists"
                                key={refreshKey}
                                label="Künstler"
                                onSearch={query => isEventCategoryFilterApplied ? api.getArtistsByQuery(query, selectedEventCategory) : api.getArtistsByQuery(query)}
                                labelKey={o => o.name}
                                minLength={0}
                                options={null}
                                multiple={true}
                                helpText="Hier können mehrere Künstler hinzugefügt werden, geben Sie für die Suche die Künstler in das Feld ein."
                            />
                        </Col>
                        <Col className="col-md-4 align-self-center">
                            <FormikCheckbox
                                id="eventCategoryFilter"
                                label={"Nur aus aktueller Eventart (DE)"}
                                onChange={(e) => handleEventCategoryFilter(e.target.checked)}
                                className="d-inline"
                                checked={isEventCategoryFilterApplied}
                                name="disableVisualSelection"
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-md-4">
                            <FormikTextInputGroup label="Queue-it EventId" name="queueItEventId" inputRef={refs.queueItEventId} testid="queueItEventId"
                                                    helpText="Die ID muss auch in Queue-it eingetragen werden. Wenn das Feld leer ist, wird Queue-it nicht genutzt."/>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-md-4">
                            <FormikTextInputGroup label="Corona-Regeln" name="coronaRulesUrl" placeholder="https://"
                                                    helpText="Verlinkung zu den Corona-Regeln"/>
                        </Col>
                        <Col className="col-md-4">
                            <FormikTextInputGroup
                                label="Eventinformation (deutsch)"
                                name="description.de"
                                type="textarea"
                            />
                        </Col>
                        <Col className="col-md-4">
                            <FormikTextInputGroup
                                label="Eventinformation (englisch)"
                                name="description.en"
                                type="textarea"
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="pt-2 col-auto">
                            <FormikCheckbox label="Google Tracking aktivieren?"
                                            helpTextsVisible={helpTextsVisible}
                                            helpText="Hier können Sie Ihr Google Tracking aktivieren, wenn Sie das User-Verhalten mit Google im Frontend tracken wollen und eine Google Tagmanager Id hinterlegt haben."
                                            name="googleTrackingEnabled" disabled={!currentTenant.currentTenant.googleTagManagerId}/>
                        </Col>
                    </Row>
                </div>
                <div className={styles.formBox}>
                    <h2 className={styles.formTitle}>Spielstätte und Saalpläne</h2>
                    <HelpContent as="p" className="text-muted">Hier wird die Spielstätte des Events und das Spielstätten-Layout ausgewählt, darunter kann der Saalplan ausgewählt werden.</HelpContent>
                    <Row className="mt-3">
                        <Col className="col-md-4">
                            <FormikSelect
                                name="venue"
                                label="Spielstätte"
                                options={venue}
                                emptyValueLabel="Bitte wählen ..."
                                onChange={changeVenueSelection}
                            />
                        </Col>
                        <Col className="col-md-4">
                            <FormikSelect
                                name="venuePlan"
                                label="Saalplan"
                                options={venuePlanOptions}
                                emptyValueLabel="Bitte wählen ..."
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col className="col-md-8">
                            {formik.values.venue && formik.values.venue.id && venuePlanId ? (
                                <FeedbackButton to={`/base/venue/${formik.values.venue.id}/venue-plan/${venuePlanId}`}>
                                    Saalplan bearbeiten
                                </FeedbackButton>

                            ) : ''}
                        </Col>
                    </Row>
                </div>
                <div className={styles.formBox}>
                    <FormVenueEvents
                        metaEventStatus={metaEventStatus}
                        formik={formik}
                        refs={refs}
                    />
                </div>
            </div>
            <ListingModal
                show={isShowBatchModal}
                title="Bild wählen"
                initialValues={initialValues}
                validationSchema={formSchema}
                onCancel={closeModal}
                onHandleChooseImage={onHandleChooseImage}
            />
        </>
    )
};

FormData.propTypes = {
    submitPending: PropTypes.bool,
    formik: PropTypes.object,
    metaEvent: PropTypes.any,
    eventSeries: PropTypes.any,
    venue: PropTypes.any,
    eventCategory: PropTypes.any,
    venuePlan: PropTypes.any,
    refs: PropTypes.object,
    metaEventStatus: PropTypes.object
};

const mapStateToProps = (state, props) => {

    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps)(FormData);
