import React, { useRef, useState, useEffect } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { FormikValues, FormikErrors } from 'formik';
import {Col, Row} from 'react-bootstrap';
import ImagePreview from './ImagePreview/ImagePreview';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import styles from './ImagePreview/ImagePreview.module.scss'

interface UploadButtonProps {
    acceptedTypes: string[];
    onChange: (file: File | null, formik?: FormikValues) => void;
    deleteUploadFile: (formik?: FormikValues) => void;
    label: string;
    errors: FormikErrors<FormikValues>;
    validationPending: boolean;
    showPreview?: boolean;
    validFile: boolean | null;
    uploadProgress: number;
}

const UploadButton: React.FC<UploadButtonProps> = ({
    acceptedTypes,
    errors,
    label,
    onChange,
    validationPending,
    validFile,
    uploadProgress,
    deleteUploadFile,
    showPreview = false
}) => {
    const [previewImage, setPreviewImage] = useState<string | undefined>(undefined);
    const fileInput = useRef<HTMLInputElement>(null);

    const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        const selectedFile = event.target.files && event.target.files[0];

        if (selectedFile && selectedFile.type.includes('image')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result as string);
            };
            reader.readAsDataURL(selectedFile);
        } else {
            setPreviewImage(undefined);
        }

        if (onChange) {
            if (fileInput.current) {
                fileInput.current.value = '';
            }
            onChange(selectedFile);
        }
    };

    const clearPreview = () => {
        setPreviewImage(undefined);
        deleteUploadFile();
    };

    return (
        <span>
            <input
                type="file"
                ref={fileInput}
                accept={acceptedTypes.join(', ')}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <Button
                onClick={() => fileInput.current?.click()}
                sx={{ marginBottom: '20px', paddingRight: !showPreview ? '12px' : '24px' }}
                variant="contained"
            >
                {label}
                {validationPending && <CircularProgress size={20} />}
                {validFile && !showPreview && <CheckIcon  className='ml-2' />}
                {validFile !== null && !validFile && !showPreview && (
                    <CloseIcon className='ml-2' />
                )}
            </Button>
            {previewImage && showPreview && (
                uploadProgress ? (
                    <Row>
                        <Col className='col-md-4'>
                            <div className={styles.previewContainer}>
                                <CircularProgress size={30} />
                            </div>
                        </Col>
                    </Row>
                ) : (
                    !errors?.uploadError && (
                        <Row>
                            <Col className='col-md-4'>
                                <ImagePreview src={previewImage} handleClearPreview={clearPreview} showClose />
                            </Col>
                        </Row>
                    )
                )
            )}
        </span>
    );
};

export default UploadButton;
