import React, {useState} from 'react';
import ConnectedListView from '../../common/listView/ConnectedListView';
import {connect} from 'react-redux';
import FeedbackButton from '../../common/FeedbackButton';
import Modal from "react-bootstrap/Modal";
import {get} from "lodash";
import * as ZukoZone from "../../../state/entities/zukoZone";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import AddIcon from '@mui/icons-material/Add';
import styles from "../../form.module.scss";

const List = ({venue, helpTextsVisible, deleteZukoEvent}) => {
    const [isShowDeleteConfirmDialog, setIsShowDeleteConfirmDialog] = useState(false);
    const [zukoZoneToDelete, setZukoZoneToDelete] = useState(undefined);

    const showDeleteConfirmDialog = (zukoZone) => {
        setZukoZoneToDelete(zukoZone);
        setIsShowDeleteConfirmDialog(true);
    }
    const handleCancelDelete = () => {
        setIsShowDeleteConfirmDialog(false);
    };

    const handleConfirmDelete = () => {
        setIsShowDeleteConfirmDialog(false);
        deleteZukoEvent(zukoZoneToDelete);
    };

    return (
        <>
            <div className={styles.formBox}>
                <h2 className={styles.formTitle}>Zutrittszonen des Zutrittskontrollsystems</h2>
                <p className={!helpTextsVisible ? 'hidden' : styles.formDecription}>Tragen Sie hier bitte die in der Zuko vorliegenden Zutrittszonen ein,
                    die für Events in dieser Spielstätte grundsätzlich zur Verfügung stehen können.
                    Denken Sie daran, diese im Event-Kontext oder auf Blockebene zu verknüpfen.</p>

                <FeedbackButton
                    to={`/base/venue/${venue.id}/zuko-zone/create`}
                    icon={<AddIcon />}
                    variant='contained'
                >
                    Neue Zuko-Zone
                </FeedbackButton>
            </div>        

            <ConnectedListView endpoint={`listingView/zukoZone?venueId=${venue.id}`}>
                {({fields, item}) => (
                    <>
                        <FeedbackButton
                            className="list-link"
                            title="Bearbeiten"
                            to={`/base/venue/${venue.id}/zuko-zone/${item.id}`}
                            icon={<EditNoteOutlinedIcon className='table-icon' />}
                        />
                        <FeedbackButton
                            title="Löschen"
                            className="list-link"
                            onClick={() => showDeleteConfirmDialog(item)}
                        >
                            <DeleteOutlineOutlinedIcon className='table-icon' />
                        </FeedbackButton>
                    </>
                )}
            </ConnectedListView>

            <Modal show={isShowDeleteConfirmDialog} onHide={handleCancelDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>Zuko-Zone löschen</Modal.Title>
                </Modal.Header>
                <Modal.Body>Möchten Sie die Zuko-Zone "{get(zukoZoneToDelete, 'entry', '')}" wirklich löschen?</Modal.Body>
                <Modal.Footer>
                    <FeedbackButton variant="outlined" onClick={handleCancelDelete}>
                        Abbrechen
                    </FeedbackButton>
                    <FeedbackButton onClick={handleConfirmDelete}>
                        Löschen
                    </FeedbackButton>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default connect(null, {
    deleteZukoEvent: entity => ZukoZone.actions.delete({entity})
})(List);
