import {Point} from './types';

/**
 * Ein Raster von Punkt-Positionen.
 */
export class PointGrid {

    readonly rows: number;

    readonly columns: number;

    private readonly widthX: number;
    private readonly widthY: number;
    private readonly countX: number;
    private readonly countY: number;
    private readonly stepX: number;
    private readonly stepY: number;

    constructor(private readonly origin: Point, control: Point, private readonly step: number) {
        this.widthX = control.x - origin.x;
        this.widthY = control.y - origin.y;
        this.countX = Math.floor(Math.abs(this.widthX / this.step));
        this.countY = Math.floor(Math.abs(this.widthY / this.step));
        this.rows = this.countY + 1;
        this.columns = this.countX + 1;

        // Schritt mit "Richtung" berechnen.
        // Hier wird nicht Math.sign() verwendet, da diese Funktion nicht in allen Browsern verfügbar ist.
        this.stepX = this.step * (this.widthX / Math.abs(this.widthX));
        this.stepY = this.step * (this.widthY / Math.abs(this.widthY));

    }

    get points(): Iterable<Point> {
        return this.generatePoints();
    }

    private* generatePoints() {
        for (let x = 0; x <= this.countX; x++) {
            for (let y = 0; y <= this.countY; y++) {
                yield {
                    x: this.origin.x + x * this.stepX,
                    y: this.origin.y + y * this.stepY
                };
            }
        }
    }

    getPointsByRow(): Point[][] {
        const rows: Point[][] = [];
        for (let y = 0; y < this.rows; y++) {
            const points: Point[] = [];
            for (let x = 0; x < this.columns; x++) {
                const newPoint: Point = {
                    x: this.origin.x + x * this.stepX,
                    y: this.origin.y + y * this.stepY
                };
                points.push(newPoint);
            }
            rows.push(points);
        }
        return rows;
    }

}
