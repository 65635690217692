import React, {Component} from 'react';
import Form from "./Form";
import * as media from "../../state/entities/media";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Create extends Component {

    onSubmit = (media, {setErrors}) => {
        this.props.createMedia(media, setErrors);
    };

    render() {
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["media"]} endpoints = {['media/breadcrumb']} title = "Medien" links={['/base/media/']} active={[0]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Hier können Sie die Medien einstellen.</h1>
                </Col>
            </Row>
            <Form media={{}} onSubmit={this.onSubmit} submitPending={this.props.createPending} />
        </>;
    }
}

const mapStateToProps = (state) => {
    return {
        createPending: state.entities.media.createPending
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createMedia: entity => dispatch(media.actions.create({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
