import React, {useState} from 'react';
import * as yup from 'yup';
import {Formik} from 'formik';
import {Card, Col, Form as BootstrapForm, Row} from 'react-bootstrap';
import {FormikTextInputGroup} from '../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../common/FeedbackButton';
import {connect} from "react-redux";
import Footer from "../layout/Footer";
import {FormikCheckbox} from "../common/formik/FormikCheckbox";
import ConfirmModal from "../common/modal/ConfirmModal";
import styles from "../form.module.scss";

const getInitialValues = () => (
    {
        name: '',
        archived: false,
    }
);

const FORM_SCHEMA = yup.object().shape({
    name: yup.string().required(),
});

const Form = ({placePool, onSubmit, submitPending, helpTextsVisible}) => {
    const [isArchived, setIsArchived] = useState(placePool.archived);
    const ArchivedStatus = ({setFieldValue}) => {
        const confirmChangeStatus = () => () => {
            let newStatus = !isArchived
            setIsArchived(newStatus);
            setFieldValue('archived', newStatus);
        };
        return (
            <ConfirmModal title="Status ändern"
                          body={(isArchived) ? `Platzpool wieder reaktivieren?`: `Platzpool ins Archiv verschieben?`}
                          cancelLabel="Abbrechen"
                          confirmLabel="Bestätigen"
            >
                {confirm => (
                    <FormikCheckbox
                        id="archived"
                        label="Platzpool archivieren"
                        onChange={(e) => confirm(confirmChangeStatus(e))}
                        name="archived"
                        checked={isArchived}
                    />
                )}
            </ConfirmModal>
        )
    }


        return <Formik
            initialValues={{...getInitialValues(), ...placePool}}
            validationSchema={FORM_SCHEMA}
            onSubmit={onSubmit}
        >
            {formik => {

                return (
                    <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                        <div className={styles.formBox}>
                            <h2 className={styles.formTitle}>Allgemein</h2>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Titel des Platzpools*" name="name" testid="name"/>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <ArchivedStatus setFieldValue={formik.setFieldValue}/>
                            </Row>
                        </div>
                        <Footer>
                            <FeedbackButton to={`/base/place-pool`}>
                                Abbrechen
                            </FeedbackButton>
                            <FeedbackButton
                                type="submit"
                                busy={submitPending}
                            >
                                Speichern
                            </FeedbackButton>
                        </Footer>
                    </form>
                )
            }}
        </Formik>
};


Form.propTypes = {
    onSubmit: PropTypes.any,
    submitPending: PropTypes.any,
    blockGroup: PropTypes.any,
    requestErrors: PropTypes.any
};

const mapStateToProps = (state, props) => {

    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps, null)(Form);



