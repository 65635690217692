import React, {Component} from 'react';
import Form from "./Form";
import * as blockGroup from "../../../../state/entities/blockGroup";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import * as venuePlan from "../../../../state/entities/venuePlan";
import {LoadingIndicator} from "../../../common/LoadingIndicator";
import Breadcrumbs from "../../../common/breadcrumbs/Breadcrumbs";

class Create extends Component {

    componentDidMount() {
        this.props.loadVenuePlan(this.props.venuePlanId);
    }

    onSubmit = (blockGroup, {setErrors}) => {
        this.props.createBlockGroup(blockGroup, setErrors);
    };

    render() {
        const {venuePlan, venuePlanLoading, venuePlanId} = this.props;
        if (venuePlanLoading) {
            return <LoadingIndicator/>;
        }
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Spielstätte", "Saalplan", "Blockgruppe"]} endpoints = {['venue/breadcrumb', `venue/${venuePlan.venue.id}/venue_plan/breadcrumb`, `venue_plan/${venuePlan.id}/block_group/breadcrumb`]} title = "Blockgruppen" links={['/base/venue/', `/base/venue/${venuePlan.venue.id}/venue-plan/`, `/base/venue-plan/${venuePlan.id}/block-group/`]} active={[venuePlan.venue.id, venuePlan.id]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Erstellen einer neuen Blockgruppe für den Saalplan {venuePlan.name}</h1>
                </Col>
            </Row>
            <Form blockGroup={{}} onSubmit={this.onSubmit} submitPending={this.props.createPending} venuePlanId={venuePlanId}/>
        </>;
    }
}

const mapStateToProps = (state, props) => {
    const venuePlanId = props.match.params.vpid;
    const venuePlan = state.entities.venuePlan.byId[venuePlanId];
    return {
        venuePlanId,
        venuePlan,
        venuePlanLoading: !venuePlan || state.entities.venuePlan.loading[venuePlanId],
        createPending: state.entities.blockGroup.createPending
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createBlockGroup: entity => dispatch(blockGroup.actions.create({entity})),
        loadVenuePlan: id => dispatch(venuePlan.actions.load({id})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
