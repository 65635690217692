import {Card} from "react-bootstrap";
import React from "react";
import PaymentDetails from "./PaymentDetails";

const Payments = ({order}) => {
    return <>
        {order.payments && order.payments.map(payment => (
            <Card className="mt-4 mb-4" key={payment.id}>
                <Card.Body>
                    <h2 className="font-weight-bold pb-3 mb-3">Payment {payment.id}</h2>
                    <PaymentDetails payment={payment}/>
                </Card.Body>
            </Card>
        ))}
    </>
}

export default Payments;