import React, {useState} from 'react';
import * as yup from 'yup';
import {Formik} from 'formik';
import {Card, Col, Form as BootstrapForm, Row} from 'react-bootstrap';
import {FormikTextInputGroup} from '../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../common/FeedbackButton';
import {connect} from "react-redux";
import Footer from "../layout/Footer";
import {FormikCheckbox} from "../common/formik/FormikCheckbox";
import ConfirmModal from "../common/modal/ConfirmModal";
import styles from "../form.module.scss";

const getInitialValues = () => (
    {
        name: '',
        partnerAttribute: '',
        enabled: true,
        archived: false,
    }
);

const FORM_SCHEMA = yup.object().shape({
    name: yup.string().required(),
    partnerAttribute: yup.string().required(),
    enabled: yup.bool(),
    archived: yup.bool(),
});

const Form = ({targetGroup, onSubmit, submitPending, helpTextsVisible}) => {
    const [isArchived, setIsArchived] = useState(targetGroup.archived);
    const ArchivedStatus = ({setFieldValue}) => {
        const confirmChangeStatus = () => () => {
            let newStatus = !isArchived
            setIsArchived(newStatus);
            setFieldValue('archived', newStatus);
        };
        return (
            <ConfirmModal title="Status ändern"
                          body={(isArchived) ? `Personenpool wieder reaktivieren?`: `Personenpool ins Archiv verschieben?`}
                          cancelLabel="Abbrechen"
                          confirmLabel="Bestätigen"
            >
                {confirm => (
                    <FormikCheckbox
                        id="archived"
                        label="Personenpool archivieren"
                        onChange={(e) => confirm(confirmChangeStatus(e))}
                        name="archived"
                        checked={isArchived}
                    />
                )}
            </ConfirmModal>
        )
    }
        return <Formik
            initialValues={{...getInitialValues(), ...targetGroup}}
            validationSchema={FORM_SCHEMA}
            onSubmit={onSubmit}
        >
            {formik => {

                return (
                    <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                        <div className={styles.formBox}>
                            <h2 className={styles.formTitle}>Allgemein</h2>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Titel des Personenpools*" name="name" testid="name"
                                                            helpTextsVisible={helpTextsVisible} helpText="Hier können die Mappings der poolbildenden Partnerattribute welche aus der Plattform kommen, festgelegt werden. Beispiel: &quot;Mitglieder&quot;."/>
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Partnerattribut/Benutzergruppe" name="partnerAttribute" testid="partnerAttribute"
                                                            helpTextsVisible={helpTextsVisible} helpText="Hier können die Mappings der poolbildenden Partnerattribute oder Benutzergruppen welche aus der Plattform kommen, festgelegt werden.
Beispiel: &quot;et.partner.2.ticketing.pool.isAuthorizedToBuyMember&quot;. Benutzergruppen werden wie folgt benannt: &quot;et.partner.<group.partnerId der Plattform>.ticketing.pool.<group.uniqueIdentifier der Plattform>&quot;."/>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikCheckbox label="aktiviert" name="enabled" testid="enabled" helpText="Wenn deaktiviert, wird dieser Personenpool keinem Nutzer zugeordnet."/>
                                </Col>
                                <Col className="col-md-4">
                                    <ArchivedStatus setFieldValue={formik.setFieldValue}/>
                                </Col>
                            </Row>
                        </div>
                        <Footer>
                            <FeedbackButton to={`/base/target-group`}>
                                Abbrechen
                            </FeedbackButton>
                            <FeedbackButton
                                type="submit"
                                busy={submitPending}
                            >
                                Speichern
                            </FeedbackButton>
                        </Footer>
                    </form>
                )
            }}
        </Formik>
};


Form.propTypes = {
    onSubmit: PropTypes.any,
    submitPending: PropTypes.any,
    blockGroup: PropTypes.any,
    requestErrors: PropTypes.any
};

const mapStateToProps = (state, props) => {

    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps, null)(Form);



