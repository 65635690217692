import { Assets, Container, DisplayObject, Point, Rectangle, Sprite, Texture, Transform } from 'pixi.js';
import { ImageData } from './ImageData';
import { ModifyHandle } from '../editableVisuals/modifyHandle';
import { VisualItem } from '../editableVisuals/visualItem';



export class Image extends VisualItem<ImageData> {

    private handles: Array<ModifyHandle> = [];
    private imageContainer: Container;
    private imageWidth: number;
    private imageHeight: number;
    private isImageLoaded = false;
    private preciseRotation: number; //if real rotation is snapped
    private isCornerHandleDragging: boolean;


    constructor(
        imageData: ImageData,
        visualsContainer: Container,
        sensorsContainer: Container,
        mainHandlesContainer: Container,
        private onUpdateTransform: () => void, //make a whole render update to avoid PIXI lagging of worldTransform
        onSelect: (item: Image) => void, //when image wants to be selected
        onUpdated: (imageData: ImageData) => void, //when image is changed/updated internally
        private isShiftPressed: () => boolean  //callback if shift key is pressed
    ) {
        super(
            imageData,
            visualsContainer,
            sensorsContainer,
            mainHandlesContainer,
            onSelect,
            onUpdated
        );
        this.imageContainer = new Container();
        this.imageContainer.angle = imageData.rotation;
        this.visualContainer.position = {x: imageData.x, y: imageData.y};
        this.visualContainer.addChild(this.imageContainer);

        if (imageData.media.url) {
            Assets.load(imageData.media.url).then((texture) => {
                this.initializeInteractiveContainer(texture);
            });
        }
    }

    private initializeInteractiveContainer(texture: Texture) {
        this.isImageLoaded = true;
        const picture = new Sprite(texture);
        picture.anchor.set(0.5);
        this.imageWidth = texture.width;
        this.imageHeight = texture.height;
        this.imageContainer.addChild(picture);
        this.mouseSensor.hitArea = new Rectangle(-this.imageContainer.width / 2, -this.imageContainer.height / 2, this.imageContainer.width, this.imageContainer.height);
        for (var hi = 0; hi < 4; hi++) {
            const handle = new ModifyHandle(hi);
            handle.onMouseDown = (handle: ModifyHandle, event) => this.onCornerHandleDown(handle, event);
            this.handlesContainer.addChild(handle);
            this.handles.push(handle);
        }
        this.imageContainer.scale.x = this.imageContainer.scale.y = this.itemData.width / this.imageWidth;
        this.updateModifiersPositions();
    }


    getImageDO(): DisplayObject {
        return this.visualContainer;
    }

    reInsertInContainerAt(index: number) {
        this.visualContainer.parent.addChildAt(this.visualContainer, index);
        this.mouseSensor.parent.addChildAt(this.mouseSensor, index);
    }


    updateModifiersPositions() {
        if (!this.isImageLoaded) return;
        this.onUpdateTransform();
        let worldTransform: Transform = new Transform();
        this.imageContainer.worldTransform.decompose(worldTransform);
        this.handlesContainer.position = worldTransform.position;
        this.mouseSensor.position = worldTransform.position;
        this.handlesContainer.rotation = worldTransform.rotation;
        this.mouseSensor.rotation = worldTransform.rotation;
        this.mouseSensor.scale = worldTransform.scale;
        let imW = this.imageWidth * worldTransform.scale.x / 2;
        let imH = this.imageHeight * worldTransform.scale.x / 2;
        this.handles[0].position = new Point(-imW, -imH);
        this.handles[1].position = new Point(imW, -imH);
        this.handles[2].position = new Point(imW, imH);
        this.handles[3].position = new Point(-imW, imH);
        if (this.isSelected) {
            this.selectionMarquee.clear();
            this.selectionMarquee.lineStyle(5, 16777215, 1, 0.5, false);
            this.selectionMarquee.drawRect(-imW, -imH, 2 * imW, 2 * imH);
            this.selectionMarquee.lineStyle(1.5, 6724095, 1, 0.5, false);
            this.selectionMarquee.drawRect(-imW, -imH, 2 * imW, 2 * imH);
        }
    }


    private onCornerHandleDown(handle: ModifyHandle, event) {
        if (!this.isSelected) this.onSelect(this);
        this.isCornerHandleDragging = true;
        handle.onMouseMove = (handle: ModifyHandle, event) => this.onCornerHandleMove(handle, event);
        handle.onMouseUp = (handle: ModifyHandle, event) => this.onCornerHandleUp(handle, event);
    }


    private onCornerHandleMove(handle: ModifyHandle, event) {
        const pLocalMouse = this.visualContainer.toLocal(event.data.global);
        const mouseDistance = Math.sqrt(pLocalMouse.x * pLocalMouse.x + pLocalMouse.y * pLocalMouse.y);
        const imageCornerDistance = Math.sqrt(this.imageWidth / 2 * this.imageWidth / 2 + this.imageHeight / 2 * this.imageHeight / 2);
        const newScaleX = mouseDistance / imageCornerDistance;
        this.imageContainer.scale.x = this.imageContainer.scale.y = Math.max(0.01, newScaleX);

        const mouseRot = Math.atan2(pLocalMouse.y, pLocalMouse.x);
        let handleRotation;
        switch (handle.index) {
            case 0: handleRotation = Math.atan2(-this.imageHeight / 2, -this.imageWidth / 2);
                break;
            case 1: handleRotation = Math.atan2(-this.imageHeight / 2, this.imageWidth / 2);
                break;
            case 2: handleRotation = Math.atan2(this.imageHeight / 2, this.imageWidth / 2);
                break;
            case 3: handleRotation = Math.atan2(this.imageHeight / 2, -this.imageWidth / 2);
                break;
        }
        this.preciseRotation = mouseRot - handleRotation;
        this.updateRotationOnCornerDragging();
    }


    updateRotationOnCornerDragging() {
        //avoid jump back from 45°-snapped rotation even after mouse released:
        if (!this.isCornerHandleDragging) return;
        this.imageContainer.rotation = this.isShiftPressed()
            ? Math.round(this.preciseRotation / (Math.PI / 4)) * Math.PI / 4
            : this.preciseRotation;
        this.updateModifiersPositions();
    }


    private onCornerHandleUp(handle: ModifyHandle, event) {
        this.isCornerHandleDragging = false;
        handle.onMouseMove = handle.onMouseUp = null;
        this.itemData.width = this.imageWidth * this.imageContainer.scale.x;
        this.itemData.height = this.imageHeight * this.imageContainer.scale.x;
        this.itemData.rotation = this.imageContainer.angle;
        this.onUpdated(this.itemData);
    }
}
