import React from 'react';
import {Col, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {getIn} from "formik";
import Form from "react-bootstrap/Form";

const BundleFormSalesRule = ({index, element, removeSalesRuleHandle, salesRuleName, associationFormik}) => {
    const handleChangeMinVal = (e) => {
        let maxValue = parseInt(getIn(associationFormik.values, `salesRules[${index}].maxTickets`, 1));

        const regex = RegExp('^[0-9]*$');
        if (regex.exec(e.target.value) !== null && e.target.value !== "" && parseInt(e.target.value) <= maxValue) {
            associationFormik.setFieldValue(`salesRules[${index}].minTickets`, parseInt(e.target.value));
        }
    };
    const handleChangeMaxVal = (e) => {
        let minValue = parseInt(getIn(associationFormik.values, `salesRules[${index}].minTickets`, 1));

        const regex = RegExp('^[0-9]*$');
        if (regex.exec(e.target.value) !== null && e.target.value !== "" && parseInt(e.target.value) >= minValue) {
            associationFormik.setFieldValue(`salesRules[${index}].maxTickets`, parseInt(e.target.value));
        }
    };
    return (
        <div className="mt-3 bundle-form-sales-rule-item">
                <Row>
                    <Col className="col-md-11">
                        <h2>{salesRuleName}</h2>
                    </Col>
                    <Col className="col-md-1">
                        <span onClick={(salesRule, el) => {removeSalesRuleHandle(salesRule, el)}}>X</span>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-md-3">
                        <Form.Group>
                            <Form.Label>Min</Form.Label>
                            <Form.Control as="input"
                                          type="number"
                                          value={getIn(associationFormik.values, `salesRules[${index}].minTickets`, 1)}
                                          onChange={(e) => {handleChangeMinVal(e);}}
                                          name={`salesRules[${index}].minTickets`}
                                          min={1}
                            />
                        </Form.Group>

                    </Col>
                    <Col className="col-md-3">
                        <Form.Group>
                            <Form.Label>Max</Form.Label>
                            <Form.Control as="input"
                                          type="number"
                                          value={getIn(associationFormik.values, `salesRules[${index}].maxTickets`, 1)}
                                          onChange={(e) => {handleChangeMaxVal(e);}}
                                          name={`salesRules[${index}].maxTickets`}
                                          min={1}
                            />
                        </Form.Group>
                    </Col>
                </Row>
        </div>
    )
};

BundleFormSalesRule.propTypes = {
    index: PropTypes.number,
    element: PropTypes.object,
    removeSalesRuleHandle: PropTypes.func,
    salesRuleName: PropTypes.string,
};

export default BundleFormSalesRule;
