import React from 'react';
import { Router, Route, Switch} from 'react-router-dom';
import NotFound from './pages/NotFound';
import {Provider} from 'react-redux';
import store, {history} from '../state/configureStore';
import EventSeriesRouting from './eventSeries/Routing';
import VenueEventRouting from './venueEvent/Routing';
import MetaEventRouting from './metaEvent/Routing';
import PastMetaEventRouting from './metaEvent/PastMetaEventRouting';
import PastVenueEventRouting from "./venueEvent/PastVenueEventRouting";
import PricingListRouting from './pricingList/Routing';
import PricingCategoryRouting from './pricingCategory/Routing';
import PricingClassRouting from './pricingClass/Routing';
import PurchasableItemClassRouting from './purchasableItemTemplate/Routing';
import SalesRuleRouting from './salesRule/Routing';
import SeatingTypeRouting from './seatingType/Routing';
import BackendUserRouting from './backendUser/Routing';
import TenantRouting from './tenant/Routing';
import ArtistRouting from './artist/Routing';
import MediaRouting from './media/Routing';
import EventCategoryRouting from './eventCategory/Routing';
import OrderRouting from './order/Routing';
import ServiceCaseRouting from './serviceCase/Routing';
import PlacePoolRouting from './placePool/Routing';
import TargetGroupRouting from './targetGroup/Routing';
import TicketLayoutRouting from './ticketLayout/Routing';
import VenueRouting from './venue/Routing';
import { IntlProvider } from 'react-intl';
import SecureRoute from './auth/SecureRoute';
import Layout from "./layout/Layout";
import {AuthProvider} from "./auth/AuthProvider";
import Dashboard from "./dashboard/Dashboard";
import {Col, Row} from "react-bootstrap";
import GlobalErrorList from "./common/error/GlobalErrorList";
import GlobalMessagesList from "./common/success/GlobalMessagesList";
import { ThemeProvider } from '@mui/material/styles';
import { GlobalThemeMui } from './GlobalThemeMui';
import SeatingEditor from '../seating/editor/SeatingEditor';

const App = () => {
    return (
        <IntlProvider locale="de">
            <Provider store={store}>
                <ThemeProvider theme={GlobalThemeMui}>
                    <AuthProvider>
                        <Router history={history}>
                            <Layout>
                                <Row>
                                    <Col>
                                        <GlobalErrorList/>
                                        <GlobalMessagesList/>
                                    </Col>
                                </Row>
                                <Switch>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} exact path="/base/venue-plan/:vpid/seating/editor/ui" component={SeatingEditor} />
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/base/backend-user" component={BackendUserRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/base/seating-type" component={SeatingTypeRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/base/pricing-category" component={PricingCategoryRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/base/pricing-class" component={PricingClassRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/base/tenant" component={TenantRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/base/artist" component={ArtistRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/base/media" component={MediaRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/base/event-category" component={EventCategoryRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/base/place-pool" component={PlacePoolRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/base/target-group" component={TargetGroupRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/base/ticket-layout" component={TicketLayoutRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/base/venue" component={VenueRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/base/venue-plan" component={VenueRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/base/block-group" component={VenueRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/base/block" component={VenueRouting}/>

                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/event-management/event-series" component={EventSeriesRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/event-management/event" component={VenueEventRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/event-management/past-event" component={PastVenueEventRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/event-management/meta-event" component={MetaEventRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/event-management/past-meta-event" component={PastMetaEventRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/event-management/pricing-list" component={PricingListRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/event-management/purchasable-item-template" component={PurchasableItemClassRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/event-management/sales-rule" component={SalesRuleRouting}/>

                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/sales/order" component={OrderRouting}/>
                                    <SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/sales/service-case" component={ServiceCaseRouting}/>
                                    {/*<SecureRoute requiredRoles={['admin_admin', 'admin_super_admin']} path="/base/sales-rule" component={SalesRuleRouting}/>*/}
                                    { /* TODO: SecureRoute einsetzen */}
                                    <SecureRoute exact={true} requiredRoles={['admin_admin', 'admin_super_admin']} path="/" component={Dashboard}/>
                                    <Route component={NotFound}/>
                                </Switch>
                            </Layout>
                        </Router>
                    </AuthProvider>
                </ThemeProvider>
            </Provider>
        </IntlProvider>
    );
};

export default App;
