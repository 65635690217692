import { Container, DisplayObject, Graphics, Point } from "pixi.js";


export class CenterMarker extends Graphics {


    private readonly crossSize = 8;

    private guideLines: Graphics;


    constructor(
        private underlay: Container,
        private viewport: DisplayObject
    ) {
        super();
        this.lineStyle(1, 0x000000, 0.4, 1, true);
        this.moveTo(0, -this.crossSize);
        this.lineTo(0, this.crossSize);
        this.moveTo(-this.crossSize, 0);
        this.lineTo(this.crossSize, 0);

        this.guideLines = new Graphics();

        this.underlay.addChild(this.guideLines);
    }


    updatePosition(canvasWidth: number, canvasHeight: number) {
        this.position = (this.parent.toLocal(new Point(0, 0), this.viewport));
        this.guideLines.position = this.underlay.toLocal(this.position, this.parent);
        this.drawGuideLines(canvasWidth, canvasHeight);
    }

    private drawGuideLines(canvasWidth: number, canvasHeight: number) {
        this.guideLines.clear();
        this.guideLines.lineStyle(1, 0xDDDDDD, 1, 1, true);
        this.guideLines.moveTo(0, -this.guideLines.y);
        this.guideLines.lineTo(0, canvasHeight - this.guideLines.y);
        this.guideLines.moveTo(-this.guideLines.x, 0);
        this.guideLines.lineTo(canvasWidth - this.guideLines.x, 0);
    }
}
