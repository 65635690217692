import React, {Component} from 'react';
import Form from "./Form";
import * as seatingType from "../../state/entities/seatingType";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Create extends Component {

    onSubmit = (seatingType, {setErrors}) => {
        this.props.createSeatingType(seatingType, setErrors);
    };

    render() {
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Bestuhlungstyp"]} endpoints = {['seating_type/breadcrumb']} title = "Bestuhlungstypen" links={['/base/seating-type/']} active={[0]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Erstellen eines neuen Bestuhlungstyps</h1>
                </Col>
            </Row>
            <Form seatingType={{}} onSubmit={this.onSubmit} submitPending={this.props.createPending} />
        </>;
    }
}

const mapStateToProps = (state) => {
    return {
        createPending: state.entities.seatingType.createPending
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createSeatingType: entity => dispatch(seatingType.actions.create({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
