import React from 'react';
import {Col, Row} from "react-bootstrap";
import {FormattedDate} from "react-intl";
import Euro from "../common/numberFormat/Euro";

const RefundDetails = ({refund}) => {
    return (
        <div className="paymentRefund mt-4">
            <Row>
                <Col className="col-sm-3 col-lg-2">
                    Refund Reference
                </Col>
                <Col className="col-sm-9">
                    {refund.refundReference}
                </Col>
            </Row>
            <Row>
                <Col className="col-sm-3 col-lg-2">
                    Type
                </Col>
                <Col className="col-sm-9">
                    {refund.type}
                </Col>
            </Row>
            {refund.type === 'payone' && <Row>
                <Col className="col-sm-3 col-lg-2">
                    Payone refund id
                </Col>
                <Col className="col-sm-9">
                    {refund.payoneRefundId}
                </Col>
            </Row>}
            {refund.type === 'mollie' && <Row>
                <Col className="col-sm-3 col-lg-2">
                    Mollie refund id
                </Col>
                <Col className="col-sm-9">
                    {refund.mollieRefundId}
                </Col>
            </Row>}
            <Row>
                <Col className="col-sm-3 col-lg-2">
                    Amount
                </Col>
                <Col className="col-sm-9">
                    <Euro centAmount={refund.amount}/>
                </Col>
            </Row>
            <Row>
                <Col className="col-sm-3 col-lg-2">
                    Status
                </Col>
                <Col className="col-sm-9">
                    {refund.status}
                </Col>
            </Row>
            {refund.type === 'payone' && <Row>
                <Col className="col-sm-3 col-lg-2">
                    Payone status
                </Col>
                <Col className="col-sm-9">
                    {refund.payoneRefundStatus}
                </Col>
            </Row>}
            {refund.type === 'mollie' && <Row>
                <Col className="col-sm-3 col-lg-2">
                    Mollie status
                </Col>
                <Col className="col-sm-9">
                    {refund.mollieRefundStatus}
                </Col>
            </Row>}
            <Row>
                <Col className="col-sm-3 col-lg-2">
                    Status changes
                </Col>
                <Col className="col-sm-9">
                    {refund.statusChanges.map((statusChange, index) => <Row key={index}>
                        <Col>
                            <FormattedDate value={statusChange.occurred} year="numeric" month="2-digit"
                                           day="2-digit" hour="2-digit" minute="2-digit" second="2-digit"/>
                        </Col>
                        <Col>
                            {statusChange.status}
                        </Col>
                    </Row>)}
                </Col>
            </Row>
        </div>
    )
}

export default RefundDetails;
