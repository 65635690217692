import { Container } from "pixi.js";
import { VisualItem } from "./visualItem";
import { VisualItemData } from "./visualItemData";
import { cloneDeep } from "lodash";



export interface IVisualsManager<DataType extends VisualItemData> {
    initItems(items: DataType[]): void;
    addItem(newItem: DataType): void;
    getSelectedItem(): DataType;
    updateItems(dataItems: DataType[]): void;
    deleteSelectedItem(): void;
    onItemSelected: (item: DataType) => void;
    onItemsAdded: (items: DataType[]) => void;
    onItemsChanged: (items: DataType[]) => void;
    onItemsDeleted: (items: DataType[]) => void;
}



export abstract class VisualsManager<DOType extends VisualItem<DataType>, DataType extends VisualItemData> {

    protected sensorsContainer: Container;
    protected handlesContainer: Container;

    protected items: Array<DOType> = [];
    protected selectedItem: DOType = undefined;
    protected isEditMode = false;


    constructor(
        protected itemsContainer: Container,
        overlay: Container
    ) {
        this.sensorsContainer = new Container();
        this.handlesContainer = new Container();
        overlay.addChild(this.sensorsContainer, this.handlesContainer);
        this.setEditMode(false);
    }


    updatePositions() {
        if (!this.isEditMode) return;
        for (const item of this.items) item.updateModifiersPositions();
    }


    setEditMode(editMode: boolean) {
        this.isEditMode = editMode;
        this.updatePositions();
        this.sensorsContainer.visible = this.handlesContainer.visible = editMode;
    }


    private handleSelectItem(item: DOType) {
        this.selectedItem?.setSelected(false);
        this.selectedItem = item;
        this.selectedItem?.setSelected(true);
        if (this.onItemSelected) this.onItemSelected(this.selectedItem?.getItemData());
    }


    initItems(dataItems: DataType[]) {
        for (let di = 0; di < dataItems.length; di++) this.createVisualItem(dataItems[di], di);
        if (!this.selectedItem && this.items.length) this.handleSelectItem(this.items[0]);
    }


    addItem(dataItem: DataType) {
        const newItem = this.createVisualItem(dataItem, this.items.length);
        this.handleSelectItem(newItem);
        if (this.onItemsAdded) this.onItemsAdded([dataItem]);
    }


    private createVisualItem(dataItem: DataType, index: number): DOType {
        const newDataItem = cloneDeep(dataItem);  //clone redux object to make it mutable
        const newItem = this.visualItemFactory(
            newDataItem,
            index,
            this.itemsContainer,
            this.sensorsContainer,
            this.handlesContainer,
            (item) => this.handleSelectItem(item),
            (itemData) => this.onItemUpdated(itemData)
        );
        this.items.push(newItem);
        return newItem;
    }


    protected abstract visualItemFactory(
        dataItem: DataType,
        index: number,
        itemsContainer: Container,
        sensorsContainer: Container,
        handlesContainer: Container,
        onSelectItem: (item: DOType) => void,
        onItemChanged: (itemData: DataType) => void
    ): DOType;


    private onItemUpdated(itemData: DataType) {
        if (this.onItemsChanged) this.onItemsChanged([itemData]);
    }


    getSelectedItem(): DataType {
        return this.selectedItem?.getItemData();
    }


    updateItems(dataItems: DataType[]) {
        for (let item of this.items) {
            const dataItem = dataItems.find(dataItem => dataItem.id === item.getItemData().id);
            if (dataItem) item.updateData(dataItem);
        }
    }


    deleteSelectedItem() {
        const delItem = this.selectedItem;
        const delItemIndex = this.items.indexOf(delItem);
        if (delItemIndex < 0) return;
        this.items.splice(delItemIndex, 1);
        const delData = delItem.getItemData();
        delItem.dispose();
        this.handleSelectItem(this.items.length ? this.items[0] : null);
        if (this.onItemsDeleted) this.onItemsDeleted([delData]);
    }

    onItemSelected: (dataItem: DataType) => void;
    onItemsAdded: (dataItems: DataType[]) => void;
    onItemsChanged: (dataItems: DataType[]) => void;
    onItemsDeleted: (dataItems: DataType[]) => void;
}
