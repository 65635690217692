import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import ConnectedListView from '../../../common/listView/ConnectedListView';
import FeedbackButton from '../../../common/FeedbackButton';
import {connect} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {get} from 'lodash';
import * as block from "../../../../state/entities/block";
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import AddIcon from '@mui/icons-material/Add';

class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDeleteConfirmDialog: false,
            blockToDelete: undefined,
        }
    }

    showDeleteConfirmDialog(block) {
        this.setState({
            showDeleteConfirmDialog: true,
            blockToDelete: block,
        });
    }

    handleCancelDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
    };

    handleConfirmDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
        this.props.deleteBlock(this.state.blockToDelete);
    };

    render() {
        return (
            <>
                <Row className="mb-4 mt-4">
                    <Col className="pt-3 col-sm-8">
                        <h1>Blöcke für den Saalplan <em>{this.props.venuePlan.name}</em></h1>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="form-group">
                            <FeedbackButton
                                to={`/base/venue-plan/${this.props.venuePlanId}/block/standing/create`}
                                icon={<AddIcon />}
                                variant='contained'
                            >
                                Neuer Stehplatzblock
                            </FeedbackButton>
                        </div>
                        <div className="form-group">
                            <FeedbackButton
                                to={`/base/venue-plan/${this.props.venuePlanId}/block/seating/create`}
                                icon={<AddIcon />}
                                variant='contained'
                            >
                                Neuer Sitzplatzblock
                            </FeedbackButton>
                        </div>
                    </Col>
                </Row>
                <ConnectedListView endpoint={`listingView/block?venuePlanId=${this.props.venuePlanId}`} card_testid="blockList">
                    {({fields, item}) => (
                        <>
                            {item.blockType === 'seating' ? (
                                <FeedbackButton
                                    className="list-link"
                                    title="Sitzplatzblock bearbeiten"
                                    to={`/base/venue-plan/${this.props.venuePlanId}/block/seating/${item.id}`}
                                    icon={<EditNoteOutlinedIcon className='table-icon' />}
                                />
                            ) : (
                                <FeedbackButton
                                    className="list-link"
                                    title="Stehplatzblock bearbeiten"
                                    to={`/base/venue-plan/${this.props.venuePlanId}/block/standing/${item.id}`}
                                    icon={<EditNoteOutlinedIcon className='table-icon' />}
                                />
                            )}

                            <FeedbackButton title="Block löschen" className="list-link" onClick={() => this.showDeleteConfirmDialog(item)}>
                                <DeleteOutlineOutlinedIcon className='table-icon' />
                            </FeedbackButton>
                        </>
                    )}
                </ConnectedListView>

                <Modal show={this.state.showDeleteConfirmDialog} onHide={this.handleCancelDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Block löschen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Möchten Sie den Block "{get(this.state, 'blockToDelete.label')}" wirklich löschen?</Modal.Body>
                    <Modal.Footer>
                        <FeedbackButton variant="outlined" onClick={this.handleCancelDelete}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton onClick={this.handleConfirmDelete}>
                            Löschen
                        </FeedbackButton>
                    </Modal.Footer>
                </Modal>

            </>
        );
    }
}


export default connect(null, {
    deleteBlock: entity => block.actions.delete({entity})
})(List);
