import {createSlice} from 'redux-starter-kit';

/**
 * Redux Slice for the togglebutton that shows and hides help texts.

 */

const helpTextsToggle = createSlice({
    slice: 'helpTextsToggle',
    initialState: false,
    reducers: {
        toggle: state => !state
    }
});

export const actions = {
    ...helpTextsToggle.actions
};

export const reducer = helpTextsToggle.reducer;












