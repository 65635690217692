import React, {Component} from 'react';
import * as yup from 'yup';
import {Formik} from 'formik';
import {Card, Col, Row} from 'react-bootstrap';
import {FormikTextInputGroup} from '../../../../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../../../../common/FeedbackButton';
import FormikObjectSelectMui from '../../../../common/formik/FormikObjectSelectMui';
import {connect} from "react-redux";
import {actions as SeatingTypeActions} from "../../../../../state/entities/seatingType";
import FormikAsyncTypeaheadInput from "../../../../common/formik/FormikAsyncTypeaheadInput";
import Api from '../../../../../api';
import BackButton from "../../../../common/BackButton";
import Footer from "../../../../layout/Footer";
import styles from "../../../../form.module.scss";
import {isUndefined} from "lodash";

const api = new Api();


const getInitialValues = (block, defaultSeatingType) => ({
    pricingCategory: '',
    seatingType: !isUndefined(defaultSeatingType) ? defaultSeatingType : '',
    fromRow: '',
    toRow: '',
    fromSeat: '',
    toSeat: '',
    block: block,
});

const FORM_SCHEMA = yup.object().shape({
    seatingType: yup.object().required(),
    fromRow: yup.number().required(),
    toRow: yup.number().required(),
    fromSeat: yup.number().required(),
    toSeat: yup.number().required(),
    pricingCategory: yup.object().required(),
});


class FormCreate extends Component {
    componentDidMount() {
        this.props.loadSeatingType();
        // Wir wollen diesen Effekt explizit nur einmal "on-mount" durchführen, daher geben wir [] als dependency an.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    render() {
        let {onSubmit, submitPending, seat, seatingTypes, block} = this.props;
        let defaultSeatingType = seatingTypes.find(seatingType => seatingType.default === true);
        return <Formik initialValues={{...getInitialValues(block, defaultSeatingType), ...seat}}
                       validationSchema={FORM_SCHEMA}
                       onSubmit={onSubmit}
                       enableReinitialize
        >
            {formik => {
                return (
                    <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                        <div className={styles.formBox}>
                            <h2 className={styles.formTitle}>Allgemein</h2>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikAsyncTypeaheadInput
                                        id="pricingCategory"
                                        label="Preiskategorie"
                                        onSearch={query => api.getPricingCategoriesByQuery(query)}
                                        labelKey={o => o.name}
                                        minLength={0}
                                        testid="pricingCategory"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Von Reihe*" name="fromRow" testid="fromRow"/>
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="bis Reihe*" name="toRow" testid="toRow"/>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Von Sitznummer*" name="fromSeat" testid="fromSeat"/>
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="bis Sitznummer*" name="toSeat" testid="toSeat"/>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikObjectSelectMui
                                        name="seatingType"
                                        label="Bestuhlungstyp"
                                        options={seatingTypes}
                                        testid="seatingType"
                                        emptyValueLabel="Bitte wählen ..."
                                    />
                                </Col>
                            </Row>
                        </div>
                        <Footer>
                            {/*
                            FIXME: auf Grund der aktuellen Routenstruktur ist es hier nicht möglich den Link Button
                            zu verwenden um in die Edit-Seite eines Saalplans/Listing der Blockgruppen zu gelangen,
                            da die Venue-ID hier nicht bekannt ist und sowohl die VenuePlan-ID als auch die
                            BlockGroup-ID aus der URL gepuhlt werden.
                            Der Browserback ist nicht in allen Fällen korrekt (bspw. nicht bei externen Einsprüngen)
                            und die Problematik sollte im Zuge der "Breadcrums" behoben werden.
                            */}
                            {/*<LinkButton variant="link" to={{pathname: `/base/venue/${venueId}/venue-plan/${venuePlanId}`}} >*/}
                            {/*    Abbrechen*/}
                            {/*</LinkButton>*/}
                            <BackButton>
                                Abbrechen
                            </BackButton>
                            <FeedbackButton
                                type="submit"
                                busy={submitPending}
                            >
                                Speichern
                            </FeedbackButton>
                        </Footer>
                    </form>
                )
            }}
        </Formik>
    }
}

FormCreate.propTypes = {
    onSubmit: PropTypes.any,
    submitPending: PropTypes.any,
    seat: PropTypes.any,
    requestErrors: PropTypes.any
};

export default connect((state, ownProps) => {
    let seatingTypes = Object.values(state.entities.seatingType.byId);
    const helpTextsVisible = state.helpTextsToggle;
    return {
        seatingTypes,
        helpTextsVisible
    }
}, {
    loadSeatingType: SeatingTypeActions.loadAll
})(FormCreate)


