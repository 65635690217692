import {call, put} from "@redux-saga/core/effects";
import {normalize} from "normalizr";

/**
 * Saga für die Sitzplatzerstellung
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export const batchSaga = ({actions, resource, schema, onSuccess}) => function* ({payload: {seats}}) {
    try {
        const result = yield call(resource.batch, seats);
        const response = normalize(result, schema);

        yield put(actions.batchSuccess({response}));

        if (onSuccess) {
            yield onSuccess(result)
        }
    } catch (error) {
        yield put(actions.batchError({seats, error}));
    }
};
