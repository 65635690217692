import { queryListView } from '../../../state/entities/listView/actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ListView from './ListView';

import { defaults, find } from 'lodash';
import { PAGINATION_WINDOW_SIZE_DEFAULT } from "./ListViewPaginationControl";

class ConnectedListView extends ListView {

    componentDidMount() {
        const {filter, pagination, sort} = this.props;

        // Bei Anzeige der Liste im immer eine neue Query lostreten, neue/geänderte im Hintergund geladen
        // und dann angezeigt werden, selbst wenn wir bereits eine letzten Stand aus dem State haben.
        this.props.queryListView({
            filter,
            pagination,
            sort
        });
    }

    componentDidUpdate(prevProps) {
        const { filter, pagination, sort, refreshListView } = this.props;

        if (refreshListView !== prevProps.refreshListView) {
            this.props.queryListView({
                filter,
                pagination,
                sort
            });
        }
    }
}

ConnectedListView.propTypes = {
    endpoint: PropTypes.string.isRequired,
    isShowSelectionWithBatchActions: PropTypes.bool,
    allowedActions: PropTypes.array,
};

const mapStateToProps = (state, ownProps) => {
    const query = defaults(state.entities.listView.query[ownProps.endpoint], {
        filter: {},
        pagination: {
            currentPage: 1,
            totalPages: 1,
            maxPerPage: PAGINATION_WINDOW_SIZE_DEFAULT
        },
        sort: {}
    });
    const {fields, items} = defaults(state.entities.listView.result[ownProps.endpoint], {
        fields: [],
        items: [],
    });

    const loading = state.entities.listView.loading[ownProps.endpoint];

    let entity = ownProps.endpoint.split('/')[1];

    const archived = 'archived'
    if (entity.indexOf(archived) === 0) {
        entity = entity.charAt(archived.length).toLowerCase() + entity.slice(archived.length + 1);
    }

    const isDeleting = find((state.entities[entity]?.deleting || {}), v => v === true) ?? false;

    const isShowSelectionWithBatchActions = ownProps.isShowSelectionWithBatchActions || false;
    const allowedActions = ownProps.allowedActions || [];

    return {
        ...query,
        fields,
        items,
        loading,
        isDeleting,
        isShowSelectionWithBatchActions,
        allowedActions,
        entity
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        queryListView: query => dispatch(queryListView(ownProps.endpoint, query)),
    }
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        onPageChange(page, maxPerPage) {
            return dispatchProps.queryListView({
                filter: stateProps.filter,
                sort: stateProps.sort,
                pagination: {
                    ...stateProps.pagination,
                    maxPerPage,
                    currentPage: page
                }
            });
        },
        onSortChange(sort) {
            return dispatchProps.queryListView({
                filter: stateProps.filter,
                sort,
                pagination: stateProps.pagination
            });
        },
        onFilterChange(filter) {
            return dispatchProps.queryListView({
                filter: filter,
                sort: stateProps.sort,
                pagination: {
                    ...stateProps.pagination,
                    currentPage: 1
                }
            });
        },
        showAllHandle() {
            return dispatchProps.queryListView({
                filter: stateProps.filter,
                sort: stateProps.sort,
                pagination: {
                    ...stateProps.pagination,
                    currentPage: 1,
                    maxPerPage: -1
                }
            });
        },
        reloadView() {
            return dispatchProps.queryListView({
                filter: stateProps.filter,
                sort: stateProps.sort,
                pagination: stateProps.pagination
            });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ConnectedListView);
