import React, {useState} from 'react';
import styles from "../layoutEditor.module.scss";
import PropTypes from "prop-types";

const Triangle = ({element, editModeEnabled, updateElement}) => {
    const [color, setColor] = useState(element.color);

    const updateColor = (e) => {
        setColor(e.target.value);
    };

    const onBlur = () => {
        updateElement({...element, color: color});
    };

    return <div className={`${styles.LayoutElementInner} ${styles.Rectangle} ${!editModeEnabled ? "interactive" : ""}`}
                style={{
                    width: element.width + "px",
                    height: element.height + "px",
                }}
    >
        {editModeEnabled && <input type="color" onChange={updateColor} onBlur={onBlur}/>}

        <svg height="100%" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
            <polygon points="0,0 100,50 0,100" style={{
                strokeWidth: 0,
                fill: color,
                stroke: color,
            }}></polygon>
        </svg>
    </div>;
};

Triangle.propTypes = {
    element: PropTypes.object.isRequired,
    editModeEnabled: PropTypes.bool,
    updateElement: PropTypes.func.isRequired,
};

export default Triangle;
