import {Card, Col, Row} from "react-bootstrap";
import ConnectedListView from "../common/listView/ConnectedListView";
import React from "react";

const Invoices = ({order}) => {
    return <>
        <Row>
            <Col className="mt-4 col-12">
                <Card className="mt-4 mb-4">
                    <Card.Body>
                        <h2 className="font-weight-bold pb-3 mb-3">
                            <span>Rechnungen/ Gutschriften</span>
                        </h2>
                        <ConnectedListView endpoint={`listingView/invoice?orderId=${order.id}`} />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
}

export default Invoices;