import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import * as yup from 'yup';
import FeedbackButton from "../../components/common/FeedbackButton";
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogTitle, DialogContent, Grid, Typography } from "@mui/material";
import { IState, selectNewAddedSeats, selectPlaceCategories, selectSeatingBlocks, selectSeatingTypes, selectSeatsByBlockAndRow } from "../../state/entities/venueEditor/selectors";
import { Seat, SeatRecord, SeatStyle, SortDirection } from "../types";
import { PointGrid } from "../editor/geometry";
import { SEAT_DEFAULT_COLOR } from "../editor/display/scene";
import ObjectID from "bson-objectid";
import LoadingDialog from "../LoadingDialog";
import { Formik, Form, FormikProps, FormikErrors } from "formik";
import FormikSelect from "../../components/common/formik/FormikSelect";
import { FormikTextInputGroup } from "../../components/common/formik/FormikTextInputGroup";

interface IAddSeatsDialogProps {
    open: boolean;
    pointGrid: Readonly<PointGrid>;
    onConfirm: (newSeats: SeatRecord) => void;
    onCancel: () => void;
}


interface IFormValues {
    placeCategoryId: string;
    seatingTypeId: string;
    blockId: string;
    rowOrder: SortDirection,
    rowStartAt: number;
    seatOrder: SortDirection,
    seatStartAt: number;
    changedSeats: Seat[][];
}

const SORT_SELECT_VALUES = [
    { name: 'aufsteigend', id: SortDirection.ASCENDING },
    { name: 'absteigend', id: SortDirection.DESCENDING }
]

interface IFormErrors extends FormikErrors<IFormValues> {
    general?: string;
}


const validationSchema = yup.object({
    placeCategoryId: yup.string().required('Preiskategorie ist erforderlich'),
    seatingTypeId: yup.string().required('Bestuhlungstyp ist erforderlich'),
    blockId: yup.string().required('Blockbezeichnung ist erforderlich'),
    rowStartAt: yup.number().min(0, 'Start muss größer/gleich 0 sein').required('Reihen-Start ist erforderlich'),
    seatStartAt: yup.number().min(0, 'Start muss größer/gleich 0 sein').required('Sitznummern-Start ist erforderlich'),
});


const AddSeatsDialog: React.FC<IAddSeatsDialogProps> = ({ open, pointGrid, onConfirm, onCancel }) => {
    const placeCategories = useSelector((state: IState) => selectPlaceCategories(state));
    const seatingTypes = useSelector((state: IState) => selectSeatingTypes(state));
    const seatBlocks = useSelector((state: IState) => selectSeatingBlocks(state));
    const seatsbyBlockAndRow = useSelector((state: IState) => selectSeatsByBlockAndRow(state));
    const newAddedSeats = useSelector((state: IState) => selectNewAddedSeats(state));
    const [isLoaderShowing, setIsLoaderShowing] = useState(false);
    const seatCount = pointGrid ? Array.from(pointGrid.points).length : 0;

    const generateSeatRows = (values: IFormValues) => {
        const { rowOrder, rowStartAt, seatOrder, seatStartAt, placeCategoryId, seatingTypeId, blockId } = values;
        const pointsByRow = pointGrid.getPointsByRow();
        return pointsByRow.map((row, rowIndex) => {
            return row.map((point, pointIndex) => {
                return {
                    id: ObjectID.generate(),
                    blockId: blockId,
                    row: (rowStartAt + rowIndex * (rowOrder === SortDirection.ASCENDING ? 1 : -1)).toString(),
                    label: (seatStartAt + pointIndex * (seatOrder === SortDirection.ASCENDING ? 1 : -1)).toString(),
                    seatingTypeId: seatingTypeId,
                    pricingCategoryId: placeCategoryId,
                    color: SEAT_DEFAULT_COLOR,
                    x: point.x,
                    y: point.y,
                    enabled: true,
                    available: true,
                    tags: [],
                    style: 'AVAILABLE' as SeatStyle,
                };
            });
        });
    };


    const initialValues = useMemo(() => {
        const values = {
            placeCategoryId: placeCategories[0]?.id || '',
            seatingTypeId: seatingTypes.find(seatingType => seatingType.default)?.id || seatingTypes[0]?.id || '',
            blockId: seatBlocks[0]?.id || '',
            rowOrder: SortDirection.ASCENDING,
            rowStartAt: 1,
            seatOrder: SortDirection.ASCENDING,
            seatStartAt: 1,
            changedSeats: []
        } as IFormValues;
        if (pointGrid) values.changedSeats = generateSeatRows(values);
        return values;
    }, [placeCategories, seatingTypes, seatBlocks, pointGrid]);


    useEffect(() => {
        if (newAddedSeats.length) setIsLoaderShowing(false);
    }, [newAddedSeats])


    const handleFieldChange = (fieldName: string, value: any, formik: FormikProps<any>) => {
        formik.setFieldValue(fieldName, value).then(() => {
            const newValues = { ...formik.values, [fieldName]: value } as IFormValues;
            formik.setFieldValue('changedSeats', generateSeatRows(newValues))
                .then(() => {
                    formik.validateForm();
                });
            if (fieldName === 'rowOrder'
                && newValues.rowOrder === SortDirection.DESCENDING
                && newValues.rowStartAt < newValues.changedSeats?.length
            ) formik.setFieldValue('rowStartAt', newValues.changedSeats?.length);
            if (fieldName === 'seatOrder'
                && newValues.seatOrder === SortDirection.DESCENDING
                && newValues.seatStartAt < newValues.changedSeats[0]?.length
            ) formik.setFieldValue('seatStartAt', newValues.changedSeats[0]?.length);
        });
    }


    const validateSeats = (values: IFormValues): IFormErrors => {
        let errors: IFormErrors = {};
        values.changedSeats = generateSeatRows(values);
        const errorLines = [] as string[];
        let negativeErrorFound = false;
        let collisionErrorFound = false;
        for (let row of values.changedSeats) {
            for (let seat of row) {
                if (!negativeErrorFound && (Number(seat.row) < 0 || Number(seat.label) < 0)) {
                    errorLines.push('Einstellung erzeugt negative Werte.');
                    negativeErrorFound = true;
                }
                if (!collisionErrorFound
                    && seatsbyBlockAndRow[seat.blockId]
                    && seatsbyBlockAndRow[seat.blockId][seat.row]
                    && seatsbyBlockAndRow[seat.blockId][seat.row].some((s: Seat) => s.label === seat.label)
                ) {
                    errorLines.push('Kombi aus Sitz-/Reihenbezeichnung existiert bereits.');
                    collisionErrorFound = true;
                }
            }
        }
        if (errorLines.length) errors.general = errorLines.join('\n');
        return errors;
    };


    const handleSubmit = (values: IFormValues, actions) => {
        setIsLoaderShowing(true);
        const newSeats = values.changedSeats.flat().reduce((acc: Record<string, Seat>, seat: Seat) => {
            acc[seat.id] = seat;
            return acc;
        }, {});
        onConfirm(newSeats);
    }

    return (
        <Dialog open={open} fullWidth={true} maxWidth="md">
            <DialogTitle>{seatCount === 1 ? 'Einen Sitz' : seatCount + ' Sitze'} hinzufügen</DialogTitle>
            <Formik<IFormValues>
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                validate={validateSeats}
                validateOnChange
                enableReinitialize
            >
                {(formik: FormikProps<IFormValues>) => {
                    const highestRowInBlock = Object.keys(seatsbyBlockAndRow[formik.values.blockId] ?? {}).reduce(
                        (max, current) => Math.max(parseInt(current), max),
                        0
                    );

                    return (
                        <>
                            <Form onSubmit={formik.handleSubmit}>
                                <DialogContent>
                                    <Grid container direction="column">
                                        <Grid item container columns={3} spacing={2} sx={{ marginBottom: 2 }}>
                                            <Grid item md={1}>
                                                <FormikSelect
                                                    name="placeCategoryId"
                                                    label="Preiskategorie"
                                                    onChange={(value) => handleFieldChange('placeCategoryId', value, formik)}
                                                    options={placeCategories}
                                                />
                                            </Grid>
                                            <Grid item md={1}>
                                                <FormikSelect
                                                    name="seatingTypeId"
                                                    label="Bestuhlungstyp"
                                                    onChange={(value) => handleFieldChange('seatingTypeId', value, formik)}
                                                    options={seatingTypes}
                                                />
                                            </Grid>
                                            <Grid item md={1}>
                                                <FormikSelect
                                                    name="blockId"
                                                    label="Blockbezeichnung"
                                                    onChange={(value) => handleFieldChange('blockId', value, formik)}
                                                    options={seatBlocks.map(block => ({ name: block.backendNameShort, id: block.id }))}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container columns={11} alignItems="center" spacing={2} sx={{ marginBottom: 2 }}>
                                            <Grid item md={5}>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item container spacing={2}>
                                                        <Grid item md={7}>
                                                            <FormikSelect
                                                                label={`Reihenlabel (${formik.values.changedSeats.length})`}
                                                                name="rowOrder"
                                                                onChange={(value) => handleFieldChange('rowOrder', value, formik)}
                                                                options={SORT_SELECT_VALUES}
                                                                isShowEmptyValue={false}
                                                            />
                                                        </Grid>
                                                        <Grid item md={5}>
                                                            <FormikTextInputGroup
                                                                label="beginnen bei"
                                                                name="rowStartAt"
                                                                placeholder="z.B. 10"
                                                                type="number"
                                                                onChange={(value) => handleFieldChange('rowStartAt', value, formik)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={1} />
                                            <Grid item md={5}>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item container spacing={2}>
                                                        <Grid item md={7}>
                                                            <FormikSelect
                                                                name="seatOrder"
                                                                label={`Sitzlabel (${formik.values.changedSeats[0]?.length ?? 0})`}
                                                                onChange={(value) => handleFieldChange('seatOrder', value, formik)}
                                                                options={SORT_SELECT_VALUES}
                                                                isShowEmptyValue={false}
                                                            />
                                                        </Grid>
                                                        <Grid item md={5}>
                                                            <FormikTextInputGroup
                                                                label="beginnen bei"
                                                                name="seatStartAt"
                                                                placeholder="z.B. 10"
                                                                type="number"
                                                                onChange={(value) => handleFieldChange('seatStartAt', value, formik)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Typography>
                                            Aktuell höchste, bereits genutzte Reihe in ausgewähltem Block: {highestRowInBlock}
                                        </Typography>
                                        {(formik.errors as IFormErrors).general && (  //typecast, since i didn't geht IFormErrors into Formik
                                            <Typography style={{
                                                fontSize: '0.75rem',
                                                marginTop: '3px',
                                                textAlign: 'right',
                                                color: '#B3261E',
                                                whiteSpace: 'pre-line'
                                            }}>
                                                {(formik.errors as IFormErrors).general}
                                            </Typography>
                                        )}
                                    </Grid>
                                </DialogContent>
                            </Form>
                            <DialogActions>
                                <FeedbackButton onClick={onCancel} variant="outlined">Abbrechen</FeedbackButton>
                                <FeedbackButton onClick={() => formik.submitForm()} type="submit" disabled={!formik.isValid || formik.isSubmitting}>
                                    Hinzufügen
                                </FeedbackButton>
                            </DialogActions>
                        </>
                    )
                }}
            </Formik>
            <LoadingDialog message="Sitzplätze werden erzeugt." isLoading={isLoaderShowing && open} />
        </Dialog>
    );
};


export default AddSeatsDialog;
