import {schema} from 'normalizr';

export const purchasableItemTemplate = new schema.Entity('purchasableItemTemplate');

export const pricingCategory = new schema.Entity('pricingCategory');
export const pricingClass = new schema.Entity('pricingClass');
export const pricingMatrix = new schema.Entity('pricingMatrix');

export const venueEditor = new schema.Entity('venueEditor');

export const salesRule = new schema.Entity('salesRule');

export const placePool = new schema.Entity('placePool');
export const targetGroup = new schema.Entity('targetGroup');
export const ticketLayout = new schema.Entity('ticketLayout');

export const pricingList = new schema.Entity('pricingList', {
    pricingMatrix
});

export const eventSeries = new schema.Entity('eventSeries');
export const venueEvent = new schema.Entity('venueEvent', {
    pricingList
});
export const metaEvent = new schema.Entity('metaEvent', {
    pricingList
});

export const backendUser = new schema.Entity('backendUser');

export const seatingType = new schema.Entity('seatingType');

export const tenant = new schema.Entity('tenant');

export const artist = new schema.Entity('artist');

export const media = new schema.Entity('media');

export const eventCategory = new schema.Entity('eventCategory');

export const venue = new schema.Entity('venue');

export const venuePlan = new schema.Entity('venuePlan');
export const venuePlanList = new schema.Array(venuePlan);

export const zukoZone = new schema.Entity('zukoZone');
export const zukoZoneList = new schema.Array(zukoZone);

export const block = new schema.Entity('block');
export const blockList = new schema.Array(block);

export const blockGroup = new schema.Entity('blockGroup', {
    blocks: blockList
});

export const seat = new schema.Entity('seat');

export const order = new schema.Entity('order');
export const cartItem = new schema.Entity('cartItem');

