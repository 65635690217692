import {isEmpty, keys, some, values} from "lodash";

export const getCellMap = (state, matrixId) => state.entities.pricingMatrix.cellMapById[matrixId];

export const hasItemTemplatesForClassId = (state, matrixId, classId) => {
    const map = getCellMap(state, matrixId);
    return (classId in map) && some(values(map[classId]), itemEntry => !isEmpty(itemEntry));
};

export const hasItemTemplatesForCategoryId = (state, matrixId, categoryId) => {
    const map = getCellMap(state, matrixId);
    return some(values(map), categoryMap => (categoryId in categoryMap) && !isEmpty(categoryMap[categoryId]));
};

export const hasItemTemplates = (state, matrixId) => {
    const map = getCellMap(state, matrixId);
    return some(keys(map), classId => hasItemTemplatesForClassId(state, matrixId, classId));
};

