import React, {useEffect, useRef} from 'react';
import PropTypes from "prop-types";
import interact from "interactjs";
import styles from './layoutEditor.module.scss';

export const AbsolutePosition = (el) => {
    let
        found,
        left = 0,
        top = 0,
        width = 0,
        height = 0,
        offsetBase = AbsolutePosition.offsetBase;
    if (!offsetBase && document.body) {
        offsetBase = AbsolutePosition.offsetBase = document.createElement('div');
        offsetBase.style.cssText = 'position:absolute;left:0;top:0';
        document.body.appendChild(offsetBase);
    }
    if (el && el.ownerDocument === document && 'getBoundingClientRect' in el && offsetBase) {
        const boundingRect = el.getBoundingClientRect();
        const baseRect = offsetBase.getBoundingClientRect();
        found = true;
        left = boundingRect.left - baseRect.left;
        top = boundingRect.top - baseRect.top;
        width = boundingRect.right - boundingRect.left;
        height = boundingRect.bottom - boundingRect.top;
    }
    return {
        found: found,
        left: left,
        top: top,
        width: width,
        height: height,
        right: left + width,
        bottom: top + height
    };
}


const Prototype = ({prototype}) => {
    const elementRef = useRef();

    useEffect(() => {
        const elementRefCopy = elementRef.current;

        let clone;

        const dragOptions = {
            inertia: false,
            manualStart: true,
            autoScroll: true,
            onmove: function (event) {

                const target = event.target;

                const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
                const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

                // translate the element
                target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

                // update the posiion attributes
                target.setAttribute('data-x', x);
                target.setAttribute('data-y', y);
            }, onend: function (event) {
                clone.remove();
            }
        };
        interact(elementRefCopy)
            .draggable(dragOptions)
            .on('move', function (event) {
                const interaction = event.interaction;

                // if the pointer was moved while being held down
                // and an interaction hasn't started yet
                if (interaction.pointerIsDown && !interaction.interacting()) {
                    let original = event.currentTarget;
                    // create a clone of the currentTarget element
                    clone = event.currentTarget.cloneNode(true);

                    let position = AbsolutePosition(original);

                    // insert the clone to the page
                    document.body.appendChild(clone);

                    clone.style.left = position.left + "px";
                    clone.style.top = position.top + "px";

                    clone.style.position = 'absolute';
                    clone.style.width = '250px';

                    // start a drag interaction targeting the clone
                    interaction.start({name: 'drag'}, event.interactable, clone);
                }
            });

        return () => {
            interact(elementRefCopy).unset();
        };
    }, [])

    return <div className={styles.WidgetPrototype} ref={elementRef} data-type={prototype.type}>
        <div className={styles.WidgetHeader}>
            <div className={styles.WidgetHandle}>
            </div>
            <div className={styles.WidgetLabel}>{prototype.label}</div>

            <div className={styles.WidgetIcon}>
                <i className="fa fa-arrows"></i>
            </div>
        </div>
    </div>
};

Prototype.propTypes = {
    prototype: PropTypes.object.isRequired,
};

export default Prototype;
