import React from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {getIn} from 'formik';
import {range} from 'lodash';
import {FormikTextInputGroup} from '../common/formik/FormikTextInputGroup';
import FormikDateTimeInput from '../common/formik/FormikDateTimeInput';
import Table from 'react-bootstrap/Table';
import {SalesRuleAssociationFormSelect} from './SalesRuleAssociationFormSelect';
import FormikAsyncTypeaheadInput from "../common/formik/FormikAsyncTypeaheadInput";
import Api from "../../api";
import FormikCheckbox from "../common/formik/FormikCheckbox";
import FormikSelect from "../common/formik/FormikSelect";
import {USE_FOR, SECONDARY_MARKET_FEE_FACTOR_CODE} from "./FormData";
import FeedbackButton from "../common/FeedbackButton";

const api = new Api();

const SalesRuleBatchEdit = ({associationFormik, allDeliveryGroups, ticketLayouts, setBatchEditSubmitButtonName}) => {
    const ListRow = ({element, associationFormik}) => {

        // get index of deliveryGroup in selectedDeliveryGroups array
        const getIndexSelectedDeliveryGroup = () => {
            return associationFormik.values.deliveryGroups.findIndex(dg => dg.shippingCode === element.shippingCode);
        };

        // get options for days from 1 to 16
        const getOptions = (key) => {
            let options = range(1, 16).map(i => ({id: i, name: i.toString()}))
            let dayNaming = ' Tage';
            if (element.considerOnlyWorkingDays) {
                dayNaming = ' Werktage';
            }
            if (key === 'activeUntilWorkingDaysBeforeEventStartDate') {
                options = options.map(op => {
                    return ({id: op.id, name: op.name + dayNaming})
                });
            }
            return options;
        };

        const getValue = (key) => {
            if (getIndexSelectedDeliveryGroup() >= 0) {
                return associationFormik.values.deliveryGroups[getIndexSelectedDeliveryGroup()][key]
            } else {
                return null;
            }
        };
        // add or remove toggled deliveryGroups to / from selectedDeliveryGroups array
        const toggleDeliveryGroup = (event) => {
            let newDeliveryGroups = [];
            if (event.target.checked) {
                newDeliveryGroups = [...associationFormik.values.deliveryGroups, {
                    'shippingCode': element.shippingCode,
                    'activeFromWorkingDaysBeforeEventStartDate': getValue('activeFromWorkingDaysBeforeEventStartDate'),
                    'activeUntilWorkingDaysBeforeEventStartDate': getValue('activeUntilWorkingDaysBeforeEventStartDate'),
                    'lastApplicableDayTime': getValue('lastApplicableDayTime')
                }];
            } else {
                newDeliveryGroups = associationFormik.values.deliveryGroups.filter(dg => dg.shippingCode !== element.shippingCode);
            }
            associationFormik.setFieldValue('deliveryGroups', newDeliveryGroups);
        }

        // this function is called when either the lastApplicableDayTime or one of the other values is changed
        const onSelectionChange = (eventOrDate, key) => {
            // if this deliveryGroup is already checked it will be found in the selectedDeliveryGroups array
            if (getIndexSelectedDeliveryGroup() >= 0) {
                const index = getIndexSelectedDeliveryGroup();
                let newDeliveryGroup = {};
                // lastApplicableDayTime doesn't send an event but a date, so it can be set as date
                if (key === 'lastApplicableDayTime') {
                    newDeliveryGroup = {
                        ...associationFormik.values.deliveryGroups[getIndexSelectedDeliveryGroup()],
                        [key]: eventOrDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: false})
                    };
                    // the other values send events so the value is in event.target.value
                } else {
                    newDeliveryGroup = {
                        ...associationFormik.values.deliveryGroups[getIndexSelectedDeliveryGroup()],
                        [key]: eventOrDate.target.value
                    };
                }
                let newDeliveryGroups = getIn(associationFormik.values, 'deliveryGroups', []);
                newDeliveryGroups = [...newDeliveryGroups];
                newDeliveryGroups.splice(index, 1, newDeliveryGroup);
                associationFormik.setFieldValue('deliveryGroups', newDeliveryGroups);
                // if this deliveryGroup is not checked yet it will be added to the selectedDeliveryGroups array here with the changed value and is therefore checked automatically
            } else {
                let newDeliveryGroup = {
                    'shippingCode': element.shippingCode,
                    'activeFromWorkingDaysBeforeEventStartDate': getValue('activeFromWorkingDaysBeforeEventStartDate'),
                    'activeUntilWorkingDaysBeforeEventStartDate': getValue('activeUntilWorkingDaysBeforeEventStartDate'),
                    'lastApplicableDayTime': '23:59',
                };
                // only when clicking on lastApplicableDayTime the value will be default, otherwise the selected value should be set
                if (key !== 'lastApplicableDayTime') {
                    newDeliveryGroup = {
                        ...newDeliveryGroup,
                        [key]: eventOrDate.target.value
                    };
                }
                const newDeliveryGroups = [...associationFormik.values.deliveryGroups, newDeliveryGroup];
                associationFormik.setFieldValue('deliveryGroups', newDeliveryGroups);
            }
        };

        let checked = !!associationFormik.values.deliveryGroups.find(dg => element.shippingCode === dg.shippingCode);

        let date = new Date(element.lastApplicableDateTime);

        return (
            // FIXME: use Formik <FieldArray> instead of calculating the field names
            <>
                <tr>
                    <td>
                        <Form.Check type="checkbox"
                                    id={`check${element.shippingCode}`}
                                    checked={checked}
                                    onChange={(event) => toggleDeliveryGroup(event)}
                                    data-testid={`check_${element.shippingCode}`}
                                    label={element.description}
                        />
                    </td>
                    <td>
                        <SalesRuleAssociationFormSelect
                            name={`deliveryGroups[${getIndexSelectedDeliveryGroup()}].activeFromWorkingDaysBeforeEventStartDate`}
                            options={getOptions('activeFromWorkingDaysBeforeEventStartDate')}
                            emptyValueLabel="always"
                            onChange={(event) => onSelectionChange(event, 'activeFromWorkingDaysBeforeEventStartDate')}
                            testid={`active_from_${element.shippingCode}`}
                        />
                    </td>
                    <td>
                        <SalesRuleAssociationFormSelect
                            name={`deliveryGroups[${getIndexSelectedDeliveryGroup()}].activeUntilWorkingDaysBeforeEventStartDate`}
                            options={getOptions('activeUntilWorkingDaysBeforeEventStartDate')}
                            emptyValueLabel={`Spieltag(${date.toLocaleDateString()})`}
                            onChange={(event) => onSelectionChange(event, 'activeUntilWorkingDaysBeforeEventStartDate')}
                            testid={`active_until_${element.shippingCode}`}
                        />
                    </td>
                    <td className="sales-rules-time-td">
                        <FormikDateTimeInput enableTime
                                             noCalendar
                                             onChange={(date) => onSelectionChange(date, 'lastApplicableDayTime')}
                                             name={`deliveryGroups[${getIndexSelectedDeliveryGroup()}].lastApplicableDayTime`}/>
                    </td>
                    <td>
                        <FeedbackButton
                        type="button"
                        onClick={() => {
                            setBatchEditSubmitButtonName(`shippingCode_${element.shippingCode}`);
                            associationFormik.handleSubmit()
                        }}>
                            Speichern
                        </FeedbackButton>
                    </td>
                </tr>
                <tr>
                </tr>
            </>
        )
    }

    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        <p>Versandarten</p>
                        <Table striped bordered hover className="overlay-table">
                            <thead>
                            <tr>
                                <th>
                                    <span>Versandgruppe</span>
                                </th>
                                <th>
                                    <span>ab Tage vorher verfügbar</span>
                                </th>
                                <th>
                                    <span>bis Tage vorher</span>
                                </th>
                                <th>
                                    <span>um</span>
                                </th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {allDeliveryGroups.map((element) => {
                                if (element.shippingCode !== 'DGT') {
                                    return <ListRow key={element.shippingCode} element={element}
                                                    associationFormik={associationFormik}/>
                                }
                            })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-8">
                        <FormikSelect
                            label="Ticketlayout"
                            name="ticketLayout"
                            options={ticketLayouts}
                            emptyValueLabel="«Ticketlayout auswählen»"
                            testid="ticket_layout"
                        />
                    </Col>
                    <Col className="col-4 pt-4">
                        <FeedbackButton
                            type="button"
                            onClick={() => {
                                setBatchEditSubmitButtonName('ticketLayout');
                                associationFormik.handleSubmit()
                            }}
                            className="mb-3"
                        >
                            Speichern
                        </FeedbackButton>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-8">
                        <FormikTextInputGroup label="Max. Tickets" name="maxTicketsPerUser" type="number"
                                              testid="max_tickets"/>
                    </Col>
                    <Col className="col-4 pt-5">
                        <FeedbackButton
                            type="button"
                            onClick={() => {
                                setBatchEditSubmitButtonName('maxTicketsPerUser');
                                associationFormik.handleSubmit()
                            }}
                            className="mb-3"
                        >
                            Speichern
                        </FeedbackButton>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-8">
                        <FormikDateTimeInput enableTime label="Gültig von" name="validFrom" testid="valid_from"/>
                    </Col>
                    <Col className="col-4 pt-4">
                        <FeedbackButton
                            type="button"
                            onClick={() => {
                                setBatchEditSubmitButtonName('validFrom');
                                associationFormik.handleSubmit()
                            }}
                            className="mb-3"
                        >
                            Speichern
                        </FeedbackButton>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-8">
                        <FormikDateTimeInput enableTime label="Gültig bis" name="validTo" testid="valid_to"/>
                    </Col>
                    <Col className="col-4 pt-4">
                        <FeedbackButton
                            type="button"
                            onClick={() => {
                                setBatchEditSubmitButtonName('validTo');
                                associationFormik.handleSubmit()
                            }}
                            className="mb-3"
                        >
                            Speichern
                        </FeedbackButton>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-8">
                        <FormikDateTimeInput enableTime label="Sammelrechnung je Abonnent zu diesem Zeitpunkt erstellen" name="contractMustBeReadyBy" testid="contract_must_be_ready_by"/>
                    </Col>
                    <Col className="col-4 pt-4">
                        <FeedbackButton
                            type="button"
                            onClick={() => {
                                setBatchEditSubmitButtonName('contractMustBeReadyBy');
                                associationFormik.handleSubmit()
                            }}
                            className="mb-3"
                        >
                            Speichern
                        </FeedbackButton>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-8">
                        <FormikAsyncTypeaheadInput
                            id="tags"
                            label="Tags"
                            onSearch={query => api.getTagsForSalesRulesByQuery(query)}
                            minLength={0}
                            testid="tags"
                            multiple={true}
                            arrayOfStrings={true}
                            allowNew={true}
                        />
                    </Col>
                    <Col className="col-4 pt-5">
                        <FeedbackButton
                            type="button"
                            onClick={() => {
                                setBatchEditSubmitButtonName('tags');
                                associationFormik.handleSubmit()
                            }}
                            className="mb-3"
                        >
                            Speichern
                        </FeedbackButton>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-8">
                        <FormikSelect label="Print at Home Ticketlayout" name="printAtHomeTicketLayout"
                                            options={ticketLayouts} emptyValueLabel="«Ticketlayout auswählen»"
                                            testid="pah_ticket_layout"/>
                    </Col>
                    <Col className="col-4 pt-3">
                        <FeedbackButton
                            type="button"
                            onClick={() => {
                                setBatchEditSubmitButtonName('printAtHomeTicketLayout');
                                associationFormik.handleSubmit()
                            }}
                            className="mb-3 mt-3"
                        >
                            Speichern
                        </FeedbackButton>
                    </Col>
                </Row>
                <Row>

                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <p className="mt-3 ml-2">Ticketbörse</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-8">
                                <FormikSelect name="secondaryMarketFeeFactorCode" label="Ticketbörsengebühr"
                                              options={SECONDARY_MARKET_FEE_FACTOR_CODE}/>
                            </Col>
                            <Col className="col-4 pt-4">
                                <FeedbackButton
                                    type="button"
                                    onClick={() => {
                                        setBatchEditSubmitButtonName('secondaryMarketFeeFactorCode');
                                        associationFormik.handleSubmit()
                                    }}
                                    className="mb-3"
                                >
                                    Speichern
                                </FeedbackButton>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-8">
                                <FormikSelect
                                    name="useFor"
                                    label="Verkaufsregel benutzen für"
                                    options={USE_FOR}
                                />
                            </Col>
                            <Col className="col-4 pt-4">
                                <FeedbackButton
                                    type="button"
                                    onClick={() => {
                                        setBatchEditSubmitButtonName('useFor');
                                        associationFormik.handleSubmit()
                                    }}
                                    className="mb-3"
                                >
                                    Speichern
                                </FeedbackButton>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-8 mt-3">
                                <FormikCheckbox
                                    label="Tickets aus dieser Regel dürfen in die Ticketbörse eingestellt werden"
                                    name="useSecondaryMarket"/>
                            </Col>
                            <Col className="col-4">
                                <FeedbackButton
                                    type="button"
                                    onClick={() => {
                                        setBatchEditSubmitButtonName('useSecondaryMarket');
                                        associationFormik.handleSubmit()
                                    }}
                                    className="mb-3"
                                >
                                    Speichern
                                </FeedbackButton>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-8 mt-3">
                                <FormikCheckbox
                                    label="Download-Link für Print@Home-Tickets in Web/Wallet generieren"
                                    name="provideDownloadUrl"/>
                            </Col>
                            <Col className="col-4">
                                <FeedbackButton
                                    type="button"
                                    onClick={() => {
                                        setBatchEditSubmitButtonName('provideDownloadUrl');
                                        associationFormik.handleSubmit()
                                    }}
                                    className="mb-3"
                                >
                                    Speichern
                                </FeedbackButton>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
};

SalesRuleBatchEdit.propTypes = {
    ticketLayouts: PropTypes.any,
    associationFormik: PropTypes.object,
    element: PropTypes.object,
    allDeliveryGroups: PropTypes.array,
    setBatchEditSubmitButtonName: PropTypes.func
};

export default SalesRuleBatchEdit;
