import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import styles from './ImagePreview.module.scss'
import CloseIcon from '@mui/icons-material/Close';
import classnames from 'classnames';

interface ImagePreviewProps {
    src: string;
    handleClearPreview?: () => void;
    onHandleClick?: () => void;
    showClose?: boolean;
    className?: string;
    fullSize?: boolean;
    width?: string;
    height?: string;
}

export const ImagePreview: React.FC<ImagePreviewProps> = ({
    src,
    handleClearPreview,
    className,
    width,
    height,
    fullSize = false,
    showClose = false
}) => {
    return (
        <div
            className={classnames(styles.previewContainer, className)}
            style={{ width: width || '200px', height: height || '200px' }}
        >
            <img
                src={src}
                alt="Preview"
                className={styles.previewImg}
                style={{
                    objectFit: fullSize ? 'cover' : 'contain',
                }}
            />
            {showClose && (
                <IconButton onClick={handleClearPreview} className={styles.icon}>
                    <CloseIcon />
                </IconButton>
            )}
        </div>
    )
};

export default ImagePreview
