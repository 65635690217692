import {call, put} from 'redux-saga/effects';
import {normalize} from 'normalizr';

/**
 * Generische Saga für das Laden von Entitäten.
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export default ({actions, resource, schema}) => function* ({payload: {id}}) {
    try {
        const entity = yield call(resource.fetch, id);
        const response = normalize(entity, schema);

        yield put(actions.loadSuccess({response}))
    } catch (error) {
        yield put(actions.loadError({id, error}))
    }
}
