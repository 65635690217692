import React, { useEffect, useState } from "react";
import { Formik, Form } from 'formik';
import { useSelector} from "react-redux";
import * as yup from 'yup';
import { Dialog, DialogTitle, DialogContent, } from '@mui/material';
import { IState, selectIsAddAreaFormLoading, selectPlaceCategories } from "../../state/entities/venueEditor/selectors";
import FeedbackButton from "../../components/common/FeedbackButton";
import { FormikTextInputGroup } from "../../components/common/formik/FormikTextInputGroup";
import { Point, Polygon } from "pixi.js";
import { Col, Row } from 'react-bootstrap';
import styles from "./AddBlockAreaDialog.module.scss";
import { AreaFormData } from "../editor/display/areaForms/AreaFormData";
import LoadingDialog from "../LoadingDialog";
import FormikSelect from "../../components/common/formik/FormikSelect";
import debounce from 'debounce-promise';
import { memoize } from "../../form/fieldValidation";
import Api from "../../api";



const api = new Api();

export enum DialogMode {
    CREATE = "dialogModeCreate",
    EDIT = "dialogModeEdit"
}


interface IAddBlockAreaDialogProps {
    open: boolean;
    shape: Polygon;
    dialogMode: DialogMode;
    editData: AreaFormData;
    venuePlanId: string;
    onConfirm: (areaForm: AreaFormData) => void;
    onCancel: () => void;
}

const INITIAL_VALUES = {
    title: '',
    shortTitle: '',
    ticketHint: '',
    capacity: null,
    pricingList: ''
};

const FORM_SCHEMA = yup.object({
    title: yup.string().required('Titel ist erforderlich'),
    shortTitle: yup.string().required('Kurztitel ist erforderlich'),
    capacity: yup.number().required('Zahl ist erforderlich').min(0, 'Die Kapazität sollte größer oder gleich 0 sein'),
    pricingList: yup.string().required('Preiskategorie muss ausgewählt sein'),
});

const AddBlockAreaDialog: React.FC<IAddBlockAreaDialogProps> = ({
    open,
    shape,
    dialogMode,
    editData,
    venuePlanId,
    onConfirm,
    onCancel
}) => {
    const placeCategories = useSelector((state: IState) => selectPlaceCategories(state));
    const [isLoaderShowing, setIsLoaderShowing] = useState(false);
    const isAddAreaFormLoading = useSelector((state: IState) => selectIsAddAreaFormLoading(state));
    const getBlockByBackendNameShortDebounced = debounce((backendNameShort, venuePlanId) => api.getBlockByBackendNameShort(backendNameShort, venuePlanId), 500);

    useEffect(() => {
        setIsLoaderShowing(isAddAreaFormLoading);
    }, [isAddAreaFormLoading]);

    const validateBackendNameShort = memoize({
        initialValue: undefined,
        async validate(backendNameShort) {
            // Wenn der backendNameShort leer ist brauchen wir nicht die API zu fragen.
            if (!backendNameShort) return;
            const responseBlock = await getBlockByBackendNameShortDebounced(backendNameShort, venuePlanId);
            if (responseBlock && responseBlock.id !== (dialogMode === DialogMode.EDIT ? editData.id : undefined)) {
                return 'Der Kurztitel des Blocks wird bereits verwendet'
            }
        },
        initialResult: undefined
    });

    const onSubmit = (values) => {
        const data = {
            id: dialogMode === DialogMode.CREATE ? 'provisory' : editData.id,
            title: {
                de: values.title,
                en: values.title
            },
            entranceHint: {
                de: values.ticketHint,
                en: values.ticketHint
            },
            shortTitle: values.shortTitle,
            blockType: values.blockType,
            priceCategoryId: values.pricingList,
            capacity: parseInt(values.capacity),
            x: dialogMode === DialogMode.EDIT ? editData.x : 0,
            y: dialogMode === DialogMode.EDIT ? editData.y : 0,
            points: shape.points.reduce((acc, curr, idx, src) => {
                    if (idx % 2 === 0) acc.push(new Point(curr, src[idx + 1]));
                    return acc;
                }, []),  //conv flat number array to Point array
            placePools: dialogMode === DialogMode.EDIT ? editData.placePools : []
        } as AreaFormData;
        onConfirm(data);
    };

    let initValues = {...INITIAL_VALUES};

    if (dialogMode === DialogMode.EDIT) {
        initValues.title = editData.title.de;
        initValues.shortTitle = editData.shortTitle;
        initValues.ticketHint = editData.entranceHint.de;
        initValues.capacity = editData.capacity;
        initValues.pricingList = editData.priceCategoryId;
    }

    return (
        <div>
            <Dialog open={open} fullWidth maxWidth={"lg"}>
                <DialogTitle>Stehplatzblock {dialogMode === DialogMode.EDIT ? 'bearbeiten' : 'erzeugen'}</DialogTitle>
                <DialogContent className={styles.dialogContent}>
                    <Formik
                        initialValues={initValues}
                        validationSchema={FORM_SCHEMA}
                        onSubmit={onSubmit}
                    >
                        {formik => {
                            return (
                                <>
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Row className="mt-3">
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup label="Titel des Blocks*" name="title" />
                                            </Col>
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup label="Eingangshinweis zum Aufdruck auf das Ticket" name="ticketHint"/>
                                            </Col>
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup
                                                    label="Kurztitel des Blocks*"
                                                    name="shortTitle"
                                                    validate={validateBackendNameShort}
                                                />
                                            </Col>
                                            <Col className="col-md-4">
                                                <FormikTextInputGroup label="Kapazität des Blockes*" name="capacity" type="number" />
                                            </Col>
                                            <Col className="col-md-4">                               
                                                <div className={styles.pricingCategoryBox}>
                                                <FormikSelect
                                                    name="pricingList"
                                                    label="Preiskategorie*"
                                                    options={placeCategories}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Row>
                                        <Col className="col-md-12 justify-content-end d-flex mt-3" style={{ gap: '8px'}}>
                                            <FeedbackButton onClick={onCancel} variant={'outlined'}>Abbrechen</FeedbackButton>
                                            <FeedbackButton onClick={() => formik.submitForm()}>
                                                {dialogMode === DialogMode.EDIT ? 'Speichern' : 'Hinzufügen'}
                                            </FeedbackButton>
                                        </Col>
                                    </Row>
                                </>
                            )
                        }}
                    </Formik>        
                </DialogContent>
            </Dialog>
            <LoadingDialog message="Block wird erzeugt." isLoading={isLoaderShowing && open} />
        </div>
    );
};

export default AddBlockAreaDialog;
