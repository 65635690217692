import React, {useEffect} from 'react';
import Form from "./Form";
import {connect} from "react-redux";
import {LoadingIndicator} from "../common/LoadingIndicator";
import {Col, Row} from "react-bootstrap";
import * as salesRule from "../../state/entities/salesRule";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";


const Edit = ({loadSalesRule, saveSalesRule, salesRuleId, salesRule, loading, saving}) => {

    useEffect(() => {
        if (!salesRule) {
            loadSalesRule(salesRuleId);
        }

        // Wir wollen diesen Effekt explizit nur einmal "on-mount" durchführen, daher geben wir [] als dependency an.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (salesRule, {setErrors}) => {
        saveSalesRule(salesRule, setErrors);
    };

    if (!salesRule || loading) {
        return <LoadingIndicator/>;
    }
    return <>
        <Row className="my-4">
            <Breadcrumbs entity={["Verkaufsregel"]} endpoints = {['sales_rule/breadcrumb']} title = "Verkaufsregeln" links={['/event-management/sales-rule/']} active={[salesRule.id]} />
        </Row>
        <Row className="mb-4 mt-4">
            <Col className="pt-3 col-sm-8">
                <h1>Bearbeiten der Verkaufsregel <em>{salesRule.name}</em></h1>
            </Col>
        </Row>
        <Form salesRule={salesRule} onSubmit={onSubmit} submitPending={saving} />

    </>;
};


const mapStateToProps = (state, props) => {
    const salesRuleId = props.match.params.srid;

    const salesRule = state.entities.salesRule.byId[salesRuleId];


    return {
        salesRuleId,
        loading: state.entities.salesRule.loading[salesRuleId],
        saving: state.entities.salesRule.saving[salesRuleId],
        salesRule,
    }
};

const mapDispatchToProps = dispatch => ({
    loadSalesRule: id => dispatch(salesRule.actions.load({id})),
    saveSalesRule: entity => dispatch(salesRule.actions.save({entity})),

});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
