import React from 'react';
import {
    Button,
    ButtonProps,
    CircularProgress 
} from '@mui/material';
import { isEmpty } from 'lodash';
import { Link, LinkProps } from 'react-router-dom';

interface MuiButtonProps {
    busy?: boolean;
    icon?: React.ReactNode;
    to?: LinkProps['to'];
    target?: string;
    title?: string;
}

export const MuiButton: React.FC<ButtonProps & MuiButtonProps> = ({
    children,
    disabled,
    busy,
    title,
    icon,
    type,
    variant,
    onClick,
    to,
    target,
    ...props
}) => {
    const setVariantByDefault = (!isEmpty(to) && variant !== 'contained') ? 'text' : variant || 'contained';
    if (!isEmpty(to)) {
        return (
            <Link to={to} target={target} title={title}>
                <Button
                    {...props}
                    disabled={disabled || busy}
                    type={type}
                    variant={setVariantByDefault}
                    onClick={onClick}
                    startIcon={icon ? icon : ''}
                >
                    {busy && <CircularProgress color="secondary" size={12} />}
                    {children}
                </Button>
            </Link>
        );
    }
    return (
        <Button
            {...props}
            disabled={disabled || busy}
            type={type}
            variant={variant || 'contained'}
            onClick={onClick}
            startIcon={icon ? icon : ''}
            title={title}
        >
            {busy && <CircularProgress color="secondary" size={12} />}
            {children}
        </Button>
    );
};

export default MuiButton
