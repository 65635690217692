import {createSlice} from 'redux-starter-kit';

const entityName = 'seat';

export const batch = createSlice({
    slice: entityName,
    initialState: {},
    reducers: {
        batch: (state, {payload}) => {
            state[payload.seats] = true;
        },
        batchError: (state, {payload}) => {
            state[payload.seats] = false;
        },
        batchSuccess: (state, {payload}) => {
            // Als payload wird eine "normalizr" response erwartet
            state[payload.response.result] = false;
        }
    }
});


