import React from "react";
import TextContent from "../layout/TextContent";
import {connect} from "react-redux";
import TenantSelect from '../tenant/TenantSelect';
import authService from "../auth/authService";

const Dashboard = ({ helpTextsVisible }) => {
    const isSuperAdmin = authService.hasRole('admin_super_admin');
    return <TextContent>
        {isSuperAdmin && <TenantSelect isShowEmptyValue={false} />}
        <h1 className="h1-dashboard">Willkommen beim Ticketing!</h1>
        <p>
            Wie Du hier Tickets für ein Event verkaufen kannst? <strong>Ganz einfach!</strong>
        </p>

        <h1 className="h1-dashboard">1) Weise dem Event einen <u>Saalplan</u> aus einer Spielstätte zu.</h1>
        <div className={!helpTextsVisible ? "hidden" : "text-muted"}>
            <h2>Was ist ein Saalplan?</h2>
            <ul>
                <li>Ein Saalplan stellt dar, welche Plätz physikalisch an einem Ort verfügbar sind.</li>
                <li>Ein Saalplan ist global und kann in beliebigen Veranstaltungen eingesetzt werden.</li>
                <li>Ein Saalplan hat u.A. Blöcke ("<i>33L</i>"), Blockgruppen ("<i>Westkurve</i>") und Sitze ("<i>Reihe 2 Platz 4</i>"),
                    die eine Preiskategorie (<i>1 billig - 6 teuer</i>) haben.</li>
                <li>Ein Saalplan dient <i>nicht</i> dazu, festzulegen, welche Plätze davon in den Verkauf gehen sollen. Dazu werden Platzpools genutzt.</li>
                <li>Jeder Sitz kann zu einem oder mehreren Platzpools gehören.</li>
            </ul>
            <h2>Was sind Platzpools?</h2>
            <ul>
                <li>Platzpools bündeln beliebige Plätze eines Saalplans, für welche die gleichen Verkaufsregeln gelten sollen. Also Verfügbarkeit, Preise etc.</li>
                <li>Plätze können mehreren Platzpools angehören.</li>
                <li>Platzpools werden an den (globalen) Saalplänen gepflegt, müssen aber natürlich nicht an allen Veranstaltungen eine Wirkung entfalten.</li>
            </ul>
        </div>

        <h1 className="h1-dashboard">2) Lege passgenaue und handliche <u>Preislisten</u> an.</h1>
        <div className={!helpTextsVisible ? "hidden" : "text-muted"}>
            <h2>Was sind Preislisten?</h2>
            <ul>
                <li>Preislisten legen in einfachen Matrix fest, welche Leistung ("Zutritt mit RMV", "Bier", ...)(Link auf Leistungsvorlagen) für
                    welche Preisklasse ("Normal", "Ermäßigt", ...) und welche Preiskategorie wie viel kosten soll.</li>
                <li>Tipp: Preislisten sollten eher klein sein und durch mehrere Verkaufsregeln zu einer großen kombiniert werden.</li>
                <li>Kleine Preislisten können flexibler wiederverwendet werden.</li>
                <li>Lieber acht kleine Preislisten als eine sehr große.</li>
            </ul>
            <h2>Was sind Preiskategorien?</h2>
            <ul>
                <li>Preiskategorien bilden die Wertigkeit eines Platzes abbilden.</li>
                <li>Jeder Platz gehört zu genau einer Preiskategorie.</li>
                <li>Preiskategorien sollen NICHT abbilden, WER die entsprechende Plätze kaufen kann. Dafür sind Platzpools gedacht, s.o.</li>
            </ul>
            <h2>Was sind Preisklassen?</h2>
            <ul>
                <li>Preisklassen (in anderen Systemen auch <i>Ermäßigungen</i>) legen fest, auf wen sich ein Preis bezieht ("Kinder", "Normal", ...)</li>
                <li>Finden sich bei uns in den Preismatrizen.</li>
                <li>Preisklassen sollen NICHT abbilden, wer genau entsprechende Plätze kaufen kann - dafür dienen Personenpools.</li>
            </ul>
        </div>

        <h1 className="h1-dashboard">3) Lege mit <u>Verkaufsregeln</u> fest, wer (Personenpools) was (Platzpools) zu welchem Preis (Preisliste) kaufen kann.</h1>
        <div className={!helpTextsVisible ? "hidden" : "text-muted"}>
            <h2>Was sind Verkaufsregeln?</h2>
            <ul>
                <li>Verkaufsregeln <strong>sind global</strong> und können in mehreren Events <strong>wiederverwendet</strong> werden.</li>
                <li>Verkaufsregeln legen fest, wer (Personenpools!) was (Platzpools!) wann und zu welchem Preis (Preisliste!) kaufen kann.</li>
                <li>Verkaufsregeln sind das zentrale Werkzeug zur Vertriebssteuerung - sie schnüren die einzelnen Teile zusammen.</li>
                <li>Verkaufsregeln sind also Dinge wie "Mitgliederfrühverkauf für Bundesliga-Spiele"</li>
            </ul>
            <h2>Was sind Personenpools?</h2>
            <ul>
                <li>Personenpools sind einfach Gruppierungen von Käufern. Zum Beispiel "Dauerkartenbesitzer", "Mitglieder", "Frauen", "Leute
                    die Klaus heissen und Linkshänder sind".</li>
                <li>Jeder Käufer kann beliebig vielen Personenpools angehören.</li>
                <li>Personen werden in der Plattform selektiert und Personenpools zugewiesen.</li>
            </ul>
        </div>

        <h1 className="h1-dashboard">4) <u>Verknüpfe</u> beliebig viele deiner <u>Verkaufsregeln mit deinem Event</u>.</h1>
        <div className={!helpTextsVisible ? "hidden" : "text-muted"}>
            <ul>
                <li>Dort kannst Du unter anderem steuern, wann deine globalen Regeln für dieses Event gültig sind.</li>
            </ul>
        </div>

        <p>Ein wenig mehr Info gefällig? Schalte oben rechts die Info-Texte an!</p>
    </TextContent>
};

const mapStateToProps = (state, props) => {
    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible,
    }
};

export default connect(mapStateToProps)(Dashboard);
