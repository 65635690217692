import React, {Component} from 'react';
import {Container, Row} from 'react-bootstrap';
import ConnectedListView from '../common/listView/ConnectedListView';
import {connect} from 'react-redux';
import Modal from 'react-bootstrap/Modal';

import {get} from 'lodash';
import * as artist from "../../state/entities/artist";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import FeedbackButton from "../common/FeedbackButton";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDeleteConfirmDialog: false,
            artistToDelete: undefined
        }
    }

    showDeleteConfirmDialog(artist) {
        this.setState({
            showDeleteConfirmDialog: true,
            artistToDelete: artist,
        });
    }

    handleCancelDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
    };

    handleConfirmDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
        this.props.deleteartist(this.state.artistToDelete);
    };

    render() {
        return (
            <Container>
                <Row className="my-4">
                    <Breadcrumbs entity={["Artist"]} endpoints = {['artist/breadcrumb']} title = "Künstler" links={['/base/artist/']} active={[0]} />
                </Row>
                <ConnectedListView
                    heading="Künstler"
                    endpoint="listingView/artist"
                    buttonTitle="Neuer Künstler"
                    buttonLink="/base/artist/create"
                    testid="link_new_artist"
                >
                    {({fields, item}) => (
                        <>
                            <FeedbackButton
                                className="list-link"
                                title="Künstler bearbeiten"
                                to={`/base/artist/${item.id}`}
                                icon={<EditNoteOutlinedIcon className='table-icon' />}
                            />
                            <FeedbackButton
                                className="list-link"
                                title="Künstler löschen"
                                data-testid="link_delete_artist"
                                onClick={() => this.showDeleteConfirmDialog(item)}
                                icon={<DeleteOutlineOutlinedIcon className='table-icon' />}
                            />
                        </>
                    )}
                </ConnectedListView>


                <Modal show={this.state.showDeleteConfirmDialog} onHide={this.handleCancelDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Künstler löschen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Möchten Sie den Künstler "{get(this.state, 'artistToDelete.name')}" wirklich löschen?</Modal.Body>
                    <Modal.Footer>
                        <FeedbackButton
                            variant="outlined"
                            onClick={this.handleCancelDelete}
                        >
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton
                            onClick={this.handleConfirmDelete}
                        >
                            Löschen
                        </FeedbackButton>
                    </Modal.Footer>
                </Modal>

            </Container>
        );
    }
}

export default connect(null, {
    deleteartist: entity => artist.actions.delete({entity})
})(List);
