import {call, put} from 'redux-saga/effects';
import {normalize} from 'normalizr';

/**
 * Generische Saga für das Speichern von Entitäten.
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export default ({actions, resource, schema}) => function* ({payload: {entity}}) {
    try {
        const result = yield call(resource.save, entity);
        const response = normalize(result, schema);
        yield put(actions.saveSuccess({
            response, globalMessagesList: {
                id: Date.now(),
                variant: 'success',
                isCreate: false,
                message: 'Änderung erfolgreich gespeichert!'
            }
        }))
    } catch (error) {
        yield put(actions.saveError({entity, error}))
    }
}
