import { createSelector } from "@reduxjs/toolkit";
import { InteractionMode } from "../../../seating/editor/display/interaction";
import { SeatRecord } from "../../../seating/types";
import { IPlaceCategory } from "../../../seating/types/PlaceCategory";
import { IPlacepool } from "../../../seating/types/Placepool";
import { ISeatingType } from "../../../seating/types/SeatingType";
import { IBlock } from "../../../seating/types/Block";
import { IAreaFormsSliceData, IBlocksData, IImagesSliceData, ISeatsSliceData, ISelectedSeatIdsSliceData,
    IUndoHistorySliceData, IVenuePlanSettingsSliceData, IVenuePlanSliceData } from "./slice";


export interface IState {
    entities: IEntitiesRoot;
}

interface IEntitiesRoot {
    venueEditor: IVenueEditorRoot;
}

export interface IVenueEditorRoot {
    venuePlan: IVenuePlanSliceData,
    venuePlanSettings: IVenuePlanSettingsSliceData,
    isApiRequestPending: boolean,
    seats: ISeatsSliceData,
    selectedSeatIds: ISelectedSeatIdsSliceData,
    undoHistory: IUndoHistorySliceData,
    placepoolDefinitions: Array<IPlacepool>,
    interactionMode: InteractionMode,
    moveOnGrid: boolean,
    placeCategories: Array<IPlaceCategory>,
    seatingTypes: Array<ISeatingType>,
    blocks: IBlocksData,
    images: IImagesSliceData,
    areaForms: IAreaFormsSliceData,
    fatalAPIError: string
}



export const selectVenuePlan = (state: IState) => state.entities.venueEditor.venuePlan.venuePlan;
export const selectVenuePlanIsLoaded = (state: IState) => state.entities.venueEditor.venuePlan.isLoaded;

export const selectVenuePlanSettings = (state: IState) => state.entities.venueEditor.venuePlanSettings.venuePlanSettings;
export const selectVenuePlanSettingsIsLoaded = (state: IState) => state.entities.venueEditor.venuePlanSettings.isLoaded;

export const selectIsApiRequestPending = (state: IState) => state.entities.venueEditor.isApiRequestPending;

export const selectAllSeats = (state: IState) => state.entities.venueEditor.seats.allSeats;
export const selectNewAddedSeats = (state: IState) => state.entities.venueEditor.seats.newAddedSeats;
export const selectIsSeatsLoaded = (state: IState) => state.entities.venueEditor.seats.isLoaded;
export const selectUpdatedSeatsBeforeUpdate = (state: IState) => state.entities.venueEditor.seats.updatedSeatsBeforeUpdate;

export const selectSelectedSeatIds = (state: IState) => state.entities.venueEditor.selectedSeatIds.selectedSeatIds;
export const selectSelectedSeatsSelectedBy = (state: IState) => state.entities.venueEditor.selectedSeatIds.selectedBy;
export const selectChangedSelectionBeforeChange = (state: IState) =>
    state.entities.venueEditor.selectedSeatIds.changedSelectionBeforeChange;

export const selectSelectedSeats = createSelector(  //memoized selector
    [selectAllSeats, selectSelectedSeatIds],
    (allSeats, selectedSeatIds) => {
        const selectedSeats = selectedSeatIds.map(seatId => allSeats[seatId]);
        return selectedSeats;
    }
);

export const selectUndoHistory = (state: IState) => state.entities.venueEditor.undoHistory.undoActions;
export const selectRedoHistory = (state: IState) => state.entities.venueEditor.undoHistory.redoActions;

export const selectPlacepoolDefinitions = (state: IState) => state.entities.venueEditor.placepoolDefinitions;

export const selectPlaceCategories = (state: IState) => state.entities.venueEditor.placeCategories;

export const selectSeatingTypes = (state: IState) => state.entities.venueEditor.seatingTypes;

export const selectBlocks = (state: IState): IBlock[] => state.entities.venueEditor.blocks.allBlocks;
export const selectBlocksIsLoaded = (state: IState): boolean => state.entities.venueEditor.blocks.isLoaded;

export const selectSeatingBlocks = createSelector(
    [selectBlocks],
    allBlocks => allBlocks.reduce((seatBlocks, block) => {
            if (block.blockType === 'seating') seatBlocks.push(block);
            return seatBlocks;
        },
        []
    ) as IBlock[]
);

export const selectInteractionMode = (state: IState) => state.entities.venueEditor.interactionMode;
export const selectMoveOnGrid = (state: IState) => state.entities.venueEditor.moveOnGrid;

export const selectImagesIsLoaded = (state: IState) => state.entities.venueEditor.images.isLoaded;
export const selectImages = (state: IState) => state.entities.venueEditor.images.allImages;
export const selectNewAddedImages = (state: IState) => state.entities.venueEditor.images.newAddedImages;

export const selectAreaForms = (state: IState) => state.entities.venueEditor.areaForms.areaForms;
export const selectNewAddedAreaForms = (state: IState) => state.entities.venueEditor.areaForms.newAddedAreaForms;
export const selectIsAddAreaFormLoading = (state: IState) => state.entities.venueEditor.areaForms.isAddAreaFormLoading;
export const selectAreaFormsIsLoaded = (state: IState) => state.entities.venueEditor.areaForms.isLoaded;
export const selectSelectedAreaFormId = (state: IState) => state.entities.venueEditor.areaForms.selectedAreaFormId;

export const selectSelectedAreaForm = createSelector(  //memoized selector
    [selectAreaForms, selectSelectedAreaFormId],
    (allAreaForms, selectedAreaFormId) => {
        if (!selectedAreaFormId) return null;
        const selectedAreaForm = allAreaForms[selectedAreaFormId];
        return selectedAreaForm;
    }
);

export const selectFatalAPIError = (state: IState) => state.entities.venueEditor.fatalAPIError;



export const selectSeatsWithEmptyLabelOrRow = createSelector(
    [selectAllSeats],
    seats => {
        return Object.values(seats).filter(seat => seat.row === '' || seat.label === '');
    }
);

export const selectSeatsByBlockAndRow = createSelector(
    [selectAllSeats],
    (seats: SeatRecord) => {
        const blocks = {};
        for (const seat of Object.values(seats)) {
            const blockId = seat.blockId;
            const row = seat.row;
            if (!blocks[blockId]) {  //block not existing yet
                const newBlock = {};
                newBlock[row] = [seat];
                blocks[blockId] = newBlock;
            } else if (!blocks[blockId][row]) { //row in block not existing yet
                blocks[blockId][row] = [seat];
            } else {
                blocks[blockId][row].push(seat);
            }
        }
        return blocks;
    }
)

export const selectSeatsCountWithoutPlacepools = createSelector(
    [selectAllSeats],
    (seats: SeatRecord) => {
        return Object.values(seats).reduce((count, seat) => count + (!seat.tags?.length ? 1 : 0), 0);
    }
)
