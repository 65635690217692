import React, {useEffect} from 'react';
import Form from "./Form";
import {connect} from "react-redux";
import {LoadingIndicator} from "../common/LoadingIndicator";
import {Col, Row} from "react-bootstrap";
import * as ticketLayout from "../../state/entities/ticketLayout";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

const Edit = ({loadTicketLayout, saveTicketLayout, ticketLayoutId, ticketLayout, loading, saving}) => {

    useEffect(() => {
        if (!ticketLayout) {
            loadTicketLayout(ticketLayoutId);
        }

        // Wir wollen diesen Effekt explizit nur einmal "on-mount" durchführen, daher geben wir [] als dependency an.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (ticketLayout, {setErrors}) => {
        saveTicketLayout(ticketLayout, setErrors);
    };

    if (!ticketLayout || loading) {
        return <LoadingIndicator/>;
    }
    return <>
        <Row className="my-4">
            <Breadcrumbs entity={["TicketLayout"]} endpoints = {['ticket_layout/breadcrumb']} title = "Ticketlayouts" links={['/base/ticket-layout/']} active={[ticketLayout.id]} />
        </Row>
        <Row className="mb-4 mt-4">
            <Col className="pt-3 col-sm-8">
                <h1>Bearbeiten des Ticketlayouts <em>{ticketLayout.name}</em></h1>
            </Col>
        </Row>
        <Form ticketLayout={ticketLayout} onSubmit={onSubmit} submitPending={saving} />

    </>;
};


const mapStateToProps = (state, props) => {
    const ticketLayoutId = props.match.params.spid;

    const ticketLayout =state.entities.ticketLayout.byId[ticketLayoutId];

    return {
        ticketLayoutId,
        loading: state.entities.ticketLayout.loading[ticketLayoutId],
        saving: state.entities.ticketLayout.saving[ticketLayoutId],
        ticketLayout,
    }
};

const mapDispatchToProps = dispatch => ({
    loadTicketLayout: id => dispatch(ticketLayout.actions.load({id})),
    saveTicketLayout: entity => dispatch(ticketLayout.actions.save({entity})),

});

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
