import {all} from 'redux-saga/effects';

import listViewSaga from './entities/listView/saga';
import breadcrumbsSaga from './entities/breadcrumbs/saga';
import {saga as eventSeries} from './entities/eventSeries';
import {saga as venueEvent} from './entities/venueEvent';
import {saga as metaEvent} from './entities/metaEvent';
import {saga as pricingCategory} from './entities/pricingCategory';
import {saga as pricingClass} from './entities/pricingClass';
import {saga as pricingList} from './entities/pricingList';
import {saga as pricingMatrix} from './entities/pricingMatrix';
import {saga as purchasableItemTemplate} from './entities/purchasableItemTemplate';
import {saga as salesRule} from './entities/salesRule';
import {saga as backendUser} from './entities/backendUser';
import {saga as seatingType} from './entities/seatingType';
import {saga as tenant} from './entities/tenant';
import {saga as artist} from './entities/artist';
import {saga as eventCategory} from './entities/eventCategory';
import {saga as media} from './entities/media';
import {saga as venue} from './entities/venue';
import {saga as venuePlan} from './entities/venuePlan';
import {saga as zukoZone} from './entities/zukoZone';
import {saga as blockGroup} from './entities/blockGroup';
import {saga as block} from './entities/block';
import {saga as seat} from './entities/seat';
import {saga as placePool} from './entities/placePool';
import {saga as targetGroup} from './entities/targetGroup';
import {saga as ticketLayout} from './entities/ticketLayout';
import {saga as order} from './entities/order';
import {saga as venueEditor} from './entities/venueEditor';

export default function* rootSaga() {
    yield all([
        listViewSaga(),
        breadcrumbsSaga(),
        eventSeries(),
        venueEvent(),
        metaEvent(),
        pricingList(),
        pricingMatrix(),
        pricingClass(),
        pricingCategory(),
        purchasableItemTemplate(),
        salesRule(),
        backendUser(),
        seatingType(),
        tenant(),
        artist(),
        eventCategory(),
        media(),
        venue(),
        venuePlan(),
        zukoZone(),
        blockGroup(),
        block(),
        seat(),
        placePool(),
        targetGroup(),
        ticketLayout(),
        order(),
        venueEditor(),
    ])
}
