import React, {Component} from 'react';
import {Container, Row} from 'react-bootstrap';
import ConnectedListView from '../common/listView/ConnectedListView';
import FeedbackButton from '../common/FeedbackButton';
import {connect} from 'react-redux';
import Modal from 'react-bootstrap/Modal';

import {get} from 'lodash';
import * as backendUser from "../../state/entities/backendUser";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDeleteConfirmDialog: false,
            backendUserToDelete: undefined,
        }
    }

    showDeleteConfirmDialog(backendUser) {
        this.setState({
            showDeleteConfirmDialog: true,
            backendUserToDelete: backendUser,
        });
    }

    handleCancelDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
    };

    handleConfirmDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
        this.props.deleteBackendUser(this.state.backendUserToDelete);
    };

    render() {
        return (
            <Container>
                <Row className="my-4">
                    <Breadcrumbs
                        entity={["Benutzer"]}
                        endpoints={['backend_user/breadcrumb']}
                        title="Benutzer"
                        links={['/base/backend-user/']}
                        active={[0]}
                    />
                </Row>
                <ConnectedListView
                    heading="Benutzer"
                    endpoint="listingView/backendUser"
                    buttonTitle="Neuer Benutzer"
                    buttonLink="/base/backend-user/create"
                    testid="link_new_backendUser"
                >
                    {({fields, item}) => (   
                        <>
                            <FeedbackButton
                                to={`/base/backend-user/${item.id}`}
                                className="list-link"
                                title="Benutzer bearbeiten"
                                data-testid="link_edit_backendUser"
                                icon={<EditNoteOutlinedIcon className='table-icon' />}
                            />
                        </>
                    )}
                </ConnectedListView>


                <Modal show={this.state.showDeleteConfirmDialog} onHide={this.handleCancelDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Benutzer löschen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Möchten Sie den Benutzer "{get(this.state, 'backendUserToDelete.name')}" wirklich löschen?</Modal.Body>
                    <Modal.Footer>
                        <FeedbackButton variant="outlined" onClick={this.handleCancelDelete}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton onClick={this.handleConfirmDelete}>
                            Löschen
                        </FeedbackButton>
                    </Modal.Footer>
                </Modal>

            </Container>
        );
    }
}

export default connect(null, {
    deleteBackendUser: entity => backendUser.actions.delete({entity})
})(List);
