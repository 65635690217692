import React from "react";
import { CircularProgress, Dialog, DialogContent} from "@mui/material";



interface ILoadingDialogProps {
    message?: string;
    isLoading: boolean;
}


const LoadingDialog: React.FC<ILoadingDialogProps> = ({message = 'Wird geladen.', isLoading }) => {

    return (
        <Dialog open={isLoading}>
            <DialogContent style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center'
            }}>
                {message}
                <div style={{marginTop: '15px', marginLeft: '13px'}}><CircularProgress /></div>
            </DialogContent>
        </Dialog>
    );
}

export default LoadingDialog;
