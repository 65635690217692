import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';

import ConnectedListView from '../common/listView/ConnectedListView';
import {connect} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import {get} from 'lodash';
import * as media from "../../state/entities/media";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import FeedbackButton from "../common/FeedbackButton";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RefreshIcon from "@mui/icons-material/Refresh";
import {BATCH_ACTION_MOVE_TO_ARCHIVE} from "../common/listView/ListViewBatchActions";

const LinkToArchived = () => {
    return (
        <FeedbackButton
            to={`/base/media/archived`}
            icon={<RefreshIcon />}
            variant='contained'
            className='listLinkCenter'
        >
            Archiv
        </FeedbackButton>
    );
}

const Breadcrumb = () => {
    return (
        <Breadcrumbs entity={["Media"]} endpoints = {['media/breadcrumb']} title = "Medien" links={['/base/media']} active={[0]} />
    )
}

class List extends Component {
    linkNearHeader = LinkToArchived;
    listViewEndpoint = "listingView/media";
    allowedBatchActions = [BATCH_ACTION_MOVE_TO_ARCHIVE];
    breadcrumb = Breadcrumb;
    heading = 'Medien';

    constructor(props) {
        super(props);

        if (props.linkNearHeader !== undefined) {
            this.linkNearHeader = props.linkNearHeader;
        }
        if (props.allowedBatchActions !== undefined) {
            this.allowedBatchActions = props.allowedBatchActions;
        }
        if (props.listViewEndpoint !== undefined) {
            this.listViewEndpoint = props.listViewEndpoint;
        }
        if (props.heading !== undefined) {
            this.heading = props.heading;
        }
        if (props.breadcrumb !== undefined) {
            this.breadcrumb = props.breadcrumb;
        }

        this.state = {
            showDeleteConfirmDialog: false,
            mediaToDelete: undefined,
        }
    }

    showDeleteConfirmDialog(media) {
        this.setState({
            showDeleteConfirmDialog: true,
            mediaToDelete: media,
        });
    }

    handleCancelDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
    };

    handleConfirmDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
        this.props.deleteMedia(this.state.mediaToDelete);
    };

    render() {
        return (
            <Container>
                <Row className="my-4">
                    { this.breadcrumb() }
                </Row>
                <ConnectedListView
                    heading={this.heading}
                    endpoint={this.listViewEndpoint}
                    buttonTitle="Neue Medien"
                    buttonLink="/base/media/create"
                    testid="link_new_media"
                    isShowSelectionWithBatchActions={true}
                    allowedActions={this.allowedBatchActions}
                    linkNearHeading={this.linkNearHeader}
                >
                    {({fields, item}) => (
                        <>
                            <FeedbackButton
                                className="list-link"
                                title="Medien bearbeiten"
                                to={`/base/media/${item.id}`}
                                icon={<EditNoteOutlinedIcon className='table-icon' />}
                            />
                            <FeedbackButton title="Medien löschen" className="list-link" onClick={() => this.showDeleteConfirmDialog(item)}>
                                <DeleteOutlineOutlinedIcon className='table-icon' />
                            </FeedbackButton>
                        </>
                    )}
                </ConnectedListView>

                <Modal show={this.state.showDeleteConfirmDialog} onHide={this.handleCancelDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Medien löschen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Möchten Sie die Medien "{get(this.state, 'mediaToDelete.nameDe')}" wirklich löschen?</Modal.Body>
                    <Modal.Footer>
                        <FeedbackButton variant="outlined" onClick={this.handleCancelDelete}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton onClick={this.handleConfirmDelete}>
                            Löschen
                        </FeedbackButton>
                    </Modal.Footer>
                </Modal>
            </Container>
        );
    }
}

export default connect(null, {
    deleteMedia: entity => media.actions.delete({entity})
})(List);
