import React from "react";
import PropTypes from 'prop-types';

const TextContent = (props) => {
    return <div className="container pb-2">
        <div className="row mb-2">
            <div className="offset-2 col-8">
                {props.breadcrumb}
            </div>
        </div>
        <div className="row">
            <div className="offset-2 col-8">
                {props.children}
            </div>
        </div>
    </div>
};

TextContent.propTypes = {
    breadcrumb: PropTypes.element
};
export default TextContent;
