import * as types from './types';
import produce from 'immer';
import {combineReducers} from 'redux';

const query = produce((state = {}, {type, payload}) => {
    switch (type) {
        case types.QUERY_BREADCRUMBS:
            state[payload.endpoint] = payload.query;
            break;

        case types.QUERY_BREADCRUMBS_SUCCESS:
            state[payload.endpoint] = {
                ...state[payload.endpoint]
            };
            break;

        default:
            return state;
    }
});

const result = produce((state = {}, {type, payload}) => {
    switch (type) {
        case types.QUERY_BREADCRUMBS_SUCCESS:
            state[payload.endpoint] = payload.result;
            break;

        default:
            return state;
    }
});

const loading = produce((state = {}, {type, payload}) => {
    switch (type) {
        case types.QUERY_BREADCRUMBS:
            state[payload.endpoint] = true;
            break;

        case types.QUERY_BREADCRUMBS_ERROR:
        case types.QUERY_BREADCRUMBS_SUCCESS:
            state[payload.endpoint] = false;
            break;

        default:
            return state;
    }
});

export default combineReducers({
    query,
    result,
    loading,
});



