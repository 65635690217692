import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import ConnectedListView from '../common/listView/ConnectedListView';
import FeedbackButton from '../common/FeedbackButton';
import {connect} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {get} from 'lodash';
import * as purchasableItemTemplate from "../../state/entities/purchasableItemTemplate";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import {BATCH_ACTION_MOVE_TO_ARCHIVE} from "../common/listView/ListViewBatchActions";
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

const LinkToArchived = () => {
    return (
        <FeedbackButton
            to={`/event-management/purchasable-item-template/archived`}
            icon={<RefreshIcon />}
            variant='contained'
            className='listLinkCenter'
        >
            Archiv
        </FeedbackButton>
    );
}

const Breadcrumb = () => {
    return (
        <Breadcrumbs entity={["Leistungsvorlage"]} endpoints = {['purchasable_item_template/breadcrumb']} title = "Leistungsvorlagen" links={['/event-management/purchasable-item-template/']} active={[0]} />
    )
}

class List extends Component {
    linkNearHeader = LinkToArchived;
    heading = 'Leistungsvorlagen';
    listViewEndpoint = "listingView/purchasableItemTemplate";
    breadcrumb = Breadcrumb;
    allowedBatchActions = [BATCH_ACTION_MOVE_TO_ARCHIVE];
    constructor(props) {
        super(props);

        if (props.linkNearHeader !== undefined) {
            this.linkNearHeader = props.linkNearHeader;
        }
        if (props.heading !== undefined) {
            this.heading = props.heading;
        }
        if (props.listViewEndpoint !== undefined) {
            this.listViewEndpoint = props.listViewEndpoint;
        }
        if (props.breadcrumb !== undefined) {
            this.breadcrumb = props.breadcrumb;
        }
        if (props.allowedBatchActions !== undefined) {
            this.allowedBatchActions = props.allowedBatchActions;
        }

        this.state = {
            showCopyConfirmDialog: false,
            purchasableItemTemplateToCopy: undefined,
            showDeleteConfirmDialog: false,
            purchasableItemTemplateToDelete: undefined
        }
    }

    showCopyConfirmDialog(purchasableItemTemplate) {
        this.setState({
            showCopyConfirmDialog: true,
            purchasableItemTemplateToCopy: purchasableItemTemplate,
        });
    }

    handleCancelCopy = () => {
        this.setState({showCopyConfirmDialog: false});
    };

    handleConfirmCopy = () => {
        this.setState({showCopyConfirmDialog: false});
        this.props.copyPurchasableItemTemplate(this.state.purchasableItemTemplateToCopy);
    };

    showDeleteConfirmDialog(purchasableItemTemplate) {
        this.setState({
            showDeleteConfirmDialog: true,
            purchasableItemTemplateToDelete: purchasableItemTemplate,
        });
    }

    handleCancelDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
    };

    handleConfirmDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
        this.props.deletePurchasableItemTemplate(this.state.purchasableItemTemplateToDelete);
    };

    render() {
        return (
            <Container>
                <Row className="my-4">
                    { this.breadcrumb() }
                </Row>
                <ConnectedListView
                    heading={this.heading}
                    endpoint={this.listViewEndpoint}
                    testid="link_new_purchasableItem"
                    buttonTitle="Neue Leistungsvorlage"
                    buttonLink="/event-management/purchasable-item-template/create"
                    linkNearHeading={this.linkNearHeader}
                    isShowSelectionWithBatchActions={true}
                    allowedActions={this.allowedBatchActions}
                >
                    {({fields, item}) => (
                        <>
                            <FeedbackButton
                                className="list-link"
                                title="Leistungsvorlage bearbeiten"
                                to={`/event-management/purchasable-item-template/${item.id}`}
                                icon={<EditNoteOutlinedIcon className='table-icon' />}
                            />
                            <FeedbackButton title="Leistungsvorlage kopieren" className="list-link" onClick={() => this.showCopyConfirmDialog(item)}>
                                <ContentCopyOutlinedIcon className='table-icon' />
                            </FeedbackButton>
                            <FeedbackButton title="Leistungsvorlage löschen" className="list-link" onClick={() => this.showDeleteConfirmDialog(item)}>
                                <DeleteOutlineOutlinedIcon className='table-icon' />
                            </FeedbackButton>
                        </>
                    )}
                </ConnectedListView>


                <Modal show={this.state.showCopyConfirmDialog} onHide={this.handleCancelCopy}>
                    <Modal.Header closeButton>
                        <Modal.Title>Leistungsvorlage kopieren</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Möchten Sie die Leistungsvorlage "{get(this.state, 'purchasableItemTemplateToCopy.name')}" wirklich kopieren?</Modal.Body>
                    <Modal.Footer>
                        <FeedbackButton variant="outlined" onClick={this.handleCancelCopy}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton onClick={this.handleConfirmCopy}>
                            Kopieren
                        </FeedbackButton>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showDeleteConfirmDialog} onHide={this.handleCancelDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Leistungsvorlage löschen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Möchten Sie die Leistungsvorlage "{get(this.state, 'purchasableItemTemplateToDelete.name')}" wirklich löschen?</Modal.Body>
                    <Modal.Footer>
                        <FeedbackButton variant="outlined" onClick={this.handleCancelDelete}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton onClick={this.handleConfirmDelete} data-testid="delete">
                            Löschen
                        </FeedbackButton>
                    </Modal.Footer>
                </Modal>

            </Container>
        );
    }
}

export default connect(null, {
    copyPurchasableItemTemplate: entity => purchasableItemTemplate.actions.copy({entity}),
    deletePurchasableItemTemplate: entity => purchasableItemTemplate.actions.delete({entity})
})(List);
