import React, {Component} from 'react';
import * as yup from 'yup';
import {Formik} from 'formik';
import {Card, Col, Row} from 'react-bootstrap';
import {FormikTextInputGroup} from '../../../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../../../common/FeedbackButton';
import MultiSelectList from "../../../common/form/MultiSelectList";
import {connect} from "react-redux";
import * as block from "../../../../state/entities/block";
import {denormalize} from "normalizr";

import * as schema from '../../../../state/schema';
import {getByVenuePlanId} from "../../../../state/entities/block/selectors";
import Footer from "../../../layout/Footer";
import BackButton from "../../../common/BackButton";
import styles from "../../../form.module.scss";

const getInitialValues = (venuePlanId) => (
    {
        name: {
            de: '',
            en: ''
        },
        backendNameShort: '',
        venuePlan: {id: venuePlanId},
        blocks: []
    }
);

const FORM_SCHEMA = yup.object().shape({
    name: yup.object().shape({
        de: yup.string().required(),
        en: yup.string()
    }),
    backendNameShort: yup.string().required(),
});

class Form extends Component {

    componentDidMount() {
        this.props.loadBlocks(this.props.venuePlanId);
    };

    render() {
        let {onSubmit, submitPending, blockGroup, venuePlanId, allBlocksInVenueBlocks, helpTextsVisible} = this.props;
        const blockSelectFieldMap = {
            "label.de": 'Titel',
            "backendNameShort": 'Kurztitel',
            "entrance.de": 'Eingangshinweis',
            "blockType": 'Typ'
        };

        return <Formik
            initialValues={{...getInitialValues(venuePlanId), ...blockGroup}}
            validationSchema={FORM_SCHEMA}
            onSubmit={onSubmit}
        >
            {formik => {
                const handleBlockSelectionChange = (blockSelection) => {
                    const selectedBlocks = blockSelection.filter(selection => typeof (selection) === 'object');
                    formik.setFieldValue("blocks", selectedBlocks);
                };
                return (
                    <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                        <div className={styles.formBox}>
                            <h2 className={styles.formTitle}>Allgemein</h2>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Titel der Blockgruppe (deutsch)*" name="name.de" testid="name_german"
                                                            helpTextsVisible={helpTextsVisible} helpText="Dieser Name wird auf deutschsprachigen Tickets angezeigt."/>
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Titel der Blockgruppe (englisch)" name="name.en" testid="name_english"
                                                            helpTextsVisible={helpTextsVisible} helpText="Dieser Name wird auf englischsprachigen Tickets angezeigt."/>
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Kurztitel der Blockgruppe*" name="backendNameShort" testid="shortName"
                                                            helpTextsVisible={helpTextsVisible} helpText="Dieser Name wird nur im Backend benutzt."/>
                                </Col>
                            </Row>
                        </div>
                        <div className={styles.formBox}>
                            <Row className="mt-3">
                                <Col>
                                    <h2 className={styles.formTitle}>Zugehörige Blöcke</h2>
                                    <MultiSelectList
                                        fieldMap={blockSelectFieldMap}
                                        elements={allBlocksInVenueBlocks}
                                        selected={blockGroup.blocks}
                                        onChange={handleBlockSelectionChange}
                                        testid="check"
                                    />
                                </Col>

                            </Row>
                        </div>
                        <Footer>
                            {/*
                            FIXME: auf Grund der aktuellen Routenstruktur ist es hier nicht möglich den Link Button
                            zu verwenden um in die Edit-Seite eines Saalplans/Listing der Blockgruppen zu gelangen,
                            da die Venue-ID hier nicht bekannt ist und sowohl die VenuePlan-ID als auch die
                            BlockGroup-ID aus der URL gepuhlt werden.
                            Der Browserback ist nicht in allen Fällen korrekt (bspw. nicht bei externen Einsprüngen)
                            und die Problematik sollte im Zuge der "Breadcrums" behoben werden.
                            */}
                            {/*<LinkButton variant="link" to={{pathname: `/base/venue/${venueId}/venue-plan/${venuePlanId}`}} >*/}
                            {/*    Abbrechen*/}
                            {/*</LinkButton>*/}
                            <BackButton variant="link">
                                Abbrechen
                            </BackButton>
                            <FeedbackButton
                                type="submit"
                                busy={submitPending}
                            >
                                Speichern
                            </FeedbackButton>
                        </Footer>
                    </form>
                )
            }}
        </Formik>
    }
}


Form.propTypes = {
    onSubmit: PropTypes.any,
    submitPending: PropTypes.any,
    blockGroup: PropTypes.any,
    requestErrors: PropTypes.any
};

const mapStateToProps = (state, props) => {

    const blockGroup = denormalize(props.blockGroup, schema.blockGroup, {
        block: state.entities.block.byId
    });
    const helpTextsVisible = state.helpTextsToggle;
    return {
        blockGroup,
        allBlocksInVenueBlocks: getByVenuePlanId(state, props.venuePlanId),
        helpTextsVisible
    }
};

export default connect(mapStateToProps, {
    loadBlocks: id => block.actions.byVenuePlan({id})
})(Form);


