import React, {Component} from 'react';
import * as pricingList from '../../../state/entities/pricingList';
import * as pricingMatrixState from '../../../state/entities/pricingMatrix';
import * as pricingClass from '../../../state/entities/pricingClass';
import * as pricingCategory from '../../../state/entities/pricingCategory';
import * as purchasableItemTemplate from '../../../state/entities/purchasableItemTemplate';
import {connect} from 'react-redux';
import {LoadingIndicator} from '../../common/LoadingIndicator';
import {Col, Row} from 'react-bootstrap';
import FeedbackButton from "../../common/FeedbackButton";
import {get, isArray} from 'lodash';
import Matrix from './Matrix';
import ObjectSelect from './ObjectSelect';
import PurchasableItemDragPrototype from './PurchasableItemDragPrototype';
import ConfirmModal from '../../common/modal/ConfirmModal';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import {getSelectableForPricingMatrix} from '../../../state/entities/purchasableItemTemplate/selectors';
import {
    addPurchasableItemTemplate,
    changeLocalItemTemplate,
    clearAllPrices,
    clearUnusedPurchasableItemTemplates,
    toggleEditLocalItemTemplate,
    toggleIncludeStrikeAmount
} from '../../../state/entities/pricingMatrix/actions';
import ReactDOM from 'react-dom';
import styles from './pricingMatrix.module.scss';
import {Link} from "react-router-dom";
import Breadcrumbs from "../../common/breadcrumbs/Breadcrumbs";
import Form from "../Form";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

class Edit extends Component {

    constructor(props) {
        super(props);
        // Create a div that we'll render the modal into. Because each
        // Modal component has its own element, we can render multiple
        // modal components into the modal container.
        this.el = document.createElement('div');
        this.state = {
            sidebarClosed: false
        }
    }

    componentDidMount = () => {
        this.props.loadDependencies(this.props.pricingListId);
    };

    onSubmit = (pricingListData) => {
        this.props.savePricingMatrixWithPricingList(this.props.pricingMatrix, pricingListData)
    };

    render() {
        const {
            pricingMatrix, pricingList,
            selectablePurchasableItemTemplates, addPurchasableItemTemplate,
            clearUnusedItemTemplates, clearAllPrices, togglePricingComponentsVisible, pricingComponentsVisible,
            onLocalItemTemplateChange, toggleEditLocalItemTemplate, helpTextsVisible, onToggleIncludeStrikeAmount
        } = this.props;

        const sidebarRoot = document.getElementById('sidebar');
        if (sidebarRoot) {
            if (!sidebarRoot.hasChildNodes()) {
                sidebarRoot.appendChild(this.el);
            }
        }

        if (!pricingList || pricingList.loading || !pricingMatrix || !sidebarRoot) {
            return <LoadingIndicator/>;
        }

        const toggleSidebar = () => {
            this.setState({sidebarClosed: !this.state.sidebarClosed})
        };

        if (this.state.sidebarClosed) {
            sidebarRoot.classList.add('sidebarClosed');
        } else {
            sidebarRoot.classList.remove('sidebarClosed');
        }

        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Preisliste"]}
                             endpoints = {[(pricingList.archived ? 'archived_pricing_list/breadcrumb' :'pricing_list/breadcrumb')]}
                             title = "Preislisten"
                             links={[`/event-management/pricing-list/`]}
                             active={[pricingList.id]}
                />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Bearbeiten der Preisliste <em>{pricingList.name}</em></h1>
                </Col>
            </Row>
            <Form pricingList={pricingList} onSubmit={this.onSubmit} submitPending={this.props.savingWithPricingList}/>

            <DndProvider backend={HTML5Backend}>
                <Row>
                    <Col><h1><span className="text-muted">Bearbeitungsmodus</span></h1></Col>
                </Row>
                <Row>
                    <Col>
                        <p className={!helpTextsVisible ? "hidden" : "text-muted"}>
                            In der Preismatrix werden die Leistungskomponenten (linke Sidebar) den Preiskategorien und Preisklassen zugewiesen.
                            Dazu werden zunächst bestehende Preiskategorien, Preisklassen und Leistungskomponenten über die jeweiligen Plus-Buttons hinzugefügt.
                            Die Leistungskomponenten können dann per Drag and Drop in das entsprechende Feld gezogen werden. Dort muss dann der Bruttopreis
                            noch eingetragen werden. Dieser darf nicht niedriger sein als die Summe der Preiskomponenten in den Leistungskomponenten (brutto).
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Matrix pricingMatrix={pricingMatrix}/>
                    </Col>

                    {ReactDOM.createPortal(
                        // Any valid React child: JSX, strings, arrays, etc.

                        (<>
                            <Col className={["mt-3 px-4", styles.stickySidebar].join(" ")}>
                                <FeedbackButton variant="outlined" className="d-block px-0 arrow-right" size="sm"
                                        onClick={() => toggleSidebar()}>
                                    <i className="fa fa-angle-double-right fa-2x mb-2"></i>
                                </FeedbackButton>
                                <FeedbackButton variant="outlined" className="d-block px-0 arrow-left" size="sm"
                                        onClick={() => toggleSidebar()}>
                                    <i className="fa fa-angle-double-left fa-2x mb-2"></i>
                                </FeedbackButton>
                                <div className="sidebarContent">
                                    <FeedbackButton variant="outlined" className="p-0 mb-2" onClick={togglePricingComponentsVisible}>
                                        {pricingComponentsVisible
                                            ? <><VisibilityOffOutlinedIcon className='table-icon' />&nbsp;Leistungskomponenten
                                                ausblenden</>
                                            : <><RemoveRedEyeOutlinedIcon className='table-icon' />&nbsp;Leistungskomponenten einblenden</>
                                        }
                                    </FeedbackButton>

                                    <FeedbackButton variant="outlined" className="p-0 mb-2" onClick={() => { onToggleIncludeStrikeAmount(pricingMatrix)}}>
                                        <i className={pricingMatrix.includeStrikeAmount ? "fa fa-lg fa-check-square-o" : "fa fa-lg fa-square-o"}
                                           aria-hidden="true"/>&nbsp;Streichpreis anlegen
                                    </FeedbackButton>

                                    <ConfirmModal title="Preise leeren"
                                                  body="Möchten sie wirklich alle Preise leeren?"
                                                  cancelLabel="Abbrechen"
                                                  confirmLabel="Leeren">
                                        {confirm =>
                                            <FeedbackButton variant="outlined" className="p-0 mb-2"
                                                    onClick={() => confirm(() => clearAllPrices(pricingMatrix))}>
                                                &nbsp;&nbsp;
                                                <DeleteOutlineOutlinedIcon className='table-icon' />
                                                &nbsp;
                                                Preise leeren
                                            </FeedbackButton>
                                        }
                                    </ConfirmModal>

                                    <FeedbackButton variant="outlined" className="p-0 mb-2"
                                            onClick={() => {this.props.loadDependencies(this.props.pricingListId);}}>
                                        &nbsp;&nbsp;<i className="fa fa-lg fa-refresh" aria-hidden="true"/>&nbsp;
                                    </FeedbackButton>

                                    {
                                        pricingMatrix.availableLocalItemTemplates &&
                                        isArray(pricingMatrix.availableLocalItemTemplates) &&
                                        pricingMatrix.availableLocalItemTemplates.length > 0 &&
                                        <>
                                            <p>In dieser Preisliste verfügbare Leistungsvorlagen</p>
                                            {pricingMatrix.availableLocalItemTemplates.map(localItemTemplate =>
                                                <PurchasableItemDragPrototype
                                                    key={localItemTemplate.id}
                                                    pricingComponentsVisible={pricingComponentsVisible}
                                                    localItemTemplate={localItemTemplate}
                                                    onOverrideChange={(values) => onLocalItemTemplateChange(pricingMatrix, localItemTemplate, values)}
                                                    toggleEditLocalItemTemplate={() => {
                                                        toggleEditLocalItemTemplate(pricingMatrix.id, localItemTemplate.id)
                                                    }}
                                                />
                                            )}
                                        </>
                                    }

                                    <Row>
                                        <Col className="col-12 text-center mb-2">
                                            <Link target="_blank" className={'text-center'} variant="link" to={`/event-management/purchasable-item-template/create`}>
                                                Neue Leistungsvorlage erstellen
                                            </Link>
                                        </Col>
                                    </Row>

                                    <ObjectSelect
                                        label="  Weitere Leistungsvorlage hinzufügen"
                                        classname="dropdown-right"
                                        alignRight={false}
                                        drop="down"
                                        width="dropdown-btn-width"
                                        buttonHint="Hinzufügen"
                                        emptySelection="«Leistungsvorlage auswählen»"
                                        options={selectablePurchasableItemTemplates}
                                        onSelect={(itemTemplate) => addPurchasableItemTemplate(pricingMatrix.id, itemTemplate)}
                                        optionLabel={itemTemplate => `${itemTemplate.name}`}
                                    />
                                    <ConfirmModal title="Zuweisung löschen"
                                                  body="Wollen Sie die nicht verwendeten Leistungvorlagen wirklich aus der Preismatrix entfernen?">
                                        {confirm =>
                                            <FeedbackButton 
                                                variant="outlined"
                                                className="p-0"
                                                onClick={() => confirm(() => clearUnusedItemTemplates(pricingMatrix))}
                                            >
                                                <DeleteOutlineOutlinedIcon className='table-icon' />
                                                &nbsp;
                                                Alle nicht verwendeten Leistungsvorlagen entfernen
                                            </FeedbackButton>
                                        }
                                    </ConfirmModal>
                                </div>
                            </Col>
                        </>)
                        ,
                        // A DOM element
                        this.el,
                    )}
                </Row>
            </DndProvider>
        </>
    }
}

const mapStateToProps = (state, props) => {
    const pricingListId = props.match.params.id;
    const pricingList = state.entities.pricingList.byId[pricingListId];

    const pricingMatrixId = get(pricingList, 'pricingMatrix');
    const pricingMatrix = state.entities.pricingMatrix.byId[pricingMatrixId] || {};
    const savingWithPricingList = state.entities.pricingMatrix.savingWithPricingList[pricingMatrixId];

    const loading = !pricingList
        || state.entities.pricingList.loading[pricingListId]
        || state.entities.pricingCategory.loadingAll
        || state.entities.pricingClass.loadingAll
        || state.entities.purchasableItemTemplate.loadingAll;

    const helpTextsVisible = state.helpTextsToggle;

    return {
        loading,
        pricingListId,
        pricingList,
        pricingMatrix,
        savingWithPricingList,
        selectablePurchasableItemTemplates: getSelectableForPricingMatrix(state, pricingMatrix),
        pricingComponentsVisible: state.entities.pricingMatrix.pricingComponentsVisible,
        includeStrikeAmountVisible: state.entities.pricingMatrix.includeStrikeAmount,
        helpTextsVisible
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadDependencies: pricingListId => {
            dispatch(pricingList.actions.load({id: pricingListId}));
            dispatch(pricingCategory.actions.loadAll());
            dispatch(pricingClass.actions.loadAll());
            dispatch(purchasableItemTemplate.actions.loadAll());
        },
        addPurchasableItemTemplate: (matrixId, purchasableItemTemplate) => dispatch(addPurchasableItemTemplate({
            matrixId,
            purchasableItemTemplate
        })),
        savePricingMatrixWithPricingList: (pricingMatrix, pricingList) => dispatch(pricingMatrixState.actions.saveWithPricingList({pricingMatrix, pricingList})),
        clearUnusedItemTemplates: pricingMatrix => dispatch(clearUnusedPurchasableItemTemplates({matrixId: pricingMatrix.id})),
        clearAllPrices: pricingMatrix => dispatch(clearAllPrices({matrixId: pricingMatrix.id})),
        onLocalItemTemplateChange: (pricingMatrix, localItemTemplate, overrides) => {
            dispatch(changeLocalItemTemplate({
                matrixId: pricingMatrix.id,
                localItemTemplate: {...localItemTemplate, ...overrides}
            }))
        },
        togglePricingComponentsVisible: () => dispatch(pricingMatrixState.actions.togglePricingComponentsVisible()),
        toggleEditLocalItemTemplate: (matrixId, localItemTemplateId) => dispatch(toggleEditLocalItemTemplate({
            matrixId,
            localItemTemplateId
        })),
        onToggleIncludeStrikeAmount: (pricingMatrix) => dispatch(toggleIncludeStrikeAmount({
            matrixId: pricingMatrix.id
        }))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
