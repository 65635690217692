import React, {Component} from 'react';
import Form from "./Form";
import {actions} from "../../state/entities/metaEvent";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Create extends Component {

    onSubmit = (metaEvent) => {
        let event = metaEvent;
        if (metaEvent.maxTicketsPerUser === "") {
            event = {...metaEvent, maxTicketsPerUser: null}
        }
        this.props.create(event);
    };

    render() {
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Event"]} endpoints={['meta_event/breadcrumb']} title="Meta-Events"
                             links={['/event-management/meta-event/']} active={[0]}/>
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Meta-Event erstellen</h1>
                </Col>
            </Row>
            <Form metaEvent={{}} onSubmit={this.onSubmit} submitPending={this.props.createPending}/>
        </>;
    }
}

const mapStateToProps = (state) => {
    return {
        createPending: state.entities.metaEvent.createPending
    }
};

const mapDispatchToProps = dispatch => {
    return {
        create: entity => dispatch(actions.create({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
