import React from 'react';
import Form from 'react-bootstrap/Form';

/**
 * Dropdown Item
 */
const DropdownItemSeatException = ({index, handleFilterChange}) => {


    return (
        <div className="disable dropdown-item disabled">
            <Form>
                <Form.Group className="breadcrumb-input-item">
                    <p className="breadcrumb-input-label">Suche</p>
                    <Form.Label className="breadcrumb-input-label small">Reihe</Form.Label>
                    <Form.Control type="text" className="breadcrumb-input"
                                  onChange={event => handleFilterChange(event, 'rowLabel', index)}/>
                    <Form.Label className="breadcrumb-input-label small">Platz</Form.Label>
                    <Form.Control type="text" className="breadcrumb-input"
                                  onChange={event => handleFilterChange(event, 'seatLabel', index)}/>
                </Form.Group>
            </Form>
        </div>
    )

}

export default DropdownItemSeatException;

