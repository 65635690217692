export * from './Seat';

/**
 * Modus welches Hintergrundbild aktuell im Editor angezeigt/bearbeitet wird.
 */
export type VenueBackdropMode =
    | 'BACKEND'
    | 'FRONTEND'


export enum SortDirection {
    ASCENDING = "ascending",
    DESCENDING = "descending"
}
