import React from 'react';
import styles from './MuiBoxLikeFormField.module.scss'
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';

interface MuiBoxLikeFormFieldProps {
    value: string | null;
    label?: string;
    mediaId?: string;
    placeholder?: string;
    onHanldleRemove?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    onHandleShowModal?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    fieldClassName?: string;
    showCloseIcon?: boolean;
    disabled?: boolean;
}

export const MuiBoxLikeFormField: React.FC<MuiBoxLikeFormFieldProps> = ({
    value,
    label,
    mediaId,
    fieldClassName,
    placeholder,
    onHanldleRemove,
    onHandleShowModal,
    disabled = false,
    showCloseIcon = false,
}) => {
    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (disabled) return;
        if (!value) {
            onHandleShowModal(e);
        } else {
            mediaId && window.open(`/base/media/${mediaId}`, '_blank');
        }
    }

    return (
        <div className={styles.wrap}>
            {label && <span className={styles.label}>{label}</span>}
            <span
                className={classNames(styles.field, { [fieldClassName]: value })}
                onClick={onClick}
            >
                <span>
                    {value ? value : <i>{placeholder}</i>}
                </span>
                {value && showCloseIcon && (
                    <IconButton onClick={onHanldleRemove} className={styles.closeIcon}>
                        <CloseIcon />
                    </IconButton>
                )}
            </span>
        </div>
    )
};

export default MuiBoxLikeFormField
