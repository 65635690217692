import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';

import * as pricingCategory from "../../state/entities/pricingCategory";

import ConnectedListView from '../common/listView/ConnectedListView';
import {Link} from 'react-router-dom';
import FeedbackButton from '../common/FeedbackButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import {connect} from 'react-redux';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Modal from 'react-bootstrap/Modal';
import {get} from 'lodash';
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import {BATCH_ACTION_MOVE_TO_ARCHIVE} from "../common/listView/ListViewBatchActions";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

const LinkToArchived = () => {
    return (
        <FeedbackButton
            to={`/base/pricing-category/archived`}
            icon={<RefreshIcon />}
            variant='contained'
            className='listLinkCenter'
        >
            Archiv
        </FeedbackButton>
    );
}

const Breadcrumb = () => {
    return (
        <Breadcrumbs entity={["Preiskategorie"]} endpoints = {['pricing_category/breadcrumb']} title = "Preiskategorien" links={['/base/pricing-category/']} active={[0]} />
    )
}

class List extends Component {
    linkNearHeader = LinkToArchived;
    heading = 'Preiskategorien';
    listViewEndpoint = "listingView/pricingCategory";
    breadcrumb = Breadcrumb;
    allowedBatchActions = [BATCH_ACTION_MOVE_TO_ARCHIVE];

    constructor(props) {
        super(props);

        if (props.linkNearHeader !== undefined) {
            this.linkNearHeader = props.linkNearHeader;
        }

        if (props.heading !== undefined) {
            this.heading = props.heading;
        }

        if (props.listViewEndpoint !== undefined) {
            this.listViewEndpoint = props.listViewEndpoint;
        }

        if (props.breadcrumb !== undefined) {
            this.breadcrumb = props.breadcrumb;
        }

        if (props.allowedBatchActions !== undefined) {
            this.allowedBatchActions = props.allowedBatchActions;
        }

        this.state = {
            showCopyConfirmDialog: false,
            pricingCategoryToCopy: undefined,
            showDeleteConfirmDialog: false,
            pricingCategoryToDelete: undefined
        }
    }

    showCopyConfirmDialog(pricingCategory) {
        this.setState({
            showCopyConfirmDialog: true,
            pricingCategoryToCopy: pricingCategory,
        });
    }

    handleCancelCopy = () => {
        this.setState({showCopyConfirmDialog: false});
    };

    handleConfirmCopy = () => {
        this.setState({showCopyConfirmDialog: false});
        this.props.copyPricingCategory(this.state.pricingCategoryToCopy);
    };

    showDeleteConfirmDialog(pricingCategory) {
        this.setState({
            showDeleteConfirmDialog: true,
            pricingCategoryToDelete: pricingCategory,
        });
    }

    handleCancelDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
    };

    handleConfirmDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
        this.props.deletePricingCategory(this.state.pricingCategoryToDelete);
    };

    render() {
        return (
            <Container>
                <Row className="my-4">
                    { this.breadcrumb() }
                </Row>
                <ConnectedListView
                    endpoint={this.listViewEndpoint}
                    heading={this.heading}
                    buttonTitle="Neue Preiskategorie"
                    buttonLink="/base/pricing-category/create"
                    testid="link_new_pricingCategory"
                    linkNearHeading={this.linkNearHeader}
                    isShowSelectionWithBatchActions={true}
                    allowedActions={this.allowedBatchActions}
                >
                    {({fields, item}) => (
                        <>
                            <FeedbackButton
                                className="list-link"
                                title="Preiskategorie bearbeiten"
                                to={`/base/pricing-category/${item.id}`}
                                icon={<EditNoteOutlinedIcon className='table-icon' />}
                            />
                            <FeedbackButton title="Preiskategorie kopieren" className="list-link" onClick={() => this.showCopyConfirmDialog(item)} data-testid="link_copy_pricingCategory">
                                <ContentCopyOutlinedIcon className='table-icon' />
                            </FeedbackButton>
                            <FeedbackButton title="Preiskategorie löschen" className="list-link" onClick={() => this.showDeleteConfirmDialog(item)} data-testid="link_delete_pricingCategory">
                                <DeleteOutlineOutlinedIcon className='table-icon' />
                            </FeedbackButton>
                        </>
                    )}
                </ConnectedListView>


                <Modal show={this.state.showCopyConfirmDialog} onHide={this.handleCancelCopy}>
                    <Modal.Header closeButton>
                        <Modal.Title>Preiskategorie kopieren</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Möchten Sie die Preiskategorie "{get(this.state, 'pricingCategoryToCopy.name')}" wirklich kopieren?</Modal.Body>
                    <Modal.Footer>
                        <FeedbackButton variant="outlined" onClick={this.handleCancelCopy}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton onClick={this.handleConfirmCopy}>
                            Kopieren
                        </FeedbackButton>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showDeleteConfirmDialog} onHide={this.handleCancelDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Preiskategorie löschen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Möchten Sie die Preiskategorie "{get(this.state, 'pricingCategoryToDelete.name')}" wirklich löschen?</Modal.Body>
                    <Modal.Footer>
                        <FeedbackButton variant="outlined" onClick={this.handleCancelDelete}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton onClick={this.handleConfirmDelete}>
                            Löschen
                        </FeedbackButton>
                    </Modal.Footer>
                </Modal>

            </Container>
        );
    }
}

export default connect(null, {
    copyPricingCategory: entity => pricingCategory.actions.copy({entity}),
    deletePricingCategory: entity => pricingCategory.actions.delete({entity})
})(List);
