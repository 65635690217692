import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {LoadingIndicator} from "../common/LoadingIndicator";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import {Col, Row} from "react-bootstrap";
import VerticalSpacer from "../layout/VerticalSpacer";
import * as order from "../../state/entities/order";
import {Tab, Tabs} from "@mui/material";
import TabsPanel from "../common/TabsPanel/TabsPanel";
import Overview from "./Overview";
import Payments from "./Payments";
import Cancellations from "./Cancellations";
import Invoices from "./Invoices";
import styles from "../form.module.scss";
import FeedbackButton from "../common/FeedbackButton";
import Footer from "../layout/Footer";
import Api from "../../api";

const Details = ({loadOrder, orderId, order, loading}) => {
    const [currentTab, setCurrentTab] = useState('overview');
    const api = new Api();
    useEffect(() => {
        loadOrder(orderId);
    }, []);

    const handleExport = () => {
        api.getBIData(order.id)
            .then(data => {
                const jsonData = JSON.stringify(data, null, 2);
                const blob = new Blob([jsonData], {type: 'text/plain'});
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'exported_data.txt');
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
                document.body.removeChild(link);
            })
            .catch(error => {
                console.error('Error fetching BI Data:', error);
            });
    };

    if (!order || loading) {
        return <LoadingIndicator/>;
    }
    return <>
        <Row className="my-4">
            <Breadcrumbs entity={['Spielstätte', 'Saalplan']} endpoints={['order/breadcrumb']} title = "Orders" links={['/sales/order/']} active={[order.id]} />
        </Row>

        <Row className="mb-4 mt-4">
            <Col className="pt-3 col-sm-8">
                <h1>Bestellung: <em>{order.id}</em></h1>
            </Col>
        </Row>
        <Tabs id="uncontrolled-tab-example" value={currentTab} onChange={(event, newTab) => setCurrentTab(newTab)} className={styles.tabsContainer}>
            <Tab value="overview" label="Übersicht" />
            <Tab value="payments" label="Zahlungsvorgänge" />
            <Tab value="cancellations" label="Stornierungen" />
            <Tab value="invoices" label="Belege" />
        </Tabs>

        <TabsPanel value={currentTab} index={'overview'} data-event-key='overwiev'>
            <Overview order={order}/>
        </TabsPanel>

        <TabsPanel value={currentTab} index={'payments'} data-event-key='payments'>
            <Payments order={order}/>
        </TabsPanel>

        <TabsPanel value={currentTab} index={'cancellations'} data-event-key='cancellations'>
            <Cancellations order={order}/>
        </TabsPanel>

        <TabsPanel value={currentTab} index={'invoices'} data-event-key='invoices'>
            <Invoices order={order}/>
        </TabsPanel>

        <Footer>
            <FeedbackButton to={`/sales/order`}>
                Abbrechen
            </FeedbackButton>
            <FeedbackButton onClick={handleExport}>
                Export BI Data
            </FeedbackButton>
        </Footer>

        <VerticalSpacer size={4}/>
    </>;
}

const mapStateToProps = (state, props) => {
    const orderId = props.match.params.id;
    return {
        orderId,
        loading: state.entities.order.loading[orderId],
        saving: state.entities.order.saving[orderId],
        order: state.entities.order.byId[orderId]
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadOrder: id => dispatch(order.actions.load({id}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
