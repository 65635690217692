import * as PIXI from 'pixi.js';
import {Seat, SeatRecord} from '../../types';
import {MOUSE_WHEEL_ZOOM_EVENT, setupRenderer, setupViewport} from './index';
import {DisplayContext, getIdleState, InteractionMode, InteractionState} from './interaction';
import {Scene} from './scene';
import {DataManager} from './data';
import {InteractionCallback, VenuePlanInteractionEvent} from './interaction/event';
import {debounce} from 'lodash';
import {RowLabelVisibilityMode} from './labels/rows';
import {DisplayEvent, EventType} from './interaction/common';
import {Point} from '../geometry';
import {MOVE_ON_GRID_SIZE} from './data/common';
import {snapToGrid} from '../geometry/util';
import { AreaFormsManager, IAreaFormsManager } from './areaForms/areaFormsManager';
import { IImagesManager, ImagesManager } from './images/imagesManager';
import { Viewport } from 'pixi-viewport';
import { easeInOutCubic, easeOutCubic } from 'js-easing-functions';
import { CenterMarker } from './centerMarker';


const INITIAL_ZOOM_SCALE = 9;
const MIN_ZOOM_SCALE = 0.2;
const MAX_ZOOM_SCALE = 150;
const LABEL_REFRESH_DEBOUNCE_DELAY = 500;


export class VenuePlanDisplay {

    /**
     * Flag, ob bei Verschiebung von Seats dies am grid ausgerichtet werden sollen.
     */
    public snapToGrid: boolean = false;

    get interactionMode(): InteractionMode {
        return this._interactionMode;
    }

    set interactionMode(mode: InteractionMode) {
        this._interactionMode = mode;
        this.interactionState.onEviction(this.createContext());
        this.interactionState = getIdleState(this._interactionMode);
        this.interactionState.onInstallation(this.createContext());
    }

    set onInteraction(value: InteractionCallback | undefined) {
        this._onInteraction = value ?? ((event) => {
            // noop by default
        });
    }

    get rowLabelVisibilityMode() {
        return this._rowLabelVisibilityMode;
    }

    set rowLabelVisibilityMode(value: RowLabelVisibilityMode) {
        this._rowLabelVisibilityMode = value;
        this.refreshRowAndSeatLabels();
    }

    get showSeatLabels() {
        return this._showSeatLabels;
    }

    set showSeatLabels(value) {
        this._showSeatLabels = value;
        this.refreshRowAndSeatLabels();
    }

    private readonly canvas: HTMLCanvasElement;
    private readonly scene: Scene;
    private readonly viewport: Viewport;
    private readonly centerMarker: CenterMarker;

    private readonly dataManager: DataManager;

    private readonly areaFormsManager: AreaFormsManager;
    private readonly imagesManager: ImagesManager;

    private renderer?: PIXI.Renderer;

    private _interactionMode: InteractionMode = InteractionMode.SELECT;

    private _onInteraction: InteractionCallback = (event) => {
        // noop by default
    };

    private interactionState: InteractionState

    private _rowLabelVisibilityMode: RowLabelVisibilityMode = 'ALL';

    private _showSeatLabels: boolean = true;

    constructor(canvas: HTMLCanvasElement) {

        this.canvas = canvas;
        this.renderer = setupRenderer(this.canvas);

        const ticker = new PIXI.Ticker();
        ticker.add(() => this.renderer && this.renderer.render(this.scene.root), PIXI.UPDATE_PRIORITY.LOW);
        ticker.start();

        this.viewport = setupViewport(
            this.canvas.offsetWidth,
            this.canvas.offsetHeight,
            this.renderer.events
        );

        this.scene = new Scene(this.viewport);
        this.scene.root.eventMode = "static";
        this.scene.overlay.root.eventMode = "static";

        this.centerMarker = new CenterMarker(this.scene.underlay.root, this.scene.viewport.root);
        this.scene.overlay.root.addChild(this.centerMarker);
        this.centerMarker.updatePosition(this.canvas.width, this.canvas.height);

        this.dataManager = new DataManager(this.scene, this.dispatch.bind(this));

        this.imagesManager = new ImagesManager(
            this.scene.viewport.images,
            this.scene.viewport.root,
            this.scene.overlay.root
        );

        this.areaFormsManager = new AreaFormsManager(
            this.scene.viewport.areaForms,
            this.scene.overlay.root,
            () => this.viewport.scaled
        );

        // Wenn die Ansicht gezoomt order verschoben wird, müssen die Handle neu ausgerichtet werden,
        // da diese im Koordinaten-System des Overlay liegen.
        this.scene.viewport.root.on('moved', () => {
            this.imagesManager.updatePositions();
            this.areaFormsManager.updatePositions();
            this.centerMarker.updatePosition(this.canvas.width, this.canvas.height);
        })

        this.interactionState = getIdleState(this._interactionMode);

        const events = [
            'click',
            'mousedown',
            'mouseout',
            'mouseover',
            'mouseup',
            'mouseupoutside',
            'pointercancel',
            'pointerdown',
            'pointermove',
            'pointerout',
            'pointerover',
            'pointertap',
            'pointerup',
            'pointerupoutside',
            'rightclick',
            'rightdown',
            'rightup',
            'rightupoutside',
            'tap',
            'touchcancel',
            'touchend',
            'touchendoutside',
            'touchmove',
            'touchstart'
        ]

        events.forEach(event => this.scene.root.on(event, (e: PIXI.FederatedPointerEvent) => {
            this.handleInteractionEvent({type: EventType.PIXI_INTERACTION, event: e});
        }));

        // Die Mausrad-Events werden nur auf dem viewport erzeugt, daher hier separat den listener dafür registrieren.
        this.scene.viewport.root.on(MOUSE_WHEEL_ZOOM_EVENT, (e) => {
            this.handleInteractionEvent({type: EventType.MOUSE_WHEEL_ZOOM, event: e})
        });

        window.addEventListener('keydown', e => {
            this.handleInteractionEvent({type: EventType.KEYBOARD, event: e})
        })

        window.addEventListener('keyup', e => {
            this.handleInteractionEvent({type: EventType.KEYBOARD, event: e})
        })

        this.setupSceneGraph();
        this.areaFormsManager.updatePositions();
        this.imagesManager.updatePositions();

        this.scene.viewport.root.clampZoom({minScale: MIN_ZOOM_SCALE, maxScale: MAX_ZOOM_SCALE});
    }

    private dispatch(event: VenuePlanInteractionEvent) {
        switch (event.type) {
            case 'SEATS_ADDED':
            case 'SEATS_DELETED':
            case 'SEATS_UPDATED':
                this.refreshRowAndSeatLabels();
                break;
        }
        this._onInteraction?.(event);
    }

    private createContext(): DisplayContext {
        return {
            interactionMode: this._interactionMode,
            scene: this.scene,
            dataManager: this.dataManager,
            imagesManager: this.imagesManager,
            areaFormsManager: this.areaFormsManager,
            dispatch: this._onInteraction,
            constrainPosition: (position: Point): Point => {
                return this.snapToGrid ? snapToGrid(position, MOVE_ON_GRID_SIZE) : position;
            }
        }
    }

    private handleInteractionEvent(event: DisplayEvent) {
        this.interactionState = this.interactionState.onEvent(this.createContext(), event);
    }

    private setupSceneGraph() {
        // FIXME: Den gizmo togglebar machen
        // const gizmo = new PIXI.Graphics();
        // gizmo.lineStyle(1, 0x00FF00, 1);
        // gizmo.moveTo(0, 0);
        // gizmo.lineTo(500, 0);
        // gizmo.lineStyle(1, 0xFF0000, 1);
        // gizmo.moveTo(0, 0);
        // gizmo.lineTo(0, 500);
        // gizmo.beginFill(0xFFFF00, 1);
        // gizmo.lineStyle(0);
        // gizmo.drawCircle(0, 0, 5);
        // this.scene.viewport.root.addChild(gizmo);

        this.scene.viewport.root.setZoom(INITIAL_ZOOM_SCALE);
    }

    resizeRenderer(canvasWidth, canvasHeight) {
        this.renderer.resize(canvasWidth, canvasHeight);
        this.viewport.resize(canvasWidth, canvasHeight);
    }

    getImagesManager(): IImagesManager {
        return this.imagesManager;
    }

    getAreaFormsManager(): IAreaFormsManager {
        return this.areaFormsManager;
    }

    /**
     * Eine gegebene Funktion für jeden verwalteten Seat ausführen.
     */
    forEachSeat(callback: (seat: Seat) => void): void {
        this.dataManager.forEachSeat(callback);
    }

    /**
     * Fügt die spezifizierten Sitzplätze zum Saalplan hinzu.
     * @param seats
     */
    addSeats(seats: SeatRecord): void {
        this.dataManager.addSeats(Object.entries(seats).map(([key, seat]) => seat));  //Collection in Array wandeln
        this.refreshRowAndSeatLabels();
    }

    /**
     * Entfernt die spezifizierten Sitzplätze aus dem Saalplan.
     * @param seats
     */
    removeSeats(seats: Seat[]): void {
        this.dataManager.removeSeats(seats);
        this.refreshRowAndSeatLabels();
    }

    /**
     * Aktualisiert die spezifizierten Sitzplätze im Saalplan.
     * @param seats
     */
    updateSeats(seats: Seat[]): void {
        this.dataManager.updateSeats(seats);
        this.refreshRowAndSeatLabels();
    }

    /**
     * Liefert die Daten der aktuell ausgewählten Sitzplätze.
     */
    getSelectedSeats(): Seat[] {
        return this.dataManager.getSelectedSeats();
    }

    /**
     * Setzt die Auswahl auf die übergebenen Sitze.
     */
    setSelectedSeats(seats: Seat[], suppressEvent = false): void {
        this.dataManager.selectSeats(seats, suppressEvent);
    }

    /**
     * Entfernt die aktuell ausgewählten Sitzplätze aus dem Saalplan.
     */
    deleteSelectedSeats(): void {
        this.dataManager.deleteSelectedSeats();
        // Die aktuelle Auswahl ist nun nicht mehr gültig, daher die Interaktion zurücksetzen.
        this.interactionMode = InteractionMode.SELECT;

        this.refreshRowAndSeatLabels();
    }

    zoomToFull(screenReducedFromRight: number = 400): void {
        const scaleW = (this.canvas.width - screenReducedFromRight) / this.viewport.worldWidth;
        const scaleH = this.canvas.height / this.viewport.worldHeight;
        let scale = Math.min(scaleW, scaleH) * 0.7;
        if (scale === Infinity) scale = 8;
        const position = new PIXI.Point(screenReducedFromRight / 2 / scale, 0);

        this.viewport.animate({
            time: 400,
            position: position,
            scale: scale,
            ease: easeInOutCubic
        });
    }

    zoomInOut(screenReducedFromRight: number = 400, zoomIn: boolean): void {
        const ZOOM_FACTOR = 1.5;
        let targetZoomFactor = zoomIn ? this.viewport.scale.x * ZOOM_FACTOR : this.viewport.scale.x / ZOOM_FACTOR;
        targetZoomFactor = Math.max(MIN_ZOOM_SCALE, Math.min(targetZoomFactor, MAX_ZOOM_SCALE));
        const pivot = new PIXI.Point((this.viewport.screenWidth - screenReducedFromRight) / 2, this.viewport.screenHeight / 2)
        let currentWorldPivot = this.viewport.toWorld(pivot);
        let desiredWorldCenterX = currentWorldPivot.x - (pivot.x - this.viewport.screenWidth / 2) / targetZoomFactor;
        let desiredWorldCenterY = currentWorldPivot.y - (pivot.y - this.viewport.screenHeight / 2) / targetZoomFactor;
        this.viewport.animate({
            time: 400,
            position: new PIXI.Point(desiredWorldCenterX, desiredWorldCenterY),
            scale: targetZoomFactor,
            ease: easeOutCubic,
        })
    }

    /**
     * Die Anzeige der Reihen- und Seat-Labels aktualisieren.
     *
     * Diese Funktion ist debounced, damit der recht teure Prozess nicht unnötig oft aufgerufen wird.
     */
    private refreshRowAndSeatLabels = debounce(() => {
        this.dataManager.refreshRowAndSeatLabels(this._rowLabelVisibilityMode, this._showSeatLabels);
    }, LABEL_REFRESH_DEBOUNCE_DELAY, {
        // Durch zusätzliches triggern auf der leading edge, wird der refresh min. einmal
        // direkt beim ersten Aufruf durchgeführt so dass man bei einer Einzelaktion
        // direkt eine Änderung sieht. Die nächsten refreshes erfolgen dann debounced.
        leading: true,
        trailing: true
    });

    removePreliminarySeats() {
        this.dataManager.removePreliminarySeats();
    }
}

export default VenuePlanDisplay
