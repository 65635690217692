import React, { useRef, useEffect, useState } from 'react';
import {Col, Row} from 'react-bootstrap';
import PropTypes from "prop-types";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend';
import styles from "../form.module.scss";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import classNames from 'classnames';

// get the list of deliveryGroup-shippingCodes with the right order
export const getDeliveryGroupsPriorities = (deliveryGroups, deliveryGroupsPriorities) => {
    const orderedDg = deliveryGroupsPriorities.map(id => deliveryGroups.find(pc => pc.shippingCode === id));
    const allDg = [...orderedDg, ...deliveryGroups];
    const dg = allDg.filter((value, index, self) => {
        return self.indexOf(value) === index;
    });
    return dg;
};


const ItemType = 'ITEM';

const DragAndDropItem = ({ description, index, moveCard }) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: ItemType,
        hover: (draggedItem, monitor) => {
            if (!ref.current) return;
            const dragIndex = draggedItem.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) return;

            moveCard(dragIndex, hoverIndex);
            draggedItem.index = hoverIndex;
        },

        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        item: { index },
        collect: monitor => ({
          isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    const cssClasses = classNames('sortable-item', {
		'sortable-item__dragging': isDragging,
	});

    return (
        <li ref={ref} className={cssClasses}>
            <DragIndicatorIcon />
            <span>{description}</span>
        </li>
    );
};

const FormDeliveryGroupsPriorities = ({
    formik,
    deliveryGroups,
    deliveryGroupsPriorities
}) => {
    const [deliveryGroupsPrioritized, setDeliveryGroupsPrioritized] = useState(
        getDeliveryGroupsPriorities(deliveryGroups, deliveryGroupsPriorities)
    );
    
    useEffect(() => {
        formik.setFieldValue("shippingCodesPriorities", deliveryGroupsPrioritized.map(o => o.shippingCode));
     }, []);


    const moveCard = (fromIndex, toIndex) => {
        const newOrder = [...deliveryGroupsPrioritized];
        const [movedItem] = newOrder.splice(fromIndex, 1);
        newOrder.splice(toIndex, 0, movedItem);
  
        setDeliveryGroupsPrioritized(newOrder);
        formik.setFieldValue("shippingCodesPriorities", newOrder.map(o => o.shippingCode));
     };

    return (
        <DndProvider backend={HTML5Backend}> 
            <div className={styles.formBox}>
                <h2 className={styles.formTitle}>Versandarten Vorauswahl</h2>
                <Row className="mt-3">
                    <Col className="pt-2 col-md-4">
                        <p className="d-inline" data-testid="above_dnd">Hier können Sie per Drag'n'Drop die Reihenfolge der Versandoptionen in allen Kaufprozessen dieses Events festlegen. Die erste Versandoption, die für die jeweilige Auswahl verfügbar ist, wird für den Käufer vorausgewählt. Bitte beachten, dass die Ausfertigung von Tickets für die Wallet (Versandoption Digitales Ticket) automatisch immer erfolgt.
                            <br/>
                            Bitte daran denken, unten rechts zu "Speichern"</p>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="pt-2 col-md-4">                  
                        <ul className="drag-n-drop-list">
                            {deliveryGroupsPrioritized.map((p, index) => (
                                <DragAndDropItem
                                    key={`item-${p.description}`}
                                    index={index}
                                    description={p.description}
                                    moveCard={moveCard}
                                />
                            ))}
                        </ul>
                    </Col>
                </Row>
            </div>
        </DndProvider>
    )
}

FormDeliveryGroupsPriorities.propTypes = {
    formik: PropTypes.object,
    deliveryGroups: PropTypes.array,
    deliveryGroupsPriorities: PropTypes.array
};



export default FormDeliveryGroupsPriorities;
