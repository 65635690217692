/**
 * Combine all reducers in this file and export the combined reducers.
 */

import {combineReducers} from 'redux';

import listView from './entities/listView/reducers';
import breadcrumbs from './entities/breadcrumbs/reducers';
import errors from './errors/reducers';
import globalMessagesList from './success/reducers';
import {reducer as eventSeries} from './entities/eventSeries';
import {reducer as venueEvent} from './entities/venueEvent';
import {reducer as metaEvent} from './entities/metaEvent';
import {reducer as pricingList} from './entities/pricingList';
import {reducer as pricingMatrix} from './entities/pricingMatrix';
import {reducer as pricingCategory} from './entities/pricingCategory';
import {reducer as pricingClass} from './entities/pricingClass';
import {reducer as purchasableItemTemplate} from './entities/purchasableItemTemplate';
import {reducer as salesRule} from './entities/salesRule';
import {reducer as backendUser} from './entities/backendUser';
import {reducer as seatingType} from './entities/seatingType';
import {reducer as tenant} from './entities/tenant';
import {reducer as artist} from './entities/artist';
import {reducer as eventCategory} from './entities/eventCategory';
import {reducer as media} from './entities/media';
import {reducer as venue} from './entities/venue';
import {reducer as venuePlan} from './entities/venuePlan';
import {reducer as zukoZone} from './entities/zukoZone';
import {reducer as blockGroup} from './entities/blockGroup';
import {reducer as block} from './entities/block';
import {reducer as seat} from './entities/seat';
import {reducer as placePool} from './entities/placePool';
import {reducer as targetGroup} from './entities/targetGroup';
import {reducer as ticketLayout} from './entities/ticketLayout';
import {reducer as helpTextsToggle} from './helpTextsToggle/helpTextsToggleSlice';
import {reducer as order} from './entities/order';
import {reducer as currentTenant} from './currentTenant/currentTenantSlice';
import {reducer as venueEditor} from './entities/venueEditor';

export default () => combineReducers({
    errors,
    globalMessagesList,
    helpTextsToggle,
    currentTenant,
    entities: combineReducers({
        listView,
        breadcrumbs,
        eventSeries,
        venueEvent,
        metaEvent,
        pricingList,
        pricingMatrix,
        pricingClass,
        pricingCategory,
        purchasableItemTemplate,
        salesRule,
        backendUser,
        seatingType,
        tenant,
        artist,
        eventCategory,
        media,
        venue,
        venuePlan,
        zukoZone,
        blockGroup,
        block,
        seat,
        placePool,
        targetGroup,
        ticketLayout,
        order,
        venueEditor
    })

});
