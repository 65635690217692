import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VenuePlanDisplay from './display/VenuePlanDisplay';
import { Seat, SeatColor, SeatRecord } from '../types';
import { initLoad } from './display/resource';
import { useRouteMatch } from 'react-router-dom';
import { InteractionCallback, VenuePlanInteractionEvent } from './display/interaction/event';
import {switchToSerialClient, actions as venueEditorActions} from '../../state/entities/venueEditor';
import { IState, selectAllSeats, selectBlocks, selectPlaceCategories, selectPlacepoolDefinitions,
    selectSeatingTypes, selectImages, selectInteractionMode, selectFatalAPIError,
    selectVenuePlanSettings, selectIsSeatsLoaded, selectNewAddedSeats, selectVenuePlanIsLoaded,
    selectVenuePlanSettingsIsLoaded, selectAreaForms, selectMoveOnGrid, selectIsApiRequestPending,
    selectNewAddedAreaForms, selectBlocksIsLoaded, selectImagesIsLoaded, selectNewAddedImages,
    selectSelectedSeats,
    selectSelectedSeatsSelectedBy
    } from '../../state/entities/venueEditor/selectors';
import { LoadingIndicator } from "../../components/common/LoadingIndicator";
import BaseLayout from '../baseLayout/BaseLayout';
import { TabPlacepoolChangeFunc } from '../baseLayout/TabPlacePools';
import { determinePrimaryPlacepoolForSeat } from './display/data/common';
import { SEAT_DEFAULT_COLOR } from './display/scene';
import { IPlacepool } from '../types/Placepool';
import { ImageData } from './display/images/ImageData';
import AddSeatsDialog from '../addSeatsDialog/AddSeatsDialog';
import AddBlockAreaDialog, { DialogMode } from '../addBlockAreaDialog/AddBlockAreaDialog';
import { PointGrid } from './geometry';
import { convert2RowLabelVisibilityMode } from './display/labels/rows';
import InfoBar from '../infoBar/InfoBar';
import ToolbarUtils from '../toolBar/ToolbarUtils';
import ToolbarUtilsForZoom from '../toolBar/ToolbarUtilsForZoom';
import { Polygon } from 'pixi.js';
import { AreaFormData } from './display/areaForms/AreaFormData';
import { InteractionMode } from './display/interaction';
import { IBlock } from '../types/Block';
import { APIAreaFormData, convertApiAfData2AfData } from '../../state/entities/venueEditor/saga';
import { UpdateSource } from '../../state/entities/venueEditor/slice';



type AddSeatsDialogControlType = {
    isDialogShown: boolean,
    grid: Readonly<PointGrid>
}

type AreaFormDialogControlType = {
    isDialogShown: boolean,
    dialogMode: DialogMode,
    shape: Polygon,
    editData: AreaFormData  //if given, dialog works in edit mode
}


/**
 * Wandelt einen Hexadezimal-String in einen Integer-Wert um, der als Farbwert verwendet werden kann.
 *
 * @param color Der zu parsende string.
 *
 * @return Der durch den color-string repräsentierte Integer-Wert, oder SEAT_DEFAULT_COLOR,
 *  wenn der string nicht als Hexadezimal-Wert geparsed werden kann.
 */
export function parseSeatColor(color: string): SeatColor {
    return parseInt(color, 16) || SEAT_DEFAULT_COLOR;
}

/**
 * Bestimmte die für einen Platz anzuzeigenden Farbe in Abhängigkeit der zugewiesenen tags.
 *
 * @param seatTags Die zu berücksichtigenden tag.
 * @param tagDefinitions Die zu berücksichtigenden Tag-Definitionen.
 */
export function determineSeatColor(seatTags: string[], tagDefinitions: IPlacepool[]): SeatColor {
    const primaryTag = determinePrimaryPlacepoolForSeat(seatTags, tagDefinitions);
    return parseSeatColor((primaryTag && primaryTag?.color) ?? SEAT_DEFAULT_COLOR.toString(16));
}


const SeatingEditor: React.FC = () => {
    const dispatch = useDispatch();
    const venuePlanIsLoaded = useSelector((state: IState) => selectVenuePlanIsLoaded(state));
    const venuePlanSettingsIsLoaded = useSelector((state: IState) => selectVenuePlanSettingsIsLoaded(state));
    const venuePlanSettings = useSelector((state: IState) => selectVenuePlanSettings(state));
    const isApiRequestPending = useSelector((state: IState) => selectIsApiRequestPending(state));
    const placepoolDefinitions = useSelector((state: IState) => selectPlacepoolDefinitions(state));
    const placeCategories = useSelector((state: IState) => selectPlaceCategories(state));
    const seatingTypes = useSelector((state: IState) => selectSeatingTypes(state));
    const blocks = useSelector((state: IState) => selectBlocks(state));
    const blocksIsLoaded = useSelector((state: IState) => selectBlocksIsLoaded(state));
    const [seatsLoadStarted, setSeatsLoadStarted] = useState(false);
    const allSeats = useSelector((state: IState) => selectAllSeats(state));
    const newAddedSeats = useSelector((state: IState) => selectNewAddedSeats(state));
    const isSeatsLoaded = useSelector((state: IState) => selectIsSeatsLoaded(state));
    const selectedSeats = useSelector((state: IState) => selectSelectedSeats(state));
    const selectedSeatsSelectedBy = useSelector((state: IState) => selectSelectedSeatsSelectedBy(state));
    const imagesIsLoaded = useSelector((state: IState) => selectImagesIsLoaded(state));
    const images = useSelector((state: IState) => selectImages(state));
    const newAddedImages = useSelector((state: IState) => selectNewAddedImages(state));
    const [isDisplay, setIsDisplay] = useState(false);
    const interactionMode = useSelector((state: IState) => selectInteractionMode(state));
    const moveOnGrid = useSelector((state: IState) => selectMoveOnGrid(state));
    const areaForms = useSelector((state: IState) => selectAreaForms(state));
    const fatalAPIError = useSelector((state: IState) => selectFatalAPIError(state));
    const [isAddSeatsDialogShown, setIsAddSeatsDialogShown] = useState(
        {isDialogShown: false, grid: undefined} as AddSeatsDialogControlType);
    const [isAreaFormDialogShown, setIsAreaFormDialogShown] = useState(
        {
            isDialogShown: false,
            dialogMode: DialogMode.CREATE,
            shape: new Polygon(),
            editData: null
        } as AreaFormDialogControlType);
    const newAddedAreaForms = useSelector((state: IState) => selectNewAddedAreaForms(state));

    const canvas = useRef<HTMLCanvasElement>(null);
    const display = useRef<VenuePlanDisplay>();
    const { vpid } = useRouteMatch<{
        vpid: string;
      }>().params;

    let neededWidthForSideBar = 0;

    // Dieser Callback wird als ref deklariert, da wir diesen innerhalb eines InteractionCallback für VenuePlanDisplay
    // verwenden wollen, welcher dann außerhalb der react render-cycles stattfindet und andernfalls immer nur die
    // initial übergebene Deklaration verwenden würde, ungeachtet zwischenzeitlicher state Änderungen.
    const handleInteractionEvent = useRef<InteractionCallback>(() => {
        // initial ein noop, wird durch useEffect(setupInteractionEventHandler()) initi-/aktualisiert.
    });


    useEffect(() => {
        setIsDisplay(false);
        initLoad(() => {});
        switchToSerialClient();
        dispatch(venueEditorActions.loadVenuePlan(vpid));
        dispatch(venueEditorActions.loadVenuePlanSettings(vpid));
        dispatch(venueEditorActions.loadPlacepoolDefinitions(vpid));
        dispatch(venueEditorActions.loadPlaceCategories());
        dispatch(venueEditorActions.loadSeatingTypes());
        dispatch(venueEditorActions.loadBlocks(vpid));
        dispatch(venueEditorActions.loadImages(vpid));
    }, [dispatch, vpid]);


    useEffect(() => {
        if (
            !venuePlanIsLoaded ||
            !venuePlanSettingsIsLoaded ||
            !placepoolDefinitions ||
            !placeCategories ||
            !seatingTypes ||
            !blocksIsLoaded ||
            !imagesIsLoaded ||
            seatsLoadStarted
        ) return;
        const filteredStandingBlocks = blocks.reduce((standBlocks: Record<string, AreaFormData>, block: IBlock) => {
            const anyBlock: unknown = block;
            if (block.blockType !== 'standing') return standBlocks;
            const af = convertApiAfData2AfData(anyBlock as APIAreaFormData);
            if (!af.placePools) af.placePools = [];
            standBlocks[block.id] = af;
            return standBlocks;
        }, {});
        dispatch(venueEditorActions.setLoadedAreaForms(filteredStandingBlocks));
        setSeatsLoadStarted(true);
        dispatch(venueEditorActions.loadAllSeats(vpid));
    }, [dispatch, venuePlanIsLoaded, venuePlanSettingsIsLoaded, vpid, placepoolDefinitions,
        placeCategories, seatingTypes, blocks, imagesIsLoaded, blocksIsLoaded, seatsLoadStarted]);


    useEffect(() => {
        if (isDisplay) return;
        if (!isSeatsLoaded) return;
        if (!canvas.current) return;
        display.current = new VenuePlanDisplay(canvas.current);
        display.current.onInteraction = (event) => handleInteractionEvent.current(event);
        window.addEventListener('resize', () => {
            display.current.resizeRenderer(canvas.current.offsetWidth, canvas.current.offsetHeight);
        });
        const seats: SeatRecord = allSeats;
        handleInteractionEvent.current = (event: VenuePlanInteractionEvent) => {
            switch (event.type) {
                case 'SEATS_ADD_REQUESTED':
                    setIsAddSeatsDialogShown({isDialogShown: true, grid: event.pointGrid});
                    return;
                case 'SEATS_ADDED':
                    //TODO: für neue Sitze relevante Defaultwerte oder eingestellte Werte setzen
                    let newSeats: Seat[] = event.seats.map(seat => {
                        let newSeat = {
                            ...seat,
                            pricingCategoryId: placeCategories[0].id,
                            seatingTypeId: seatingTypes[0].id
                        };
                        return newSeat;
                    });
                    dispatch(venueEditorActions.addSeats(newSeats));
                    dispatch(venueEditorActions.setInteractionMode(InteractionMode.SELECT));
                    dispatch(venueEditorActions.setSelectedSeats({
                        seats: newSeats,
                        selectedBy: UpdateSource.STORE
                    }));
                    dispatch(venueEditorActions.purgeUndoHistory());
                    dispatch(venueEditorActions.purgeRedoHistory());
                    return;
                case 'SEATS_UPDATED':
                    // Die Sitzplätze der aktuellen Auswahl haben sich evtl. geändert.
                    dispatch(venueEditorActions.updateSomeSeats(event.seats));
                    return;
                case 'SEATS_DELETED':
                    dispatch(venueEditorActions.deleteSeats(event.seats));
                    dispatch(venueEditorActions.purgeUndoHistory());
                    dispatch(venueEditorActions.purgeRedoHistory());
                    return;
                 case 'SEATS_SELECTED':
                    let selSeats = display.current?.getSelectedSeats();
                    if (selSeats?.length) {
                        console.log("seat: x " + selSeats[0].x + ", y " + selSeats[0].y);
                    }
                    dispatch(venueEditorActions.setSelectedSeats({
                        seats: display.current?.getSelectedSeats(),
                        selectedBy: UpdateSource.DISPLAY
                    }));
                    return;
                 case 'SEATS_UNSELECTED':
                    dispatch(venueEditorActions.setSelectedSeats({
                        seats: display.current?.getSelectedSeats(),
                        selectedBy: UpdateSource.DISPLAY
                    }));
                    return;
            }
        }
        display.current.addSeats(seats);
        setIsDisplay(true);
        display.current.getImagesManager().initItems(images);
        display.current.getAreaFormsManager().initItems(Object.values(areaForms));
        display.current.zoomToFull(neededWidthForSideBar);
        dispatch(venueEditorActions.purgeUndoHistory());
    }, [canvas, allSeats, isSeatsLoaded, isDisplay, dispatch, seatingTypes, placeCategories,
        images, areaForms, neededWidthForSideBar]);



    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (!isApiRequestPending) return;
            event.preventDefault();
            event.returnValue = 'Änderungen werden gerade gespeichert. Diese gehen eventuell verloren.\nWirklich schließen?';
        }
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        };
    }, [isApiRequestPending]);


    useEffect(() => {
        if (!isDisplay) return;  //don't execute on initialization
        display.current.updateSeats(Object.values(allSeats));
    }, [allSeats, isDisplay]);


    useEffect(() => {
        if (!isDisplay) return;  //don't execute on initialization
        display.current.getAreaFormsManager().updateItems(Object.values(areaForms));
    }, [areaForms, isDisplay]);


    // Anzeige der Labels steuern
    useEffect(() => {
        if (venuePlanSettings && display.current) {
            display.current.rowLabelVisibilityMode = convert2RowLabelVisibilityMode(
                venuePlanSettings.backendSettings.showRowNumberAtBeginningOfRow,
                venuePlanSettings.backendSettings.showRowNumberAtEndOfRow);
            display.current.showSeatLabels = venuePlanSettings.backendSettings.showSeatLabels;
        }
    }, [venuePlanSettings, isDisplay]);


    useEffect(() => {
        if (!display.current) return;
        display.current.interactionMode = interactionMode;
    }, [interactionMode, isDisplay]);


    useEffect(() => {
        if (newAddedSeats.length === 0) return;
        const newSeatsMap = newAddedSeats.reduce((allSeats: SeatRecord, seat: Seat) => {
            allSeats[seat.id] = seat;
            return allSeats;
        }, {} as SeatRecord)
        display.current.addSeats(newSeatsMap);
        dispatch(venueEditorActions.setInteractionMode(InteractionMode.SELECT));
        display.current.setSelectedSeats(Object.values(newAddedSeats));
        dispatch(venueEditorActions.purgeNewAddedSeats());
        setIsAddSeatsDialogShown({isDialogShown: false, grid: undefined});
    }, [newAddedSeats, dispatch]);


    useEffect(() => {
        if (!newAddedImages.length) return;
        for (const image of newAddedImages) display.current.getImagesManager().addItem({...image});
        dispatch(venueEditorActions.purgeNewAddedImages());
        dispatch(venueEditorActions.setInteractionMode(InteractionMode.IMAGES));
    }, [newAddedImages, dispatch]);


    useEffect(() => {
        if (Object.keys(newAddedAreaForms).length === 0) return;
        for (const aF of Object.values(newAddedAreaForms)) display.current.getAreaFormsManager().addItem({...aF});
        dispatch(venueEditorActions.removeFromNewAddedAreaForms(newAddedAreaForms));
        setIsAreaFormDialogShown(
            {isDialogShown: false, dialogMode: DialogMode.CREATE, shape: undefined, editData: null}
        );
    }, [newAddedAreaForms, dispatch]);


    useEffect(() => {
        if (!isDisplay) return;
        if (selectedSeatsSelectedBy !== UpdateSource.DISPLAY) display.current.setSelectedSeats(selectedSeats, true);
    }, [selectedSeats, selectedSeatsSelectedBy, isDisplay])


    useEffect(() => {
        if (!isDisplay) return;
        display.current.snapToGrid = moveOnGrid;
    }, [moveOnGrid, isDisplay])


    useEffect(() => {
        if (!fatalAPIError) return;
        // eslint-disable-next-line no-restricted-globals
        confirm("Folgender Fehler ist aufgetreten:\n" + fatalAPIError + "\n\nDie Seite wird neu geladen.");
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }, [fatalAPIError]);


    const handleSeatsDelete = () => {
        display.current?.deleteSelectedSeats();
    }
    
    /**
     * This is only called by change operations from outside the editor, because it will
     * also send the updates to the editor.
     * Changes from inside the editor will be caught in handleInteractionEvent
     * @param seats 
     */
    const handleSeatsChanged: TabPlacepoolChangeFunc = (seats, tagsChanged) => {
        if (tagsChanged) {
            seats.forEach(seat => {
                seat.color = determineSeatColor(seat.tags, placepoolDefinitions);
            });
        }
        dispatch(venueEditorActions.updateSomeSeats(seats));
    }

    const handleAddSeatsDialogClose = (okClicked: boolean, newSeats: SeatRecord = undefined) => {
        display.current.removePreliminarySeats();
        if (okClicked) {
            dispatch(venueEditorActions.addSeats(Object.values(newSeats)));
        } else {
            dispatch(venueEditorActions.setInteractionMode(InteractionMode.SELECT));
            setIsAddSeatsDialogShown({isDialogShown: false, grid: undefined});
        }
    }


    const handleImagesChanged = (images: ImageData[]) => {
        dispatch(venueEditorActions.updateImages(images));
    }

    const handleImagesDeleted = (images: ImageData[]) => {
        dispatch(venueEditorActions.deleteImages(images));
    }

    const handleNeededWidthChanged = (width: number) => {
        neededWidthForSideBar = width;
    }

    const handleZoomIn = () => {
        display.current.zoomInOut(neededWidthForSideBar, true);  //on hold for later
    }

    const handleZoomToFull = () => {
        display.current.zoomToFull(neededWidthForSideBar);
    }

    const handleZoomOut = () => {
        display.current.zoomInOut(neededWidthForSideBar, false);  //on hold for later
    }

    const handleRequestAddAreaDialog = (rect: Polygon) => {
        if (!rect) return;
        dispatch(venueEditorActions.setInteractionMode(InteractionMode.AREAFORMS));
        setIsAreaFormDialogShown({isDialogShown: true, dialogMode: DialogMode.CREATE, shape: rect, editData: null});
    }

    const handleShowEditAreaDialog = () => {
        const selectedAF = display.current.getAreaFormsManager().getSelectedItem();
        if (!selectedAF) return;
        setIsAreaFormDialogShown({
            isDialogShown: true,
            dialogMode: DialogMode.EDIT,
            shape: new Polygon(selectedAF.points),
            editData: selectedAF
        });
    }

    const handleAreaFormDialogClose = (okClicked: boolean, data: AreaFormData = undefined) => {
        if (!okClicked) {
            setIsAreaFormDialogShown(
                {isDialogShown: false, dialogMode: DialogMode.CREATE, shape: undefined, editData: null}
            );
            dispatch(venueEditorActions.setInteractionMode(InteractionMode.AREAFORMS));
            return;
        }
        if (isAreaFormDialogShown.dialogMode === DialogMode.CREATE) {
            dispatch(venueEditorActions.addAreaForm({venuePlanId: vpid, newAreaForm: data}));
            dispatch(venueEditorActions.setInteractionMode(InteractionMode.AREAFORMS));
        } else if (isAreaFormDialogShown.dialogMode === DialogMode.EDIT) {
            dispatch(venueEditorActions.updateAreaForms({venuePlanId: vpid, updatedAreaForms: [data]}));
            setIsAreaFormDialogShown(
                {isDialogShown: false, dialogMode: DialogMode.CREATE, shape: undefined, editData: null}
            );
        } else ;  // should be unreachable for now
    }

    const handleAreaFormSelected = (areaFormData: AreaFormData) => {
        dispatch(venueEditorActions.setSelectedAreaFormId(areaFormData?.id));
    }

    const handleAreaFormsChanged = (areaForms: AreaFormData[]) => {
        dispatch(venueEditorActions.updateAreaForms({venuePlanId: vpid, updatedAreaForms: areaForms}));
    }

    const handleAreaFormsDeleted = (areaForms: AreaFormData[]) => {
        dispatch(venueEditorActions.deleteAreaForms(areaForms));
    }

    if (isDisplay) {
        display.current.getImagesManager().onItemsChanged = handleImagesChanged;
        display.current.getImagesManager().onItemsDeleted = handleImagesDeleted;
        display.current.getAreaFormsManager().onRequestAddAreaForm = handleRequestAddAreaDialog;
        display.current.getAreaFormsManager().onItemSelected = handleAreaFormSelected;
        display.current.getAreaFormsManager().onItemsChanged = handleAreaFormsChanged;
        display.current.getAreaFormsManager().onItemsDeleted = handleAreaFormsDeleted;
        handleAreaFormSelected(display.current.getAreaFormsManager().getSelectedItem());
    }


    if (!isSeatsLoaded) return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <LoadingIndicator disableShrink />
        </div>
    );


    return (
        <>
            <div className="display-wrapper">
                <canvas className="display" ref={canvas} />
            </div>
            <BaseLayout
                onSelectedSeatsChanged={handleSeatsChanged}
                imagesManager={display.current?.getImagesManager()}
                areaFormsManager={display.current?.getAreaFormsManager()}
                venuePlanId={vpid}
                onNeededWidthChanged={handleNeededWidthChanged}
                onAreaFormEdit={handleShowEditAreaDialog}
            />
            <ToolbarUtils
                venuePlanId={vpid}
                imagesManager={display.current?.getImagesManager()}
                areaFormsManager={display.current?.getAreaFormsManager()}
                onSeatsDelete={handleSeatsDelete}
                onEditAreaForm={handleShowEditAreaDialog}
            />
            <ToolbarUtilsForZoom
                onZoomIn={handleZoomIn}
                onZoomToFull={handleZoomToFull}
                onZoomOut={handleZoomOut}
            />
            <InfoBar/>
            <AddSeatsDialog
                open={isAddSeatsDialogShown.isDialogShown}
                pointGrid={isAddSeatsDialogShown.grid}
                onConfirm={(newSeats) => handleAddSeatsDialogClose(true, newSeats)}
                onCancel={() => handleAddSeatsDialogClose(false)}
            />
            <AddBlockAreaDialog
                open={isAreaFormDialogShown.isDialogShown}
                shape={isAreaFormDialogShown.shape}
                dialogMode={isAreaFormDialogShown.dialogMode}
                editData={isAreaFormDialogShown.editData}
                venuePlanId={vpid}
                onConfirm={(data: AreaFormData) => handleAreaFormDialogClose(true, data)}
                onCancel={() => handleAreaFormDialogClose(false)}
            ></AddBlockAreaDialog>
        </>
    )
}

export default SeatingEditor;
