import React, { useRef, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PropTypes from "prop-types";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styles from "../form.module.scss";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import classNames from 'classnames';

const ItemType = 'ITEM';

const DragAndDropItem = ({ id, name, index, moveItem }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem, monitor) => {
      if (!ref.current) return;

      const dragIndex = draggedItem.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      moveItem(dragIndex, hoverIndex);
      draggedItem.index = hoverIndex;
    },

    collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

	const cssClasses = classNames('sortable-item', {
		'sortable-item__dragging': isDragging,
	});

  drag(drop(ref));

  return (
    <li ref={ref} className={cssClasses}>
        <DragIndicatorIcon />
        <span>{name}</span>
    </li>
  );
};

const FormPricingClassesPriorities = ({
    formik,
    pricingClasses,
    pricingClassesPriorities
}) =>{

    const getPricingClassesPriorities = () => {
        if (!pricingClasses) {
            pricingClasses = [];
        }

        const orderedPc = pricingClassesPriorities.map(id => pricingClasses.find(pc => pc.id === id));
        const allPc = [...orderedPc, ...pricingClasses];

        const pc = allPc.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });

        return pc;
    }

    const [pricingClassesPrioritized, setPricingClassesPrioritized] = useState(getPricingClassesPriorities());

    useEffect(() => {
        formik.setFieldValue("pricingClassesPriorities", pricingClassesPrioritized.map(o => o.id));
        // We want this process to happen only once at "on-mount", therefore we use [] as dependency.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const moveItem = (fromIndex, toIndex) => {
        const updatedItems = [...pricingClassesPrioritized];
        const [movedItem] = updatedItems.splice(fromIndex, 1);
        updatedItems.splice(toIndex, 0, movedItem);
    
        setPricingClassesPrioritized(updatedItems);
        formik.setFieldValue("pricingClassesPriorities", updatedItems.map(o => o.id));
    };
    
    return (
        <DndProvider backend={HTML5Backend}>
            <div className={styles.formBox}>
                <h2 className={styles.formTitle}>Preisklassenvorauswahl</h2>
                <Row className="mt-3">
                    <Col className="pt-2 col-md-4">
                        <p className="d-inline">Hier können Sie per Drag'n'Drop die Reihenfolge der Preisklassen in den Dropdowns der Kaufprozesse dieses Events festlegen.<br/>
                            Die erste Preisklasse die für die jeweilige Auswahl verfügbar ist, wird für den Käufer vorausgewählt.<br/>
                            Bitte daran denken, unten rechts zu "Speichern"</p>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="pt-2 col-md-4">
                        <ul className="drag-n-drop-list">
                            {pricingClassesPrioritized.map((p, index) => (
                                <DragAndDropItem key={p.id} id={p.id} name={p.name} index={index} moveItem={moveItem} />
                            ))}
                        </ul>
                    </Col>
                </Row>
            </div>
        </DndProvider>
    )
}

FormPricingClassesPriorities.propTypes = {
    formik: PropTypes.object,
    pricingClasses: PropTypes.array,
    pricingClassesPriorities: PropTypes.array
};



export default FormPricingClassesPriorities;
