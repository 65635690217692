import { setLightness } from 'polished';

export const formatBytes = (size: number, precision = 2) => {
    if (size === 0) return '0 Bytes';

    const base = Math.log(size) / Math.log(1024);
    const suffixes = ['B', 'KB', 'MB', 'GB', 'TB'];

    return (Math.pow(1024, base - Math.floor(base))).toFixed(precision) + ' ' + suffixes[Math.floor(base)];
}

const getURL = () => {
    const url = new URL(window.location.href);

    const urlParts = url.hostname.split('.');
    if (urlParts.length > 2 && urlParts[0] === 'backend') {
        url.hostname = urlParts.slice(1).join('.');
    }

    return url;
}

export const getApiBaseUrl = () => {
    if (window.config?.REACT_APP_API_DOMAIN) {
        return window.config?.REACT_APP_API_DOMAIN + window.config.REACT_APP_API_BASE_PATH;
    }

    const url = getURL();

    return url.protocol + '//api.' + url.hostname + window?.config?.REACT_APP_API_BASE_PATH;
};

export const getTicketingBaseUrl = () => {
    if (window.config?.REACT_APP_TICKETING_DOMAIN) {
        return window.config?.REACT_APP_TICKETING_DOMAIN;
    }

    const url = getURL();

    return url.protocol + '//ticketing.' + url.hostname;
};

export const lightenColorPolished = (color: string, amount = 0.98) => setLightness(amount, color);