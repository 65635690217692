import React from 'react';
import {Col} from 'react-bootstrap';
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import List from "./List";
import {BATCH_ACTION_MOVE_TO_ACTIVE} from "../common/listView/ListViewBatchActions";
import RefreshIcon from '@mui/icons-material/Refresh';
import FeedbackButton from '../common/FeedbackButton';

const LinkToActive = () => {
    return (
        <FeedbackButton
            to={`/base/pricing-class`}
            icon={<RefreshIcon />}
            variant='contained'
            className='listLinkCenter'
        >
            Archiv
        </FeedbackButton>
    );
}

const Breadcrumb = () => {
    return (
        <Breadcrumbs entity={["Archivierte Preisklasse"]} endpoints = {['archived_pricing_class/breadcrumb']} title = "Archived Preisklassen" links={['/base/pricing-class/']} active={[0]} />
    )
}

const ArchivedPricingClassList = () => {
    return (
        <List
            linkNearHeader={LinkToActive}
            breadcrumb={Breadcrumb}
            heading="Archivierte Preisklassen"
            listViewEndpoint="listingView/archivedPricingClass"
            allowedBatchActions={[BATCH_ACTION_MOVE_TO_ACTIVE]}
        />
    );
};

export default ArchivedPricingClassList;

