import React from 'react';
import styles from './toolBar.module.scss';
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CenterFocusWeakOutlinedIcon from '@mui/icons-material/CenterFocusWeakOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';



interface IToolbarUtilsForZoomProps {
    onZoomIn: () => void;
    onZoomToFull: () => void;
    onZoomOut: () => void;
}
  
  

export const ToolbarUtilsForZoom: React.FC<IToolbarUtilsForZoomProps> = ({onZoomIn, onZoomToFull, onZoomOut}) => {

    return (
        <div className={styles.toolBarZoomContainer}>
            <IconButton onClick={onZoomIn} className={styles.toolBarZoomBtn}>
                <AddIcon />
            </IconButton>
            <IconButton onClick={onZoomToFull} className={styles.toolBarZoomBtn}>
                <CenterFocusWeakOutlinedIcon />
            </IconButton>
            <IconButton onClick={onZoomOut} className={styles.toolBarZoomBtn}>
                <RemoveOutlinedIcon />
            </IconButton>
        </div>
    )
}

export default ToolbarUtilsForZoom;