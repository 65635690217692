import React from 'react';
import {Redirect, Switch} from 'react-router-dom';

import Edit from './Edit';
import {Container} from 'react-bootstrap';
import SecureRoute from '../auth/SecureRoute';
import PastVenueEventList from "./PastVenueEventList";

export default () => (
    <Container className={'mt-1'}>
        <Switch>
            <SecureRoute exact path="/event-management/past-event" component={PastVenueEventList}/>
            <SecureRoute exact path="/event-management/past-event/create">
                <Redirect to="/event-management/event/create" />
            </SecureRoute>
            <SecureRoute exact path="/event-management/past-event/:id" component={Edit}/>
        </Switch>
    </Container>
);
