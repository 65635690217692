import * as yup from 'yup';
import {inRange, isInteger} from 'lodash';
import {
    MaxTicketsSalesRulesGroup,
    Quota,
    urlError,
    urlPattern,
    barcodeSettingsSchema
} from "../venueEvent/schema";

/**
 * Checks if a range of dates defined by a start and end is valid.
 *
 * @param start {Date?} The beginning of the range.
 * @param end  {Date?} The end of the range.
 * @returns {boolean} true if the range is open on either end (i.e. start or end is missing),
 * of if end is after start.
 */
export function validateDateRange(start, end) {
    return !start || !end || (start < end);
}

export function validateDays(from, until) {
    if (isInteger(parseInt(from)) && isInteger(parseInt(until))) {
        return parseInt(from) >= parseInt(until);
    } else {
        return true;
    }
}

export function validateNumberOrEmptyString(value) {
    if (isInteger(parseInt(value))) {
        if (inRange(parseInt(value), 1, 1000)) {
            return true;
        } else {
            return false;
        }
    }
    return value === '';
}

const I18nText = yup.object().shape({
    de: yup.string().nullable().default(''),
    en: yup.string().nullable().default(''),
})

const Tags = yup.object().shape({
    de: yup.array().of(yup.string()).nullable().default([]),
    en: yup.array().of(yup.string()).nullable().default([]),
})

export const SalesRuleAssociation = yup.object().shape({
        enabled: yup.bool().default(false),
        ticketLayout: yup.string().nullable().test(
            'ticket_layout_required',
            'Ticketlayout ist erforderlich',
            ticketLayout => !!ticketLayout
        ),
        validFrom: yup.date().nullable().test(
            'start_before_end',
            'Der Startzeitpunkt muss vor dem Endzeitpunkt liegen',
            function (validFrom) {
                return validateDateRange(validFrom, this.parent.validTo);
            }
        ),
        validTo: yup.date().nullable()
            .test(
                'end_after_start',
                'Der Endzeitpunkt muss nach dem Startzeitpunkt liegen',
                function (validTo) {
                    return validateDateRange(this.parent.validFrom, validTo);
                }
            ),
        maxTicketsPerUser: yup.mixed().test(
            'is_number_or_empty_string',
            'Bitte geben Sie eine passende Zahl ein',
            maxTicketsPerUser => validateNumberOrEmptyString(maxTicketsPerUser)
        ).default(''),
        deliveryGroups: yup.array().default([]).of(yup.object().shape({
            shippingCode: yup.string(),
            activeFromWorkingDaysBeforeEventStartDate: yup.string().nullable().test(
                'higher_than_until',
                'Dieser Wert muss höher liegen als bis Tage vorher, oder gleich',
                function (activeFromWorkingDaysBeforeEventStartDate) {
                    return validateDays(activeFromWorkingDaysBeforeEventStartDate, this.parent.activeUntilWorkingDaysBeforeEventStartDate);
                }
            ),
            activeUntilWorkingDaysBeforeEventStartDate: yup.string().nullable().test(
                'lower_than_from',
                'Dieser Wert muss niedriger sein als ab Tage vorher, oder gleich',
                function (activeUntilWorkingDaysBeforeEventStartDate) {
                    return validateDays(this.parent.activeFromWorkingDaysBeforeEventStartDate, activeUntilWorkingDaysBeforeEventStartDate);
                }
            ),
            lastApplicableDateTime: yup.string().nullable(),
            lastApplicableDayTime: yup.string().nullable(),
        })),
        printAtHomeTicketLayout: yup.string().nullable().when('deliveryGroups', (deliveryGroups, schema) => {
            if (deliveryGroups && deliveryGroups.find(group => group.shippingCode === 'PAH' || group.shippingCode === 'PAHWOI')) {
                return schema.required().default({});
            }
            return schema.default({});
        }),
        tags: yup.array().default([]),
        provideDownloadUrl: yup.bool().default(true),
        activeMembersOnly: yup.bool().default(false),
        activeMemberSinceAt: yup.date().nullable().test(
            'active_member_since_at_required',
            'Muss Mitglied sein seit ist erforderlich',
            function (activeMemberSinceAt) {
                if (this.parent.activeMembersOnly) {
                    return !!activeMemberSinceAt;
                }
                return true;
            }
        ).default(null),
        secondaryMarketFeeFactorCode: yup.string().test(
            'secondary_market_fee_factor_code_required',
            'Ticketbörsengebühr ist erforderlich',
            secondaryMarketFeeFactorCode => !!secondaryMarketFeeFactorCode
        ).default('FREE'),
        useFor: yup.string().nullable(),
    }
);


export const mediaSchema = yup.object().shape({
    id: yup.string(),
    url: yup.string().url(),
    title: yup.string(),
});


export const MetaEvent = yup.object().shape({
    eventSeries: yup.string().nullable(),
    eventCategory: yup.string().nullable(),
    disableSalesRulesAfterStartDate: yup.bool().default(false),
    disableSalesRulesAfterEndDate: yup.bool().default(true),
    title: yup.object().shape({
        de: yup.string().required(),
        en: yup.string().required(),
    }),
    subtitle: I18nText,
    ident: yup.string().required(),
    team1Image: mediaSchema.nullable(),
    team2Image: mediaSchema.nullable(),
    walletLogo: mediaSchema.nullable(),
    walletHeader: mediaSchema.nullable(),
    ticketSelectionExpiration: yup.number().integer(),
    salesInformation: I18nText.nullable(),
    description: I18nText.nullable(),
    tags: Tags.nullable(),
    queueItEventId: yup.string().nullable(),
    coronaRulesUrl: yup.string().matches(urlPattern, urlError).nullable(),
    salesRules: yup.array().of(SalesRuleAssociation),
    quotas: yup.array().of(Quota).nullable(),
    maxTicketsSalesRulesGroups: yup.array().of(MaxTicketsSalesRulesGroup).nullable(),
    maxTicketsPerUser: yup.number().nullable().min(1).max(999).integer(),
    pricingClasses: yup.array(),
    disableVisualSelection: yup.bool().default(false),
    productId: yup.string().default(''),
    barcodeContext: barcodeSettingsSchema
});
