import React, {Component} from 'react';
import Form from "./Form";
import {actions} from "../../state/entities/venueEvent";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Create extends Component {
    onSubmit = (venueEvent) => {
        let event = venueEvent;
        if(venueEvent.maxTicketsPerUser === ""){
            event = {...venueEvent, maxTicketsPerUser: null}
        }
        this.props.create(event);
    };

    render() {
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Event"]} endpoints = {['venue_event/breadcrumb']} title = "Events" links={['/event-management/event/']} active={[0]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Erstellen eines neuen Event</h1>
                </Col>
            </Row>
            <Form venueEvent={{}} onSubmit={this.onSubmit} submitPending={this.props.createPending} />
        </>;
    }
}

const mapStateToProps = (state) => {
    return {
        createPending: state.entities.venueEvent.createPending
    }
};

const mapDispatchToProps = dispatch => {
    return {
        create: entity => dispatch(actions.create({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
