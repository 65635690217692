import React, { useRef } from 'react';
import Form from 'react-bootstrap/Form';
import {ItemField} from '../ListViewPropTypes';
import PropTypes from 'prop-types';
import classnames from "classnames";
import {get, isEmpty} from 'lodash';
import {
    Checkbox,
    FormGroup,
    FormControlLabel,
    FormControl,
    InputLabel,
    Button,
    IconButton
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment-timezone';
import Flatpickr from 'react-flatpickr';
import { German } from 'flatpickr/dist/l10n/de.js';

function DateRangeFilterConfig({field, filter, onFilterChange}) {
    const refDateInput1 = useRef(null);
    const refDateInput2 = useRef(null);
    
    function toggleOnlyEmptyValue(event) {
        if (event.target.checked) {
            onFilterChange({range: {onlyEmpty: !!event.target.checked}});
        } else {
            onFilterChange(undefined);
        }
    }

    function handleDateChange(min, max) {
        if (isEmpty(min) && isEmpty(max)) {
            onFilterChange(undefined);
        } else {
            onFilterChange({range: {min: min || undefined, max: max || undefined}});
        }
    }

    function handleMinDateChange(_, min) {
        min = moment(min).startOf('day').toISOString();
        handleDateChange(min, get(filter, 'range.max'));
    }

    function handleMaxDateChange(_, max) {
        max = moment(max).endOf('day').toISOString();
        handleDateChange(get(filter, 'range.min'), max);
    }

    function clearFilter() {
        onFilterChange(undefined);
    }

    const flatpickrOptions = {
        static: true,
        wrap: true,
        altFormat: 'd.m.Y',
        altInput: true,
        locale: German,
    };

    return (
        <Form>
            <FormControl
                fullWidth
                variant="outlined"
                sx={{
                    paddingBottom: 0
                }}
            >
                <InputLabel className={classnames('MuiLabel-noHover, MuiLabel-topSpace')}>Wählen Sie ein Datumsintervall für <strong>{field.label || field.name}</strong> nach dem gefiltert werden soll.</InputLabel>

                <Form.Group className='MuiFormControl-root-custom' style={{ padding: 0}}>
                    <Flatpickr
                        value={get(filter, 'range.min', '')}
                        options={flatpickrOptions}
                        onChange={handleMinDateChange}
                        ref={refDateInput1}
                    >
                        <input type="text" className="form-control" placeholder="Startdatum auswählen..." data-input/>
                        {get(filter, 'range.min') ? (
                            <IconButton
                                onClick={clearFilter}
                                className={'date-clear-icon'}
                            >
                                <CancelIcon />
                            </IconButton>
                        ) : (
                            <IconButton
                                onClick={() => {
                                    refDateInput1.current.flatpickr.open();
                                }}
                                className={'date-clear-icon'}
                            >
                                <CalendarMonthIcon />
                            </IconButton>
                        )}
                    </Flatpickr>
                </Form.Group>

                <Form.Group className='MuiFormControl-root-custom mt-2' style={{ padding: 0}}>
                    <Flatpickr
                        value={get(filter, 'range.max', '')}
                        options={flatpickrOptions}
                        onChange={handleMaxDateChange}
                        ref={refDateInput2}
                    >
                        <input type="text" className="form-control" placeholder="Enddatum auswählen..." data-input/>
                        {get(filter, 'range.max') ? (
                            <IconButton
                                onClick={clearFilter}
                                className={'date-clear-icon'}
                            >
                                <CancelIcon />
                            </IconButton>
                        ) : (
                            <IconButton
                                onClick={() => {
                                    refDateInput2.current.flatpickr.open();
                                }}
                                className={'date-clear-icon'}
                            >
                                <CalendarMonthIcon />
                            </IconButton>
                        )}
                    </Flatpickr>
                </Form.Group>
            </FormControl>

            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox checked={get(filter, 'range.onlyEmpty', false)} onChange={toggleOnlyEmptyValue} sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }} />
                    }
                    label="Nur Einträge ohne Wert anzeigen"
                    sx={{
                        marginBottom: 0
                    }}
                />
            </FormGroup>
            <Button
                variant="text"
                onClick={clearFilter}
                sx={{ display: 'flex', marginLeft: 'auto' }}
            >
                Filterung aufheben
            </Button>
        </Form>
    );
}

DateRangeFilterConfig.propTypes = {
    field: ItemField.isRequired,
    filter: PropTypes.any,
    onFilterChange: PropTypes.func.isRequired
};

export default DateRangeFilterConfig;
