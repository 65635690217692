/**
 * Create the store with dynamic reducers
 */

import {applyMiddleware, compose, createStore} from 'redux';
import {createBrowserHistory} from 'history';
import createSagaMiddleware from 'redux-saga';
import createReducer from './reducers';
import rootSaga from './saga';
import {loadState, saveState} from './localStorage';

export const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL,
});

function configureStore(persistedState) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(
        createReducer(history), // root reducer with router state
        persistedState,
        composeEnhancers(
            applyMiddleware(
                sagaMiddleware
            ),
        ),

    );

    sagaMiddleware.run(rootSaga);
    return store;
}

const persistedState = loadState();
const store = configureStore(persistedState);
store.subscribe(() => {
    saveState({
        helpTextsToggle: store.getState().helpTextsToggle,
        currentTenant: store.getState().currentTenant
    })
})
export default store;
