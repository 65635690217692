import React from 'react';
import { Route } from 'react-router-dom';
import {some} from 'lodash';
import {AuthConsumer} from './AuthProvider';
import PermissionDenied from '../pages/PermissionDenied';
import Dashboard from '../dashboard/Dashboard';

const SecureRoute = ({render, component: Component, requiredRoles, path, ...rest}) => {
    const getCurrentTenant = JSON.parse(localStorage.getItem('state'));

    const renderFn = (Component) => (props) => (
        <AuthConsumer>
            {({isAuthenticated, hasRole}) => {
                const isSuperAdmin = hasRole('admin_super_admin');
                if (!!Component && isAuthenticated()) {
                    if (requiredRoles) {
                        const authorized = some(requiredRoles, role => hasRole(role.toLowerCase()));
                        if (!authorized) {
                            return <PermissionDenied/>
                        }
                        else if (
                            isSuperAdmin &&
                            !getCurrentTenant?.currentTenant.selectedTenant?.id &&
                            path !== '/base/tenant' &&
                            path !== '/base/backend-user'
                        ) {
                            return <Dashboard />
                        }
                    }
                    return Component ? <Component {...props} /> : render(props);
                } else {
                    return <PermissionDenied/>
                }
            }}
        </AuthConsumer>
    );
    return <Route {...rest} render={renderFn(Component || render)}/>;
};
export default SecureRoute;
