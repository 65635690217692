import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { get, sortBy, values, find, isEmpty, intersectionWith } from 'lodash';
import { actions as eventSeriesActions } from '../../state/entities/eventSeries';
import { actions as venueActions } from '../../state/entities/venue';
import { actions as placePoolActions } from '../../state/entities/placePool';
import { actions as eventCategoriesActions } from '../../state/entities/eventCategory';
import { actions as venuePlanActions } from '../../state/entities/venuePlan';
import { actions as zukoZoneActions } from '../../state/entities/zukoZone';
import { actions as salesRuleActions } from '../../state/entities/salesRule';
import { actions as ticketLayoutActions } from '../../state/entities/ticketLayout';
import { actions as pricingClassActions } from '../../state/entities/pricingClass';
import { actions as pricingCategoryActions } from '../../state/entities/pricingCategory';
import { useLocation } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import TabsPanel from '../common/TabsPanel/TabsPanel';
import FormSalesRules, { getDeliveryGroupsWithDescriptions } from './FormSalesRules';
import FormZuko from './FormZuko';
import FormPricingClassesPriorities from './FormPricingClassesPriorities';
import FormPersonalization from './FormPersonalization';
import FormBarcode from './FormBarcode';
import ErrorFocus from '../common/formik/ErrorFocus';
import FeedbackButton from '../common/FeedbackButton';
import Footer from '../layout/Footer';
import FormData from './FormData';
import * as PropTypes from 'prop-types';
import { VenueEvent } from './schema';
import { LoadingIndicator } from '../common/LoadingIndicator';
import Api from '../../api';
import FormDeliveryGroupsPriorities from './FormDeliveryGroupsPriorities';
import FormStandingBlocks from "./FormStandingBlocks";
import FormQuotas from "./FormQuotas";
import FormBundles from "./FormBundles";
import styles from "../form.module.scss";
import ExportTickets from "./ExportTickets";

const api = new Api();

const INITIAL_VALUES = {
    id: '',
    ident: '',
    eventSeries: null,
    team1Image: null,
    team2Image: null,
    title: {
        de: '',
        en: '',
    },
    subtitle: {
        de: '',
        en: '',
    },
    voucherExternalEventId: null,
    venuePlan: null,
    startDate: null,
    endDate: null,
    doorsOpenAt: null,
    ticketSelectionExpiration: 15,
    isDatePreliminary: false,
    disableVisualSelection: false,
    disableSalesRulesAfterStartDate: false,
    disableSalesRulesAfterEndDate: true,
    preliminaryDateText: {
        de: '',
        en: '',
    },
    salesInformation: {
        de: '',
        en: '',
    },
    description: {
        de: '',
        en: '',
    },
    tags: {
        de: [],
        en: [],
    },
    coronaRulesUrl: '',
    salesRules: [],
    standingBlocks: [],
    quotas: [],
    maxTicketsSalesRulesGroups: [],
    bundles: [],
    salesRule: null,
    eventCategory: null,
    maxTicketsPerUser: '',
    pricingClassesPriorities: [],
    shippingCodesPriorities: [],
    salesChannelPersonalizations: [],
    barcodeContext: {
        barcodeConfig: null,
        barcodeType: 'QR100Utf8',
        hashSecret: null
    },
    artists: [],
    googleTrackingEnabled: false
};

const Form = (
    {
        onSubmit,
        submitPending,
        venueEvent,
        eventSeries,
        loadEventSeries,
        pricingClasses,
        loadPricingClasses,
        pricingCategories,
        loadPricingCategories,
        artists,
        loadVenues,
        loadEventCategories,
        salesRules,
        loadSalesRules,
        venue,
        eventCategory,
        loadVenuePlansByVenueId,
        loadZukoZonesByVenueId,
        zukoZones,
        placePools,
        loadPlacePools,
        ticketLayouts,
        loadTicketLayouts
    }) => {
    const location = useLocation();
    const [currentTab, setCurrentTab] = useState(location?.location?.tab || 'home');
    const [allDeliveryGroups, setAllDeliveryGroups] = useState([]);
    const [standingBlocks, setStandingBlocks] = useState({});
    const [databaseQuotas, setDatabaseQuotas] = useState({});
    const [newUpdateVersion, setNewUpdateVersion] = useState(0);
    const [updateBarcodeParts, setUpdateBarcodeParts] = useState([]);
    const [previewBarcode, setPreviewBarcode] = useState([]);

    const [barcodeLabels, setBarcodeLabels] = useState({
        encodings: [],
        types: [],
        imageTypes: [],
        hashTypes: [],
        partTypes: [],
        allowedDelimiters: {
            Code39: [],
            qr: []
        }
    });

    const loadQuotasByEvent = (eventId) => {
        api.getQuotasData(eventId).then(result => {
            setDatabaseQuotas(result);
        });
    };

    const loadStandingBlocksByVenueEventAndVenuePlan = (venueEventId, venuePlanId) => {
        api.getStandingBlocksData(venueEventId, venuePlanId).then(result => {
            setStandingBlocks(result);
        });
    };

    const updateBarcodePartLabels = (barcodePartLabels) => {
        if (!isEmpty(venueEvent)) {
            const updateBarcodeParts = !isEmpty(venueEvent.barcodeContext.barcodeConfig) &&
                intersectionWith(barcodePartLabels, venueEvent.barcodeContext.barcodeConfig.barcodeParts, (obj1, obj2) => obj1.id === obj2.type);
            setUpdateBarcodeParts(updateBarcodeParts);
        }
    }

    useEffect(() => {
        setNewUpdateVersion(venueEvent.updateVersion);
    }, [venueEvent.updateVersion]);

    useEffect(() => {
        loadPricingClasses();
        loadPricingCategories();
        loadVenues();
        loadSalesRules();
        loadEventCategories();
        loadPlacePools();
        loadTicketLayouts();
        loadEventSeries();
        if (venueEvent.venue) {
            loadVenuePlansByVenueId(venueEvent.venue)
            loadZukoZonesByVenueId(venueEvent.venue)
        }
        api.getDeliveryGroupsForVenueEvent().then(result => {
            const deliveryGroupsWithDescription = getDeliveryGroupsWithDescriptions(result)
            setAllDeliveryGroups(deliveryGroupsWithDescription);
        });
        api.getBarcodeLabels().then(data => {
            setBarcodeLabels(data);
            updateBarcodePartLabels(data.partTypes);
        });

        api.getBarcodePreview(venueEvent.id, venueEvent.barcodeContext).then(data => {
            setPreviewBarcode(data);
        });
        // Wir wollen diesen Effekt explizit nur einmal "on-mount" durchführen, daher geben wir [] als dependency an.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateBarcodePartLabels(barcodeLabels.partTypes);
    }, [venueEvent.barcodeContext?.barcodeConfig]);

    const ident = useRef(null);
    const title = useRef(null);
    const startDate = useRef(null);
    const endDate = useRef(null);
    const ticketLayout = useRef(null);
    const barcodeContext = useRef(null);

    const refsData = {
        ident,
        title,
        startDate,
        endDate,
        barcodeContext
    }

    const refsSalesRules = {
        ticketLayout
    }

    const handleSubmit = (venueEvent, formik) => {
        let event = {
            ...venueEvent,
            // 15 minutes is default value
            ticketSelectionExpiration: venueEvent.ticketSelectionExpiration === '' ? 900 : venueEvent.ticketSelectionExpiration * 60,
            endDate: venueEvent.endDate === undefined ? null : venueEvent.endDate,
            doorsOpenAt: venueEvent.doorsOpenAt === undefined ? null : venueEvent.doorsOpenAt,
            updateVersion: newUpdateVersion,
            artists: venueEvent.artists.map(artist => artist.id),
            barcodeContext: {
                ...venueEvent.barcodeContext.barcodeType !== 'Custom' ? {
                    barcodeConfig: null
                } : {
                    barcodeConfig: {
                        ...venueEvent.barcodeContext.barcodeConfig,
                        barcodeParts: venueEvent.barcodeContext.barcodeConfig.barcodeParts.map(item => ({ type: item.id })),
                    },
                },
                barcodeType: venueEvent.barcodeContext.barcodeType,
                hashSecret: venueEvent.barcodeContext.hashSecret === '' ? null : venueEvent.barcodeContext.hashSecret
            }
        };

        let rules = venueEvent.salesRules.map(rule => {
            const getSalesRuleId = rule.salesRule.id;
            // change this date when it sets in datepicker to +0 hours for send to api
            if (rule.activeMemberSinceAt && rule.activeMemberSinceAt instanceof Date) {
                const date = rule.activeMemberSinceAt;
                let newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
                rule = {...rule, salesRule: getSalesRuleId, activeMemberSinceAt: newDate}
            }
            if (rule.useFor === '') {
                rule = {...rule, salesRule: getSalesRuleId, useFor: null};
            }
            if (rule.maxTicketsPerUser === '') {
                return {...rule, salesRule: getSalesRuleId, maxTicketsPerUser: null};
            } else {
                return {...rule, salesRule: getSalesRuleId };
            }
        });

        api.getBarcodeCheckForProblems(event.barcodeContext).then(() => {
            return;
        }).then(() => {
            if (!isEmpty(venueEvent.id)) {
                api.getBarcodePreview(venueEvent.id, event.barcodeContext).then(data => {
                    setPreviewBarcode(data);
                });
            }      
            
            onSubmit({...event, salesRules: rules});
        }).catch(err => {
            const getErrors = get(err, 'errors', []);
            [getErrors].map((error, i) => {
                const errorKey = Object.keys(error)?.[i];
                const errorValue = Object.values(error)?.[i][0].message;
                if (errorKey)
                    formik.setFieldError(`barcodeContext.${errorKey}`, errorValue);
            });
        });
    };

    ticketLayouts.sort((a, b) => (a.name > b.name) ? 1 : -1);

    if (pricingClasses.length === 0) {
        return(
            <>
                <p>Keine Preisklassen gefunden</p>
                <LoadingIndicator/>
            </>
        )
    }
    if (allDeliveryGroups.length === 0) {
        return(
            <>
                <p>Keine Versandarten Vorauswahl gefunden</p>
                <LoadingIndicator/>
            </>
        )
    }

    const updatedSalesRules = !isEmpty(venueEvent) && venueEvent.salesRules.map(item => {
        const matchingRule = find(salesRules, { id: item.salesRule });
        return matchingRule ? { ...item, salesRule: matchingRule } : item;
    });

    const modifiedVenueEvent = {
        ...venueEvent,
        artists: [],
        salesRules: updatedSalesRules || [],
        ...!isEmpty(venueEvent) && {            
            barcodeContext: {
                ...venueEvent.barcodeContext,
                ...!isEmpty(venueEvent.barcodeContext.barcodeConfig) ? {       
                    barcodeConfig: {
                        ...venueEvent.barcodeContext.barcodeConfig,
                        barcodeParts: updateBarcodeParts || []
                    }
                } : {
                    barcodeConfig: null
                }
            }
        }
    };

    return <Formik initialValues={{...INITIAL_VALUES, ...modifiedVenueEvent }}
                   validationSchema={VenueEvent}
                   enableReinitialize
                   onSubmit={(event, formik) => handleSubmit(event, formik)}
    >
        {formik => {
            return (
                <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                    <Tabs id="uncontrolled-tab-example" value={currentTab} onChange={(event, newTab) => setCurrentTab(newTab)}>
                        <Tab value="home" label="Stammdaten" />
                        <Tab value="salesRules" label="Verkaufsregeln" />
                        <Tab value="quotas" label="Kontingente" />
                        <Tab value="bundles" label="Bundles" />
                        <Tab value="zukoZones" label="Zuko" />
                        <Tab value="standingBlocks" label="Stehplatzblock" />
                        <Tab value="pricingClassPriorities" label="Preisklassenvorauswahl" />
                        <Tab value="personalization" label="Personalisierung" />
                        <Tab value="deliveryGroupsPriorities" label="Versandarten Vorauswahl" />
                        <Tab value="export" label="Ticket-Export" />
                        <Tab value="barcode" label="Barcode Einstellungen" />
                    </Tabs>
                    <div className={styles.tabsContentWrapper}>
                        <TabsPanel value={currentTab} index={'home'} data-event-key='home'>
                            <FormData
                                venueEvent={venueEvent}
                                formik={formik}
                                onSubmit={onSubmit}
                                formSchema={VenueEvent}
                                initialValues={INITIAL_VALUES}
                                submitPending={submitPending}
                                venue={venue}
                                eventCategory={eventCategory}
                                eventSeries={eventSeries}
                                loadVenuePlansByVenueId={loadVenuePlansByVenueId}
                                loadZukoZonesByVenueId={loadZukoZonesByVenueId}
                                zukoZones={zukoZones}
                                refs={refsData}
                            />
                        </TabsPanel>

                        <TabsPanel value={currentTab} index={'salesRules'} data-event-key='salesRules'>
                            <FormSalesRules
                                ticketLayouts={ticketLayouts}
                                event={venueEvent}
                                onSubmit={onSubmit}
                                submitPending={submitPending}
                                initialValues={INITIAL_VALUES}
                                formSchema={VenueEvent}
                                formik={formik}
                                refs={refsSalesRules}
                            />
                        </TabsPanel>

                        <TabsPanel value={currentTab} index={'quotas'} data-event-key='quotas'>
                            <FormQuotas
                                event={venueEvent}
                                databaseQuotas={databaseQuotas}
                                loadQuotasByEvent={loadQuotasByEvent}
                                formik={formik}
                                refs={refsData}
                            />
                        </TabsPanel>

                        <TabsPanel value={currentTab} index={'bundles'} data-event-key='bundles'>
                            <FormBundles
                                event={venueEvent}
                                formik={formik}
                                refs={refsData}
                            />
                        </TabsPanel>

                        <TabsPanel value={currentTab} index={'zukoZones'} data-event-key='zukoZones'>
                            <div>
                                <FormZuko
                                    zukoZones={formik.values.venue ? zukoZones.filter(zone => zone.venue.id === formik.values.venue) : []}
                                    venueEvent={venueEvent}
                                    onSubmit={onSubmit}
                                    formik={formik}
                                />
                            </div>
                        </TabsPanel>

                        <TabsPanel value={currentTab} index={'standingBlocks'} data-event-key='standingBlocks'>
                            <FormStandingBlocks
                                pricingCategories={pricingCategories}
                                placePools={placePools}
                                formik={formik}
                                refs={refsData}
                                standingBlocks={standingBlocks}
                                loadStandingBlocksByVenueEventAndVenuePlan={loadStandingBlocksByVenueEventAndVenuePlan}
                            />
                        </TabsPanel>

                        <TabsPanel value={currentTab} index={'pricingClassPriorities'} data-event-key='pricingClassPriorities'>
                            <FormPricingClassesPriorities
                                pricingClasses={pricingClasses}
                                pricingClassesPriorities={venueEvent.pricingClassesPriorities || []}
                                formik={formik}
                            />
                        </TabsPanel>

                        <TabsPanel value={currentTab} index={'personalization'} data-event-key='personalization'>
                            <FormPersonalization
                                salesChannels={formik.values.salesChannelPersonalizations}
                                formik={formik}
                            />
                        </TabsPanel>

                        <TabsPanel value={currentTab} index={'deliveryGroupsPriorities'} data-event-key='deliveryGroupsPriorities'>
                            <FormDeliveryGroupsPriorities
                                deliveryGroups={allDeliveryGroups}
                                deliveryGroupsPriorities={venueEvent.shippingCodesPriorities || []}
                                onSubmit={onSubmit}
                                submitPending={submitPending}
                                initialValues={INITIAL_VALUES}
                                formSchema={VenueEvent}
                                formik={formik}
                                refs={refsData}
                            />
                        </TabsPanel>

                        <TabsPanel value={currentTab} index={'export'} data-event-key='export'>
                            <ExportTickets
                                eventShortName={venueEvent.ident}
                                eventId={venueEvent.id}
                            />
                        </TabsPanel>

                        <TabsPanel value={currentTab} index={'barcode'} data-event-key='barcode'>
                            <FormBarcode
                                barcodeLabels={barcodeLabels}
                                previewBarcode={previewBarcode}
                                formik={formik}
                                ticketLayouts={ticketLayouts}
                                refs={refsData}
                            />
                        </TabsPanel>
                    </div>
                    <ErrorFocus setCurrentTab={setCurrentTab} refs={{...refsData, ...refsSalesRules}}/>
                    <Footer>
                        {venueEvent.id && venueEvent.deepLink && <div className="footer-center-button">
                            <a href={venueEvent.deepLink} target="_blank" rel="noreferrer">
                                <FeedbackButton variant="outlined">
                                    Tickets für diese Veranstaltung kaufen
                                </FeedbackButton>
                            </a></div>}
                        <FeedbackButton to={`/event-management/event`}>
                            Abbrechen
                        </FeedbackButton>

                        <FeedbackButton
                            type="submit"
                            busy={submitPending || (formik.isSubmitting && formik.isValidating)}
                        >
                            Speichern
                        </FeedbackButton>
                    </Footer>
                </form>
            )
        }}
    </Formik>
};

Form.propTypes = {
    onSubmit: PropTypes.func,
    submitPending: PropTypes.bool,
    venueEvent: PropTypes.object,
    eventSeries: PropTypes.array,
    loadEventSeries: PropTypes.func,
    loadVenues: PropTypes.func,
    loadEventCategories: PropTypes.func,
    loadTicketLayouts: PropTypes.func,
    loadVenuePlansByVenueId: PropTypes.func,
    loadZukoZonesByVenueId: PropTypes.func,
    loadPricingClasses: PropTypes.func,
    loadArtists: PropTypes.func,
    placePools: PropTypes.array,
    loadPlacePools: PropTypes.func,
    loadPricingCategories: PropTypes.func,
    venue: PropTypes.array,
    eventCategory: PropTypes.array,
    ticketLayouts: PropTypes.array,
    pricingClasses: PropTypes.array,
    zukoZones: PropTypes.array
};

export default connect((state, ownProps) => {
    let eventSeries = state.entities.eventSeries.byId;
    const assignedEventSeries = get(ownProps, 'venueEvent.eventSeries');

    // Da die auswählbaren Eventserien asynchron geladen werden, sind diese noch nicht verfügbar, wenn das
    // Formular bereits angezeigt wird. daher mergen wir die ggf. dem Event bereits zugewiesene Eventserie
    // in die Auswahlmöglichkeiten, denn dann kann immer min. die bereits zugewiesene Eventserie angezeigt
    // und ausgewählt werden.
    if (assignedEventSeries) {
        eventSeries = {
            ...eventSeries,
            [assignedEventSeries.id]: assignedEventSeries
        };
    }
    const venues = Object.values(state.entities.venue.byId);
    const eventCategories = Object.values(state.entities.eventCategory.byId);
    
    return {
        eventSeries: sortBy(values(eventSeries), 'name'),
        venue: venues,
        eventCategory: eventCategories,
        zukoZones: Object.values(state.entities.zukoZone.byId),
        ticketLayouts: Object.values(state.entities.ticketLayout.byId),
        pricingClasses: Object.values(state.entities.pricingClass.byId),
        pricingCategories: Object.values(state.entities.pricingCategory.byId),
        salesRules: Object.values(state.entities.salesRule.byId),
        placePools: Object.values(state.entities.placePool.byId),
    }
}, {
    loadEventSeries: eventSeriesActions.loadAll,
    loadVenues: venueActions.loadAll,
    loadEventCategories: eventCategoriesActions.loadAll,
    loadTicketLayouts: ticketLayoutActions.loadAll,
    loadVenuePlansByVenueId: id => venuePlanActions.byVenue({id}),
    loadZukoZonesByVenueId: id => zukoZoneActions.byVenue({id}),
    loadPricingClasses: pricingClassActions.loadAll,
    loadPricingCategories: pricingCategoryActions.loadAll,
    loadSalesRules: salesRuleActions.loadAll,
    loadPlacePools: placePoolActions.loadAll,
})(Form)
