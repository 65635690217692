import React, {useState, useEffect} from "react";
import * as yup from "yup";
import {get, isNumber, keyBy} from 'lodash';
import {Formik} from "formik";
import {Col, Row} from "react-bootstrap";
import {FormikTextInputGroup} from "../../common/formik/FormikTextInputGroup";
import AutoSubmit from "../../common/formik/AutoSubmit";
import Euro from "../../common/numberFormat/Euro";
import styles from "../../form.module.scss";
import FormikSelect from "../../common/formik/FormikSelect";
import Api from "../../../api";

const pricingComponentDefault = {
    productBookingGroup: "",
    productBookingGroupDescription: "",
    productBookingGroupVatKey: "",
    productBookingGroupVatValue: ""
};

const pricingComponentSchema = yup.object().shape({
    productBookingGroup: yup.string(),
    productBookingGroupDescription: yup.string(),
    productBookingGroupVatKey: yup.string().nullable(),
    productBookingGroupVatValue: yup.number()
        .transform((value, originalValue) => isNumber(originalValue) ? value: null)
        .min(0).nullable()
});

const FORM_SCHEMA = yup.object().shape({
    mainPricingComponent: pricingComponentSchema,
    pricingComponents: yup.array().of(pricingComponentSchema)
});


export default ({localItemTemplate, onChange}) => {
    const api = new Api();
    const [taxCategoryLabels, setTaxCategoryLabels] = useState([]);
    useEffect(() => {api.getTaxCategoryLabels().then(res => {setTaxCategoryLabels(res)})}, []);

    const itemTemplateOriginal = localItemTemplate.purchasableItemTemplate;
    const originalPricingComponentsById = keyBy(itemTemplateOriginal.pricingComponents, 'id');
    const pricingComponentOverridesById = keyBy(localItemTemplate.pricingComponents, 'id');

    const initialValues = {};
    initialValues.mainPricingComponent = {...pricingComponentDefault, ...localItemTemplate.mainPricingComponent};
    initialValues.pricingComponents = itemTemplateOriginal.pricingComponents.map(pricingComponent => ({
        id: pricingComponent.id, ...pricingComponentDefault, ...get(pricingComponentOverridesById, pricingComponent.id, {})
    }));

    return <div className="mt-4">
        <Formik initialValues={initialValues}
                validationSchema={FORM_SCHEMA}
                onSubmit={onChange}
        >
            {formik => (
                <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                    <Row><Col className="ml-2"><strong>{itemTemplateOriginal.mainPricingComponent.name}</strong></Col></Row>

                    <Row> 
                        <Col>
                            <FormikTextInputGroup
                                label="Produktbuchungsgruppe"
                                name="mainPricingComponent.productBookingGroup"
                                placeholder={itemTemplateOriginal.mainPricingComponent.productBookingGroup}
                            />
                        </Col>
                        <Col>
                            <p className={!itemTemplateOriginal.mainPricingComponent.productBookingGroupAppendEventSuffix ? "hidden" : "text-muted"}>+Eventsuffix</p>
                        </Col>
                        <Col>
                            <FormikSelect
                                label="MwSt.-Produktbuchungsgruppe"
                                name="mainPricingComponent.productBookingGroupVatKey"
                                placeholder={itemTemplateOriginal.mainPricingComponent.productBookingGroupVatKey}
                                options={taxCategoryLabels}
                            />
                        </Col>
                        <Col>
                            <p className={!itemTemplateOriginal.mainPricingComponent.productBookingGroupVatKeyAppendEventSuffix ? "hidden" : "text-muted"}>+Eventsuffix</p>
                        </Col>

                    </Row>
                    <Row>
                        <Col><FormikTextInputGroup label="Mehrwertsteuersatz" type="number" value=""
                                                   name="mainPricingComponent.productBookingGroupVatValue"
                                                   placeholder={`${itemTemplateOriginal.mainPricingComponent.productBookingGroupVatValue}`}
                        /></Col>
                        <Col><FormikTextInputGroup label="Beschr. ProdBuchGrp"
                                                   name="mainPricingComponent.productBookingGroupDescription"
                                                   placeholder={itemTemplateOriginal.mainPricingComponent.productBookingGroupDescription}
                        /></Col>
                    </Row>

                    {initialValues.pricingComponents.map((pricingComponent, index) => {

                        const originalPricingComponent = originalPricingComponentsById[pricingComponent.id];

                        return <React.Fragment key={pricingComponent.id}>

                            <Row><Col className="ml-2 mt-4"><strong>{originalPricingComponent.name}: <Euro centAmount={originalPricingComponent.grossAmount}/></strong></Col></Row>

                            <Row>
                                <Col><FormikTextInputGroup
                                    label="Produktbuchungsgruppe"
                                    name={`pricingComponents[${index}].productBookingGroup`}
                                    placeholder={originalPricingComponent.productBookingGroup}
                                />
                                    <p className={!originalPricingComponent.productBookingGroupAppendEventSuffix ? "hidden" : "text-muted"}>+Eventsuffix</p>
                                </Col>
                                <Col>
                                    <FormikSelect
                                        label="MwSt.-Produktbuchungsgruppe"
                                        name={`pricingComponents[${index}].productBookingGroupVatKey`}
                                        placeholder={originalPricingComponent.productBookingGroupVatKey}
                                        options={taxCategoryLabels}
                                    />
                                    <p className={!originalPricingComponent.productBookingGroupVatKeyAppendEventSuffix ? "hidden" : "text-muted"}>+Eventsuffix</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col><FormikTextInputGroup label="Mehrwertsteuersatz" type="number"
                                                           name={`pricingComponents[${index}].productBookingGroupVatValue`}
                                                           placeholder={`${originalPricingComponent.productBookingGroupVatValue}`}
                                /></Col>
                                <Col><FormikTextInputGroup label="Beschr. ProdBuchGrp"
                                                           name={`pricingComponents[${index}].productBookingGroupDescription`}
                                                           placeholder={originalPricingComponent.productBookingGroupDescription}
                                /></Col>
                            </Row>
                        </React.Fragment>
                    })}

                    <AutoSubmit />
                </form>
            )}
        </Formik>
    </div>;
};
