import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import GlobalError from './GlobalError';
import {dismiss} from '../../../state/errors/actions';

/**
 * Redux-Connected Liste der globalen Fehler.
 *
 * @constructor
 */
function GlobalErrorList({errors, dismiss}) {

    function dismissError(error) {
        dismiss({error})
    }

    return (
        <div className="error-message">
            {errors.map((error, i) => <GlobalError key={i} error={error} onDismiss={dismissError}/>)}
        </div>
    );
}

GlobalErrorList.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.shape({
        message: PropTypes.string.isRequired
    })).isRequired
};

export default connect(({errors}) => ({errors}), {dismiss})(GlobalErrorList);
