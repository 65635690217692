import React, { useEffect, useState } from 'react';
import debounce from 'debounce-promise';
import { getIn } from 'formik';
import Api from '../../api';
import { FormikTextInputGroup } from '../common/formik/FormikTextInputGroup';
import { FormikDateTimeInput } from '../common/formik/FormikDateTimeInput';
import { FormikCheckbox } from '../common/formik/FormikCheckbox';
import { memoize } from '../../form/fieldValidation';
import { Col, Row } from 'react-bootstrap';
import { FormikSelect } from '../common/formik/FormikSelect';
import PropTypes from 'prop-types';
import { isEmpty, intersectionWith } from 'lodash';
import FeedbackButton from '../common/FeedbackButton';
import Modal from 'react-bootstrap/Modal';
import HelpContent from '../common/help/HelpContent';
import FormikAsyncTypeaheadInput from "../common/formik/FormikAsyncTypeaheadInput";
import { connect, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import ListingModal from "../common/modal/ListingModal";
import FieldImage from '../common/FieldImage/FieldImage';
import styles from "../form.module.scss";

export const SECONDARY_MARKET_FEE_FACTOR_CODE = [
    {id: 'FREE', name: 'Kostenlos'},
    {id: 'FIVE', name: '5%'},
    {id: 'TEN', name: '10%'}
]

export const USE_FOR = [
    {id: 'placePools', name: 'Platzpools'},
    {id: 'pricingList', name: 'Preisliste'}
]

const api = new Api();

const FormData = ({
    venueEvent,
    eventSeries,
    loadZukoZonesByVenueId,
    venue,
    eventCategory,
    zukoZones,
    formik,
    refs,
    formSchema,
    initialValues,
    helpTextsVisible
}) => {

    const [showChangeVenueConfirmDialog, setShowChangeVenueConfirmDialog] = useState(false);
    const [newVenueId, setNewVenueId] = useState('');
    const [refreshKey, setRefreshKey] = useState(0);
    const [isEventCategoryFilterApplied, setIsEventCategoryFilterApplied] = useState(true);

    const [activeFieldName, setActiveFieldName] = useState('');
    const [isShowBatchModal, setIsShowBatchModal] = useState(false);
    const [venuePlanOptions, setVenuePlanOptions] = useState([]);
    const currentTenant = useSelector(state => state.currentTenant);

    const closeModal = () => {
        setIsShowBatchModal(false);
    }

    const onHandleShowModal = (e, fieldName) => {
        e.stopPropagation();
        setIsShowBatchModal(true);
        setActiveFieldName(fieldName);
    }

    const onHanldleRemove = (e, fieldName) => {
        e.stopPropagation();
        formik.setFieldValue(fieldName, null);
    }
    
    const onHandleChooseImage = (item) => {
        const { id, title, url } = item;
        formik.setFieldValue(activeFieldName, {
            title,
            url,
            id,
        });
        setIsShowBatchModal(false);
    }
    // Die debounce Funktion aus lodash reicht hier nicht aus, denn wir brauchen ein debounctes Promise.
    // Bereits beim ersten Aufruf muss ein Promise geliefert werden, damit Formik weiß, dass die Validierung
    // anegstoßen wurde. Dieses Promise darf aber erst dann aufgelöst werden wenn die debouncte async Funktion
    // selbst abgeschlossen ist.
    const getVenueEventByIdentDebounced = debounce(ident => api.getVenueEventByIdent(ident), 500);

    // async field-level Validierung für ident, memoisiert um zu vermeiden,
    // dass die API bei jeder Änderung des Formulars abgefragt wird.
    const validateIdent = memoize({
        initialValue: venueEvent.ident,
        async validate(ident) {
            // Wenn der Ident leer ist brauchen wir nicht die API zu fragen.
            if (!ident) return;
            if (ident.length > 10) {
                return 'Die maximale Länge der Kurzbezeichnung beträgt 10 Zeichen'
            }
            const event = await getVenueEventByIdentDebounced(ident);

            if (event && event.id !== venueEvent.id) {
                return 'Der Kurzbezeichnung wird bereits verwendet'
            }
        }
    });

    const changeVenueSelection = (newId) => {
        formik.setFieldValue('venuePlan', null);
        api.byVenue(newId).then(res => setVenuePlanOptions(res))
        setShowChangeVenueConfirmDialog(false);
    }

    const handleCancelChangeVenue = () => {
        setNewVenueId(venueEvent.venue)
        formik.setFieldValue('venue', venueEvent.venue)
        setShowChangeVenueConfirmDialog(false);
    }

    const handleVenueSelection = (newId) => {
        setNewVenueId(newId)
        loadZukoZonesByVenueId(newId)
        const newZukoZones = zukoZones.filter(zone => zone.venue.id === newId)
        const newZukoZoneIds = newZukoZones.map(zone => zone.id)
        // check if the zuko zones selected for this event are also part of the newly chosen venue
        // if not all selected zuko zones for this event are in the newly chosen venue, show the modal

        if (venueEvent.zukoZones && venueEvent.zukoZones.length > 0) {
            if (venueEvent.zukoZones.find(zone => !newZukoZoneIds.includes(zone)) !== undefined) {
                setShowChangeVenueConfirmDialog(true);
            }
        } else {
            changeVenueSelection(newId)
        }
    };

    let selectedEventCategory = formik.values.eventCategory ?? null;

    const changeEventCategory = (newId) => {
        selectedEventCategory = newId;
    };

    const handleEventCategoryFilter = (filterChecked) => {
        setIsEventCategoryFilterApplied(filterChecked)
        setRefreshKey(prevKey => prevKey + 1);
    }

    //touch required fields to mark them into red border
    useEffect(() => {
        const requiredFields = [
            'title.en',
            'title.de',
            'ident',
            'startDate',
            'endDate',
        ];

        requiredFields.forEach((field) => {
            formik.setFieldTouched(field, true, false);
        })
        !isEmpty(formik.values.venue) && api.byVenue(formik.values.venue).then(res => setVenuePlanOptions(res))

        if (!isEmpty(venueEvent.artists)) {
            api.getArtistsByQuery().then(res => formik.setFieldValue('artists', intersectionWith(res, venueEvent.artists, (a, b) => a.id === b)));
        }
    }, []);

    return (
        <>
            <div className={styles.formBox}>
                <h2 className={styles.formTitle}>Allgemein</h2>
                <Row className="mt-3">
                    <Col className="col-md-4">
                        <FormikTextInputGroup label="Eventtitel* (deutsch)" name="title.de" inputRef={refs.title} testid="titleDe"
                                                helpText="Dieser Name wird auf dem Ticket angezeigt."/>
                    </Col>
                    <Col className="col-md-4">
                        <FormikTextInputGroup label="Eventtitel* (englisch)" name="title.en" inputRef={refs.title} testid="titleEn"
                                                helpText="Dieser Name wird auf dem Ticket angezeigt."/>
                    </Col>
                    <Col className="col-md-4">
                        <FormikTextInputGroup label="Eventuntertitel (deutsch)" name="subtitle.de"
                                                helpText="Dieser Name wird auf dem Ticket angezeigt."/>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-md-4">
                        <FormikTextInputGroup label="Eventuntertitel (englisch)" name="subtitle.en"
                                                helpText="Dieser Name wird auf dem Ticket angezeigt."/>
                    </Col>
                    <Col className="col-md-4">
                        <FormikTextInputGroup label="Kurzbezeichnung*" name="ident" validate={validateIdent} testid="ident" inputRef={refs.ident}
                                                helpText="Kürzel des Events für Anzeige im Backend."/>
                    </Col>
                    <Col className="col-md-4">
                        <FormikSelect
                            name="eventSeries"
                            label="Eventserie"
                            options={eventSeries}
                            emptyValueLabel="Bitte wählen ..."
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-md-4">
                        <FormikCheckbox label="Plätze ohne grafischen Saalplan verkaufen" name="disableVisualSelection"/>
                    </Col>
                </Row>
            </div>
            <div className={styles.formBox}>
                <h2 className={styles.formTitle}>Grafiken</h2>
                <HelpContent as="p" className="text-muted">Hier werden die URLs der Grafiken eingefügt.</HelpContent>
                <Row className="mt-3">
                    <Col className="col-md-4">
                        <FieldImage
                            values={getIn(formik.values, 'team1Image')}
                            fieldName='team1Image'
                            label="Logo/Bild 1/Vereinslogo 1"
                            onHandleShowModal={onHandleShowModal}
                            onHanldleRemove={onHanldleRemove}
                            helpText='Vereinslogos werden im Ticketauswahlfenster angezeigt.'
                        />
                    </Col>
                    <Col className="col-md-4">
                        <FieldImage
                            values={getIn(formik.values, 'team2Image')}
                            fieldName='team2Image'
                            label="Logo/Bild 2/Vereinslogo 2"
                            onHandleShowModal={onHandleShowModal}
                            onHanldleRemove={onHanldleRemove}
                            helpText="Vereinslogos werden im Ticketauswahlfenster angezeigt."
                        />
                    </Col>
                    <Col className="col-md-4">
                        <FieldImage
                            values={getIn(formik.values, 'walletLogo')}
                            fieldName='walletLogo'
                            label="Wallet Logo"
                            onHandleShowModal={onHandleShowModal}
                            onHanldleRemove={onHanldleRemove}
                            helpText="Wird in der Wallet (Frontseite) oben links angezeigt"
                        />
                    </Col>
                    <Col className="col-md-4">
                        <FieldImage
                            values={getIn(formik.values, 'walletHeader')}
                            fieldName='walletHeader'
                            label="Wallet Headergrafik"
                            onHandleShowModal={onHandleShowModal}
                            onHanldleRemove={onHanldleRemove}
                            helpText="Wird in der Wallet (Frontseite) oben als Gestaltungselement angezeigt"
                        />
                    </Col>
                </Row>
            </div>
            <div className={styles.formBox}>
                <h2 className={styles.formTitle}>Zeiten</h2>
                <Row className="mt-3">
                    <Col className="col-md-4">
                        <FormikDateTimeInput label="Startzeitpunkt*" name="startDate" enableTime={true} inputRef={refs.startDate} testid="startDate"/>
                    </Col>
                    <Col className="col-md-4">
                        <FormikDateTimeInput label="Endzeitpunkt" name="endDate" enableTime={true} inputRef={refs.endDate}/>
                    </Col>
                    <Col className="col-md-4">
                        <FormikDateTimeInput label="Einlasszeitpunkt" name="doorsOpenAt" enableTime={true} inputRef={refs.doorsOpenAt}/>
                    </Col>
                    <Col className="col-md-4">
                        <FormikTextInputGroup label="Ablaufzeit bei Ticketauswahl" name="ticketSelectionExpiration"
                                                helpText="Die Zeit, in der das Ticket reserviert bleibt, nachdem es in den Warenkorb gelegt wurde (in Minuten)."/>
                    </Col>
                    <Col className="col-md-4">
                        <FormikTextInputGroup label="Text für &quot;Terminierung noch vorläufig&quot; (deutsch)"
                                                name="preliminaryDateText.de"
                        />
                    </Col>
                    <Col className="col-md-4">
                        <FormikTextInputGroup label="Text für &quot;Terminierung noch vorläufig&quot; (englisch)"
                                                name="preliminaryDateText.en"
                        />
                    </Col>
                    <Col className="col-md-12">
                        <FormikCheckbox label="Terminierung noch vorläufig" name="isDatePreliminary"/>
                    </Col>
                </Row>
            </div>
            <div className={styles.formBox}>
                <h2 className={styles.formTitle}>Weiteres</h2>
                <Row className="mt-3">
                    <Col className="col-md-4">
                        <FormikTextInputGroup
                            label="Vertriebsinformation (deutsch)"
                            name="salesInformation.de"
                            type="textarea"
                        />
                    </Col>
                    <Col className="col-md-4">
                        <FormikTextInputGroup
                            label="Vertriebsinformation (englisch)"
                            name="salesInformation.en"
                            type="textarea"
                        />
                    </Col>
                    <Col className="col-md-4">
                        <FormikAsyncTypeaheadInput
                            id="voucherExternalEventId"
                            label="Zu nutzende Event-ID auf Seiten der Gutschein-API"
                            placeholder="Bitte wählen..."
                            labelKey={o => o.name}
                            onSearch={() => api.getExternalEvents()}
                            minLength={0}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-md-4">
                        <FormikAsyncTypeaheadInput
                            id="tags.de"
                            label="Eventtag (deutsch)"
                            onSearch={() => api.getTagsForVenueEventsByLang('de')}
                            minLength={0}
                            testid="tags.de"
                            multiple={true}
                            arrayOfStrings={true}
                            allowNew={true}
                            helpText="Tags, nach denen gesucht werden kann, um dieses Event zu finden."
                        />
                    </Col>
                    <Col className="col-md-4">
                        <FormikAsyncTypeaheadInput
                            id="tags.en"
                            label="Eventtag (englisch)"
                            onSearch={() => api.getTagsForVenueEventsByLang('en')}
                            minLength={0}
                            testid="tags.en"
                            multiple={true}
                            arrayOfStrings={true}
                            allowNew={true}
                            helpText="Tags, nach denen gesucht werden kann, um dieses Event zu finden."
                        />
                    </Col>
                    <Col className="col-md-4">
                        <FormikSelect
                            name="eventCategory"
                            label="Eventart"
                            options={eventCategory}
                            emptyValueLabel="Bitte wählen ..."
                            onChange={changeEventCategory}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-md-4">
                        <FormikAsyncTypeaheadInput
                            id="artists"
                            label="Künstler"
                            key={refreshKey}
                            onSearch={query => isEventCategoryFilterApplied ? api.getArtistsByQuery(query, selectedEventCategory) : api.getArtistsByQuery(query)}
                            labelKey={o => o.name}
                            minLength={0}
                            options={null}
                            multiple={true}
                            helpText="Hier können mehrere Künstler hinzugefügt werden, geben Sie für die Suche die Künstler in das Feld ein."
                        />
                    </Col>
                    <Col className="col-md-4 align-self-center">
                        <FormikCheckbox
                            id="eventCategoryFilter"
                            label={"Nur aus aktueller Eventart (DE)"}
                            onChange={(e) => handleEventCategoryFilter(e.target.checked)}
                            className="d-inline"
                            checked={isEventCategoryFilterApplied}
                            name="disableVisualSelection"
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-md-4">
                        <FormikTextInputGroup
                            label="Eventinformation (deutsch)"
                            name="description.de"
                            type="textarea"
                        />
                    </Col>
                    <Col className="col-md-4">
                        <FormikTextInputGroup
                            label="Eventinformation (englisch)"
                            name="description.en"
                            type="textarea"
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="pt-2 col-auto">
                        <FormikCheckbox label="Google Tracking aktivieren?"
                                        helpTextsVisible={helpTextsVisible}
                                        helpText="Hier können Sie Ihr Google Tracking aktivieren, wenn Sie das User-Verhalten mit Google im Frontend tracken wollen und eine Google Tagmanager Id hinterlegt haben."
                                        name="googleTrackingEnabled" disabled={!currentTenant.currentTenant.googleTagManagerId}/>
                    </Col>
                </Row>
            </div>
            <div className={styles.formBox}>
                <h2 className={styles.formTitle}>Spielstätte und Saalpläne</h2>
                <HelpContent as="p" className="text-muted">Hier wird die Spielstätte des Events und das Spielstätten-Layout ausgewählt, darunter kann der Saalplan ausgewählt werden.</HelpContent>
                <Row className="mt-3">
                    <Col className="col-md-4">
                        <FormikSelect
                            name="venue"
                            label="Spielstätte"
                            options={venue}
                            emptyValueLabel="Bitte wählen ..."
                            onChange={handleVenueSelection}
                        />
                    </Col>
                    <Col className="col-md-4">
                        <FormikSelect
                            name="venuePlan"
                            label="Saalplan"
                            options={venuePlanOptions}
                            emptyValueLabel="Bitte wählen ..."
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-md-8">
                        {formik.values.venue && formik.values.venuePlan ? (
                        <FeedbackButton to={`/base/venue/${formik.values.venue}/venue-plan/${formik.values.venuePlan}`}>
                            Saalplan bearbeiten
                        </FeedbackButton>
                    ) : ''}
                    </Col>
                </Row>

                <Modal show={showChangeVenueConfirmDialog} onHide={handleCancelChangeVenue}>
                    <Modal.Header closeButton>
                        <Modal.Title>Spielstätte ändern</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Beim Ändern der Spielstätte werden die derzeitig ausgewählten Zuko-Zonen entfernt. Möchten Sie fortfahren?</Modal.Body>
                    <Modal.Footer>
                        <FeedbackButton variant="outlined" onClick={handleCancelChangeVenue}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton onClick={() => changeVenueSelection(newVenueId)}>
                            Ändern
                        </FeedbackButton>
                    </Modal.Footer>
                </Modal>
            </div>
            <ListingModal
                show={isShowBatchModal}
                title="Bild wählen"
                initialValues={initialValues}
                validationSchema={formSchema}
                onCancel={closeModal}
                onHandleChooseImage={onHandleChooseImage}
            />
        </>
    )
};

FormData.propTypes = {
    submitPending: PropTypes.bool,
    formik: PropTypes.object,
    venueEvent: PropTypes.any,
    eventSeries: PropTypes.any,
    loadZukoZonesByVenueId: PropTypes.func,
    venue: PropTypes.any,
    eventCategory: PropTypes.any,
    venuePlan: PropTypes.any,
    zukoZones: PropTypes.array,
    refs: PropTypes.object
};

const mapStateToProps = (state, props) => {

    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps)(FormData);
