import React, {useCallback, useEffect} from 'react';
import {Col, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {FormikTextInputGroup} from '../common/formik/FormikTextInputGroup';
import FormikSelect from "../common/formik/FormikSelect";
import FormPayonePaymentProducts from "./FormPayonePaymentProducts";
import Form from "react-bootstrap/Form";
import {getApiBaseUrl} from "../../utils/utils";

export const ENVIRONMENTS = [
    {id: 'production', name: 'Production'},
    {id: 'test', name: 'Test'},
]

function FormPayonePaymentSettings({ formik, tenant }) {
    useEffect(() => {
        if (formik.values.paymentSettings?.provider !== 'payone') {
            return;
        }

        if (formik.values.paymentSettings?.payone === null) {
            formik.setFieldValue('paymentSettings.payone', {
                    environment: 'test',
                    merchantId: '',
                    apiKey: '',
                    apiSecret: '',
                    webhookKey: '',
                    webhookSecret: '',
                    paymentProducts: [],
                }
            );
        }
    }, [formik]);

    const getPortal = useCallback(() => {
        if (formik.values.paymentSettings?.payone?.environment === 'production') {
            return 'https://portal.payone.com';
        } else {
            return 'https://portal.preprod.payone.com';
        }
    }, [formik]);

    const webhookUrl = getApiBaseUrl() + "/webhook/payone";

    const visible = formik.values.paymentSettings.provider === 'payone';

    return (
        <>
            <Row className={`${!visible ? 'hidden' : ''}`}>
                <Col className="col-md-4">
                    <Row className="mt-3">
                        <Col className="col-md-12">
                            <FormikSelect
                                name="paymentSettings.payone.environment"
                                label="Umgebung"
                                options={ENVIRONMENTS}
                                isShowEmptyValue={false}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-md-12">
                            <FormikTextInputGroup
                                label="Ihre PSPID in unserer Test- oder Live-Umgebung"
                                name="paymentSettings.payone.merchantId"
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-md-12">
                            <FormikTextInputGroup
                                label={`API-Schlüssel-ID, den Sie im Merchant Portal finden ${getPortal()}/developer/payment-api`}
                                name="paymentSettings.payone.apiKey"
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-md-12">
                            <FormikTextInputGroup
                                label={`Geheimer API-Schlüssel, den Sie im Merchant Portal finden ${getPortal()}/developer/payment-api`}
                                name="paymentSettings.payone.apiSecret"
                                type='password'
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-md-12">
                            <FormikTextInputGroup
                                label={`Webhook-ID, den Sie im Merchant Portal finden ${getPortal()}/developer/webhooks`}
                                name="paymentSettings.payone.webhookKey"
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-md-12">
                            <FormikTextInputGroup
                                type='password'
                                label={`Geheimer Webhook-Schlüssel, den Sie im Merchant Portal finden ${getPortal()}/developer/webhooks`}
                                name="paymentSettings.payone.webhookSecret"
                            />
                        </Col>
                    </Row>
                </Col>
                <Col className="col-md-6">
                    <Row className="mt-3">
                        <Col className="col-md-12">
                            <h3>Wählen Sie hier ihre grundsätzlich möglichen Zahlungsarten aus:</h3>
                            <FormPayonePaymentProducts formik={formik}/>
                        </Col>
                    </Row>
                </Col>
                <Col className="col-md-4">
                    <Row className="mt-3">
                        <Col className="col-md-12">
                            <h3>Webhook-Endpunkt-URL. die Sie im Merchant Portal {getPortal()}/developer/webhooks
                                hinzufügen sollten.</h3>
                            {tenant.slug === formik.values.slug ? <Form.Control
                                as="textarea"
                                value={webhookUrl}
                                readOnly={true}
                            /> : <p> Bitte speichern Sie den Mandanten zuerst, um die URL zu sehen. </p>}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

FormPayonePaymentSettings.propTypes = {
    formik: PropTypes.object,
    tenant: PropTypes.object,
};

export default FormPayonePaymentSettings;
