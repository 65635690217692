import * as yup from "yup";
import React, {useEffect, useState} from 'react';
import Api from "../../api";
import { Formik } from "formik";
import { get } from 'lodash';
import { Col, Row } from "react-bootstrap";
import FormikObjectSelectMui from "../common/formik/FormikObjectSelectMui";
import * as currentTenant from "../../state/currentTenant/currentTenantSlice";
import {useDispatch, useSelector} from "react-redux";
import LoadingButton from '../common/LoadingButton/LoadingButton';
import styles from "../form.module.scss";

const FORM_SCHEMA = yup.object().shape({
    tenant: yup.mixed().required()
});

const api = new Api();

const TenantSelect = ({
    isShowEmptyValue
}) => {
    const useCurrentTenant = useSelector(state => state.currentTenant);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isActiveTenant, setIsActiveTenant] = useState(false);
    const [listTenants, setListTenants] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        api.getTenantsSelect().then(( data ) => {
            setListTenants(data);
            setIsLoading(false);
        })
    }, []);

    useEffect(() => {
        const filtered = listTenants.some((tenant) => tenant.id === useCurrentTenant.selectedTenant?.id);
        setIsActiveTenant(filtered)
    }, [useCurrentTenant.selectedTenant?.id, listTenants]);

    const onHandleSubmit = (values) => {
        setIsLoading(true);
    
        setTimeout(() => {
            const getSelectTanent = get(values, 'tenant');
            const foundDefaultTenant = listTenants.find((tenant) => tenant.id === getSelectTanent.id);

            localStorage.setItem('state', JSON.stringify({
                helpTextsToggle: false,
                currentTenant: {
                    selectedTenant: foundDefaultTenant,
                    currentTenant: useCurrentTenant.currentTenant
                }
            }));
            dispatch(currentTenant.actions.setSelectedTenant(foundDefaultTenant));
            setIsLoading(false);

            // noinspection SillyAssignmentJS
            window.location.href = window.location.href;
        }, 500);
    };

    return (
        <Formik
            initialValues={{ tenant: isActiveTenant ? useCurrentTenant.selectedTenant : null }}
            validationSchema={FORM_SCHEMA}
            onSubmit={onHandleSubmit}
            enableReinitialize
        >
            {({ handleSubmit }) => (
                <form className={styles.formCotainer}>
                    <div className={styles.formBox}>
                        <Row className="mt-3">
                            <Col className="col-md-4">
                                <FormikObjectSelectMui
                                    name="tenant"
                                    label="Select tenant*"
                                    options={listTenants}
                                    disabled={isLoading}
                                    isShowEmptyValue={isShowEmptyValue}
                                />
                            </Col>
                        </Row>

                        <LoadingButton isLoading={isLoading} handleSubmit={handleSubmit} >
                            speichern
                        </LoadingButton>
                    </div>
                </form>
            )}

        </Formik>
    )
};

export default TenantSelect;
