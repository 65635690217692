import React from 'react';
import PropTypes from 'prop-types';
import {Alert} from 'react-bootstrap';
import ErrorIcon from '@mui/icons-material/Error';

/**
 * Komponente zur Anzeige einer globalen Fehlermeldung.
 *
 * @param error Der anzuzeigenden Fehler
 * @param onDismiss Handler für der mit dem Fehler aufgerufen wird, wenn die Fehlermeldung ausgeblendet werden soll.
 *
 * @constructor
 */
function GlobalError({error, onDismiss}) {
    return (
        <Alert className="text-center" variant="danger" dismissible onClose={() => onDismiss && onDismiss(error)}>
            <ErrorIcon fontSize='small' />&ensp;<span>{error.message}</span>
        </Alert>
    );
}

GlobalError.propTypes = {
    onDismiss: PropTypes.func,
    error: PropTypes.shape({
        message: PropTypes.string.isRequired
    }).isRequired
};

export default GlobalError;
