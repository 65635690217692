import React from "react";
import {calculateMainPricingComponentAmount} from "./helper";
import Euro from "../../common/numberFormat/Euro";

export default ({grossAmount, itemTemplate}) => {
    const mainPricingComponentAmount = calculateMainPricingComponentAmount(grossAmount, itemTemplate);

    const mainComponent = grossAmount
        ? <div className="font-italic d-flex">
            <span className="text-truncate" title={itemTemplate.mainPricingComponent.name}>{itemTemplate.mainPricingComponent.name}</span>
            <span className="mr-auto">:</span>
            <span className="formatted-number-euro"><Euro centAmount={mainPricingComponentAmount}/></span>
          </div>
        : <div className="font-italic">
            {itemTemplate.mainPricingComponent.name}
          </div>;

    return <small>
        {mainComponent}
        {itemTemplate.pricingComponents.map((pc, index) =>
            <div key={index} className="d-flex">
              <span className="text-truncate" title={pc.name}>{pc.name}</span>
              <span className="mr-auto">:</span>
              <span className="formatted-number-euro"><Euro centAmount={pc.grossAmount}/></span>
            </div>
        )}
    </small>;
};
