import React from 'react';
import Form from "./Form";
import * as placePool from "../../state/entities/placePool";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

const Create = ({createPending, createPlacePool}) => {

    const onSubmit = (placePool, {setErrors}) => {
        createPlacePool(placePool, setErrors);
    };


    return <>
        <Row className="my-4">
            <Breadcrumbs entity={["Platzpool"]} endpoints = {['place_pool/breadcrumb']} title = "Platzpools" links={['/base/place-pool/']} active={[0]} />
        </Row>
        <Row className="mb-4 mt-4">
            <Col className="pt-3 col-sm-8">
                <h1>Erstellen eines neuen Platzpools</h1>
            </Col>
        </Row>
        <Form placePool={{}} onSubmit={onSubmit} submitPending={createPending} />
    </>;

}

const mapStateToProps = (state, props) => {
    return {
        createPending: state.entities.placePool.createPending
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createPlacePool: entity => dispatch(placePool.actions.create({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
