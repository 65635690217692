import React from 'react';
import Form from "./Form";
import * as zukoZone from "../../../state/entities/zukoZone";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Breadcrumbs from "../../common/breadcrumbs/Breadcrumbs";

const Create = ({createPending, createZukoZone, venueId}) => {

    const onSubmit = (zukoZone, {setErrors}) => {
        createZukoZone(zukoZone, setErrors);
    };


    return <>
        <Row className="my-4">
            <Breadcrumbs entity={["Spielstätte", "Zuko-Zonen"]} endpoints = {['venue/breadcrumb', `venue/${venueId}/zuko_zone/breadcrumb`]} title = "Zuko-Zonen" links={['/base/venue/', `/base/venue/${venueId}/venue-plan/`]} active={[venueId]} />
        </Row>
        <Row className="mb-4 mt-4">
            <Col className="pt-3 col-sm-8">
                <h1>Erstellen einer neuen Zuko-Zone</h1>
            </Col>
        </Row>
        <Form zukoZone={{}} onSubmit={onSubmit} venueId={venueId} submitPending={createPending}/>
    </>;

}

const mapStateToProps = (state, props) => {
    const venueId = props.match.params.id;
    return {
        venueId,
        createPending: state.entities.zukoZone.createPending
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createZukoZone: entity => dispatch(zukoZone.actions.create({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
