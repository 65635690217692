import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from "prop-types";
import FormPlacePools from "./FormPlacePools";
import { FormikTextInputGroup } from "../common/formik/FormikTextInputGroup";
import FormikCheckbox from "../common/formik/FormikCheckbox";
import FeedbackButton from '../common/FeedbackButton';
import HelpContent from '../common/help/HelpContent';
import { isEmpty } from 'lodash';
import styles from "../form.module.scss";
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

function FormStandingBlocks ({
    formik,
    pricingCategories,
    placePools,
    loadStandingBlocksByVenueEventAndVenuePlan,
    standingBlocks
}) {
    const [checkboxStates, setCheckboxStates] = useState(Array.from({length: formik.values.standingBlocks.length}, () => false));

    useEffect(() => {
        if (!formik.values.standingBlocks || !Array.isArray(formik.values.standingBlocks)) {
            return;
        }

        setCheckboxStates(formik.values.standingBlocks.map(block => block.overwriteCapacity || false));

        if (!standingBlocks || !Array.isArray(standingBlocks.venueEventStandingBlocks)) {
            return;
        }

        const fomikBlock = formik.values.standingBlocks[0]?.block;
        const standingBlock = standingBlocks.venueEventStandingBlocks[0]?.block;
        
        if (fomikBlock !== standingBlock) {
            formik.setFieldValue('standingBlocks', standingBlocks.venueEventStandingBlocks);
        }
    }, [formik.values.standingBlocks, standingBlocks]);

    useEffect(() => {
        if (formik.values.venuePlan) {
            loadStandingBlocksByVenueEventAndVenuePlan(formik.values.id, formik.values.venuePlan);
        }
    }, [formik.values.id, formik.values.venuePlan]);

    const handleCheckboxChange = (_, index, isChecked) => {
        setCheckboxStates(prevStates => {
            const newCheckboxStates = [...prevStates];
            newCheckboxStates[index] = isChecked;
            return newCheckboxStates;
        });

        formik.setFieldValue(`standingBlocks[${index}].overwriteCapacity`, isChecked);
    };

    const getBlockInfo = (formBlock, infoKey) => {
        if (!standingBlocks || !Array.isArray(standingBlocks.venueEventStandingBlocks)) {
            return 0;
        }

        const block = standingBlocks.venueEventStandingBlocks.find(item => item.block === formBlock.block);

        if (block && block[infoKey]) {
            return infoKey === 'label' ? block[infoKey].de : block[infoKey];
        }

        return infoKey === 'label' ? '' : 0;
    };

    const findPricingCategoryName = (id) => {
        if (!pricingCategories || !Array.isArray(pricingCategories)) {
            return '';
        }

        const pricingCategory = pricingCategories.find(item => {
            return item.id === id;
        });

        return pricingCategory ? pricingCategory.name : '';
    }

    if (isEmpty(formik.values.standingBlocks)) {
        return (
            <div className={styles.formBox}>
                <Row>
                    <Col className="col-md-4">
                        <p>Es gibt keine Blöcke im Veranstaltungsortplan</p>
                    </Col>
                </Row>
            </div>
        )
    }
    
    return (
        <>
            {formik.values.standingBlocks.map((element, blockIndex) => (
                <div className={styles.formBox} key={blockIndex}>
                    <div className={styles.flexBox}>
                        <h2 className={styles.formTitle}>Stehplatzblock {getBlockInfo(element, 'label')} {element.pricingCategory && `(${findPricingCategoryName(element.pricingCategory)})`}</h2>
                        <FeedbackButton
                            className="list-link"
                            title="Saalplan bearbeiten"
                            to={`/base/venue-plan/${formik.values.venuePlan}/block/standing/${element.block}`}
                            icon={<EditNoteOutlinedIcon className='table-icon' />}
                        />
                    </div>
                    <Row>
                        <Col className="col-md-4">
                            <FormikCheckbox
                                label="Blockgrößen(Kapazität)-Änderungen immer übernehmen"
                                name={'standingBlocks[' + blockIndex + '].overwriteCapacity'}
                                checked={checkboxStates[blockIndex]}
                                onChange={(e, isChecked) => handleCheckboxChange(e, blockIndex, isChecked)}
                            />
                            <HelpContent as="p" className="text-muted">
                                Änderungen an dem verwendeten Saalplan immer direkt übernehmen und die lokalen Werte an
                                diesem Event überschreiben? <br/>
                                Verkaufte Tickets/Plätze sind hiervon nicht betroffen.
                            </HelpContent>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-md-4">
                            <FormikTextInputGroup
                                type="number"
                                min="0"
                                label="Anzahl Stehplätze"
                                name={'standingBlocks[' + blockIndex + '].capacity'}
                                disabled={!checkboxStates[blockIndex]}
                                validate={(capacity) =>  capacity < 0 ? "Die Kapazität sollte größer oder gleich 0 sein" : undefined}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-md-4">
                            Gebuchte Plätze: {getBlockInfo(element, 'bookedPlaces')}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-md-4">
                            Kapazität im Saalplan: {getBlockInfo(element, 'venuePlanCapacity')}
                        </Col>
                    </Row>
                    {(
                        <Row>
                            <Col className="col-md-4">
                                <FormPlacePools
                                    formik={formik}
                                    blockIndex={blockIndex}
                                    placePools={placePools}
                                    standingBlocks={standingBlocks}
                                />
                            </Col>
                        </Row>
                    )}
                </div>
            ))}
        </>
    )
}

FormStandingBlocks.propTypes = {
    formik: PropTypes.object,
    standingBlocks: PropTypes.object,
    pricingCategories: PropTypes.array,
    placePools: PropTypes.array,
    loadStandingBlocksByVenueEventAndVenuePlan: PropTypes.func,
};

export default FormStandingBlocks;
