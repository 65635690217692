import * as types from './types';

export const queryBreadcrumbs = (endpoint, query) => {
    return {type: types.QUERY_BREADCRUMBS, payload: {endpoint, query}};
};
export const queryBreadcrumbsSuccess = (endpoint, query, result) => {
    return {type: types.QUERY_BREADCRUMBS_SUCCESS, payload: {endpoint, query, result}};
};
export const queryBreadcrumbsError = (endpoint, query) => {
    return {type: types.QUERY_BREADCRUMBS_ERROR, payload: {endpoint, query}};
};
