import {EventSystem, Renderer, Sprite} from 'pixi.js';
import {Plugin, Viewport} from 'pixi-viewport';
import {Point} from '../geometry';

const INITIAL_ZOOM_FACTOR = 1;

export const MOUSE_WHEEL_ZOOM_EVENT = 'lm.wheel-zoom';

export interface SeatDisplayData {
    id: string
    position: Point
    sprite: Sprite
}

export const setupRenderer = (canvas: HTMLCanvasElement): Renderer => {
    return new Renderer({
        antialias: false,
        view: canvas,
        // TODO: restore auto-resize
        // resizeTo: canvas,
        width: canvas.offsetWidth,
        height: canvas.offsetHeight,
        resolution: window.devicePixelRatio || 1,
        powerPreference: 'high-performance',
        background: 0xF0F0F0,
        backgroundAlpha: 1,
        hello: true
    });
}

export const setupViewport = (screenWidth: number, screenHeight: number, events:EventSystem): Viewport => {
    const viewport = new Viewport({
        disableOnContextMenu: true,
        screenWidth,
        screenHeight,
        events
    });

    // activate plugins
    viewport.drag({wheel: false, mouseButtons: 'middle-right'}).pinch().wheel();

    // Um in der Lage zu sein mouse-wheel events abzugreifen unabhängig vom "Mausrad-Zoom" des pixijs-viewport,
    // installieren wir ein eigenes plugin, denn dass "wheel" Plugin vom viewport sendet keine "wheel" Events
    // mehr, wenn es per pause() deaktiviert wird.
    viewport.plugins.add(MOUSE_WHEEL_ZOOM_EVENT, new MouseWheelZoomPlugin(viewport));

    viewport.setZoom(INITIAL_ZOOM_FACTOR);
    viewport.moveCenter(0, 0);

    return viewport;
}

class MouseWheelZoomPlugin extends Plugin {

    wheel(event: WheelEvent): boolean | undefined {
        this.parent.emit(MOUSE_WHEEL_ZOOM_EVENT, {
            direction: event.deltaY < 0 ? 'IN' : 'OUT'
        });

        return;
    }

}
