import React from 'react';
import {Col} from 'react-bootstrap';
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import {BATCH_ACTION_MOVE_TO_ACTIVE} from "../common/listView/ListViewBatchActions";
import List from "./List";
import RefreshIcon from '@mui/icons-material/Refresh';
import FeedbackButton from '../common/FeedbackButton';

const LinkToActive = () => {
    return (
        <FeedbackButton
            to={`/event-management/purchasable-item-template`}
            icon={<RefreshIcon />}
            variant='contained'
            className='listLinkCenter'
        >
            Aktuell
        </FeedbackButton>
    );
}

const Breadcrumb = () => {
    return (
        <Breadcrumbs entity={["Archivierte Leistungsvorlagen"]}
                     endpoints={['archived_purchasable_item_template/breadcrumb']} title="Archived Leistungsvorlagen" links={['/event-management/purchasable-item-template/archived']} active={[0]}/>
    )
}

const ArchivedPurchasableItemTemplateList = () => {
    return (
        <List
            linkNearHeader={LinkToActive}
            breadcrumb={Breadcrumb}
            heading="Archivierte Leistungsvorlagen"
            listViewEndpoint="listingView/archivedPurchasableItemTemplate"
            allowedBatchActions={[BATCH_ACTION_MOVE_TO_ACTIVE]}
        />
    );
};

export default ArchivedPurchasableItemTemplateList;
