import React, {useCallback, useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import FormikCheckbox from "../common/formik/FormikCheckbox";
import {getIn} from "formik";
import {LoadingIndicator} from "../common/LoadingIndicator";
import Api from "../../api";

const api = new Api();

function FormPayonePaymentProducts({formik}) {
    const [loading, setLoading] = useState(false);
    const [payoneError, setPayoneError] = useState('');
    const [paymentProducts, setPaymentProducts] = useState([]);

    const payoneSettingsInit = {
        environment: 'test',
        merchantId: '',
        apiKey: '',
        apiSecret: '',
        webhookKey: '',
        webhookSecret: '',
        paymentProducts: [],
    };

    const arePayoneSettingsValid = (payoneSettings) => {
        return !(
            !payoneSettings
            || !payoneSettings.environment
            || !payoneSettings.merchantId
            || !payoneSettings.apiKey
            || !payoneSettings.apiSecret
            || !payoneSettings.webhookKey
            || !payoneSettings.webhookSecret
        );
    };

    const provider = formik.values.paymentSettings?.provider;
    const payoneSettings = formik.values.paymentSettings?.payone ?? payoneSettingsInit;

    useEffect(() => {
        const settings = {
            environment: payoneSettings.environment,
            merchantId: payoneSettings.merchantId,
            apiKey: payoneSettings.apiKey,
            apiSecret: payoneSettings.apiSecret,
            webhookKey: payoneSettings.webhookKey,
            webhookSecret: payoneSettings.webhookSecret,
        }

        if (provider !== 'payone' || !arePayoneSettingsValid(settings)) {
            return;
        }

        setPayoneError('');

        const delayInputTimeoutId = setTimeout(() => {
            setLoading(true);
            api.getPayonePaymentProducts(settings).then(result => {
                setPaymentProducts(result);
                setLoading(false);
            }).catch(errResponse => {
                if (errResponse.hasOwnProperty('message')) {
                    setPayoneError(errResponse.message);
                }
                setLoading(false);
                setPaymentProducts([]);
            });
        }, 500);

        return () => clearTimeout(delayInputTimeoutId);
    }, [provider, payoneSettings.environment, payoneSettings.merchantId,
        payoneSettings.apiKey, payoneSettings.apiSecret, payoneSettings.webhookKey, payoneSettings.webhookSecret]);

    useEffect(() => {
        if (provider !== 'payone') {
            return;
        }

        if (formik.values.paymentSettings?.payone === null) {
            formik.setFieldValue('paymentSettings.payone', {...payoneSettingsInit}
            );
        }
    }, [provider, formik]);

    useEffect(() => {
        if (formik.values.paymentSettings?.provider !== 'payone') {
            return;
        }

        if (!loading && !formik.values.paymentSettings?.payone) {
            formik.setFieldValue('paymentSettings.payone', {...payoneSettingsInit}
            );
        }
    }, [loading, formik]);

    const paymentProductsId = 'paymentSettings.payone.paymentProducts';

    const isMethodChecked = useCallback((method) => {
        if (!formik.values.paymentSettings?.payone?.paymentProducts) {
            return false;
        }
        return formik.values.paymentSettings.payone.paymentProducts.includes(method);
    }, [formik]);

    const PaymentProducts = useCallback(() => {
        if (!(!!getIn(formik.touched, paymentProductsId))) {
            return <></>;
        }

        const errorText = getIn(formik.errors, paymentProductsId);

        if (errorText) {
            return <span className={'invalid-checkbox'}>
                {errorText}
            </span>;
        }
        return <></>;
    }, [formik]);

    const handleCheckboxChange = useCallback((event) => {
        const value = Number(event.target.value);
        const paymentProducts = formik.values.paymentSettings?.payone?.paymentProducts ?? [];
        const alreadySelected = paymentProducts.includes(value);

        formik.setFieldTouched(paymentProductsId, true);

        if (alreadySelected) {
            formik.setFieldValue(
                paymentProductsId,
                paymentProducts.filter(method => method !== value)
            );
        } else {
            formik.setFieldValue(
                paymentProductsId,
                [...paymentProducts, value]
            );
        }
    }, [formik]);

    if (loading) {
        return <LoadingIndicator/>;
    }

    if (!arePayoneSettingsValid(formik.values.paymentSettings?.payone)) {
        return <span className={'invalid-checkbox'}>
                Bitte füllen Sie alle Payone Felder aus, um die verfügbaren Zahlarten zu laden.
            </span>;
    }

    if (payoneError) {
        return <span className={'invalid-checkbox'}>{payoneError}</span>;
    }

    return (
        <>
            {paymentProducts.map(product => (
                <Row className="mt-1" key={product.id}>
                    <Col className="col-md-12 px-4">
                        <FormikCheckbox
                            label={product.label}
                            icon={product.logo}
                            type="checkbox"
                            name={`payone-${product.id}`}
                            value={product.id}
                            checked={isMethodChecked(product.id)}
                            onChange={handleCheckboxChange}
                        />
                    </Col>
                </Row>
            ))}
            <PaymentProducts/>
        </>
    )
}

FormPayonePaymentProducts.propTypes = {
    formik: PropTypes.object,
};

export default FormPayonePaymentProducts;
