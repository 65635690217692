import React from 'react';
import {Dropdown} from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import {sortBy} from 'lodash';
import DropdownItemSeatException from "./DropdownItemSeatException";
import AddIcon from '@mui/icons-material/Add';
/**
 * Dropdown Item
 */
const DropdownItem = ({option, entity, index, crumbs, handleFilterChange}) => {
    const getSearchElements = () => {
        return (
            <div className="disable dropdown-item disabled">
                <Form>
                    <Form.Group className="breadcrumb-input-item">
                        <Form.Label className="breadcrumb-input-label">Suche</Form.Label>
                        <Form.Control type="text" className="breadcrumb-input"
                                      onChange={event => handleFilterChange(event, 'label', index)}/>
                    </Form.Group>
                </Form>
            </div>
        )
    }

    const getSearchItem = () => {
            if (option.link.includes('seat/')) {
                return(
                    <DropdownItemSeatException handleFilterChange={handleFilterChange} index={index} />
                    )
            } else {
                return getSearchElements()
            }
    }

    const getSortedItems = () => {
        return sortBy(option.items, ['name'])
    }

    const generateLink = (op, i) => {
        // if the item is a block item, then the link should contain the blockType
        if (option.link.includes('block/') && !option.link.includes('seat/')) {
            if ("capacity" in crumbs[index].items[i]) {
                return option.link + "standing/" + op.id;
            } else {
                return option.link + "seating/" + op.id;
            }
        } else if(option.link.includes('pricing-list/')) {
            return option.link + op.id + "/edit";
        } else {
            return option.link + op.id;
        }
    }

    return (
        <>
            {/*searchfields*/}
            {getSearchItem()}
            {/*add item*/}
            {/*if it's the block breadcrumb there should be two adding links for standing and seating blocks, otherwise its only one*/}
            {option.link.includes('block/') && !option.link.includes('seat/') ?
                <div key={option.link}>
                    <Dropdown.Item href={option.link + "standing/create"} className="breadcrumb-button">Stehplatzblock neu anlegen <AddIcon /></Dropdown.Item>
                    <Dropdown.Item href={option.link + "seating/create"} className="breadcrumb-button">Sitzplatzblock neu anlegen <AddIcon /></Dropdown.Item>
                </div>
                :
                <div key={option.link}>
                    <Dropdown.Item href={option.link + "create"} className="breadcrumb-button">{entity[index]} neu anlegen <AddIcon /></Dropdown.Item>
                </div>
            }
            {/*items*/}
            {getSortedItems().map((op, i) =>
                <div key={i}>
                    <Dropdown.Item className="breadcrumb-dropdown-item" href={generateLink(op, i)}>{op.name}</Dropdown.Item>
                </div>
            )}

        </>
    );
};


export default DropdownItem;
