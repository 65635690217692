import React from "react";
import { OutlinedInput } from '@mui/material';
import { NumericFormat } from 'react-number-format';

const CurrencyInput = ({
    value,
    onChange,
    onBlur,
    endAdornment,
    onFocus,
    className
}) => {
    const handleChange = (values) => {
      const centsValue = Math.round(values.floatValue * 100);
      onChange(centsValue);
    };

  return (
    <NumericFormat
        customInput={OutlinedInput}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        fixedDecimalScale={true}
        suffix="&nbsp;€"
        value={value / 100}
        onValueChange={(values) => handleChange(values)}
        onBlur={onBlur}
        onFocus={onFocus}
        className={className}
        endAdornment={endAdornment}
    />
  );
};

export default CurrencyInput;