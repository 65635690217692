import {createSlice} from 'redux-starter-kit';

const currentTenant = createSlice({
    slice: 'tenant',
    initialState: {
        selectedTenant: {},
        currentTenant: {},
    },
    reducers: {
        setCurrentTenant: (state, {payload}) =>  {
            state.currentTenant = {...payload};
        },
        setSelectedTenant: (state, {payload}) =>  {
            state.selectedTenant = {...payload};
        },
    }
});

export const actions = {
    ...currentTenant.actions
};

export const reducer = currentTenant.reducer;