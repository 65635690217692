import React, {Component} from 'react';
import {Container, Row} from 'react-bootstrap';
import ConnectedListView from '../common/listView/ConnectedListView';
import {connect} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import {get} from 'lodash';
import * as eventCategory from "../../state/entities/eventCategory";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import FeedbackButton from "../common/FeedbackButton";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDeleteConfirmDialog: false,
            eventCategoryToDelete: undefined
        }
    }

    showDeleteConfirmDialog(eventCategory) {
        this.setState({
            showDeleteConfirmDialog: true,
            eventCategoryToDelete: eventCategory,
        });
    }

    handleCancelDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
    };

    handleConfirmDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
        this.props.deleteeventCategory(this.state.eventCategoryToDelete);
    };

    render() {
        return (
            <Container>
                <Row className="my-4">
                    <Breadcrumbs entity={["EventCategory"]} endpoints = {['event_category/breadcrumb']} title = "Eventarten" links={['/base/event-category/']} active={[0]} />
                </Row>
                <ConnectedListView
                    heading="Eventarten"
                    endpoint="listingView/eventCategory"
                    buttonTitle="Neue Eventart"
                    buttonLink="/base/event-category/create"
                    testid="link_new_eventCategory"
                >
                    {({fields, item}) => (
                        <>
                            <FeedbackButton
                                className="list-link"
                                title="Eventarten bearbeiten"
                                to={`/base/event-category/${item.id}`}
                                icon={<EditNoteOutlinedIcon className='table-icon' />}
                            />
                            <FeedbackButton
                                className="list-link"
                                title="Eventarten löschen"
                                onClick={() => this.showDeleteConfirmDialog(item)}
                                icon={<DeleteOutlineOutlinedIcon className='table-icon' />}
                            />
                        </>
                    )}
                </ConnectedListView>


                <Modal show={this.state.showDeleteConfirmDialog} onHide={this.handleCancelDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Eventarten löschen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Möchten Sie die Eventart "{get(this.state, 'eventCategoryToDelete.nameDe')}" wirklich löschen?</Modal.Body>
                    <Modal.Footer>
                        <FeedbackButton variant="outlined" onClick={this.handleCancelDelete}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton onClick={this.handleConfirmDelete}>
                            Löschen
                        </FeedbackButton>
                    </Modal.Footer>
                </Modal>

            </Container>
        );
    }
}

export default connect(null, {
    deleteeventCategory: entity => eventCategory.actions.delete({entity})
})(List);
