import {call, put} from 'redux-saga/effects';

/**
 * Generische Saga für das Löschen von Entitäten.
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param onSuccess
 */
export default ({actions, resource, onSuccess}) => function* ({payload: {entity}}) {
    try {
        yield call(resource.delete, entity.id);

        yield put(actions.deleteSuccess({entity, globalMessagesList: {
                id: Date.now(),
                variant: 'success',
                isCreate: false,
                message: 'Die Vorlage wurde erfolgreich gelöscht'
            }}))

        if (onSuccess) {
            yield onSuccess(entity);
        }
    } catch (error) {
        yield put(actions.deleteError({entity, error}))
    }
}
