import React, { useState, useRef } from 'react';
import { Box, Divider, IconButton, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import NearMeIcon from '@mui/icons-material/NearMe';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import { Tab, Tabs } from '@mui/material';
import TabsPanel from '../../components/common/TabsPanel/TabsPanel';
import styles from './toolBar.module.scss';
import { InteractionMode } from '../editor/display/interaction';
import { useDispatch, useSelector } from 'react-redux';
import {actions as venueEditorActions} from '../../state/entities/venueEditor';
import { IState, selectInteractionMode, selectMoveOnGrid, selectRedoHistory, selectSelectedAreaForm, selectSelectedSeatIds, selectUndoHistory } from '../../state/entities/venueEditor/selectors';
import { AREAFORMS_ENABLED, IAreaFormsManager } from '../editor/display/areaForms/areaFormsManager';
import { IImagesManager } from '../editor/display/images/imagesManager';
import ListingModal from "../../components/common/modal/ListingModal";
import { ImageData } from "../editor/display/images/ImageData";
import FieldImage, { FieldValuesProps } from '../../components/common/FieldImage/FieldImage';
import { isEmpty } from 'lodash';
import ConfirmModal from '../../components/common/modal/ConfirmModal';
import useUndoManager from '../editor/UndoManager';


enum TabState {
    SEATS = 'seats',
    STANDINGBLOCKS = 'standingBlocks',
    IMAGES = 'images',
    NONE = ''
}


const subToolbarStyles = {
    display: 'flex',
    alignItems: 'flex-start',
    width: 'fit-content',
    height: 'auto',
    borderRadius: 1,
    boxShadow: 3,
    bgcolor: 'background.paper',
};

const iconButtonStyles = {
    width: 32,
    height: 32,
    '& svg': {fontSize: 18, color: '#1D1D1F'},
    '&.Mui-disabled': {
        '& svg': {color: 'action.disabled'}
    }
};


interface IToolbarUtilsProps {
    venuePlanId: string;
    areaFormsManager: IAreaFormsManager;
    imagesManager: IImagesManager;
    onSeatsDelete: () => void;
    onEditAreaForm: () => void;
}


export const ToolbarUtils: React.FC<IToolbarUtilsProps> = ({
    venuePlanId,
    areaFormsManager,
    imagesManager,
    onSeatsDelete,
    onEditAreaForm,
}) => {
    const dispatch = useDispatch();
    const interactionMode = useSelector((state: IState) => selectInteractionMode(state));
    const moveOnGrid = useSelector((state: IState) => selectMoveOnGrid(state));
    const [hoveredTab, setHoveredTab] = useState(TabState.NONE);
    const selectedSeatIds = useSelector((state: IState) => selectSelectedSeatIds(state));
    const undoHistory = useSelector((state: IState) => selectUndoHistory(state));
    const redoHistory = useSelector((state: IState) => selectRedoHistory(state));
    const tabPanelRef = useRef(null);
    const [isShowBatchModal, setIsShowBatchModal] = useState(false);
    const [activeFieldName, setActiveFieldName] = useState('');
    const [imageFields, setImageFields] = useState([]);
    const selectedAreaForm = useSelector((state: IState) => selectSelectedAreaForm(state));
    const {handleUndo, handleRedo} = useUndoManager();


    const handleTabHover = (event: React.MouseEvent<HTMLButtonElement>, tabValue: TabState) => {
        const target = event.target as HTMLButtonElement;
        if (target.classList.contains('Mui-selected')) {
            handleTabLeave();  //for moving from hovered button to currently active button
            return;  
        }
        setHoveredTab(tabValue);
    };

    const handleTabLeave = () => {
        setHoveredTab(TabState.NONE);
    };

    const getActiveTab = (): TabState => {
        if ([InteractionMode.SELECT, InteractionMode.ADD].includes(interactionMode)) return TabState.SEATS;
        if ([InteractionMode.AREAFORMS, InteractionMode.ADD_AREAFORM].includes(interactionMode)) return TabState.STANDINGBLOCKS;
        if ([InteractionMode.IMAGES].includes(interactionMode)) return TabState.IMAGES;
    }


    const handleChange = (event: React.MouseEvent<HTMLElement>, newInteractionMode: InteractionMode) => {
        if (newInteractionMode === null) return;
        dispatch(venueEditorActions.setInteractionMode(newInteractionMode))
        return;
    }

    const handleMainTabClick = (event, value: string) => {
        const defaultTabModes = {
            [TabState.SEATS]: InteractionMode.SELECT,
            [TabState.STANDINGBLOCKS]: InteractionMode.AREAFORMS,
            [TabState.IMAGES]: InteractionMode.IMAGES
        };
        dispatch(venueEditorActions.setInteractionMode(defaultTabModes[value]));
    }

    const handleMoveOnGrid = () => {
        dispatch(venueEditorActions.setMoveOnGrid(!moveOnGrid));
    }

    const onHandleShowModal = (e: React.MouseEvent<HTMLButtonElement>, fieldName: string) => {
        e.stopPropagation();
        setIsShowBatchModal(true);
        setActiveFieldName(fieldName);
    }

    const onHandleRemove = (e: React.MouseEvent<HTMLButtonElement>, fieldName: string) => {
        e.stopPropagation();
        setImageFields(prevState => prevState.filter(item => item.id !== fieldName));
    }

    const handleAddImageClick = () => {
        dispatch(venueEditorActions.setInteractionMode(InteractionMode.IMAGES));
        setIsShowBatchModal(true);
    }
    
    const onHandleChooseImage = (item: FieldValuesProps) => {
        setIsShowBatchModal(false);
        imagesManager.prebuildNewImage(item.id, item.title, item.url).then((image: ImageData) => {
            dispatch(venueEditorActions.addImage({venuePlanId: venuePlanId, newImage: image}));
        });

        setActiveFieldName(item.id)
    }

    const deleteSeatsDisabled = getActiveTab() !== TabState.SEATS || !Object.values(selectedSeatIds).length

    return (
        <>
            <div className={styles.toolBarUtilsContainer} onMouseLeave={handleTabLeave}>
                <Tabs
                    value={getActiveTab()}
                    onChange={handleMainTabClick}
                    className={styles.tabsToolbar}
                >
                    <Tab
                        component="button"
                        value="seats"
                        label="Sitzplätze"
                        onMouseEnter={(e) => handleTabHover(e, TabState.SEATS)} 
                        disableRipple
                    />
                    <Tab
                        component="button"
                        value="standingBlocks"
                        label="Stehplatzblöcke"
                        onMouseEnter={(e) => handleTabHover(e, TabState.STANDINGBLOCKS)}
                        disabled={!AREAFORMS_ENABLED}
                        disableRipple
                    />
                    <Tab
                        component="button"
                        value="images"
                        label="Grafiken"
                        onMouseEnter={(e) => handleTabHover(e, TabState.IMAGES)}
                        disableRipple
                    />
                </Tabs>
                <TabsPanel
                    value={getActiveTab()}
                    index={TabState.SEATS}
                    isHovered={hoveredTab === 'seats'}
                    tabPanelRef={tabPanelRef}
                    className={styles.tabsPanel}
                    style={{top: '10px'}}
                >    
                    <Box sx={subToolbarStyles}>
                        <ToggleButtonGroup
                            color="primary"
                            value={interactionMode}
                            onChange={(e, newIntercationMode) => handleChange(e, newIntercationMode)}
                            className={styles.toolBarUtilsBtnGroup}
                            exclusive
                            sx={{boxShadow: 0}}
                        >
                            <ToggleButton value={InteractionMode.SELECT}>
                                {/*no support for outside Tooltip on ToggleButton w/ correct styling */}
                                <Tooltip title="Sitze Auswählen">
                                    <NearMeIcon />
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton value={InteractionMode.ADD}>
                                <Tooltip title="Sitze hinzufügen">
                                    <AddIcon />
                                </Tooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <Tooltip title="Selektierte Sitze löschen">
                            <span>
                                <ConfirmModal title="Sitzplätze löschen"
                                        body={
                                            <>
                                                Die ausgewählten Sitzplätze wirklich löschen?<br />
                                                Dies kann nicht rückgängig gemacht werden.
                                            </>
                                        }
                                        cancelLabel="Abbrechen"
                                        confirmLabel="Löschen"
                                >
                                    {confirm =>
                                        <IconButton
                                            value="deleteSeats"
                                            disabled={deleteSeatsDisabled}
                                            sx={iconButtonStyles}
                                            onClick={() => Object.values(selectedSeatIds).length
                                                && confirm(() => onSeatsDelete())}
                                        >
                                            <DeleteOutlineOutlinedIcon />
                                        </IconButton>
                                    }
                                </ConfirmModal>
                            </span>
                        </Tooltip>
                        <Tooltip title="Rückgängig">
                            <span>
                                <IconButton
                                    value="undo"
                                    disabled={!undoHistory.length}
                                    sx={iconButtonStyles}
                                    onClick={handleUndo}
                                >
                                    <UndoIcon/>
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Wiederherstellen">
                            <span>
                                <IconButton
                                    value="redo"
                                    disabled={!redoHistory.length}
                                    sx={iconButtonStyles}
                                    onClick={handleRedo}
                                >
                                    <RedoIcon/>
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Divider orientation="vertical" flexItem />
                        <Tooltip title="Am Raster bewegen">
                            <span>
                                <ToggleButton
                                    value="MOVE_ON_GRID"
                                    selected={moveOnGrid}
                                    className={styles.toolBarUtilsBtnGroup__button}
                                    onClick={handleMoveOnGrid}
                                    disabled={getActiveTab() !== TabState.SEATS}
                                    sx={{...iconButtonStyles, ...(moveOnGrid && {
                                        bgcolor: '#9FD8F9'
                                    })}}
                                >
                                    <Grid4x4Icon/>
                                </ToggleButton>
                            </span>
                        </Tooltip>
                    </Box>
                </TabsPanel>
                <TabsPanel
                    value={getActiveTab()}
                    index={TabState.STANDINGBLOCKS}
                    isHovered={hoveredTab === 'standingBlocks'}
                    tabPanelRef={tabPanelRef}
                    className={styles.tabsPanel}
                    style={{top: '50px'}}
                >
                    <Box sx={subToolbarStyles}>
                        <ToggleButtonGroup
                            color="primary"
                            value={interactionMode}
                            onChange={(e, newAlignment) => handleChange(e, newAlignment)}
                            className={styles.toolBarUtilsBtnGroup}
                            exclusive
                            sx={{boxShadow: 0}}
                        >
                            <ToggleButton value={InteractionMode.AREAFORMS}>
                                <Tooltip title="Stehplatzblock auswählen">
                                    <NearMeIcon />
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton
                                value={InteractionMode.ADD_AREAFORM}
                            >
                                <Tooltip title="Stehplatzblock hinzufügen">
                                    <AddIcon />
                                </Tooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <Tooltip title="Stehplatzblock editieren">
                            <span>
                                <IconButton
                                    value="edit"
                                    onClick={onEditAreaForm}
                                    disabled={interactionMode !== InteractionMode.AREAFORMS || !selectedAreaForm}
                                    sx={iconButtonStyles}
                                >
                                    <EditOutlinedIcon/>
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Selektierten Stehplatzblock löschen">
                            <span>
                                <ConfirmModal title="Stehplatz-Block löschen"
                                    body="Den ausgewählten Block wirklich löschen?"
                                    cancelLabel="Abbrechen"
                                    confirmLabel="Löschen"
                            >
                                {confirm =>
                                    <IconButton
                                        value="deleteAF"
                                        onClick={() => selectedAreaForm && confirm(() => areaFormsManager.deleteSelectedItem())}
                                        disabled={getActiveTab() !== TabState.STANDINGBLOCKS || !selectedAreaForm}
                                        sx={iconButtonStyles}
                                    >
                                        <DeleteOutlineOutlinedIcon/>
                                    </IconButton>
                                }
                            </ConfirmModal>
                            </span>
                        </Tooltip>
                    </Box>
                </TabsPanel>
                <TabsPanel
                    value={getActiveTab()}
                    index={TabState.IMAGES}
                    isHovered={hoveredTab === 'images'}
                    tabPanelRef={tabPanelRef}
                    className={styles.tabsPanel}
                    style={{top: '82px'}}
                >
                    <Box sx={subToolbarStyles}>
                        <ToggleButtonGroup
                            color="primary"
                            value={interactionMode}
                            onChange={(e, newAlignment) => handleChange(e, newAlignment)}
                            className={styles.toolBarUtilsBtnGroup}
                            exclusive
                            sx={{boxShadow: 0}}
                        >
                            <ToggleButton value={InteractionMode.IMAGES}>
                                <Tooltip title="Bilder auswählen">
                                    <NearMeIcon />
                                </Tooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <Tooltip title="Bild hinzufügen">
                            <IconButton
                                value="add"
                                sx={iconButtonStyles}
                                onClick={handleAddImageClick}
                            >
                                <AddIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Selektiertes Bild löschen">
                            <span>
                                <ConfirmModal title="Bild löschen"
                                        body="Das ausgewählte Bild wirklich löschen?"
                                        cancelLabel="Abbrechen"
                                        confirmLabel="Löschen"
                                >
                                    {confirm =>
                                        <IconButton
                                            value="deleteImage"
                                            disabled={getActiveTab() !== TabState.IMAGES || !imagesManager.getSelectedItem()}
                                            sx={iconButtonStyles}
                                            onClick={() => imagesManager.getSelectedItem()
                                                && confirm(() => imagesManager.deleteSelectedItem())
                                            }
                                        >
                                            <DeleteOutlineOutlinedIcon/>
                                        </IconButton>
                                    }
                                </ConfirmModal>
                            </span>
                        </Tooltip>
                    </Box>
                </TabsPanel>
                {!isEmpty(imageFields) && imageFields.map((item) => {
                    return (
                        <div key={item.id}>
                            <FieldImage
                                values={item}
                                fieldName={activeFieldName}
                                label={`Label Image ${item.title}`}
                                onHandleShowModal={(e) => onHandleShowModal(e, item.id)}
                                onHanldleRemove={(e) => onHandleRemove(e, item.id)}
                            />
                        </div>
                    );
                })}
            </div>
             <ListingModal
                show={isShowBatchModal}
                title="Bild wählen"
                onCancel={() => setIsShowBatchModal(false)}
                onHandleChooseImage={onHandleChooseImage}
            />
        </>
    )
}

export default ToolbarUtils;