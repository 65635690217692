import { history } from '../configureStore';
import entitySlice from './entity/slice';
import entitySaga from './entity/saga';
import Api from '../../api';
import {eventSeries as schema} from '../schema';

const api = new Api();
const onCreateSuccess = entity => {
    history.push(`/event-management/event-series/${entity.id}`);
};
const onCopySuccess = onCreateSuccess;

const entityName = 'eventSeries';
const slice = entitySlice(entityName);
const resource = api.createResource(entityName);

export const actions = slice.actions;

export const reducer = slice.reducer;

export const saga = entitySaga({
    actions: slice.actions,
    resource,
    schema,
    onCreateSuccess,
    onCopySuccess,
});


