import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {FormikTextInputGroup} from '../../../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../../../common/FeedbackButton';
import FormikAsyncTypeaheadInput from "../../../common/formik/FormikAsyncTypeaheadInput";
import Api from "../../../../api";
import {connect} from "react-redux";
import AddIcon from '@mui/icons-material/Add';

const api = new Api();


const FormPlacePoolsTypeAhead = ({subformik, validateCapacityPlacePool, blockType, helpTextsVisible}) => {

    return (
        <>
            <p>Platzpools der Plätze</p>

            <p className={!helpTextsVisible ? "hidden" : "text-muted"}>Hier können mehrere Platzpools hinzugefügt werden, geben Sie für die Suche die Platzpools in das Feld ein.</p>
            <Row>
                <Col className="col-4">
                    <FormikAsyncTypeaheadInput
                        id="placePool"
                        onSearch={query => api.getPlacePoolsByQuery(query)}
                        labelKey={o => o.name}
                        minLength={0}
                        testid="name_new_placePool"
                    />
                </Col>
                {blockType === "standing" && (
                    <Col className="col-5">
                        <FormikTextInputGroup type="number" min="0" label="Anzahl Plätze dieses Platzpools in diesem Block"
                                              name="capacityPlacePool" validate={validateCapacityPlacePool} testid="capacity_new_placePool"/>
                    </Col>
                )}
                <Col className="mt-1">
                    <FeedbackButton
                        className="pull-right"
                        icon={<AddIcon />}
                        onClick={() => subformik.submitForm()}
                    >
                        hinzufügen
                    </FeedbackButton>
                </Col>
            </Row>
        </>
    )

};

FormPlacePoolsTypeAhead.propTypes = {
    subformik: PropTypes.any,
    validateCapacityPlacePool: PropTypes.func
};

const mapStateToProps = (state, props) => {

    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps)(FormPlacePoolsTypeAhead);



