import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {FormikTextInputGroup} from '../common/formik/FormikTextInputGroup';
import FormikCheckbox from "../common/formik/FormikCheckbox";
import {getIn} from "formik";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import FeedbackButton from "../common/FeedbackButton";
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CheckIcon from '@mui/icons-material/Check';
import { isEmpty } from "lodash";
import classnames from 'classnames';

const MaxTicketsSalesRulesGroupForm = ({element, associationFormik, parentFormik}) => {
    const nameOfValueSelectedSalesRule = 'group_selected_sales_rule';
    const [isLoading, setLoading] = useState(false);
    const [groupSalesRules, setGroupSalesRules] = useState([]);
    const [availableSalesRules, setAvailableSalesRules] = useState([]);

    useEffect(() => {
        onSearchAvailableEventSalesRules();
    }, []);

    useEffect(() => {
        setGroupSalesRules(getIn(associationFormik.values, 'eventSalesRuleIds', []));
        onSearchAvailableEventSalesRules();
    }, [element, associationFormik.values, element.eventSalesRuleIds]);

    function onSearchAvailableEventSalesRules() {
        setLoading(true);
        let usedSalesRulesInOneArray = [];

        parentFormik.values.maxTicketsSalesRulesGroups.map((group) => {
            if (group.id !== element.id) {
                group.eventSalesRuleIds.map(
                    salesRuleId => usedSalesRulesInOneArray.push(salesRuleId)
                )
            }
        });

        if (typeof associationFormik.values.eventSalesRuleIds !== "undefined") {
            associationFormik.values.eventSalesRuleIds.map(
                salesRuleId => usedSalesRulesInOneArray.push(salesRuleId)
            )
        }

        usedSalesRulesInOneArray = usedSalesRulesInOneArray.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });

        // get sales rules from event that are not used on bundles
        setAvailableSalesRules(parentFormik.values.salesRules.filter(item => !usedSalesRulesInOneArray.includes(item.id)));
        setLoading(false);
    }

    const addSalesRule = () => {
        let selectedSalesRule = getIn(associationFormik.values, nameOfValueSelectedSalesRule, null);
        if (selectedSalesRule) {
            associationFormik.setFieldValue('eventSalesRuleIds',
                [...groupSalesRules,
                selectedSalesRule.id
                ]
            );

            associationFormik.setFieldValue(nameOfValueSelectedSalesRule, null);
        }
    }

    const removeSalesRuleHandle = (salesRuleId) => {
        let oldSalesRules = getIn(associationFormik.values, 'eventSalesRuleIds', []);

        associationFormik.setFieldValue('eventSalesRuleIds', [
            ...oldSalesRules.filter(s => s !== salesRuleId)
        ]);
    }

    const getSalesRuleNameByEventSalesRuleId = (eventSalesRuleId) => {
        if (typeof parentFormik.values.salesRules !== "undefined") {
            for (let salesRule of parentFormik.values.salesRules) {
                if (salesRule.id === eventSalesRuleId) {
                    return salesRule.salesRule.name;
                }
            }
        }
        return 'Sales Rule';
    }

    const renderMenuItemChildren = (option, props) => {
        const selectedOption = getIn(associationFormik.values, nameOfValueSelectedSalesRule);
        const selected = !isEmpty(selectedOption) ? props.labelKey(selectedOption) === props.labelKey(option) : false;

        return (
          <div className={classnames('MuiList-asyncTypeahead-option', { 'MuiList-asyncTypeahead-option--selected': selected })}>
            {selected && <CheckIcon className='MuiList-asyncTypeahead-icon' />}
            <span>{props.labelKey(option)}</span>
          </div>
        );
    };

    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        <FormikTextInputGroup label="Name Verkaufsregelgruppierung" name="name" type="text"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormikTextInputGroup label="Max. Tickets" name="maxTicketsPerUser" type="number"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row className="mb-3">
                            <Col className="salesRules">
                                {groupSalesRules.map((item, index) => {
                                    return <Row>
                                        <Col className="col-md-11">
                                            <h2>{getSalesRuleNameByEventSalesRuleId(item)}</h2>
                                        </Col>
                                        <Col className="col-md-1">
                                            <FeedbackButton
                                                className="pull-right mb-3 list-link"
                                                onClick={() => {
                                                    removeSalesRuleHandle(item)
                                                }}
                                            >
                                                <DeleteOutlineOutlinedIcon className='table-icon' />
                                            </FeedbackButton>
                                        </Col>
                                    </Row>
                                })}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col className="col-md-12">
                                <Form.Group controlId={nameOfValueSelectedSalesRule} data-testid={nameOfValueSelectedSalesRule} className='MuiFormControl-root-custom'>
                                    <label className='MuiFormLabel-root-custom'>Verkaufsregel</label>
                                    <AsyncTypeahead
                                        id={nameOfValueSelectedSalesRule}
                                        inputProps={{id: nameOfValueSelectedSalesRule, name: nameOfValueSelectedSalesRule, autoComplete: "on"}}
                                        placeholder="Select sales rule"
                                        options={availableSalesRules}
                                        renderMenuItemChildren={renderMenuItemChildren}
                                        onChange={(el) =>{associationFormik.setFieldValue(nameOfValueSelectedSalesRule, el[0] || null)}}
                                        onSearch={onSearchAvailableEventSalesRules}
                                        isLoading={isLoading}
                                        labelKey={o => o.salesRule.name}
                                        filterBy={() => true}
                                        selected={getIn(associationFormik.values, nameOfValueSelectedSalesRule) ? [getIn(associationFormik.values, nameOfValueSelectedSalesRule)] : []}
                                        minLength={0}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-md-6">
                        <FormikCheckbox label="Checkbox aktivieren" name="enabled"/>
                    </Col>
                    <Col className="col-md-6">
                        <FeedbackButton
                            className="pull-right mb-3 mt-3"
                            onClick={() => addSalesRule()}
                            icon={<AddIcon />}
                        >
                            Verkaufsregel hinzufügen
                        </FeedbackButton>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
};

MaxTicketsSalesRulesGroupForm.propTypes = {
    associationFormik: PropTypes.object,
    element: PropTypes.object,
    salesRulesFromEvent: PropTypes.array
};

export default MaxTicketsSalesRulesGroupForm;
