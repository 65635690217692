import {Seat, SeatColor, SeatId, SeatStyle} from '../../../types';
import {Point} from '../../geometry';
import {applySeatStyle, createSeatSprite, LABEL_BRIGHT_COLOR, LABEL_DEFAULT_COLOR, SEAT_SIZE} from '../scene';
import {TagDefinition} from '../../../../entities';
import { IPlacepool } from '../../../types/Placepool';
import { BitmapText } from 'pixi.js';
import { getLuminance } from '../../geometry/util';


/**
 * Minimal distance between two seats calculated on CSV import (seat size * 1,25)
 */
const SEAT_IMPORTED_MIN_DISTANCE = SEAT_SIZE * 1.25;

/**
 * Grid size for snapped moving seats.
 */
export const MOVE_ON_GRID_SIZE = SEAT_IMPORTED_MIN_DISTANCE / 2;

/**
 * Grid size for new created seats.
 */
export const SEAT_GRID_STEP_SIZE = SEAT_IMPORTED_MIN_DISTANCE;

export interface SeatSet extends Iterable<Seat> {

    contains(seat: Seat): boolean;

}

export class RenderableSeat implements Seat {

    private _position: Point = {x: 0, y: 0};

    private _color: SeatColor = 0x000000;

    private _style: SeatStyle = 'BLOCKED';

    readonly id: SeatId;

    readonly sprite = createSeatSprite(this._style, this._color);
    
    private _labelSprite: BitmapText = undefined;

    publicId: string;
    area?: string;
    row?: string;
    label?: string;
    enabled: boolean;
    available: boolean;
    seatingTypeId: string;
    pricingCategoryId: string;
    blockId: string;

    tags: string[];

    constructor(seat: Readonly<Seat>) {
        this.id = seat.id;
        this.tags = seat.tags ? [...seat.tags] : [];
        this.area = seat.area;
        this.row = seat.row;
        this.label = seat.label;
        this.enabled = seat.enabled;
        this.available = seat.available;
        this._color = seat.color;
        this._style = seat.style;
        this.position = seat;
        this.seatingTypeId = seat.seatingTypeId;
        this.pricingCategoryId = seat.pricingCategoryId;
        this.blockId = seat.blockId;
        this.redraw();
    }

    get x() {
        return this.position.x;
    }

    get y() {
        return this.position.y;
    }

    get position(): Readonly<Point> {
        return this._position;
    }

    get color(): SeatColor {
        return this._color;
    }

    set color(color: SeatColor) {
        this._color = color;
        this.redraw();
    }

    get style(): SeatStyle {
        return this._style;
    }

    set style(style: SeatStyle) {
        this._style = style;
        this.redraw();
        this.updateLabelColor();
    }

    set labelSprite(label: BitmapText) {
        this._labelSprite = label;
        this.updateLabelColor();
    }

    set position(position: Readonly<Point>) {
        this._position.x = position.x;
        this._position.y = position.y;
        this.sprite.position.copyFrom(this);
    }

    private updateLabelColor() {
        if (!this._labelSprite) return;
        this._labelSprite.tint = (this.style === 'SELECTED' && getLuminance(this.color) < 128)
            ? LABEL_BRIGHT_COLOR
            : LABEL_DEFAULT_COLOR;
    }

    private redraw() {
        applySeatStyle(this.sprite, this.style, this.color);
    }

}

/**
 * Bestimmt den primären für einen Seat anzuwendenden tag.
 *
 * Der primäre tag eines Seat, ist der entsprechend der Reihenfolge aller TagDefinitionen erste definierte.
 *
 * @param seatTagIds Die IDs der tags eines Seat, die betrachtet werden sollen.
 * @param tagDefinitions Die TagDefinitionen die zu berücksichtigen sind.
 *
 * @return Der primäre tag eines Seat, wenn vorhanden.
 */
 export function determinePrimaryTagForSeat(seatTagIds: string[], tagDefinitions: TagDefinition[]): TagDefinition | undefined {
    const seatTagIdSet = new Set(seatTagIds);

    // FIXME: Evtl. sollte man noch die als deleted = true markierten Tags rausfiltern, da aber aktuelle generell
    //  die Funktionalität zum löschen von tags komplett broken scheint, lassen wir das erstmal, dann sieht
    //  man wenigstens, dass eigentlich gelöschte tags noch dem Seat zugewiesen sind.

    // Um die Reihenfolge der tags entsprechend der Definition zu erhalten,
    // verwenden wir die Definitionen als "führendes Set".
    return tagDefinitions.find(t => seatTagIdSet.has(t.id));
}


export function determinePrimaryPlacepoolForSeat(seatTagIds: string[], tagDefinitions: IPlacepool[]): IPlacepool | undefined {
    const seatTagIdSet = new Set(seatTagIds);

    // FIXME: Evtl. sollte man noch die als deleted = true markierten Tags rausfiltern, da aber aktuelle generell
    //  die Funktionalität zum löschen von tags komplett broken scheint, lassen wir das erstmal, dann sieht
    //  man wenigstens, dass eigentlich gelöschte tags noch dem Seat zugewiesen sind.

    // Um die Reihenfolge der tags entsprechend der Definition zu erhalten,
    // verwenden wir die Definitionen als "führendes Set".
    return tagDefinitions.find(t => seatTagIdSet.has(t.id));
}
