import {call, put} from 'redux-saga/effects';
import {normalize} from 'normalizr';

/**
 * Generische Saga für das Erzeugen von Entitäten.
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 * @param onSuccess Optionale callback Funktion deren Ergebnis "geyielded" wird nachdem die Entität erfolgreich angelegt wurde. Erhält die neu erzeugte Entität als Parameter.
 */
export default ({actions, resource, schema, onSuccess}) => function* ({payload: { entity, noRedirectAfterCreate = false }}) {
    try {
        const result = yield call(resource.create, entity);
        const response = normalize(result, schema);

        yield put(actions.createSuccess({
            response, globalMessagesList: {
                id: Date.now(),
                variant: 'success',
                isCreate: true,
                message: 'Änderung erfolgreich gespeichert!'
            }
        }));

        if (onSuccess) {
            yield onSuccess(result, noRedirectAfterCreate);
        }
    } catch (error) {
        yield put(actions.createError({entity, error}));
    }
}
