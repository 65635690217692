import React, { useEffect, useState } from "react";
import styles from "./baseLayout.module.scss";
import { useSelector } from "react-redux";
import { IState, selectBlocks, selectPlaceCategories, selectSeatingTypes, selectSeatsByBlockAndRow, selectSeatsWithEmptyLabelOrRow, selectSelectedSeats } from "../../state/entities/venueEditor/selectors";
import FeedbackButton from "../../components/common/FeedbackButton";
import MaterialInput from '../../components/layout/MaterialInput';
import MaterialDropdown from "../../components/layout/MaterialDropdown";
import { Seat } from "../types";
import { TabPlacepoolChangeFunc } from "./TabPlacePools";


interface ITabSeatDataFormProps {
    onSelectedSeatsChange?: TabPlacepoolChangeFunc;
    venuePlanId: string;
}


const TabSeatDataForm: React.FC<ITabSeatDataFormProps> = ({onSelectedSeatsChange, venuePlanId}) => {
    const placeCategories = useSelector((state: IState) => selectPlaceCategories(state));
    const seatingTypes = useSelector((state: IState) => selectSeatingTypes(state));
    const blocks = useSelector((state: IState) => selectBlocks(state));
    const selectedSeats = useSelector((state: IState) => selectSelectedSeats(state));
    const [changedSeats, setChangedSeats] = useState<Seat[]>([]);
    const seatsWithEmptyLabelOrRow = useSelector((state: IState) => selectSeatsWithEmptyLabelOrRow(state));
    const seatsByBlockAndRow = useSelector((state: IState) => selectSeatsByBlockAndRow(state));
    const [changedPlaceCategoryId, setChangedPlaceCategoryId] = useState<string>("");
    const [changedSeatingTypeId, setChangedSeatingTypeId] = useState<string>("");
    const [changedSeatLabel, setChangedSeatLabel] = useState<string>("");
    const [changedRowLabel, setChangedRowLabel] = useState<string>("");
    const [changedBlockId, setChangedBlockId] = useState<string>("");



    useEffect(() => {
        //wenn alle sel. Plätze denselben Wert für pricingCat/seatingType/block haben, zeige ihn, sonst leer.
        setChangedPlaceCategoryId(selectedSeats.length > 0
            ? (
                (selectedSeats.every((seat, _, selSeats) => seat.pricingCategoryId === selSeats[0].pricingCategoryId)
                && selectedSeats[0].pricingCategoryId) || '')
            : ''
        );
        setChangedSeatingTypeId(selectedSeats.length > 0
            ? (
                (selectedSeats.every((seat, _, chSeats) => seat.seatingTypeId === chSeats[0].seatingTypeId)
                && selectedSeats[0].seatingTypeId) || '')
            : ''
        );
        setChangedBlockId(selectedSeats.length > 0
            ? (
                (selectedSeats.every((seat, _, chSeats) => seat.blockId === chSeats[0].blockId)
                && selectedSeats[0].blockId) || '')
            : ''
        );
        setChangedRowLabel(selectedSeats.length > 0
            ? (
                (selectedSeats.every((seat, _, chSeats) => seat.row === chSeats[0].row)
                && selectedSeats[0].row) || '')
            : ''
        );
        setChangedSeatLabel(selectedSeats.length > 0
            ? (
                (selectedSeats.every((seat, _, chSeats) => seat.label === chSeats[0].label)
                && selectedSeats[0].label) || '')
            : ''
        );
     }, [selectedSeats]);


    useEffect(() => {
        setChangedSeats(selectedSeats.map((seat: Seat): Seat => {
            const chSeat: Seat = {...seat};
            if (changedPlaceCategoryId !== '') chSeat.pricingCategoryId = changedPlaceCategoryId;
            if (changedSeatingTypeId !== '') chSeat.seatingTypeId = changedSeatingTypeId;
            if (changedSeatLabel !== '') chSeat.label = changedSeatLabel;  //wenn leer, dann soll Originalwert nicht überschrieben werden
            if (changedRowLabel !== '') chSeat.row = changedRowLabel;
            return chSeat;
        }));    
    }, [selectedSeats, changedPlaceCategoryId, changedSeatingTypeId, changedBlockId, changedSeatLabel, changedRowLabel]);


    const handleSubmitChanges = () => {
        onSelectedSeatsChange(changedSeats, false);
    }

    


    let errorHint = "";
    let foundBlocker = false;

    for (let currChSeatIndex = 0; currChSeatIndex < changedSeats.length; currChSeatIndex++) {
        const currChSeat = changedSeats[currChSeatIndex];
        for (let ci = currChSeatIndex + 1; ci < changedSeats.length; ci++) { //check if combination exists in rest of changed seats
            if (changedSeats[ci].blockId === currChSeat.blockId
                && changedSeats[ci].row === currChSeat.row
                && changedSeats[ci].label === currChSeat.label)
            {
                foundBlocker = true;
                errorHint = "Doppelte Kombi aus Sitz-/Reihenbezeichnung vorhanden.";
                break;
            }
        }
        if (foundBlocker) break;
        if (!(seatsByBlockAndRow[currChSeat.blockId] && seatsByBlockAndRow[currChSeat.blockId][currChSeat.row])) continue;
        for (const seat of seatsByBlockAndRow[currChSeat.blockId][currChSeat.row]) {    // check if combination already exists in venue plan
            if (seat.label === currChSeat.label
                && currChSeat.id !== seat.id
            ) {
                foundBlocker = true;
                errorHint = "Kombi aus Sitz-/Reihenbezeichnung existiert bereits.";
                break;
            }
        }
        if (foundBlocker) break;
        
    }
    if (!foundBlocker) {  //probably obsolete
        if (seatsWithEmptyLabelOrRow.length > 0) errorHint = "Es gibt noch Sitze mit leerer Reihe/Sitzbezeichnung!";
    }

    return (
        <div className={styles.tabsContent}>
            <div className={styles.tabsContent__fixedBiggerBlock}>
                <h2 className={styles.tabsContent__title}>Platzdaten</h2>
                {selectedSeats.length === 0
                    ? <h3>Keine Sitze ausgewählt.</h3>
                    :
                <>
                    <div className={styles.fieldContainer}>
                        <MaterialDropdown className={styles.field}
                            label="Preiskategorie"
                            startAdornment={false}
                            endAdornment={false}
                            value={changedPlaceCategoryId}
                            onChange={value => setChangedPlaceCategoryId(value)}
                            options={[{label: 'Bitte wählen', value: ''}].concat(placeCategories.map(placeCategory => {return {label: placeCategory.name, value: placeCategory.id}}))}
                        />
                        <MaterialDropdown className={styles.field}
                            label="Bestuhlungstyp"
                            value={changedSeatingTypeId}
                            startAdornment={false}
                            endAdornment={false}
                            onChange={value => setChangedSeatingTypeId(value)}
                            options={[{label: 'Bitte wählen', value: ''}].concat(seatingTypes.map(seatingType => {return {label: seatingType.name, value: seatingType.id}}))}
                        />
                    </div>
                    <div className={styles.fieldContainer}>
                        <MaterialInput className={styles.field}
                            label='Sitzbezeichnung'
                            value={changedSeatLabel}
                            startAdornment={false}
                            showExternalLabel={true}
                            onChange={value => setChangedSeatLabel(value)}
                        />
                        <MaterialInput className={styles.field}
                            label='Reihenbezeichnung'
                            startAdornment={false}
                            value={changedRowLabel}
                            showExternalLabel={true}
                            onChange={value => setChangedRowLabel(value)}
                        />
                        <MaterialInput className={styles.field}
                            label='Blockbezeichnung'
                            startAdornment={false}
                            value={blocks.find(block => block.id === changedBlockId)?.backendNameShort}
                            showExternalLabel={true}
                            disabled={true}
                        />
                    </div>
                    <FeedbackButton disabled={foundBlocker} variant="outlined" onClick={handleSubmitChanges}>
                        Übernehmen
                    </FeedbackButton>
                    <span className={styles.tabsContent__errorHint}>{errorHint}</span>
                </>}
            </div>
        </div>
    );
};



export default TabSeatDataForm;