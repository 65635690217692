import React, {Component} from 'react';
import {Container, Row} from 'react-bootstrap';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import ConnectedListView from '../common/listView/ConnectedListView';
import FeedbackButton from '../common/FeedbackButton';
import {connect} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {get} from 'lodash';
import * as tenant from "../../state/entities/tenant";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDeleteConfirmDialog: false,
            tenantToDelete: undefined
        }
    }

    showDeleteConfirmDialog(tenant) {
        this.setState({
            showDeleteConfirmDialog: true,
            tenantToDelete: tenant,
        });
    }

    handleCancelDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
    };

    handleConfirmDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
        this.props.deleteTenant(this.state.tenantToDelete);
    };

    render() {
        return (
            <Container style={{ width: this.state.tableWidth }}>
                <Row className="my-4">
                    <Breadcrumbs entity={["Tenant"]} endpoints = {['tenant/breadcrumb']} title = "Mandanten" links={['/base/tenant/']} active={[0]} />
                </Row>
                <ConnectedListView
                    heading="Mandanten"
                    endpoint="listingView/tenant"
                    buttonTitle="Mandanten hinzufügen"
                    buttonLink="/base/tenant/create"
                    testid="link_new_tenant"
                >
                    {({fields, item}) => (
                        <>
                            <FeedbackButton
                                className="list-link"
                                title="Tenant bearbeiten"
                                to={`/base/tenant/${item.id}`}
                                icon={<EditNoteOutlinedIcon className='table-icon' />}
                            />
                            <FeedbackButton title="Tenant löschen" className="list-link" onClick={() => this.showDeleteConfirmDialog(item)}>
                                <DeleteOutlineOutlinedIcon className='table-icon' />
                            </FeedbackButton>
                        </>
                    )}
                </ConnectedListView>


                <Modal show={this.state.showDeleteConfirmDialog} onHide={this.handleCancelDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tenant löschen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Möchten Sie den Tenant "{get(this.state, 'tenantToDelete.name')}" wirklich löschen?</Modal.Body>
                    <Modal.Footer>
                        <FeedbackButton variant="outlined" onClick={this.handleCancelDelete}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton onClick={this.handleConfirmDelete}>
                            Löschen
                        </FeedbackButton>
                    </Modal.Footer>
                </Modal>

            </Container>
        );
    }
}

export default connect(null, {
    deleteTenant: entity => tenant.actions.delete({entity})
})(List);
