import React, {Component} from 'react';
import Form from "./Form";
import {actions} from "../../state/entities/eventSeries";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Create extends Component {

    onSubmit = (eventSeries) => {
        this.props.create(eventSeries);
    };

    render() {
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Eventserie"]} endpoints = {['event_series/breadcrumb']} title = "Eventserien" links={['/event-management/event-series/']} active={[0]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Erstellen einer neuen Eventserie</h1>
                </Col>
            </Row>
            <Form eventSeries={{}} onSubmit={this.onSubmit} submitPending={this.props.createPending} />
        </>;
    }
}

const mapStateToProps = (state) => {
    return {
        createPending: state.entities.eventSeries.createPending
    }
};

const mapDispatchToProps = dispatch => {
    return {
        create: entity => dispatch(actions.create({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
