import React, {Component} from 'react';
import Form from "./Form";
import * as pricingCategory from "../../state/entities/pricingCategory";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Create extends Component {

    onSubmit = (pricingCategory, {setErrors}) => {
        this.props.createPricingCategory(pricingCategory, setErrors);
    };

    render() {
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Preiskategorie"]} endpoints = {['pricing_category/breadcrumb']} title = "Preiskategorien" links={['/base/pricing-category/']} active={[0]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Erstellen einer neuen Preiskategorie</h1>
                </Col>
            </Row>
            <Form pricingCategory={{}} onSubmit={this.onSubmit} submitPending={this.props.createPending}/>
        </>;
    }
}

const mapStateToProps = (state) => {
    return {
        createPending: state.entities.pricingCategory.createPending
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createPricingCategory: entity => dispatch(pricingCategory.actions.create({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
