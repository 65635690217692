import {Resource} from "../../../api";

export default class ZukoZone extends Resource {

    constructor(axios) {
        super('zukoZone', axios);
    }

    /**
     * Ruft Zuko-Zonen einer Spielstätte ab
     */
    byVenue = (id) => {
        return this.axios.get(`/zukoZone/byVenue/${id}`)
            .then((res) => res.data);
    };
}
