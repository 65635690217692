import React from 'react';
import Form from "./Form";
import * as ticketLayout from "../../state/entities/ticketLayout";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

const Create = ({createPending, createTargetGroup}) => {
    const onSubmit = (ticketLayout, {setErrors}) => {
        createTargetGroup(ticketLayout, setErrors);
    };

    return <>
        <Row className="my-4">
            <Breadcrumbs entity={["TicketLayout"]} endpoints = {['ticket_layout/breadcrumb']} title = "Ticketlayouts" links={['/base/ticket-layout/']} active={[0]} />
        </Row>
        <Row className="mb-4 mt-4">
            <Col className="pt-3 col-sm-8">
                <h1>Erstellen eines neuen Ticketlayouts</h1>
            </Col>
        </Row>
        <Form ticketLayout={{}} onSubmit={onSubmit} submitPending={createPending} />
    </>;

}

const mapStateToProps = (state, props) => {
    return {
        createPending: state.entities.ticketLayout.createPending
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createTargetGroup: entity => dispatch(ticketLayout.actions.create({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
