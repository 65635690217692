import React, {useEffect, useState} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormikTextInputGroup } from '../common/formik/FormikTextInputGroup';
import FeedbackButton from '../common/FeedbackButton';
import { FormikMoneyInputGroup } from "../common/formik/FormikMoneyInputGroup";
import ConfirmModal from "../common/modal/ConfirmModal";
import { FormikCheckbox } from "../common/formik/FormikCheckbox";
import FormikRadio, { ItemsProps } from '../common/formik/FormikRadio';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FormikSelect from "../common/formik/FormikSelect";
import Api from "../../api";

interface PricingComponentFormProps {
    index: number;
    remove: () => void;
    helpTextsVisible: boolean;
}

interface ITaxCategoryLabel {
    id: string;
    name: string;
}

const PricingComponentForm: React.FC<PricingComponentFormProps> = ({
    index,
    remove,
    helpTextsVisible
}) => {
    const api = new Api();
    const [taxCategoryLabels, setTaxCategoryLabels] = useState<ITaxCategoryLabel[]>([]);
    useEffect(() => {api.getTaxCategoryLabels().then(res => {setTaxCategoryLabels(res)})}, []);

    const items: ItemsProps[] = [
        {
            id: 'INVOICE_DISPLAY_TYPE_NONE',
            value: 'INVOICE_DISPLAY_TYPE_NONE',
            name: 'Nicht auf Rechnung ausweisen',
            component: ''
        },
        {
            id: 'INVOICE_DISPLAY_TYPE_WITHOUT_AMOUNT',
            value: 'INVOICE_DISPLAY_TYPE_WITHOUT_AMOUNT',
            name: 'Auf Rechnung ausweisen, ohne Betrag',
            component: ''
        },
        {
            id: 'INVOICE_DISPLAY_TYPE_WITH_AMOUNT',
            value: 'INVOICE_DISPLAY_TYPE_WITH_AMOUNT',
            name: 'Auf Rechnung ausweisen, mit Betrag',
            component: ''
        },
    ];

    return (
        <>

            <Row className="pricingComponent">
                <Row><Col sm={12}><h3 className="mb-3 font-weight-bold">Weitere Komponente</h3></Col></Row>
                <Row>
                    <Col sm={4}>
                        <FormikTextInputGroup label="Bezeichnung" name={`pricingComponents[${index}].name`}/>
                    </Col>
                    <Col sm={4}>
                        <FormikTextInputGroup label="Kurzbezeichnung" name={`pricingComponents[${index}].shortName`}/>
                    </Col>
                    <Col sm={4}>
                        <FormikMoneyInputGroup
                            label={<span>Betrag (brutto)</span>}
                            name={`pricingComponents[${index}].grossAmount`}
                        />
                    </Col>

                    <Col sm={4}>
                        <FormikTextInputGroup label="Produktbuchungsgruppe"
                                            name={`pricingComponents[${index}].productBookingGroup`}/>
                    </Col>

                    <Col sm={4}>
                        <FormikSelect label="MwSt.-Produktbuchungsgruppe"
                                      name={`pricingComponents[${index}].productBookingGroupVatKey`}
                                      options={taxCategoryLabels}
                                      isShowEmptyValue={false}/>
                    </Col>
                    <Col sm={4}>
                        <FormikTextInputGroup type="number" label="Mehrwertsteuersatz"
                                            name={`pricingComponents[${index}].productBookingGroupVatValue`}/>
                    </Col>

                    <Col sm={4}>
                        <FormikCheckbox label="Event-Suffix anhängen?"
                                            classNameCheckbox="pricingcomponent-suffix-checkbox"
                                            name={`pricingComponents[${index}].productBookingGroupAppendEventSuffix`}
                                            helpTextsVisible={helpTextsVisible}
                                            helpText="Soll beim Verkauf dieser Leistung der bei dem Event gepflegte
                                            'Fibu-Suffix für Übergabe an ERP' an den Wert dieses Feldes angehängt werden?"/> 
                    </Col>

                    <Col sm={4}>
                        <FormikCheckbox label="Event-Suffix anhängen?"
                                        classNameCheckbox="pricingcomponent-suffix-checkbox"
                                        name={`pricingComponents[${index}].productBookingGroupVatKeyAppendEventSuffix`}
                                        helpTextsVisible={helpTextsVisible}
                                        helpText="Soll beim Verkauf dieser Leistung der bei dem Event gepflegte
                                        'Fibu-Suffix für Übergabe an ERP' an den Wert dieses Feldes angehängt werden?"/>
                    </Col>

                    <Col sm={12}>
                        <FormikTextInputGroup label="Beschreibung Produktbuchungsgruppe"
                                            name={`pricingComponents[${index}].productBookingGroupDescription`}/>
                    </Col>

                    <Col className="mt-4">
                        <Form.Group>
                            <FormikRadio
                                name={`pricingComponents[${index}].invoiceDisplayType`}
                                items={items}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm={12} className="text-right">
                        <ConfirmModal title="Wirklich löschen?" body="Möchten Sie diese Preiskomponente wirklich aus der Leistungsvorlage entfernen?">
                            {(confirm: (remove: () => void) => void) =>
                                <FeedbackButton variant="outlined" onClick={() => confirm(remove)}>
                                    <DeleteOutlineOutlinedIcon className='table-icon' />
                                </FeedbackButton>
                            }
                        </ConfirmModal>
                    </Col>
                </Row>
            </Row>
        </>
    );
}

export default PricingComponentForm;
