import React from 'react';
import PropTypes from "prop-types";
import {Col, Form, Row} from "react-bootstrap";
import styles from './layoutEditor.module.scss';
import ConfirmModal from "../common/modal/ConfirmModal";
import FeedbackButton from "../common/FeedbackButton";

export const GRID_SIZES = [
    {label: 'Kein Gitter', value: 0},
    {label: 'Feines Gitter', value: 10},
    {label: 'Mittleres Gitter', value: 20},
    {label: 'Grobes Gitter', value: 40},
    {label: 'Sehr grobes Gitter', value: 60},
];

const Toolbar = ({
                     editModeEnabled,
                     gridSize,
                     showElementBorder,
                     showElementToolbar,
                     showGrid,
                     snapToGrid,
                     zoomLevel,
                     toggleEditMode,
                     toggleShowGrid,
                     toggleSnapToGrid,
                     toggleShowElementBorder,
                     toggleShowElementToolbar,
                     importElements,
                     exportElements,
                     setGridSize,
                     setZoomLevel
                 }) => {
    const handleZoomLevelChange = (e) => {
        setZoomLevel(parseInt(e.target.value));
    };

    let fileInput = null;

    return <>
        <Row>
            <Col className="mt-4 col-12">
                <label><input type="checkbox" checked={editModeEnabled} onChange={toggleEditMode}/> Bearbeitungsmodus (Alt+Shift+1)</label>
            </Col>
        </Row>
        <Row>
            <Col className="mt-4 col-6">
                <label><input type="checkbox" checked={showElementToolbar} onChange={toggleShowElementToolbar}/> Toolbar</label>
            </Col>
            <Col className="mt-4 col-6">
                <label><input type="checkbox" checked={showElementBorder} onChange={toggleShowElementBorder}/> Elementrahmen</label>
            </Col>
        </Row>
        <Row>
            <Col className="mt-3 col-6">
                <label><input type="checkbox" checked={showGrid} onChange={toggleShowGrid}/> Gitternetz</label>
            </Col>
            <Col className="mt-3 col-6">
                <label><input type="checkbox" checked={snapToGrid} onChange={toggleSnapToGrid}/> Am Raster ausrichten</label>
            </Col>
        </Row>
        <Row>
            <Col className="col-12">
                <Form.Control as="select"
                              value={gridSize}
                              onChange={(event) => {
                                  setGridSize(event.target.value);
                              }}
                >
                    {GRID_SIZES.map(size => {
                        return (
                            <option key={size.value} value={size.value}>{size.label}</option>
                        );
                    })}
                </Form.Control>
            </Col>
        </Row>
        <Row>
            <Col className="col-12">
                <div className="text-center">{zoomLevel * 10}%</div>
                <input type="range" className={`${styles.Zoom}`} min="10" max="30" value={zoomLevel}
                       onChange={handleZoomLevelChange}
                />
            </Col>
        </Row>
        <Row>
            <Col className="col-6 text-center mt-2">
                <button onClick={() => exportElements()} className="btn btn-info" type="button">
                    <i className="fa fa-upload"></i> Export
                </button>
            </Col>
            <Col className="col-6 text-center mt-2">
                <ConfirmModal title="Confirm import"
                              body={`The import will override all current layout elements!`}
                              cancelLabel="Abbrechen"
                              confirmLabel="Import"
                >
                    {confirm => <span>
                        <input type="file"
                               ref={file => {
                                   fileInput = file
                               }}
                               accept='.json, .JSON'
                               style={{display: 'none'}}
                               onChange={importElements}
                        />
                        <FeedbackButton onClick={() => confirm(() => fileInput.click())} style={{marginBottom: 20}}>
                            <i className="fa fa-download"></i> Import
                        </FeedbackButton>
                 </span>}
                </ConfirmModal>
            </Col>
        </Row>
    </>
};

Toolbar.propTypes = {
    editModeEnabled: PropTypes.bool.isRequired,
    showGrid: PropTypes.bool.isRequired,
    snapToGrid: PropTypes.bool.isRequired,
    showElementBorder: PropTypes.bool.isRequired,
    showElementToolbar: PropTypes.bool.isRequired,
    gridSize: PropTypes.number.isRequired,
    zoomLevel: PropTypes.number.isRequired,
    toggleEditMode: PropTypes.func.isRequired,
    toggleShowGrid: PropTypes.func.isRequired,
    toggleSnapToGrid: PropTypes.func.isRequired,
    toggleShowElementBorder: PropTypes.func.isRequired,
    toggleShowElementToolbar: PropTypes.func.isRequired,
    importElements: PropTypes.func.isRequired,
    exportElements: PropTypes.func.isRequired,
    setGridSize: PropTypes.func.isRequired,
    setZoomLevel: PropTypes.func.isRequired,
};

export default Toolbar;



