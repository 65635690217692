import React from 'react';
import TextContent from "../layout/TextContent";

const PermissionDenied = () => {
    return <TextContent>
        <h1>Keine Berechtigung</h1>
        <p>
            Sie haben zu dieser Seite keinen Zugriff.
        </p>
    </TextContent>
};

export default PermissionDenied;
