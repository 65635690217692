import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import PropTypes from "prop-types";
import FeedbackButton from '../common/FeedbackButton';
import {getIn} from "formik";
import FilteringMultiSelectList from "../common/form/FilteringMultiSelectList/FilteringMultiSelectList";
import {findIndex, get} from "lodash";
import ConfirmModal from "../common/modal/ConfirmModal";
import FormModal from "../common/modal/FormModal";
import {Bundle} from "./schema";
import ObjectId from "bson-objectid";
import BundleForm from "./BundleForm";
import Api from "../../api";
import AddIcon from '@mui/icons-material/Add';
import styles from "../form.module.scss";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

const api = new Api();

export function createBundleListElements(formBundles, eventSalesRules, usedBundlesWhichCannotDelete) {
    return formBundles.map((bundle, index) => {
        let enabledStatus = !!bundle.enabled;
        let bundleStatusText = bundle.enabled ? 'Enabled' : 'Disabled';

        if (typeof bundle.salesRules !== "undefined") {

            for (let i = 0; i < bundle.salesRules.length; i++) {
                // find current eventSalesRule from bundle in event's sales rules
                let eventSalesRule = eventSalesRules.find(salesRule => {
                    return salesRule.id === bundle.salesRules[i].eventSalesRuleId;
                });

                if (eventSalesRule) {
                    if (!eventSalesRule.enabled || Date.parse(eventSalesRule.validTo) <= Date.now()) {
                        bundleStatusText = 'Disabled because ' + eventSalesRule.salesRule.name;
                        enabledStatus = false;
                        break;
                    }
                }
            }
        }

        return ({
            ...bundle,
            index: index,
            enabledText: bundleStatusText,
            enabledStatus: enabledStatus,
            canDelete: !usedBundlesWhichCannotDelete.includes(bundle.id)
        });
    });
}

function FormBundles({formik, event}) {
    const [usedBundlesWhichCannotDelete, setUsedBundlesWhichCannotDelete] = useState([]);
    const [bundleToEdit, setBundleToEdit] = useState(null);
    const [bundleToAdd, setBundleToAdd] = useState(null);
    const [availableEventSalesRules, setAvailableEventSalesRules] = useState([]);

    useEffect(() => {
        if (!!event.id) {
            api.getUsedBundlesForEvent(event.id).then(result => {
                if (Array.isArray(result.usedBundles)) {
                    setUsedBundlesWhichCannotDelete(result.usedBundles);
                } else {
                    console.error("usedBundles from getUsedBundlesForEvent is not an array", result.usedBundles)
                }
            });
        }
    }, []);

    const fieldCheckbox = {name: 'enabled', label: 'Regel freigeben', showStatusIcon: true, type: 'formBundles'};
    const bundleFields = [
        {name: 'name', label: 'Name'},
    ];

    const addBundle = (addedBundle) => {
        formik.setFieldValue('bundles', [...formik.values.bundles, {
            ...addedBundle,
            id: ObjectId.generate()
        }]);

        setBundleToAdd(null);
    };

    const updateBundle = (updatedBundle) => {
        let bundles = getIn(formik.values, 'bundles', []);
        const index = findIndex(bundles, s => s.id === updatedBundle.id);

        if (index !== -1) {
            bundles = [...bundles];
            bundles.splice(index, 1, updatedBundle);
            formik.setFieldValue('bundles', bundles);
        }

        setBundleToEdit(null);
    };

    const onChange = (selection) => {
        formik.setFieldValue('bundles', selection)
    };

    const RemoveBundleAction = ({id}) => {
        const bundle = formik.values.bundles.find(item => {
            return item.id === id;
        });

        const removeBundle = (id) => () => {
            const values = formik.values.bundles.filter(quota => quota.id !== id);
            formik.setFieldValue('bundles', values)
        };

        return (
            <ConfirmModal title="Bundle entfernen"
                          body={`Möchten Sie das Bundle "${bundle.name}" wirklich entfernen?`}
                          cancelLabel="Abbrechen"
                          confirmLabel="Löschen"
            >
                {confirm => (
                    <FeedbackButton title="Bundle löschen" className="list-link" onClick={() => confirm(removeBundle(id))}>
                        <DeleteOutlineOutlinedIcon className='table-icon' />
                    </FeedbackButton>
                )}
            </ConfirmModal>
        )
    }

    const EditBundleButton = ({bundle}) => (
        <FeedbackButton title="edit" className="list-link" onClick={() => setBundleToEdit(bundle)}>
            <EditNoteOutlinedIcon className='table-icon' />
        </FeedbackButton>
    )
    return (
        <>
            <FormModal show={!!bundleToAdd}
                       title="Bundle hinzufügen"
                       initialValues={{...Bundle.default(), ...bundleToAdd}}
                       validationSchema={Bundle}
                       onConfirm={addBundle}
                       onCancel={() => setBundleToAdd(null)}
                       submitTestId="submit_bundle"
            >
                {({associationFormik}) => {
                    return (
                        <BundleForm associationFormik={associationFormik}
                                    availableEventSalesRules={availableEventSalesRules}
                                    element={{...Bundle.default(), ...bundleToAdd}}
                                    setAvailableEventSalesRules={setAvailableEventSalesRules}
                                    eventSalesRules={formik.values.salesRules}
                                    parentFormik={formik}
                        />
                    )
                }}
            </FormModal>
            <FormModal show={!!bundleToEdit}
                       title="Bundle bearbeiten"
                       initialValues={{...Bundle.default(), ...bundleToEdit}}
                       validationSchema={Bundle}
                       onConfirm={updateBundle}
                       onCancel={() => setBundleToEdit(null)}
                       submitTestId="submit_bundle"
            >
                {({associationFormik}) => {
                    return (
                        <BundleForm associationFormik={associationFormik}
                                    availableEventSalesRules={availableEventSalesRules}
                                    element={{...Bundle.default(), ...bundleToEdit}}
                                    setAvailableEventSalesRules={setAvailableEventSalesRules}
                                    eventSalesRules={formik.values.salesRules}
                                    parentFormik={formik}
                        />
                    )
                }}
            </FormModal>
            <Row>
                <Col className='col-md-4'>
                    <div className={styles.formBox}>
                        <h2 className={styles.formTitle}>Alle Bundles</h2>
                        <FeedbackButton
                            icon={<AddIcon />}
                            className="mb-3"
                            onClick={() => setBundleToAdd({})
                        }>
                            Bundle hinzufügen
                        </FeedbackButton>
                        <FilteringMultiSelectList
                            elements={createBundleListElements(formik.values.bundles, formik.values.salesRules, usedBundlesWhichCannotDelete)}
                            fieldCheckbox={fieldCheckbox}
                            fields={bundleFields}
                            formik={formik}
                            onChange={onChange}
                        >
                            {({element}) => {
                                return (
                                    <div key={`div${element.id}`}>
                                        <EditBundleButton bundle={element}/>
                                        {element.canDelete && <RemoveBundleAction id={element.id}/>}
                                    </div>
                                )
                            }}
                        </FilteringMultiSelectList>
                    </div>
                </Col>
            </Row>
        </>
    )
}

FormBundles.propTypes = {
    formik: PropTypes.object,
    event: PropTypes.object,
};

export default FormBundles;
