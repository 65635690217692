const format = (amountInCents) => {
    let amount = amountInCents ? amountInCents / 100 : 0;

    return amount.toLocaleString('de-de', {
        currency: 'EUR',
        style: 'currency',
    })
};

export default ({centAmount}) => format(centAmount);
