import React from 'react';
import {Switch} from 'react-router-dom';

import List from './List';
import {Container} from 'react-bootstrap';
import SecureRoute from "../auth/SecureRoute";

const Routing = () => {
    return (
        <Container className={'mt-1'}>
            <Switch>
                <SecureRoute exact path="/sales/service-case" component={List}/>
            </Switch>
        </Container>
    )
};

export default Routing;
