import React from 'react';
import PropTypes from "prop-types";
import styles from "../layoutEditor.module.scss";
import logo from './img/event_series_logo.png';

const EventSeriesLogo = ({element, editModeEnabled}) => {
    return <div className={`${styles.LayoutElementInner} ${!editModeEnabled ? "interactive" : ""}`}>
        <img src={logo}
             style={{
                 maxWidth: element.width + "px",
                 maxHeight: element.height + "px",
             }} alt="eventSeriesLogo"/>
    </div>;
};

EventSeriesLogo.propTypes = {
    element: PropTypes.object.isRequired,
    editModeEnabled: PropTypes.bool
};

export default EventSeriesLogo;
