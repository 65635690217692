import React from 'react';
import PropTypes from 'prop-types';
import TablePagination from '@mui/material/TablePagination';

export const PAGINATION_WINDOW_SIZE_DEFAULT = 10;

const ListViewPaginationControl = ({
    currentPage,
    onPageChange,
    totalPages,
    maxPerPage,
    totalCount,
}) => {

    const handleChangePage = (_, newPage) => {
        onPageChange(newPage + 1, maxPerPage || PAGINATION_WINDOW_SIZE_DEFAULT);
    };
    
    const handleChangeRowsPerPage = (event) => {
        onPageChange(1, parseInt(event.target.value, 10));
    };

    return (
        <TablePagination
            showLastButton
            showFirstButton
            component="div"
            count={totalCount}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            rowsPerPage={maxPerPage || PAGINATION_WINDOW_SIZE_DEFAULT}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Zeilen pro Seite"
        />
    );
}

ListViewPaginationControl.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    paginationWindowSize: PropTypes.number,
    maxPerPage: PropTypes.number,
    onPageChange: PropTypes.func
};

export default ListViewPaginationControl;
