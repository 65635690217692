import React from 'react';
import {Col, Row} from "react-bootstrap";
import {FormattedDate} from "react-intl";
import Euro from "../common/numberFormat/Euro";

const PaymentEvent = ({paymentEvent}) => {

    const PayoneProperties = () => {
        if (paymentEvent.type !== 'payone') {
            return <></>;
        }

        return <>
            <Row>
                <Col className="col-3">
                    Payone payment status
                </Col>
                <Col className="col-9">
                    {paymentEvent.payonePaymentStatus}
                </Col>
            </Row>
            <Row>
                <Col className="col-3">
                    Payone webhook date
                </Col>
                <Col className="col-9">
                    {paymentEvent.webhookDate &&
                        <FormattedDate value={paymentEvent.webhookDate} year="numeric" month="2-digit"
                                       day="2-digit" hour="2-digit" minute="2-digit" second="2-digit"/>}
                </Col>
            </Row>
            <Row>
                <Col className="col-3">
                    Payone event type
                </Col>
                <Col className="col-9">
                    {paymentEvent.webhookType}
                </Col>
            </Row>
        </>;
    };

    const MollieProperties = () => {
        if (paymentEvent.type !== 'mollie') {
            return <></>;
        }

        return <>
            <Row>
                <Col className="col-3">
                    Mollie payment status
                </Col>
                <Col className="col-9">
                    {paymentEvent.molliePaymentStatus}
                </Col>
            </Row>
            <Row>
                <Col className="col-3">
                    Mollie webhook date
                </Col>
                <Col className="col-9">
                    {paymentEvent.webhookDate &&
                        <FormattedDate value={paymentEvent.webhookDate} year="numeric" month="2-digit"
                                       day="2-digit" hour="2-digit" minute="2-digit" second="2-digit"/>}
                </Col>
            </Row>
        </>;
    };

    const MollieOrderProperties = () => {
        if (paymentEvent.type !== 'mollieOrder') {
            return <></>;
        }

        return <>
            <Row>
                <Col className="col-3">
                    Mollie order status
                </Col>
                <Col className="col-9">
                    {paymentEvent.mollieOrderStatus}
                </Col>
            </Row>
            <Row>
                <Col className="col-3">
                    Mollie webhook date
                </Col>
                <Col className="col-9">
                    {paymentEvent.webhookDate &&
                        <FormattedDate value={paymentEvent.webhookDate} year="numeric" month="2-digit"
                                       day="2-digit" hour="2-digit" minute="2-digit" second="2-digit"/>}
                </Col>
            </Row>
        </>;
    };

    const Errors = () => {
        if (!paymentEvent.error) {
            return <></>;
        }

        return <>
            <Row>
                <Col className="col-3">
                    Error
                </Col>
                <Col className="col-9">
                    <Row>
                        <Col className="col-3">
                            Message
                        </Col>
                        <Col className="col-9">
                            {paymentEvent.error.message}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-3">
                            Details
                        </Col>
                        <Col className="col-9">
                            {paymentEvent.error.details.map((detail, index) => <>
                                <Row key={index}>
                                    <Col className="col-12">
                                        {detail}
                                    </Col>
                                </Row>
                            </>)}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>;
    };

    return (
        <div className="paymentEvent mt-4">
            <Row>
                <Col className="col-3">
                    Action
                </Col>
                <Col className="col-9">
                    {paymentEvent.action}
                </Col>
            </Row>
            <Row>
                <Col className="col-3">
                    Status
                </Col>
                <Col className="col-9">
                    {paymentEvent.status}
                    {paymentEvent.orderStatus}
                </Col>
            </Row>
            <Row>
                <Col className="col-3">
                    Payment status created at
                </Col>
                <Col className="col-9">
                    <FormattedDate value={paymentEvent.createdAt} year="numeric" month="2-digit"
                                   day="2-digit" hour="2-digit" minute="2-digit" second="2-digit"/>
                </Col>
            </Row>

            {paymentEvent.amount && <Row>
                <Col className="col-3">
                    Betrag
                </Col>
                <Col className="col-9">
                    <Euro centAmount={paymentEvent.amount}/>
                </Col>
            </Row>}

            <PayoneProperties/>
            <MollieProperties/>
            <MollieOrderProperties/>
            <Errors/>
        </div>
    )
}

export default PaymentEvent;
