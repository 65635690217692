import React, {Component} from 'react';
import * as yup from 'yup';
import {Formik} from 'formik';
import {Card, Col, Form as BootstrapForm, Row} from 'react-bootstrap';
import {FormikTextInputGroup} from '../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../common/FeedbackButton';
import Footer from "../layout/Footer";
import {connect} from "react-redux";
import ConfirmModal from "../common/modal/ConfirmModal";
import FormikCheckbox from "../common/formik/FormikCheckbox";
import styles from "../form.module.scss";
import debounce from "debounce-promise";
import Api from "../../api";
import {memoize} from '../../form/fieldValidation';
import {LoadingIndicator} from "../common/LoadingIndicator";

const INITIAL_VALUES = {
    name: '',
    ident: '',
};

const FORM_SCHEMA = yup.object().shape({
    name: yup.string().required(),
    ident: yup.string().required().min(1).max(5).default(''),
});

const api = new Api();
const getPricingClassByIdentDebounced = debounce(ident => api.getPricingClassByIdent(ident), 500);

class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isArchived: props.pricingClass.archived,
        }

    }

    render() {
        let {onSubmit, submitPending, pricingClass, helpTextsVisible} = this.props;

        const validateIdent = memoize({
            initalValue: pricingClass.ident,
            async validate(ident) {
                if (!ident) {
                    return;
                }

                const responsePricingCategory = await getPricingClassByIdentDebounced(ident);
                if (responsePricingCategory && pricingClass.id !== responsePricingCategory.id) {
                    return 'Kurzbezeichnung bereits vergeben';
                }
            }
        });

        const ArchivedStatus = ({setFieldValue}) => {
            const confirmChangeStatus = () => () => {
                let newStatus = !this.state.isArchived
                this.setState({isArchived: newStatus});
                setFieldValue('archived', newStatus);
            };
            return (
                <ConfirmModal title="Status ändern"
                              body={(this.state.isArchived) ? `Preisklasse wieder reaktivieren?`: `Preisklasse ins Archiv verschieben?`}
                              cancelLabel="Abbrechen"
                              confirmLabel="Bestätigen"
                >
                    {confirm => (
                        <FormikCheckbox type="checkbox"
                                             id="archived"
                                             label="Preisklasse archivieren"
                                             onChange={(e) => confirm(confirmChangeStatus(e))}
                                             name="archived"
                                             checked={this.state.isArchived}
                        />
                    )}
                </ConfirmModal>
            )
        }
        return <Formik initialValues={{...INITIAL_VALUES, ...pricingClass}}
                       validationSchema={FORM_SCHEMA}
                       onSubmit={onSubmit}
        >
            {formik => {
                return (
                    <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                        <div className={styles.formBox}>
                            <h2 className={styles.formTitle}>Allgemein</h2>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Name*" name="name" testid="name"/>
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Kurzbezeichnung*" name="ident" testid="ident" validate={validateIdent}/>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Öffentliche Bezeichnung (deutsch)" name="publicName.de" testid="name_german"
                                                            helpTextsVisible={helpTextsVisible} helpText="Wird auf dem Ticket angezeigt."/>
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Öffentliche Bezeichnung (englisch)" name="publicName.en" testid="name_english"
                                                            helpTextsVisible={helpTextsVisible} helpText="Wird auf dem Ticket angezeigt."/>
                                </Col>
                            </Row>
                            <ArchivedStatus setFieldValue={formik.setFieldValue}/>
                        </div>
                        <Footer>
                            <FeedbackButton to={`/base/pricing-class`}>
                                Abbrechen
                            </FeedbackButton>
                            <FeedbackButton
                                type="submit"
                                busy={submitPending}
                            >
                                Speichern
                            </FeedbackButton>
                        </Footer>
                    </form>
                )
            }}
        </Formik>
    }
}

Form.propTypes = {
    onSubmit: PropTypes.any,
    submitPending: PropTypes.any,
    pricingClass: PropTypes.any,
    requestErrors: PropTypes.any
};


const mapStateToProps = (state, props) => {

    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps)(Form);
