
export interface ICOUNTRIES {
    name: string;
    id: string;
}

export const COUNTRIES: ICOUNTRIES[] = [
    {
        id: 'DE',
        name: `Deutschland`
    },
    {
        id: 'AT',
        name: `Österreich`
    },
    {
        id: 'CH',
        name: `Schweiz`
    },
    {
        id: 'AF',
        name: `Afghanistan`
    },
    {
        id: 'EG',
        name: `Ägypten`
    },
    {
        id: 'AL',
        name: `Albanien`
    },
    {
        id: 'DZ',
        name: `Algerien`
    },
    {
        id: 'AD',
        name: `Andorra`
    },
    {
        id: 'AO',
        name: `Angola`
    },
    {
        id: 'AI',
        name: `Anguilla`
    },
    {
        id: 'AQ',
        name: `Antarktis`
    },
    {
        id: 'AG',
        name: `Antigua und Barbuda`
    },
    {
        id: 'GQ',
        name: `Äquatorial Guinea`
    },
    {
        id: 'AR',
        name: `Argentinien`
    },
    {
        id: 'AM',
        name: `Armenien`
    },
    {
        id: 'AW',
        name: `Aruba`
    },
    {
        id: 'AZ',
        name: `Aserbaidschan`
    },
    {
        id: 'ET',
        name: `Äthiopien`
    },
    {
        id: 'AU',
        name: `Australien`
    },
    {
        id: 'BS',
        name: `Bahamas`
    },
    {
        id: 'BH',
        name: `Bahrain`
    },
    {
        id: 'BD',
        name: `Bangladesh`
    },
    {
        id: 'BB',
        name: `Barbados`
    },
    {
        id: 'BE',
        name: `Belgien`
    },
    {
        id: 'BZ',
        name: `Belize`
    },
    {
        id: 'BJ',
        name: `Benin`
    },
    {
        id: 'BM',
        name: `Bermudas`
    },
    {
        id: 'BT',
        name: `Bhutan`
    },
    {
        id: 'MM',
        name: `Birma`
    },
    {
        id: 'BO',
        name: `Bolivien`
    },
    {
        id: 'BA',
        name: `Bosnien-Herzegowina`
    },
    {
        id: 'BW',
        name: `Botswana`
    },
    {
        id: 'BV',
        name: `Bouvet Inseln`
    },
    {
        id: 'BR',
        name: `Brasilien`
    },
    {
        id: 'IO',
        name: `Britisch-Indischer Ozean`
    },
    {
        id: 'BN',
        name: `Brunei`
    },
    {
        id: 'BG',
        name: `Bulgarien`
    },
    {
        id: 'BF',
        name: `Burkina Faso`
    },
    {
        id: 'BI',
        name: `Burundi`
    },
    {
        id: 'CL',
        name: `Chile`
    },
    {
        id: 'CN',
        name: `China`
    },
    {
        id: 'CX',
        name: `Christmas Island`
    },
    {
        id: 'CK',
        name: `Cook Inseln`
    },
    {
        id: 'CR',
        name: `Costa Rica`
    },
    {
        id: 'DK',
        name: `Dänemark`
    },
    {
        id: 'DJ',
        name: `Djibuti`
    },
    {
        id: 'DM',
        name: `Dominika`
    },
    {
        id: 'DO',
        name: `Dominikanische Republik`
    },
    {
        id: 'EC',
        name: `Ecuador`
    },
    {
        id: 'SV',
        name: `El Salvador`
    },
    {
        id: 'CI',
        name: `Elfenbeinküste`
    },
    {
        id: 'ER',
        name: `Eritrea`
    },
    {
        id: 'EE',
        name: `Estland`
    },
    {
        id: 'FK',
        name: `Falkland Inseln`
    },
    {
        id: 'FO',
        name: `Färöer Inseln`
    },
    {
        id: 'FJ',
        name: `Fidschi`
    },
    {
        id: 'FI',
        name: `Finnland`
    },
    {
        id: 'FR',
        name: `Frankreich`
    },
    {
        id: 'GF',
        name: `französisch Guyana`
    },
    {
        id: 'PF',
        name: `Französisch Polynesien`
    },
    {
        id: 'TF',
        name: `Französisches Süd-Territorium`
    },
    {
        id: 'GA',
        name: `Gabun`
    },
    {
        id: 'GM',
        name: `Gambia`
    },
    {
        id: 'GE',
        name: `Georgien`
    },
    {
        id: 'GH',
        name: `Ghana`
    },
    {
        id: 'GI',
        name: `Gibraltar`
    },
    {
        id: 'GD',
        name: `Grenada`
    },
    {
        id: 'GR',
        name: `Griechenland`
    },
    {
        id: 'GL',
        name: `Grönland`
    },
    {
        id: 'GB',
        name: `Vereinigtes Königreich`
    },
    {
        id: 'GP',
        name: `Guadeloupe`
    },
    {
        id: 'GU',
        name: `Guam`
    },
    {
        id: 'GT',
        name: `Guatemala`
    },
    {
        id: 'GN',
        name: `Guinea`
    },
    {
        id: 'GW',
        name: `Guinea Bissau`
    },
    {
        id: 'GY',
        name: `Guyana`
    },
    {
        id: 'HT',
        name: `Haiti`
    },
    {
        id: 'HM',
        name: `Heard und McDonald Islands`
    },
    {
        id: 'HN',
        name: `Honduras`
    },
    {
        id: 'HK',
        name: `Hong Kong`
    },
    {
        id: 'IN',
        name: `Indien`
    },
    {
        id: 'ID',
        name: `Indonesien`
    },
    {
        id: 'IQ',
        name: `Irak`
    },
    {
        id: 'IR',
        name: `Iran`
    },
    {
        id: 'IE',
        name: `Irland`
    },
    {
        id: 'IS',
        name: `Island`
    },
    {
        id: 'IL',
        name: `Israel`
    },
    {
        id: 'IT',
        name: `Italien`
    },
    {
        id: 'JM',
        name: `Jamaika`
    },
    {
        id: 'JP',
        name: `Japan`
    },
    {
        id: 'YE',
        name: `Jemen`
    },
    {
        id: 'JO',
        name: `Jordanien`
    },
    {
        id: 'YU',
        name: `Jugoslawien`
    },
    {
        id: 'KY',
        name: `Kaiman Inseln`
    },
    {
        id: 'KH',
        name: `Kambodscha`
    },
    {
        id: 'CM',
        name: `Kamerun`
    },
    {
        id: 'CA',
        name: `Kanada`
    },
    {
        id: 'CV',
        name: `Kap Verde`
    },
    {
        id: 'KZ',
        name: `Kasachstan`
    },
    {
        id: 'KE',
        name: `Kenia`
    },
    {
        id: 'KG',
        name: `Kirgisistan`
    },
    {
        id: 'KI',
        name: `Kiribati`
    },
    {
        id: 'CC',
        name: `Kokosinseln`
    },
    {
        id: 'CO',
        name: `Kolumbien`
    },
    {
        id: 'KM',
        name: `Komoren`
    },
    {
        id: 'CG',
        name: `Kongo`
    },
    {
        id: 'CD',
        name: `Kongo, Demokratische Republik`
    },
    {
        id: 'HR',
        name: `Kroatien`
    },
    {
        id: 'CU',
        name: `Kuba`
    },
    {
        id: 'KW',
        name: `Kuwait`
    },
    {
        id: 'LA',
        name: `Laos`
    },
    {
        id: 'LS',
        name: `Lesotho`
    },
    {
        id: 'LV',
        name: `Lettland`
    },
    {
        id: 'LB',
        name: `Libanon`
    },
    {
        id: 'LR',
        name: `Liberia`
    },
    {
        id: 'LY',
        name: `Libyen`
    },
    {
        id: 'LI',
        name: `Liechtenstein`
    },
    {
        id: 'LT',
        name: `Litauen`
    },
    {
        id: 'LU',
        name: `Luxemburg`
    },
    {
        id: 'MO',
        name: `Macao`
    },
    {
        id: 'MG',
        name: `Madagaskar`
    },
    {
        id: 'MW',
        name: `Malawi`
    },
    {
        id: 'MY',
        name: `Malaysia`
    },
    {
        id: 'MV',
        name: `Malediven`
    },
    {
        id: 'ML',
        name: `Mali`
    },
    {
        id: 'MT',
        name: `Malta`
    },
    {
        id: 'MP',
        name: `Marianen`
    },
    {
        id: 'MA',
        name: `Marokko`
    },
    {
        id: 'MH',
        name: `Marshall Inseln`
    },
    {
        id: 'MQ',
        name: `Martinique`
    },
    {
        id: 'MR',
        name: `Mauretanien`
    },
    {
        id: 'MU',
        name: `Mauritius`
    },
    {
        id: 'YT',
        name: `Mayotte`
    },
    {
        id: 'MK',
        name: `Mazedonien`
    },
    {
        id: 'MX',
        name: `Mexiko`
    },
    {
        id: 'FM',
        name: `Mikronesien`
    },
    {
        id: 'MZ',
        name: `Mocambique`
    },
    {
        id: 'MD',
        name: `Moldavien`
    },
    {
        id: 'MC',
        name: `Monaco`
    },
    {
        id: 'MN',
        name: `Mongolei`
    },
    {
        id: 'MS',
        name: `Montserrat`
    },
    {
        id: 'NA',
        name: `Namibia`
    },
    {
        id: 'NR',
        name: `Nauru`
    },
    {
        id: 'NP',
        name: `Nepal`
    },
    {
        id: 'NC',
        name: `Neukaledonien`
    },
    {
        id: 'NZ',
        name: `Neuseeland`
    },
    {
        id: 'NI',
        name: `Nicaragua`
    },
    {
        id: 'NL',
        name: `Niederlande`
    },
    {
        id: 'AN',
        name: `Niederländische Antillen`
    },
    {
        id: 'NE',
        name: `Niger`
    },
    {
        id: 'NG',
        name: `Nigeria`
    },
    {
        id: 'NU',
        name: `Niue`
    },
    {
        id: 'KP',
        name: `Nord Korea`
    },
    {
        id: 'NF',
        name: `Norfolk Inseln`
    },
    {
        id: 'NO',
        name: `Norwegen`
    },
    {
        id: 'OM',
        name: `Oman`
    },
    {
        id: 'PK',
        name: `Pakistan`
    },
    {
        id: 'PS',
        name: `Palästina`
    },
    {
        id: 'PW',
        name: `Palau`
    },
    {
        id: 'PA',
        name: `Panama`
    },
    {
        id: 'PG',
        name: `Papua Neuguinea`
    },
    {
        id: 'PY',
        name: `Paraguay`
    },
    {
        id: 'PE',
        name: `Peru`
    },
    {
        id: 'PH',
        name: `Philippinen`
    },
    {
        id: 'PN',
        name: `Pitcairn`
    },
    {
        id: 'PL',
        name: `Polen`
    },
    {
        id: 'PT',
        name: `Portugal`
    },
    {
        id: 'PR',
        name: `Puerto Rico`
    },
    {
        id: 'QA',
        name: `Qatar`
    },
    {
        id: 'RE',
        name: `Reunion`
    },
    {
        id: 'RW',
        name: `Ruanda`
    },
    {
        id: 'RO',
        name: `Rumänien`
    },
    {
        id: 'RU',
        name: `Russland`
    },
    {
        id: 'LC',
        name: `Saint Lucia`
    },
    {
        id: 'ZM',
        name: `Sambia`
    },
    {
        id: 'AS',
        name: `Samoa`
    },
    {
        id: 'WS',
        name: `Samoa`
    },
    {
        id: 'SM',
        name: `San Marino`
    },
    {
        id: 'ST',
        name: `Sao Tome`
    },
    {
        id: 'SA',
        name: `Saudi Arabien`
    },
    {
        id: 'SE',
        name: `Schweden`
    },
    {
        id: 'SN',
        name: `Senegal`
    },
    {
        id: 'SC',
        name: `Seychellen`
    },
    {
        id: 'SL',
        name: `Sierra Leone`
    },
    {
        id: 'SG',
        name: `Singapur`
    },
    {
        id: 'SK',
        name: `Slowakei -slowakische Republik-`
    },
    {
        id: 'SI',
        name: `Slowenien`
    },
    {
        id: 'SB',
        name: `Solomon Inseln`
    },
    {
        id: 'SO',
        name: `Somalia`
    },
    {
        id: 'GS',
        name: `South Georgia, South Sandwich Isl.`
    },
    {
        id: 'ES',
        name: `Spanien`
    },
    {
        id: 'LK',
        name: `Sri Lanka`
    },
    {
        id: 'SH',
        name: `St. Helena`
    },
    {
        id: 'KN',
        name: `St. Kitts Nevis Anguilla`
    },
    {
        id: 'PM',
        name: `St. Pierre und Miquelon`
    },
    {
        id: 'VC',
        name: `St. Vincent`
    },
    {
        id: 'KR',
        name: `Süd Korea`
    },
    {
        id: 'ZA',
        name: `Südafrika`
    },
    {
        id: 'SD',
        name: `Sudan`
    },
    {
        id: 'SR',
        name: `Surinam`
    },
    {
        id: 'SJ',
        name: `Svalbard und Jan Mayen Islands`
    },
    {
        id: 'SZ',
        name: `Swasiland`
    },
    {
        id: 'SY',
        name: `Syrien`
    },
    {
        id: 'TJ',
        name: `Tadschikistan`
    },
    {
        id: 'TW',
        name: `Taiwan`
    },
    {
        id: 'TZ',
        name: `Tansania`
    },
    {
        id: 'TH',
        name: `Thailand`
    },
    {
        id: 'TP',
        name: `Timor`
    },
    {
        id: 'TG',
        name: `Togo`
    },
    {
        id: 'TK',
        name: `Tokelau`
    },
    {
        id: 'TO',
        name: `Tonga`
    },
    {
        id: 'TT',
        name: `Trinidad Tobago`
    },
    {
        id: 'TD',
        name: `Tschad`
    },
    {
        id: 'CZ',
        name: `Tschechische Republik`
    },
    {
        id: 'TN',
        name: `Tunesien`
    },
    {
        id: 'TR',
        name: `Türkei`
    },
    {
        id: 'TM',
        name: `Turkmenistan`
    },
    {
        id: 'TC',
        name: `Turks und Kaikos Inseln`
    },
    {
        id: 'TV',
        name: `Tuvalu`
    },
    {
        id: 'UG',
        name: `Uganda`
    },
    {
        id: 'UA',
        name: `Ukraine`
    },
    {
        id: 'HU',
        name: `Ungarn`
    },
    {
        id: 'UY',
        name: `Uruguay`
    },
    {
        id: 'UZ',
        name: `Usbekistan`
    },
    {
        id: 'VU',
        name: `Vanuatu`
    },
    {
        id: 'VA',
        name: `Vatikan`
    },
    {
        id: 'VE',
        name: `Venezuela`
    },
    {
        id: 'AE',
        name: `Vereinigte Arabische Emirate`
    },
    {
        id: 'US',
        name: `Vereinigte Staaten von Amerika`
    },
    {
        id: 'VN',
        name: `Vietnam`
    },
    {
        id: 'VG',
        name: `Virgin Island (Brit.)`
    },
    {
        id: 'VI',
        name: `Virgin Island (USA)`
    },
    {
        id: 'WF',
        name: `Wallis et Futuna`
    },
    {
        id: 'BY',
        name: `Weissrussland`
    },
    {
        id: 'EH',
        name: `Westsahara`
    },
    {
        id: 'CF',
        name: `Zentralafrikanische Republik`
    },
    {
        id: 'ZW',
        name: `Zimbabwe`
    },
    {
        id: 'CY',
        name: `Zypern`
    }
]