import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import {Formik} from 'formik';
import {Col, Row} from 'react-bootstrap';
import {FormikTextInputGroup} from '../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../common/FeedbackButton';
import Footer from "../layout/Footer";
import {connect} from "react-redux";
import FormikSelect from "../common/formik/FormikSelect";
import FormikCheckbox from "../common/formik/FormikCheckbox";
import Api from '../../api';
import styles from "../form.module.scss";
import { FormHelperText } from '@mui/material';
import authService from "../auth/authService";

const api = new Api();
const SALUTATION_OPTIONS = [
    {id: 'm', name: 'Herr'},
    {id: 'f', name: 'Frau'},
    {id: 'd', name: 'Divers'},
]

const INITIAL_VALUES = {
    salutation: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    enabled: true,
    isPasswordTemporary: true,
    tenantId: '',
    roles: []
};

const specialChars = /[()[\]{}?!$%&/=*+~,.:;<>-_]/;

const FORM_SCHEMA = yup.object().shape({
    salutation: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),

    password: yup.string()
        .min(8, 'Ihre Eingabe erfüllt nicht die Mindestkriterien')
        .matches(specialChars, 'Ihre Eingabe erfüllt nicht die Mindestkriterien')
        .notOneOf([yup.ref('email')], 'Ihre Eingabe erfüllt nicht die Mindestkriterien')
        .nullable(),

    enabled: yup.boolean(),
    isPasswordTemporary: yup.boolean(),
    tenantId: yup.string().when('roles', (roles, schema) => {
        if (!authService.hasRole('ROLE_ADMIN_SUPER_ADMIN') || (roles && roles[0].includes('ROLE_ADMIN_SUPER_ADMIN'))) {
            return schema.nullable();
        } else {
            return schema.required('Tenant ID is required');
        }
    }),
    roles: yup.array().required().min(1, 'At least one role must be selected'),
    email: yup.string().email('Invalid email').required('Email is required')
});

const Form = ({ onSubmit, submitPending, backendUser }) => {
    const [tenantsBackendUser, setTenantsBackendUser] = useState([]);
    const [rolesBackendUser, setRolesBackendUser] = useState([]);

    useEffect(() => {
        api.getBackendUserFormOptions().then(({ roles, tenants }) => {
            setRolesBackendUser(roles);
            setTenantsBackendUser(tenants);
        });
    }, []);
    const isSuperAdmin = authService.hasRole('admin_super_admin');

    return (
        <Formik
            initialValues={{...INITIAL_VALUES, ...backendUser}}
            validationSchema={FORM_SCHEMA}
            onSubmit={onSubmit}
        >
            {({ errors, touched, values, handleSubmit, setFieldValue}) => {
                const isInvalid = touched && errors && errors.roles;
                return (
                    <form onSubmit={handleSubmit} className={styles.formCotainer}>
                        <div className={styles.formBox}>
                            <h2 className={styles.formTitle}>Allgemein</h2>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikSelect
                                        label="Anrede*"
                                        options={SALUTATION_OPTIONS}
                                        name='salutation'
                                        isShowEmptyValue={false}
                                    />
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Nachname*" name="lastName" testid="lastName" />
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Vorname*" name="firstName" testid="firstName" />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="E-Mail*" name='email' />
                                </Col>
                                {isSuperAdmin && <Col className="col-md-4">
                                    <FormikSelect
                                        label="Mandant*"
                                        options={tenantsBackendUser}
                                        name='tenantId'
                                        isShowEmptyValue={false}
                                    />
                                </Col>}
                                <Col className="col-md-4">
                                    <FormikTextInputGroup
                                        type="password"
                                        label="Passwort"
                                        name="password"
                                        testid="password"
                                        placeholder="min 8 Zeichen und ein Sonderzeichen"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-2">
                                    <FormikCheckbox label="Benutzer wird aktiviert" name="enabled"/>
                                </Col>
                                <Col className="col-md-6">
                                </Col>
                                {values.password && <Col className="col-md-4">
                                    <FormikCheckbox label="Benutzer muss das Passwort beim nächsten Anmelden ändern"
                                                    name="isPasswordTemporary"/>
                                </Col>}
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <label className={styles.formLabel}>Rollen*</label>
                                    {rolesBackendUser.map(item => (
                                        <div key={item.id}>
                                            <FormikCheckbox
                                                label={item.name}
                                                name={item.id}
                                                checked={values.roles.includes(item.id)}
                                                onChange={(e) => {
                                                    const newRoles = e.target.checked ? [
                                                        ...values.roles,
                                                        item.id
                                                    ] : values.roles.filter(id => id !== item.id);

                                                    setFieldValue('roles', newRoles);
                                                }}
                                            />
                                        </div>
                                    ))}
                                    {isInvalid && <FormHelperText sx={{ bottom: '-8px' }} className={isInvalid ? 'Mui-error' : ''} data-testid="error">{errors.roles}</FormHelperText>}
                                </Col>
                            </Row>
                        </div>
                        <Footer>
                            <FeedbackButton to={`/base/backend-user`}>
                                Abbrechen
                            </FeedbackButton>
                            <FeedbackButton
                                type="submit"
                                busy={submitPending}
                            >
                                Speichern
                            </FeedbackButton>
                        </Footer>
                    </form>
                )
            }}
        </Formik>
    )
};

Form.propTypes = {
    onSubmit: PropTypes.any,
    submitPending: PropTypes.any,
    seatingType: PropTypes.any,
    requestErrors: PropTypes.any
};


const mapStateToProps = (state, props) => {

    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps)(Form);
