import React, {useCallback, useState} from 'react';
import PropTypes from "prop-types";
import styles from "../layoutEditor.module.scss";
import Api from "../../../api";
import FeedbackButton from '../../common/FeedbackButton';
import FormModal from '../../common/modal/FormModal';
import ConnectedListView from '../../common/listView/ConnectedListView';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const api = new Api();

const Image = ({element, editModeEnabled, updateElement}) => {
    const INITIAL_VALUES = {images: [], nextCursor: '', pending: false};

    const [showModal, setShowModal] = useState(false);
    const [refreshListView, setRefreshListView] = useState(false);

    const onHandleRefresh = () => {
        setRefreshListView(prevState => !prevState);
    }

    const handleCancelModal = () => {
        setShowModal(false);
    };

    const selectImage = useCallback((media) => {
        updateElement({
            ...element,
            media
        });

        handleCancelModal();
    })

    const ImageElement = () => {
        if (element.media?.url) {
            return <img src={element.media?.url}
                        onClick={() => setShowModal(true)}
                        style={{
                            maxWidth: element.width + "px",
                            maxHeight: element.height + "px",
                        }} alt={element.media?.url}/>
        } else {
            return <div className={styles.AssetIcon} onClick={() => setShowModal(true)}>
                <i className="fa fa-picture-o fa-5x" aria-hidden="true"></i>
            </div>
        }
    };

    return <div className={`${styles.Image} ${styles.LayoutElementInner} ${!editModeEnabled ? "interactive" : ""}`}>
        <FormModal
            show={showModal}
            title={'Bild wählen'}
            initialValues={INITIAL_VALUES}
            className={styles.tiketsLayoutModalWrap}
            showIconRefresh={true}
            onHandleRefresh={onHandleRefresh}
            validationSchema={null}
            onCancel={handleCancelModal}
            isCustomFooterElems={true}
            renderFooterElems={() => (
                <FeedbackButton
                    to={`/base/media/`}
                    icon={<OpenInNewIcon />}
                    variant='contained'
                    target='_blank'
                >
                    Zur Medienverwaltung
                </FeedbackButton>
            )}
        >
        {() => {
            return (
                <>
                    <ConnectedListView
                        endpoint={'listingView/media'}
                        testid="link_new_media"
                        className='pt-0'
                        isShowSelectionWithBatchActions={false}
                        refreshListView={refreshListView}
                    >
                        {({ item }) => (
                            <>
                                <FeedbackButton onClick={() => selectImage(item)}>auswählen</FeedbackButton>
                            </>
                        )}
                    </ConnectedListView>
                </>
            )
        }}
        </FormModal>
        <ImageElement/>
    </div>;
};

Image.propTypes = {
    element: PropTypes.object.isRequired,
    editModeEnabled: PropTypes.bool,
    updateElement: PropTypes.func.isRequired,
};

export default Image;
