import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {dismiss, resend} from '../../../state/success/actions';
import { Alert } from "react-bootstrap";
import ErrorIcon from '@mui/icons-material/Error';
import {withRouter} from "react-router-dom";

/**
 * Redux-Connected for global messages.
 *
 * @constructor
 */

function GlobalMessagesList({globalMessagesList, dismiss, resend, ...props}) {
    let [currentLocation, setCurrentLocation] = useState(props.location.pathname);
    let [timeoutIds, setTimeoutIds] = useState([]);

    useEffect(() => {
        let newTimeoutIds = [];
        globalMessagesList.map((message, i) => {
            if (!timeoutIds.includes(message.id)) {
                setTimeout(() => {
                    dismiss({globalMessagesList: message});
                }, 5000);
                newTimeoutIds.push(message.id);
            }
        });

        if (newTimeoutIds.length > 0) {
            setTimeoutIds([...timeoutIds, ...newTimeoutIds]);
        }

        if (globalMessagesList.length === 0 && timeoutIds.length > 0) {
            setTimeoutIds([]);
        }

        if (currentLocation !== props.location.pathname) {
            setCurrentLocation(props.location.pathname);

            globalMessagesList.map((message, i) => {
                dismiss({globalMessagesList: message});

                if (message.isCreate) {
                    resend({globalMessagesList: {...message, isCreate: false}});
                }
            })
        }
    }, [dismiss, resend, globalMessagesList, currentLocation, setCurrentLocation, props.location.pathname, timeoutIds]);

    return (
        <div className="error-message">
            {globalMessagesList.map((message, i) => {
                return (                    
                    <Alert
                        key={i}
                        className="text-center"
                        variant={message.variant}
                        onClose={() => dismiss({globalMessagesList: message})}
                        dismissible
                    >
                        <ErrorIcon fontSize='small' />&ensp;<span>{message.message}</span>
                    </Alert>
                )
            })}
        </div>

    );
}

GlobalMessagesList.propTypes = {
    globalMessagesList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        variant: PropTypes.string,
        message: PropTypes.string,
        isCreate: PropTypes.bool
    })).isRequired
};


export default withRouter(connect(({globalMessagesList}) => ({globalMessagesList}), {
    dismiss,
    resend
})(GlobalMessagesList));
