import React, {Component} from 'react';
import Form from "./Form";
import {connect} from "react-redux";
import {LoadingIndicator} from "../../../common/LoadingIndicator";
import {Col, Row} from "react-bootstrap";
import * as venuePlan from "../../../../state/entities/venuePlan";
import * as block from "../../../../state/entities/block";
import List from "./seat/List";
import Breadcrumbs from "../../../common/breadcrumbs/Breadcrumbs";

class Edit extends Component {

    componentDidMount() {
        this.props.loadVenuePlan(this.props.venuePlanId);
        this.props.loadBlock(this.props.blockId);
    }

    onSubmit = (block, {setErrors}) => {
        if(block.label.en === "" && block.label.de !== ""){
            block.label.en = block.label.de;
        }
        this.props.saveBlock(block, setErrors);
    };

    render() {
        const { block, saving, venuePlan, blockId } = this.props;

        if (!block || !venuePlan) {
            return <LoadingIndicator/>;
        }

        if(block.blockType === "seating") {
            return <>
                <Row className="my-4" data-testid="breadcrumb">
                    <Breadcrumbs
                        entity={["Spielstätte", "Saalplan", "Blockgruppe", "Block", "Sitzplätze"]}
                        endpoints = {['venue/breadcrumb', `venue/${block.venue}/venue_plan/breadcrumb`, `venue_plan/${block.venuePlan}/block_group/breadcrumb`, `venue_plan/${block.venuePlan}/block/breadcrumb`, `block/${block.id}/seat/breadcrumb`]}
                        title = {["Blockgruppen", "Blöcke", "Sitzplätze"]}
                        links={['/base/venue/', `/base/venue/${block.venue}/venue-plan/`, `/base/venue-plan/${block.venuePlan}/block-group/`, `/base/venue-plan/${block.venuePlan}/block/`, `/base/block/${block.id}/seat/`]}
                        active={[block.venue, block.venuePlan, 0, block.id]}
                    />
                </Row>
                <Row className="mb-4 mt-4">
                    <Col className="pt-3 col-sm-8">
                        <h1>Bearbeiten des Sitzplatzblocks <em>{block.label.de}</em></h1>
                    </Col>
                </Row>
                <Form block={block} venuePlanId={block.venuePlan} venue={venuePlan.venue} onSubmit={this.onSubmit} submitPending={saving} type="seating" />
                <List block={block} blockId={blockId}/>
            </>;
        }else{
            return <>
                <Row className="my-4">
                    <Breadcrumbs
                        entity={["Spielstätte", "Saalplan", "Blockgruppe", "Block"]}
                        endpoints = {['venue/breadcrumb', `venue/${block.venue}/venue_plan/breadcrumb`, `venue_plan/${block.venuePlan}/block_group/breadcrumb`, `venue_plan/${block.venuePlan}/block/breadcrumb`]}
                        title = {["Blockgruppen", "Blöcke"]}
                        links={['/base/venue/', `/base/venue/${block.venue}/venue-plan/`, `/base/venue-plan/${block.venuePlan}/block-group/`, `/base/venue-plan/${block.venuePlan}/block/`]}
                        active={[block.venue, block.venuePlan, 0, block.id]}
                    />
                </Row>
                <Row>
                    <Col>
                        <h1>Bearbeiten des Stehplatzblocks <em>{block.label.de}</em> für den Saalplan <em>{venuePlan.name}</em></h1>
                    </Col>
                </Row>
                <Form block={block} venuePlanId={block.venuePlan} venue={venuePlan.venue} onSubmit={this.onSubmit} submitPending={saving} type="standing" />
            </>;
        }
    }
}

const mapStateToProps = (state, props) => {
    const venuePlanId = props.match.params.vpid;
    const blockId = props.match.params.bid;

    return {
        blockId,
        venuePlanId,
        loading: state.entities.block.loading[blockId],
        saving: state.entities.block.saving[blockId],
        block: state.entities.block.byId[blockId],
        venuePlan: state.entities.venuePlan.byId[venuePlanId],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadBlock: id => dispatch(block.actions.load({id})),
        saveBlock: entity => dispatch(block.actions.save({entity})),
        loadVenuePlan: id => dispatch(venuePlan.actions.load({id})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
