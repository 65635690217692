import React from 'react';
import {Switch} from 'react-router-dom';

import List from './List';
import Edit from './Edit';
import Create from './Create';
import {Container} from 'react-bootstrap';
import SecureRoute from "../auth/SecureRoute";
import EditVenuePlan from './venuePlan/Edit';
import CreateVenuePlan from './venuePlan/Create';
import CreateZukoZone from './zukoZone/Create';
import EditZukoZone from './zukoZone/Edit';
import EditBlockGroup from './venuePlan/blockGroup/Edit';
import CreateBlockGroup from './venuePlan/blockGroup/Create';
import EditBlock from './venuePlan/block/Edit';
import CreateBlock from './venuePlan/block/Create';
import EditSeat from './venuePlan/block/seat/Edit';
import CreateSeat from './venuePlan/block/seat/Create';
import SeatingEditor from '../../seating/editor/SeatingEditor';


const Routing = () => {
    return (
        <>
        <Container className={'mt-1'}>
            <Switch>
                <SecureRoute exact path="/base/venue" component={List}/>
                <SecureRoute exact path="/base/venue/create" component={Create}/>
                <SecureRoute exact path="/base/venue/:id" component={Edit}/>
                <SecureRoute exact path="/base/venue/:id/venue-plan/create" component={CreateVenuePlan}/>
                <SecureRoute exact path="/base/venue/:id/venue-plan/:vpid" component={EditVenuePlan}/>
                <SecureRoute exact path="/base/venue/:id/zuko-zone/create" component={CreateZukoZone}/>
                <SecureRoute exact path="/base/venue/:vid/zuko-zone/:zid" component={EditZukoZone}/>
                <SecureRoute exact path="/base/venue-plan/:vpid/block-group/create" component={CreateBlockGroup}/>
                <SecureRoute exact path="/base/venue-plan/:vpid/block-group/:bgid" component={EditBlockGroup}/>
                <SecureRoute exact path="/base/venue-plan/:vpid/block/standing/create" render={(props) => <CreateBlock {...{...props, isSeating: false}}/>}/>
                <SecureRoute exact path="/base/venue-plan/:vpid/block/standing/:bid" render={(props) => <EditBlock {...{...props, isSeating: false}}/>}/>
                <SecureRoute exact path="/base/venue-plan/:vpid/block/seating/create" render={(props) => <CreateBlock {...{...props, isSeating: true}}/>}/>
                <SecureRoute exact path="/base/venue-plan/:vpid/block/seating/:bid" render={(props) => <EditBlock {...{...props, isSeating: true}}/>}/>
                <SecureRoute exact path="/base/block/:bid/seat/create" component={CreateSeat}/>
                <SecureRoute exact path="/base/block/:bid/seat/:sid" component={EditSeat}/>
            </Switch>
        </Container>

        </>
    )
};

export default Routing;
