import { createTheme } from '@mui/material/styles';

export const GlobalThemeMui = createTheme({
    typography: {
        fontFamily: 'inherit',
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                }
            },
        },
        MuiPaper: {
            styleOverrides: {
                container: {
                    ['& > .MuiPaper-root']: {
                        backgroundColor: '#fff'
                    }
                }
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    overflowY: 'visible',
                    borderRadius: 4
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    overflowY: 'visible',
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '0 24px 24px 24px'
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: 18,
                    fontWeight: 700
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    height: 56,
                    paddingLeft: 46,
                    fontSize: 14,

                    ['&:hover, &:focus']: {
                        backgroundColor: 'rgba(16, 157, 241, 0.10)'
                    },

                    '& [data-testid="CheckIcon"]': {
                        position: 'absolute',
                        left: 10
                    },
                }
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: 0
                }
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    paddingBottom: 24,
                    paddingTop: 24,
                    height: '100%',
                    verticalAlign: 'baseline',

                    '&:hover': {
                        '& .MuiFormLabel-root:not(.MuiLabel-noHover):not(.Mui-disabled)': {
                            color: '#109DF1'
                        },

                        '& textarea': {
                            backgroundColor: '#EEF5F9',
                            '& + .MuiInputAdornment-root': {
                                opacity: 1,
                                pointerEvents: 'auto'
                            },
                        }
                    },

                    '& textarea': {
                        border: 'none',
                        padding: '18px',
                        boxSizing: 'border-box',
                        width: '100%',
                        backgroundColor: '#F6F6F6',
                        borderRadius: '4px',
                        fontSize: 14,
                        '& + .MuiInputAdornment-root': {
                            position: 'absolute',
                            right: 22,
                            top: 28,
                        },
                        '&.Mui-error': {
                            boxShadow: 'inset 0 0 0 2px #B3261E'
                        },
                        ['&:hover, &:focus']: {
                            outline: 0,
                        }
                    },

                    '&.MuiFormControl-searchBar': {
                        padding: '0 !important',
                        margin: '25px 28px 15px 28px',
                        maxWidth: '273px',

                        ['& .MuiInputAdornment-positionEnd, & .MuiInputAdornment-positionStart']: {
                            opacity: 1
                        },

                        '& .MuiOutlinedInput-input': {
                            padding: '6px 0',
                            height: 32,
                        },
                        '& .MuiOutlinedInput-root': {
                            backgroundColor: '#fff',
                            paddingLeft: 10,
                            paddingRight: 0
                        },
                        '& .MuiButtonBase-root': {
                            marginRight: 0
                        },
                    },
                },
            }
        },
        MuiCheckbox: {
            padding: 0,
            styleOverrides: {
                root: {
                    color: '#494949',
                    '&.Mui-checked': {
                        color: '#109DF1'
                    },
                    '& .MuiSvgIcon-root': {
                        fontSize: 20
                    }
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: '#494949',
                    '&.Mui-checked': {
                        color: '#109DF1'
                    }
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginLeft: 0,
                    marginBottom: 0
                },
                label: {
                    lineHeight: 1
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    '& [aria-expanded="true"]': {
                        transition: '.3s',
                        backgroundColor: '#EEF5F9',
                        borderRadius: '4px 4px 0px 0px',
                        boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)',
                    },

                    '& .MuiSelect-select': {
                        padding: '18px 10px',
                        '& > .MuiSvgIcon-root': {
                            display: 'none'
                        }
                    },

                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    ['& .MuiOutlinedInput-root']: {
                        paddingTop: 0,
                        paddingBottom: 0,
                        minWidth: 140
                    },

                    ['&.MuiAutocomplete-error .MuiOutlinedInput-root']: {
                        boxShadow: 'inset 0 0 0 2px #B3261E'
                    }
                },
                listbox: {
                    padding: 0
                },
                popper: {
                    ['& .MuiMenuItem-root']: {
                        paddingLeft: 46
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: '#F6F6F6',
                    fontSize: 14,
                    color: '#1D1D1F',
                    marginTop: 'auto',
                    '& .MuiOutlinedInput-input': {
                        padding: '0 10px',
                        height: 56,
                        boxSizing: 'border-box',
                        '&[readonly]': {
                            color: '#7F7F7F',
                        },
                    },

                    '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },

                    '&.Mui-error': {
                        boxShadow: 'inset 0 0 0 2px #B3261E'
                    },

                    ['&.Mui-focused, &:hover:not(.Mui-disabled)']: {
                        backgroundColor: '#EEF5F9'
                    },

                    ['&.Mui-focused, &.Mui-error']: {
                        '.MuiInputAdornment-root': {
                            opacity: 1,
                            pointerEvents: 'auto',
                            transition: '.3s'
                        }
                    },
                    '&.Mui-focused:not(.Mui-error)': {
                        '.MuiSvgIcon-root:not(.MuiSelect-icon)': {
                            color: '#D1D1D1',
                            transition: '.3s',
                            '&:hover': {
                                color: '#494949'
                            }
                        }
                    },

                    'input': {
                        '&::placeholder': {
                          color: '#494949',
                          opacity: 1
                        }
                    }
                }
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: '#1D1D1F',
                    fontSize: 15,
                    transform: 'none !important',
                    position: 'relative !important',
                    whiteSpace: 'wrap !important',
                    fontWeight: 600,
                    marginTop: -24,
                    marginLeft: 10,
                    maxWidth: 'calc(100% - 24px) !important',
                    '&.Mui-focused:not(.MuiLabel-noHover)': {
                        color: '#109DF1',
                        maxWidth: 'calc(100% - 24px)',
                    },
                    '&.Mui-error': {
                        color: '#B3261E'
                    }
                }
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginRight: 10,
                    marginLeft: 10,
                    position: 'absolute',
                    lineHeight: 1,
                    bottom: 0,
                    '&.Mui-error': {
                        color: '#B3261E'
                    }
                }
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    opacity: 0,
                    transition: '.3s',
                    pointerEvents: 'none',

                   ['&:hover, &:focus']: {
                        opacity: 1,
                        pointerEvents: 'auto'
                   }
                }
            },
        },
        MuiIconButton: {
            styleOverrides: {
                edgeStart: {
                    pointerEvents: 'none'
                }
            }
        },
        MuiLoadingButton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#109DF1',
                    textTransform: 'none',
                    padding: '4px 24px',

                    '&:hover': {
                        backgroundColor: '#0181CD'
                    }
                }
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    padding: '0 24px',
                    height: 32,
                    lineHeight: 1
                },
                contained: {
                    backgroundColor: '#109DF1',

                    '&:hover': {
                        backgroundColor: '#0181CD'
                    }
                },
                text: {
                    color: '#109DF1',
                    '&:hover': {
                        color: '#0181CD',
                        backgroundColor: '#EEF5F9'
                    },
                    '&:active': {
                        backgroundColor: '#EEF5F9',
                        boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)'
                    }
                },
                outlined: {
                    textTransform: 'none',
                    color: '#494949',
                    border: '1px solid #494949',

                    ['&:hover, &:focus']: {
                        backgroundColor: '#EEF5F9',
                        color: '#109DF1',
                        border: '1px solid #109DF1',
                    }
                },
                startIcon: {
                    marginRight: 4
                }
            },
        },
        // MuiIconButton: {
        //     styleOverrides: {
        //         root: {
        //             borderRadius: 3,
        //             marginRight: 8,

        //             '& .MuiTouchRipple-child': {
        //                 borderRadius: "inherit"
        //             }
        //         }
        //     }
        // },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    marginBottom: 0,

                    '&.MuiLabel-noHover': {
                        lineHeight: '20px',
                        maxWidth: 'calc(100% - 32px) !important',
                        '&.Mui-focused': {
                            color: '#1D1D1F',
                        },
                    },

                    '&.MuiLabel-topSpace': {
                        marginTop: 0
                    },


                }
            },
        },
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    width: '12px',
                    height: '12px',
                    marginRight: 8,
                    color: '#109DF1',
                }
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: 32,
                    boxShadow: '0px 1px 0px 0px #E9E9E9',
                    padding: '0px 28px'
                },
                flexContainer: {
                    gap: '10px 30px',
                    flexWrap: 'wrap'
                },
                indicator: {
                    backgroundColor: '#0181CD'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    padding: '10px 0px',
                    minHeight: 'inherit',
                    minWidth: 'auto',
                    textTransform: 'none',
                    fontWeight: 600,
                    fontSize: 14,
                    color: '#494949',
                    
                    '&.Mui-selected': {
                        color: '#0181CD'
                    },

                    ['&:hover, &:focus']: {
                        color: '#0181CD',
                        transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        boxShadow: 'inset 0px -2px 0px 0px #0181CD'
                    }
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    minHeight: 32,

                    '& .MuiSvgIcon-root': {
                        fontSize: 16
                    },

                    '& .MuiInputBase-root': {
                        fontWeight: 600,
                        backgroundColor: '#fff',
                        height: 26
                    },
                },
                menuItem: {
                    paddingRight: 0,
                    paddingLeft: 0,
                    justifyContent: 'center'
                },
                displayedRows: {
                    margin: 0,
                    fontSize: 14,
                    fontWeight: 600,
                    color: '#494949',
                },
                selectLabel: {
                    margin: 0,
                    fontSize: 14,
                    fontWeight: 600,
                    color: '#494949',
                },
                toolbar: {
                    minHeight: '32px !important'
                }
            }
        },
    },
});