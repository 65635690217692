import React from 'react';
import { Col, Row } from 'react-bootstrap';

import PropTypes from 'prop-types';
import MultiSelectList from '../common/form/MultiSelectList';
import HelpContent from '../common/help/HelpContent';
import styles from "../form.module.scss";

function FormZuko({ formik, zukoZones, venueEvent }) {

    const zukoZoneSelectFieldMap = {
        'entry': 'Zuko-Zone',
        'label': 'Zutrittszone im Ticketing'
    };

    const handleZukoZoneSelectionChange = (zukoZoneSelection) => {
        const selectedZukoZones = zukoZoneSelection.filter(selection => typeof (selection) === 'object');
        formik.setFieldValue('zukoZones', selectedZukoZones);
    };

    return (
        <div className={styles.formBox}>
            <Row className="mt-3">
                <Col className="col-md-4">
                    <h2 className="font-weight-bold pb-3 mb-3">Automatisch gestattete Zutrittszonen für alle Tickets dieses Events</h2>
                    <HelpContent as="p" className="text-muted">Absolut jedes Ticket für dieses Event erhält Zutritt zu den hier eingetragenen Zutrittszonen. Dieses Feld kann leer bleiben, wenn sie Zutrittszonen an allen Blöcken des Saalplanes eintragen.</HelpContent>
                    <MultiSelectList
                        fieldMap={zukoZoneSelectFieldMap}
                        elements={zukoZones}
                        selected={venueEvent.zukoZones && venueEvent.zukoZones.map(zone => ({id: zone}))}
                        onChange={selection => handleZukoZoneSelectionChange(selection)}
                    />
                </Col>
            </Row>
        </div>
    )
}

FormZuko.propTypes = {
    formik: PropTypes.object,
    zukoZones: PropTypes.array,
    venueEvent: PropTypes.object,
};

export default FormZuko;
