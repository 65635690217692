import {call, put} from 'redux-saga/effects';
import {normalize} from 'normalizr';

/**
 * Generische Saga für das Laden aller Instanzen von Entitäten.
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export default ({actions, resource, schema}) => function* () {
    try {
        const entities = yield call(resource.getAll);
        const response = normalize(entities, [schema]);
        yield put(actions.loadAllSuccess({response}))
    } catch (error) {
        yield put(actions.loadAllError({error}))
    }
}
