import React, {Component} from 'react';
import * as yup from 'yup';
import {Formik} from 'formik';
import {Card, Col, Row} from 'react-bootstrap';
import {FormikTextInputGroup} from '../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../common/FeedbackButton';
import Footer from "../layout/Footer";
import {connect} from "react-redux";
import styles from "../form.module.scss";
import FormikCheckbox from "../common/formik/FormikCheckbox";

const INITIAL_VALUES = {
    name: '',
    label: {
        de: '',
        en: ''
    },
    default: false,
};

const FORM_SCHEMA = yup.object().shape({
    name: yup.string().required(),
});

class Form extends Component {
    render() {
        let {onSubmit, submitPending, seatingType, helpTextsVisible} = this.props;

        return <Formik initialValues={{...INITIAL_VALUES, ...seatingType}}
                       validationSchema={FORM_SCHEMA}
                       onSubmit={onSubmit}
        >
            {formik => {
                return (
                    <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                        <div className={styles.formBox}>
                            <h2 className={styles.formTitle}>Allgemein</h2>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Name*" name="name" testid="name"/>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Öffentliche Bezeichnung (deutsch)" name="label.de" testid="name_german"
                                                            helpTextsVisible={helpTextsVisible} helpText="Der Bestuhlungstyp wird Sitzplätzen zugeordnet."/>
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Öffentliche Bezeichnung (englisch)" name="label.en" testid="name_english"
                                                            helpTextsVisible={helpTextsVisible} helpText="Der Bestuhlungstyp wird Sitzplätzen zugeordnet."/>
                                </Col>
                            </Row>
                            <Row>
                                <FormikCheckbox
                                    id="default"
                                    label="Default"
                                    name="default"
                                />
                            </Row>
                        </div>
                        <Footer>
                            <FeedbackButton to={`/base/seating-type`}>
                                Abbrechen
                            </FeedbackButton>
                            <FeedbackButton
                                type="submit"
                                busy={submitPending}
                            >
                                Speichern
                            </FeedbackButton>
                        </Footer>
                    </form>
                )
            }}
        </Formik>
    }
}

Form.propTypes = {
    onSubmit: PropTypes.any,
    submitPending: PropTypes.any,
    seatingType: PropTypes.any,
    requestErrors: PropTypes.any
};


const mapStateToProps = (state, props) => {

    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps)(Form);
