import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getIn } from 'formik';
import { FormikTextInputGroup } from '../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import styles from "../form.module.scss";
import FormikAsyncTypeaheadInput from "../common/formik/FormikAsyncTypeaheadInput";
import ListingModal from "../common/modal/ListingModal";
import MuiAutocomplete from '../common/MuiAutocomplete/MuiAutocomplete';
import FieldImage from '../common/FieldImage/FieldImage';
import Api from '../../api';
import FormikSelect from "../common/formik/FormikSelect";
import { COUNTRIES } from "../../utils/countries";
import { COUNTRY_CODE } from "../../utils/countryCode";
import { get } from 'lodash';

const api = new Api();

const SALUTATION_OPTIONS = [
    { id: 'm', name: 'Herr' },
    { id: 'f', name: 'Frau' },
    { id: 'd', name: 'Divers' },
]

const FormGeneralData = ({
    formik,
    initialValues,
    validationSchema,
    helpTextsVisible
}) => {
    const [activeFieldName, setActiveFieldName] = useState('');
    const [isShowBatchModal, setIsShowBatchModal] = useState(false);

    const closeModal = () => {
        setIsShowBatchModal(false);
    }

    const onHandleShowModal = (e, fieldName) => {
        e.stopPropagation();
        setIsShowBatchModal(true);
        setActiveFieldName(fieldName);
    }

    const onHanldleRemove = (e, fieldName) => {
        e.stopPropagation();
        formik.setFieldValue(fieldName, null);
    }

    const onHandleChooseImage = (item) => {
        const { id, title, url } = item;
        formik.setFieldValue(activeFieldName, {
            title,
            url,
            id,
        });
        setIsShowBatchModal(false);
    }

    const getValueCountryCode = ( fieldname ) => ({
        name: get(formik.values, `legalContact.${fieldname}.countryCode`),
        id: get(formik.values, `legalContact.${fieldname}.countryCode`)
    });
    
    return (
        <>
            <div className={styles.formBox}>
                <h2 className={styles.formTitle}>Allgemein</h2>
                <Row className="mt-3">
                    <Col className="col-md-4">
                        <FormikTextInputGroup
                            label="Name der Spielstätte*"
                            name="name"
                            testid="name_venue"
                            helpTextsVisible={helpTextsVisible}
                        />
                    </Col>
                    <Col className="col-md-4">
                        <FieldImage
                            values={getIn(formik.values, 'logo')}
                            fieldName='logo'
                            label="Logo/ Bild"
                            onHandleShowModal={onHandleShowModal}
                            onHanldleRemove={onHanldleRemove}
                            helpText="Wird im Eventlisting ausgeliefert"
                        />
                    </Col>
                    <Col className="col-md-4">
                        <FormikAsyncTypeaheadInput
                            id="tags.de"
                            label="Tags Spielstätte (deutsch)"
                            onSearch={() => api.getTagsForVenueByLang('de')}
                            minLength={0}
                            testid="tags.de"
                            multiple={true}
                            arrayOfStrings={true}
                            allowNew={true}
                            helpText="Tags, nach denen gesucht werden kann, um diese Spielstätte zu finden."
                        />
                    </Col>
                    <Col className="col-md-4">
                        <FormikAsyncTypeaheadInput
                            id="tags.en"
                            label="Tags Spielstätte (englisch)"
                            onSearch={() => api.getTagsForVenueByLang('en')}
                            minLength={0}
                            testid="tags.en"
                            multiple={true}
                            arrayOfStrings={true}
                            allowNew={true}
                            helpText="Tags, nach denen gesucht werden kann, um diese Spielstätte zu finden."
                        />
                    </Col>
                </Row>
            </div>
            <div className={styles.formBox}>
                <h2 className={styles.formTitle}>Vertragsadresse</h2>
                <Row className="mt-3">
                    <Col className="col-md-4">
                        <FormikSelect
                            label="Anrede"
                            options={SALUTATION_OPTIONS}
                            name={`legalContact.salutation`}
                            isShowEmptyValue={false}
                            emptyValueLabel="Bitte wählen..."
                        />
                    </Col>
                    <Col className="col-md-4">
                        <FormikTextInputGroup
                            label="Nachname"
                            name={`legalContact.lastName`}
                        />
                    </Col>
                    <Col className="col-md-4">
                        <FormikTextInputGroup
                            label="Vorname"
                            name={`legalContact.firstName`}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-md-4">
                        <FormikTextInputGroup
                            label="Straße & Hausnummer*"
                            name={`legalContact.address1`}
                        />
                    </Col>
                    <Col className="col-md-4">
                        <FormikTextInputGroup
                            label="Adresszusatz"
                            name={`legalContact.address2`}
                        />
                    </Col>
                    <Col className="col-md-4">
                        <FormikTextInputGroup
                            label="Firma"
                            name={`legalContact.companyName`}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-md-4">
                        <FormikTextInputGroup
                            label="PLZ*"
                            name={`legalContact.postalCode`}
                        />
                    </Col>
                    <Col className="col-md-4">
                        <FormikTextInputGroup
                            label="Stadt*"
                            name={`legalContact.city`}
                        />
                    </Col>
                    <Col className="col-md-4">
                        <FormikSelect
                            label="Land*"
                            options={COUNTRIES}
                            name={`legalContact.country`}
                            isShowEmptyValue={false}
                            emptyValueLabel="Bitte wählen..."
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-md-4">
                        <div className={styles.phoneBox}>
                            <MuiAutocomplete
                                label='Ländervorwahl'
                                prefix={'+'}
                                options={COUNTRY_CODE}
                                value={getValueCountryCode('phone')}
                                error={get(formik.errors.legalContact, 'phone.countryCode')}
                                onChange={(_, { id }) => formik.setFieldValue(`legalContact.phone.countryCode`, id)}
                            />
                            <FormikTextInputGroup
                                label="Telefon"
                                name={`legalContact.phone.phoneNumber`}
                            />
                        </div>
                    </Col>
                    <Col className="col-md-4">
                        <div className={styles.phoneBox}>
                            <MuiAutocomplete
                                label='Ländervorwahl'
                                prefix={'+'}
                                options={COUNTRY_CODE}
                                value={getValueCountryCode('mobile')}
                                error={get(formik.errors.legalContact, 'mobile.countryCode')}
                                onChange={(_, { id }) => formik.setFieldValue(`legalContact.mobile.countryCode`, id)}
                            />
                            <FormikTextInputGroup
                                label="Mobil"
                                name={`legalContact.mobile.phoneNumber`}
                            />
                        </div>
                    </Col>
                    <Col className="col-md-4">
                        <FormikTextInputGroup
                            label="E-Mail"
                            name={`legalContact.email`}
                        />
                    </Col>
                </Row>
            </div>
            <ListingModal
                show={isShowBatchModal}
                title="Bild wählen"
                initialValues={initialValues}
                validationSchema={validationSchema}
                onCancel={closeModal}
                onHandleChooseImage={onHandleChooseImage}
            />
        </>
    )
}

FormGeneralData.propTypes = {
    helpTextsVisible: PropTypes.bool
};


export default FormGeneralData;
