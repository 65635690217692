import React from 'react';
import {SettingsOutlined} from '@mui/icons-material';
import {useSelector} from "react-redux";

function TenantSettingsButton() {
    const crrentTenant = useSelector(state => state.currentTenant);

    return (
        crrentTenant?.currentTenant?.id &&
        <a href={`/base/tenant/${crrentTenant?.currentTenant.id}`} className='pl-4 pr-2'>
            <SettingsOutlined />
        </a>
    );
}

export default TenantSettingsButton;
