import React from 'react';
import {
    Button,
} from '@mui/material';
import {withRouter} from 'react-router-dom';

import {omit} from 'lodash';

/**
 * Frei platzierbarer Button, der onClick den gleichen Effekt hat wie der Back-Button des Browsers.
 */
function BackButton(props) {

    function goBack() {
        props.history.goBack();
    }

    return (
        // staticContext kommt durch withRouter HOC und führt zu irritierender Fehlermeldung,
        // wenn man das einfach durchschleift, daher hier ausschließen.
        <Button {...omit(props, 'staticContext')} onClick={goBack} variant={props.variant || 'text'}>
            {props.children}
        </Button>
    );
}


export default withRouter(BackButton);
