import React, {useState} from 'react';
import styles from "../layoutEditor.module.scss";
import PropTypes from "prop-types";
import {Editor} from "@tinymce/tinymce-react";
import {tinyMceConfig} from "./Templete";

const RmvLink = ({element, editModeEnabled, updateElement}) => {
    const [contentEditor, setContentEditor] = useState(element.template);

    if (!editModeEnabled) {
        return <div dangerouslySetInnerHTML={{__html: contentEditor}} className={`${styles.LayoutElementInner}`}></div>;
    }

    const handleEditorChange = (content, editor) => {
        setContentEditor(content);
    }

    const onBlur = () => {
        updateElement((prevElem) => ({...prevElem, template: contentEditor}));
    }

    return <div className={`${styles.LayoutElementInner}`}>
        <Editor
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/6.1.2/tinymce.min.js'}
            disabled={!editModeEnabled}
            init={tinyMceConfig}
            value={contentEditor}
            onEditorChange={handleEditorChange}
            onBlur={onBlur}
        />
    </div>;
};

RmvLink.propTypes = {
    element: PropTypes.object.isRequired,
    editModeEnabled: PropTypes.bool,
    updateElement: PropTypes.func.isRequired,
};

export default RmvLink;
