import React, {Component} from 'react';
import Form from './Form';
import {actions} from '../../state/entities/venueEvent';
import {connect} from 'react-redux';
import {LoadingIndicator} from '../common/LoadingIndicator';
import {Col, Row} from 'react-bootstrap';

import {denormalize} from 'normalizr';
import * as schema from '../../state/schema';
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Edit extends Component {

    componentDidMount() {
        this.props.load(this.props.venueEventId);
    }

    onSubmit = (venueEvent) => {
        let event = venueEvent;
        if(venueEvent.maxTicketsPerUser === ""){
            event = {...venueEvent, maxTicketsPerUser: null}
        }
        this.props.save(event);
    };

    getVenueEvent = () => {
        let event = {...this.props.venueEvent, ticketSelectionExpiration: this.props.venueEvent.ticketSelectionExpiration / 60};
        if(this.props.venueEvent.maxTicketsPerUser === null){
            event = {...event, maxTicketsPerUser: ""}
        }
        let rules = event.salesRules.map(rule => {
            if(rule.maxTicketsPerUser === null){
                return {...rule, maxTicketsPerUser: ""};
            }
            else{
                return rule;
            }
        })
        return {...event, salesRules: rules}
    }

    render() {
        const {venueEvent, loading, saving} = this.props;
        const currentPath = this.props.match.path;

        if (!venueEvent || loading) {
            return <LoadingIndicator/>;
        }
        return <>
            <Row className="my-4">
                { (currentPath === '/event-management/past-event/:id')
                    ? <Breadcrumbs entity={["Event"]} endpoints = {['past_venue_event/breadcrumb']} title = "Vergangene Event" links={['/event-management/past-event/', '/event-management/event/']} active={[venueEvent.id]} />
                    : <Breadcrumbs entity={["Event"]} endpoints = {['venue_event/breadcrumb']} title = "Events" links={['/event-management/event/']} active={[venueEvent.id]} />
                }
            </Row>
            <h1>Bearbeiten des Events <em>{venueEvent.title.de}</em></h1>
            <Form venueEvent={this.getVenueEvent()} onSubmit={this.onSubmit} submitPending={saving} />
        </>;
    }
}

const mapStateToProps = (state, props) => {
    const venueEventId = props.match.params.id;

    // Das Event liegt liegt in normalisierter Form vor, daher müssen wir es denormalisieren,
    // damit wir Informationen für referenzierte Daten wie die Preisliste anzeigen können.
    const venueEvent = denormalize(venueEventId, schema.venueEvent, {
        venueEvent: state.entities.venueEvent.byId,
        pricingList: state.entities.pricingList.byId,
    });

    return {
        venueEventId,
        loading: state.entities.venueEvent.loading[venueEventId],
        saving: state.entities.venueEvent.saving[venueEventId],
        venueEvent
    }
};

const mapDispatchToProps = dispatch => {
    return {
        load: id => dispatch(actions.load({id})),
        save: entity => dispatch(actions.save({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
