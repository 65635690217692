import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { Col, Row } from 'react-bootstrap';
import MaterialInput from '../../layout/MaterialInput';
import { Checkbox } from '@mui/material';
import styles from '../../common/listView/listView.module.scss';
import { filter, findIndex, get, includes, isEqual, keys, some, upperCase, words } from 'lodash';

export default ({ elements, fieldMap, selected = [], onChange = () => { }, testid, label }) => {
    const [selectedElements, setSelectedElements] = useState(selected);
    const [allChecked, setAllChecked] = useState(false);
    const [filterText, setFilterText] = useState('');
    
    const isSelected = element => some(selectedElements, e => e.id === element.id);
    
    const selectElement = element => {
        const newSelection = [...selectedElements, element];
        onChange(newSelection);
        setSelectedElements(newSelection)
    };

    const deselectElement = element => {
        const i = findIndex(selectedElements, e => e.id === element.id);
        const newSelection = [...selectedElements.slice(0, i), ...selectedElements.slice(i + 1)];
        onChange(newSelection);
        setSelectedElements(newSelection);
    };

    const toggleElement = element => isSelected(element)
        ? deselectElement(element)
        : selectElement(element);


    const getFilteredElements = () => {
        const filterWords = words(filterText);
        if (filterWords.length === 0) return elements ? elements : [];
        return filter(elements, element => {
            if (isSelected(element)) return true;

            const fieldValues = keys(fieldMap).map(fieldKey => get(element, fieldKey));
            return some(fieldValues, strVal =>
                some(filterWords, filterWord =>
                    includes(upperCase(strVal), upperCase(filterWord))
                )
            );
        });
    };

    const allVisibleSelected = () => isEqual(selectedElements, getFilteredElements());
    const selectAllVisible = () => {
        const newSelection = getFilteredElements();
        onChange(newSelection);
        setSelectedElements(newSelection);
    };
    const deselectAllVisible = () => {
        const newSelection = getFilteredElements();
        onChange(newSelection);
        setSelectedElements(selectedElements.filter(x => !newSelection.includes(x)));

    };
    const selectAll = () => {
        onChange([]);
        setSelectedElements(elements)
    };
    const deselectAll = () => {
        onChange([]);
        setSelectedElements([])
    };
    const toggleAllVisible = () => {
        if (allVisibleSelected()) {
            if (allChecked) {
                deselectAll()
            } else {
                selectAll();
            }
        } else {
            if (allChecked) {
                deselectAllVisible()
            } else {
                selectAllVisible();
            }
        }
        setAllChecked(!allChecked)
    };

    return (
        <Row>
            <Col>
                <div className={styles.searchBarWrap}>
                    <MaterialInput
                        label='Suche'
                        className="MuiFormControl-searchBar"
                        onChange={value => setFilterText(value)}
                    />
                </div>
                <Table className={styles.TableWrap} striped bordered hover>
                    <thead className={styles.TableHead}>
                        <tr>
                            <th>
                                <Checkbox
                                    id={{ id: 1 }}
                                    label="" // leeres Label damit Positionierung korrekt
                                    checked={allChecked}
                                    onChange={toggleAllVisible}
                                />
                            </th>
                            {keys(fieldMap).map(fieldKey =>
                                <th key={fieldKey}>{fieldMap[fieldKey]}</th>
                            )}
                        </tr>
                    </thead>
                    <tbody className={styles.TableBody}>
                        {getFilteredElements().map(element =>
                            <tr key={element.id}>
                                <td>
                                    <Checkbox
                                        id={element.id}
                                        label="" // leeres Label damit Positionierung korrekt
                                        checked={isSelected(element)}
                                        onChange={() => toggleElement(element)}
                                        data-testid={testid}
                                    />
                                </td>
                                {keys(fieldMap).map(fieldKey =>
                                    <td key={fieldKey}><span title={element.name}>{element.icon}</span>&nbsp;{get(element, fieldKey)}</td>
                                )}
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
};
