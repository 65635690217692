import React, {useState} from 'react';
import * as yup from 'yup';
import {Field, FieldArray, Formik} from 'formik';
import {cloneDeep} from 'lodash';
import {Button, Card, Col, Form as ReactForm, Row} from 'react-bootstrap';
import {FormikTextInputGroup} from '../common/formik/FormikTextInputGroup';

import ObjectId from 'bson-objectid';

import PricingComponentForm from './PricingComponentForm';
import FeedbackButton from '../common/FeedbackButton';
import MainPricingComponentForm from "./MainPricingComponentForm";
import Footer from "../layout/Footer";
import {FormikCheckbox} from "../common/formik/FormikCheckbox";
import {connect} from "react-redux";
import ConfirmModal from "../common/modal/ConfirmModal";
import styles from "../form.module.scss";
import AddIcon from '@mui/icons-material/Add';

export const INVOICE_DISPLAY_TYPE_NONE = 'INVOICE_DISPLAY_TYPE_NONE';
export const INVOICE_DISPLAY_TYPE_WITH_AMOUNT = 'INVOICE_DISPLAY_TYPE_WITH_AMOUNT';
export const INVOICE_DISPLAY_TYPE_WITHOUT_AMOUNT = 'INVOICE_DISPLAY_TYPE_WITHOUT_AMOUNT';

const INITIAL_VALUES = {
    name: '',
    publicName: {de: '', en: ''},
    additionalTextOnTicket: {de: '', en: ''},
    itemInvoiceAddTlText: '',
    color: '#AAAAAA',
    hasRmv: false,
    forwardable: true,
    archived: false,
    pricingComponents: [],
    mainPricingComponent: {
        name: 'Grundpreis',
        shortName: '',
        productBookingGroup: '',
        productBookingGroupDescription: '',
        productBookingGroupVatKey: 'S',
        productBookingGroupAppendEventSuffix: false,
        productBookingGroupVatKeyAppendEventSuffix: false,
        productBookingGroupVatValue: 0,
        invoiceDisplayType: INVOICE_DISPLAY_TYPE_NONE
    }
};

const pricingComponentSchema = {
    name: yup.string(),
    shortName: yup.string(),
    productBookingGroup: yup.string(),
    productBookingGroupDescription: yup.string(),
    productBookingGroupVatKey: yup.string().required(),
    productBookingGroupAppendEventSuffix: yup.boolean(),
    productBookingGroupVatKeyAppendEventSuffix: yup.boolean(),
    productBookingGroupVatValue: yup.number().min(0).typeError('Bitte geben Sie einen Mehrwertsteuersatz an, bspw. 19'),
    invoiceDisplayType: yup.mixed().oneOf([
        INVOICE_DISPLAY_TYPE_NONE,
        INVOICE_DISPLAY_TYPE_WITH_AMOUNT,
        INVOICE_DISPLAY_TYPE_WITHOUT_AMOUNT
    ]),
};

const simplePricingComponentSchema = cloneDeep(pricingComponentSchema);
simplePricingComponentSchema['id'] = yup.string().required();
simplePricingComponentSchema['grossAmount'] = yup.number()
    .transform(value => isNaN(value) ? undefined : value).min(0).typeError('Bitte geben Sie einen Betrag an, bspw. 42');

const FORM_SCHEMA = yup.object().shape({
    name: yup.string(),
    publicName: yup.object().shape({
        de: yup.string(),
        en: yup.string()
    }),
    additionalTextOnTicket: yup.object().shape({
        de: yup.string().max(100),
        en: yup.string().max(100)
    }),
    itemInvoiceAddTlText: yup.string().max(1000).nullable(),
    color: yup.string().required(),
    mainPricingComponent: yup.object().shape(pricingComponentSchema),
    pricingComponents: yup.array().of(yup.object().shape(simplePricingComponentSchema))
});

function newPriceComponent() {
    return {
        id: ObjectId.generate(),
        name: '',
        shortName: '',
        productBookingGroup: '',
        productBookingGroupDescription: '',
        productBookingGroupVatKey: 'S',
        productBookingGroupAppendEventSuffix: false,
        productBookingGroupVatKeyAppendEventSuffix: false,
        productBookingGroupVatValue: 0,
        grossAmount: 0,
        strikeAmount: null,
        invoiceDisplayType: INVOICE_DISPLAY_TYPE_NONE,
    }
}

const Form = ({onSubmit, submitPending, purchasableItemTemplate, helpTextsVisible}) => {
    const [isArchived, setIsArchived] = useState(purchasableItemTemplate.archived);
    const ArchivedStatus = ({setFieldValue}) => {
        const confirmChangeStatus = () => () => {
            let newStatus = !isArchived
            setIsArchived(newStatus);
            setFieldValue('archived', newStatus);
        };
        return (
            <ConfirmModal title="Status ändern"
                          body={(isArchived) ? `Leistungsvorlage wieder reaktivieren?`: `Leistungsvorlage ins Archiv verschieben?`}
                          cancelLabel="Abbrechen"
                          confirmLabel="Bestätigen"
            >
                {confirm => (
                    <FormikCheckbox
                        type="checkbox"
                        id="archived"
                        label="Leistungsvorlage archivieren"
                        onChange={(e) => confirm(confirmChangeStatus(e))}
                        name="archived"
                        checked={isArchived}
                    />
                )}
            </ConfirmModal>
        )
    }

    return (
        <Formik initialValues={{...INITIAL_VALUES, ...purchasableItemTemplate}}
                validationSchema={FORM_SCHEMA}
                onSubmit={onSubmit}
        >
            {({values, handleSubmit, setFieldValue}) => {
                return (
                    <form onSubmit={handleSubmit} className={styles.formCotainer}>
                        <div className={styles.formBox}>
                            <h2 className={styles.formTitle}>Allgemein</h2>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Interne Bezeichnung" name="name" testid="name"/>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Öffentliche Bezeichnung  (deutsch)" name="publicName.de" testid="name_german"
                                                            helpTextsVisible={helpTextsVisible} helpText="Wird im Ticketauswahlfenster angezeigt."/>
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Öffentliche Bezeichnung  (englisch)" name="publicName.en" testid="name_english"
                                                            helpTextsVisible={helpTextsVisible} helpText="Wird im Ticketauswahlfenster angezeigt."/>
                                </Col>
                                <Col className="col-md-4">
                                    <ReactForm.Group className="col-6">
                                        <ReactForm.Label>
                                            Farbe
                                        </ReactForm.Label>
                                        <p className={!helpTextsVisible ? "hidden" : "text-muted"}>Zur leichteren Unterscheidung der Leistungen in der Preismatrix kann die Farbe frei gewählt werden.</p>
                                        <Field type="color" name="color" className="form-control"/>
                                    </ReactForm.Group>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup
                                        label="Leistungsvorlagentext zum Aufdruck auf Ticket (deutsch)"
                                        name="additionalTextOnTicket.de"
                                        testid="additionalTextOnTicket_german"
                                    />
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup
                                        label="Leistungsvorlagentext zum Aufdruck auf Ticket (englisch)"
                                        name="additionalTextOnTicket.en"
                                        testid="additionalTextOnTicket_english"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup
                                        type="textarea"
                                        label="Text für den Ausdruck auf der Rechnung"
                                        name="itemInvoiceAddTlText"
                                        testid="itemInvoiceAddTlText"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="col-md-8">
                                    <FormikCheckbox label="Bei Kauf einer Leistung mit dieser Leistungsvorlage RMV Ticket durch Plattform ausstellen lassen" name="hasRmv"/>
                                    <FormikCheckbox label="Bei Kauf einer Leistung mit dieser Leistungsvorlage ist das Ticket weiterleitbar" name="forwardable"/>
                                    <ArchivedStatus setFieldValue={setFieldValue}/>
                                </Col>
                            </Row>
                        </div>
                        <div className={styles.formBox}>
                            <Row>
                                <Col>
                                    <h2 className={styles.formTitle}>Preiskomponenten</h2>
                                    <p className={!helpTextsVisible ? "hidden" : "text-muted"}>Die Komponenten, aus denen der Verkaufspreis zusammengesetzt wird. Wahlweise können diese auf der Rechnung ausgewiesen werden. Es kann die benötigte Anzahl an Komponenten hinzugefügt werden.</p>
                                    <div>
                                        <MainPricingComponentForm helpTextsVisible={helpTextsVisible} />
                                    </div>
                                    <FieldArray name="pricingComponents">
                                        {({push, remove}) => (
                                            <>
                                                <div>
                                                    {values.pricingComponents.map((pricingComponent, index) => (
                                                        <PricingComponentForm helpTextsVisible={helpTextsVisible} key={pricingComponent.id} index={index} remove={() => remove(index)}/>
                                                    ))}
                                                </div>
                                                <FeedbackButton
                                                    className="mt-3"
                                                    icon={<AddIcon />}
                                                    variant='text'
                                                    onClick={() => push(newPriceComponent())}
                                                    data-testid="new_pricingComponent"
                                                >
                                                    Preiskomponente hinzufügen
                                                </FeedbackButton>
                                            </>
                                        )}
                                    </FieldArray>
                                </Col>
                            </Row>
                        </div>
                        <Footer>
                            <FeedbackButton to={`/event-management/purchasable-item-template`}>
                                Abbrechen
                            </FeedbackButton>
                            <FeedbackButton
                                type="submit"
                                busy={submitPending}
                            >
                                Speichern
                            </FeedbackButton>
                        </Footer>
                    </form>
                )
            }}
        </Formik>
    )
};


const mapStateToProps = (state, props) => {

    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps)(Form);

