import React, { useState, useRef } from 'react';
import Table from 'react-bootstrap/Table';
import { Col, Form, Row } from 'react-bootstrap';
import { get, identity, isArray, isEmpty, isEqual, isInteger, isString, noop, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ListViewColumnFilterConfig from "../../listView/ListViewColumnFilterConfig";
import classnames from "classnames";
import update from "immutability-helper";
import FilteringMultiSelectListRow from "./FilteringMultiSelectListRow";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend';
import FeedbackButton from "../../FeedbackButton";
import { IconButton, Checkbox } from '@mui/material';
import styles from '../../listView/listView.module.scss';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import HelpContent from '../../../common/help/HelpContent';
import CloseIcon from '@mui/icons-material/Close';

export const getElementValue = (element, field) => {
    return (field.transformValue || identity)(get(element, field.name), element)
}

export const getSortedElements = (order, filter, elements) => {
    if (!isEmpty(filter)) {
        for (const fieldName in filter) {
            // skip loop if the property is from prototype
            if (!filter.hasOwnProperty(fieldName)) continue;

            let obj = filter[fieldName];

            if (obj.data) {
                elements = elements.filter((element) => {
                    let fieldForFilter = get(element, fieldName);

                    if (obj.data.empty === true) {
                        if (fieldForFilter === '' || fieldForFilter.length === 0) {
                            return true;
                        }
                        return false;
                    }

                    if (!isString(fieldForFilter) && !isInteger(fieldForFilter)) {
                        fieldForFilter = getElementValue(element, obj.field);
                    }

                    if ((isString(fieldForFilter) || isInteger(fieldForFilter)) && obj.data.prefix !== undefined) {
                        return fieldForFilter.toString().toLowerCase().includes(obj.data.prefix.toString().toLowerCase());
                    }

                    //filter by datetime
                    if (obj.data.range !== undefined) {
                        if (obj.data.range.onlyEmpty !== undefined) {
                            if (fieldForFilter === '' || fieldForFilter === null) {
                                return true;
                            }
                        }
                        let timeForCheck = new Date(fieldForFilter).getTime();
                        let from = null;
                        let to = null;
                        if (obj.data.range.min !== undefined) {
                            from = new Date(obj.data.range.min).getTime();
                        }

                        if (obj.data.range.max !== undefined) {
                            to = new Date(obj.data.range.max).getTime();
                        }

                        if (from && to) {
                            return (timeForCheck >= from && timeForCheck <= to);
                        }

                        if (from) {
                            return (timeForCheck >= from);
                        }

                        if (to) {
                            return (timeForCheck <= to);
                        }
                        return false;
                    }

                    fieldForFilter = get(element, fieldName);
                    if (isArray(fieldForFilter)) {
                        for (const field of fieldForFilter) {
                            if (field.name !== undefined) {
                                if ((isString(field.name) || isInteger(field.name)) && obj.data.prefix !== undefined) {
                                    if(field.name.toString().toLowerCase().includes(obj.data.prefix.toString().toLowerCase())) {
                                        return true;
                                    }
                                }
                            }

                            if (field.shippingCode !== undefined) {
                                if ((isString(field.shippingCode) || isInteger(field.shippingCode)) && obj.data.prefix !== undefined) {
                                    if(field.shippingCode.toString().toLowerCase().includes(obj.data.prefix.toString().toLowerCase())) {
                                        return true;
                                    }
                                }
                            }
                        }
                    }
                    return false;
                });
            }
        }
    }

    if (!isEmpty(order)) {
        const keyFunc = order.field.keyFunc || (e => getElementValue(e, order.field));

        return orderBy(elements, e => {
            const key = keyFunc(e);
            return isString(key) ? key.toUpperCase() : key;

        }, [order.ascending ? 'asc' : 'desc']);
    }

    return elements;
};

/**
 * adds the date into the chosen element and field after selecting it in the calendar of flatpickr
 * or clears the date, if date is null (after clicking clear button)
 * returns the salesrules
 *
 * @param date selected date or null, if
 * @param elements list of salesrules
 * @param element the selected element
 * @param field the selected field
 * @returns array of salesrules
 */
export const saveSelectedDate = (date, elements, element, field) => {
    const values = elements.filter(rule => rule.id !== element.id);
    const value = {
        ...element,
        [field.name]: date
    };
    values.splice(element.index, 0, value);
    return values;
};

const sortElements = (order, filter, elements) => {
    return isEmpty(order) && isEmpty(filter) ? elements : getSortedElements(order, filter, elements);
};

const FilteringMultiSelectList = ({
    children,
    fields,
    elements,
    fieldCheckbox,
    fieldBatchAction,
    onChange = noop
}) => {
    const [ order, setOrder ] = useState({});
    const [ filter, setFilter ] = useState({});
    const [ columnVisibility, setColumnVisibility ] = useState({});
    const [ showBatchActions, setShowBatchActions ] = useState(false);

    const selectElement = (element, field) => {
        elements = elements.filter(rule => rule.id !== element.id);
        const value = {
            ...element
        };
        value[field.name] = true;
        elements.splice(element.index, 0, value);
        onChange(elements)
    };
    const deselectElement = (element, field) => {
        elements = elements.filter(rule => rule.id !== element.id);
        const value = {
            ...element
        };
        value[field.name] = false;
        elements.splice(element.index, 0, value);
        onChange(elements)
    };

    const selectAllBatchAction = (select) => {
        elements.map((element) => {
            if (select) {
                deselectElement(element, fieldBatchAction);
            } else {
                selectElement(element, fieldBatchAction);
            }
        });
    };

    const toggleColumnVisibility = (e, fieldName) => {
        e.stopPropagation();
        setColumnVisibility((prevState) => ({
          ...prevState,
          [fieldName]: !prevState[fieldName],
        }));
      };

    const toggleSort = (e, field) => {
        e.stopPropagation();
        if (isEmpty(order)) {
            setOrder({field: field, ascending: true});
        } else {
            if (isEqual(order.field, field)) {
                if (order.ascending) {
                    setOrder({field: field, ascending: false})
                } else {
                    setOrder({field: field, ascending: true})
                }
            } else {
                setOrder({field: field, ascending: true})
            }
        }
    };

    const getSortToggleClassnames = (fieldName) => {
        const field = get(order, 'field');
        const ascending = get(order, 'ascending');

        if (field === fieldName) {
            return ascending ? (
                <ArrowUpwardOutlinedIcon className={styles.arrowSortIconActive} onClick={(e) => toggleSort(e, fieldName)} />
            ) : (
                <ArrowDownwardOutlinedIcon className={styles.arrowSortIconActive} onClick={(e) => toggleSort(e, fieldName)} />
            );
        } else {
            return <ArrowUpwardOutlinedIcon className={styles.arrowSortIcon} onClick={(e) => toggleSort(e, fieldName)} />
        }
    };


    const handleFilterChange = (field, data) => {
        setFilter({
            ...filter,
            [field.name]: {field, data},
        });
    };

    const updateRowOrder = (dragIndex, hoverIndex) => {
        elements = update(elements, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, elements[dragIndex]],
            ],
        });
        onChange(elements);
    }

    const onSortEnd = ({oldIndex, newIndex}) => {
        updateRowOrder(oldIndex, newIndex);
    };

    const ItemType = 'ItemElement';

    const moveCard = (fromIndex, toIndex) => {
        setTimeout(() => updateRowOrder(fromIndex, toIndex), 800);
    };

    const DragAndDropItem = ({
        element,
        index,
        moveCard,
        showBatchActions,
        fieldBatchAction
    }) => {
        const ref = useRef(null);
    
        const [, drop] = useDrop({
            accept: ItemType,
            hover: (draggedItem, monitor) => {
                if (!ref.current) return;
                const dragIndex = draggedItem.index;
                const hoverIndex = index;
    
                if (dragIndex === hoverIndex) return;
    
                moveCard(dragIndex, hoverIndex);
                draggedItem.index = hoverIndex;
            }
        });
    
        const [, drag] = useDrag({
            type: ItemType,
            item: { index },
            collect: monitor => ({
                isDragging: monitor.isDragging()
            })
        });
    
        drag(drop(ref));  // Combining drag and drop refs
    
        return (
            <FilteringMultiSelectListRow
                innerRef={ref}
                element={element}
                index={index}
                fieldCheckbox={fieldCheckbox}
                fieldBatchAction={fieldBatchAction}
                selectElement={selectElement}
                deselectElement={deselectElement}
                fields={fields}
                children={children}
                columnVisibility={columnVisibility}
                showBatchActions={showBatchActions}
            />
        );
    };

    const SortableList = ({
        items,
        showBatchActions,
        fieldBatchAction
    }) => {
        return (
            <tbody className={styles.TableBody}>
                {items.map((element, index) => {
                    return (
                        <DragAndDropItem
                            key={`item-${element.id}`}
                            index={index}
                            element={element}
                            showBatchActions={showBatchActions}
                            fieldBatchAction={fieldBatchAction}
                            moveCard={moveCard}
                        />
                    )
                })}
            </tbody>
        );
    };

    return (
        <DndProvider backend={HTML5Backend}>
            {fieldBatchAction && <Row>
                <Col className="mb-3">
                    <div className='titleWrapWithBtn'>
                        <FeedbackButton onClick={() => setShowBatchActions(!showBatchActions)}>
                            {showBatchActions ? "Batchbearbeitung ausblenden" : "Batchbearbeitung einblenden"}
                        </FeedbackButton>
                        <HelpContent>
                            <p className='longTitle'>
                                Auflistung der schon hinzugefügten Verkaufsregeln dieses Events. <br />
                                Es können Änderungen vorgenommen werden, oder unter Aktionen in die jeweilige Verkaufsregel gesprungen werden bzw. diese kann dort wieder aus der Liste entfernt werden.
                            </p>
                        </HelpContent>
                    </div>
                    {showBatchActions && fieldBatchAction && fieldBatchAction.actions}
                </Col>
            </Row>}
            <Row>
                <Col>
                <div className='table-responsive'>
                    <Table striped bordered hover className={styles.TableWrap}>
                        <thead className={styles.TableHead}>
                        <tr>
                            {fieldCheckbox && <th className="th-id">
                                {getSortToggleClassnames(fieldCheckbox)}
                                <span>{fieldCheckbox.label}</span>
                            </th>}

                            {fields.map((field, index) =>
                                <th key={index + field.label} className={classnames({[styles.ListViewItemHead]: columnVisibility[field.name]})}>
                                    <OverlayTrigger
                                        trigger="click"
                                        placement="top"
                                        rootClose={true}
                                        overlay={<Popover>
                                            <Popover.Body>
                                                <ListViewColumnFilterConfig
                                                    field={field}
                                                    filter={filter[field.name] ? filter[field.name].data : null}
                                                    onFilterChange={filter => handleFilterChange(field, filter)}
                                                />
                                                <IconButton className={styles.MuiIconButtonClose} onClick={() => document.body.click()}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Popover.Body>
                                        </Popover>}
                                    >
                                        <span className="d-flex align-items-center">
                                            {columnVisibility[field.name] ? (
                                                <VisibilityOffOutlinedIcon
                                                    className={classnames(styles.eyeIcon, styles.eyeIconActive)}
                                                    onClick={(e) => toggleColumnVisibility(e, field.name)}
                                                />
                                            ) : (
                                                <>                                                        
                                                    <span className="text-truncate">{field.label || field.name}</span>
                                                    <FilterAltOutlinedIcon className={classnames(styles.filterIcon, {[styles.filterIconActive]: filter[field.name]})} />
                                                    {getSortToggleClassnames(field.name)}
                                                    <RemoveRedEyeOutlinedIcon
                                                        className={styles.eyeIcon}
                                                        onClick={(e) => toggleColumnVisibility(e, field.name)}
                                                    />
                                                </>
                                            )}
                                        </span>
                                    </OverlayTrigger>
                                </th>
                            )}
                            {children && <th>Aktionen</th>}
                            {fieldBatchAction && showBatchActions && <th className="th-id">
                                <Checkbox
                                    id="selectAllBatch"
                                    label=""
                                    onChange={(e) => selectAllBatchAction(!e.target.checked)}
                                />
                            </th>}
                        </tr>
                        </thead>
                        <SortableList
                            items={sortElements(order, filter, elements)}
                            showBatchActions={showBatchActions}
                            fieldBatchAction={fieldBatchAction}
                            onSortEnd={onSortEnd}
                            helperClass="sortable-helper-table"
                            distance={5}
                        />
                    </Table>
                </div>
                </Col>
            </Row>
        </DndProvider>
    )
};

FilteringMultiSelectList.propTypes = {
    elements: PropTypes.array,
    onChange: PropTypes.func,
    fields: PropTypes.array,
    fieldCheckbox: PropTypes.object,
    fieldBatchAction: PropTypes.object
};


export default FilteringMultiSelectList;
