import React from 'react';
import {Col} from 'react-bootstrap';
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import List from "./List";
import {BATCH_ACTION_MOVE_TO_ACTIVE} from "../common/listView/ListViewBatchActions";
import RefreshIcon from '@mui/icons-material/Refresh';
import FeedbackButton from '../common/FeedbackButton';

const LinkToActive = () => {
    return (
        <FeedbackButton
            to={`/base/pricing-category`}
            icon={<RefreshIcon />}
            variant='contained'
            className='listLinkCenter'
        >
            Aktuell
        </FeedbackButton>
    );
}

const Breadcrumb = () => {
    return (
        <Breadcrumbs entity={["Archivierte Preiskategorie"]} endpoints = {['archived_pricing_category/breadcrumb']} title = "Archived Preiskategorien" links={['/base/pricing-category/']} active={[0]} />
    )
}

const ArchivedPricingCategoryList = () => {
    return (
        <List
            linkNearHeader={LinkToActive}
            breadcrumb={Breadcrumb}
            heading="Archivierte Preiskategorien"
            listViewEndpoint="listingView/archivedPricingCategory"
            allowedBatchActions={[BATCH_ACTION_MOVE_TO_ACTIVE]}
        />
    );
};

export default ArchivedPricingCategoryList;
