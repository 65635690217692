import {combineReducers} from 'redux';

import creatingSlice from './create';
import loadingSlice from './load';
import loadingAllSlice from './loadAll';
import savingSlice from './save';
import copyingSlice from './copy';
import deletingSlice from './delete';
import byId from './byId';

/**
 * Generischer Redux Slice für CRUD State/Actions einer Entität.
 *
 * @param entityName Der Name der Entität für die der Slice erzeugt wird.
 */
export default (entityName) => {

    const createSlice = creatingSlice(entityName);
    const loadSlice = loadingSlice(entityName);
    const loadAllSlice = loadingAllSlice(entityName);
    const saveSlice = savingSlice(entityName);
    const copySlice = copyingSlice(entityName);
    const deleteSlice = deletingSlice(entityName);

    return {
        actions: {
            ...createSlice.actions,
            ...loadSlice.actions,
            ...loadAllSlice.actions,
            ...saveSlice.actions,
            ...copySlice.actions,
            ...deleteSlice.actions,
        },
        reducer: combineReducers({
            byId: byId(entityName),
            createPending: createSlice.reducer,
            loading: loadSlice.reducer,
            loadingAll: loadAllSlice.reducer,
            saving: saveSlice.reducer,
            copying: copySlice.reducer,
            deleting: deleteSlice.reducer,
        })
    }
}
