import React from 'react';
import {Col, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {FormikTextInputGroup} from '../common/formik/FormikTextInputGroup';

const QuotaForm = ({associationFormik, validateCapacity}) => {
    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        <FormikTextInputGroup label="Name" name="name" type="text"
                                              testid="name"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormikTextInputGroup label="Insgesamt" name="capacity" type="number"
                                              validate={validateCapacity}
                                              testid="name"/>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
};

QuotaForm.propTypes = {
    associationFormik: PropTypes.object,
    element: PropTypes.object,
    validateCapacity: PropTypes.func,
};

export default QuotaForm;
