// Import all the third party stuff
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';

import Api from './api';

import './assets/scss/main.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'font-awesome/css/font-awesome.min.css';
import '@mdxeditor/editor/style.css';
// setLocale() von yup muss vor allen jeder anderen Benutzung geschenen, sonst greift es nicht daher hier das
// Modul mit der entsprechenden Initialisierung vor den ganzen anderen Komponenten laden.
// eslint-disable-next-line
// noinspection ES6UnusedImports
import App from './components/App';

import log from 'loglevel';
import {makeServer} from './mirageServer';

log.enableAll();

// Checking for NODE_ENV === 'development' ensures that the MirageJS API mock
// is excluded by tree shaking from the production bundle.
if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_DEV_ENABLE_MIRAGE_API_MOCK === 'true') {
    makeServer({environment: 'development'})
}

// publish application context to be used by Cypress when running  in the Cypress context.
if (window.Cypress) {
    window.app = {api: new Api()}
}

const MOUNT_NODE = document.getElementById('root');

const root = createRoot(MOUNT_NODE);
const render = () => {
    root.render(<App />);
};

if (module.hot) {
    module.hot.accept(['./components/App'], () => {
        render();
    });
}

render();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
