import React, {Component} from 'react';
import Form from "./Form";
import {connect} from "react-redux";
import {LoadingIndicator} from "../../common/LoadingIndicator";
import {Col, Row} from "react-bootstrap";
import * as venue from "../../../state/entities/venue";
import * as venuePlan from "../../../state/entities/venuePlan";
import BlockGroupList from "./blockGroup/List";
import BlockList from "./block/List";
import Breadcrumbs from "../../common/breadcrumbs/Breadcrumbs";
import VenueEvents from "./VenueEvents";

class Edit extends Component {

    componentDidMount() {
        this.props.loadVenue(this.props.venueId);
        this.props.loadVenuePlan(this.props.venuePlanId);
    }

    onSubmit = (venuePlan, {setErrors}) => {
        this.props.saveVenuePlan(venuePlan, setErrors);
    };

    render() {
        const {venuePlan, loading, saving, venue, venueLoading, venuePlanId} = this.props;
        if (!venuePlan || !venue || loading || venueLoading) {
            return <LoadingIndicator/>;
        }
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Spielstätte", "Saalplan", "Blockgruppe", "Block"]} endpoints = {['venue/breadcrumb', `venue/${venue.id}/venue_plan/breadcrumb`, `venue_plan/${venuePlan.id}/block_group/breadcrumb`, `venue_plan/${venuePlan.id}/block/breadcrumb`]} title = {["Blockgruppen", "Blöcke"]} links={['/base/venue/', `/base/venue/${venue.id}/venue-plan/`, `/base/venue-plan/${venuePlan.id}/block-group/`, `/base/venue-plan/${venuePlan.id}/block/`]} active={[venue.id, venuePlan.id]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Bearbeiten des Saalplans <em>{venuePlan.name}</em> für die Spielstätte <em>{venue.name}</em></h1>
                </Col>
            </Row>
            <Form venuePlan={venuePlan} onSubmit={this.onSubmit} submitPending={saving} venueId={this.props.venueId} />
            <BlockGroupList venuePlan={venuePlan} venuePlanId={venuePlanId} />
            <VenueEvents venuePlan={venuePlan} />
            <BlockList venuePlan={venuePlan} venuePlanId={venuePlanId} />
        </>;
    }
}

const mapStateToProps = (state, props) => {
    const venueId = props.match.params.id;
    const venuePlanId = props.match.params.vpid;
    return {
        venuePlanId,
        venueId,
        loading: state.entities.venuePlan.loading[venuePlanId],
        saving: state.entities.venuePlan.saving[venuePlanId],
        venuePlan: state.entities.venuePlan.byId[venuePlanId],
        venue: state.entities.venue.byId[venueId],
        venueLoading: state.entities.venue.loading[venueId],
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadVenuePlan: id => dispatch(venuePlan.actions.load({id})),
        saveVenuePlan: entity => dispatch(venuePlan.actions.save({entity})),
        loadVenue: id => dispatch(venue.actions.load({id})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
