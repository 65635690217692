import * as types from './types';
import produce from 'immer';
import {combineReducers} from 'redux';

const query = produce((state = {}, {type, payload}) => {
    switch (type) {
        case types.QUERY_LIST_VIEW:
            state[payload.endpoint] = payload.query;
            break;

        case types.QUERY_LIST_VIEW_SUCCESS:
            state[payload.endpoint] = {
                ...state[payload.endpoint],
                pagination: payload.result.pagination
            };
            break;

        default:
            return state;
    }
});

const result = produce((state = {}, {type, payload}) => {
    switch (type) {
        case types.QUERY_LIST_VIEW_SUCCESS:
            state[payload.endpoint] = payload.result;
            break;

        default:
            return state;
    }
});

const loading = produce((state = {}, {type, payload}) => {
    switch (type) {
        case types.QUERY_LIST_VIEW:
            state[payload.endpoint] = true;
            break;

        case types.QUERY_LIST_VIEW_ERROR:
        case types.QUERY_LIST_VIEW_SUCCESS:
            state[payload.endpoint] = false;
            break;

        default:
            return state;
    }
});

export default combineReducers({
    query,
    result,
    loading,
});



