import React from "react";
import styles from "./baseLayout.module.scss";
import { useSelector } from "react-redux";
import { IState, selectInteractionMode } from "../../state/entities/venueEditor/selectors";
import FeedbackButton from "../../components/common/FeedbackButton";
import { IImagesManager } from "../editor/display/images/imagesManager";


interface ITabImagesProps {
    imagesManager: IImagesManager;
    venuePlanId: string;
}


const TabImages: React.FC<ITabImagesProps> = ({imagesManager, venuePlanId}) => {
    const interactionMode = useSelector((state:IState) => selectInteractionMode(state));


    const handleMoveUp = () => {
        imagesManager.moveUpSelectedImage();
    }

    const handleMoveDown = () => {
        imagesManager.moveDownSelectedImage();
    }

    const handleMoveToTop = () => {
        imagesManager.moveToTopSelectedImage();
    }

    const handleMoveToBottom = () => {
        imagesManager.moveToBottomSelectedImage();
    }


    const buttonsDisabled = interactionMode !== "IMAGES" || !imagesManager.getSelectedItem();

    return (
        <div className={styles.tabsContent}>
            <h2 className={styles.tabsContent__title}>Grafiken</h2>
            <div className={styles.tabsContent__buttons}>
                 <div className={styles.tabsContent__buttonRow}>
                    <FeedbackButton value="AddImage"
                        onClick={handleMoveUp} disabled={buttonsDisabled}
                        variant="outlined" className={styles.tabsContent__fixedButton}>
                        Ebene höher
                    </FeedbackButton>
                    <FeedbackButton value="AddImage" 
                        onClick={handleMoveDown} disabled={buttonsDisabled} 
                        variant="outlined" className={styles.tabsContent__fixedButton}>
                        Ebene tiefer
                    </FeedbackButton>
                </div>
                <div className={styles.tabsContent__buttonRow}>
                    <FeedbackButton value="AddImage" 
                        onClick={handleMoveToTop} disabled={buttonsDisabled} 
                        variant="outlined" className={styles.tabsContent__fixedButton}>
                        Oberste Ebene
                    </FeedbackButton>
                    <FeedbackButton value="AddImage" 
                        onClick={handleMoveToBottom} disabled={buttonsDisabled} 
                        variant="outlined" className={styles.tabsContent__fixedButton}>
                        Unterste Ebene
                    </FeedbackButton>
                </div>
             </div>
        </div>
    );
};


export default TabImages;