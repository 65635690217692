import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import FeedbackButton from '../FeedbackButton';
import {Form, Formik} from 'formik';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {noop} from 'lodash';

/**
 * Component to show a modal form dialog.
 *
 * This sets up a form context with formik including validation. The the
 * inputs of the form should be provided as children to the component.
 */
const FormModal = props => {
    const renderFooterElements = (associationFormik) => (
        props.isCustomFooterElems ? (
            props.renderFooterElems()
        ) : (
            <>            
                <FeedbackButton variant="outlined" onClick={props.onCancel}>{props.cancelLabel}</FeedbackButton>
                {/*
                    The explicit onClick handler is necessary to prevent an implicit form submission from
                    bubbling to a possible surrounding form scope and triggering that form's submit action.
                */}
                <FeedbackButton type="submit" onClick={associationFormik.handleSubmit} disabled={props.submitBusy}> {props.confirmLabel}</FeedbackButton>
            </>
        )
    );
    return (
        <Modal
            className={props.className}
            show={props.show}
            size={props.size}
            onHide={props.onCancel}
            enforceFocus={false}
            centered
        >
            <Formik onSubmit={props.onConfirm} initialValues={props.initialValues} validationSchema={props.validationSchema}>
                {(associationFormik) => (
                    <Form style={props.style}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {props.title}
                                {props.showIconRefresh && (
                                    <IconButton onClick={props.onHandleRefresh} className='ml-2'>
                                        <RefreshIcon />
                                    </IconButton>
                                )}
                            </Modal.Title>
                        </Modal.Header>
                        {props.children && <Modal.Body>{props.children({associationFormik})}</Modal.Body>}
                        <Modal.Footer>
                            {renderFooterElements(associationFormik)}
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
};

FormModal.propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.string,
    confirmLabel: PropTypes.string,
    size: PropTypes.string,
    cancelLabel: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    renderFooterElems: PropTypes.func,
    onHandleRefresh: PropTypes.func,
    isCustomFooterElems: PropTypes.bool,
    className: PropTypes.string,
    initialValues: PropTypes.object.isRequired,
    validationSchema: PropTypes.object.isRequired,
    submitTestId: PropTypes.string,
    submitBusy: PropTypes.bool,
};

FormModal.defaultProps = {
    confirmLabel: 'Speichern',
    cancelLabel: 'Abbrechen',
    size: 'lg',
    onConfirm: noop,
    onCancel: noop,
    submitBusy: false,
}

export default FormModal;
