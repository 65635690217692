import {all, takeEvery, select} from 'redux-saga/effects';

import createSaga from './create';
import loadSaga from './load';
import loadAllSaga from './loadAll';
import saveSaga from './save';
import deleteSaga from './delete';
import copySaga from './copy';

/**
 * Generische Saga für CRUD Funktionalität einer Entität.
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 * @param onCreateSuccess Optionale callback Funktion deren Ergebnis "geyielded" wird nachdem die Entität erfolgreich angelegt wurde. Erhält die neu erzeugte Entität als Parameter.
 * @param onCopySuccess Optionale callback Funktion deren Ergebnis "geyielded" wird nachdem die Entität erfolgreich kopier wurde. Erhält die neu erzeugte Entität als Parameter.
 */
export default ({actions, resource, schema, onCreateSuccess, onCopySuccess}) => function* saga() {
    yield all([
        takeEvery(actions.create, createSaga({actions, resource, schema, onSuccess: onCreateSuccess})),
        takeEvery(actions.load, loadSaga({actions, resource, schema})),
        takeEvery(actions.loadAll, loadAllSaga({actions, resource, schema})),
        takeEvery(actions.save, saveSaga({actions, resource, schema})),
        takeEvery(actions.delete, deleteSaga({actions, resource, schema})),
        takeEvery(actions.copy, copySaga({actions, resource, schema, onSuccess: onCopySuccess}))
    ])
}
