import React from "react";
import styles from "./baseLayout.module.scss";
import { Button } from "@mui/material";
import { IAreaFormsManager } from "../editor/display/areaForms/areaFormsManager";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { InteractionMode } from "../editor/display/interaction";
import { useSelector } from "react-redux";
import { IState, selectInteractionMode, selectSelectedAreaForm } from "../../state/entities/venueEditor/selectors";


interface ITabAreaFormsProps {
    areaFormsManager: IAreaFormsManager;
    onEditAreaForm: () => void;
    //    venuePlanId: string;
}


const TabAreaForms: React.FC<ITabAreaFormsProps> = ({areaFormsManager, onEditAreaForm}) => {
    const interactionMode = useSelector((state: IState) => selectInteractionMode(state));
    const selectedAreaForm = useSelector((state: IState) => selectSelectedAreaForm(state));


    return (
        <div className={styles.tabsContent}>
            <h2 className={styles.tabsContent__title}>Stehplatzblöcke</h2>
            <div className={styles.tabsContent__buttons}>
                <div>
                    <Button
                        value="AreaForms"
                        variant="contained"
                        onClick={onEditAreaForm}
                        disabled={interactionMode !== InteractionMode.AREAFORMS || !selectedAreaForm}
                        startIcon={<EditOutlinedIcon/>}
                    >
                        Stehplatz-Block editieren
                    </Button>
                </div>
            </div>
        </div>
    );
};


export default TabAreaForms;