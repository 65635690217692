import React from 'react';
import {Col} from 'react-bootstrap';
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import List from "./List";
import {BATCH_ACTION_MOVE_TO_ACTIVE} from "../common/listView/ListViewBatchActions";
import RefreshIcon from '@mui/icons-material/Refresh';
import FeedbackButton from '../common/FeedbackButton';

const LinkToActive = () => {
    return (
        <FeedbackButton
            to={`/base/target-group`}
            icon={<RefreshIcon />}
            variant='contained'
            className='listLinkCenter'
        >
            Archiv
        </FeedbackButton>
    );
}

const Breadcrumb = () => {
    return (
        <Breadcrumbs entity={["Personenpool archivieren"]} endpoints = {['archived_target_group/breadcrumb']} title = "Personenpool archivieren" links={['/base/target-group/archived/']} active={[0]} />
    )
}

const ArchivedTargetGroupList = () => {
    return (
        <List
            linkNearHeader={LinkToActive}
            breadcrumb={Breadcrumb}
            heading="Personenpool archivieren"
            listViewEndpoint="listingView/archivedTargetGroup"
            allowedBatchActions={[BATCH_ACTION_MOVE_TO_ACTIVE]}
        />
    );
};

export default ArchivedTargetGroupList;
