import React, {Component} from 'react';
import Form from "./FormCreate";
import * as venue from "../../state/entities/venue";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Create extends Component {

    onSubmit = (venue, {setErrors}) => {
        this.props.createVenue(venue, setErrors);
    };

    render() {
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Spielstätte"]} endpoints = {['venue/breadcrumb']} title = "Spielstätten" links={['/base/venue/']} active={[0]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Erstellen einer neuen Spielstätte</h1>
                </Col>
            </Row>
            <Form venue={{}} onSubmit={this.onSubmit} submitPending={this.props.createPending} />
        </>;
    }
}

const mapStateToProps = (state) => {
    return {
        createPending: state.entities.venue.createPending
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createVenue: entity => dispatch(venue.actions.create({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
