import React, {useEffect, useState} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap';
import PropTypes from "prop-types";
import {getIn} from "formik";
import FilteringMultiSelectList from "../common/form/FilteringMultiSelectList/FilteringMultiSelectList";
import {findIndex, get} from "lodash";
import ConfirmModal from "../common/modal/ConfirmModal";
import FormModal from "../common/modal/FormModal";
import {Quota} from "./schema";
import QuotaForm from "./QuotaForm";
import ObjectId from "bson-objectid";
import {createListElements} from "./FormSalesRules";
import classNames from "classnames";
import FeedbackButton from "../common/FeedbackButton";
import AddIcon from '@mui/icons-material/Add';
import styles from "../form.module.scss";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import {
    FormControlLabel,
    FormControl,
    Select as MuiSelect,
    MenuItem,
    Checkbox
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

export function createQuotaListElements(formQuotas, databaseQuotas) {
    return formQuotas.map((quota, index) => {
        const databaseQuota = databaseQuotas.quotas ? databaseQuotas.quotas.find(item => {
            return item.id === quota.id;
        }) : null;

        return ({
            ...quota,
            index: index,
            used: databaseQuota ? databaseQuota.capacity - databaseQuota.availableCapacity : 0,
            remaining: databaseQuota ? (quota.capacity - (databaseQuota.capacity - databaseQuota.availableCapacity)) : quota.capacity,
            canDelete: !databaseQuota || databaseQuota.capacity === databaseQuota.availableCapacity
        });
    });
}

function FormQuotas({formik, event, databaseQuotas, loadQuotasByEvent}) {
    const [quotaToEdit, setQuotaToEdit] = useState(null);
    const [quotaToAdd, setQuotaToAdd] = useState(null);
    const [selectedQuotaId, setSelectedQuotaId] = useState(null);

    const [stringifyFormQuotas, setStringifyFormQuotas] = useState('');
    useEffect(() => {
        const newStringify = JSON.stringify(formik.values.quotas);

        if (stringifyFormQuotas !== newStringify) {
            if (loadQuotasByEvent) {
                loadQuotasByEvent(formik.values.id);
            }

            setStringifyFormQuotas(newStringify);
        }
    }, [loadQuotasByEvent, formik.values.id, formik.values.quotas, stringifyFormQuotas]);

    useEffect(() => {
        let quotas = getIn(formik.values, 'quotas', []).map((quota, index) => {
            if (databaseQuotas && Array.isArray(databaseQuotas.quotas)) {
                const databaseQuota = databaseQuotas.quotas.find(item => {
                    return item.id === quota.id;
                });

                if (databaseQuota) {
                    return {
                        ...quota,
                        availableCapacity: (quota.capacity - (databaseQuota.capacity - databaseQuota.availableCapacity))
                    }
                }
            }

            return quota;
        });

        formik.setFieldValue('quotas', quotas);
    }, [databaseQuotas]);

    const removeQuotaId = '--remove-quota--';

    const validateCapacity = (quotaId, capacity) => {
        let minCapacity = 1;

        if (databaseQuotas && Array.isArray(databaseQuotas.quotas)) {
            const databaseQuota = databaseQuotas.quotas.find(item => {
                return item.id === quotaId;
            });

            if (databaseQuota) {
                if (databaseQuota && databaseQuota.availableCapacity) {
                    minCapacity = databaseQuota.capacity - databaseQuota.availableCapacity
                }
            }
        }

        if (minCapacity > capacity) {
            return `Die Kapazität sollte größer oder gleich ${minCapacity} sein`
        }
    };

    const quotaFields = [
        {name: 'name', label: 'Name'},
        {name: 'capacity', label: 'Insgesamt'},
        {name: 'used', label: 'Aufgebraucht'},
        {name: 'remaining', label: 'Verbleibend'},
    ];

    const salesRulesFieldCheckbox = {name: 'selectedForQuota', label: ' ', checkboxId: 'quotasCheck', type: 'formSalesRules'};
    const salesRulesFields = [
        {
            name: 'quota',
            label: 'Kontingent',
            transformValue: (groups, element) => {
                const quota = formik.values.quotas.find(quota => quota.eventSalesRuleIds.includes(element.id))

                if (quota) {
                    return quota.name;
                }
                return '';
            }
        },
        {
            name: 'status',
            label: 'Status',
            transformValue: (status, element) => {
                //todo add red square
                return <i
                    className={classNames('sales-rule-status fa mt-1', (element.status === 'aktiv' && (!element.quota || (element.quota && element.quota.availableCapacity > 0)) ? 'fa-play' : 'fa-stop'))}></i>;
            }
        },
        {name: 'salesRule.name', label: 'Verkaufsregel'},
        {
            name: 'salesRule.targetGroups',
            label: 'Personenpools',
            transformValue: (groups, element) => {
                const operator = get(element, 'salesRule.linkTargetGroups')
                const targetGroups = (groups || []).map(g => g.name).join(', ');
                return `${operator}: ${targetGroups}`;
            }
        },
        {
            name: 'salesRule.placePools',
            label: 'Platzpools',
            transformValue: pools => (pools || []).map(p => p.name).join(', ')
        },
        {name: 'salesRule.pricingList.name', label: 'Preisliste'},
        {name: 'validFrom', label: 'Gültig von', type: 'datetime'},
        {name: 'validTo', label: 'Gültig bis', type: 'datetime'},
        {
            name: 'salesRule.salesChannels',
            label: 'Kanal',
            keyFunc: e => e.salesRule.salesChannels[0].name,
            transformValue: channels => {
                // instead of a textual representation render list of icons
                return <>{(channels || []).map(c => <span key={c.id} title={c.name}>{c.icon}</span>)}</>;
            }
        }
    ];

    // current timestamp
    let now = Date.now() / 1000;

    const addQuota = (addedQuota) => {
        formik.setFieldValue('quotas', [...formik.values.quotas, {
            ...addedQuota,
            id: ObjectId.generate(),
            availableCapacity: addedQuota.capacity,
        }]);

        setQuotaToAdd(null);
    };

    const applySalesRules = () => {
        const selectedSalesRulesIds = formik.values.salesRules.filter(salesRule => salesRule.selectedForQuota)
            .map(salesRule => salesRule.id);

        if (selectedQuotaId) {
            let quotas = getIn(formik.values, 'quotas', []).map((quota, index) => {
                return {
                    ...quota,
                    eventSalesRuleIds: quota.eventSalesRuleIds.filter(id => !selectedSalesRulesIds.includes(id))
                };
            });

            if (selectedQuotaId !== removeQuotaId) {
                quotas = quotas.map((quota, index) => {
                    if (selectedQuotaId !== quota.id) {
                        return {
                            ...quota
                        };
                    }

                    return {
                        ...quota,
                        eventSalesRuleIds: quota.eventSalesRuleIds.concat(selectedSalesRulesIds.filter(saleRuleId => !quota.eventSalesRuleIds.includes(saleRuleId)))
                    };
                });
            }

            formik.setFieldValue('quotas', quotas);

            toggleAllSalesRules(false);
        }
    };

    const updateQuota = (updatedQuota) => {
        let quotas = getIn(formik.values, 'quotas', []);
        const index = findIndex(quotas, s => s.id === updatedQuota.id);

        if (index !== -1) {
            updatedQuota.availableCapacity = updatedQuota.availableCapacity + (updatedQuota.capacity - quotas[index].capacity);

            quotas = [...quotas];
            quotas.splice(index, 1, updatedQuota);
            formik.setFieldValue('quotas', quotas);
        }

        setQuotaToEdit(null);
    };

    const toggleAllSalesRules = (checked) => {
        const salesRules = getIn(formik.values, 'salesRules', []).map((salesRule, index) => {
            return {...salesRule, selectedForQuota: checked};
        });

        formik.setFieldValue('salesRules', salesRules);
    };

    const quotasDropdown = getIn(formik.values, 'quotas', []).map((quota, index) => {
        return {id: quota.id, name: quota.name};
    });

    const onChange = (selection) => {
        formik.setFieldValue('quotas', selection)
    };

    const onChangeSalesRules = (selection) => {
        formik.setFieldValue('salesRules', selection)
    };

    const RemoveQuotaAction = ({id}) => {
        const quota = formik.values.quotas.find(item => {
            return item.id === id;
        });

        const removeQuota = (id) => () => {
            const values = formik.values.quotas.filter(quota => quota.id !== id);
            formik.setFieldValue('quotas', values)
        };

        return (
            <ConfirmModal title="Kontingent entfernen"
                          body={`Möchten Sie das Kontingent "${quota.name}" wirklich entfernen?`}
                          cancelLabel="Abbrechen"
                          confirmLabel="Löschen"
            >
                {confirm => (
                    <FeedbackButton title="Kontingente löschen" className="list-link" onClick={() => confirm(removeQuota(id))}>
                        <DeleteOutlineOutlinedIcon className='table-icon' />
                    </FeedbackButton>
                )}
            </ConfirmModal>
        )
    }

    const EditQuotaButton = ({quota}) => (
        <FeedbackButton title="edit" className="list-link" data-testid="edit_quota" onClick={() => setQuotaToEdit(quota)}>
            <EditNoteOutlinedIcon className='table-icon' />
        </FeedbackButton>
    )

    return (
        <>
            <FormModal show={!!quotaToAdd}
                       title="Kontingent hinzufügen"
                       initialValues={{...Quota.default(), ...quotaToAdd}}
                       validationSchema={Quota}
                       onConfirm={addQuota}
                       onCancel={() => setQuotaToAdd(null)}
                       submitTestId="submit_salesRule"
            >
                {({associationFormik}) => {
                    return (
                        <QuotaForm associationFormik={associationFormik}
                                   validateCapacity={(capacity) => validateCapacity(null, capacity)}/>
                    )
                }}
            </FormModal>
            <FormModal show={!!quotaToEdit}
                       title="Kontingent bearbeiten"
                       initialValues={{...Quota.default(), ...quotaToEdit}}
                       validationSchema={Quota}
                       onConfirm={updateQuota}
                       onCancel={() => setQuotaToEdit(null)}
                       submitTestId="submit_salesRule"
            >
                {({associationFormik}) => {
                    return (
                        <QuotaForm associationFormik={associationFormik}
                                   validateCapacity={(capacity) => validateCapacity(quotaToEdit.id, capacity)}/>
                    )
                }}
            </FormModal>
            <div className={styles.formBox}>
                <h2 className={styles.formTitle}>Alle Kontingente</h2>
                <FeedbackButton
                    icon={<AddIcon />}
                    className="mb-3"
                    onClick={() => setQuotaToAdd({})}
                >
                    Kontigent hinzufügen
                </FeedbackButton>
                <FilteringMultiSelectList
                    elements={createQuotaListElements(formik.values.quotas, databaseQuotas)}
                    fields={quotaFields}
                    formik={formik}
                    onChange={onChange}
                >
                    {({element}) => {
                        return (
                            <div key={`div${element.id}`}>
                                <EditQuotaButton quota={element}/>
                                {element.canDelete && <RemoveQuotaAction id={element.id}/>}
                            </div>
                        )
                    }}
                </FilteringMultiSelectList>
            </div>

            {formik.values.quotas.length > 0 && (
                <div className={styles.formBox}>
                    <h2 className={styles.formTitle}>Zuweisung zu Verkaufsregeln</h2>
                    <FilteringMultiSelectList
                        elements={
                            createListElements(
                                formik.values.salesRules,
                                formik.values.quotas,
                                formik.values.maxTicketsSalesRulesGroups,
                                now,
                                formik.values.startDate,
                                formik.values.disableSalesRulesAfterStartDate,
                                formik.values.endDate,
                                formik.values.disableSalesRulesAfterEndDate
                            )}
                        fields={salesRulesFields}
                        fieldCheckbox={salesRulesFieldCheckbox}
                        onChange={onChangeSalesRules}
                        formik={formik}
                    >
                    </FilteringMultiSelectList>

                    <Row className="mt-3">
                        <Col className="col-md-2">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={`toggleAllSalesRules`}
                                        onChange={(e) => {
                                            toggleAllSalesRules(e.target.checked);
                                        }}
                                    />
                                }
                                label='Alle'
                            ></FormControlLabel>
                        </Col>
                        <Col className="col-md-4">
                            <FormControl fullWidth variant="outlined" sx={{ padding: 0 }}>
                                <MuiSelect onChange={(e) => setSelectedQuotaId(e.target.value)}>
                                    <MenuItem value="">
                                        <em>Bitte wählen ...</em>
                                    </MenuItem>
                                    {quotasDropdown.map(({ name, id }) => {
                                        return (
                                            <MenuItem key={id} value={id}>
                                                {selectedQuotaId === id && <CheckIcon />}
                                                {name}
                                            </MenuItem>
                                        )
                                    })}
                                    <MenuItem value={removeQuotaId}>
                                        <em>Zuweisung entfernen</em>
                                    </MenuItem>
                                </MuiSelect>
                            </FormControl>
                        </Col>

                        <Col className="col-md-4 align-self-center">
                            <FeedbackButton variant="outlined" onClick={() => applySalesRules()}>
                                Anwenden
                            </FeedbackButton>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    )
}

FormQuotas.propTypes = {
    formik: PropTypes.object,
    event: PropTypes.object,
    databaseQuotas: PropTypes.object,
    loadQuotasByEvent: PropTypes.func,
};

export default FormQuotas;
