import React from 'react';
import * as yup from 'yup';
import {Formik} from 'formik';
import {Card, Col, Row} from 'react-bootstrap';
import {FormikTextInputGroup} from '../../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../../common/FeedbackButton';
import {connect} from 'react-redux';
import * as placePool from "../../../state/entities/placePool";
import Footer from "../../layout/Footer";
import styles from "../../form.module.scss";

const getInitialValues = (venueId) => ({
    entry: '',
    label: '',
    comment: '',
    venue: {id: venueId},
});

const FORM_SCHEMA = yup.object().shape({
    entry: yup.string().required().default(''),
    label: yup.string().required().default(''),
    comment: yup.string().default('')
});

const ZukoZoneForm = ({zukoZone, venueId, onSubmit, submitPending, helpTextsVisible}) => {

    return <Formik
        initialValues={{...getInitialValues(venueId), ...zukoZone}}
        validationSchema={FORM_SCHEMA}
        onSubmit={onSubmit}
    >
        {formik => {

            return (
                <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                   <div className={styles.formBox}>
                        <h2 className={styles.formTitle}>Allgemein</h2>
                        <Row className="mt-3">
                            <Col className="col-md-4">
                                <FormikTextInputGroup label="Zuko-Zone*" name="entry" testid="entry"/>
                            </Col>
                            <Col className="col-md-4">
                                <FormikTextInputGroup label="Zutrittszone im Ticketing*" name="label" testid="label"/>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col className="col-md-4">
                                <FormikTextInputGroup label="Kommentar" name="comment" testid="comment"/>
                            </Col>
                        </Row>
                    </div>
                    <Footer>
                        <FeedbackButton to={`/base/venue/${venueId}`}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton
                            type="submit"
                            busy={submitPending}
                        >
                            Speichern
                        </FeedbackButton>
                    </Footer>
                </form>
            )
        }
        }
    </Formik>
};


ZukoZoneForm.propTypes = {
    onSubmit: PropTypes.func,
    submitPending: PropTypes.bool,
    requestErrors: PropTypes.any
};

const mapStateToProps = (state, props) => {
    const helpTextsVisible = state.helpTextsToggle;
    return {
        helpTextsVisible
    }
};

const mapDispatchToProps = dispatch => ({
    loadPlacePools: () => dispatch(placePool.actions.loadAll()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ZukoZoneForm);


