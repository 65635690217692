import {Assets, Container, DisplayObject, Texture} from 'pixi.js';
import '@pixi/math-extras';
import { ImageData } from './ImageData';
import { Image } from './imageItem';
import { IVisualsManager, VisualsManager } from '../editableVisuals/visualsManager';


export interface IImagesManager extends IVisualsManager<ImageData> {
    prebuildNewImage(imageLibId: string, imageTitle: string, imageUrl: string): Promise<ImageData>;
    moveUpSelectedImage(): void;
    moveDownSelectedImage(): void;
    moveToTopSelectedImage(): void;
    moveToBottomSelectedImage(): void;
    setShiftKeyPressed(isPressed: boolean): void;
}

const IMAGE_STARTSIZE = 50;


export class ImagesManager extends VisualsManager<Image, ImageData> implements IImagesManager {

    private suspendTransformUpdate = false;  // to suppress update on each child (performance)
    private shiftPressed = false;


    constructor(
        itemsContainer: Container,
        private viewportRoot: Container,
        overlay: Container
    ) {
        super(itemsContainer, overlay);
    }


    async prebuildNewImage(imageLibId: string, imageTitle: string, imageUrl: string): Promise<ImageData> {
        const texture: Texture = await Assets.load(imageUrl);
        const scaleDown = IMAGE_STARTSIZE / Math.max(texture.width, texture.height);
        const newImage: ImageData = {
            id: "",
            media: {
                id: imageLibId,
                title: imageTitle,
                url: imageUrl
            },
            width: texture.width * scaleDown,
            height: texture.height * scaleDown,
            rotation: 0,
            x: 0,
            y: 0,
            zIndex: 0
        };
        return newImage;
    }

    updatePositions() {
        if (!this.isEditMode) return;
        this.suspendTransformUpdate = true;
        this.viewportRoot.updateTransform();
        super.updatePositions();
        this.suspendTransformUpdate = false;
    }


    private handleUpdateTransform() {
        if (this.suspendTransformUpdate) return;
        this.viewportRoot.updateTransform();  //to up2date worldTransform matrices
    }


    initItems(images: ImageData[]): void {
        const newImages = images.map(image => ({...image}))   //sort by zIndex
            .sort((im1, im2) => im1.zIndex - im2.zIndex);
        super.initItems(newImages);
    }    


    protected visualItemFactory(
        dataItem: ImageData,
        index: number,
        itemsContainer: Container<DisplayObject>,
        sensorsContainer: Container<DisplayObject>,
        handlesContainer: Container<DisplayObject>,
        onSelectItem: (item: Image) => void,
        onItemChanged: (itemData: ImageData) => void
    ): Image {
        dataItem.zIndex = index;
        const newImageDO = new Image(
            dataItem,
            itemsContainer,
            sensorsContainer,
            handlesContainer,
            () => this.handleUpdateTransform(),
            onSelectItem,
            onItemChanged,
            () => this.shiftPressed
        );
        newImageDO.getImageDO().zIndex = index;
        return newImageDO;
    }


    moveUpSelectedImage() {
        const selIndex = this.items.indexOf(this.selectedItem);
        if (selIndex === this.items.length - 1) return;
        this.items.splice(selIndex + 2, 0, this.selectedItem);
        this.items.splice(selIndex, 1);
        this.selectedItem.reInsertInContainerAt(selIndex + 1);
        this.items[selIndex + 1].getItemData().zIndex = selIndex + 1;
        this.items[selIndex].getItemData().zIndex = selIndex;
        this.onItemsChanged([this.selectedItem.getItemData(), this.items[selIndex].getItemData()]);
    }


    moveDownSelectedImage() {
        const selIndex = this.items.indexOf(this.selectedItem);
        if (selIndex === 0) return;
        this.items.splice(selIndex - 1, 0, this.selectedItem);
        this.items.splice(selIndex + 1, 1);
        this.selectedItem.reInsertInContainerAt(selIndex - 1);
        this.items[selIndex - 1].getItemData().zIndex = selIndex - 1;
        this.items[selIndex].getItemData().zIndex = selIndex;
        this.onItemsChanged([this.selectedItem.getItemData(), this.items[selIndex].getItemData()]);
    }


    moveToTopSelectedImage() {
        const selIndex = this.items.indexOf(this.selectedItem);
        if (selIndex === this.items.length - 1) return;
        this.selectedItem.reInsertInContainerAt(this.items.length - 1);
        this.items.push(this.selectedItem);
        this.items.splice(selIndex, 1);
        const changeditems: ImageData[] = [];
        for (let ii = selIndex; ii < this.items.length; ii++) {
            this.items[ii].getItemData().zIndex = ii;
            changeditems.push(this.items[ii].getItemData());
        }
        this.onItemsChanged(changeditems);
    }


    moveToBottomSelectedImage() {
        const selIndex = this.items.indexOf(this.selectedItem);
        if (selIndex === 0) return;
        this.selectedItem.reInsertInContainerAt(0);
        this.items.splice(selIndex, 1);
        this.items.unshift(this.selectedItem);
        const changeditems = [];
        for (let ii = selIndex; ii >= 0; ii--) {
            this.items[ii].getItemData().zIndex = ii;
            changeditems.push(this.items[ii].getItemData());
        }
        this.onItemsChanged(changeditems);
    }


    setShiftKeyPressed(isPressed: boolean): void {
        this.shiftPressed = isPressed;
        this.selectedItem.updateRotationOnCornerDragging();
    }
}
