import {createAction} from "redux-starter-kit";

export const cellDrop = createAction('pricingMatrix/cellDrop');
export const cellClear = createAction('pricingMatrix/cellClear');
export const classDrop = createAction('pricingMatrix/classDrop');
export const categoryDrop = createAction('pricingMatrix/categoryDrop');
export const matrixDrop = createAction('pricingMatrix/matrixDrop');

export const changeCellGrossAmount = createAction('pricingMatrix/changeCellGrossAmount');
export const changeCellStrikeAmount = createAction('pricingMatrix/changeCellStrikeAmount');
