import {Card, Col, Row} from "react-bootstrap";
import {FormattedDate} from "react-intl";
import ConnectedListView from "../common/listView/ConnectedListView";
import {BATCH_ACTION_RESOLVE_SERVICE_CASE_CANCELLATION_MANUAL} from "../common/listView/ListViewBatchActions";
import React from "react";

const Cancellations = ({order}) => {
    return <>
        {order.cancellations.map((cancellation, index) => (
            <Row key={"cancellation" + index}>
                <Col className="mt-4 col-12">
                    <Card className="mt-4 mb-4">
                        <Card.Body>
                            <h2 className="font-weight-bold pb-3 mb-3">
                                <span>Stornoprozess vom </span>
                                <FormattedDate value={cancellation.createdAt} year="numeric" month="2-digit"
                                               day="2-digit" hour="2-digit" minute="2-digit"/>
                            </h2>
                            <ConnectedListView
                                endpoint={`listingView/orderCancellationCartItem?orderCancellationId=${cancellation.id}`}
                                isShowSelectionWithBatchActions={true}
                                allowedActions={[BATCH_ACTION_RESOLVE_SERVICE_CASE_CANCELLATION_MANUAL]}
                            >
                            </ConnectedListView>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        ))}
    </>
}

export default Cancellations;