import React from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import styles from './navbar.module.scss';
import {AuthConsumer} from "../auth/AuthProvider";
import ToggleHelptexts from "../common/ToggleHelptexts";
import TenantSettingsButton from "../common/TenantSettingsButton";

/**
 * Create a new navbar instance.
 *
 * @param     {Object}      props
 * @property  {String|null} className CSS Selector classes.
 * @property  {String}      home      The home route.
 * @property  {String}      profile   The profile route.
 * @property  {Object}      user      The authenticated user.
 * @return    {JSX}
 */

export default ({
  className
}) => (
  <Navbar className={[styles.Navbar, className].join(' ')} fixed="top">
    <Nav className="ml-auto align-items-center">
      <TenantSettingsButton />
      <ToggleHelptexts/>
      <AuthConsumer>
        {({ logout }) => {
            return (
              <a href="#" onClick={logout} className='border-left pl-4 pr-2'>
                <LogoutIcon />
              </a>
            )
        }}
      </AuthConsumer>
    </Nav>
  </Navbar>
);
