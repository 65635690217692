import React from 'react';
import {Switch} from 'react-router-dom';

import List from './List';
import Edit from './Edit';
import Create from './Create';
import {Container} from 'react-bootstrap';
import SecureRoute from "../auth/SecureRoute";
import ArchivedPurchasableItemTemplateList from "./ArchivedPurchasableItemTemplateList";

const Routing = () => {
    return (
        <Container className={'mt-1'}>
            <Switch>
                <SecureRoute exact path="/event-management/purchasable-item-template/archived" component={ArchivedPurchasableItemTemplateList}/>
                <SecureRoute exact path="/event-management/purchasable-item-template" component={List}/>
                <SecureRoute exact path="/event-management/purchasable-item-template/create" component={Create}/>
                <SecureRoute exact path="/event-management/purchasable-item-template/:id" component={Edit}/>
            </Switch>
        </Container>
    );
};

export default Routing;
