import * as types from './types';

import {put, takeEvery} from 'redux-saga/effects'
import {
    queryListViewError,
    queryListViewSuccess,
} from './actions';

import Api from '../../../api';

export function* queryListViewSaga({type, payload: {endpoint, query}}) {
    const api = new Api();

    try {
        const result = yield api.queryListView(endpoint, query);
        yield put(queryListViewSuccess(endpoint, query, result));
    } catch (error) {
        yield put(queryListViewError(endpoint, query));
    }
}

export default function* componentSaga() {
    yield takeEvery(types.QUERY_LIST_VIEW, queryListViewSaga);
}
