import React, {useState} from 'react';
import styles from "../layoutEditor.module.scss";
import PropTypes from "prop-types";

const TicketQRCode = ({element, editModeEnabled, updateElement}) => {
    const [color, setColor] = useState(element.color);

    const updateColor = (e) => {
        setColor(e.target.value);
    };

    const onBlur = () => {
        updateElement({...element, color: color});
    };

    return <div className={`${styles.LayoutElementInner} ${styles.Rectangle} ${!editModeEnabled ? "interactive" : ""}`}
                style={{
                    width: element.width + "px",
                    height: element.height + "px",
                    backgroundColor: color,
                }}
    >
        {editModeEnabled && <input type="color" onChange={updateColor} onBlur={onBlur}/>}
    </div>;
};

TicketQRCode.propTypes = {
    element: PropTypes.object.isRequired,
    editModeEnabled: PropTypes.bool,
    updateElement: PropTypes.func.isRequired,
};

export default TicketQRCode;
