import React, {Component} from 'react';
import Form from './Form';
import {actions} from '../../state/entities/eventSeries';
import {connect} from 'react-redux';
import {LoadingIndicator} from '../common/LoadingIndicator';
import {Col, Row} from 'react-bootstrap';
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Edit extends Component {

    componentDidMount() {
        this.props.load(this.props.eventSeriesId);
    }

    onSubmit = (eventSeries) => {
        this.props.save(eventSeries);
    };

    render() {
        const {eventSeries, loading, saving} = this.props;
        if (!eventSeries || loading) {
            return <LoadingIndicator/>;
        }
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Eventserie"]} endpoints = {['event_series/breadcrumb']} title = "Eventserien" links={['/event-management/event-series/']} active={[eventSeries.id]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Bearbeiten der Eventserie <em>{eventSeries.name.de}</em></h1>
                </Col>
            </Row>
            <Form eventSeries={eventSeries} onSubmit={this.onSubmit} submitPending={saving} />
        </>;
    }
}

const mapStateToProps = (state, props) => {
    const eventSeriesId = props.match.params.id;
    return {
        eventSeriesId,
        loading: state.entities.eventSeries.loading[eventSeriesId],
        saving: state.entities.eventSeries.saving[eventSeriesId],
        eventSeries: state.entities.eventSeries.byId[eventSeriesId]
    }
};

const mapDispatchToProps = dispatch => {
    return {
        load: id => dispatch(actions.load({id})),
        save: entity => dispatch(actions.save({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
