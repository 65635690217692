import {Assets, Texture} from 'pixi.js';
import {SeatStyle} from '../../types';

const LABEL_SPRITESHEET_NAME = '/sprites.json';
const SEAT_SELECTED_TEXTURE = 'seat_disc.png';
const SEAT_AVAILABLE_TEXTURE = 'seat_circle.png';
const SEAT_UNAVAILABLE_TEXTURE = 'seat_disc.png';

const SEAT_TEXTURE_BY_STATE: Record<SeatStyle, string> = {
    AVAILABLE: SEAT_AVAILABLE_TEXTURE,
    BLOCKED: SEAT_UNAVAILABLE_TEXTURE,
    SELECTED: SEAT_SELECTED_TEXTURE,
};

export async function initLoad(onLoaded: Function) {
    await Assets.load(LABEL_SPRITESHEET_NAME);
    onLoaded();
}


/**
 * Returns the state specific texture for a seat.
 *
 * @param state The state for which the texture should be returned.
 *
 * @throws if there is no matching texture for the seat.
 */
export function getSeatTexture(style: SeatStyle): Texture {
    const texture = Assets.get(LABEL_SPRITESHEET_NAME) //getResourceLoader().resources[LABEL_SPRITESHEET_NAME]
        ?.textures?.[SEAT_TEXTURE_BY_STATE[style]];

    if (!texture) {
        throw new Error('no texture for seat found');
    }

    return texture;
}

//assumingly obsolete with elements placing feature later on
export async function loadBackdropTexture(url: string): Promise<Texture> {
    // Der loadType ist für diesen Fall explizit anzugeben, da anhand des Datei-Suffixes entschieden wird
    // wie die Resource interpretiert wird. Da die URL i.d.R. über kein Suffix einer Bilddatei verfügt
    // würde daher sonst keine Textur erzeugt werden.
//    BACKDROP_LOADER.reset().add({url, loadType: LOAD_TYPE.IMAGE});

  /*  const backdropResources = await new Promise<Record<string, LoaderResource>>((resolve) => {
        BACKDROP_LOADER.load((loader) => {
            resolve(loader.resources);
        });
    })

    const backdrop = backdropResources[url];

    if (!backdrop.texture) {
        throw new Error('failed to load texture for backdrop');
    }

    return backdrop.texture;
*/
    return null;
}

export * from './fonts';
