import {find, keyBy, map, every, sum, isNumber, trim} from "lodash";

export const getLocalItemTemplateById = (pricingMatrix, itemTemplateId) => {
    const itemTemplate = find(pricingMatrix.availableLocalItemTemplates, itemTemplate => itemTemplate.id === itemTemplateId);
    if (itemTemplate) {
        return itemTemplate;
    }
    return null;
};

export const calculateMainPricingComponentAmount = (grossAmount, itemTemplate) => {
    const pricingComponentsGrossAmountSum = sum(map(itemTemplate.pricingComponents, pc => pc.grossAmount));
    return (grossAmount - pricingComponentsGrossAmountSum);
};


const pricingComponentIsComplete = (localOverride, original) => {
    const isEmptyString = val => trim(val).length === 0;

    if (!localOverride) {
        return !isEmptyString(original.productBookingGroup)
            && !isEmptyString(original.productBookingGroupVatKey)
            && isNumber(original.productBookingGroupVatValue);
    }
    return (!isEmptyString(localOverride.productBookingGroup) || (original && !isEmptyString(original.productBookingGroup)))
        && (!isEmptyString(localOverride.productBookingGroupVatKey) || (original && !isEmptyString(original.productBookingGroupVatKey)))
        && (isNumber(localOverride.productBookingGroupVatValue) || (original && isNumber(original.productBookingGroupVatValue)))
};

export const localItemTemplateIsComplete = (localItemTemplate) => {
    const itemTemplateOriginal = localItemTemplate.purchasableItemTemplate;
    const originalPricingComponentsById = keyBy(itemTemplateOriginal.pricingComponents, 'id');

    return pricingComponentIsComplete(localItemTemplate.mainPricingComponent, itemTemplateOriginal.mainPricingComponent)
        && every(localItemTemplate.pricingComponents, localPricingComponent =>
            pricingComponentIsComplete(localPricingComponent, originalPricingComponentsById[localPricingComponent.id])
        );

};
