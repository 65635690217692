import React from "react";
import { Field, getIn } from 'formik';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    InputAdornment,
    IconButton
} from '@mui/material';

import { defaultTo } from 'lodash';
import CurrencyInput from "../form/CurrencyInput";
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';

export const FormikMoneyInputGroup = ({
    name,
    label,
    disabled = false
}) =>
    <Field name={name}>
        {({ field, form }) => {
            const value = defaultTo(field.value, 0);
            const error = getIn(form.errors, field.name);
            const touched = getIn(form.touched, field.name);
            const isInvalid = touched && error;

            return (
                <FormControl fullWidth variant="outlined" error={isInvalid} disabled={disabled}>
                    {label && <InputLabel>{label}</InputLabel>}
                    <CurrencyInput
                        value={value}
                        onChange={(centValue) => {
                            form.setFieldValue(field.name, centValue);
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                {value ? (
                                    <IconButton onClick={() => form.setFieldValue(field.name, '')} edge="end" tabIndex={-1}>
                                        <CancelIcon sx={{ color: '#494949'}} />
                                    </IconButton>
                                ) : ''}
                                {!value && !!error && (
                                    <IconButton edge="end" tabIndex={-1}>
                                        <ErrorIcon sx={{ color: '#B3261E'}} />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        }
                    />
                    {isInvalid && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            );
        }}
    </Field>;
