import {call, put} from 'redux-saga/effects';
import {normalize} from 'normalizr';

/**
 * Generische Saga für das Kopieren von Entitäten.
 *
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 * @param onSuccess Optionale callback Funktion deren Ergebnis "geyielded" wird nachdem die Entität erfolgreich kopiert wurde. Erhält die neu erzeugte Entität als Parameter.
 */
export default ({actions, resource, schema, onSuccess}) => function* ({payload: {entity}}) {
    try {
        const result = yield call(resource.copy, entity.id);
        const response = normalize(result, schema);

        yield put(actions.copySuccess({response}));

        if (onSuccess) {
            yield onSuccess(result)
        }
    } catch (error) {
        yield put(actions.copyError({entity, error}))
    }
}
