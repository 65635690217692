import React from 'react';
import {Col} from 'react-bootstrap';
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import {BATCH_ACTION_MOVE_TO_ACTIVE} from "../common/listView/ListViewBatchActions";
import List from "./List";
import RefreshIcon from '@mui/icons-material/Refresh';
import FeedbackButton from '../common/FeedbackButton';

const LinkToActive = () => {
    return (
        <FeedbackButton
            to={`/event-management/pricing-list`}
            icon={<RefreshIcon />}
            variant='contained'
            className='listLinkCenter'
        >
            Aktuell
        </FeedbackButton>
    );
}

const Breadcrumb = () => {
    return (
        <Breadcrumbs entity={["Preisliste archivieren"]} endpoints = {['archived_pricing_list/breadcrumb']} title = "Preisliste archivieren" links={['/event-management/pricing-list/']} active={[0]} />
    )
}

const ArchivedPricingListList = () => {
    return (
        <List
            linkNearHeader={LinkToActive}
            breadcrumb={Breadcrumb}
            heading="Preisliste archivieren"
            listViewEndpoint="listingView/archivedPricingList"
            allowedBatchActions={[BATCH_ACTION_MOVE_TO_ACTIVE]}
        />
    );
};

export default ArchivedPricingListList;
