import React, {Component} from 'react';
import Form from "./Form";
import {connect} from "react-redux";
import {LoadingIndicator} from "../common/LoadingIndicator";
import {Col, Row} from "react-bootstrap";
import * as pricingClass from "../../state/entities/pricingClass";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Edit extends Component {

    componentDidMount() {
        this.props.loadPricingClass(this.props.pricingClassId);
    }

    onSubmit = (pricingClass, {setErrors}) => {
        this.props.savePricingClass(pricingClass, setErrors);
    };

    render() {
        const {pricingClass, loading, saving} = this.props;
        if (!pricingClass || loading) {
            return <LoadingIndicator/>;
        }
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Preisklasse"]} endpoints = {['pricing_class/breadcrumb']} title = "Preisklassen" links={['/base/pricing-class/']} active={[pricingClass.id]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Bearbeiten der Preisklasse <em>{pricingClass.name}</em></h1>
                </Col>
            </Row>
            <Form pricingClass={pricingClass} onSubmit={this.onSubmit} submitPending={saving} />
        </>;
    }
}

const mapStateToProps = (state, props) => {
    const pricingClassId = props.match.params.id;
    return {
        pricingClassId,
        loading: state.entities.pricingClass.loading[pricingClassId],
        saving: state.entities.pricingClass.saving[pricingClassId],
        pricingClass: state.entities.pricingClass.byId[pricingClassId]
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadPricingClass: id => dispatch(pricingClass.actions.load({id})),
        savePricingClass: entity => dispatch(pricingClass.actions.save({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
