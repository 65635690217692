import React from 'react';
import {Switch} from 'react-router-dom';

import List from './List';
import MatrixEdit from './pricingMatrix/Edit';
import Create from './Create';
import {Container} from 'react-bootstrap';
import SecureRoute from '../auth/SecureRoute';
import ArchivedPricingListList from "./ArchivedPricingListList";

const Routing = () => {
    return (
        <>
            <Container fluid={true} className={'mt-1'}>
                <Switch>
                    <SecureRoute exact path="/event-management/pricing-list/archived" component={ArchivedPricingListList}/>
                    <SecureRoute exact path="/event-management/pricing-list" component={List}/>
                    <SecureRoute exact path="/event-management/pricing-list/create" component={Create}/>
                    <SecureRoute exact path="/event-management/pricing-list/:id" component={MatrixEdit}/>
                </Switch>
            </Container>
        </>
    )
};

export default Routing;
