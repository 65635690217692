import React, {useState} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {FormikTextInputGroup} from "../common/formik/FormikTextInputGroup";
import FeedbackButton from "../common/FeedbackButton";
import Api from "../../api";
import styles from "../form.module.scss";

const api = new Api();
function FormMailSettings({ formik }) {

    const [statusMessage, setStatusMessage] = useState([]);

    const makeSmtpStatusCheckRequest = () => {
        let data = {...formik.values.mailSettings};

        let resp = api.checkTenantSmtpSettings(data);
        resp.then((result) => {
            if (result.errors) {
                setStatusMessage(result.errors);
            } else {
                setStatusMessage([]);
            }
        });
    };

    const getErrorMessages = () => {
        return (<span className="email-validation invalid">
            {statusMessage.join(", ")}
        </span>)
    };

    return (
        <div className={styles.formBox}>
            <Row className="mt-12">
                <Col className="col-md-4">
                    <h2 className={styles.formTitle}>SMTP Konfiguration</h2>
                </Col>
                <Col className="col-md-4">
                    <FeedbackButton
                        onClick={makeSmtpStatusCheckRequest}
                    >
                        Konfiguration prüfen
                    </FeedbackButton>

                    {!statusMessage.length && <span className="email-validation valid">Valid</span>}
                    {!!statusMessage.length && getErrorMessages() }
                </Col>
            </Row>

            <Row>
                <Col className="col-md-4">
                    <Row className="mt-3">
                        <Col className="col-md-12">
                            <FormikTextInputGroup
                                label="Beschreibung"
                                name="mailSettings.description"
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-md-12">
                            <FormikTextInputGroup
                                label="Server"
                                name="mailSettings.server"
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-md-12">
                            <FormikTextInputGroup
                                label="Port"
                                type="number"
                                name="mailSettings.port"
                            />
                        </Col>
                    </Row>
                </Col>
                <Col className="col-md-4">
                    <Row className="mt-3">
                        <Col className="col-md-12">
                            <FormikTextInputGroup
                                label="Username Mailserver"
                                name="mailSettings.username"
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-md-12">
                            <FormikTextInputGroup
                                label="Kennwort Mailserver"
                                name="mailSettings.password"
                                type='password'
                            />
                        </Col>
                    </Row>
                </Col>
                <Col className="col-md-4">
                    <Row className="mt-3">
                        <Col className="col-md-12">
                            <FormikTextInputGroup
                                label="Absender E-Mail-Adresse"
                                name="mailSettings.sender"
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-md-12">
                            <FormikTextInputGroup
                                label="Rückantwort an E-Mail-Adresse"
                                name="mailSettings.replyTo"
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col className="col-md-12">
                            <FormikTextInputGroup
                                label="BCC jeder Mail"
                                name="mailSettings.bcc"
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

        </div>
    )
}

FormMailSettings.propTypes = {
    formik: PropTypes.object,
    tenant: PropTypes.object,
};

export default FormMailSettings;
