import React, {Component} from 'react';
import Form from "./Form";
import * as artist from "../../state/entities/artist";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Create extends Component {

    onSubmit = (artist, {setErrors}) => {
        this.props.createartist(artist, setErrors);
    };

    render() {
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Artist"]} endpoints = {['artist/breadcrumb']} title = "Künstler" links={['/base/artist/']} active={[0]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Erstellen eines neuen Künstler</h1>
                </Col>
            </Row>
            <Form artist={{}} onSubmit={this.onSubmit} submitPending={this.props.createPending} />
        </>;
    }
}

const mapStateToProps = (state) => {
    return {
        createPending: state.entities.artist.createPending
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createartist: entity => dispatch(artist.actions.create({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
