import React from 'react';
import {Col} from 'react-bootstrap';
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import List from "./List";
import {BATCH_ACTION_MOVE_TO_ACTIVE} from "../common/listView/ListViewBatchActions";
import RefreshIcon from '@mui/icons-material/Refresh';
import FeedbackButton from '../common/FeedbackButton';

const LinkToActive = () => {
    return (
        <FeedbackButton
            to={`/base/ticket-layout`}
            icon={<RefreshIcon />}
            variant='contained'
            className='listLinkCenter'
        >
            Aktuell
        </FeedbackButton>
    );
}

const Breadcrumb = () => {
    return (
        <Breadcrumbs entity={["Ticketlayout archivieren"]} endpoints = {['archived_ticket_layout/breadcrumb']} title = "Ticketlayout archivieren" links={['/base/ticket-layout/archived/']} active={[0]} />
    )
}

const ArchivedTicketLayoutList = () => {
    return (
        <List
            linkNearHeader={LinkToActive}
            breadcrumb={Breadcrumb}
            heading="Ticketlayout archivieren"
            listViewEndpoint="listingView/archivedTicketLayout"
            allowedBatchActions={[BATCH_ACTION_MOVE_TO_ACTIVE]}
        />
    );
};

export default ArchivedTicketLayoutList;
