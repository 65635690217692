import React, {Component} from 'react';
import ConnectedListView from '../common/listView/ConnectedListView';
import FeedbackButton from '../common/FeedbackButton';
import {connect} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {get} from 'lodash';
import * as venue from '../../state/entities/venue';
import {Container, Row} from 'react-bootstrap';
import Breadcrumbs from '../common/breadcrumbs/Breadcrumbs';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showCopyConfirmDialog: false,
            venueToCopy: undefined,
            showDeleteConfirmDialog: false,
            venueToDelete: undefined
        }
    }

    showCopyConfirmDialog(venue) {
        this.setState({
            showCopyConfirmDialog: true,
            venueToCopy: venue,
        });
    }

    handleCancelCopy = () => {
        this.setState({showCopyConfirmDialog: false});
    };

    handleConfirmCopy = () => {
        this.setState({showCopyConfirmDialog: false});
        this.props.copyVenue(this.state.venueToCopy);
    };

    showDeleteConfirmDialog(venue) {
        this.setState({
            showDeleteConfirmDialog: true,
            venueToDelete: venue,
        });
    }

    handleCancelDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
    };

    handleConfirmDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
        this.props.deleteVenue(this.state.venueToDelete);
    };

    render() {
        return (
            <Container>
                <Row className="my-4">
                    <Breadcrumbs entity={['Spielstätte']} endpoints={['venue/breadcrumb']} title="Spielstätten" links={['/base/venue/']} active={[0]}/>
                </Row>
                <ConnectedListView
                    heading="Spielstätten"
                    endpoint="listingView/venue"
                    buttonTitle="Neue Spielstätte"
                    buttonLink="/base/venue/create"
                    testid="link_new_venue"
                >
                    {({fields, item}) => (
                        <>
                            <FeedbackButton
                                className="list-link"
                                title="Spielstätte bearbeiten"
                                to={`/base/venue/${item.id}`}
                                icon={<EditNoteOutlinedIcon className='table-icon' />}
                            />
                            <FeedbackButton
                                title="Spielstätte kopieren"
                                className="list-link"
                                onClick={() => this.showCopyConfirmDialog(item)}
                            >
                                <ContentCopyOutlinedIcon className='table-icon' />
                            </FeedbackButton>
                            <FeedbackButton
                                title="Spielstätte löschen"
                                className="list-link"
                                onClick={() => this.showDeleteConfirmDialog(item)}
                            >
                                <DeleteOutlineOutlinedIcon className='table-icon' />
                            </FeedbackButton>
                        </>
                    )}
                </ConnectedListView>


                <Modal show={this.state.showCopyConfirmDialog} onHide={this.handleCancelCopy}>
                    <Modal.Header closeButton>
                        <Modal.Title>Spielstätte kopieren</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Möchten Sie die Spielstätte "{get(this.state, 'venueToCopy.name')}" wirklich
                        kopieren?</Modal.Body>
                    <Modal.Footer>
                        <FeedbackButton variant="outlined" onClick={this.handleCancelCopy}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton onClick={this.handleConfirmCopy}>
                            Kopieren
                        </FeedbackButton>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showDeleteConfirmDialog} onHide={this.handleCancelDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Spielstätte löschen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Möchten Sie die Spielstätte "{get(this.state, 'venueToDelete.name')}" wirklich
                        löschen?</Modal.Body>
                    <Modal.Footer>
                        <FeedbackButton variant="outlined" onClick={this.handleCancelDelete}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton onClick={this.handleConfirmDelete}>
                            Löschen
                        </FeedbackButton>
                    </Modal.Footer>
                </Modal>

            </Container>
        );
    }
}

export default connect(null, {
    deleteVenue: entity => venue.actions.delete({entity}),
    copyVenue: entity => venue.actions.copy({entity}),
})(List);
