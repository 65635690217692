import React from "react";
import styles from "./baseLayout.module.scss";
import { useSelector } from "react-redux";
import { IState, selectSeatsCountWithoutPlacepools } from "../../state/entities/venueEditor/selectors";



interface ITabStatisticsProps {}


const TabStatistics: React.FC<ITabStatisticsProps> = () => {
    const seatsCountWithoutPlacepools = useSelector((state: IState) => selectSeatsCountWithoutPlacepools(state));


    return (
        <div className={styles.tabsContent}>
            <h2 className={styles.tabsContent__title}>Statistiken</h2>
            <div>Plätze ohne zugewiesenen Platzpool: {seatsCountWithoutPlacepools}</div>
        </div>
    );
};



export default TabStatistics;