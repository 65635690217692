import React, {Component} from 'react';
import Form, {cleanupTenant} from "./Form";
import * as tenant from "../../state/entities/tenant";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Create extends Component {

    onSubmit = (tenant, {setErrors}) => {
        this.props.createTenant(cleanupTenant(tenant), setErrors);
    };

    render() {
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Tenant"]} endpoints = {['tenant/breadcrumb']} title = "Mandanten" links={['/base/tenant/']} active={[0]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Erstellen eines neuen Mandanten</h1>
                </Col>
            </Row>
            <Form tenant={{}} onSubmit={this.onSubmit} submitPending={this.props.createPending} />
        </>;
    }
}

const mapStateToProps = (state) => {
    return {
        createPending: state.entities.tenant.createPending
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createTenant: entity => dispatch(tenant.actions.create({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
