import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { range } from 'lodash';
import { FormikTextInputGroup } from '../common/formik/FormikTextInputGroup';
import FormikDateTimeInput from '../common/formik/FormikDateTimeInput';
import Table from 'react-bootstrap/Table';
import { SalesRuleAssociationFormSelect } from './SalesRuleAssociationFormSelect';
import FormikAsyncTypeaheadInput from "../common/formik/FormikAsyncTypeaheadInput";
import Api from "../../api";
import FormikCheckbox from "../common/formik/FormikCheckbox";
import FormikSelect from "../common/formik/FormikSelect";
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { USE_FOR, SECONDARY_MARKET_FEE_FACTOR_CODE } from "./FormData";

const api = new Api();

const SalesRuleAssociationForm = ({ associationFormik, allDeliveryGroups, ticketLayouts }) => {
    const [checkPAHToggled, setCheckPAHToggled] = useState(false);
    const [isShowActiveMemberSinceAt, setIsShowActiveMemberSinceAt] = useState(!!associationFormik.values.activeMembersOnly);

    const availableTicketLayoutsForTicketLayout = ticketLayouts.filter(
        tl =>
            !tl.archived ||
            (
                associationFormik.values.ticketLayout &&
                tl.id === associationFormik.values.ticketLayout.id
            )
    );

    const availableTicketLayoutsForPrintAtHomeTicketLayout = ticketLayouts.filter(
        tl =>
            !tl.archived ||
            (
                associationFormik.values.printAtHomeTicketLayout &&
                tl.id === associationFormik.values.printAtHomeTicketLayout
            )
    );

    useEffect(() => {
        // if the p@h deliveryGroups are unchecked the printAtHomeTicketLayout should be set to null if it was selected before
        if (checkPAHToggled && !associationFormik.values.deliveryGroups.find(group => group.shippingCode === 'PAH' || group.shippingCode === 'PAHWOI')) {
            associationFormik.setFieldValue('printAtHomeTicketLayout', null);
        }
        setCheckPAHToggled(false);
    }, [checkPAHToggled]);

    const toggleActiveMembersOnly = (event) => {
        setIsShowActiveMemberSinceAt(!!event.target.checked);
        associationFormik.setFieldValue('activeMembersOnly', !!event.target.checked);
    }

    const ListRow = ({ element, associationFormik }) => {

        // get index of deliveryGroup in selectedDeliveryGroups array
        const getIndexSelectedDeliveryGroup = () => {
            return associationFormik.values.deliveryGroups.findIndex(dg => dg.shippingCode === element.shippingCode);
        };

        // get options for days from 1 to 16
        const getOptions = (key) => {
            let options = range(1, 16).map(i => ({ id: i, name: i.toString() }))
            let dayNaming = ' Tage';
            if (element.considerOnlyWorkingDays) {
                dayNaming = ' Werktage';
            }
            if (key === 'activeUntilWorkingDaysBeforeEventStartDate') {
                options = options.map(op => {
                    return ({ id: op.id, name: op.name + dayNaming })
                });
            }
            return options;
        };

        const getValue = (key) => {
            if (getIndexSelectedDeliveryGroup() >= 0) {
                return associationFormik.values.deliveryGroups[getIndexSelectedDeliveryGroup()][key]
            } else {
                return null;
            }
        };
        // add or remove toggled deliveryGroups to / from selectedDeliveryGroups array
        const toggleDeliveryGroup = (event) => {
            let newDeliveryGroups = [];
            if (event.target.checked) {
                newDeliveryGroups = [...associationFormik.values.deliveryGroups, {
                    'shippingCode': element.shippingCode,
                    'activeFromWorkingDaysBeforeEventStartDate': getValue('activeFromWorkingDaysBeforeEventStartDate'),
                    'activeUntilWorkingDaysBeforeEventStartDate': getValue('activeUntilWorkingDaysBeforeEventStartDate'),
                    'lastApplicableDayTime': getValue('lastApplicableDayTime')
                }];
            } else {
                newDeliveryGroups = associationFormik.values.deliveryGroups.filter(dg => dg.shippingCode !== element.shippingCode);
                // it should be checked if one of the p@h deliveryGroups have been deselected here and this will
                // be done in useEffect above if this value is set to true
                setCheckPAHToggled(true);
            }
            associationFormik.setFieldValue('deliveryGroups', newDeliveryGroups);
        }

        // this function is called when either the lastApplicableDayTime or one of the other values is changed
        const onSelectionChange = (eventOrDate, key) => {
            // if this deliveryGroup is already checked it will be found in the selectedDeliveryGroups array
            
            if (getIndexSelectedDeliveryGroup() >= 0) {
                const index = getIndexSelectedDeliveryGroup();
                let newDeliveryGroup = {};
                // lastApplicableDayTime doesn't send an event but a date, so it can be set as date
                if (key === 'lastApplicableDayTime') {
                    newDeliveryGroup = {
                        ...associationFormik.values.deliveryGroups[getIndexSelectedDeliveryGroup()],
                        [key]: eventOrDate && eventOrDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
                    };
                    // the other values send events so the value is in event.target.value
                } else {
                    newDeliveryGroup = {
                        ...associationFormik.values.deliveryGroups[getIndexSelectedDeliveryGroup()],
                        [key]: eventOrDate && eventOrDate.target.value
                    };
                }
                let newDeliveryGroups = getIn(associationFormik.values, 'deliveryGroups', []);
                newDeliveryGroups = [...newDeliveryGroups];
                newDeliveryGroups.splice(index, 1, newDeliveryGroup);
                associationFormik.setFieldValue('deliveryGroups', newDeliveryGroups);
                // if this deliveryGroup is not checked yet it will be added to the selectedDeliveryGroups array here with the changed value and is therefore checked automatically
            } else {
                let newDeliveryGroup = {
                    'shippingCode': element.shippingCode,
                    'activeFromWorkingDaysBeforeEventStartDate': getValue('activeFromWorkingDaysBeforeEventStartDate'),
                    'activeUntilWorkingDaysBeforeEventStartDate': getValue('activeUntilWorkingDaysBeforeEventStartDate'),
                    'lastApplicableDayTime': '23:59',
                };
                // only when clicking on lastApplicableDayTime the value will be default, otherwise the selected value should be set
                if (key !== 'lastApplicableDayTime') {
                    newDeliveryGroup = {
                        ...newDeliveryGroup,
                        [key]: eventOrDate.target.value
                    };
                }
                const newDeliveryGroups = [...associationFormik.values.deliveryGroups, newDeliveryGroup];
                associationFormik.setFieldValue('deliveryGroups', newDeliveryGroups);
            }
        };

        let disabled = false;
        let checked = !!associationFormik.values.deliveryGroups.find(dg => element.shippingCode === dg.shippingCode);
        if (element.shippingCode === 'DGT') {
            disabled = true;
            checked = true;
        }

        let date = new Date(element.lastApplicableDateTime);

        return (
            // FIXME: use Formik <FieldArray> instead of calculating the field names
            <tr>
                <td>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id={`check${element.shippingCode}`}
                                    checked={checked}
                                    onChange={(event) => toggleDeliveryGroup(event)}
                                    data-testid={`check_${element.shippingCode}`}
                                    disabled={disabled}
                                />
                            }
                            label={element.description}
                        />
                    </FormGroup>
                </td>
                <td>
                    <SalesRuleAssociationFormSelect
                        name={`deliveryGroups[${getIndexSelectedDeliveryGroup()}].activeFromWorkingDaysBeforeEventStartDate`}
                        options={getOptions('activeFromWorkingDaysBeforeEventStartDate')}
                        emptyValueLabel="always"
                        onChange={(event) => onSelectionChange(event, 'activeFromWorkingDaysBeforeEventStartDate')}
                        disabled={disabled}
                        testid={`active_from_${element.shippingCode}`}
                    />
                </td>
                <td>
                    <SalesRuleAssociationFormSelect
                        name={`deliveryGroups[${getIndexSelectedDeliveryGroup()}].activeUntilWorkingDaysBeforeEventStartDate`}
                        options={getOptions('activeUntilWorkingDaysBeforeEventStartDate')}
                        emptyValueLabel={`Spieltag(${date.toLocaleDateString()})`}
                        onChange={(event) => onSelectionChange(event, 'activeUntilWorkingDaysBeforeEventStartDate')}
                        disabled={disabled}
                        testid={`active_until_${element.shippingCode}`}
                    />
                </td>
                <td className="sales-rules-time-td">
                    <FormikDateTimeInput enableTime
                        noCalendar
                        disabled={disabled}
                        onChange={(date) => onSelectionChange(date, 'lastApplicableDayTime')}
                        name={`deliveryGroups[${getIndexSelectedDeliveryGroup()}].lastApplicableDayTime`} />
                </td>
            </tr>
        )
    }

    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        <p>Versandarten</p>
                        <Table striped bordered hover className="overlay-table">
                            <thead>
                                <tr>
                                    <th>
                                        <span>Versandgruppe</span>
                                    </th>
                                    <th>
                                        <span>ab Tage vorher verfügbar</span>
                                    </th>
                                    <th>
                                        <span>bis Tage vorher</span>
                                    </th>
                                    <th>
                                        <span>um</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {allDeliveryGroups.map((element) =>
                                    <ListRow key={element.shippingCode} element={element} associationFormik={associationFormik} />
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <FormikSelect
                            name="ticketLayout"
                            label="Ticketlayout"
                            options={availableTicketLayoutsForTicketLayout}
                            testid="ticket_layout"
                            emptyValueLabel="Bitte wählen ..."
                        />
                    </Col>
                    <Col>
                        <FormikTextInputGroup label="Max. Tickets" name="maxTicketsPerUser" type="number" testid="max_tickets" />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <FormikDateTimeInput enableTime label="Gültig von" name="validFrom" testid="valid_from" />
                    </Col>
                    <Col>
                        <FormikDateTimeInput enableTime label="Gültig bis" name="validTo" testid="valid_to" />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <FormikAsyncTypeaheadInput
                            id="tags"
                            label="Tags"
                            onSearch={query => api.getTagsForSalesRulesByQuery(query)}
                            minLength={0}
                            testid="tags"
                            multiple={true}
                            arrayOfStrings={true}
                            allowNew={true}
                        />
                    </Col>
                    <Col>
                        <FormikSelect
                            name="printAtHomeTicketLayout"
                            label="Print at Home Ticketlayout"
                            options={availableTicketLayoutsForPrintAtHomeTicketLayout}
                            testid="pah_ticket_layout"
                            emptyValueLabel="Bitte wählen ..."
                        />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Row>
                            <Col>
                                <p className="ml-2">Ticketbörse</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormikSelect
                                    name="secondaryMarketFeeFactorCode"
                                    label="Ticketbörsengebühr"
                                    options={SECONDARY_MARKET_FEE_FACTOR_CODE}
                                    isShowEmptyValue={false}
                                />
                            </Col>
                            <Col>
                                <FormikSelect
                                    name="useFor"
                                    label="Verkaufsregel benutzen für"
                                    options={USE_FOR}
                                    displayEmpty={true}
                                    emptyValueLabel="Platzpools und Preisliste"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-md-12">
                                <FormikCheckbox label="Tickets aus dieser Regel dürfen in die Ticketbörse eingestellt werden" name="useSecondaryMarket" />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-md-12">
                                <FormikCheckbox label="Download-Link für Print@Home-Tickets in Web/Wallet generieren" name="provideDownloadUrl" />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-md-12">
                                <div className="form-group">
                                    <FormikCheckbox
                                        name="activeMembersOnly"
                                        id="activeMembersOnly"
                                        label="Dauer der Mitgliedschaft prüfen"
                                        checked={associationFormik.values.activeMembersOnly}
                                        onChange={toggleActiveMembersOnly}
                                    />
                                </div>
                            </Col>
                            {isShowActiveMemberSinceAt && <Col className="col-md-6">
                                <FormikDateTimeInput label="Muss Mitglied sein seit:" name="activeMemberSinceAt" />
                            </Col>}
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
};

SalesRuleAssociationForm.propTypes = {
    ticketLayouts: PropTypes.any,
    associationFormik: PropTypes.object,
    element: PropTypes.object,
    allDeliveryGroups: PropTypes.array
};

export default SalesRuleAssociationForm;
