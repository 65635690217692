import React, { useState, useRef } from 'react';
import {Container, Col, Row} from 'react-bootstrap';
import ConnectedListView from '../common/listView/ConnectedListView';
import FeedbackButton from '../common/FeedbackButton';
import {connect} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {get} from 'lodash';
import * as placePool from "../../state/entities/placePool";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import { BATCH_ACTION_MOVE_TO_ARCHIVE} from "../common/listView/ListViewBatchActions";
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

const LinkToArchived = () => {
    return (
        <FeedbackButton
            to={`/base/place-pool/archived`}
            icon={<RefreshIcon />}
            variant='contained'
            className='listLinkCenter'
        >
            Archiv
        </FeedbackButton>
    );
}

const Breadcrumb = () => {
    return (
        <Breadcrumbs entity={["Platzpool"]} endpoints = {['place_pool/breadcrumb']} title = "Platzpools" links={['/base/place-pool/']} active={[0]} />
    )
}

const List = ({
                  deletePlacePool,
                  copyPlacePool,
                  linkNearHeader = LinkToArchived,
                  breadcrumb = Breadcrumb,
                  heading = 'Platzpools',
                  listViewEndpoint = 'listingView/placePool',
                  allowedBatchActions = [BATCH_ACTION_MOVE_TO_ARCHIVE]
}) => {
    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [placePoolToDelete, setPlacePoolToDelete] = useState(undefined);
    const [showCopyConfirmDialog, setShowCopyConfirmDialog] = useState(false);
    const [placePoolToCopy, setPlacePoolToCopy] = useState(undefined);

    const showDeleteConfirmingDialog = (placePool) => {
        setShowDeleteConfirmDialog(true);
        setPlacePoolToDelete(placePool);
    };

    const handleCancelDelete = () => {
        setShowDeleteConfirmDialog(false);
    };

    const handleConfirmDelete = () => {
        setShowDeleteConfirmDialog(false);
        deletePlacePool(placePoolToDelete);
    };

    const showCopyConfirmingDialog = (placePool) => {
        setShowCopyConfirmDialog(true);
        setPlacePoolToCopy(placePool);
    };

    const handleCancelCopy = () => {
        setShowCopyConfirmDialog(false);
    };

    const handleConfirmCopy = () => {
        setShowCopyConfirmDialog(false);
        copyPlacePool(placePoolToCopy);
    };



    return (
        <Container>
            <Row className="my-4">
                {breadcrumb()}
            </Row>

            <ConnectedListView
                endpoint={listViewEndpoint}
                linkNearHeading={linkNearHeader}
                heading={heading}
                buttonTitle="Neuer Platzpool"
                buttonLink="/base/place-pool/create"
                isShowSelectionWithBatchActions={true}
                allowedActions={allowedBatchActions}
            >
                {({fields, item}) => (
                    <>
                        <FeedbackButton
                            className="list-link"
                            title="Platzpool bearbeiten"
                            to={`/base/place-pool/${item.id}`}
                            icon={<EditNoteOutlinedIcon className='table-icon' />}
                        />
                        <FeedbackButton title="Platzpool kopieren" className="list-link" onClick={() => showCopyConfirmingDialog(item)}>
                            <ContentCopyOutlinedIcon className='table-icon' />
                        </FeedbackButton>
                        <FeedbackButton title="Platzpool löschen" className="list-link" onClick={() => showDeleteConfirmingDialog(item)}>
                            <DeleteOutlineOutlinedIcon className='table-icon' />
                        </FeedbackButton>
                    </>
                )}
            </ConnectedListView>


            <Modal show={showCopyConfirmDialog} onHide={handleCancelCopy}>
                <Modal.Header closeButton>
                    <Modal.Title>Platzpool kopieren</Modal.Title>
                </Modal.Header>
                <Modal.Body>Möchten Sie den Platzpool "{get(placePoolToCopy, 'name')}" wirklich
                    kopieren?</Modal.Body>
                <Modal.Footer>
                    <FeedbackButton variant="outlined" onClick={handleCancelCopy}>
                        Abbrechen
                    </FeedbackButton>
                    <FeedbackButton onClick={handleConfirmCopy}>
                        Kopieren
                    </FeedbackButton>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteConfirmDialog} onHide={handleCancelDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>Platzpool löschen</Modal.Title>
                </Modal.Header>
                <Modal.Body>Möchten Sie den Platzpool "{get(placePoolToDelete, 'name')}" wirklich löschen?</Modal.Body>
                <Modal.Footer>
                    <FeedbackButton variant="outlined" onClick={handleCancelDelete}>
                        Abbrechen
                    </FeedbackButton>
                    <FeedbackButton variant="primary" onClick={handleConfirmDelete} data-testid="delete">
                        Löschen
                    </FeedbackButton>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        deletePlacePool: entity => dispatch(placePool.actions.delete({entity})),
        copyPlacePool: entity => dispatch(placePool.actions.copy({entity}))
    }
};
export default connect(null, mapDispatchToProps)(List);
