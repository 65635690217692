import React, {useState} from 'react';
import * as yup from 'yup';
import {Field, Formik} from 'formik';
import {Card, Col, Form as BootstrapForm, Form as ReactForm, Row} from 'react-bootstrap';
import {FormikTextInputGroup} from '../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../common/FeedbackButton';
import Footer from '../layout/Footer';
import ConfirmModal from "../common/modal/ConfirmModal";
import {FormikCheckbox} from "../common/formik/FormikCheckbox";
import styles from "../form.module.scss";
import debounce from "debounce-promise";
import Api from "../../api";
import {LoadingIndicator} from "../common/LoadingIndicator";
import {memoize} from '../../form/fieldValidation';

const FORM_SCHEMA = yup.object().shape({
    name: yup.string().required().default(''),
    ident: yup.string().required().min(1).max(5).default(''),
    color: yup.string().required().default('#000000')
});

const api = new Api();
const getPricingCategoryByIdentDebounced = debounce(ident => api.getPricingCategoryByIdent(ident), 500);

/**
 * Renders the form to enter the data for a pricing category.
 *
 * @param {Object} pricingCategory The pricingCategory whose data is to be edited.
 * @param {boolean} submitPending Flag to signal if a submit is currently in progress.
 * @param {function} onSubmit Callback to perform on submit.
 */
const Form = ({pricingCategory, submitPending, onSubmit}) => {
    const [isArchived, setIsArchived] = useState(pricingCategory.archived);
    const [identIsLoading, setIdentIsLoading] = useState(false);

    const validateIdent = memoize({
        initalValue: pricingCategory.ident,
        async validate(ident) {
            if (!ident) {
                return;
            }

            setIdentIsLoading(true);
            const responsePricingCategory = await getPricingCategoryByIdentDebounced(ident);
            setIdentIsLoading(false);
            if (responsePricingCategory && pricingCategory.id !== responsePricingCategory.id) {
                return 'Kurzbezeichnung bereits vergeben';
            }
        }
    });
    const ArchivedStatus = ({setFieldValue}) => {
        const confirmChangeStatus = () => () => {
            let newStatus = !isArchived
            setIsArchived(newStatus);
            setFieldValue('archived', newStatus);
        };
        return (
            <ConfirmModal title="Status ändern"
                          body={(isArchived) ? `Preiskategorie wieder reaktivieren?` : `Preiskategorie ins Archiv verschieben?`}
                          cancelLabel="Abbrechen"
                          confirmLabel="Bestätigen"
            >
                {confirm => (
                    <FormikCheckbox
                        id="archived"
                        label="Preiskategorie archivieren"
                        onChange={(e) => confirm(confirmChangeStatus(e))}
                        name="archived"
                        checked={isArchived}
                    />
                )}
            </ConfirmModal>
        )
    }

    return (
        <Formik initialValues={{...FORM_SCHEMA.default(), ...pricingCategory}}
                validationSchema={FORM_SCHEMA}
                onSubmit={onSubmit}
        >
            {({handleSubmit, setFieldValue}) => (
                <form onSubmit={handleSubmit} className={styles.formCotainer}>
                    <div className={styles.formBox}>
                        <h2 className={styles.formTitle}>Allgemein</h2>
                        <Row className="mt-3">
                            <Col className="col-md-4">
                                <FormikTextInputGroup label="Name*" name="name" testid="name"/>
                            </Col>
                            <Col className="col-md-4">
                                <FormikTextInputGroup label="Kurzbezeichnung*" name="ident" testid="ident" validate={validateIdent}/>
                            </Col>
                            <Col className="col-md-4">
                                <div className='MuiFormWrapper'>
                                    <label className='MuiInputLabel'>Farbe</label>
                                    <Field data-testid="color" type="color" name="color" className="form-control"/>
                                </div>
                            </Col>
                        </Row>
                        <ArchivedStatus setFieldValue={setFieldValue}/>
                        {identIsLoading && <LoadingIndicator/>}
                    </div>
                    <Footer>
                        <FeedbackButton to={`/base/pricing-category`}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton
                            type="submit"
                            busy={submitPending}
                        >
                            Speichern
                        </FeedbackButton>
                    </Footer>
                </form>
            )}
        </Formik>
    )
}

Form.propTypes = {
    onSubmit: PropTypes.any,
    submitPending: PropTypes.any,
    pricingCategory: PropTypes.any,
};

export default Form;
