import React, {Component} from 'react';
import * as yup from 'yup';
import {Formik} from 'formik';
import {Col, Row} from 'react-bootstrap';
import {FormikTextInputGroup} from '../common/formik/FormikTextInputGroup';
import * as PropTypes from 'prop-types';
import FeedbackButton from '../common/FeedbackButton';
import Footer from "../layout/Footer";
import {connect} from "react-redux";
import styles from "../form.module.scss";

const INITIAL_VALUES = {
    name: {
        de: '',
        en: ''
    }
};

const FORM_SCHEMA = yup.object().shape({
    name: yup.object().shape({
        de: yup.string().required(),
        en: yup.string().required()
    })
});

class Form extends Component {
    render() {
        let {onSubmit, submitPending, eventCategory, helpTextsVisible} = this.props;

        return <Formik initialValues={{...INITIAL_VALUES, ...eventCategory}}
                       validationSchema={FORM_SCHEMA}
                       onSubmit={onSubmit}
        >
            {formik => {
                return (
                    <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                        <div className={styles.formBox}>
                            <h2 className={styles.formTitle}>Allgemein</h2>
                            <Row className="mt-3">
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Eventart (deutsch)" name="name.de" testid="name_german"
                                                            helpTextsVisible={helpTextsVisible} helpText=""/>
                                </Col>
                                <Col className="col-md-4">
                                    <FormikTextInputGroup label="Eventart (englisch)" name="name.en" testid="name_english"
                                                            helpTextsVisible={helpTextsVisible} helpText=""/>
                                </Col>
                            </Row>
                        </div>
                        <Footer>
                            <FeedbackButton to={`/base/event-category`}>
                                Abbrechen
                            </FeedbackButton>
                            <FeedbackButton
                                type="submit"
                                busy={submitPending}
                            >
                                Speichern
                            </FeedbackButton>
                        </Footer>
                    </form>
                )
            }}
        </Formik>
    }
}

Form.propTypes = {
    onSubmit: PropTypes.any,
    submitPending: PropTypes.any,
    eventCategory: PropTypes.any,
    requestErrors: PropTypes.any
};


const mapStateToProps = (state, props) => {

    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps)(Form);
