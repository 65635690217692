import React from 'react';
import Form from "./Form";
import * as salesRule from "../../state/entities/salesRule";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

const Create = ({createPending, createSalesRule}) => {

    const onSubmit = (salesRule, {setErrors}) => {
        createSalesRule(salesRule, setErrors);
    };


    return <>
        <Row className="my-4">
            <Breadcrumbs entity={["Verkaufsregel"]} endpoints = {['sales_rule/breadcrumb']} title = "Verkaufsregeln" links={['/event-management/sales-rule/']} active={[0]} />
        </Row>
        <Row className="mb-4 mt-4">
            <Col className="pt-3 col-sm-8">
                <h1>Erstellen einer neuen Verkaufsregel</h1>
            </Col>
        </Row>
        <Form salesRule={{}} onSubmit={onSubmit} submitPending={createPending}/>
    </>;

}

const mapStateToProps = (state, props) => {
    return {
        createPending: state.entities.salesRule.createPending
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createSalesRule: entity => dispatch(salesRule.actions.create({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
