import React from 'react';
import TextContent from "../layout/TextContent";
import {Link} from "react-router-dom";

const NotFound = () => {
    return <TextContent>
        <h1>Fehler</h1>
        <p>
            Leider konnte die angefragte Seite nicht gefunden werden. Klicken Sie auf diesen <Link to="/">Link</Link>
            um zum Start zurückzukehren.
        </p>
    </TextContent>
};

export default NotFound;
