import React, {Component} from 'react';
import Form from "./Form";
import * as venuePlan from "../../../state/entities/venuePlan";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import * as venue from "../../../state/entities/venue";
import {LoadingIndicator} from "../../common/LoadingIndicator";
import Breadcrumbs from "../../common/breadcrumbs/Breadcrumbs";

class Create extends Component {

    componentDidMount() {
        this.props.loadVenue(this.props.venueId);
    }

    onSubmit = (venuePlan, {setErrors}) => {
        this.props.createVenuePlan(venuePlan, setErrors);
    };

    render() {
        const {venue, venueLoading, venueId, createPending} = this.props;
        if (venueLoading) {
            return <LoadingIndicator/>;
        }
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Spielstätte", "Saalplan"]} endpoints = {['venue/breadcrumb', `venue/${venue.id}/venue_plan/breadcrumb`]} title = "Saalpläne" links={['/base/venue/', `/base/venue/${venue.id}/venue-plan/`]} active={[venue.id]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Erstellen eines neuen Saalplans für die Spielstätte {venue.name}</h1>
                </Col>
            </Row>
            <Form venuePlan={{}} onSubmit={this.onSubmit} submitPending={createPending} venueId={venueId} />
        </>;
    }
}

const mapStateToProps = (state, props) => {
    const venueId = props.match.params.id;
    const venue = state.entities.venue.byId[venueId];

    return {
        venueId,
        venue: state.entities.venue.byId[venueId],
        venueLoading: !venue || state.entities.venue.loading[venueId],
        createPending: state.entities.venuePlan.createPending
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createVenuePlan: entity => dispatch(venuePlan.actions.create({entity})),
        loadVenue: id => dispatch(venue.actions.load({id})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
