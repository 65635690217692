import React from 'react';
import { Field, getIn, FormikProps, FieldProps } from 'formik';
import { get, isArray } from 'lodash';
import {
    FormControl,
    InputLabel,
    Select as MuiSelect,
    MenuItem,
    FormHelperText
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

interface Option {
    [key: string]: any;
}

interface IFormikObjectSelectProps {
    name: string;
    label: string;
    keyProperty: string;
    labelProperty: string;
    options: Option[];
    isSetArray?: boolean;
    emptyValueLabel?: string;
    secondLabelProperty?: string;
    isShowEmptyValue?: boolean;
    displayEmpty?: boolean;
    disabled?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface FormValues {
    [field: string]: string;
}

export const FormikObjectSelect: React.FC<IFormikObjectSelectProps> = ({
    name,
    label,
    options,
    emptyValueLabel = 'Bitte wählen...',
    isShowEmptyValue = true,
    displayEmpty = true,
    isSetArray = false,
    keyProperty = 'id',
    labelProperty = 'de',
    secondLabelProperty,
    onChange = () => {},
    disabled
}) => {
    return (
        <Field name={name}>
            {({ field, form }: FieldProps<string, FormikProps<FormValues>>) => {
                const errorMessage = getIn(form.errors, field.name);
                const isInvalid = getIn(form.touched, field.name) && errorMessage;
                const getFieldId = isSetArray ? get(field, 'value[0].id') : get(field, 'value.id');

                const getValue = () => {
                    if (!getIn(form.values, name)) return '';
                    if (isArray(getIn(form.values, name)) && isSetArray) {
                        return (getIn(form.values, name)[0] || {})[keyProperty];
                    } else {
                        return (getIn(form.values, name) || {})[keyProperty];
                    }
                }

                const handleChange = (event: any) => {
                    const getCurrentValue = options.find(o => o[keyProperty] === event.target.value) || null;
                    if (!getCurrentValue) {
                        form.setFieldValue(name, '');
                    } else {
                        form.setFieldValue(name, isSetArray ? [getCurrentValue] : getCurrentValue);
                        onChange(event.target.value)
                    }
                };

                return (
                    <FormControl fullWidth error={isInvalid} variant="outlined">
                        <InputLabel>{label}</InputLabel>
                        <MuiSelect
                            {...field}
                            label={label}
                            value={getValue()}
                            displayEmpty={displayEmpty}
                            disabled={!!disabled}
                            onChange={handleChange}
                            renderValue={(selected: any) => {
                                if (!selected) {
                                  return <em>{emptyValueLabel}</em>;
                                }
                                const selectedOption = options.find(option => option[keyProperty] === selected);
                                const displayedName = selectedOption ? typeof selectedOption.name === 'string' ? selectedOption.name : selectedOption.name[labelProperty] : '';
                                return displayedName;
                            }}
                        >
                            {isShowEmptyValue && (
                                <MenuItem value="">
                                    <em>{emptyValueLabel}</em>
                                </MenuItem>
                            )}
                            {options.map(( option ) => {
                                const displayedName = typeof option.name === 'string' ? option.name : option.name[labelProperty];
                                return (
                                    <MenuItem key={option[keyProperty]} value={option[keyProperty]}>
                                        {getFieldId === option[keyProperty] && <CheckIcon />}
                                        {secondLabelProperty
                                            ? (`${displayedName} (${get(option, secondLabelProperty)})`)
                                            : displayedName
                                        }
                                    </MenuItem>
                                )
                            })}
                        </MuiSelect>
                        {isInvalid && <FormHelperText>{errorMessage}</FormHelperText>}
                    </FormControl>
                );
            }}
        </Field>
    )

};

export default FormikObjectSelect
