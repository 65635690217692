import React, {useState} from 'react';
import * as yup from 'yup';
import {Formik} from 'formik';
import {Card, Col, Row} from 'react-bootstrap';
import {FormikTextInputGroup} from '../common/formik/FormikTextInputGroup';
import FeedbackButton from '../common/FeedbackButton';
import Footer from "../layout/Footer";
import {connect} from "react-redux";
import ConfirmModal from "../common/modal/ConfirmModal";
import styles from "../form.module.scss";

const INITIAL_VALUES = {
    name: '',
    archived: false
};

const FORM_SCHEMA = yup.object().shape({
    name: yup.string().required(),
    archived: yup.bool()
});


const Form = ({onSubmit, submitPending, pricingList, helpTextsVisible}) => {

    const [isArchived, setIsArchived] = useState(pricingList.archived);
    const ArchivedStatus = ({setFieldValue}) => {
        const confirmChangeStatus = () => () => {
            let newStatus = !isArchived
            setIsArchived(newStatus);
            setFieldValue('archived', newStatus);
        };
        return (
            <ConfirmModal title="Status ändern"
                          body={(isArchived) ? `Preisliste wieder reaktivieren?`: `Preisliste ins Archiv verschieben?`}
                          cancelLabel="Abbrechen"
                          confirmLabel="Bestätigen"
            >
                {confirm => (
                    <>
                        <label><input
                            id="archived"
                            type="checkbox"
                            checked={isArchived}
                            onChange={(e) => confirm(confirmChangeStatus(e))}
                            name="archived"
                        /> Preisliste archivieren</label>
                    </>

                )}
            </ConfirmModal>
        )
    }

    return <Formik initialValues={{...INITIAL_VALUES, ...pricingList}}
                   validationSchema={FORM_SCHEMA}
                   onSubmit={onSubmit}
    >
        {formik => (
            <form onSubmit={formik.handleSubmit} className={styles.formCotainer}>
                <div className={styles.formBox}>
                    <h2 className={styles.formTitle}>Allgemein</h2>
                    <Row className="mt-3">
                        <Col className="col-md-4">
                            <FormikTextInputGroup label="Name" name="name" testid="name"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-md-2" style={{padding: "12px", paddingLeft: "16px"}}>
                            <ArchivedStatus setFieldValue={formik.setFieldValue}/>
                        </Col>
                    </Row>
                </div>
                <Footer>
                    <FeedbackButton to={`/event-management/pricing-list`}>
                        Abbrechen
                    </FeedbackButton>
                    <FeedbackButton
                        type="submit"
                        busy={submitPending}
                    >
                        Speichern
                    </FeedbackButton>
                </Footer>
            </form>
        )}
    </Formik>
};


const mapStateToProps = (state, props) => {

    const helpTextsVisible = state.helpTextsToggle;

    return {
        helpTextsVisible
    }
};

export default connect(mapStateToProps)(Form);

