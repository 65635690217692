import React, {Component} from 'react';
import Form from './FormEdit';
import {connect} from 'react-redux';
import {LoadingIndicator} from '../common/LoadingIndicator';
import {Col, Row} from 'react-bootstrap';
import * as venue from '../../state/entities/venue';
import VerticalSpacer from '../layout/VerticalSpacer';
import Breadcrumbs from '../common/breadcrumbs/Breadcrumbs';

class Edit extends Component {

    componentDidMount() {
        this.props.loadVenue(this.props.venueId);
    }

    onSubmit = (venue, {setErrors}) => {
        this.props.saveVenue(venue, setErrors);
    };

    render() {
        const {venue, loading, saving} = this.props;
        if (!venue || loading) {
            return <LoadingIndicator/>;
        }
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={['Spielstätte', 'Saalplan']} endpoints={['venue/breadcrumb', `venue/${venue.id}/venue_plan/breadcrumb`]} title="Saalpläne" links={['/base/venue/', `/base/venue/${venue.id}/venue-plan/`]} active={[venue.id]}/>
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Bearbeiten der Spielstätte <em>{venue.name}</em></h1>
                </Col>
            </Row>
            <Form venue={venue} onSubmit={this.onSubmit} submitPending={saving}/>
            <VerticalSpacer size={4}/>
        </>;
    }
}

const mapStateToProps = (state, props) => {
    const venueId = props.match.params.id;
    return {
        venueId,
        loading: state.entities.venue.loading[venueId],
        saving: state.entities.venue.saving[venueId],
        venue: state.entities.venue.byId[venueId]
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadVenue: id => dispatch(venue.actions.load({id})),
        saveVenue: entity => dispatch(venue.actions.save({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
