import React, {Component} from 'react';
import Form from "./Form";
import * as pricingList from "../../state/entities/pricingList";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";

class Create extends Component {

    onSubmit = (pricingList) => {
        this.props.createPricingList(pricingList);
    };

    render() {
        return <>
            <Row className="my-4">
                <Breadcrumbs entity={["Preisliste"]} endpoints = {['pricing_list/breadcrumb']} title = "Preislisten" links={['/event-management/pricing-list/']} active={[0]} />
            </Row>
            <Row className="mb-4 mt-4">
                <Col className="pt-3 col-sm-8">
                    <h1>Erstellen einer neuen Preisliste</h1>
                </Col>
            </Row>
            <Form pricingList={{}} onSubmit={this.onSubmit} submitPending={this.props.createPending}/>
        </>;
    }
}

const mapStateToProps = (state) => {
    return {
        createPending: state.entities.pricingList.createPending
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createPricingList: entity => dispatch(pricingList.actions.create({entity})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
