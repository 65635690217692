import React from 'react';
import { Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { IState, selectVenuePlan } from '../../state/entities/venueEditor/selectors';
import styles from "./infoBar.module.scss";




const InfoBar: React.FC = () => {
  const venuePlan = useSelector((state: IState) => selectVenuePlan(state));

  return (
    <Box className={styles.infoBar}>
      <Typography className={styles.infoText} variant="body1" sx={{flexGrow: 1}}>Venue-Plan: {venuePlan.name}</Typography>
    </Box>
  );
}

export default InfoBar;
