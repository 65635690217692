import {Resource} from "../../../api";

export default class Block extends Resource {

    constructor(axios) {
        super('block', axios);
    }

    /**
     * Ruft Blöcke ab
     */
    byVenuePlan = (id) => {
        return this.axios.get(`/block/byVenuePlan/${id}`)
            .then((res) => res.data);
    };
}
