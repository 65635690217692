import {put} from 'redux-saga/effects';
import {normalize} from 'normalizr';
import {cloneDeep, find, forEach, transform} from "lodash";
import {select} from "@redux-saga/core/effects";
import {getCellMap} from "../cellMap/selectors";
import {actions as pricingListActions} from "../../pricingList";

/**
 * @param actions Aktionen für die Entität
 * @param resource API Resource für die Entität
 * @param schema normalizr Schema der Entitität
 */
export default ({actions, api, schema, schemaPricingList}) => function* ({payload: {pricingMatrix, pricingList}}) {
    try {
        // Die API erwartet die Zuweisung von Leistungsvorlagen zu Preisklassen und -Kategorien als Liste von Tripeln
        // Deshalb wickeln wir unsere 2D-Arbeits-Map wieder in diese Struktur aus
        const pricingMatrixForSaving = cloneDeep(pricingMatrix);
        const cellMap = yield select(state => getCellMap(state, pricingMatrixForSaving.id));

        const isEmpty = (value) => {
            return (value == null || (typeof value === "string" && value.trim().length === 0));
        }

        pricingMatrixForSaving.matrixCells = transform(cellMap, (result, matrixCellsByCategoryId, classId) => {
            const pricingClass = find(pricingMatrixForSaving.pricingClasses, pricingClass => pricingClass.id === classId);
            forEach(matrixCellsByCategoryId, (matrixCell, categoryId) => {
                if (matrixCell) {
                    const pricingCategory = find(pricingMatrixForSaving.pricingCategories, pricingCategory => pricingCategory.id === categoryId);
                    result.push({
                        localItemTemplateId: matrixCell.localItemTemplateId,
                        grossAmount: !isEmpty(matrixCell.grossAmount) ? parseInt(matrixCell.grossAmount) : null,
                        strikeAmount: !isEmpty(matrixCell.strikeAmount) ? parseInt(matrixCell.strikeAmount) : null,
                        pricingClass,
                        pricingCategory
                    });
                }
            });
        }, []);

        pricingMatrixForSaving.availableLocalItemTemplates = pricingMatrixForSaving.availableLocalItemTemplates.map(localItemTemplate => {
            const mainVatValue = localItemTemplate.mainPricingComponent.productBookingGroupVatValue;
            const mainVatKey = localItemTemplate.mainPricingComponent.productBookingGroupVatKey;

            return {
                ...localItemTemplate,
                mainPricingComponent: {
                    ...localItemTemplate.mainPricingComponent,
                    productBookingGroupVatValue: !isEmpty(mainVatValue) ? mainVatValue : null,
                    productBookingGroupVatKey: !isEmpty(mainVatKey) ? mainVatKey : null,

                },
                pricingComponents: localItemTemplate.pricingComponents.map(pricingComponent => {
                    const componentVatValue = pricingComponent.productBookingGroupVatValue;
                    const componentVatKey = pricingComponent.productBookingGroupVatKey;

                    return {
                        ...pricingComponent,
                        productBookingGroupVatValue: !isEmpty(componentVatValue) ? componentVatValue : null,
                        productBookingGroupVatKey: !isEmpty(componentVatKey) ? componentVatKey : null
                    }
                }),
            }
        });

        const formData = {
            pricingList: pricingList,
            pricingMatrix: pricingMatrixForSaving
        }

        const result = yield api.savePricingListWithMatrix(pricingList.id, pricingMatrixForSaving.id, formData);

        const response = normalize(result.pricingMatrix, schema);
        const responsePricingList = normalize(result, schemaPricingList);

        yield put(pricingListActions.load({id: responsePricingList.result}))

        yield put(actions.saveWithPricingListSuccess({
            response,
            globalMessagesList: {
                id: Date.now(),
                variant: 'success',
                isCreate: false,
                message: 'Änderung erfolgreich gespeichert!'
            }
        }))
    } catch (error) {
        yield put(actions.saveWithPricingListError({entity: pricingMatrix, error}))
    }
}
