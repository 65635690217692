import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PrefixFilterConfig from './filter/PrefixFilterConfig';
import {ItemField} from './ListViewPropTypes';
import DateRangeFilterConfig from './filter/DateRangeFilterConfig';

class ListViewColumnFilterConfig extends Component {
    render() {

        switch (this.props.field.type) {
            case 'datetime':
                return <DateRangeFilterConfig {...this.props}/>;
            default:
                return <PrefixFilterConfig {...this.props}/>;
        }
    }
}

ListViewColumnFilterConfig.propTypes = {
    field: ItemField.isRequired,
    filter: PropTypes.any,
    onFilterChange: PropTypes.func.isRequired
};

export default ListViewColumnFilterConfig;
