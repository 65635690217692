import React, {Component} from 'react';
import {Container, Row} from 'react-bootstrap';
import ConnectedListView from '../common/listView/ConnectedListView';
import FeedbackButton from '../common/FeedbackButton';
import {connect} from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {get} from 'lodash';
import * as seatingType from "../../state/entities/seatingType";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

class List extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDeleteConfirmDialog: false,
            seatingTypeToDelete: undefined
        }
    }

    showDeleteConfirmDialog(seatingType) {
        this.setState({
            showDeleteConfirmDialog: true,
            seatingTypeToDelete: seatingType,
        });
    }

    handleCancelDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
    };

    handleConfirmDelete = () => {
        this.setState({showDeleteConfirmDialog: false});
        this.props.deleteSeatingType(this.state.seatingTypeToDelete);
    };

    render() {
        return (
            <Container>
                <Row className="my-4">
                    <Breadcrumbs entity={["Bestuhlungstyp"]} endpoints = {['seating_type/breadcrumb']} title = "Bestuhlungstypen" links={['/base/seating-type/']} active={[0]} />
                </Row>
                <ConnectedListView
                    heading="Bestuhlungstypen"
                    endpoint="listingView/seatingType"
                    buttonTitle="Neuer Bestuhlungstyp"
                    buttonLink="/base/seating-type/create"
                    testid="link_new_seatingType"
                >
                    {({fields, item}) => (
                        <>
                            <FeedbackButton
                                to={`/base/seating-type/${item.id}`}
                                title="Bestuhlungstyp bearbeiten"
                                className="list-link"
                                icon={<EditNoteOutlinedIcon className='table-icon' />}
                            />
                            <FeedbackButton title="Bestuhlungstyp löschen" className="list-link" onClick={() => this.showDeleteConfirmDialog(item)}>
                                <DeleteOutlineOutlinedIcon className='table-icon' />
                            </FeedbackButton>
                        </>
                    )}
                </ConnectedListView>


                <Modal show={this.state.showDeleteConfirmDialog} onHide={this.handleCancelDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Bestuhlungstyp löschen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Möchten Sie den Bestuhlungstyp "{get(this.state, 'seatingTypeToDelete.name')}" wirklich löschen?</Modal.Body>
                    <Modal.Footer>
                        <FeedbackButton variant="outlined" onClick={this.handleCancelDelete}>
                            Abbrechen
                        </FeedbackButton>
                        <FeedbackButton onClick={this.handleConfirmDelete}>
                            Löschen
                        </FeedbackButton>
                    </Modal.Footer>
                </Modal>

            </Container>
        );
    }
}

export default connect(null, {
    deleteSeatingType: entity => seatingType.actions.delete({entity})
})(List);
